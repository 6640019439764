/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { TextStyle, TextStyleKey } from 'canvas-shared/lib/types/text.types'

/*---------------------------------------------------------------------------*/

export const textStyles: TextStyle[] = [
  {
    id: 'font-bold',
    displayName: 'Bold',
    icon: 'bold',
  },
  {
    id: 'italic',
    displayName: 'Italic',
    icon: 'italic',
  },
  {
    id: 'underline',
    displayName: 'Underline',
    icon: 'underline',
  },
]

export const defaultTextStyle: TextStyleKey[] = []
