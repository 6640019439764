/*---- External -------------------------------------------------------------*/

import React from 'react'
import { useSelector } from 'react-redux'

import { CirclesThreePlus } from 'phosphor-react'

import { Intent, Toast, Toaster } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { useInteractionModeActions } from '../../redux/actions/board/useInteractionModeActions'
import { selectInteractionMode } from '../../redux/selectors/board/interactionModes'

import { InteractionMode } from '../../types'

/*---------------------------------------------------------------------------*/

export const CanvasInteractionModeToast: React.FC = () => {
  const interactionMode = useSelector(selectInteractionMode)
  const { setInteractionMode } = useInteractionModeActions()

  if (!interactionMode || interactionMode === InteractionMode.NORMAL) {
    return null
  }

  return (
    <Toaster className="top-offset-toolbar z-above-items" usePortal={false}>
      <Toast
        action={{
          onClick: () => setInteractionMode(InteractionMode.NORMAL),
          text: 'Stop',
        }}
        className="hide-toast-close-x"
        icon={<CirclesThreePlus className="bp3-icon" />}
        intent={Intent.PRIMARY}
        message={ToastMessages[interactionMode]}
        timeout={0}
      />
    </Toaster>
  )
}

const ToastMessages = {
  [InteractionMode.VOTING]: (
    <>
      <p>
        <b>Voting in progress</b>
      </p>
      <p>Only your own votes and reactions are visible</p>
    </>
  ),
  [InteractionMode.ESTIMATION]: (
    <>
      <p>
        <b>Estimation in progress</b>
      </p>
      <p>Only your own estimates are visible</p>
    </>
  ),
  [InteractionMode.ESTIMATE_RESOLUTION]: (
    <>
      <p>
        <b>Resolve estimates</b>
      </p>
      <p>Click an estimate to agree it</p>
    </>
  ),
}
