/*---- External -------------------------------------------------------------*/

import React from 'react'
import { useSelector } from 'react-redux'

import { Route } from 'react-router'

/*---- Qualdesk -------------------------------------------------------------*/

import { ReactComponent as PenAccentDuotoneThin } from '../../assets/icons/pen-duotone-thin.svg'

import { SearchProvider } from '../../contexts/Search'

import { WorkspaceNavigation } from '../../components/workspace/WorkspaceNavigation'
import { WorkspaceScrollView } from '../../components/workspace/WorkspaceScrollView'
import { AccountSettings } from '../../components/settings/AccountSettings'
import { TeamSettings } from '../../components/settings/TeamSettings'

import { useWorkspaceActions } from '../../redux/actions/workspace/useWorkspaceActions'
import { createTeamBoardsSelector } from '../../redux/selectors/container/boards'
import { createTeamSelector } from '../../redux/selectors/auth/teams'

import { useTypedParams } from '../../hooks/helpers/useTypedParams'

import {
  useArchivedBoards,
  useBoardsSharedWithMe,
  useMyBoards,
} from '../../redux/selectorHooks/workspace/boards'

import { Routes } from '../../router/routes'

import { WorkspaceActionProps } from '../../types/workspace'

/*---------------------------------------------------------------------------*/

export const Workspace: React.FC = () => (
  <SearchProvider>
    <div className="flex flex-row h-full select-none overflow-hidden">
      <WorkspaceNavigation />
      <Route path={Routes.WORKSPACE_PERSONAL.path} exact>
        <MyBoards />
      </Route>
      <Route path={Routes.WORKSPACE_SHARED_WITH_ME.path} exact>
        <BoardsSharedWithMe />
      </Route>
      <Route path={Routes.WORKSPACE_ARCHIVED.path} exact>
        <ArchivedBoards />
      </Route>
      <Route path={Routes.ACCOUNT_SETTINGS.path} exact>
        <AccountSettings />
      </Route>
      <Route path={Routes.WORKSPACE_TEAM.path} exact>
        <TeamBoards />
      </Route>
      <Route path={Routes.WORKSPACE_TEAM_SETTINGS.path} exact>
        <TeamSettings />
      </Route>
    </div>
  </SearchProvider>
)

const MyBoards: React.FC = () => {
  const boards = useMyBoards()

  const { createBoard } = useWorkspaceActions()

  const handleCreate = async () => {
    await createBoard()
  }

  const actions: WorkspaceActionProps[] = [
    {
      iconElement: (
        <PenAccentDuotoneThin className="text-icon-accent-light dark:text-icon-secondary-dark w-20 h-20 lg:w-24 lg:h-24" />
      ),
      onClick: handleCreate,
      text: 'Start a new private board',
    },
  ]

  return (
    <WorkspaceScrollView actions={actions} boards={boards} title="My boards" />
  )
}

const BoardsSharedWithMe: React.FC = () => {
  const boards = useBoardsSharedWithMe()
  const emptyState = {
    title: 'No shared boards to show',
    description: 'When someone shares a board you’ll see it here',
  }
  return (
    <WorkspaceScrollView
      boards={boards}
      title="Shared with me"
      emptyState={emptyState}
    />
  )
}

const ArchivedBoards: React.FC = () => {
  const boards = useArchivedBoards()
  const emptyState = {
    title: 'You haven’t archived any boards',
    description: 'If you archive a board you’ll see it here',
  }
  return (
    <WorkspaceScrollView
      boards={boards}
      title="Archived"
      emptyState={emptyState}
    />
  )
}

const TeamBoards: React.FC = () => {
  const { teamId } = useTypedParams()

  const boardsSelector = createTeamBoardsSelector(teamId as string)
  const boards = useSelector(boardsSelector)

  const teamSelector = createTeamSelector(teamId as string)
  const team = useSelector(teamSelector)

  const { createBoard } = useWorkspaceActions()

  if (!team) {
    return null
  }

  const handleCreate = async () => {
    await createBoard({ teamId: teamId })
  }

  const actions: WorkspaceActionProps[] = [
    {
      iconElement: (
        <PenAccentDuotoneThin className="text-icon-accent-light dark:text-icon-secondary-dark w-20 h-20 lg:w-24 lg:h-24" />
      ),
      onClick: handleCreate,
      text: 'Start a new shared board',
    },
  ]

  return (
    <WorkspaceScrollView
      actions={actions}
      boards={boards}
      team={team}
      title={team.name}
    />
  )
}
