/*---- External -------------------------------------------------------------*/

import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { addRectDimensionsToPositionIfRequired } from 'canvas-shared/lib/helpers/addRectDimensionsToPositionIfRequired'
import { isLinePosition } from 'canvas-shared/lib/helpers/isLinePosition'
import { isRectPosition } from 'canvas-shared/lib/helpers/isRectPosition'

import { ItemPosition } from 'canvas-shared/lib/types/Position.types'
import { movePositionsAction } from '../../actionCreators/positions'

/*---------------------------------------------------------------------------*/

interface PositionAlignmentActions {
  alignItemsLeft: (positions: ItemPosition[]) => void
  alignItemsRight: (positions: ItemPosition[]) => void
  alignItemsTop: (positions: ItemPosition[]) => void
  alignItemsBottom: (positions: ItemPosition[]) => void
  alignItemsVerticalCenter: (positions: ItemPosition[]) => void
  alignItemsHorizontalCenter: (positions: ItemPosition[]) => void
}

export const usePositionAlignmentActions = (): PositionAlignmentActions => {
  const dispatch = useDispatch()

  const alignItemsLeft = useCallback(
    (positions: ItemPosition[]) => {
      const newLeft = Math.min(
        ...positions.map((p) => addRectDimensionsToPositionIfRequired(p).left)
      )
      const newPositions = positions.reduce((res: ItemPosition[], p) => {
        if (isRectPosition(p)) {
          res = res.concat({
            ...p,
            left: newLeft,
          })
        } else if (isLinePosition(p)) {
          const startX =
            p.startX < p.endX ? newLeft : newLeft + (p.startX - p.endX)
          const endX =
            p.startX < p.endX ? newLeft + (p.endX - p.startX) : newLeft

          res = res.concat({
            ...p,
            startX,
            endX,
          })
        }

        return res
      }, [])
      return dispatch(movePositionsAction(newPositions))
    },
    [dispatch]
  )

  const alignItemsRight = useCallback(
    (positions: ItemPosition[]) => {
      const newRight = Math.max(
        ...positions.map((p) => {
          const pos = addRectDimensionsToPositionIfRequired(p)
          return pos.left + pos.width
        })
      )
      const newPositions = positions.reduce((res: ItemPosition[], p) => {
        if (isRectPosition(p)) {
          const currentRight = p.left + p.width
          const newLeft = p.left + newRight - currentRight

          res = res.concat({
            ...p,
            left: newLeft,
          })
        } else if (isLinePosition(p)) {
          const startX =
            p.startX < p.endX ? newRight - (p.endX - p.startX) : newRight
          const endX =
            p.startX < p.endX ? newRight : newRight - (p.startX - p.endX)

          res = res.concat({
            ...p,
            startX,
            endX,
          })
        }

        return res
      }, [])
      return dispatch(movePositionsAction(newPositions))
    },
    [dispatch]
  )

  const alignItemsTop = useCallback(
    (positions: ItemPosition[]) => {
      const newTop = Math.min(
        ...positions.map((p) => addRectDimensionsToPositionIfRequired(p).top)
      )
      const newPositions = positions.reduce((res: ItemPosition[], p) => {
        if (isRectPosition(p)) {
          res = res.concat({
            ...p,
            top: newTop,
          })
        } else if (isLinePosition(p)) {
          const startY =
            p.startY < p.endY ? newTop : newTop + (p.startY - p.endY)
          const endY = p.startY < p.endY ? newTop + (p.endY - p.startY) : newTop

          res = res.concat({
            ...p,
            startY,
            endY,
          })
        }

        return res
      }, [])
      return dispatch(movePositionsAction(newPositions))
    },
    [dispatch]
  )

  const alignItemsBottom = useCallback(
    (positions: ItemPosition[]) => {
      const newBottom = Math.max(
        ...positions.map((p) => {
          const pos = addRectDimensionsToPositionIfRequired(p)
          return pos.top + pos.height
        })
      )
      const newPositions = positions.reduce((res: ItemPosition[], p) => {
        if (isRectPosition(p)) {
          const currentBottom = p.top + p.height
          const newTop = p.top + newBottom - currentBottom

          res = res.concat({
            ...p,
            top: newTop,
          })
        } else if (isLinePosition(p)) {
          const startY =
            p.startY < p.endY ? newBottom - (p.endY - p.startY) : newBottom
          const endY =
            p.startY < p.endY ? newBottom : newBottom - (p.startY - p.endY)

          res = res.concat({
            ...p,
            startY,
            endY,
          })
        }

        return res
      }, [])
      return dispatch(movePositionsAction(newPositions))
    },
    [dispatch]
  )

  const alignItemsVerticalCenter = useCallback(
    (positions: ItemPosition[]) => {
      const leftMost = Math.min(
        ...positions.map((p) => addRectDimensionsToPositionIfRequired(p).left)
      )
      const rightMost = Math.max(
        ...positions.map((p) => {
          const pos = addRectDimensionsToPositionIfRequired(p)
          return pos.left + pos.width
        })
      )

      const newCenter = leftMost + (rightMost - leftMost) / 2

      const newPositions = positions.reduce((res: ItemPosition[], p) => {
        if (isRectPosition(p)) {
          const newLeft = newCenter - p.width / 2
          res = res.concat({
            ...p,
            left: newLeft,
          })
        } else if (isLinePosition(p)) {
          const halfwayPoint = (p.endX - p.startX) / 2
          const startX =
            p.startX < p.endX
              ? newCenter - halfwayPoint
              : newCenter + halfwayPoint
          const endX =
            p.startX < p.endX
              ? newCenter + halfwayPoint
              : newCenter - halfwayPoint

          res = res.concat({
            ...p,
            startX,
            endX,
          })
        }

        return res
      }, [])
      return dispatch(movePositionsAction(newPositions))
    },
    [dispatch]
  )

  const alignItemsHorizontalCenter = useCallback(
    (positions: ItemPosition[]) => {
      const topMost = Math.min(
        ...positions.map((p) => addRectDimensionsToPositionIfRequired(p).top)
      )
      const bottomMost = Math.max(
        ...positions.map((p) => {
          const pos = addRectDimensionsToPositionIfRequired(p)
          return pos.top + pos.height
        })
      )

      const newCenter = topMost + (bottomMost - topMost) / 2

      const newPositions = positions.reduce((res: ItemPosition[], p) => {
        if (isRectPosition(p)) {
          const newTop = newCenter - p.height / 2
          res = res.concat({
            ...p,
            top: newTop,
          })
        } else if (isLinePosition(p)) {
          const halfwayPoint = (p.endY - p.startY) / 2
          const startY =
            p.startY < p.endY
              ? newCenter - halfwayPoint
              : newCenter + halfwayPoint
          const endY =
            p.startY < p.endY
              ? newCenter + halfwayPoint
              : newCenter - halfwayPoint

          res = res.concat({
            ...p,
            startY,
            endY,
          })
        }

        return res
      }, [])
      return dispatch(movePositionsAction(newPositions))
    },
    [dispatch]
  )

  return useMemo(
    () => ({
      alignItemsLeft,
      alignItemsRight,
      alignItemsTop,
      alignItemsBottom,
      alignItemsVerticalCenter,
      alignItemsHorizontalCenter,
    }),
    [
      alignItemsLeft,
      alignItemsRight,
      alignItemsTop,
      alignItemsBottom,
      alignItemsVerticalCenter,
      alignItemsHorizontalCenter,
    ]
  )
}
