/*---- External -------------------------------------------------------------*/

import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPreparedPayload,
} from '@reduxjs/toolkit'
import { OneOrMany } from 'canvas-shared/lib/types'
import { Epic, ofType } from 'redux-observable'
import { OperatorFunction } from 'rxjs'
import { ignoreElements } from 'rxjs/internal/operators/ignoreElements'
import { tap } from 'rxjs/internal/operators/tap'
import { map } from 'rxjs/operators'

/*---- Qualdesk -------------------------------------------------------------*/

import { app } from '../../../config/firebase'
import type {
  ActionWithPayload,
  PayloadWithContainerAndUid,
} from '../../../types/redux'
import type { RootState } from '../../../types/redux'

import { mapPayloadUserAndContainerId } from './mapPayloadUserAndContainerId'

/*---------------------------------------------------------------------------*/

export type PayloadWithFirestore<Payload> = Payload & {
  firestore: typeof app.firestore
}

export function withFirestore<T extends {}>(): OperatorFunction<
  T,
  PayloadWithFirestore<T>
> {
  return map<T, PayloadWithFirestore<T>>((value) => ({
    ...value,
    firestore: app.firestore,
  }))
}

export function createFirestoreEpic<Payload>(
  actionCreator: OneOrMany<
    ActionCreatorWithPreparedPayload<any, Payload> | ActionCreatorWithoutPayload
  >,
  handler: OneOrMany<
    (value: PayloadWithFirestore<PayloadWithContainerAndUid<Payload>>) => void
  >
): Epic<ActionWithPayload<Payload>, never, RootState> {
  // a hack to allow for Epic to support multiple actions
  const actionCreators = Array.isArray(actionCreator)
    ? actionCreator
    : [actionCreator]

  const handlers = Array.isArray(handler) ? handler : [handler]

  return (action$, state$) => {
    const operators = [
      ofType(...actionCreators),
      mapPayloadUserAndContainerId<ActionWithPayload<Payload>, Payload>(state$),
      withFirestore<PayloadWithContainerAndUid<Payload>>(),
      ...handlers.map((h) =>
        tap<PayloadWithFirestore<PayloadWithContainerAndUid<Payload>>>(h)
      ),
    ]

    // @ts-ignore: Unfortunately, cannot use spread operator for .pipe() but we have to if we
    // want to support multiple handlers. It fails, becuase .pipe() expects a known number of
    // arguments, and TS has no way of knowing how many are there going to be in the array.
    return action$.pipe(...operators, ignoreElements())
  }
}
