/*---- External -------------------------------------------------------------*/

import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Tooltip, Position, Classes } from '@blueprintjs/core'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import GoogleFileIcon from './GoogleFileIcon'

import { safeString } from '../../../helpers/safeString'

import { itemSetColumnAction } from '../../../redux/actionCreators/itemData'
import { useEditsActions } from '../../../redux/actions/board/useEditsActions'
import { useSelectedItemIds } from '../../../redux/selectorHooks/board/useSelectedItemIds'

import { GoogleSheetsColumn } from 'canvas-shared/lib/types/connections/Google.types'
import { Item } from 'canvas-shared/lib/types/Item.types'
import { Columns } from 'canvas-shared/lib/types/item/columns.types'
import { MultilineTextInput } from '../../multilineTextInput/MultilineTextInput'

/*---------------------------------------------------------------------------*/

const COLUMN_COLORS = [
  'bg-blue-500',
  'bg-green-500',
  'bg-red-500',
  'bg-yellow-500',
  'bg-indigo-500',
  'bg-purple-500',
  'bg-pink-500',
]

const GoogleCard = ({ id, data, operation }: Item.GoogleSheetsCard) => {
  const { columns: columnsFromData, google: googleData } = data

  const [columns, setColumns] = useReducer(
    (state: Columns, partial: Partial<Columns>) =>
      ({ ...state, ...partial } as Columns),
    columnsFromData || ({} as Columns)
  )
  const [editing, setEditing] = useState<number | false>(false)

  useEffect(() => {
    setColumns(columnsFromData)
  }, [columnsFromData])

  const dispatch = useDispatch()
  const { addEdit, removeEdit } = useEditsActions()
  const selectedItemIds = useSelectedItemIds()

  const handleChange = (columnId: number, value: string) => {
    setColumns({
      [columnId]: value,
    })
  }

  const handleConfirm = (column: number, text: string) => {
    stopEditing()
    dispatch(itemSetColumnAction({ column, itemId: id, text }))
  }

  const startEditing = useCallback(
    (columnIndex: number) => {
      setEditing(columnIndex)
      addEdit(id)
    },
    [addEdit, id]
  )

  const stopEditing = () => {
    setEditing(false)
    removeEdit(id)
  }

  const startEditingIfEmptyAndSelected = () => {
    if ((!columns || !columns[0]) && selectedItemIds.includes(id)) {
      startEditing(0)
    }
  }

  useEffect(startEditingIfEmptyAndSelected, [
    columns,
    id,
    selectedItemIds,
    startEditing,
  ])

  const firstVisibleColumnIndex = googleData.options.columns?.findIndex(
    (c) => c.visible
  )

  return (
    <div className="flex flex-col h-full">
      <div className="bg-background-five-light dark:bg-background-five-dark border-border-weak-light dark:border-border-weak-dark flex flex-col flex-grow p-5 border border-b-0 rounded-t-xl">
        <div className="flex flex-col flex-grow space-y-3">
          {googleData.options.columns?.map(
            (c: GoogleSheetsColumn, columnIndex: number) => {
              if (c.visible && !!columns) {
                const handleClick = () => {
                  if (editing !== false) {
                    startEditing(columnIndex)
                  }
                }

                // If googleData.row isn't defined, we haven't yet loaded this content from Google
                const isPendingCell = !googleData.row || !googleData.metadataId
                const isFirstVisibleColumn =
                  columnIndex === firstVisibleColumnIndex

                if (isPendingCell) {
                  return (
                    <div key={columnIndex}>
                      <div
                        className={classNames('relative text-base', {
                          'text-lg': isFirstVisibleColumn,
                        })}
                      >
                        <div className={Classes.SKELETON}>Loading</div>
                      </div>
                    </div>
                  )
                }

                const isFormulaCell =
                  !!googleData.row?.values &&
                  !!googleData.row?.values[columnIndex]?.userEnteredValue
                    ?.formulaValue
                const isValidatedCell =
                  !!googleData.row?.values &&
                  !!googleData.row?.values[columnIndex]?.dataValidation
                const disabled =
                  editing === false || isFormulaCell || isValidatedCell

                const tooltipContent = () => {
                  if (isFormulaCell) {
                    return 'This cell contains a formula'
                  } else if (isValidatedCell) {
                    return 'This cell requires data validation'
                  }

                  return undefined
                }

                return (
                  <div key={columnIndex}>
                    <div
                      className={classNames('flex', {
                        'text-lg text-text-primary-light dark:text-text-primary-dark mb-2': isFirstVisibleColumn,
                        'text-base items-baseline space-x-2.5 text-text-secondary-light dark:text-text-secondary-dark': !isFirstVisibleColumn,
                      })}
                      onClick={handleClick}
                      onDoubleClick={() => startEditing(columnIndex)}
                    >
                      {!isFirstVisibleColumn && (
                        <div
                          className={classNames(
                            'flex-shrink-0 w-2.5 h-2.5 rounded-full',
                            {
                              [COLUMN_COLORS[
                                (columnIndex - 1) % COLUMN_COLORS.length
                              ]]: true,
                            }
                          )}
                        />
                      )}
                      <MultilineTextInput
                        className="flex-grow leading-normal"
                        disabled={disabled}
                        isEditing={editing === columnIndex}
                        key={operation?.origin}
                        onCancel={stopEditing}
                        onChange={(value) => handleChange(columnIndex, value)}
                        onConfirm={(value) => handleConfirm(columnIndex, value)}
                        placeholder={c.header}
                        selectAllOnFocus
                        value={safeString(columns[columnIndex])}
                      />
                      <Tooltip
                        content={tooltipContent()}
                        enforceFocus={false}
                        isOpen={
                          editing === columnIndex &&
                          disabled &&
                          selectedItemIds.includes(id)
                        }
                        position={Position.RIGHT}
                        targetClassName="absolute right-0"
                      >
                        <span></span>
                      </Tooltip>
                    </div>
                  </div>
                )
              } else {
                return null
              }
            }
          )}
        </div>
      </div>
      <footer className="bg-background-four-light dark:bg-background-five-dark border-border-weak-light dark:border-border-weak-dark flex flex-shrink-0 items-center px-5 py-4 border rounded-b-xl pointer-events-none">
        <div className="flex flex-grow items-center space-x-2">
          <div className="text-text-secondary-light dark:text-text-secondary-dark text-base">
            {googleData.file.name}
          </div>
        </div>
        <GoogleFileIcon size={20} />
      </footer>
    </div>
  )
}

export default GoogleCard
