/*---- External -------------------------------------------------------------*/

import { createCachedSelector } from 're-reselect'

/*---- Qualdesk -------------------------------------------------------------*/

import { getItemsData } from './items'

import { RootState } from '../../../types/redux'
import { Item } from 'canvas-shared/lib/types/Item.types'

/*---------------------------------------------------------------------------*/

const getSearchQuery = (state: RootState, searchQuery: string | undefined) =>
  searchQuery

export const searchResultsSelector = createCachedSelector(
  getSearchQuery,
  getItemsData,
  (searchQuery, items) => {
    if (!searchQuery) {
      return NO_RESULTS
    }

    return Object.values(items).filter((item) =>
      item.data.title
        ?.toLocaleLowerCase()
        .includes(searchQuery.toLocaleLowerCase())
    )
  }
)((state, searchQuery) => (!!searchQuery ? searchQuery : ''))

const NO_RESULTS: Item.BaseItem[] = []
