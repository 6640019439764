/*---- External -------------------------------------------------------------*/

import { createLocalStorageStateHook } from 'use-local-storage-state'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

export const useTrelloRedirectPath = createLocalStorageStateHook<
  string | undefined
>('trelloRedirectPath')
