/*---- External -------------------------------------------------------------*/

import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import clamp from 'lodash.clamp'

/*---- Qualdesk -------------------------------------------------------------*/

import getBoundingRectFromPositions from '../../helpers/getBoundingRectFromPositions'

import {
  ItemPosition,
  LinePosition,
} from 'canvas-shared/lib/types/Position.types'
import { UUID } from 'canvas-shared/lib/types'
import { KeyValuePair } from 'canvas-shared/lib/types/utilities.types'
import { Gesture } from 'canvas-shared/lib/types/pointerEvents.types'

import { ThunkAPI } from '../../types/redux'
import { currentContainerSelector } from '../selectors/container/containers'
import { mySessionSelector } from '../selectors/board/session'
import {
  MousePosition,
  ScrollDimensions,
} from 'canvas-shared/lib/types/scrollAndPosition.types'
import { selectMyUid } from '../selectors/auth/auth'
import {
  DEFAULT_CARD_WIDTH,
  MIN_ITEM_DIMENSION,
} from 'canvas-shared/lib/config/positions'
import { DEFAULT_CANVAS_HEIGHT } from '../../config/canvas'
import { isRectPosition } from 'canvas-shared/lib/helpers/isRectPosition'
import { isLinePosition } from 'canvas-shared/lib/helpers/isLinePosition'
import { generateOperation } from '../actions/helpers/generateOperation'
import { generatePseudoDOMRect } from 'canvas-shared/lib/helpers/generatePseudoDOMRect'

/*---------------------------------------------------------------------------*/

export const positionSetOneAction = createAction<
  KeyValuePair<UUID, ItemPosition | null>
>('positions/db/setOne')

export const positionRemoveAction = createAction<UUID>('positions/db/remove')
export const positionSetAllAction = createAction<Record<
  UUID,
  ItemPosition
> | null>('positions/db/setAll')

export const setPositionAction = createAction<ItemPosition>('positions/setOne')
export const setPositionsAction = createAction<ItemPosition[]>(
  'positions/setMany'
)
export const movePositionsAction = createAction<ItemPosition[]>(
  'positions/move'
)

export const movePendingPositionsToAction = createAsyncThunk<
  { canvas: ScrollDimensions; position: MousePosition },
  MousePosition,
  ThunkAPI
>('positions/movePendingTowards', (payload, { getState }) => {
  const state = getState()
  const canvas = mySessionSelector(state).scrollDimensions

  return { canvas, position: payload }
})

export const movePositionsWithGestureAction = createAsyncThunk<
  Record<UUID, ItemPosition>,
  Gesture,
  ThunkAPI
>('position/dragMove', (gesture, { getState }) => {
  const state = getState()
  const operation = generateOperation(selectMyUid(state))
  const { selectedItemPositions } = gesture
  const { canvasWidth, canvasHeight } = currentContainerSelector(state)
    ?.data || {
    canvasWidth: DEFAULT_CARD_WIDTH,
    canvasHeight: DEFAULT_CANVAS_HEIGHT,
  }
  const startRect = getBoundingRectFromPositions(selectedItemPositions)
  const deltaX = gesture.endCoords.canvasX - gesture.startCoords.canvasX
  const deltaY = gesture.endCoords.canvasY - gesture.startCoords.canvasY

  const newX = clamp(startRect.x + deltaX, 0, canvasWidth - startRect.width)
  const newY = clamp(startRect.y + deltaY, 0, canvasHeight - startRect.height)

  const clampedDeltaX = newX - startRect.x
  const clampedDeltaY = newY - startRect.y

  return selectedItemPositions.reduce(
    (res: Record<UUID, ItemPosition>, pos: ItemPosition) => {
      if (isRectPosition(pos)) {
        res[pos.id] = {
          ...pos,
          height: pos.height,
          width: pos.width,
          left: pos.left + clampedDeltaX,
          top: pos.top + clampedDeltaY,
          operation,
        }
      } else if (isLinePosition(pos)) {
        res[pos.id] = {
          ...pos,
          startX: pos.startX + clampedDeltaX,
          startY: pos.startY + clampedDeltaY,
          endX: pos.endX + clampedDeltaX,
          endY: pos.endY + clampedDeltaY,
          operation,
        }
      }
      return res
    },
    {}
  )
})

export const transformPositionsAction = createAsyncThunk<
  Record<UUID, ItemPosition>,
  Gesture,
  ThunkAPI
>('positions/transform', (gesture, { getState, dispatch }) => {
  const state = getState()
  const { selectedItemPositions, dragHandle } = gesture
  const { canvasWidth, canvasHeight } = currentContainerSelector(state)
    ?.data || {
    canvasWidth: DEFAULT_CARD_WIDTH,
    canvasHeight: DEFAULT_CANVAS_HEIGHT,
  }
  const startRect = getBoundingRectFromPositions(selectedItemPositions)
  const operation = generateOperation(selectMyUid(state))

  if (!dragHandle) {
    return Promise.reject()
  }

  if (
    selectedItemPositions.length === 1 &&
    isLinePosition(selectedItemPositions[0])
  ) {
    dispatch(transformLinePositionAction(gesture))
    return Promise.reject()
  }

  const deltaX = gesture.endCoords.canvasX - gesture.startCoords.canvasX
  const deltaY = gesture.endCoords.canvasY - gesture.startCoords.canvasY

  const newX = clamp(
    dragHandle.left ? startRect.x + deltaX : startRect.x,
    0,
    startRect.right - MIN_ITEM_DIMENSION
  )
  const newY = clamp(
    dragHandle.top ? startRect.y + deltaY : startRect.y,
    0,
    startRect.bottom - MIN_ITEM_DIMENSION
  )
  const newWidth = clamp(
    dragHandle.left ? startRect.width - deltaX : startRect.width + deltaX,
    MIN_ITEM_DIMENSION,
    canvasWidth
  )
  const newHeight = clamp(
    dragHandle.top ? startRect.height - deltaY : startRect.height + deltaY,
    MIN_ITEM_DIMENSION,
    canvasHeight
  )

  const newRect = generatePseudoDOMRect(newX, newY, newWidth, newHeight)

  const xRatio = newRect.width / startRect.width
  const yRatio = newRect.height / startRect.height

  return selectedItemPositions.reduce(
    (res: Record<UUID, ItemPosition>, pos: ItemPosition) => {
      if (isRectPosition(pos)) {
        const relativeLeft = pos.left - startRect.left
        const relativeTop = pos.top - startRect.top

        res[pos.id] = {
          ...pos,
          left: newX + relativeLeft * xRatio,
          top: newY + relativeTop * yRatio,
          width: pos.width * xRatio,
          height: pos.height * yRatio,
          operation,
        }
      } else if (isLinePosition(pos)) {
        const relativeStartX = pos.startX - startRect.x
        const relativeStartY = pos.startY - startRect.y
        const relativeEndX = pos.endX - startRect.x
        const relativeEndY = pos.endY - startRect.y

        res[pos.id] = {
          ...pos,
          startX: newX + relativeStartX * xRatio,
          startY: newY + relativeStartY * yRatio,
          endX: newX + relativeEndX * xRatio,
          endY: newY + relativeEndY * yRatio,
        }
      }
      return res
    },
    {}
  )
})

export const transformLinePositionAction = createAsyncThunk<
  LinePosition | undefined,
  Gesture,
  ThunkAPI
>('positions/transformLine', (gesture, { getState }) => {
  const state = getState()
  const { dragHandle, selectedItemPositions } = gesture
  const { canvasWidth, canvasHeight } = currentContainerSelector(state)
    ?.data || {
    canvasWidth: DEFAULT_CARD_WIDTH,
    canvasHeight: DEFAULT_CANVAS_HEIGHT,
  }

  const position = selectedItemPositions[0] as LinePosition

  const deltaX = gesture.endCoords.canvasX - gesture.startCoords.canvasX
  const deltaY = gesture.endCoords.canvasY - gesture.startCoords.canvasY

  if (dragHandle?.start) {
    return {
      ...position,
      startX: clamp(position.startX + deltaX, 0, canvasWidth),
      startY: clamp(position.startY + deltaY, 0, canvasHeight),
    }
  } else if (dragHandle?.end) {
    return {
      ...position,
      endX: clamp(position.endX + deltaX, 0, canvasWidth),
      endY: clamp(position.endY + deltaY, 0, canvasHeight),
    }
  }

  return Promise.reject()
})
