/*---- External -------------------------------------------------------------*/

import React from 'react'
import { useSelector } from 'react-redux'

import { Divider, IPanelProps } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { selectBoardConnections } from '../../redux/selectors/board/connections'
import { selectUserConnections } from '../../redux/selectors/auth/userConnections'

import { Header } from './Header'
import { ConnectionButton } from './ConnectionButton'
import { Connection } from './Connection'

import { UserConnectionType } from 'canvas-shared/lib/types/UserConnection.types'

/*---------------------------------------------------------------------------*/

export const ConnectionList: React.FC<IPanelProps> = () => {
  const connections = useSelector(selectBoardConnections)
  const userConnections = useSelector(selectUserConnections)

  return (
    <div className="px-6 py-14">
      <Header title="Connections" />
      {connections.length > 0 && (
        <>
          <h3 className="text-text-secondary-light dark:text-text-secondary-dark mt-10 text-sm font-semibold">
            Already connected
          </h3>
          <ul className="mt-5">
            {connections.map((conn) => (
              <li key={conn.id} className="-mx-2.5">
                <Connection connectionId={conn.id} />
              </li>
            ))}
          </ul>
        </>
      )}
      {(!!userConnections.jira ||
        !!userConnections.google ||
        !!userConnections.trello ||
        !!userConnections.linear) && (
        <>
          <h3 className="text-text-secondary-light dark:text-text-secondary-dark mt-10 text-sm font-semibold">
            Import content
          </h3>
          <ul className="-mx-2.5 mb-4 mt-5">
            {!!userConnections.google && (
              <li>
                <ConnectionButton
                  connectionType={UserConnectionType.Google}
                  connected
                >
                  Google Sheets
                </ConnectionButton>
              </li>
            )}
            {!!userConnections.jira && (
              <li>
                <ConnectionButton
                  connectionType={UserConnectionType.Jira}
                  connected
                >
                  Jira
                </ConnectionButton>
              </li>
            )}
            {!!userConnections.linear && (
              <li>
                <ConnectionButton
                  connectionType={UserConnectionType.Linear}
                  connected
                >
                  Linear
                </ConnectionButton>
              </li>
            )}
            {!!userConnections.trello && (
              <li>
                <ConnectionButton
                  connectionType={UserConnectionType.Trello}
                  connected
                >
                  Trello
                </ConnectionButton>
              </li>
            )}
          </ul>
        </>
      )}
      {(!userConnections.jira ||
        !userConnections.google ||
        !userConnections.trello ||
        !userConnections.linear) && (
        <>
          <Divider className="mx-0" />
          <ul className="-mx-2.5 mt-4">
            {!userConnections.google && (
              <li>
                <ConnectionButton connectionType={UserConnectionType.Google}>
                  Connect Google
                </ConnectionButton>
              </li>
            )}
            {!userConnections.jira && (
              <li>
                <ConnectionButton connectionType={UserConnectionType.Jira}>
                  Connect Jira
                </ConnectionButton>
              </li>
            )}
            {!userConnections.linear && (
              <li>
                <ConnectionButton connectionType={UserConnectionType.Linear}>
                  Connect Linear
                </ConnectionButton>
              </li>
            )}
            {!userConnections.trello && (
              <li>
                <ConnectionButton connectionType={UserConnectionType.Trello}>
                  Connect Trello
                </ConnectionButton>
              </li>
            )}
          </ul>
        </>
      )}
    </div>
  )
}
