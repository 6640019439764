/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { generateStoreAs } from '../../connectors/helpers/generateStoreAs'

import { containerIdSelector } from '../container/containers'

import { RootState, SchemaFirestore, SchemaRTDB } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

export function createContainerSpecificRTDBDataSelector<
  K extends keyof SchemaRTDB
>(key: K, defaultResult: Record<string, SchemaRTDB[K]> | null = null) {
  return createSelector(
    (state: RootState) => state.firebase.data,
    containerIdSelector,
    (result, containerId) => {
      if (!!containerId && !!result) {
        const parameterizedKey = generateStoreAs(key, containerId)
        return result[parameterizedKey as K]
      }

      return defaultResult
    }
  )
}

export function createContainerSpecificRTDBOrderedSelector<
  K extends keyof SchemaRTDB
>(
  key: K,
  defaultResult: Array<{ key: string; value: SchemaRTDB[K] }> | null = null
) {
  return createSelector(
    (state: RootState) => state.firebase.ordered,
    containerIdSelector,
    (result, containerId) => {
      if (!!containerId && !!result) {
        const parameterizedKey = generateStoreAs(key, containerId)
        return result[parameterizedKey as K]
      }

      return defaultResult
    }
  )
}

export function createContainerSpecificFirestoreDataSelector<
  K extends keyof SchemaFirestore
>(
  key: K,
  defaultResult: Record<string, SchemaFirestore[K]> | undefined = undefined
) {
  return createSelector(
    (state: RootState) => state.firestore.data,
    containerIdSelector,
    (result, containerId) => {
      if (!!containerId && !!result) {
        const parameterizedKey = generateStoreAs(key, containerId)
        return result[parameterizedKey as K]
      }

      return defaultResult
    }
  )
}

export function createContainerSpecificFirestoreOrderedSelector<
  K extends keyof SchemaFirestore
>(key: K, defaultResult: Array<SchemaFirestore[K]> | undefined = undefined) {
  return createSelector(
    (state: RootState) => state.firestore.ordered,
    containerIdSelector,
    (result, containerId) => {
      if (!!containerId && !!result) {
        const parameterizedKey = generateStoreAs(key, containerId)
        return result[parameterizedKey as K]
      }

      return defaultResult
    }
  )
}
