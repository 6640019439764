/*---- External -------------------------------------------------------------*/

import React, { useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  Button,
  H3,
  InputGroup,
  ControlGroup,
  Intent,
  Radio,
  RadioGroup,
  Classes,
} from '@blueprintjs/core'

import { Lock, Buildings, Globe, Link, CheckCircle } from 'phosphor-react'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { useBoardActions } from '../../redux/actions/board/useBoardActions'

import { selectMyEmail } from '../../redux/selectors/auth/auth'
import { useBoard } from '../../redux/selectorHooks/board/useBoard'

import { ShareSetting } from 'canvas-shared/lib/types/Board.types'

/*---------------------------------------------------------------------------*/

export const BoardInviteDialogLink = () => {
  const { updateBoardSharedSettings } = useBoardActions()

  const {
    settings: { shared, url },
  } = useBoard()
  const email = useSelector(selectMyEmail)

  const linkIcon = useMemo(() => {
    switch (shared) {
      case ShareSetting.ANY:
        return <Globe className={classNames(Classes.ICON, 'm-2.5')} size={22} />
      case ShareSetting.DOMAIN:
        return (
          <Buildings className={classNames(Classes.ICON, 'm-2.5')} size={22} />
        )
      case ShareSetting.INVITATION:
        return <Lock className={classNames(Classes.ICON, 'm-2.5')} size={22} />
    }
  }, [shared])

  const [copySuccess, setCopySuccess] = useState(false)

  const EMAIL_DOMAIN = (email as string).split('@')[1]

  const boardUrlInput = useRef<HTMLInputElement>(null)

  const selectEntireUrl = () => {
    if (!!boardUrlInput.current) {
      boardUrlInput.current.select()
    }
  }

  const copyLinkToClipboard = async () => {
    if (url) {
      await navigator.clipboard.writeText(url)
      setCopySuccess(true)
    }
  }

  const handleSettingChange = (e: React.FormEvent<HTMLInputElement>) => {
    const setting = e.currentTarget.value as ShareSetting
    updateBoardSharedSettings(
      setting,
      setting === ShareSetting.DOMAIN ? [EMAIL_DOMAIN] : undefined
    )
  }

  return (
    <>
      <H3>Share with a link</H3>
      <RadioGroup onChange={handleSettingChange} selectedValue={shared}>
        <Radio
          value={ShareSetting.INVITATION}
          labelElement={
            <>Only people on the list above can access this board</>
          }
        />
        <Radio
          value={ShareSetting.DOMAIN}
          labelElement={
            <>
              Allow people with email addresses ending in <b>@{EMAIL_DOMAIN}</b>{' '}
              and people on the list above to access this board
            </>
          }
        />
        <Radio
          value={ShareSetting.ANY}
          labelElement={<>Allow anyone with the link to access this board</>}
        />
      </RadioGroup>
      <ControlGroup className="pt-2">
        <InputGroup
          fill
          leftIcon={linkIcon}
          inputRef={boardUrlInput}
          large
          onClick={selectEntireUrl}
          defaultValue={url}
          readOnly
        />
        <Button
          className="w-40"
          icon={
            copySuccess ? (
              <CheckCircle className={Classes.ICON} size={22} />
            ) : (
              <Link className={Classes.ICON} size={22} />
            )
          }
          intent={copySuccess ? Intent.SUCCESS : Intent.NONE}
          large
          onClick={copyLinkToClipboard}
          text={copySuccess ? 'Link copied' : 'Copy link'}
        />
      </ControlGroup>
    </>
  )
}
