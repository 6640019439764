/*---- External -------------------------------------------------------------*/

import { useCallback, useMemo } from 'react'

import { IMenuItemProps, IMenuDividerProps } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { canBeDuplicated } from '../../../helpers/canBeDuplicated'

import { Item } from 'canvas-shared/lib/types/Item.types'
import { useDispatch } from 'react-redux'
import { duplicateItemsAction } from '../../../redux/actionCreators/items'

/*---------------------------------------------------------------------------*/

export const useDuplicateMenuItems = (
  actionItems: Item.BaseItem[]
): Array<IMenuItemProps | IMenuDividerProps> => {
  const dispatch = useDispatch()
  const handleDuplicate = useCallback(() => {
    dispatch(duplicateItemsAction({ items: actionItems }))
  }, [actionItems, dispatch])

  return useMemo(
    () => [
      {
        disabled: !canBeDuplicated(actionItems),
        icon: 'duplicate',
        onClick: handleDuplicate,
        text: 'Duplicate',
      } as IMenuItemProps,
    ],
    [actionItems, handleDuplicate]
  )
}
