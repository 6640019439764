/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { containerPath } from 'canvas-shared/lib/paths/containerPath'

import { UUID } from '../../types'

/*---------------------------------------------------------------------------*/

export const generateUsersPath = (containerId: UUID) =>
  `${containerPath(containerId)}/users`
export const generateUserPath = (containerId: UUID, userId: UUID) =>
  `${generateUsersPath(containerId)}/${userId}`
export const generateUserTimestampHistoryPath = (
  containerId: UUID,
  userId: UUID
) => `${generateUserPath(containerId, userId)}/timestampHistory`
