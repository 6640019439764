"use strict";
/*---- External -------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTitleFromData = void 0;
const defaultOptions = {
    removeNewlines: false,
};
const getTitleFromData = (data, maybeOptions) => {
    var _a, _b, _c, _d;
    const options = Object.assign(Object.assign({}, defaultOptions), maybeOptions);
    let result = '';
    if (data.title) {
        result = data.title;
    }
    else if (data.columns) {
        let firstVisibleColumnIndex = 0;
        if ((_b = (_a = data.google) === null || _a === void 0 ? void 0 : _a.connection) === null || _b === void 0 ? void 0 : _b.columns) {
            const i = (_d = (_c = data.google) === null || _c === void 0 ? void 0 : _c.connection) === null || _d === void 0 ? void 0 : _d.columns.findIndex((c) => !!c.visible);
            firstVisibleColumnIndex = i >= 0 ? i : 0;
        }
        result = data.columns[firstVisibleColumnIndex];
    }
    if (options.removeNewlines) {
        result = result.replace(/\r\n/g, ' ');
    }
    return result;
};
exports.getTitleFromData = getTitleFromData;
