/*---- External -------------------------------------------------------------*/

import { createReducer } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { Params } from '../../router/routes'
import { setParamsAction } from '../actionCreators/params'

/*---------------------------------------------------------------------------*/

const initialState: Params = {}

export const paramsReducer = createReducer(initialState, (builder) => {
  builder.addCase(setParamsAction, (state, action) =>
    !!action.payload ? action.payload : initialState
  )
})
