"use strict";
/*---- External -------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultLineColor = exports.defaultRectangleBackgroundColor = exports.defaultTextColor = exports.defaultStickyBackgroundColor = exports.getColor = exports.colorsWithBlackAndWhite = exports.colors = exports.getBackgroundColorClassName = exports.getTextColorClassName = exports.prettyColors = void 0;
/*---------------------------------------------------------------------------*/
const mapColors = ([color, hex]) => ({
    displayName: color[0].toUpperCase() + color.substring(1),
    color: color,
    hex,
});
exports.prettyColors = [
    ['rose', '#f43f5e'],
    ['pink', '#ec4899'],
    ['fuchsia', '#d946ef'],
    ['purple', '#a855f7'],
    ['violet', '#8b5cf6'],
    ['indigo', '#6366f1'],
    ['blue', '#3b82f6'],
    ['light-blue', '#0ea5e9'],
    ['cyan', '#06b6d4'],
    ['teal', '#14b8a6'],
    ['emerald', '#10b981'],
    ['green', '#22c55e'],
    ['lime', '#84cc16'],
    ['yellow', '#eab308'],
    ['amber', '#f59e0b'],
    ['orange', '#f97316'],
    ['red', '#ef4444'],
];
const allColors = exports.prettyColors.concat([
    ['black', '#000000'],
    ['white', '#FFFFFF'],
]);
const getColorClassName = (colorKey, saturation, prefix, darkMode = false) => {
    if (darkMode) {
        if (colorKey === 'white') {
            return `${prefix}-black`;
        }
        else if (colorKey === 'black') {
            return `${prefix}-white`;
        }
    }
    if (exports.prettyColors.find(([key]) => key === colorKey)) {
        return `${prefix}-${colorKey}-${saturation}`;
    }
    else {
        return `${prefix}-${colorKey}`;
    }
};
const getTextColorClassName = (colorKey, saturation, darkMode = false) => getColorClassName(colorKey, saturation, 'text', darkMode);
exports.getTextColorClassName = getTextColorClassName;
const getBackgroundColorClassName = (colorKey, saturation, darkMode = false) => getColorClassName(colorKey, saturation, 'bg', darkMode);
exports.getBackgroundColorClassName = getBackgroundColorClassName;
exports.colors = exports.prettyColors.map(mapColors);
exports.colorsWithBlackAndWhite = allColors.map(mapColors);
const getColor = (colorKey) => exports.colorsWithBlackAndWhite.find((c) => c.color === colorKey);
exports.getColor = getColor;
exports.defaultStickyBackgroundColor = exports.colors.find((c) => c.color === 'yellow');
exports.defaultTextColor = exports.colorsWithBlackAndWhite.find((c) => c.color === 'black');
exports.defaultRectangleBackgroundColor = exports.colorsWithBlackAndWhite.find((c) => c.color === 'teal');
exports.defaultLineColor = exports.colorsWithBlackAndWhite.find((c) => c.color === 'black');
