/*---- External -------------------------------------------------------------*/

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useHistory } from 'react-router'

/*---- Qualdesk -------------------------------------------------------------*/

import { scrollToCenterOnItemIdAction } from '../redux/actionCreators/scroll'

/*---------------------------------------------------------------------------*/

export const useCanvasGoToItem = () => {
  const {
    location: { pathname, search, hash },
    replace,
  } = useHistory()

  const dispatch = useDispatch()

  const extractItemHashFromUrl = () => {
    if (hash) {
      const itemId = hash.slice(1)
      dispatch(scrollToCenterOnItemIdAction(itemId))
      replace(`${pathname}${search}`)
    }
  }

  useEffect(extractItemHashFromUrl, [dispatch, hash, pathname, replace, search])
}
