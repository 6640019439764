/*---- External -------------------------------------------------------------*/

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'

/*---- Qualdesk -------------------------------------------------------------*/

import { TeamUserPermission } from '../utilities/TeamUserPermission'
import { TeamInvitationBar } from '../utilities/TeamInvitationBar'
import { TeamUserInvitation } from '../utilities/TeamUserInvitation'

import { userIdsInAlphabeticalOrderSelector } from '../../../redux/selectors/users/users'
import { teamInvitationsSelector } from '../../../redux/selectors/auth/teams'

import { TeamPanelProps } from '../TeamSettings'

/*---------------------------------------------------------------------------*/

export const TeamMembersPanel: React.FC<TeamPanelProps> = ({
  className,
  queueUpdate,
  team,
}) => {
  const userIdsInAlphabeticalOrder = useSelector(
    userIdsInAlphabeticalOrderSelector
  )
  const teamUserIds = useMemo(() => {
    const ids = Object.keys(team.roles.users)
    return userIdsInAlphabeticalOrder.filter((id) => ids.includes(id))
  }, [team.roles.users, userIdsInAlphabeticalOrder])

  useFirestoreConnect({
    collection: 'teams',
    doc: team.id,
    subcollections: [{ collection: 'invitations' }],
    storeAs: 'teamInvitations',
  })
  const teamInvitations = useSelector(teamInvitationsSelector)

  return (
    <div className={className}>
      <div className="mb-1 font-semibold">Team members</div>
      <div className="text-text-secondary-light dark:text-text-secondary-dark text-xs">
        View and edit permissions for members of your team
      </div>
      <div className="border-border-normal-light dark:border-border-normal-dark flex items-center py-6 border-b">
        <TeamInvitationBar
          queueUpdate={queueUpdate}
          team={team}
          teamInvitations={teamInvitations}
        />
      </div>
      <div className="flex flex-col mt-6 space-y-6">
        {teamUserIds.map((id) => (
          <TeamUserPermission
            key={id}
            level={team.roles.users[id]}
            userId={id}
            team={team}
            queueUpdate={queueUpdate}
          />
        ))}
        {teamInvitations.map(({ id, email, emailSent, level }) => (
          <TeamUserInvitation
            key={id}
            email={email}
            emailSent={emailSent}
            invitationId={id}
            queueUpdate={queueUpdate}
            level={level}
            teamId={team.id}
          />
        ))}
      </div>
    </div>
  )
}
