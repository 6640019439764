/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'
import { isLoaded } from 'react-redux-firebase'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  BoardConnection,
  GoogleBoardConnection,
  JiraBoardConnection,
  LinearBoardConnection,
  TrelloBoardConnection,
} from 'canvas-shared/lib/types/BoardConnection.types'

import {
  boardConnectionIsGoogle,
  boardConnectionIsJira,
  boardConnectionIsLinear,
  boardConnectionIsTrello,
} from 'canvas-shared/lib/types/BoardConnection.helpers'

import {
  createContainerSpecificFirestoreDataSelector,
  createContainerSpecificFirestoreOrderedSelector,
} from '../helpers/containerSpecificSelectors'

import { SyncStatus } from 'canvas-shared/lib/config/connections/sync'

import { MimeTypes } from 'canvas-shared/lib/types/file.types'
import { RootState } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

export const getBoardConnections = createContainerSpecificFirestoreOrderedSelector(
  'connections'
)

export const getBoardConnectionsData = createContainerSpecificFirestoreDataSelector(
  'connections'
)

export const selectBoardConnections = createSelector(
  getBoardConnections,
  (boardConnections) => {
    if (!boardConnections) {
      return NO_CONNECTIONS
    } else {
      return boardConnections
    }
  }
)

export const selectBoardConnectionsLoaded = createSelector(
  getBoardConnections,
  isLoaded
)

export const createSelectConnection = (connectionId: string) =>
  createSelector(getBoardConnectionsData, (connectionsData) =>
    !!connectionsData ? connectionsData[connectionId] : undefined
  )

function createConnectionsSelector<ConnectionType>(
  connectionFilter: (conn: BoardConnection | undefined) => boolean
) {
  const NO_CONNECTIONS: ConnectionType[] = []
  return createSelector(getBoardConnections, (boardConnections) => {
    if (!boardConnections) {
      return undefined
    }

    return boardConnections.reduce((res: ConnectionType[], c) => {
      if (connectionFilter(c)) {
        res = res.concat(c as any)
      }
      return res
    }, NO_CONNECTIONS)
  })
}

export const selectGoogleBoardConnections = createConnectionsSelector<
  GoogleBoardConnection
>(boardConnectionIsGoogle)
export const selectGoogleSheetsBoardConnections = createSelector(
  selectGoogleBoardConnections,
  (googleConnections) => {
    if (!googleConnections) {
      return undefined
    }
    googleConnections.filter(
      (c) => c.google.file.mimeType === MimeTypes.GOOGLE_SHEETS
    )
    return googleConnections
  }
)

export const selectJiraBoardConnections = createConnectionsSelector<
  JiraBoardConnection
>(boardConnectionIsJira)
export const selectTrelloBoardConnections = createConnectionsSelector<
  TrelloBoardConnection
>(boardConnectionIsTrello)
export const selectLinearBoardConnections = createConnectionsSelector<
  LinearBoardConnection
>(boardConnectionIsLinear)

export const createGoogleSheetsBoardConnectionSelector = createSelector(
  (_: RootState, id: string) => id,
  selectGoogleSheetsBoardConnections,
  (id, connections) => connections?.find((c) => c.id === id)
)

export const selectBoardConnectionIdsWithSyncErrors = createSelector(
  selectBoardConnections,
  (connections) =>
    connections
      .filter((c) => c.syncStatus === SyncStatus.ERROR)
      .map((c) => c.id)
)

export const selectConnectionOverviewSyncStatus = createSelector(
  selectBoardConnections,
  (connections) =>
    connections.reduce((status: SyncStatus[], connection) => {
      if (
        connection.syncStatus === SyncStatus.IN_PROGRESS &&
        !status.includes(SyncStatus.IN_PROGRESS)
      ) {
        return status.concat(SyncStatus.IN_PROGRESS)
      } else if (
        connection.syncStatus === SyncStatus.ERROR &&
        !status.includes(SyncStatus.ERROR)
      ) {
        return status.concat(SyncStatus.ERROR)
      }
      return status
    }, [])
)

const NO_CONNECTIONS: BoardConnection[] = []
