/*---- External -------------------------------------------------------------*/

import { createAction, createAsyncThunk, nanoid } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  selectPendingItems,
  selectPendingPositions,
} from '../selectors/board/pending'
import { selectMyUid } from '../selectors/auth/auth'
import {
  selectStartZ,
  positionsDataSelector,
} from '../selectors/board/positions'
import { mySelectedItemsSelector } from '../selectors/board/selections'
import { mySessionSelector } from '../selectors/board/session'

import { app } from '../../config/firebase'

import { isRectPosition } from 'canvas-shared/lib/helpers/isRectPosition'
import { getLocalSettingsForItemType } from './helpers/getLocalSettingsForItemType'
import { generateOperation } from '../actions/helpers/generateOperation'
import { canBeDuplicated } from '../../helpers/canBeDuplicated'
import { nextCoords } from '../../helpers/CardCoordsFactory'
import { getItemDimensions } from '../../helpers/getItemDimensions'

import { MIN_ITEM_DIMENSION } from 'canvas-shared/lib/config/positions'
import { CURRENT_TYPE_VERSION } from 'canvas-shared/lib/config/migration'

import { CreateableItemType, Item } from 'canvas-shared/lib/types/Item.types'
import { ItemPosition } from 'canvas-shared/lib/types/Position.types'
import { PendingStateSlice, ThunkAPI } from '../../types/redux'

/*---------------------------------------------------------------------------*/

export const pendingCancelAllAction = createAction('pending/cancelAll')

export const pendingCommitAllAction = createAsyncThunk<
  { items: Item.AnyItem[]; positions: ItemPosition[] },
  void,
  ThunkAPI
>('pending/commitAll', (_, { getState }) => {
  const state = getState()
  const items = selectPendingItems(state)
  const originalPositions = selectPendingPositions(state)

  const positions = originalPositions.map((p) => {
    if (isRectPosition(p)) {
      return {
        ...p,
        height: Math.max(p.height, MIN_ITEM_DIMENSION),
        width: Math.max(p.width, MIN_ITEM_DIMENSION),
      }
    } else {
      return p
    }
  })

  return { items, positions }
})

export const createEmptyPendingItemAction = createAsyncThunk<
  {
    item: Item.AnyItem
    position: ItemPosition
  },
  CreateableItemType,
  ThunkAPI
>('items/createEmptyPendingItem', (type: CreateableItemType, { getState }) => {
  const state = getState()
  const uid = selectMyUid(state)
  const pointerPosition = mySessionSelector(state).position
  const operation = generateOperation(uid)
  const data = getLocalSettingsForItemType(type)
  const startZ = selectStartZ(state)
  const timestamps = {
    createdAt: app.firestore.Timestamp.now(),
    createdBy: operation,
    updatedAt: app.firestore.Timestamp.now(),
    updatedBy: operation,
  }

  const item = {
    type,
    id: nanoid(),
    data,
    typeVersion: CURRENT_TYPE_VERSION,
    operation,
    ...timestamps,
  } as Item.AnyItem

  const { width, height } = getItemDimensions(item.type, item as Item.BaseData)

  const left = !!pointerPosition
    ? Math.max(pointerPosition[0] - width / 2, 0)
    : 0
  const top = !!pointerPosition
    ? Math.max(pointerPosition[1] - height / 2, 0)
    : 0

  const position = {
    id: item.id,
    operation: item.operation,
    width,
    height,
    left,
    top,
    z: startZ,
  }

  return { item, position }
})

export const createMultiplePendingItemsAction = createAsyncThunk<
  PendingStateSlice,
  { type: CreateableItemType; data: Item.BaseData[] },
  ThunkAPI
>('items/createMultiplePendingItems', (payload, { getState }) => {
  const { type, data: payloadData } = payload
  const state = getState()
  const uid = selectMyUid(state)
  const operation = generateOperation(uid)
  const localSettings = getLocalSettingsForItemType(type)
  const allPositions = positionsDataSelector(state)

  const timestamps = {
    createdAt: app.firestore.Timestamp.now(),
    createdBy: operation,
    updatedAt: app.firestore.Timestamp.now(),
    updatedBy: operation,
  }

  return payloadData.reduce(
    (res: PendingStateSlice, d, index) => {
      const id = nanoid()

      const data = { ...localSettings, ...d }

      const newItem = {
        type,
        data,
        id,
        typeVersion: CURRENT_TYPE_VERSION,
        operation,
        ...timestamps,
      } as Item.AnyItem

      const newPosition = {
        id: id,
        operation,
        ...nextCoords({
          ...getItemDimensions(type, data),
          index,
          startLeft: 0,
          startTop: 0,
          itemCount: Object.keys(res.items).length,
          positions: Object.values(allPositions),
        }),
      }

      return {
        items: {
          ...res.items,
          [id]: newItem,
        },
        positions: {
          ...res.positions,
          [id]: newPosition,
        },
      }
    },
    { items: {}, positions: {} }
  )
})

export const duplicateItemsWithDragAction = createAsyncThunk<
  PendingStateSlice,
  void,
  ThunkAPI
>('items/duplicateWithDrag', (payload, { getState, rejectWithValue }) => {
  const state = getState()

  const items = mySelectedItemsSelector(state)
  const allPositions = positionsDataSelector(state)
  const startZ = selectStartZ(state)

  const uid = selectMyUid(state)
  const operation = generateOperation(uid)

  if (!canBeDuplicated(items)) {
    return rejectWithValue(payload)
  }

  return items.reduce(
    (res: PendingStateSlice, item, i) => {
      const newId = nanoid()

      const newItem = {
        ...item,
        id: newId,
        createdAt: app.firestore.Timestamp.now(),
        createdBy: operation,
        updatedAt: app.firestore.Timestamp.now(),
        updatedBy: operation,
      }

      const newPosition: ItemPosition = {
        ...allPositions[item.id],
        id: newId,
        z: startZ + i,
        operation,
      }

      return {
        items: { ...res.items, [newId]: newItem },
        positions: { ...res.positions, [newId]: newPosition },
      }
    },
    { items: {}, positions: {} }
  )
})
