/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import * as JiraAPI from 'canvas-shared/lib/types/connections/JiraAPI.types'

/*---------------------------------------------------------------------------*/

export const boardAvatarUrl = (board: JiraAPI.Board) => {
  const apiUrl = board.self
  const loc = new URL(apiUrl)
  const avatarPath = board.location.avatarURI

  return `${loc.protocol}//${loc.hostname}/${avatarPath}`
}
