/*---- External -------------------------------------------------------------*/

import React, { useCallback, useMemo, useState } from 'react'
import { generatePath } from 'react-router-dom'

/*---- Qualdesk -------------------------------------------------------------*/

import { functions } from '../../../config/firebase'

import { generateUrlFromPath } from '../../../helpers/generateUrlFromPath'
import { Routes } from '../../../router/routes'

import { SuggestUser } from '../../invite/SuggestUser'

import { TeamPanelProps } from '../TeamSettings'

import { UUID } from 'canvas-shared/lib/types'
import { PermissionLevel } from 'canvas-shared/lib/types/permissions.types'
import { TeamUserLevel } from './TeamUserLevel'
import { Invitation } from 'canvas-shared/lib/types/invitation.types'

/*---------------------------------------------------------------------------*/

const addUserToTeamByEmail = functions.httpsCallable(
  'https-authAddUserToTeamByEmail'
)
const addUserToTeamByUserId = functions.httpsCallable(
  'https-authAddUserToTeamByUserId'
)

interface TeamInvitationBarProps extends TeamPanelProps {
  teamInvitations: Invitation[]
}

export const TeamInvitationBar: React.FC<TeamInvitationBarProps> = ({
  queueUpdate,
  team: {
    id: teamId,
    roles: { users },
  },
  teamInvitations,
}) => {
  const [level, setLevel] = useState(PermissionLevel.WRITE)

  const alreadyInvitedEmail = useCallback(
    (email: string) => teamInvitations.findIndex((i) => i.email === email) >= 0,
    [teamInvitations]
  )
  const alreadyInvitedUser = useCallback(
    (userId: UUID) => Object.keys(users).includes(userId),
    [users]
  )

  const url = useMemo(
    () =>
      generateUrlFromPath(
        generatePath(Routes.WORKSPACE_TEAM.path, { teamId: teamId })
      ),
    [teamId]
  )

  const handleSelectEmail = async (email: string) => {
    const f = () =>
      addUserToTeamByEmail({
        email,
        teamId,
        level,
        url,
      })
    if (queueUpdate) {
      queueUpdate(f)
    } else {
      f()
    }
  }
  const handleSelectUser = async (userId: UUID) => {
    const f = () => addUserToTeamByUserId({ teamId, userId, level, url })
    if (queueUpdate) {
      queueUpdate(f)
    } else {
      f()
    }
  }

  return (
    <SuggestUser
      alreadyInvitedEmail={alreadyInvitedEmail}
      alreadyInvitedUser={alreadyInvitedUser}
      onSelectEmail={handleSelectEmail}
      onSelectUser={handleSelectUser}
      rightElement={<TeamUserLevel level={level} onChange={setLevel} />}
    />
  )
}
