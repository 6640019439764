/*---- External -------------------------------------------------------------*/

import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { pointerUpAction } from '../../redux/actionCreators/pointers'

/*---------------------------------------------------------------------------*/

export const useWindowPointerUpHandlers = () => {
  const dispatch = useDispatch()

  const onPointerUp = useCallback(
    (e: PointerEvent) => {
      if ((e.target as HTMLElement).closest('.bp3-portal')) {
        e.stopPropagation()
        return
      }

      dispatch(pointerUpAction(e))

      // Re-enable browser selection
      // (disabled in usePointerDownHandler so that Safari displays the correct cursor)
      document.onselectstart = () => true
    },
    [dispatch]
  )

  const manageHandlers = () => {
    window.addEventListener('pointerup', onPointerUp)

    return () => {
      window.removeEventListener('pointerup', onPointerUp)
    }
  }

  useEffect(manageHandlers, [onPointerUp])
}
