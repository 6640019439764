/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { addRectDimensionsToPositionIfRequired } from 'canvas-shared/lib/helpers/addRectDimensionsToPositionIfRequired'
import { generatePseudoDOMRect } from 'canvas-shared/lib/helpers/generatePseudoDOMRect'

import {
  ItemPosition,
  PseudoDOMRect,
} from 'canvas-shared/lib/types/Position.types'

/*---------------------------------------------------------------------------*/

export const getBoundingRectFromPositions = (
  positions: ItemPosition[]
): PseudoDOMRect => {
  const enhancedPositions = positions.map((p) =>
    addRectDimensionsToPositionIfRequired(p)
  )

  const lefts = enhancedPositions.map((c) => c.left)
  const tops = enhancedPositions.map((c) => c.top)
  const rights = enhancedPositions.map((c) => c.left + c.width)
  const bottoms = enhancedPositions.map((c) => c.top + c.height)

  const x = Math.min(...lefts)
  const y = Math.min(...tops)

  const width = Math.max(...rights) - x
  const height = Math.max(...bottoms) - y

  return generatePseudoDOMRect(x, y, width, height)
}

export default getBoundingRectFromPositions
