import { IMenuItemProps, Menu } from '@blueprintjs/core'
import orderBy from 'lodash.orderby'

import { Item } from 'canvas-shared/lib/types/Item.types'
import { LinearProject } from 'canvas-shared/lib/types/connections/Linear.types'
import { UUID } from '../../../../types'

interface ChangeLinearProjectProps {
  cards: Item.LinearCard[]
  linearSetProject: (ids: UUID[], project: LinearProject) => Promise<void>
  linearUnsetProject: (ids: UUID[]) => Promise<void>
}

export const changeLinearProject = ({
  cards,
  linearSetProject,
  linearUnsetProject,
}: ChangeLinearProjectProps): IMenuItemProps | null => {
  const uniqProjects = (
    project: LinearProject,
    idx: number,
    list: LinearProject[]
  ) => list.findIndex((s) => s.id === project.id) === idx

  const projects = cards
    .flatMap((c) => c.data?.linear?.team?.projects?.nodes ?? [])
    .filter(uniqProjects)

  if (projects.length === 0) {
    return null
  }

  const noProject: LinearProject = { id: 'noproject', name: 'No Project' }

  const projectsWithBacklog = [...projects, noProject]

  const changeProject = (project: LinearProject) => {
    if (project.id === 'noproject') {
      linearUnsetProject(cards.map((c) => c.id))
    } else {
      linearSetProject(
        cards.map((c) => c.id),
        project
      )
    }
  }

  return {
    text: 'Project',
    icon: 'diagram-tree',
    children: orderBy(
      projectsWithBacklog,
      ['number'],
      ['desc']
    ).map((s: LinearProject) => (
      <Menu.Item key={s.name} text={s.name} onClick={() => changeProject(s)} />
    )),
  }
}
