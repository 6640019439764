/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { generateUserPath } from '../userPaths'

import { UUID } from 'canvas-shared/lib/types'

/*---------------------------------------------------------------------------*/

export const generateIsFocusedPath = (containerId: UUID, uid: string) =>
  `${generateUserPath(containerId, uid)}/isFocused`
