/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { containerPath } from 'canvas-shared/lib/paths/containerPath'

import { UUID } from '../../types'

/*---------------------------------------------------------------------------*/

export const generateDynamicItemsPath = (containerId: UUID) =>
  `${containerPath(containerId)}/dynamicItems`
export const generateDynamicItemPath = (
  containerId: UUID,
  dynamicItemId: UUID
) => `${generateDynamicItemsPath(containerId)}/${dynamicItemId}`
