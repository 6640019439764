/*---- External -------------------------------------------------------------*/

import React from 'react'

import classNames from 'classnames'

import { CaretRight } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import { TrelloBoard } from './TrelloBoardPicker'

/*---------------------------------------------------------------------------*/

interface TrelloBoardButtonProps extends Partial<TrelloBoard> {
  onClick?: (id: string) => void
  className?: string
}

export const TrelloBoardButton: React.FC<TrelloBoardButtonProps> = ({
  id,
  name,
  prefs,
  onClick,
  className,
}) => {
  const handleClick = () => onClick && id && onClick(id)

  let styles: React.CSSProperties = {
    backgroundColor: prefs?.backgroundTopColor,
  }

  if (prefs?.backgroundImageScaled) {
    styles.backgroundImage = `url(${prefs?.backgroundImageScaled[2].url})`
    styles.backgroundSize = 'cover'
    styles.backgroundPosition = '50%'
  }

  return (
    <div
      style={styles}
      className={classNames('h-20 rounded cursor-pointer', className)}
      onClick={handleClick}
      role="button"
    >
      <div className="flex p-3 h-full text-white bg-black bg-opacity-25 rounded space-x-3">
        <div className="flex-grow text-lg font-semibold leading-tight">
          {name}
        </div>
        <CaretRight
          className="flex-shrink-0 self-center"
          weight="bold"
          size={20}
        />
      </div>
    </div>
  )
}
