/*---- External -------------------------------------------------------------*/

import React, { useState } from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { validateEmail } from '../../helpers/validateEmail'

import { Routes } from '../../router/routes'

import { Input } from '../utilities/Input'
import { Button } from '../utilities/Button'
import { ArrowRight } from 'phosphor-react'
import { useAsync } from '@react-hook/async'

/*---------------------------------------------------------------------------*/

interface VerifyEmailFormProps {
  onSubmit: (email: string) => Promise<any>
}

const VerifyEmailForm: React.FC<VerifyEmailFormProps> = ({ onSubmit }) => {
  const [email, setEmail] = useState('')
  const [{ status }, handleVerifyEmail] = useAsync(async (event) => {
    event.preventDefault()
    await onSubmit(email)
  })

  return (
    <form onSubmit={handleVerifyEmail}>
      <h1 className="text-text-primary-light dark:text-text-primary-dark text-2xl font-extrabold leading-tight sm:text-3xl">
        Verify your email
      </h1>
      <p className="text-text-primary-light dark:text-text-primary-dark mb-6 mt-3 text-sm leading-normal">
        It looks like you continued the sign in process in a different browser.
        Enter your email address again so that we can verify who you are.
      </p>
      <Input
        type="email"
        placeholder="you@example.com"
        onChange={(event) => setEmail(event.target.value)}
        value={email}
        autoFocus
      />
      <Button
        className="flex items-center mt-4 w-full text-left space-x-2"
        disabled={status === 'loading' || !validateEmail(email)}
        loading={status === 'loading'}
      >
        <span className="flex-grow">Continue</span>
        <ArrowRight className="flex-shrink-0 w-4 h-4" />
      </Button>
      <div className="mt-3 text-center">
        <a
          className="text-button-primary-light dark:text-button-success-dark"
          href={Routes.AUTH_SIGN_IN.path}
        >
          Start over
        </a>
      </div>
    </form>
  )
}

export default VerifyEmailForm
