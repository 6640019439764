/*---- External -------------------------------------------------------------*/

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { firstBy } from 'thenby'
import times from 'lodash.times'
import { useAsyncEffect } from '@react-hook/async'

import { Classes } from '@blueprintjs/core'

import { SortAscending, SortDescending } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import { functions } from '../../../config/firebase'
import { boardAvatarUrl } from '../../../helpers/connections/JiraHelper'

import { selectJiraBoardConnections } from '../../../redux/selectors/board/connections'
import { selectJiraUserConnections } from '../../../redux/selectors/auth/userConnections'

import { useStatusReporter } from '../../../hooks/useStatusReporter'
import { usePartialMatchFilter } from '../../../hooks/usePartialMatchFilter'

import { SearchInputGroup } from '../../utilities/SearchInputGroup'
import { NoResults } from '../../utilities/NoResults'
import { SortOrderPopoverButton } from '../../utilities/SortOrderPopoverButton'
import { JiraBoardButton } from './JiraBoardButton'
import { SidebarPanel } from '../SidebarPanel'
import { JiraContentBrowser } from './JiraContentBrowser'

import jiraIcon from '../../../assets/integrations/jira/jira-icon.svg'

import * as JiraAPI from 'canvas-shared/lib/types/connections/JiraAPI.types'
import { ControlledPanelProps } from '../SidebarPanelStack'

/*---------------------------------------------------------------------------*/

const fetchBoards = functions.httpsCallable('https-jiraFetchBoards')
const importPreviewContent = functions.httpsCallable(
  'https-jiraImportPreviewContent'
)

enum Order {
  Recent = 'recent',
  Name = 'name',
  Key = 'key',
}

enum Sort {
  Asc = 'asc',
  Desc = 'desc',
}

export const JiraBoardPicker: React.FC<ControlledPanelProps> = (panelProps) => {
  const [boards, setBoards] = useState<JiraAPI.Board[]>([])
  const { reportError } = useStatusReporter()

  const [query, setQuery] = useState('')
  const [order, setOrder] = useState<Order>(Order.Name)
  const [sort, setSort] = useState<Sort>(Sort.Asc)
  const jiraConnections = useSelector(selectJiraBoardConnections)
  const jira = useSelector(selectJiraUserConnections)

  const hasJiraConnection = useMemo(
    () => jiraConnections && jiraConnections.some((c) => !!c.updatedAt),
    [jiraConnections]
  )

  useEffect(() => {
    if (hasJiraConnection) {
      setOrder(Order.Recent)
      setSort(Sort.Desc)
    }
  }, [hasJiraConnection])

  const cmpRecent = useCallback(
    (a, b) => {
      if (jiraConnections) {
        const aUpdatedAt =
          jiraConnections.find((c) => c.jira.boardId === a.id)?.updatedAt ?? 0
        const bUpdatedAt =
          jiraConnections.find((c) => c.jira.boardId === b.id)?.updatedAt ?? 0

        return aUpdatedAt - bUpdatedAt
      } else {
        return 0
      }
    },
    [jiraConnections]
  )

  const cmpProjectName = useCallback(
    (a, b) => a.location.projectName.localeCompare(b.location.projectName),
    []
  )

  const cmpProjectKey = useCallback(
    (a, b) => a.location.projectKey.localeCompare(b.location.projectKey),
    []
  )

  const sortedBoards = useMemo(() => {
    let cmp
    switch (order) {
      case Order.Recent:
        cmp = firstBy(cmpRecent, sort).thenBy(cmpProjectName)
        break
      case Order.Name:
        cmp = firstBy(cmpProjectName, sort)
        break
      case Order.Key:
        cmp = firstBy(cmpProjectKey, sort)
        break
    }
    return [...boards].sort(cmp)
  }, [boards, order, sort, cmpRecent, cmpProjectName, cmpProjectKey])

  const filteredBoards = usePartialMatchFilter<JiraAPI.Board>(
    sortedBoards,
    query,
    (board) => board.location.displayName
  )

  const { status: loadStatus } = useAsyncEffect(async () => {
    try {
      const { data } = await fetchBoards()
      setBoards(data)
    } catch (error) {
      reportError(error, 'Failed to get Jira boards')
    }
  }, [setBoards, reportError])

  const onClick = useCallback(
    (board: JiraAPI.Board) => {
      importPreviewContent({
        jiraBoardId: board.id,
        filters: [],
      })

      if (jira?.domain) {
        panelProps.openPanel({
          component: JiraContentBrowser,
          props: {
            board,
            domain: jira.domain,
          },
        })
      }
    },
    [jira?.domain, panelProps]
  )

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }, [])

  const onClear = useCallback(() => {
    setQuery('')
  }, [])

  const onChangeOrder = useCallback((value) => {
    switch (value) {
      case Order.Recent:
        setOrder(value)
        setSort(Sort.Desc)
        break
      case Order.Name:
        setOrder(value)
        setSort(Sort.Asc)
        break
      case Order.Key:
        setOrder(value)
        setSort(Sort.Asc)
        break
    }
  }, [])

  const onChangeSort = useCallback((value) => {
    switch (value) {
      case Sort.Asc:
        setSort(value)
        break
      case Sort.Desc:
        setSort(value)
        break
    }
  }, [])

  return (
    <SidebarPanel
      {...panelProps}
      text="Jira"
      iconUrl={jiraIcon}
      iconAltText="Jira icon"
    >
      <div className="p-6 pb-0">
        <SearchInputGroup
          value={query}
          onChange={onChange}
          onClear={onClear}
          placeholder="Find a project"
          changeBackgroundOnFocus={false}
          small
        />
        <div className="flex items-center justify-between mt-6 space-x-4">
          <div className="text-text-secondary-light dark:text-text-secondary-dark text-sm">
            {loadStatus === 'loading'
              ? 'Loading…'
              : `${filteredBoards.length} ${
                  filteredBoards.length === 1 ? 'project' : 'projects'
                }`}
          </div>
          <div className="flex-shrink-0">
            <div className="-mr-2">
              <SortOrderPopoverButton
                sort={sort}
                order={order}
                sortOptions={[
                  {
                    label: 'Ascending',
                    value: Sort.Asc,
                    icon: SortAscending,
                  },
                  {
                    label: 'Descending',
                    value: Sort.Desc,
                    icon: SortDescending,
                  },
                ]}
                orderOptions={[
                  {
                    label: 'Recently used',
                    value: Order.Recent,
                    disabled: !hasJiraConnection,
                  },
                  { label: 'Project name', value: Order.Name },
                  { label: 'Project key', value: Order.Key },
                ]}
                onChangeSort={onChangeSort}
                onChangeOrder={onChangeOrder}
              />
            </div>
          </div>
        </div>
      </div>
      {filteredBoards.length > 0 || loadStatus === 'loading' ? (
        <ul className="-mx-2.5 px-6 py-2.5 space-y-2">
          {loadStatus === 'loading' &&
            times(3).map((i) => (
              <li key={i}>
                <JiraBoardButton className={Classes.SKELETON} text="Loading…" />
              </li>
            ))}
          {filteredBoards.map((board) => (
            <li key={board.id}>
              <JiraBoardButton
                text={board.location.displayName}
                iconUrl={boardAvatarUrl(board)}
                iconAltText={`${board.location.displayName} icon`}
                onClick={() => onClick(board)}
              />
            </li>
          ))}
        </ul>
      ) : (
        <NoResults className="my-10" />
      )}
    </SidebarPanel>
  )
}
