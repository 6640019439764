/*---- External -------------------------------------------------------------*/

import React from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { useSearchContext } from '../../contexts/Search'
import { SearchInputGroup } from '../utilities/SearchInputGroup'

/*---------------------------------------------------------------------------*/

export const SearchBox: React.FC = () => {
  const { searchQuery, updateQuery } = useSearchContext()

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateQuery(event.target.value)
  }

  const onClear = () => {
    updateQuery(undefined)
  }

  return (
    <SearchInputGroup
      autoFocus
      placeholder="Find something on this board…"
      onChange={onChange}
      onClear={onClear}
      value={searchQuery}
    />
  )
}
