/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { RootState } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

const getToast = (state: RootState) => state.toast

export const toastSelector = createSelector(getToast, (toastMap) =>
  Object.values(toastMap)
)
