/*---- External -------------------------------------------------------------*/

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  archivedBoardsSelector,
  boardsSelector,
  boardsSharedWithMeSelector,
  myBoardsSelector,
} from '../../selectors/container/boards'

/*---------------------------------------------------------------------------*/

export const useBoards = () => useSelector(boardsSelector)
export const useArchivedBoards = () => useSelector(archivedBoardsSelector)
export const useMyBoards = () => useSelector(myBoardsSelector)
export const useBoardsSharedWithMe = () =>
  useSelector(boardsSharedWithMeSelector)
