/*---- External -------------------------------------------------------------*/

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { CANVAS_GUTTER } from '../../config/canvas'

import CanvasGroupRect from './CanvasGroupRect'
import { CanvasItem } from './CanvasItem'
import CanvasSelectionRect from './CanvasSelectionRect'
import CanvasSearchResultsMask from './CanvasSearchResultsMask'
import CanvasPresenceSelections from './CanvasPresenceSelections'

import { useCanvasWindowContext } from '../../contexts/CanvasWindow'

import { useBoard } from '../../redux/selectorHooks/board/useBoard'
import { selectGroupIds } from '../../redux/selectors/board/groups'
import { selectVisibleItemIds } from '../../redux/selectors/board/visibility'
import { selectZoomScale } from '../../redux/selectors/board/zoom'
import { selectPendingItemIds } from '../../redux/selectors/board/pending'

/*---------------------------------------------------------------------------*/

export const CanvasItems: React.FC = React.memo(() => {
  const itemIds = useSelector(selectVisibleItemIds)
  const pendingItemIds = useSelector(selectPendingItemIds)
  const groupIds = useSelector(selectGroupIds)

  return (
    <CanvasItemsBackdrop>
      {itemIds.map((id) => (
        <CanvasItem key={id} itemId={id} />
      ))}
      {pendingItemIds.map((id) => (
        <CanvasItem key={id} itemId={id} pending />
      ))}
      {groupIds.map((id) => (
        <CanvasGroupRect key={id} groupId={id} />
      ))}
      <CanvasSelectionRect />
      <CanvasPresenceSelections />
      <CanvasSearchResultsMask />
    </CanvasItemsBackdrop>
  )
})

const CanvasItemsBackdrop: React.FC = ({ children }) => {
  const { canvasPageRef } = useCanvasWindowContext()
  const {
    data: { canvasHeight, canvasWidth },
  } = useBoard()

  const zoomScale = useSelector(selectZoomScale)

  const gutterStyle = useMemo(
    () => ({
      padding: CANVAS_GUTTER,
      paddingTop: CANVAS_GUTTER,
      height: canvasHeight / zoomScale + CANVAS_GUTTER * 2,
      width: canvasWidth / zoomScale + CANVAS_GUTTER * 2,
    }),
    [canvasHeight, canvasWidth, zoomScale]
  )

  const backdropStyle = useMemo(
    () => ({
      height: canvasHeight,
      width: canvasWidth,
      transform: `scale(${1 / zoomScale})`,
      transformOrigin: 'top left',
    }),
    [canvasHeight, canvasWidth, zoomScale]
  )

  return (
    <div
      className="absolute z-0 min-w-full min-h-full select-none"
      style={gutterStyle}
    >
      <div
        className="bg-blue-gray-100 dark:bg-cool-gray-800 relative shadow-xl"
        id="canvas-backdrop"
        style={backdropStyle}
        ref={canvasPageRef}
      >
        {children}
      </div>
    </div>
  )
}
