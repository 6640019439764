/*---- External -------------------------------------------------------------*/

import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import classNames from 'classnames'

import { Classes } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { safeString } from '../../../helpers/safeString'

import { itemSetTitleAction } from '../../../redux/actionCreators/itemData'
import { useEditsActions } from '../../../redux/actions/board/useEditsActions'
import { useSelectedItemIds } from '../../../redux/selectorHooks/board/useSelectedItemIds'
import { selectInteractionMode } from '../../../redux/selectors/board/interactionModes'

import { EstimationControls, EstimationValue } from '../../items/ItemEstimate'

import linearIcon from '../../../assets/integrations/linear/linear-icon.svg'

import { Item } from 'canvas-shared/lib/types/Item.types'
import { InteractionMode } from '../../../types'
import { LinearIssueLabel } from './LinearIssueLabel'
import { LinearIssuePriorityIcon } from './LinearIssuePriorityIcon'
import { MultilineTextInput } from '../../multilineTextInput/MultilineTextInput'

/*---------------------------------------------------------------------------*/

export const LinearCard = ({
  id,
  data: { title: currentTitle, linear: issue },
  loading,
  operation,
}: Item.LinearCardMaybeMissingData) => {
  const titleFromData = safeString(currentTitle)

  const [title, setTitle] = useState(titleFromData)
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    setTitle(titleFromData)
  }, [titleFromData])

  const dispatch = useDispatch()
  const { addEdit, removeEdit } = useEditsActions()
  const selectedItemIds = useSelectedItemIds()

  const handleChange = (value: string) => {
    setTitle(value)
  }

  const handleConfirm = (value: string) => {
    stopEditing()
    dispatch(itemSetTitleAction({ itemId: id, title: value }))
  }

  const startEditing = useCallback(() => {
    setEditing(true)
    addEdit(id)
  }, [addEdit, id])

  const stopEditing = () => {
    setEditing(false)
    removeEdit(id)
  }

  const startEditingIfEmptyAndSelected = () => {
    if (!title && selectedItemIds.includes(id)) {
      startEditing()
    }
  }

  useEffect(startEditingIfEmptyAndSelected, [
    id,
    selectedItemIds,
    startEditing,
    title,
  ])

  const interactionMode = useSelector(selectInteractionMode)

  const isEstimatingOrResolving = [
    InteractionMode.ESTIMATE_RESOLUTION,
    InteractionMode.ESTIMATION,
  ].includes(interactionMode)

  return (
    <div className="flex flex-col h-full" onDoubleClick={startEditing}>
      <div className="bg-background-five-light dark:bg-background-five-dark border-border-weak-light dark:border-border-weak-dark flex flex-col flex-grow p-5 border border-b-0 rounded-t-xl">
        <div className="flex flex-col flex-grow">
          <MultilineTextInput
            className="text-text-primary-light dark:text-text-primary-dark flex-grow text-lg leading-normal"
            disabled={!editing}
            isEditing={editing}
            key={operation?.origin}
            onCancel={stopEditing}
            onChange={handleChange}
            onConfirm={handleConfirm}
            placeholder=""
            value={title}
          />
          <div className="flex-shrink-0 mt-6">
            <ul
              className={classNames('flex flex-wrap items-center -mx-2 -my-1', {
                [Classes.SKELETON]: loading,
              })}
            >
              {issue.state && (
                <li className="m-1 min-w-0">
                  <LinearIssueLabel
                    text={issue.state.name}
                    color={issue.state.color}
                  />
                </li>
              )}
              {issue.cycle && (
                <li className="m-1 min-w-0">
                  <LinearIssueLabel
                    text={issue.cycle?.name ?? `Cycle ${issue.cycle.number}`}
                  />
                </li>
              )}
              {issue.labels?.nodes.map((label) => (
                <li key={label.id} className="m-1 min-w-0">
                  <LinearIssueLabel text={label.name} color={label.color} />
                </li>
              ))}
            </ul>
          </div>
          <div
            className={classNames('flex-shrink-0 pointer-events-none', {
              [Classes.SKELETON]: loading,
            })}
          >
            <div className="flex items-end mt-4 space-x-2">
              <div className="flex flex-grow items-center space-x-2">
                {issue.priority !== undefined &&
                  issue.priority !== null &&
                  issue.priorityLabel && (
                    <LinearIssuePriorityIcon
                      priority={issue.priority}
                      priorityLabel={issue.priorityLabel}
                    />
                  )}
                <span className="text-text-secondary-light dark:text-text-secondary-dark flex-grow text-base">
                  {issue.identifier}
                </span>
              </div>
              <div
                className={classNames('self-stretch', {
                  [Classes.SKELETON]: loading,
                })}
              >
                <EstimationValue itemId={id} />
              </div>
              {issue.assignee?.name && issue.assignee?.avatarUrl && (
                <img
                  className="w-8 h-8 rounded-full"
                  src={issue.assignee.avatarUrl}
                  alt={issue.assignee.name}
                  title={`Assigned to ${issue.assignee.name}`}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <footer className="bg-background-four-light dark:bg-background-five-dark border-border-weak-light dark:border-border-weak-dark flex flex-shrink-0 items-center px-5 py-4 border rounded-b-xl pointer-events-none">
        <div className="flex flex-grow items-center space-x-2">
          {issue.team?.organization?.logoUrl && (
            <img
              className="w-6 h-6 rounded"
              src={issue.team.organization.logoUrl}
              alt={issue.team.organization.name}
              title={issue.team.organization.name}
            />
          )}
          <div className="text-text-secondary-light dark:text-text-secondary-dark text-base">
            {issue.team?.name}
          </div>
        </div>
        {isEstimatingOrResolving ? (
          <EstimationControls itemId={id} />
        ) : (
          <img alt="Linear" className="w-6 h-6" src={linearIcon} />
        )}
      </footer>
    </div>
  )
}
