/*---- External -------------------------------------------------------------*/

import React, { useCallback, useMemo } from 'react'

import classNames from 'classnames'

import {
  Button,
  Checkbox,
  Classes,
  Collapse,
  Spinner,
  Switch,
} from '@blueprintjs/core'

import { Minus, Plus } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import { groupByType } from 'canvas-shared/lib/helpers/jql'

import {
  Filter,
  FilterType,
} from 'canvas-shared/lib/types/connections/Jira.types'

/*---------------------------------------------------------------------------*/

interface JiraFiltersProps {
  filters: Filter[]
  isLoaded: boolean
  openSections: Set<FilterType>
  checkedValueIds: Set<string>
  setOpenSections: (value: Set<FilterType>) => void
  setCheckedValueIds: (value: Set<string>) => void
  setAppliedFilters: (value: Filter[]) => void
}

export const JiraFilters: React.FC<JiraFiltersProps> = ({
  filters,
  isLoaded,
  openSections,
  checkedValueIds,
  setOpenSections,
  setCheckedValueIds,
  setAppliedFilters,
}) => {
  const onChange = useCallback(
    (value) => {
      const newCheckedValueIds = new Set(checkedValueIds)
      if (newCheckedValueIds.has(value.id)) {
        newCheckedValueIds.delete(value.id)
      } else {
        newCheckedValueIds.add(value.id)
      }

      const checkedFilters = filters.filter((value) => {
        const isChecked = newCheckedValueIds.has(value.id)
        return value.jqlDefaultInclude ? !isChecked : isChecked
      })

      setCheckedValueIds(newCheckedValueIds)
      setAppliedFilters(checkedFilters)
    },
    [checkedValueIds, filters, setCheckedValueIds, setAppliedFilters]
  )

  const onReset = useCallback(() => {
    const newCheckedValueIds = new Set(checkedValueIds)
    newCheckedValueIds.clear()
    setCheckedValueIds(newCheckedValueIds)
    setAppliedFilters([])
  }, [checkedValueIds, setCheckedValueIds, setAppliedFilters])

  const setFilterSectionIsOpen = useCallback(
    (type) => {
      const newOpenSections = new Set(openSections)
      if (newOpenSections.has(type)) {
        newOpenSections.delete(type)
      } else {
        newOpenSections.add(type)
      }
      setOpenSections(newOpenSections)
    },
    [openSections, setOpenSections]
  )

  const genericFilters = useMemo(
    () =>
      filters.filter(
        (f) =>
          f.type === FilterType.ExcludeChild ||
          f.type === FilterType.IncludeDone
      ),
    [filters]
  )
  const otherFilters = useMemo(
    () => groupByType(filters.filter((f) => !genericFilters.includes(f))),
    [filters, genericFilters]
  )

  return (
    <>
      <div className="bg-background-four-light dark:bg-background-four-dark border-border-weak-light dark:border-border-weak-dark max-h-vh-85 flex flex-col border-b border-t overflow-y-auto">
        {!isLoaded ? (
          <Spinner className="m-20" />
        ) : (
          otherFilters.map((filters, index) => (
            <FilterSection
              key={filters[0].type}
              label={filters[0].typeLabel as string}
              isOpen={openSections.has(filters[0].type)}
              setIsOpen={() => setFilterSectionIsOpen(filters[0].type)}
            >
              {filters.map((filter) => (
                <Checkbox
                  key={filter.id}
                  className="text-text-primary-light dark:text-text-primary-dark"
                  labelElement={
                    filter.subLabel ? (
                      <>
                        {filter.label}{' '}
                        <span className="text-text-secondary-light dark:text-text-secondary-dark">
                          ({filter.subLabel})
                        </span>
                      </>
                    ) : (
                      filter.label
                    )
                  }
                  onChange={() => onChange(filter)}
                  checked={checkedValueIds.has(filter.id)}
                />
              ))}
              {index === 0 && (
                <div className="mt-5">
                  {genericFilters.map((filter) => (
                    <Switch
                      key={filter.id}
                      className="text-text-primary-light dark:text-text-primary-dark"
                      label={filter.label}
                      onChange={() => onChange(filter)}
                      checked={checkedValueIds.has(filter.id)}
                    />
                  ))}
                </div>
              )}
            </FilterSection>
          ))
        )}
      </div>
      <div
        className={classNames(
          'flex justify-end m-0 p-2',
          Classes.DIALOG_FOOTER
        )}
      >
        <Button
          className="p-2"
          minimal
          onClick={onReset}
          disabled={checkedValueIds.size === 0}
        >
          Reset filters
        </Button>
      </div>
    </>
  )
}

interface FilterSectionProps {
  label: string
  isOpen: boolean
  setIsOpen: () => void
}

const FilterSection: React.FC<FilterSectionProps> = ({
  label,
  isOpen,
  setIsOpen,
  children,
}) => {
  const Icon = useMemo(() => (isOpen ? Minus : Plus), [isOpen])

  return (
    <section className="border-border-weak-light dark:border-border-weak-dark border-b last:border-b-0">
      <button
        className="group flex items-center p-4 w-full space-x-3"
        onClick={setIsOpen}
      >
        <span
          className={classNames('flex-grow text-left text-sm', {
            'text-text-primary-light dark:text-text-primary-dark font-semibold': isOpen,
            'text-text-secondary-light dark:text-text-secondary-dark': !isOpen,
          })}
        >
          {label}
        </span>
        <Icon
          className={classNames({
            'text-icon-primary-light dark:text-icon-secondary-dark': isOpen,
            'text-icon-secondary-light dark:text-icon-primary-dark group-hover:text-icon-primary-light dark:group-hover:text-icon-secondary-dark': !isOpen,
          })}
          size={16}
          weight="bold"
        />
      </button>
      <Collapse isOpen={isOpen}>
        <div className="p-4 pt-0">{children}</div>
      </Collapse>
    </section>
  )
}
