/*---- External -------------------------------------------------------------*/

import { Item } from 'canvas-shared/lib/types/Item.types'

/*---- Qualdesk -------------------------------------------------------------*/

import { BoardFeatureId as JiraBoardFeatureId } from 'canvas-shared/lib/types/connections/JiraAPI.types'

/*---------------------------------------------------------------------------*/

export const isJiraBoardFeatureEnabledForItem = (
  item: Item.JiraCard,
  feature: JiraBoardFeatureId
) => item.data?.jira?.context?.boardFeatures?.includes(feature)
