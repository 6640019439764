/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'
import { createCachedSelector } from 're-reselect'

/*---- Qualdesk -------------------------------------------------------------*/

import { isVoteableItem } from 'canvas-shared/lib/helpers/itemTypes'

import { Item } from 'canvas-shared/lib/types/Item.types'
import { UUID } from 'canvas-shared/lib/types'
import { RootState } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

export const getItems = (state: RootState) => state.board.items.items
export const getItemsData = (state: RootState) => state.board.items.items.data
export const getItemsArray = createSelector(getItemsData, (itemsData) =>
  Object.values(itemsData)
)

export const itemsHaveVotesSelector = createSelector(
  getItemsArray,
  (items) =>
    items.some(
      (item) => isVoteableItem(item) && (item.data.votes?.length || 0) > 0
    ) ?? false
)

export const itemsHaveEmojisSelector = createSelector(
  getItemsArray,
  (items) =>
    items.some(
      (item) =>
        isVoteableItem(item) &&
        item.data.emoji &&
        Object.values(item.data.emoji).some((v) => v.length > 0)
    ) ?? false
)

export const itemsHaveEstimateVotesSelector = createSelector(
  getItemsArray,
  (items) =>
    items.some(
      (item) =>
        (item.data as Item.EstimateData).estimateVotes &&
        Object.values((item.data as Item.EstimateData).estimateVotes as any)
          .length > 0
    ) ?? false
)

export const itemIdsSelector = createSelector(getItemsData, (itemsData) =>
  Object.keys(itemsData)
)

export const selectItemsLoaded = (state: RootState) =>
  state.board.items.items.loaded

export const getItemId = (state: RootState, itemId: UUID) => itemId

export const getItem = createSelector(
  getItemId,
  getItemsData,
  (itemId, itemsData) => itemsData[itemId]
)

export const itemSelector = createCachedSelector(
  getItem,
  (item) => item
)((state, itemId) => itemId)
