/*---- External -------------------------------------------------------------*/

import React, { createContext, useRef } from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import ensureContextValue from '../helpers/ensureContextValue'

/*---------------------------------------------------------------------------*/

export interface CanvasWindow {
  canvasPageRef: React.RefObject<HTMLDivElement>
  canvasViewControlsRef: React.RefObject<HTMLDivElement>
  canvasRef: React.RefObject<HTMLDivElement>
}

export const CanvasWindowProvider: React.FC = ({ children }) => {
  const canvasRef = useRef<HTMLDivElement>(null)
  const canvasPageRef = useRef<HTMLDivElement>(null)
  const canvasViewControlsRef = useRef<HTMLDivElement>(null)

  const value = {
    canvasRef,
    canvasPageRef,
    canvasViewControlsRef,
  }

  return (
    <CanvasWindowContext.Provider value={value}>
      <div className="flex flex-col h-screen">{children}</div>
    </CanvasWindowContext.Provider>
  )
}

const CanvasWindowContext = createContext<Partial<CanvasWindow>>({})
export const useCanvasWindowContext = ensureContextValue<CanvasWindow>(
  CanvasWindowContext,
  'useCanvasWindowContext'
)
