/*---- External -------------------------------------------------------------*/

import { IMenuDividerProps, IMenuItemProps } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { NO_MENU_ITEMS } from '../constants/menus'

/*---------------------------------------------------------------------------*/

export const insertMenuItemDividers = (
  sections: Array<IMenuDividerProps | IMenuItemProps>[]
) => {
  const sectionsWithItems = sections.filter((s) => s.length > 0)

  return sectionsWithItems.reduce(
    (res: Array<IMenuDividerProps | IMenuItemProps>, s, i) => {
      if (i < sectionsWithItems.length - 1) {
        const firstItemOfNextSection = sectionsWithItems[i + 1][0]
        if (!firstItemOfNextSection.hasOwnProperty('text')) {
          // It’s a divider
          res = res.concat(s)
        } else {
          res = res.concat([...s, {} as IMenuDividerProps])
        }
      } else {
        res = res.concat(s)
      }
      return res
    },
    NO_MENU_ITEMS
  )
}
