/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

export const DEFAULT_LINE_THICKNESS = 6

export const MIN_LINE_THICKNESS = 1
export const MAX_LINE_THICKNESS = 100
