/*---- External -------------------------------------------------------------*/

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useFirestore } from 'react-redux-firebase'

import {
  Checkbox,
  FormGroup,
  HTMLSelect,
  IOptionProps,
  Switch,
} from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { useContainerId } from '../../../contexts/Container'
import { selectMyUid } from '../../../redux/selectors/auth/auth'
import { generateOperation } from '../../../redux/actions/helpers/generateOperation'

import { getGrid } from 'canvas-shared/lib/helpers/google/getGrid'
import { spreadsheetColumnLetter } from 'canvas-shared/lib/helpers/spreadsheetColumnLetter'

import { containerConnectionPath } from 'canvas-shared/lib/paths/containerConnectionPath'

import { GoogleSheetsSettingsPopoverProps } from './GoogleSheetsSettingsPopover'

import {
  GoogleSheetOptions,
  GoogleSheetsColumn,
} from 'canvas-shared/lib/types/connections/Google.types'

/*---------------------------------------------------------------------------*/

export const GoogleSheetsDataSettings: React.FC<GoogleSheetsSettingsPopoverProps> = ({
  connection,
  grid,
  sheets,
}) => {
  const firestore = useFirestore()
  const containerId = useContainerId()
  const uid = useSelector(selectMyUid)

  const connectionDocRef = firestore.doc(
    containerConnectionPath(containerId, connection.id)
  )

  const sheetsMenuOptions = useMemo(
    () =>
      sheets.reduce((options: IOptionProps[], sheet) => {
        if (
          sheet.properties?.sheetId !== undefined &&
          sheet.properties?.sheetId !== null &&
          sheet.properties?.title
        ) {
          return options.concat({
            value: sheet.properties.sheetId,
            label: sheet.properties.title,
          })
        }
        return options
      }, []),
    [sheets]
  )

  const operation = generateOperation(uid)

  const timestamp = {
    operation,
    updatedBy: operation,
    updatedAt: firestore.FieldValue.serverTimestamp(),
  }

  const changeSheet = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSheetId = Number.parseInt(e.target.value)

    const existingOptions = connection.google.options.sheetOptions[
      newSheetId
    ] as GoogleSheetOptions | undefined

    const headerRow =
      existingOptions?.headerRow !== undefined
        ? existingOptions.headerRow
        : true

    const grid = getGrid(sheets, newSheetId, headerRow)

    if (!!grid) {
      const columns = grid.columnHeaders.map((header, i) => ({
        header,
        visible:
          existingOptions?.columns[i]?.visible !== undefined
            ? existingOptions.columns[i].visible
            : true,
      }))

      connectionDocRef.update({
        'google.options.selectedSheetId': newSheetId,
        [`google.options.sheetOptions.${newSheetId}.sheetId`]: newSheetId,
        [`google.options.sheetOptions.${newSheetId}.columns`]: columns,
        [`google.options.sheetOptions.${newSheetId}.sheetName`]: grid.sheetName,
        [`google.options.sheetOptions.${newSheetId}.headerRow`]: headerRow,
        ...timestamp,
      })
    }
  }

  const sheetOptions =
    connection.google.options.sheetOptions[
      connection.google.options.selectedSheetId
    ]

  const toggleColumn = (columnId: number, visible: boolean) => {
    if (
      sheetOptions.columns.filter((c) => c.visible).length > 1 ||
      visible === true
    ) {
      const newColumns = sheetOptions.columns.reduce(
        (res: GoogleSheetsColumn[], col, i) => {
          if (i === columnId) {
            return res.concat({
              ...col,
              visible,
            })
          }

          return res.concat(col)
        },
        []
      )

      connectionDocRef.update({
        [`google.options.sheetOptions.${connection.google.options.selectedSheetId}.columns`]: newColumns,
        ...timestamp,
      })
    }
  }

  const toggleHeaderRow = (e: React.ChangeEvent<HTMLInputElement>) => {
    connectionDocRef.update({
      [`google.options.sheetOptions.${connection.google.options.selectedSheetId}.headerRow`]: e
        .target.checked,
      ...timestamp,
    })
  }

  return (
    <>
      <div className="bg-background-four-light dark:bg-background-four-dark border-border-weak-light dark:border-border-weak-dark max-h-vh-85 flex flex-col border-t overflow-y-auto">
        <div className="p-4">
          <FormGroup
            label={<span className="font-semibold">Select sheet to sync</span>}
            labelFor="sheet-input"
          >
            <HTMLSelect
              fill
              onChange={changeSheet}
              options={sheetsMenuOptions}
              value={connection.google.options.selectedSheetId}
            />
          </FormGroup>
          <FormGroup
            className="mb-0"
            label={
              <span className="font-semibold">Select columns to sync</span>
            }
          >
            {sheetOptions.columns.map(({ header, visible }, i) => {
              const columnKey = spreadsheetColumnLetter(i)
              const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                toggleColumn(i, e.target.checked)
              }
              return (
                <Checkbox
                  className="flex items-center my-3"
                  checked={visible}
                  key={i}
                  labelElement={
                    <span className="flex flex-grow items-center justify-between -mt-0.5">
                      <span>{header}</span>
                      <span className="text-text-secondary-light dark:text-text-secondary-dark">
                        Column {columnKey}
                      </span>
                    </span>
                  }
                  onChange={handleChange}
                />
              )
            })}
            <Switch
              className="mt-4"
              checked={sheetOptions.headerRow}
              label="Remove header row"
              onChange={toggleHeaderRow}
            />
          </FormGroup>
        </div>
      </div>
    </>
  )
}
