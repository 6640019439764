/*---- External -------------------------------------------------------------*/

import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Menu, MenuItem, MenuDivider } from '@blueprintjs/core'
import { Classes, Popover2 } from '@blueprintjs/popover2'

import { RemoveScroll } from 'react-remove-scroll'

/*---- Qualdesk -------------------------------------------------------------*/

import { NO_MENU_ITEMS } from '../../constants/menus'

import { insertMenuItemDividers } from '../../helpers/insertMenuItemDividers'

import { closeContextMenuAction } from '../../redux/actionCreators/contextMenu'

import { useAlignmentMenuItems } from '../../hooks/board/rightClickMenu/useAlignmentMenuItems'
import { useConnectionMenuItems } from '../../hooks/board/rightClickMenu/useConnectionMenuItems'
import { useDataMenuItems } from '../../hooks/board/rightClickMenu/useDataMenuItems'
import { useDuplicateMenuItems } from '../../hooks/board/rightClickMenu/useDuplicateMenuItems'
import { useEstimateMenuItems } from '../../hooks/board/rightClickMenu/useEstimateMenuItems'
import { useGroupMenuItems } from '../../hooks/board/rightClickMenu/useGroupMenuItems'
import { useTrashMenuItems } from '../../hooks/board/rightClickMenu/useTrashMenuItems'
import { useZIndexMenuItems } from '../../hooks/board/rightClickMenu/useZIndexMenuItems'

import { RootState } from '../../types/redux'

/*---------------------------------------------------------------------------*/

const CanvasContextMenu: React.FC = () => {
  const actionItems = useSelector(
    (state: RootState) => state.board.contextMenu.actionItems
  )

  if (actionItems.length === 0) {
    return null
  }

  return <CanvasContextMenuWithItems />
}

export const CanvasContextMenuWithItems: React.FC = () => {
  const dispatch = useDispatch()

  const contextMenuCoords = useSelector(
    (state: RootState) => state.board.contextMenu.coords
  )
  const actionItems = useSelector(
    (state: RootState) => state.board.contextMenu.actionItems
  )
  const actionItemsPositions = useSelector(
    (state: RootState) => state.board.contextMenu.actionItemsPositions
  )
  const actionGroup = useSelector(
    (state: RootState) => state.board.contextMenu.actionGroup
  )

  const connectionMenuItems = useConnectionMenuItems(actionItems)
  const dataMenuItems = useDataMenuItems(actionItems, actionGroup)
  const estimateMenuItems = useEstimateMenuItems(actionItems)
  const duplicateMenuItems = useDuplicateMenuItems(actionItems)
  const alignmentMenuItems = useAlignmentMenuItems(actionItemsPositions)

  const groupMenuItems = useGroupMenuItems(actionItems)
  const trashMenuItems = useTrashMenuItems(actionItems)
  const zIndexMenuItems = useZIndexMenuItems(actionItems)

  const targetStyle: React.CSSProperties = useMemo(
    () =>
      contextMenuCoords
        ? {
            position: 'absolute',
            left: 0,
            top: 0,
            transform: `translate(${contextMenuCoords.clientX}px, ${contextMenuCoords.clientY}px)`,
            zIndex: 10000,
          }
        : {},
    [contextMenuCoords]
  )

  const contextMenuItems = useMemo(() => {
    if (actionItems.length === 0) {
      return NO_MENU_ITEMS
    }

    return insertMenuItemDividers([
      connectionMenuItems,
      dataMenuItems,
      estimateMenuItems,
      duplicateMenuItems,
      alignmentMenuItems,
      groupMenuItems,
      zIndexMenuItems,
      trashMenuItems,
    ])
  }, [
    actionItems.length,
    alignmentMenuItems,
    connectionMenuItems,
    dataMenuItems,
    duplicateMenuItems,
    estimateMenuItems,
    groupMenuItems,
    trashMenuItems,
    zIndexMenuItems,
  ])

  if (contextMenuItems.length === 0) {
    return null
  }

  const closeContextMenu = () => {
    dispatch(closeContextMenuAction())
  }

  return (
    <RemoveScroll>
      <div style={targetStyle}>
        <Popover2
          autoFocus={false}
          enforceFocus={false}
          isOpen={!!contextMenuCoords}
          minimal
          onClose={closeContextMenu}
          placement="right-start"
          content={
            <Menu>
              {contextMenuItems.map((menuItem, i) =>
                'text' in menuItem ? (
                  <MenuItem
                    {...menuItem}
                    className={Classes.POPOVER2_DISMISS}
                    key={i}
                  />
                ) : (
                  <MenuDivider {...menuItem} key={i} />
                )
              )}
            </Menu>
          }
        >
          <span />
        </Popover2>
      </div>
    </RemoveScroll>
  )
}

export default CanvasContextMenu
