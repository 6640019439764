/*---- External -------------------------------------------------------------*/

import { useEffect } from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { useItemEditsActions } from '../redux/actions/board/useItemEditsActions'
import { useItemEdits } from '../redux/selectorHooks/board/useItemEdits'

import type { UUID } from '../types'

/*---------------------------------------------------------------------------*/

interface TitleStreamArguments {
  id: UUID
  title: string
  setTitle: React.Dispatch<React.SetStateAction<string>>
  editing: boolean
}

const useTitleStream = ({
  id,
  title,
  setTitle,
  editing,
}: TitleStreamArguments) => {
  const edits = useItemEdits(id)
  const { streamItemEdits } = useItemEditsActions(id)

  useEffect(() => {
    if (editing) {
      streamItemEdits({ title })
    }
  }, [editing, streamItemEdits, title])

  const receivedTitle = edits?.title

  const overwriteTitle = () => {
    if (!!receivedTitle || receivedTitle === '') {
      setTitle(receivedTitle)
    }
  }

  useEffect(overwriteTitle, [receivedTitle, setTitle])
}

export default useTitleStream
