/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { MAX_ZOOM_LEVEL, MIN_ZOOM_LEVEL } from '../../../config/canvas'
import { zoomInLevel, zoomOutLevel } from '../../../helpers/zoom'

import { RootState } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

export const selectZoomLevel = (state: RootState) =>
  state.board.session.data.zoomLevel

export const selectZoomScale = createSelector(
  selectZoomLevel,
  (zoomLevel) => 1 / zoomLevel
)

export const selectCanZoomIn = createSelector(
  selectZoomLevel,
  (zoomLevel) => zoomInLevel(zoomLevel) <= MAX_ZOOM_LEVEL
)
export const selectCanZoomOut = createSelector(
  selectZoomLevel,
  (zoomLevel) => zoomOutLevel(zoomLevel) >= MIN_ZOOM_LEVEL
)
