/*---- External -------------------------------------------------------------*/

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: React.ReactNode
  inputClassName?: string
  containerClassName?: string
  rightContent?: React.ReactNode
}

export const Input: React.FC<InputProps> = ({
  label,
  className,
  inputClassName,
  containerClassName,
  rightContent,
  ...inputProps
}) => (
  <label className={classNames('block', className)}>
    {label && (
      <div className="text-text-primary-light dark:text-text-primary-dark mb-2">
        {label}
      </div>
    )}
    <div
      className={classNames(
        'ring-border-normal-light dark:ring-border-normal-dark bg-background-four-light dark:bg-background-four-dark focus-within:ring-button-primary-light dark:focus-within:ring-button-success-dark text-text-primary-light dark:text-text-primary-dark flex items-center p-4 w-full h-14 rounded-lg ring-1 focus-within:ring-2 ring-inset',
        containerClassName
      )}
    >
      <input
        className={classNames(
          'flex-grow w-full bg-transparent outline-none',
          inputClassName
        )}
        {...inputProps}
      />
      {rightContent && <span className="flex-shrink-0">{rightContent}</span>}
    </div>
  </label>
)
