/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import {
  ScrollDimensions,
  ScrollOffsets,
} from 'canvas-shared/lib/types/scrollAndPosition.types'

/*---------------------------------------------------------------------------*/

interface ScrollDimensionsAndOffsetsArgs {
  canvasRefCurrent: HTMLDivElement | null
  canvasPageRefCurrent: HTMLDivElement | null
}

interface ScrollDimensionsAndOffsets {
  newScrollDimensions: ScrollDimensions
  newScrollOffsets: ScrollOffsets
}

export const getScrollDimensionsAndOffsetsFromRefs = ({
  canvasRefCurrent,
  canvasPageRefCurrent,
}: ScrollDimensionsAndOffsetsArgs): ScrollDimensionsAndOffsets => {
  const {
    clientHeight,
    clientWidth,
    scrollLeft,
    scrollTop,
    offsetLeft: canvasLeft,
    offsetTop: canvasTop,
  } = canvasRefCurrent || DEFAULT_CANVAS

  const {
    scrollHeight,
    scrollWidth,
    offsetLeft: pageLeft,
    offsetTop: pageTop,
  } = canvasPageRefCurrent || DEFAULT_CANVAS_PAGE

  const newScrollDimensions = {
    clientHeight,
    clientWidth,
    scrollHeight,
    scrollWidth,
    canvasLeft,
    pageLeft,
    canvasTop,
    pageTop,
    offsetLeft: canvasLeft + pageLeft,
    offsetTop: canvasTop + pageTop,
  }
  const newScrollOffsets = {
    scrollLeft,
    scrollTop,
  }

  return {
    newScrollDimensions,
    newScrollOffsets,
  }
}

const DEFAULT_CANVAS = {
  clientHeight: 0,
  clientWidth: 0,
  scrollLeft: 0,
  scrollTop: 0,
  offsetLeft: 0,
  offsetTop: 0,
}

const DEFAULT_CANVAS_PAGE = {
  scrollHeight: 0,
  scrollWidth: 0,
  offsetLeft: 0,
  offsetTop: 0,
}
