/*---- External -------------------------------------------------------------*/

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { userSelector } from '../../selectors/board/users'

import { UUID } from 'canvas-shared/lib/types'
import { RootState } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

export const useUser = (userId: UUID | undefined) =>
  useSelector((state: RootState) => userSelector(state, userId))
