/*---- External -------------------------------------------------------------*/

import { createAction, createAsyncThunk, nanoid } from '@reduxjs/toolkit'
import uniq from 'lodash.uniq'

/*---- Qualdesk -------------------------------------------------------------*/

import { app } from '../../config/firebase'

import { randomColor } from 'canvas-shared/lib/helpers/randomColor'

import { selectMyUid } from '../selectors/auth/auth'
import { selectGroups } from '../selectors/board/groups'

import { generateOperation } from '../actions/helpers/generateOperation'

import { UUID } from 'canvas-shared/lib/types'
import { ColorKey } from 'canvas-shared/lib/types/color.types'
import { Item, ItemType } from 'canvas-shared/lib/types/Item.types'
import { WithTimestamps } from 'canvas-shared/lib/types/timestamps.types'
import { KeyValuePair } from 'canvas-shared/lib/types/utilities.types'
import { ThunkAPI } from '../../types/redux'

/*---------------------------------------------------------------------------*/

export const dynamicItemsDatabaseSetOneAction = createAction<
  KeyValuePair<UUID, Item.DynamicItem>
>('dynamicItems/database/setOne')
export const dynamicItemsDatabaseLoadedAction = createAction(
  'dynamicItems/database/loaded'
)
export const dynamicItemsDatabaseRemoveOneAction = createAction<UUID>(
  'dynamicItems/database/removeOne'
)

export const groupItemsAction = createAsyncThunk<
  { add: Item.CanvasGroup; remove: Item.CanvasGroup[] },
  Item.BaseItem[],
  ThunkAPI
>('dynamicItems/group/addItems', (itemsToGroup, { getState }) => {
  const state = getState()
  const groups = selectGroups(state)
  const uid = selectMyUid(state)

  const oldGroups = itemsToGroup
    .map((i) => groups?.find((g) => g.memberIds.includes(i.id)))
    .filter((g) => g !== undefined) as Item.CanvasGroup[]
  const oldGroupMemberIds = uniq(oldGroups.map((g) => g.memberIds).flat())

  const memberIds = uniq(
    itemsToGroup.map((item) => item.id).concat(oldGroupMemberIds)
  )
  const newGroupId = nanoid()

  const color =
    oldGroups.length > 0 && oldGroups[0].data.color
      ? oldGroups[0].data.color
      : (randomColor() as ColorKey)

  const operation = generateOperation(uid)

  const newGroup: WithTimestamps<Item.CanvasGroup> = {
    id: newGroupId,
    type: ItemType.Group,
    data: { color },
    memberIds,
    createdAt: app.firestore.Timestamp.now(),
    createdBy: operation,
    updatedAt: app.firestore.Timestamp.now(),
    updatedBy: operation,
  }

  return { add: newGroup, remove: oldGroups }
})

export const removeItemsFromGroupAction = createAction<{
  itemIds: UUID[]
  group: Item.CanvasGroup
}>('dynamicItems/group/removeItems')
export const updateGroupColorAction = createAction<{
  groupId: UUID
  color: ColorKey
}>('dynamicItems/group/updateColor')
