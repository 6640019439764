/*---- External -------------------------------------------------------------*/

import React from 'react'

import classNames from 'classnames'

import { MagnifyingGlass } from 'phosphor-react'

import { Button, Tooltip } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'

/*---- Qualdesk -------------------------------------------------------------*/

import { HOTKEY_SEARCH } from '../../../config/hotkeys'

import { useSearchContext } from '../../../contexts/Search'
import { useClassNamesWithDarkMode } from '../../../hooks/darkMode/useClassNamesWithDarkMode'

import { SearchBox } from '../../search/SearchBox'
import { SearchResults } from '../../search/SearchResults'
import { TooltipContent } from './TooltipContent'

/*---------------------------------------------------------------------------*/

export const SearchButton: React.FC = () => {
  const { updateQuery, isOpen, setIsOpen } = useSearchContext()
  const { classNamesWithDarkMode } = useClassNamesWithDarkMode()

  return (
    <Popover2
      isOpen={isOpen}
      onClose={() => {
        updateQuery(undefined)
        setIsOpen(false)
      }}
      placement="bottom-start"
      minimal
      content={
        <div
          className={classNamesWithDarkMode(
            'max-h-vh-75 flex flex-col p-4 w-96 overflow-y-hidden'
          )}
        >
          <SearchBox />
          <div className="mt-4 overflow-y-scroll">
            <SearchResults />
          </div>
        </div>
      }
    >
      <Tooltip
        popoverClassName="tooltip"
        content={<TooltipContent title="Find" hotkey={HOTKEY_SEARCH} />}
        hoverOpenDelay={300}
      >
        <Button
          className={classNames(
            { 'text-icon-accent-light dark:text-icon-accent-dark': isOpen },
            'px-2 w-10'
          )}
          icon={
            <MagnifyingGlass
              size={24}
              weight={isOpen ? 'duotone' : undefined}
              className="toolbar-icon bp3-icon"
            />
          }
          minimal
          onClick={() => setIsOpen(!isOpen)}
          aria-label="Find"
        />
      </Tooltip>
    </Popover2>
  )
}
