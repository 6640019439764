/*---- External -------------------------------------------------------------*/

import React, { useEffect } from 'react'

import { RedirectProps, Redirect } from 'react-router-dom'

/*---- Qualdesk -------------------------------------------------------------*/

import { useRedirectPath } from '../hooks/router/useRedirectPath'

/*---------------------------------------------------------------------------*/

const RedirectWithReturn: React.FC<RedirectProps> = (props) => {
  const { from } = props
  const [redirectPath, setRedirectPath] = useRedirectPath()

  useEffect(() => {
    if (from && from !== redirectPath) {
      setRedirectPath(from)
    }
  })

  return <Redirect {...props} />
}

export default RedirectWithReturn
