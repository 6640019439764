/*---- External -------------------------------------------------------------*/

import { combineEpics } from 'redux-observable'

/*---- Qualdesk -------------------------------------------------------------*/

import { app } from '../../config/firebase'

import { generateItemPath } from '../paths/itemsPaths'

import {
  itemsAddEmojiAction,
  itemsAddEstimateVoteAction,
  itemsAddTextStyleAction,
  itemsAddVoteAction,
  itemsClearAllEmojisAction,
  itemsClearAllEstimateVotesAction,
  itemsClearAllVotesAction,
  itemsClearEmojisAction,
  itemsClearEstimatesAction,
  itemsClearVotesAction,
  itemSetColumnAction,
  itemSetTitleAction,
  itemsRemoveEmojiAction,
  itemsRemoveEstimateVoteAction,
  itemsRemoveTextStyleAction,
  itemsRemoveVoteAction,
  itemsResolveEstimateAction,
  itemsSetBackgroundColorAction,
  itemsSetLineColorAction,
  itemsSetLineThicknessAction,
  itemsSetTextAlignmentAction,
  itemsSetTextColorAction,
  itemsSetTextSizeAction,
} from '../actionCreators/itemData'

import { createFirestoreMultiItemUpdateEpic } from './helpers/createFirestoreMultiItemUpdateEpic'
import { createFirestoreEpic } from './helpers/createFirestoreEpic'

import { EstimateType } from 'canvas-shared/lib/types/item/estimate.types'
import { itemIdsSelector } from '../selectors/board/items'

/*---------------------------------------------------------------------------*/

const itemSetTitleEpic = createFirestoreEpic(
  itemSetTitleAction.fulfilled,
  ({
    content: { itemId, title, updatedAt, updatedBy },
    containerId,
    firestore,
  }) =>
    firestore().doc(generateItemPath(containerId, itemId)).update({
      'data.title': title,
      updatedAt,
      updatedBy,
    })
)

const itemSetColumnEpic = createFirestoreEpic(
  itemSetColumnAction.fulfilled,
  ({
    content: { column, itemId, text, updatedAt, updatedBy },
    containerId,
    firestore,
  }) =>
    firestore()
      .doc(generateItemPath(containerId, itemId))
      .update({
        [`data.columns.${column}`]: text,
        updatedAt,
        updatedBy,
      })
)

const itemsSetBackgroundColorEpic = createFirestoreMultiItemUpdateEpic(
  itemsSetBackgroundColorAction.fulfilled,
  ({ color }) => ({ 'data.backgroundColor': color })
)

const itemsSetLineColorEpic = createFirestoreMultiItemUpdateEpic(
  itemsSetLineColorAction.fulfilled,
  ({ color }) => ({ 'data.lineColor': color })
)

const itemsSetLineThicknessEpic = createFirestoreMultiItemUpdateEpic(
  itemsSetLineThicknessAction.fulfilled,
  ({ thickness }) => ({ 'data.lineThickness': thickness })
)

const itemsSetTextAlignmentEpic = createFirestoreMultiItemUpdateEpic(
  itemsSetTextAlignmentAction.fulfilled,
  ({ alignment }) => ({ 'data.textAlignment': alignment })
)

const itemsSetTextSizeEpic = createFirestoreMultiItemUpdateEpic(
  itemsSetTextSizeAction.fulfilled,
  ({ size }) => ({ 'data.textSize': size })
)

const itemsSetTextColorEpic = createFirestoreMultiItemUpdateEpic(
  itemsSetTextColorAction.fulfilled,
  ({ color }) => ({ 'data.textColor': color })
)

const itemsAddTextStyleEpic = createFirestoreMultiItemUpdateEpic(
  itemsAddTextStyleAction.fulfilled,
  ({ style }) => ({
    'data.textStyle': app.firestore.FieldValue.arrayUnion(style),
  })
)

const itemsRemoveTextStyleEpic = createFirestoreMultiItemUpdateEpic(
  itemsRemoveTextStyleAction.fulfilled,
  ({ style }) => ({
    'data.textStyle': app.firestore.FieldValue.arrayRemove(style),
  })
)

const itemsAddEstimateVoteEpic = createFirestoreMultiItemUpdateEpic(
  itemsAddEstimateVoteAction.fulfilled,
  ({ updatedBy: { uid }, value }) => ({
    [`data.estimateVotes.${uid}`]: {
      estimateType: EstimateType.STORY_POINTS,
      value,
    },
  })
)

const itemsRemoveEstimateVoteEpic = createFirestoreMultiItemUpdateEpic(
  itemsRemoveEstimateVoteAction.fulfilled,
  ({ updatedBy: { uid } }) => ({
    [`data.estimateVotes.${uid}`]: app.firestore.FieldValue.delete(),
  })
)

const itemsResolveEstimateEpic = createFirestoreMultiItemUpdateEpic(
  itemsResolveEstimateAction.fulfilled,
  ({ value }) => ({
    'data.estimate': {
      estimateType: EstimateType.STORY_POINTS,
      value,
    },
    'data.estimateVotes': app.firestore.FieldValue.delete(),
  })
)

const itemsClearAllEstimateVotesEpic = createFirestoreEpic(
  itemsClearAllEstimateVotesAction.fulfilled,
  ({ content: { updatedAt, updatedBy }, containerId, firestore, state }) => {
    const batch = firestore().batch()

    const itemIds = itemIdsSelector(state)

    itemIds.forEach((itemId) => {
      batch.update(firestore().doc(generateItemPath(containerId, itemId)), {
        'data.estimateVotes': app.firestore.FieldValue.delete(),
        updatedAt,
        updatedBy,
      })
    })

    return batch.commit()
  }
)

const itemsClearEstimatesEpic = createFirestoreMultiItemUpdateEpic(
  itemsClearEstimatesAction.fulfilled,
  () => ({
    'data.estimate': app.firestore.FieldValue.delete(),
  })
)

const itemsAddEmojiEpic = createFirestoreMultiItemUpdateEpic(
  itemsAddEmojiAction.fulfilled,
  ({ emojiId, updatedBy: { uid } }) => ({
    [`data.emoji.${emojiId}`]: app.firestore.FieldValue.arrayUnion(uid),
  })
)

const itemsRemoveEmojiEpic = createFirestoreMultiItemUpdateEpic(
  itemsRemoveEmojiAction.fulfilled,
  ({ emojiId, updatedBy: { uid } }) => ({
    [`data.emoji.${emojiId}`]: app.firestore.FieldValue.arrayRemove(uid),
  })
)

const itemsAddVoteEpic = createFirestoreMultiItemUpdateEpic(
  itemsAddVoteAction.fulfilled,
  ({ updatedBy: { uid } }) => ({
    'data.votes': app.firestore.FieldValue.arrayUnion(uid),
  })
)

const itemsRemoveVoteEpic = createFirestoreMultiItemUpdateEpic(
  itemsRemoveVoteAction.fulfilled,
  ({ updatedBy: { uid } }) => ({
    'data.votes': app.firestore.FieldValue.arrayRemove(uid),
  })
)

const itemsClearAllEmojisEpic = createFirestoreEpic(
  itemsClearAllEmojisAction.fulfilled,
  ({ content: { updatedAt, updatedBy }, containerId, firestore, state }) => {
    const batch = firestore().batch()

    const itemIds = itemIdsSelector(state)

    itemIds.forEach((itemId) => {
      batch.update(firestore().doc(generateItemPath(containerId, itemId)), {
        'data.emoji': app.firestore.FieldValue.delete(),
        updatedAt,
        updatedBy,
      })
    })

    return batch.commit()
  }
)

const itemsClearAllVotesEpic = createFirestoreEpic(
  itemsClearAllVotesAction.fulfilled,
  ({ content: { updatedAt, updatedBy }, containerId, firestore, state }) => {
    const batch = firestore().batch()

    const itemIds = itemIdsSelector(state)

    itemIds.forEach((itemId) => {
      batch.update(firestore().doc(generateItemPath(containerId, itemId)), {
        'data.votes': app.firestore.FieldValue.delete(),
        updatedAt,
        updatedBy,
      })
    })

    return batch.commit()
  }
)

const itemsClearEmojisEpic = createFirestoreMultiItemUpdateEpic(
  itemsClearEmojisAction.fulfilled,
  () => ({
    'data.emoji': app.firestore.FieldValue.delete(),
  })
)

const itemsClearVotesEpic = createFirestoreMultiItemUpdateEpic(
  itemsClearVotesAction.fulfilled,
  () => ({
    'data.votes': app.firestore.FieldValue.delete(),
  })
)

export const itemDataEpic = combineEpics(
  itemSetColumnEpic,
  itemSetTitleEpic,
  itemsSetBackgroundColorEpic,
  itemsSetLineColorEpic,
  itemsSetLineThicknessEpic,
  itemsSetTextAlignmentEpic,
  itemsSetTextSizeEpic,
  itemsSetTextColorEpic,
  itemsAddTextStyleEpic,
  itemsRemoveTextStyleEpic,
  itemsAddEstimateVoteEpic,
  itemsRemoveEstimateVoteEpic,
  itemsResolveEstimateEpic,
  itemsClearAllEstimateVotesEpic,
  itemsClearEstimatesEpic,
  itemsAddEmojiEpic,
  itemsRemoveEmojiEpic,
  itemsAddVoteEpic,
  itemsRemoveVoteEpic,
  itemsClearAllEmojisEpic,
  itemsClearAllVotesEpic,
  itemsClearEmojisEpic,
  itemsClearVotesEpic
)
