/*---- External -------------------------------------------------------------*/

import * as Sentry from '@sentry/react'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

export const sendSuperficialError = (error: Error, message?: string) => {
  Sentry.captureMessage(
    `${error.name}: ${message}${error.message ? ` (${error.message})` : ''}`
  )
  console.error(error)
}
