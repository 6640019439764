/*---- External -------------------------------------------------------------*/

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { orgNameSelector } from '../../redux/selectors/auth/org'
import { randomUserRoleMapSelector } from '../../redux/selectors/users/users'

import { UserGallery } from '../user/UserGallery'

import { Team } from 'canvas-shared/lib/types/Team.types'

/*---------------------------------------------------------------------------*/

export const WorkspaceTeamMembers: React.FC<Team> = (team) => {
  const orgName = useSelector(orgNameSelector)
  const randomUserRoles = useSelector(randomUserRoleMapSelector)

  const teamMemberCount = useMemo(
    () => Object.keys(team.roles.users || {}).length,
    [team.roles.users]
  )

  const roles = team.everyone ? randomUserRoles : team.roles
  const label = team.everyone
    ? `Everyone at ${orgName} has access to this team`
    : `${teamMemberCount} member${teamMemberCount > 1 ? 's' : ''}`

  return (
    <div className="flex items-center space-x-2">
      <UserGallery limit={5} roles={roles} userIconClassName="w-5 h-5" />
      <div className="text-text-secondary-light dark:text-text-secondary-dark text-xs font-light">
        {label}
      </div>
    </div>
  )
}
