/*---- External -------------------------------------------------------------*/

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { timersSelector } from '../../selectors/board/timers'

import { Timer } from '../../../types'

/*---------------------------------------------------------------------------*/

export const useTimer = (): Timer | undefined => useSelector(timersSelector)
