/*---- External -------------------------------------------------------------*/

import { createReducer } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { ORG_ID } from '../../config/org'

import { setOrgAction, setOrgLoadedAction } from '../actionCreators/org'

/*---------------------------------------------------------------------------*/

const initialState = {
  loaded: false,
  data: { id: ORG_ID, name: ORG_ID },
}

export const orgReducer = createReducer(initialState, (builder) => {
  builder.addCase(setOrgAction, (state, action) => {
    state.loaded = true
    state.data = action.payload
  })
  builder.addCase(setOrgLoadedAction, (state, action) => {
    state.loaded = true
  })
})
