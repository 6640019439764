/*---- External -------------------------------------------------------------*/

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { UserCircleMinus } from 'phosphor-react'
import { Classes, Intent } from '@blueprintjs/core'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { useAreYouSureContext } from '../../../contexts/AreYouSure'
import { selectMyUid } from '../../../redux/selectors/auth/auth'

import { functions } from '../../../config/firebase'

import UserIcon from '../../user/UserIcon'
import UserName from '../../user/UserName'
import { UserEmail } from '../../user/UserEmail'

import { TeamPanelProps } from '../TeamSettings'
import { UUID } from 'canvas-shared/lib/types'
import { PermissionLevel } from 'canvas-shared/lib/types/permissions.types'
import { TeamUserLevel } from './TeamUserLevel'

/*---------------------------------------------------------------------------*/

const updateTeam = functions.httpsCallable('https-authUpdateTeam')

interface TeamUserPermissionProps extends TeamPanelProps {
  level: PermissionLevel
  userId: UUID
}

export const TeamUserPermission: React.FC<TeamUserPermissionProps> = ({
  queueUpdate,
  level,
  team,
  userId,
}) => {
  const uid = useSelector(selectMyUid)

  const disabled = useMemo(
    () => level >= PermissionLevel.CREATOR || userId === uid,
    [level, uid, userId]
  )

  const { showAlert } = useAreYouSureContext()

  const handleUpdate = (newLevel: PermissionLevel | null) => {
    const f = () =>
      updateTeam({
        teamId: team.id,
        update: {
          [`roles.users.${userId}`]: newLevel,
        },
      })

    if (queueUpdate) {
      queueUpdate(f)
    } else {
      f()
    }
  }

  const onRemove = () => {
    showAlert(
      () => {
        handleUpdate(null)
      },
      <>
        Are you sure you want to remove <UserName uid={userId} /> from{' '}
        {team.name}?
      </>,
      {
        confirmButtonText: 'Remove member',
        icon: (
          <div
            className={classNames(
              Classes.ICON,
              'text-icon-danger-light dark:text-icon-danger-dark'
            )}
          >
            <UserCircleMinus weight="duotone" />
          </div>
        ),
        intent: Intent.DANGER,
      }
    )
  }

  return (
    <div className="flex items-center">
      <UserIcon className="w-8 h-8" hideTooltip uid={userId} />
      <div className="flex flex-col ml-4">
        <UserName uid={userId} />
        <UserEmail
          className="text-text-secondary-light dark:text-text-secondary-dark text-xs"
          uid={userId}
        />
      </div>
      <TeamUserLevel
        disabled={disabled}
        level={level}
        onChange={handleUpdate}
        onRemove={onRemove}
      />
    </div>
  )
}
