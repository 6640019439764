/*---- External -------------------------------------------------------------*/

import React from 'react'
import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { selectSelectionRect } from '../../redux/selectors/board/session'
import convertToPx from '../../helpers/convertToPx'
import { selectZoomLevel } from '../../redux/selectors/board/zoom'
import { CANVAS_GUTTER } from '../../config/canvas'

/*---------------------------------------------------------------------------*/

const CanvasDragSelectionRect: React.FC = () => {
  const selectionRect = useSelector(selectSelectionRect)
  const zoomLevel = useSelector(selectZoomLevel)

  if (!selectionRect) {
    return null
  }

  const { startX, startY, endX, endY } = selectionRect
  const left = Math.min(startX, endX)
  const top = Math.min(startY, endY)
  const width = Math.abs(endX - startX)
  const height = Math.abs(endY - startY)

  return (
    <div
      className="selection-rect dark:bg-teal-400 dark:border-teal-400 absolute bg-blue-400 bg-opacity-25 dark:bg-opacity-25 border border-blue-400 pointer-events-none"
      style={{
        left: CANVAS_GUTTER,
        top: CANVAS_GUTTER,
        width: convertToPx(width * zoomLevel),
        height: convertToPx(height * zoomLevel),
        transform: `translate(${left * zoomLevel}px, ${top * zoomLevel}px)`,
      }}
    />
  )
}

export default CanvasDragSelectionRect
