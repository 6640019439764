/*---- External -------------------------------------------------------------*/

import React from 'react'
import { Redirect } from 'react-router-dom'

/*---- Qualdesk -------------------------------------------------------------*/

import { Routes } from '../../router/routes'

/*---------------------------------------------------------------------------*/

const Home: React.FC = () => <Redirect to={Routes.WORKSPACE_PERSONAL.path} />

export default Home
