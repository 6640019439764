/*---- External -------------------------------------------------------------*/

import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import classNames from 'classnames'

import { Button, IButtonProps } from '@blueprintjs/core'
import { CaretRight, Plus } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import jiraIcon from '../../assets/integrations/jira/jira-icon.svg'
import googleSheetsIcon from '../../assets/integrations/google/google-sheets-icon.svg'
import trelloIcon from '../../assets/integrations/trello/trello-icon.svg'
import linearIcon from '../../assets/integrations/linear/linear-icon.svg'

import { UserConnectionType } from 'canvas-shared/lib/types/UserConnection.types'

/*---------------------------------------------------------------------------*/

interface ConnectionButtonProps extends IButtonProps {
  connectionType: UserConnectionType
  connected?: boolean
}

export const ConnectionButton: React.FC<ConnectionButtonProps> = ({
  connectionType,
  connected = false,
  children,
  text,
  ...props
}) => {
  const { url } = useRouteMatch()
  const { push } = useHistory()

  return (
    <Button
      rightIcon={
        connected ? (
          <CaretRight
            className="text-icon-secondary-light dark:text-icon-secondary-dark"
            weight="bold"
          />
        ) : (
          <Plus
            className="text-icon-secondary-light dark:text-icon-secondary-dark"
            weight="bold"
          />
        )
      }
      onClick={() => {
        switch (connectionType) {
          case UserConnectionType.Jira:
            return push(`${url}/jira`)
          case UserConnectionType.Google:
            return push(`${url}/google`)
          case UserConnectionType.Trello:
            return push(`${url}/trello`)
          case UserConnectionType.Linear:
            return push(`${url}/linear`)
        }
      }}
      fill
      alignText="left"
      minimal
      className={classNames(
        'hover:bg-button-secondary-hovered-light dark:hover:bg-button-secondary-hovered-dark active:bg-button-secondary-active-light dark:active:bg-button-secondary-active-dark p-2.5',
        {
          'text-text-secondary-light dark:text-text-secondary-dark': !connected,
        }
      )}
      {...props}
    >
      <div className="flex items-center space-x-3">
        {((connectionType) => {
          switch (connectionType) {
            case UserConnectionType.Jira:
              return <img alt="Jira icon" className="w-5 h-5" src={jiraIcon} />
            case UserConnectionType.Google:
              return (
                <img
                  alt="Google Sheets icon"
                  className="w-5 h-5"
                  src={googleSheetsIcon}
                />
              )
            case UserConnectionType.Trello:
              return (
                <img alt="Trello icon" className="w-5 h-5" src={trelloIcon} />
              )
            case UserConnectionType.Linear:
              return (
                <img alt="Linear icon" className="w-5 h-5" src={linearIcon} />
              )
          }
        })(connectionType)}
        <span>{children || text}</span>
      </div>
    </Button>
  )
}
