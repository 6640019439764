/*---- External -------------------------------------------------------------*/

import { lazy, Suspense } from 'react'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { useDarkMode } from '../../hooks/darkMode/useDarkMode'

/*---------------------------------------------------------------------------*/

const Light = lazy(() => import('./Light'))
const Dark = lazy(() => import('./Dark'))

export const StyleLoader = () => {
  const darkMode = useDarkMode()

  const className = classNames('h-screen', 'w-screen', {
    'bg-background-app-dark': darkMode,
    'bg-background-app-light': !darkMode,
  })

  return (
    <Suspense fallback={<div className={className}></div>}>
      {darkMode ? <Dark /> : <Light />}
    </Suspense>
  )
}
