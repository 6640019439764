/*---- External -------------------------------------------------------------*/

import { useCallback, useMemo } from 'react'

import { useFirestore } from 'react-redux-firebase'

/*---- Qualdesk -------------------------------------------------------------*/

import { generateItemPath } from '../../../../paths/itemsPaths'
import { useContainerId } from '../../../../../contexts/Container'
import { useItemUpdateActions } from '../../helpers/useItemUpdateActions'

import { LinearBoardConnection } from 'canvas-shared/lib/types/BoardConnection.types'
import { Item, ItemType } from 'canvas-shared/lib/types/Item.types'
import { UUID } from 'canvas-shared/lib/types'
import {
  LinearCycle,
  LinearLabel,
  LinearPriority,
  LinearProject,
  LinearState,
  LinearUser,
} from 'canvas-shared/lib/types/connections/Linear.types'

/*---------------------------------------------------------------------------*/

interface ItemLinearActions {
  convertItemsToLinear: (
    items: Item.BaseItem[],
    connection: LinearBoardConnection
  ) => Promise<void>
  linearAddLabel: (ids: UUID[], label: LinearLabel) => Promise<void>
  linearAssignUser: (itemIds: UUID[], user: LinearUser) => Promise<void>
  linearChangeState: (itemIds: UUID[], state: LinearState) => Promise<void>
  linearRemoveLabel: (ids: UUID[], label: LinearLabel) => Promise<void>
  linearSetCycle: (ids: UUID[], cycle: LinearCycle) => Promise<void>
  linearSetPriority: (ids: UUID[], priority: LinearPriority) => Promise<void>
  linearSetProject: (ids: UUID[], project: LinearProject) => Promise<void>
  linearUnsetCycle: (ids: UUID[]) => Promise<void>
  linearUnsetProject: (ids: UUID[]) => Promise<void>
}

export const useItemLinearActions = (): ItemLinearActions => {
  const firestore = useFirestore()
  const containerId = useContainerId()

  const { generateItemUpdate } = useItemUpdateActions()

  const convertItemsToLinear = useCallback(
    async (items: Item.BaseItem[], connection: LinearBoardConnection) => {
      const batch = firestore.batch()
      items.forEach((item) => {
        const ref = firestore.doc(generateItemPath(containerId, item.id))
        batch.update(
          ref,
          generateItemUpdate({
            type: ItemType.LinearCard,
            loading: true,
            'data.linear': {
              team: connection.linear.team,
            },
          })
        )
      })
      await batch.commit()
    },
    [containerId, firestore, generateItemUpdate]
  )

  const linearAssignUser = useCallback(
    async (itemIds: UUID[], user: LinearUser) => {
      const { avatarUrl, displayName, email, id, name } = user
      const batch = firestore.batch()
      itemIds.forEach((itemId) => {
        const ref = firestore.doc(generateItemPath(containerId, itemId))
        batch.update(
          ref,
          generateItemUpdate({
            'data.linear.assignee': {
              avatarUrl,
              displayName,
              email,
              id,
              name,
            },
          })
        )
      })
      await batch.commit()
    },
    [containerId, firestore, generateItemUpdate]
  )

  const linearChangeState = useCallback(
    async (itemIds: UUID[], state: LinearState) => {
      const { color, id, name, position } = state
      const batch = firestore.batch()
      itemIds.forEach((itemId) => {
        const ref = firestore.doc(generateItemPath(containerId, itemId))
        batch.update(
          ref,
          generateItemUpdate({
            'data.linear.state': {
              color,
              id,
              name,
              position,
            },
          })
        )
      })
      await batch.commit()
    },
    [containerId, firestore, generateItemUpdate]
  )

  const linearSetCycle = useCallback(
    async (itemIds: UUID[], cycle: LinearCycle) => {
      const batch = firestore.batch()
      itemIds.forEach((itemId) => {
        const ref = firestore.doc(generateItemPath(containerId, itemId))
        batch.update(
          ref,
          generateItemUpdate({
            'data.linear.cycle': cycle,
          })
        )
      })
      await batch.commit()
    },
    [containerId, firestore, generateItemUpdate]
  )

  const linearUnsetCycle = useCallback(
    async (itemIds: UUID[]) => {
      const batch = firestore.batch()
      itemIds.forEach((itemId) => {
        const ref = firestore.doc(generateItemPath(containerId, itemId))
        batch.update(
          ref,
          generateItemUpdate({
            'data.linear.cycle': null,
          })
        )
      })
      await batch.commit()
    },
    [containerId, firestore, generateItemUpdate]
  )

  const linearSetPriority = useCallback(
    async (itemIds: UUID[], priority: LinearPriority) => {
      const batch = firestore.batch()
      itemIds.forEach((itemId) => {
        const ref = firestore.doc(generateItemPath(containerId, itemId))
        batch.update(
          ref,
          generateItemUpdate({
            'data.linear.priority': priority,
          })
        )
      })
      await batch.commit()
    },
    [containerId, firestore, generateItemUpdate]
  )

  const linearAddLabel = useCallback(
    async (itemIds: UUID[], label: LinearLabel) => {
      const batch = firestore.batch()
      itemIds.forEach((itemId) => {
        const ref = firestore.doc(generateItemPath(containerId, itemId))
        batch.update(
          ref,
          generateItemUpdate({
            'data.linear.labels.nodes': firestore.FieldValue.arrayUnion(label),
          })
        )
      })
      await batch.commit()
    },
    [containerId, firestore, generateItemUpdate]
  )

  const linearRemoveLabel = useCallback(
    async (itemIds: UUID[], label: LinearLabel) => {
      const batch = firestore.batch()
      itemIds.forEach((itemId) => {
        const ref = firestore.doc(generateItemPath(containerId, itemId))
        batch.update(
          ref,
          generateItemUpdate({
            'data.linear.labels.nodes': firestore.FieldValue.arrayRemove(label),
          })
        )
      })
      await batch.commit()
    },
    [containerId, firestore, generateItemUpdate]
  )

  const linearSetProject = useCallback(
    async (itemIds: UUID[], project: LinearProject) => {
      const batch = firestore.batch()
      itemIds.forEach((itemId) => {
        const ref = firestore.doc(generateItemPath(containerId, itemId))
        batch.update(
          ref,
          generateItemUpdate({
            'data.linear.project': project,
          })
        )
      })
      await batch.commit()
    },
    [containerId, firestore, generateItemUpdate]
  )

  const linearUnsetProject = useCallback(
    async (itemIds: UUID[]) => {
      const batch = firestore.batch()
      itemIds.forEach((itemId) => {
        const ref = firestore.doc(generateItemPath(containerId, itemId))
        batch.update(
          ref,
          generateItemUpdate({
            'data.linear.project': null,
          })
        )
      })
      await batch.commit()
    },
    [containerId, firestore, generateItemUpdate]
  )

  return useMemo(
    () => ({
      convertItemsToLinear,
      linearAddLabel,
      linearAssignUser,
      linearChangeState,
      linearRemoveLabel,
      linearSetCycle,
      linearSetPriority,
      linearSetProject,
      linearUnsetCycle,
      linearUnsetProject,
    }),
    [
      convertItemsToLinear,
      linearAddLabel,
      linearAssignUser,
      linearChangeState,
      linearRemoveLabel,
      linearSetCycle,
      linearSetPriority,
      linearSetProject,
      linearUnsetCycle,
      linearUnsetProject,
    ]
  )
}
