/*---- External -------------------------------------------------------------*/

import { createLocalStorageStateHook } from 'use-local-storage-state'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  defaultRectangleBackgroundColor,
  defaultStickyBackgroundColor,
  defaultTextColor,
  defaultLineColor,
} from 'canvas-shared/lib/config/colors'

import { defaultTextAlignment } from '../config/textAlignments'

import { defaultTextSize } from '../config/textSizes'
import { defaultTextStyle } from '../config/textStyles'

import { DEFAULT_LINE_THICKNESS } from '../config/lines'

import { ColorKey } from 'canvas-shared/lib/types/color.types'
import {
  TextAlignmentKey,
  TextStyleKey,
} from 'canvas-shared/lib/types/text.types'
import {
  ItemType,
  CreateableItemType,
} from 'canvas-shared/lib/types/Item.types'
import { DarkMode } from 'canvas-shared/lib/types/settings.types'

/*---------------------------------------------------------------------------*/

const useCardBackgroundColor = createLocalStorageStateHook<
  ColorKey | undefined
>('cardBackgroundColor', undefined)
const useRectangleBackgroundColor = createLocalStorageStateHook<ColorKey>(
  'rectangleBackgroundColor',
  defaultRectangleBackgroundColor.color
)
const useStickyBackgroundColor = createLocalStorageStateHook<ColorKey>(
  'stickyBackgroundColor',
  defaultStickyBackgroundColor.color
)

const useRectangleTextAlignment = createLocalStorageStateHook<TextAlignmentKey>(
  'rectangleTextAlignment',
  defaultTextAlignment.id
)
const useRectangleTextSize = createLocalStorageStateHook<number>(
  'rectangleTextSize',
  defaultTextSize
)
const useRectangleTextColor = createLocalStorageStateHook<ColorKey>(
  'rectangleTextColor',
  defaultTextColor.color
)
const useRectangleTextStyle = createLocalStorageStateHook<TextStyleKey[]>(
  'rectangleTextStyle',
  defaultTextStyle
)

const useTextAlignment = createLocalStorageStateHook<TextAlignmentKey>(
  'textAlignment',
  defaultTextAlignment.id
)
const useTextSize = createLocalStorageStateHook<number>(
  'textSize',
  defaultTextSize
)
const useTextColor = createLocalStorageStateHook<ColorKey>(
  'textColor',
  defaultTextColor.color
)
const useTextStyle = createLocalStorageStateHook<TextStyleKey[]>(
  'textStyle',
  defaultTextStyle
)

const useLineColor = createLocalStorageStateHook<ColorKey>(
  'lineColor',
  defaultLineColor.color
)
const useLineThickness = createLocalStorageStateHook<number>(
  'lineThickness',
  DEFAULT_LINE_THICKNESS
)

const useItemType = createLocalStorageStateHook<CreateableItemType>(
  'itemType',
  ItemType.Card
)
const useShapeItemType = createLocalStorageStateHook<CreateableItemType>(
  'shapeItemType',
  ItemType.Rectangle
)

const useShowCanvasNavigator = createLocalStorageStateHook<boolean>(
  'showCanvasNavigator',
  true
)

const useDarkModeSetting = createLocalStorageStateHook<DarkMode | undefined>(
  'darkModeSetting',
  undefined
)

const useLocalStorageSettings = () => ({
  useCardBackgroundColor,
  useRectangleBackgroundColor,
  useStickyBackgroundColor,
  useTextAlignment,
  useTextSize,
  useTextColor,
  useTextStyle,
  useRectangleTextAlignment,
  useRectangleTextSize,
  useRectangleTextColor,
  useRectangleTextStyle,
  useLineColor,
  useLineThickness,
  useItemType,
  useShapeItemType,
  useShowCanvasNavigator,
  useDarkModeSetting,
})

export default useLocalStorageSettings
