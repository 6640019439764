/*---- External -------------------------------------------------------------*/

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { itemIsSelectedSelector } from '../../selectors/board/selections'

import { UUID } from 'canvas-shared/lib/types'
import { RootState } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

export const useItemIsSelected = (itemId: UUID) =>
  useSelector((state: RootState) => itemIsSelectedSelector(state, itemId))
