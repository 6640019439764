/*---- External -------------------------------------------------------------*/

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  createGroupSelector,
  selectGroupIds,
} from '../../redux/selectors/board/groups'
import {
  selectRectPositionIds,
  selectLinePositionIds,
} from '../../redux/selectors/board/positions'
import { useScrollDimensions } from '../../redux/selectorHooks/board/useScrollDimensions'
import { useScrollOffsets } from '../../redux/selectorHooks/board/useScrollOffsets'
import { useItemPosition } from '../../redux/selectorHooks/board/useItemPosition'

import { scrollToAction } from '../../redux/actionCreators/scroll'

import { MINIMAP_WIDTH } from '../../config/canvas'

import { selectZoomScale } from '../../redux/selectors/board/zoom'
import {
  LinePosition,
  RectPosition,
} from 'canvas-shared/lib/types/Position.types'
import { UUID } from 'canvas-shared/lib/types'
import { Item } from 'canvas-shared/lib/types/Item.types'

/*---------------------------------------------------------------------------*/

export const CanvasNavigator = () => {
  const dispatch = useDispatch()
  const rectPositionIds = useSelector(selectRectPositionIds)
  const linePositionIds = useSelector(selectLinePositionIds)
  const groupIds = useSelector(selectGroupIds)

  const miniMap = useRef<HTMLDivElement>(null)
  const viewportIndicator = useRef<HTMLDivElement>(null)

  const [initialMiniMapRect, setInitialMiniMapRect] = useState<DOMRect>()
  const [initialViewportRect, setInitialViewportRect] = useState<DOMRect>()

  const zoomScale = useSelector(selectZoomScale)

  const {
    scrollHeight,
    scrollWidth,
    clientHeight,
    clientWidth,
  } = useScrollDimensions()
  const { scrollLeft, scrollTop } = useScrollOffsets()

  const miniMapWidth = MINIMAP_WIDTH
  const miniMapHeight = useMemo(
    () => (scrollHeight / scrollWidth) * miniMapWidth,
    [miniMapWidth, scrollHeight, scrollWidth]
  )

  const handleScroll = (item: any, monitor: DropTargetMonitor) => {
    if (!initialMiniMapRect || !initialViewportRect) {
      return
    }

    const delta = monitor.getDifferenceFromInitialOffset()

    const { x, y, right, bottom, height, width } = initialMiniMapRect
    const { x: vX, y: vY, height: vHeight, width: vWidth } = initialViewportRect

    const deltaX = delta ? delta.x : 0
    const deltaY = delta ? delta.y : 0

    const boundedNewX = Math.min(Math.max(0, vX - x + deltaX), right - vWidth)
    const boundedNewY = Math.min(Math.max(0, vY - y + deltaY), bottom - vHeight)

    const leftScrollRatio = boundedNewX / width / zoomScale
    const topScrollRatio = boundedNewY / height / zoomScale

    const l = scrollWidth * leftScrollRatio
    const t = scrollHeight * topScrollRatio

    dispatch(scrollToAction({ scrollLeft: l, scrollTop: t }))
  }

  const [, dropRef] = useDrop({
    accept: 'viewport-indicator',
    hover: handleScroll,
  })

  const handlePointerDown = () => {
    // Disable browser selection so that Safari displays the correct cursor
    document.onselectstart = () => false
  }

  const handleClick = (e: React.MouseEvent) => {
    if (!miniMap.current || !viewportIndicator.current || grabbing) {
      return
    }

    const {
      x,
      y,
      right,
      bottom,
      height,
      width,
    } = miniMap.current.getBoundingClientRect()
    const {
      x: vX,
      y: vY,
      height: vHeight,
      width: vWidth,
    } = viewportIndicator.current.getBoundingClientRect()

    const deltaX = e.clientX - vX - vWidth / 2
    const deltaY = e.clientY - vY - vHeight / 2

    const boundedNewX = Math.min(Math.max(0, vX - x + deltaX), right - vWidth)
    const boundedNewY = Math.min(Math.max(0, vY - y + deltaY), bottom - vHeight)

    const leftScrollRatio = boundedNewX / width / zoomScale
    const topScrollRatio = boundedNewY / height / zoomScale

    const l = scrollWidth * leftScrollRatio
    const t = scrollHeight * topScrollRatio

    dispatch(scrollToAction({ scrollLeft: l, scrollTop: t }))
  }

  const [grabbing, setGrabbing] = useState(false)

  const begin = () => {
    if (miniMap.current && viewportIndicator.current) {
      setInitialMiniMapRect(miniMap.current.getBoundingClientRect())
      setInitialViewportRect(viewportIndicator.current.getBoundingClientRect())
      setGrabbing(true)
    }
  }

  const [, dragRef, preview] = useDrag({
    item: {
      type: 'viewport-indicator',
    },
    begin,
    end: () => {
      setGrabbing(false)
    },
  })

  const setPreviewToEmptyImage = () => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }

  useEffect(setPreviewToEmptyImage, [preview])

  const svgViewBox = `0 0 ${scrollWidth} ${scrollHeight}`

  const minimapStyle = useMemo(
    () => ({ height: `${miniMapHeight}px`, width: `${miniMapWidth}px` }),
    [miniMapHeight, miniMapWidth]
  )

  const viewportRectStyle = useMemo(
    () => ({
      left: 0,
      top: 0,
      transform: `translate(${
        (scrollLeft / scrollWidth) * zoomScale * miniMapWidth
      }px, ${
        (scrollTop / scrollHeight) * zoomScale * miniMapHeight
      }px) scale(${zoomScale})`,
      transformOrigin: 'top left',
      height: `${(clientHeight / scrollHeight) * miniMapHeight}px`,
      width: `${(clientWidth / scrollWidth) * miniMapWidth}px`,
    }),
    [
      clientHeight,
      clientWidth,
      miniMapHeight,
      miniMapWidth,
      scrollHeight,
      scrollLeft,
      scrollTop,
      scrollWidth,
      zoomScale,
    ]
  )

  return (
    <div ref={dropRef}>
      <div
        className="relative overflow-hidden"
        onPointerDown={handlePointerDown}
        onPointerUp={handleClick}
        ref={miniMap}
        style={minimapStyle}
      >
        {rectPositionIds.map((id) => (
          <Rect key={id} id={id} />
        ))}
        <svg
          className="absolute w-full h-full"
          pointerEvents="none"
          viewBox={svgViewBox}
        >
          {linePositionIds.map((id) => (
            <Line id={id} key={id} />
          ))}
        </svg>
        {groupIds.map((id) => (
          <Group id={id} key={id} />
        ))}
        <div
          className={classNames(
            'absolute',
            'dark:bg-teal-400 bg-blue-400',
            'bg-opacity-50 dark:bg-opacity-50',
            'cursor-grab',
            { 'cursor-grabbing': grabbing }
          )}
          ref={dragRef}
          style={viewportRectStyle}
        >
          <div className="h-full" ref={viewportIndicator} />
        </div>
      </div>
    </div>
  )
}

interface ShapeProps {
  id: UUID
}

const Rect: React.FC<ShapeProps> = ({ id }) => {
  const { scrollHeight, scrollWidth } = useScrollDimensions()
  const { left, top, height, width } = useItemPosition(id) as RectPosition

  const rectStyle = useMemo(
    () => ({
      left: `${(left / scrollWidth) * 100}%`,
      top: `${(top / scrollHeight) * 100}%`,
      height: `${(height / scrollHeight) * 100}%`,
      width: `${(width / scrollWidth) * 100}%`,
    }),
    [height, left, scrollHeight, scrollWidth, top, width]
  )

  return (
    <div
      className="bg-blue-gray-600 dark:bg-cool-gray-400 absolute bg-opacity-50"
      style={rectStyle}
    />
  )
}

const Line: React.FC<ShapeProps> = ({ id }) => {
  const { startX, startY, endX, endY } = useItemPosition(id) as LinePosition

  const lineStyle: React.CSSProperties = {
    stroke: '#A1A1AA',
    strokeWidth: 25,
    strokeLinecap: 'square',
    opacity: 0.5,
  }
  return <line x1={startX} x2={endX} y1={startY} y2={endY} style={lineStyle} />
}

const Group: React.FC<ShapeProps> = ({ id }) => {
  const { scrollHeight, scrollWidth } = useScrollDimensions()

  const selectGroup = createGroupSelector(id)
  const {
    groupRect: { left, top, height, width },
  } = useSelector(selectGroup) as Item.CanvasGroupWithMemberData

  const groupStyle = useMemo(
    () => ({
      left: `${(left / scrollWidth) * 100}%`,
      top: `${(top / scrollHeight) * 100}%`,
      height: `${(height / scrollHeight) * 100}%`,
      width: `${(width / scrollWidth) * 100}%`,
    }),
    [height, left, scrollHeight, scrollWidth, top, width]
  )

  return (
    <div
      className="bg-blue-gray-600 dark:bg-cool-gray-400 absolute bg-opacity-25"
      style={groupStyle}
    />
  )
}
