import React from 'react'
import { Button, IPanelProps } from '@blueprintjs/core'
import { ArrowLeft } from 'phosphor-react'
import classNames from 'classnames'

export interface SidebarPanelStackHeaderProps extends IPanelProps {
  text: string
  iconUrl: string
  iconAltText: string
  back?: () => void
  scrolled?: boolean
}

export const SidebarPanelStackHeader: React.FC<SidebarPanelStackHeaderProps> = ({
  text,
  iconUrl,
  iconAltText,
  closePanel,
  back,
  scrolled,
}) => (
  <header
    className={classNames(
      'border-border-weak-light dark:border-border-weak-dark bg-background-five-light dark:bg-background-five-dark grid grid-cols-3 px-6 py-4',
      { 'border-b': scrolled }
    )}
  >
    <div>
      <Button
        className="hover:bg-button-secondary-hovered-light dark:hover:bg-button-secondary-hovered-dark active:bg-button-secondary-active-light dark:active:bg-button-secondary-active-dark text-icon-secondary-light dark:text-icon-secondary-dark -ml-2.5 px-2.5 py-1"
        minimal
        icon={<ArrowLeft size={20} />}
        onClick={back ?? closePanel}
        title="Go back"
      />
    </div>
    <div className="flex items-center justify-center justify-self-center space-x-1">
      <img className="w-4 h-4" src={iconUrl} alt={iconAltText} />
      <span className="text-text-primary-light dark:text-text-primary-dark flex-shrink-0 text-base font-semibold">
        {text}
      </span>
    </div>
  </header>
)
