/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import convertToPx from './convertToPx'

import { PseudoDOMRect } from 'canvas-shared/lib/types/Position.types'

/*---------------------------------------------------------------------------*/

const getStyleFromDOMRect = ({ left, top, height, width }: PseudoDOMRect) => {
  if (
    left !== undefined &&
    top !== undefined &&
    height !== undefined &&
    width !== undefined
  ) {
    return {
      top: 0,
      left: 0,
      transform: `translate(${left}px, ${top}px)`,
      height: convertToPx(height),
      width: convertToPx(width),
    }
  } else {
    return {}
  }
}

export default getStyleFromDOMRect
