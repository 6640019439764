/*---- External -------------------------------------------------------------*/

import { ReduxFirestoreQuerySetting, WhereOptions } from 'react-redux-firebase'

/*---- Qualdesk -------------------------------------------------------------*/

import { SchemaFirestore } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

type Query = ReduxFirestoreQuerySetting & { collection: keyof SchemaFirestore }

export const firestoreQueryFromPath = (
  path: string,
  where?: WhereOptions,
  storeAsTransformer?: (key: keyof SchemaFirestore) => string
): Query => {
  const pathComponents = path.split('/')

  let pairs: Query[] = []

  for (let i = 0; i <= pathComponents.length; i = i + 2) {
    if (!!pathComponents[i]) {
      pairs.push(
        generateCollectionDocPair(
          pathComponents[i] as keyof SchemaFirestore,
          pathComponents[i + 1]
        )
      )
    }
  }

  const lastPair = pairs[pairs.length - 1]

  pairs.reverse()

  const query: Query = pairs.reduce((res, pair, j) => {
    if (j > 0) {
      return {
        ...pair,
        subcollections: [res],
      }
    }

    return res
  }, lastPair)

  const storeAs =
    typeof storeAsTransformer === 'function' && !lastPair.doc
      ? storeAsTransformer(lastPair.collection)
      : lastPair.collection + (lastPair.doc ? `-${lastPair.doc}` : '')

  return {
    ...query,
    where,
    storeAs,
  }
}

const generateCollectionDocPair = (
  collection: keyof SchemaFirestore,
  doc?: string
): Query => {
  const res: Query = {
    collection,
  }

  if (!!doc) {
    res.doc = doc
  }

  return res
}
