/*---- External -------------------------------------------------------------*/

import React from 'react'
import { useSelector } from 'react-redux'

import { Plus } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import { orgNameSelector } from '../../redux/selectors/auth/org'
import { teamMembershipIdsSelector } from '../../redux/selectors/auth/teams'

import { WorkspaceTeamButton } from './WorkspaceTeamButton'
import { WorkspaceNewTeamDialog } from './WorkspaceNewTeamDialog'
import { WorkspaceNavigationButton } from './WorkspaceNavigationButton'

import { useToggle } from '../utilities/useToggle'

/*---------------------------------------------------------------------------*/

export const WorkspaceNavigationOrg: React.FC = () => {
  const orgName = useSelector(orgNameSelector)
  const teamIds = useSelector(teamMembershipIdsSelector)

  const [dialogOpen, toggleDialog] = useToggle(false)

  return (
    <div className="border-border-weak-light dark:border-border-weak-dark flex flex-col flex-shrink mt-3 pt-3 border-t overflow-y-hidden">
      <div className="flex items-center justify-between pl-6 pr-4 py-1">
        <span className="text-text-secondary-light dark:text-text-secondary-dark text-xs">
          {orgName}
        </span>
      </div>
      <div className="pb-3 overflow-y-auto">
        {teamIds.map((teamId) => (
          <WorkspaceTeamButton key={teamId} teamId={teamId} />
        ))}
        <WorkspaceNavigationButton
          phosphorIcon={Plus}
          onClick={toggleDialog}
          text="New team"
          secondary
        />
      </div>
      <WorkspaceNewTeamDialog isOpen={dialogOpen} onClose={toggleDialog} />
    </div>
  )
}
