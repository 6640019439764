/*---- External -------------------------------------------------------------*/

import { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import axios, { AxiosInstance } from 'axios'

/*---- Qualdesk -------------------------------------------------------------*/

import { apiKey } from '../../config/trello'

import { selectTrelloUserConnections } from '../../redux/selectors/auth/userConnections'
import { sendSuperficialError } from '../../helpers/sendSuperficialError'

/*---------------------------------------------------------------------------*/

interface Options {
  token?: string
  apiKey?: string
}

// QUESTION: can this be moved to a https function?

class TrelloClient {
  token?: string
  apiKey?: string
  apiClient: AxiosInstance

  constructor({ token, apiKey }: Options) {
    this.token = token
    this.apiKey = apiKey

    this.apiClient = axios.create({
      baseURL: 'https://api.trello.com/1',
    })

    this.apiClient.interceptors.request.use((config) => ({
      ...config,
      params: {
        ...config.params,
        token: this.token,
        key: this.apiKey,
      },
    }))
  }

  async getCurrentUser() {
    return this.apiClient.get('/members/me')
  }

  async getBoards() {
    const { data } = await this.apiClient.get('/members/me/boards')
    return data
  }
}

const useTrelloConnection = () => {
  const [loading, setLoading] = useState(true)
  const [connected, setConnected] = useState(false)
  const [token, setToken] = useState<string>()
  const trello = useSelector(selectTrelloUserConnections)

  const client = useMemo(() => {
    return new TrelloClient({ token, apiKey })
  }, [token])

  useEffect(() => {
    const initToken = () => {
      if (trello) {
        setToken(trello.token)
      } else {
        setConnected(false)
        setLoading(false)
      }
    }

    initToken()
  }, [trello])

  useEffect(() => {
    const connectToTrello = async () => {
      try {
        await client.getCurrentUser()
        setConnected(true)
      } catch (error) {
        sendSuperficialError(error, 'Failed to get Trello user')
        setConnected(false)
      } finally {
        setLoading(false)
      }
    }

    if (client.token) connectToTrello()
  }, [client])

  return {
    connected,
    loading,
    client,
  }
}

export default useTrelloConnection
