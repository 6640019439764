/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'
import { createCachedSelector } from 're-reselect'

import { assign, uniqBy } from 'lodash'

/*---- Qualdesk -------------------------------------------------------------*/

import { containerIdSelector as currentContainerIdSelector } from '../board/container'

import { firestoreDataToArray } from '../helpers/firestoreDataToArray'
import { createListenerExistsSelector } from '../helpers/listenerStatus'

import { RootState } from '../../../types/redux'

import { Board } from 'canvas-shared/lib/types/Board.types'
import { UUID } from 'canvas-shared/lib/types'

/*---------------------------------------------------------------------------*/

export const containerIdSelector = (state: RootState) =>
  state.params.containerId

const getContainersByUidData = (state: RootState) =>
  state.firestore.data.containersByUid
const getContainersByTeamMembershipData = (state: RootState) =>
  state.firestore.data.containersByTeamMembership

export const getContainersOrdered = createSelector(
  getContainersByUidData,
  getContainersByTeamMembershipData,
  (byUid, byTeamMembership) =>
    uniqBy(
      firestoreDataToArray(byUid).concat(
        ...firestoreDataToArray(byTeamMembership)
      ),
      'id'
    )
)

const teamMembershipsListenerExistsSelector = createListenerExistsSelector(
  'containersByTeamMembership'
)

export const getContainersData = createSelector(
  getContainersByUidData,
  getContainersByTeamMembershipData,
  teamMembershipsListenerExistsSelector,
  (byUid, byTeamMembership, listenerExists) => {
    if (
      byUid !== undefined &&
      (byTeamMembership !== undefined || !listenerExists)
    ) {
      return assign({}, byUid, byTeamMembership)
    }
    return null
  }
)
export const containersWithIdSelector = createSelector(
  getContainersData,
  (containersData): Board[] | undefined => {
    if (containersData === null) {
      return undefined
    }

    return Object.keys(containersData).reduce((res: Board[], id) => {
      const maybeData = constructContainerWithId(id, containersData)

      if (!!maybeData) {
        return res.concat(maybeData)
      }

      return res
    }, [])
  }
)

const getContainerId = (_state: RootState, containerId: UUID) => containerId

export const currentContainerSelector = createSelector(
  currentContainerIdSelector,
  getContainersData,
  (containerId, containersData) =>
    constructContainerWithId(containerId, containersData)
)

export const containerSelector = (containerId: UUID) =>
  createCachedSelector(
    getContainerId,
    getContainersData,
    (containerId, containersData): Board | undefined =>
      constructContainerWithId(containerId, containersData)
  )((state, containerId) => containerId)

const constructContainerWithId = (
  containerId: UUID,
  containersData: Record<UUID, Omit<Board, 'id'>> | null
): Board | undefined => {
  if (containersData === null || !containersData[containerId]) {
    return undefined
  }

  const containerData = containersData[containerId]
  const { data } = containerData
  const { title } = data

  return {
    ...containerData,
    data: {
      ...data,
      title: title === undefined || title === '' ? null : title,
    },
    id: containerId,
  }
}
