/*---- External -------------------------------------------------------------*/

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  usersSelector,
  usersWithIdsSelector,
} from '../../selectors/users/users'

/*---------------------------------------------------------------------------*/

export const useUsers = () => useSelector(usersSelector)
export const useUserList = () => useSelector(usersWithIdsSelector)
