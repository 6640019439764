/*---- External -------------------------------------------------------------*/

import { useCallback, useMemo } from 'react'

import { useDispatch } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { ItemEdit } from 'canvas-shared/lib/types/Item.types'
import { UUID } from 'canvas-shared/lib/types'
import { editUpdateOneAction } from '../../actionCreators/edits'

/*---------------------------------------------------------------------------*/

interface ItemEditsActions {
  streamItemEdits: (edits: Partial<ItemEdit>) => void
}

export const useItemEditsActions = (itemId: UUID): ItemEditsActions => {
  const dispatch = useDispatch()

  const streamItemEdits = useCallback(
    (edits: Partial<ItemEdit>) =>
      dispatch(
        editUpdateOneAction({
          key: itemId,
          value: {
            ...edits,
            editing: true,
          },
        })
      ),
    [dispatch, itemId]
  )

  return useMemo(
    () => ({
      streamItemEdits,
    }),
    [streamItemEdits]
  )
}
