/*---- External -------------------------------------------------------------*/

import { createAction } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { ItemEdit } from 'canvas-shared/lib/types/Item.types'
import { UUID } from 'canvas-shared/lib/types'
import { UpdatePayload } from '../../types/redux'

/*---------------------------------------------------------------------------*/

export const editSetOneAction = createAction<UpdatePayload<ItemEdit>>(
  'edits/setOne'
)

export const editRemoveAction = createAction<UUID>('edits/remove')

export const editUpdateOneAction = createAction<UpdatePayload<ItemEdit>>(
  'edits/updateOne'
)
