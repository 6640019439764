/*---- External -------------------------------------------------------------*/

import React, { useEffect } from 'react'

import { Button } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { usePersistentToast } from '../usePersistentToast'

import useLocalStorageSettings from '../useLocalStorageSettings'
import { useSystemDarkMode } from './useSystemDarkMode'
import { useClassNamesWithDarkMode } from './useClassNamesWithDarkMode'

import { DarkMode } from 'canvas-shared/lib/types/settings.types'
import { DarkModePanel } from '../../components/settings/panels/DarkModePanel'

/*---------------------------------------------------------------------------*/

export const useDarkModeToggle = () => {
  const { useDarkModeSetting } = useLocalStorageSettings()
  const [userDarkModeSetting] = useDarkModeSetting()
  const systemDarkMode = useSystemDarkMode()

  const { showOrUpdate, dismiss } = usePersistentToast()

  useEffect(() => {
    const handleClose = () => {
      dismiss()
    }

    if (!userDarkModeSetting && systemDarkMode) {
      showOrUpdate({
        className: 'custom-toast overflow-hidden',
        message: <DarkModeMessage handleClose={handleClose} />,
        timeout: 0,
      })
    }
  }, [dismiss, showOrUpdate, systemDarkMode, userDarkModeSetting])
}

interface DarkModeMessageProps {
  handleClose: () => void
}

const DarkModeMessage: React.FC<DarkModeMessageProps> = ({ handleClose }) => {
  const { useDarkModeSetting } = useLocalStorageSettings()
  const [userDarkModeSetting, setUserDarkModeSetting] = useDarkModeSetting()
  const { classNamesWithDarkMode } = useClassNamesWithDarkMode()

  const chosenSetting = userDarkModeSetting || DarkMode.LIGHT

  useEffect(() => {
    if (!userDarkModeSetting) {
      setUserDarkModeSetting(chosenSetting)
    }
  }, [chosenSetting, setUserDarkModeSetting, userDarkModeSetting])

  return (
    <div className={classNamesWithDarkMode()}>
      <div className="flex flex-col space-y-2 bg-background-floating-light dark:bg-background-floating-dark">
        <DarkModePanel
          className="w-96 p-4"
          message="It looks like you’re using your device in dark mode. Would you like to switch to dark mode in Qualdesk?"
        />
        <div className="p-3 flex flex-col items-end bg-background-four-light dark:bg-background-four-dark border-border-weak-light dark:border-border-weak-dark border-t">
          <Button text="Save setting" onClick={handleClose} />
        </div>
      </div>
    </div>
  )
}
