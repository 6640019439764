/*---- External -------------------------------------------------------------*/

import React, { SyntheticEvent } from 'react'

import classNames from 'classnames'

import { CaretDown } from 'phosphor-react'

import { IButtonProps, Classes, Button, Tooltip } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { TooltipContent } from './TooltipContent'

/*---------------------------------------------------------------------------*/

type SplitPopoverButtonProps = Partial<IButtonProps> & {
  title: string
  actionTitle: string
  actionHotkey?: string
  actionIcon: JSX.Element | undefined
  actionOnClick: (event: SyntheticEvent) => void
}

export const SplitPopoverButton: React.FC<SplitPopoverButtonProps> = ({
  title,
  active,
  className,
  actionTitle,
  actionHotkey,
  actionIcon,
  actionOnClick,
  ...otherProps
}) => (
  <div
    className={classNames(
      Classes.BUTTON,
      Classes.MINIMAL,
      'split-popover-button flex items-center p-0'
    )}
  >
    <Tooltip
      popoverClassName="tooltip"
      content={<TooltipContent title={actionTitle} hotkey={actionHotkey} />}
      hoverOpenDelay={300}
    >
      <Button
        {...otherProps}
        icon={actionIcon}
        className={classNames(
          'split-popover-button-action pl-2 pr-0 bg-transparent',
          className
        )}
        minimal
        onClick={(event: SyntheticEvent) => {
          event.stopPropagation()
          actionOnClick(event)
        }}
        aria-label={actionTitle}
      />
    </Tooltip>
    <Tooltip
      popoverClassName="tooltip"
      content={<TooltipContent title={title} />}
      hoverOpenDelay={300}
    >
      <Button
        icon={
          <CaretDown
            weight="bold"
            className="popover-button-caret text-icon-secondary-light dark:text-icon-secondary-dark"
          />
        }
        className="split-popover-button-dropdown popover-button pl-0 pr-2 min-w-0 bg-transparent"
        minimal
        aria-label={title}
      />
    </Tooltip>
  </div>
)
