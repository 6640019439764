/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { RootState } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

export const mySessionSelector = (state: RootState) => state.board.session.data

export const sessionLoadedSelector = (state: RootState) =>
  state.board.session.loaded

export const selectSelectionRect = (state: RootState) =>
  state.board.session.localData.selectionRect

export const selectIsFocused = createSelector(
  mySessionSelector,
  (session) => session.isFocused
)
