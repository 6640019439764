/*---- External -------------------------------------------------------------*/

import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { generatePath, useHistory } from 'react-router-dom'

import { useFirestore, useFirebase } from 'react-redux-firebase'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  DEFAULT_CANVAS_HEIGHT,
  DEFAULT_CANVAS_WIDTH,
} from '../../../config/canvas'

import { app } from '../../../config/firebase'

import { containerPath } from 'canvas-shared/lib/paths/containerPath'
import { containersPath } from 'canvas-shared/lib/paths/containersPath'

import { generateOperation } from '../helpers/generateOperation'

import { selectMyUid } from '../../selectors/auth/auth'

import { Routes } from '../../../router/routes'

import { UUID } from 'canvas-shared/lib/types'
import {
  PermissionLevel,
  RolesMap,
} from 'canvas-shared/lib/types/permissions.types'
import { ContainerType } from 'canvas-shared/lib/types/Container.types'
import { ShareSetting, Board } from 'canvas-shared/lib/types/Board.types'

/*---------------------------------------------------------------------------*/

interface CreateBoardArgs {
  teamId?: UUID
}

interface WorkspaceActions {
  createBoard: (args?: CreateBoardArgs) => Promise<CreateBoardResult>
}

interface CreateBoardResult {
  containerId: UUID
  boardPath: string
}

export const useWorkspaceActions = (): WorkspaceActions => {
  const firestore = useFirestore()
  const firebase = useFirebase()
  const uid = useSelector(selectMyUid)
  const { push } = useHistory()

  const createBoard = useCallback(
    async (args: CreateBoardArgs | undefined): Promise<CreateBoardResult> => {
      const roles: RolesMap = {
        users: {
          [uid as string]: PermissionLevel.CREATOR,
        },
      }

      if (args?.teamId) {
        roles.teams = {
          membership: args.teamId,
        }
      }

      const operation = generateOperation(uid)

      const newBoard: Omit<Board, 'id'> = {
        type: ContainerType.Board,
        data: {
          canvasHeight: DEFAULT_CANVAS_HEIGHT,
          canvasWidth: DEFAULT_CANVAS_WIDTH,
        },
        roles,
        settings: {
          shared: ShareSetting.INVITATION,
        },
        operation,
        createdAt: app.firestore.Timestamp.now(),
        createdBy: operation,
        updatedAt: app.firestore.Timestamp.now(),
        updatedBy: operation,
      }

      const res = await firestore.collection(containersPath).add(newBoard)

      await firebase.ref(containerPath(res.id)).set({
        roles,
      })

      const boardPath = generatePath(Routes.CANVAS.path, {
        containerId: res.id,
      })

      push(boardPath)

      return { containerId: res.id, boardPath }
    },
    [firebase, firestore, push, uid]
  )

  return useMemo(
    () => ({
      createBoard,
    }),
    [createBoard]
  )
}
