/*---- External -------------------------------------------------------------*/

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { onlineUserIdsSelector } from '../../selectors/board/users'

import { UUID } from '../../../types'

/*---------------------------------------------------------------------------*/

export const useOnlineUserIds = (): UUID[] => useSelector(onlineUserIdsSelector)
