/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { createContainerSpecificRTDBDataSelector } from '../helpers/containerSpecificSelectors'

/*---------------------------------------------------------------------------*/

export const getTimers = createContainerSpecificRTDBDataSelector('timers')

// Because of the way the RTDB schema works, it assumes everything is a record
// We store one timer per canvas and so can access it as below

export const timersSelector = createSelector(getTimers, (timers) =>
  !!timers && timers.allUsers ? timers.allUsers : undefined
)
