/*---- External -------------------------------------------------------------*/

import { ArrowsClockwise, IconProps } from 'phosphor-react'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { SyncStatus } from 'canvas-shared/lib/config/connections/sync'

/*---------------------------------------------------------------------------*/

interface SyncArrowsProps extends IconProps {
  syncStatus?: SyncStatus | SyncStatus[]
}

export const SyncArrows: React.FC<SyncArrowsProps> = ({
  syncStatus,
  ...iconProps
}) => (
  <ArrowsClockwise
    {...iconProps}
    className={classNames(iconProps.className, {
      'animate-spin': syncStatus?.includes(SyncStatus.IN_PROGRESS),
    })}
  />
)
