/*---- External -------------------------------------------------------------*/

import React, { useState, useEffect, useCallback } from 'react'

import classNames from 'classnames'

import { Button, Classes, Tab, Tabs } from '@blueprintjs/core'
import { Classes as Popover2Classes } from '@blueprintjs/popover2'

/*---- Qualdesk -------------------------------------------------------------*/

import { functions } from '../../../config/firebase'

import { useStatusReporter } from '../../../hooks/useStatusReporter'

import { SidebarPopover } from '../SidebarPopover'
import { JiraFilters } from './JiraFilters'

import * as JiraAPI from 'canvas-shared/lib/types/connections/JiraAPI.types'
import {
  Filter,
  FilterType,
} from 'canvas-shared/lib/types/connections/Jira.types'

/*---------------------------------------------------------------------------*/

enum TabId {
  Filters = 'filters',
  Jql = 'jql',
}

interface JiraFiltersPopoverProps {
  board: JiraAPI.Board
  appliedFilters: Filter[]
  setAppliedFilters: (value: Filter[]) => void
}

const fetchFilters = functions.httpsCallable('https-jiraFetchFilters')

export const JiraFiltersPopover: React.FC<JiraFiltersPopoverProps> = (
  popoverProps
) => (
  <SidebarPopover
    buttonText="Filters"
    content={<PopoverContent {...popoverProps} />}
  />
)

const PopoverContent: React.FC<JiraFiltersPopoverProps> = ({
  board,
  appliedFilters,
  setAppliedFilters,
}) => {
  const { reportError } = useStatusReporter()

  const [filters, setFilters] = useState<Filter[]>([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [selectedTabId, setSelectedTabId] = useState<TabId>(
    appliedFilters.find((f) => f.type === FilterType.Custom)
      ? TabId.Jql
      : TabId.Filters
  )
  const [openSections, setOpenSections] = useState<Set<FilterType>>(
    new Set([...appliedFilters.map((f) => f.type), FilterType.IssueType])
  )
  const [checkedValueIds, setCheckedValueIds] = useState<Set<string>>(
    new Set(appliedFilters.filter((f) => !f.jqlDefaultInclude).map((f) => f.id))
  )

  useEffect(() => {
    let isSubscribed = true
    const initFilters = async () => {
      try {
        if (isSubscribed) {
          const { data } = await fetchFilters({
            boardId: board.id,
            projectId: board.location.projectId,
          })
          setIsLoaded(true)
          setFilters(data)
        }
      } catch (error) {
        reportError(error, 'Failed to get Jira filters for this project')
      }
    }

    initFilters()

    return () => {
      isSubscribed = false
    }
  }, [board, setAppliedFilters, reportError])

  const onChange = useCallback(
    (tabId) => {
      const checkedFilters = filters.filter((value) => {
        const isChecked = checkedValueIds.has(value.id)
        return value.jqlDefaultInclude ? !isChecked : isChecked
      })

      switch (tabId) {
        case TabId.Filters:
          setAppliedFilters(checkedFilters)
          setSelectedTabId(tabId)
          break
      }
    },
    [filters, checkedValueIds, setAppliedFilters]
  )

  return (
    <Tabs
      className="tabs-header dark:bg-background-four-dark rounded-t"
      id="filter"
      selectedTabId={selectedTabId}
      onChange={onChange}
    >
      <Tab
        id={TabId.Filters}
        title="Filters"
        panel={
          <JiraFilters
            filters={filters}
            isLoaded={isLoaded}
            openSections={openSections}
            checkedValueIds={checkedValueIds}
            setOpenSections={setOpenSections}
            setCheckedValueIds={setCheckedValueIds}
            setAppliedFilters={setAppliedFilters}
          />
        }
      />
      <Button
        className={classNames(
          'self-center ml-auto p-2',
          Classes.DIALOG_CLOSE_BUTTON,
          Popover2Classes.POPOVER2_DISMISS
        )}
        icon="cross"
        minimal
      />
    </Tabs>
  )
}
