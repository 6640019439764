/*---- External -------------------------------------------------------------*/

import { createSelectorCreator, defaultMemoize } from 'reselect'
import isEqual from 'lodash.isequal'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

export const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual
)
