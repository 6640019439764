/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { WorkspaceCard } from './WorkspaceCard'
import { WorkspaceEmptyState } from '../../types/workspace'

/*---------------------------------------------------------------------------*/

export const WorkspaceEmptyStateCard: React.FC<WorkspaceEmptyState> = ({
  title,
  description,
}) => (
  <WorkspaceCard className="ring-border-normal-light dark:ring-border-normal-dark flex flex-col ring-1">
    <div className="border-border-normal-light dark:border-border-normal-dark text-text-secondary-light dark:text-text-secondary-dark flex flex-col mt-auto p-4 pt-3 border-t space-y-1">
      <div className="text-base font-semibold">{title}</div>
      <div className="text-xs">{description}</div>
    </div>
  </WorkspaceCard>
)
