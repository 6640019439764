/*---- External -------------------------------------------------------------*/

import React from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { useOnlineUserIds } from '../../redux/selectorHooks/board/useOnlineUserIds'

import UserIcon from '../user/UserIcon'

/*---------------------------------------------------------------------------*/

const CanvasPresenceIndicator: React.FC = () => {
  const onlineUserIds = useOnlineUserIds()

  return (
    <div className="flex flex-row items-center h-auto space-x-2" role="list">
      {onlineUserIds.map((userId) => (
        <div key={userId} className="relative">
          <UserIcon className="w-7 h-7 bg-gray-100" uid={userId} />
        </div>
      ))}
    </div>
  )
}

export default CanvasPresenceIndicator
