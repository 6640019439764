/*---- External -------------------------------------------------------------*/

import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { pointerMoveAction } from '../../../redux/actionCreators/pointers'

/*---------------------------------------------------------------------------*/

export const usePointerMoveHandler = (): React.PointerEventHandler => {
  const dispatch = useDispatch()

  const onPointerMove = useCallback(
    (e: React.PointerEvent) => {
      dispatch(pointerMoveAction(e))
    },
    [dispatch]
  )

  return useMemo(() => onPointerMove, [onPointerMove])
}
