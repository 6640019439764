/*---- External -------------------------------------------------------------*/

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { GROUP_RECT_PADDING } from '../../config/canvas'

import { createGroupSelector } from '../../redux/selectors/board/groups'

import getStyleFromDOMRect from '../../helpers/getStyleFromDOMRect'
import { padRect } from '../../helpers/padRect'

import { UUID } from '../../types'

/*---------------------------------------------------------------------------*/

interface GroupRectProps {
  groupId: UUID
}

const CanvasGroupRect: React.FC<GroupRectProps> = ({ groupId }) => {
  const group = useSelector(createGroupSelector(groupId))

  const groupRect = group?.groupRect

  const style = useMemo(() => {
    if (!!groupRect) {
      return getStyleFromDOMRect(padRect(groupRect, GROUP_RECT_PADDING))
    }

    return undefined
  }, [groupRect])

  if (!group || !style || !group.data.color) {
    return null
  }

  return (
    <div
      className={`absolute bg-opacity-25 bg-${group.data.color}-300 rounded-sm z-0`}
      id={groupId}
      style={style}
    />
  )
}

export default CanvasGroupRect
