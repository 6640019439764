/*---- External -------------------------------------------------------------*/

import React, { useMemo } from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import sheetsFileIcon from '../../../assets/integrations/google/fileTypeIcons/sheets.png'
import convertToPx from '../../../helpers/convertToPx'

/*---------------------------------------------------------------------------*/

interface GoogleFileIconProps {
  size?: number
  style?: React.CSSProperties
}

const GoogleFileIcon: React.FC<GoogleFileIconProps> = ({
  size = 12,
  style,
}) => {
  const iconStyle = useMemo(
    () => ({ height: convertToPx(size), width: convertToPx(size), ...style }),
    [size, style]
  )

  return <img alt="Google Sheets File" src={sheetsFileIcon} style={iconStyle} />
}

export default GoogleFileIcon
