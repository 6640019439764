/*---- External -------------------------------------------------------------*/

import React, { useState, useEffect, useMemo } from 'react'

import {
  Button,
  Dialog,
  TagInput,
  Intent,
  Classes,
  Menu,
  FormGroup,
} from '@blueprintjs/core'

import { Select } from '@blueprintjs/select'

import split from 'lodash.split'

/*---- Qualdesk -------------------------------------------------------------*/

import useLocalStorageSettings from '../../hooks/useLocalStorageSettings'
import { useClassNamesWithDarkMode } from '../../hooks/darkMode/useClassNamesWithDarkMode'

import {
  ItemType,
  CreateableItemType,
} from 'canvas-shared/lib/types/Item.types'
import {
  ItemConfigurations,
  itemTypesForMultiple,
  DEFAULT_MULTIPLE_ITEM_TYPE,
} from '../../config/itemTypes'

/*---------------------------------------------------------------------------*/

interface MultilineImportDialogProps {
  onImport: (values: string[]) => void
  initialValue?: string
  initialType?: CreateableItemType
  isOpen?: boolean
  onClose?: () => void
  children?: (onOpen: () => void) => React.ReactNode
}

const MultilineImportDialog: React.FC<MultilineImportDialogProps> = ({
  onImport,
  children,
  isOpen,
  initialValue,
  initialType,
  onClose,
}) => {
  const [localIsOpen, setLocalIsOpen] = useState(!!isOpen)

  const { useItemType } = useLocalStorageSettings()
  const [itemType, setItemType] = useItemType()
  const { classNamesWithDarkMode } = useClassNamesWithDarkMode()

  useEffect(() => {
    if (initialType) {
      setItemType(initialType)
    }
  }, [initialType, setItemType])

  useEffect(() => {
    if (!itemTypesForMultiple.includes(itemType)) {
      setItemType(ItemType[DEFAULT_MULTIPLE_ITEM_TYPE])
    }
  }, [itemType, setItemType])

  const getFromInitialValue = useMemo(
    () => (!!initialValue ? split(initialValue, /\s*[\n\r]\s*/) : []),
    [initialValue]
  )
  const setFromInitialValue = () => {
    setValues(getFromInitialValue)
  }

  const [values, setValues] = useState<React.ReactNode[]>(getFromInitialValue)

  useEffect(setFromInitialValue, [getFromInitialValue, initialValue])

  useEffect(() => {
    setLocalIsOpen(!!isOpen)
  }, [isOpen])

  const handleImport = () => {
    onImport(values as string[])
    handleClose()
  }
  const handleChange = (values: React.ReactNode[]) => setValues(values)
  const handleReset = () => setValues([])
  const handleOpen = () => setLocalIsOpen(true)
  const handleClose = () => {
    handleReset()
    setLocalIsOpen(false)
    if (onClose) {
      onClose()
    }
  }

  const renderOption = (option: CreateableItemType) => {
    const Icon = ItemConfigurations[option].icon

    return (
      <Menu.Item
        active={option === itemType}
        icon={Icon && <Icon />}
        onClick={() => setItemType(option)}
        text={ItemConfigurations[option].text}
      />
    )
  }

  const Icon = ItemConfigurations[itemType].icon

  return (
    <>
      {children && children(handleOpen)}
      <Dialog
        isOpen={localIsOpen}
        onClose={handleClose}
        title="Add multiple items"
        className={classNamesWithDarkMode('flex flex-col w-2/3')}
        portalClassName="z-above-everything"
      >
        <div className={`${Classes.DIALOG_BODY}`}>
          <FormGroup inline label="Add items as">
            <Select
              activeItem={itemType}
              filterable={false}
              items={itemTypesForMultiple}
              itemRenderer={renderOption}
              onItemSelect={setItemType}
              popoverProps={{ minimal: true }}
            >
              <Button
                icon={Icon && <Icon />}
                text={ItemConfigurations[itemType].text}
                rightIcon="double-caret-vertical"
              />
            </Select>
          </FormGroup>
          <TagInput
            addOnPaste={true}
            className="paste-multiple mb-2"
            large={true}
            fill={true}
            values={values}
            onChange={handleChange}
            separator={/\s*[\n\r]\s*/}
            tagProps={{ minimal: true }}
            inputProps={{
              placeholder:
                'Type or paste content here. Each line will become a new item.',
              className: 'min-w-full w-full',
            }}
          />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button minimal onClick={handleReset}>
              Reset
            </Button>
            <Button intent={Intent.PRIMARY} onClick={handleImport}>
              Add
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default MultilineImportDialog
