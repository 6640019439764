/*---- External -------------------------------------------------------------*/

import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { safeString } from '../../helpers/safeString'

import { MultilineTextInput } from '../multilineTextInput/MultilineTextInput'

import useTitleStream from '../../hooks/useTitleStream'

import { useShouldSelectActions } from '../../redux/actions/board/useShouldSelectActions'
import { useEditsActions } from '../../redux/actions/board/useEditsActions'
import { itemSetTitleAction } from '../../redux/actionCreators/itemData'
import { useSelectionActions } from '../../redux/actions/board/useSelectionActions'

import { useItem } from '../../redux/selectorHooks/board/useItems'
import { useShouldSelect } from '../../redux/selectorHooks/board/useShouldSelect'

import { Item } from 'canvas-shared/lib/types/Item.types'
import { UUID } from 'canvas-shared/lib/types'
import { createAdjacentItemAction } from '../../redux/actionCreators/items'

/*---------------------------------------------------------------------------*/

interface ItemGenericTextInnerProps {
  className?: string
  itemId: UUID
  selectTextOnCreation?: boolean
}

export const ItemGenericTextInner: React.FC<ItemGenericTextInnerProps> = ({
  className,
  itemId,
  selectTextOnCreation = true,
}) => {
  const item = useItem(itemId) as Item.TextItem

  const titleFromData = safeString(item.data.title)

  const [title, setTitle] = useState(titleFromData)
  const [editing, setEditing] = useState(false)

  const dispatch = useDispatch()

  useTitleStream({ id: item.id, title, setTitle, editing })

  useEffect(() => {
    setTitle(titleFromData)
  }, [titleFromData])

  const { selectionSet } = useSelectionActions()
  const { addEdit, removeEdit } = useEditsActions()
  const { clearShouldSelect } = useShouldSelectActions()
  const shouldSelectId = useShouldSelect()

  const handleChange = (value: string) => {
    setTitle(value)
  }

  const handleConfirm = (value: string) => {
    stopEditing()
    dispatch(itemSetTitleAction({ itemId: item.id, title: value }))
  }

  const startEditing = useCallback(() => {
    setEditing(true)
    addEdit(item.id)
  }, [addEdit, item.id])

  const stopEditing = () => {
    setEditing(false)
    removeEdit(item.id)
  }

  const startEditingIfEmptyAndSelected = () => {
    if (!title && shouldSelectId === item.id) {
      selectionSet([item.id])
      if (selectTextOnCreation) {
        startEditing()
      }
      clearShouldSelect()
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Tab') {
      e.preventDefault()
      dispatch(createAdjacentItemAction(item.id))
    }
  }

  useEffect(startEditingIfEmptyAndSelected, [
    clearShouldSelect,
    item.id,
    selectTextOnCreation,
    selectionSet,
    shouldSelectId,
    startEditing,
    title,
  ])

  return (
    <div
      className={classNames(
        'flex flex-col w-full h-full overflow-hidden',
        className
      )}
      onDoubleClick={startEditing}
      onKeyDown={handleKeyDown}
    >
      <MultilineTextInput
        className="flex-grow"
        disabled={!editing}
        isEditing={editing}
        key={editing ? 'editing' : null}
        onCancel={stopEditing}
        onChange={handleChange}
        onConfirm={handleConfirm}
        placeholder=""
        value={title}
      />
    </div>
  )
}
