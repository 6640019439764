/*---- External -------------------------------------------------------------*/

import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Icon,
  Menu,
  IconName,
  IMenuItemProps,
  IMenuDividerProps,
} from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { estimateValues } from '../../../config/estimateValues'

import { NO_MENU_ITEMS } from '../../../constants/menus'

import { isEstimateableItem } from 'canvas-shared/lib/helpers/itemTypes'

import {
  itemsAddEstimateVoteAction,
  itemsClearEstimatesAction,
  itemsRemoveEstimateVoteAction,
  itemsResolveEstimateAction,
} from '../../../redux/actionCreators/itemData'
import { selectMyUid } from '../../../redux/selectors/auth/auth'

import { UUID } from '../../../types'
import { Item } from 'canvas-shared/lib/types/Item.types'

/*---------------------------------------------------------------------------*/

export const useEstimateMenuItems = (
  actionItems: Item.BaseItem[]
): Array<IMenuItemProps | IMenuDividerProps> => {
  const dispatch = useDispatch()

  const someItemsCannotBeEstimated = useMemo(
    () => actionItems.some((i) => !isEstimateableItem(i)),
    [actionItems]
  )

  const actionItemIds = useMemo(() => actionItems.map((i) => i.id), [
    actionItems,
  ])

  const handleClearEstimates = useCallback(() => {
    dispatch(itemsClearEstimatesAction({ ids: actionItemIds }))
  }, [actionItemIds, dispatch])

  const estimationItems = useMemo(
    () => [
      { title: 'Estimation' },
      {
        text: 'My estimate',
        children: estimateValues.map((value) => (
          <ToggleEstimateItem
            actionItems={actionItems as Item.EstimateableItem[]}
            actionItemIds={actionItemIds}
            key={value}
            value={value}
          />
        )),
      },
      {
        text: 'Resolve estimate',
        children: estimateValues.map((value) => (
          <ResolveEstimateItem
            actionItems={actionItems as Item.EstimateableItem[]}
            actionItemIds={actionItemIds}
            key={value}
            value={value}
          />
        )),
      },
      {
        disabled: actionItems.every(
          (i) => !(i as Item.EstimateableItem).data?.estimate
        ),
        text: 'Clear estimate',
        onClick: handleClearEstimates,
      },
    ],
    [actionItemIds, actionItems, handleClearEstimates]
  )

  if (someItemsCannotBeEstimated) {
    return NO_MENU_ITEMS
  } else {
    return estimationItems
  }
}

interface EstimateItemProps {
  actionItems: Item.EstimateableItem[]
  actionItemIds: UUID[]
  value: number
}

const ToggleEstimateItem: React.FC<EstimateItemProps> = ({
  actionItems,
  actionItemIds,
  value,
}) => {
  const dispatch = useDispatch()
  const uid = useSelector(selectMyUid)

  const myEstimateVotes = useMemo(
    () =>
      actionItems.reduce((res: MyVote[], { id, data }) => {
        if (!!data?.estimateVotes) {
          res.concat({
            id,
            value: data.estimateVotes[uid as string].value,
          })
        }
        return res
      }, []),
    [actionItems, uid]
  )

  const toggleEstimate = useCallback(
    (value: number) => {
      if (
        actionItems.every(
          (i) =>
            i.data?.estimateVotes &&
            i.data.estimateVotes[uid as string].value === value
        )
      ) {
        dispatch(itemsRemoveEstimateVoteAction({ ids: actionItemIds }))
      } else {
        dispatch(itemsAddEstimateVoteAction({ ids: actionItemIds, value }))
      }
    },
    [actionItemIds, actionItems, dispatch, uid]
  )

  const itemsEstimateVoteMenuIcon = useCallback(
    (value: number): IconName => {
      if (myEstimateVotes.length < 1) {
        return 'blank'
      }

      if (myEstimateVotes.every((v) => v.value === value)) {
        return 'tick'
      } else if (myEstimateVotes.some((v) => v.value === value)) {
        return 'small-minus'
      } else {
        return 'blank'
      }
    },
    [myEstimateVotes]
  )

  const handleClick = useCallback(() => toggleEstimate(value), [
    toggleEstimate,
    value,
  ])

  return (
    <Menu.Item
      labelElement={<Icon icon={itemsEstimateVoteMenuIcon(value)} />}
      key={value}
      onClick={handleClick}
      text={value}
    />
  )
}

const ResolveEstimateItem: React.FC<EstimateItemProps> = ({
  actionItems,
  actionItemIds,

  value,
}) => {
  const dispatch = useDispatch()

  const itemsEstimateMenuIcon = useCallback(
    (value: number): IconName => {
      if (actionItems.length < 1) {
        return 'blank'
      }

      if (actionItems.every((i) => i.data.estimate?.value === value)) {
        return 'tick'
      } else if (actionItems.some((i) => i.data.estimate?.value === value)) {
        return 'small-minus'
      } else {
        return 'blank'
      }
    },
    [actionItems]
  )

  const handleClick = useCallback(() => {
    dispatch(itemsResolveEstimateAction({ ids: actionItemIds, value }))
  }, [actionItemIds, dispatch, value])

  return (
    <Menu.Item
      labelElement={<Icon icon={itemsEstimateMenuIcon(value)} />}
      key={value}
      onClick={handleClick}
      text={value}
    />
  )
}

type MyVote = {
  id: UUID
  value: number
}
