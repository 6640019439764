/*---- External -------------------------------------------------------------*/

import React from 'react'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import UserIcon from './UserIcon'

import { RolesMap } from 'canvas-shared/lib/types/permissions.types'
import { useDarkMode } from '../../hooks/darkMode/useDarkMode'

/*---------------------------------------------------------------------------*/

interface UserGalleryProps {
  hideOverflowCount?: boolean
  limit?: number
  roles: RolesMap
  userIconClassName?: string
}

export const UserGallery: React.FC<UserGalleryProps> = ({
  hideOverflowCount,
  limit = DEFAULT_MAX_PEOPLE_TO_DISPLAY,
  roles,
  userIconClassName = 'h-4 w-4',
}) => {
  const darkMode = useDarkMode()
  const uids = Object.keys(roles.users)

  const usersToDisplay = uids.slice(0, limit)
  const overflowUserCount = uids.length - usersToDisplay.length

  return (
    <div className="flex flex-row flex-shrink-0 items-center -space-x-2">
      {usersToDisplay.map((u, i) => (
        <div
          className={classNames('p-0.25 rounded-full', {
            'clip-person': i + 1 < usersToDisplay.length && !darkMode,
          })}
          key={u}
        >
          <UserIcon
            className={classNames(
              userIconClassName,
              'dark:bg-background-four-dark'
            )}
            uid={u}
          />
        </div>
      ))}
      {!hideOverflowCount && overflowUserCount > 0 && (
        <div className="text-xxs pl-3 font-light select-none">
          +{overflowUserCount}
        </div>
      )}
    </div>
  )
}

const DEFAULT_MAX_PEOPLE_TO_DISPLAY = 3
