/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { getUsersData, myUserSelector } from './users'

import { selectMyUid } from '../auth/auth'

import { NO_EDITS } from '../config/defaults'

import { createEqualByKeySelector } from '../helpers/createEqualByKeySelector'

import { UUID } from 'canvas-shared/lib/types'
import { ItemEdit } from 'canvas-shared/lib/types/Item.types'

/*---------------------------------------------------------------------------*/

export const createItemEditsSelector = (itemId: UUID) =>
  createSelector(selectMyUid, getUsersData, (uid, users) => {
    if (!users) {
      return undefined
    }

    return Object.keys(users)?.reduce((res: ItemEdit | undefined, userId) => {
      const user = users[userId]
      if (userId !== uid && !!user.edits && !!user.edits[itemId]) {
        res = user.edits[itemId]
      }
      return res
    }, undefined)
  })

const createEditsSelector = createEqualByKeySelector('edits')

export const selectMyEdits = createEditsSelector(myUserSelector, (user) =>
  !!user && !!user.edits ? user.edits : NO_EDITS
)

export const selectEditsItemIds = createSelector(selectMyEdits, (edits) =>
  Object.keys(edits)
)

export const editsByOtherUsersItemIdsSelector = createSelector(
  selectMyUid,
  getUsersData,
  (uid, users) => {
    if (!users) {
      return NO_IDS
    }

    return Object.keys(users).reduce((res: UUID[], userId) => {
      if (userId !== uid) {
        const user = users[userId]
        if (!!user.edits) {
          const editingItemIds = Object.keys(user.edits)
          if (editingItemIds.length > 0) {
            res = res.concat(...editingItemIds)
          }
        }
      }
      return res
    }, NO_IDS)
  }
)

export const editsByOtherUsersUserIdsSelector = createSelector(
  selectMyUid,
  getUsersData,
  (uid, users) => {
    if (!users) {
      return NO_IDS
    }

    return Object.keys(users).reduce((res: UUID[], userId) => {
      if (userId !== uid) {
        const user = users[userId]
        if (!!user.edits) {
          return res.concat(userId)
        }
      }
      return res
    }, NO_IDS)
  }
)

const NO_IDS: UUID[] = []
