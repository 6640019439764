/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'

import uniq from 'lodash.uniq'

/*---- Qualdesk -------------------------------------------------------------*/

import { getUsersData } from './users'

import { PresenceSelection } from '../../../types'
import { selectMyUid } from '../auth/auth'
import { positionsDataSelector } from './positions'

/*---------------------------------------------------------------------------*/

export const otherUserSelectionsSelector = createSelector(
  selectMyUid,
  getUsersData,
  positionsDataSelector,
  (uid, users, positionsData) => {
    if (!users) {
      return NO_PRESENCE
    }

    return Object.keys(users).reduce((res: PresenceSelection[], userId) => {
      if (userId !== uid) {
        const selectedItemIds = Object.keys(
          users[userId].selection || {}
        ).filter((id) => !!positionsData[id])
        if (!!selectedItemIds && selectedItemIds.length > 0) {
          res = res.concat({
            userId,
            selectedItemIds,
          })
        }
      }
      return res
    }, NO_PRESENCE)
  }
)

export const selectionsByOtherUsersItemIdsSelector = createSelector(
  otherUserSelectionsSelector,
  (otherUserSelections) =>
    uniq(otherUserSelections.map((s) => s.selectedItemIds).flat())
)

const NO_PRESENCE: PresenceSelection[] = []
