/*---- External -------------------------------------------------------------*/

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  getItemsArray,
  getItemsData,
  itemSelector,
  itemsHaveVotesSelector,
  itemsHaveEstimateVotesSelector,
  itemsHaveEmojisSelector,
  itemIdsSelector,
} from '../../selectors/board/items'
import { createSelectPendingItem } from '../../selectors/board/pending'

import { Item } from 'canvas-shared/lib/types/Item.types'
import { UUID } from 'canvas-shared/lib/types'
import { RootState } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

export const useItems = () => useSelector(getItemsArray)
export const useItemsData = () => useSelector(getItemsData)
export const useItemIds = () => useSelector(itemIdsSelector)

export const useItem = (id: UUID): Item.AnyItem | undefined => {
  const selector = (state: RootState) => itemSelector(state, id)
  const commmittedItem = useSelector(selector)
  const selectPendingItem = createSelectPendingItem(id)
  const pendingItem = useSelector(selectPendingItem)
  return commmittedItem || pendingItem
}

export const useItemsHaveReactions = () => ({
  itemsHaveVotes: useSelector(itemsHaveVotesSelector),
  itemsHaveEstimateVotes: useSelector(itemsHaveEstimateVotesSelector),
  itemsHaveEmojis: useSelector(itemsHaveEmojisSelector),
})
