/*---- External -------------------------------------------------------------*/

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { containerInvitationsSelector } from '../../selectors/container/invitations'

/*---------------------------------------------------------------------------*/

export const useContainerInvitations = () =>
  useSelector(containerInvitationsSelector)
