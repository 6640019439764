/*---- External -------------------------------------------------------------*/

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { useSearchContext } from '../../../contexts/Search'
import { searchResultsSelector } from '../../selectors/board/searchResults'

import { RootState } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

export const useSearchResults = () => {
  const { searchQuery } = useSearchContext()
  const searchResults = useSelector((state: RootState) =>
    searchResultsSelector(state, searchQuery)
  )
  return searchResults
}
