/*---- External -------------------------------------------------------------*/

import { Action, AnyAction } from 'redux'
import { UUID } from 'canvas-shared/lib/types'

import { RouterState } from 'redux-first-history'

import { IToastProps } from '@blueprintjs/core'
import {
  ActionCreatorWithPayload,
  AsyncThunk,
  ThunkDispatch,
} from '@reduxjs/toolkit'
import { ActionsObservable, StateObservable } from 'redux-observable'
import { Observable } from 'rxjs'

/*---- Qualdesk -------------------------------------------------------------*/

import { FirebaseReducer, FirestoreReducer } from './firestore'
import { InteractionMode, Timer } from '.'
import { CursorMode } from 'canvas-shared/lib/types/CursorMode.types'

import type { Params } from '../router/routes'

import type { Board } from 'canvas-shared/lib/types/Board.types'
import type {
  BoardConnection,
  GoogleSheetsBoardConnection,
} from 'canvas-shared/lib/types/BoardConnection.types'
import type { Invitation } from 'canvas-shared/lib/types/invitation.types'
import type { Item } from 'canvas-shared/lib/types/Item.types'
import type { ItemPosition } from 'canvas-shared/lib/types/Position.types'
import type { User, UserDetails } from 'canvas-shared/lib/types/user.types'
import type { KeyValuePair } from 'canvas-shared/lib/types/utilities.types'
import type { UserConnection } from 'canvas-shared/lib/types/UserConnection.types'
import type { Org } from 'canvas-shared/lib/types/Org.types'
import type { Team } from 'canvas-shared/lib/types/Team.types'
import type {
  CursorPosition,
  XYPosition,
} from 'canvas-shared/lib/types/scrollAndPosition.types'
import type { Gesture } from 'canvas-shared/lib/types/pointerEvents.types'

/*---------------------------------------------------------------------------*/

export interface RootState {
  router: RouterState
  params: Params
  firebase: FirebaseReducer<UserDetails, SchemaRTDB>
  firestore: FirestoreReducer<SchemaFirestore>
  board: BoardState
  toast: ToastState
  org: OrgState
}

export interface SchemaRTDB {
  timers: Timer
  interactionModes: InteractionMode
  system: string
}

export interface SchemaFirestore {
  connections: BoardConnection
  containersByUid: Omit<Board, 'id'>
  containersByTeamMembership: Omit<Board, 'id'>
  invitations: Omit<Invitation, 'id'>
  items: Omit<Item.AnyItem, 'id'>
  teamsByInvitation: Omit<Team, 'id'>
  teamsEveryone: Omit<Team, 'id'>
  teamInvitations: Omit<Invitation, 'id'>
  users: Omit<UserDetails, 'uid'>
  userConnections: UserConnection
  recentConnectionsGoogleSheets: GoogleSheetsBoardConnection
}

export type ClipboardStateSlice = { text?: string } | null
export type ContainerStateSlice = { containerId: UUID }
export type CursorModeStateSlice = CursorMode
export type DragRectStateSlice = DOMRect | null

export type ItemsStateSlice = {
  dynamicItems: DataLoader<Record<UUID, Item.DynamicItem>>
  items: DataLoader<Record<UUID, Item.AnyItem>>
  positions: DataLoader<Record<UUID, ItemPosition>>
}
export type SessionStateSlice = DataLoader<User> & {
  localData: {
    autoScroll: {
      status: AutoScrollStatus
    }
    isScrollingWithGesture: boolean
    gestures: Record<number, Gesture>
    cursor?: CursorPosition
    selectionRect?: {
      startX: number
      startY: number
      endX: number
      endY: number
    }
  }
}
export type ShouldSelectItemIdStateSlice = UUID | null
export type UsersStateSlice = DataLoader<Record<UUID, User>>
export enum AutoScrollStatus {
  STOPPED,
  SHOULD_START,
  STARTED,
  SHOULD_STOP,
}

export interface ContextMenuStateSlice {
  coords?: XYPosition
  actionItems: Item.BaseItem[]
  actionItemsPositions: ItemPosition[]
  actionGroup?: Item.CanvasGroupWithMemberData
}

export interface PendingStateSlice {
  items: Record<string, Item.AnyItem>
  positions: Record<string, ItemPosition>
}

export interface BoardState {
  clipboard: ClipboardStateSlice
  container: ContainerStateSlice
  contextMenu: ContextMenuStateSlice
  cursorMode: CursorModeStateSlice
  items: ItemsStateSlice
  pending: PendingStateSlice
  session: SessionStateSlice
  shouldSelectItemId: ShouldSelectItemIdStateSlice
  users: UsersStateSlice
}

export type OrgState = DataLoader<Org>

export interface ToastPiece {
  id: UUID
  toast: IToastProps
}

export type ToastState = Record<UUID, ToastPiece>

export type PayloadWithContainerAndUserIds<T> = {
  containerId: UUID
  userId: UUID
  content: T
}

export type PayloadWithContainerAndUid<T> = {
  containerId: UUID
  uid: UUID
  state: RootState
  content: T
}

export type UpdatePayload<T> = KeyValuePair<UUID, T>
export interface ThunkAPI {
  dispatch: ThunkDispatch<RootState, void, AnyAction>
  state: RootState
}

export interface ActionWithPayload<T> extends AnyAction {
  payload: T
}

export interface DataLoaded<T> {
  loaded: true
  data: T
}

export interface DataNotLoaded<D> {
  loaded: false
  data: D
}

export type DataLoader<T, D = T> = DataLoaded<T> | DataNotLoaded<D>

export type ActionHandler<X, Y = X> =
  | ActionCreatorWithPayload<X, string>
  | AsyncThunk<Y, X, ThunkAPI>

export interface EpicWithDifferentActions<
  InputAction extends Action<any>,
  OutputAction
> {
  (
    action$: ActionsObservable<InputAction>,
    state$: StateObservable<RootState>
  ): Observable<OutputAction>
}
