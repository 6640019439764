/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import {
  CreateableItemType,
  ItemType,
} from 'canvas-shared/lib/types/Item.types'

import {
  defaultRectangleBackgroundColor,
  defaultStickyBackgroundColor,
  defaultTextColor,
} from 'canvas-shared/lib/config/colors'
import { defaultTextAlignment } from '../../../config/textAlignments'

import { defaultTextSize } from '../../../config/textSizes'
import { defaultTextStyle } from '../../../config/textStyles'
import { getLocalStorageOption } from './getLocalStorageOption'
import { ColorKey } from 'canvas-shared/lib/types/color.types'
import {
  TextAlignmentKey,
  TextStyleKey,
} from 'canvas-shared/lib/types/text.types'

/*---------------------------------------------------------------------------*/
export const getLocalSettingsForItemType = (type: CreateableItemType) => {
  switch (type) {
    case ItemType.Sticky:
      return {
        backgroundColor: getLocalStorageOption<ColorKey>(
          'stickyBackgroundColor',
          defaultStickyBackgroundColor.color
        ),
      }
    case ItemType.Card:
      return {
        backgroundColor: getLocalStorageOption<ColorKey>(
          'cardBackgroundColor',
          defaultRectangleBackgroundColor.color
        ),
      }
    case ItemType.Text:
      return {
        textAlignment: getLocalStorageOption<TextAlignmentKey>(
          'textAlignment',
          defaultTextAlignment.id
        ),
        textSize: getLocalStorageOption<number>('textSize', defaultTextSize),
        textColor: getLocalStorageOption<ColorKey>(
          'textColor',
          defaultTextColor.color
        ),
        textStyle: getLocalStorageOption<TextStyleKey[]>(
          'textStyle',
          defaultTextStyle
        ),
      }
    default:
      return {}
  }
}
