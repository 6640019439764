/*---- External -------------------------------------------------------------*/

import React, { useEffect, useState } from 'react'

import { Dialog, Radio, RadioGroup, Tag } from '@blueprintjs/core'
import { GearSix, Moon, Sun } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import useLocalStorageSettings from '../../../hooks/useLocalStorageSettings'
import { useClassNamesWithDarkMode } from '../../../hooks/darkMode/useClassNamesWithDarkMode'

import LoadingSpinner from '../../loading/LoadingSpinner'

import { DarkMode } from 'canvas-shared/lib/types/settings.types'
import { SettingsPanelProps } from '../../../types/settings'

/*---------------------------------------------------------------------------*/

interface DarkModePanelProps extends SettingsPanelProps {
  message?: string
}

export const DarkModePanel: React.FC<DarkModePanelProps> = ({
  className,
  message,
}) => {
  const { useDarkModeSetting } = useLocalStorageSettings()
  const [userDarkModeSetting, setUserDarkModeSetting] = useDarkModeSetting()

  const [reloading, setReloading] = useState(false)

  const chosenSetting = userDarkModeSetting || DarkMode.LIGHT

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value as DarkMode
    if (userDarkModeSetting !== newValue) {
      setReloading(true)
      setUserDarkModeSetting(newValue)
    }
  }

  return (
    <div className={className}>
      {reloading && <DarkModeReloader />}
      <div className="flex flex-col space-y-1">
        <div className="mb-1 font-semibold">Experimental dark mode support</div>
        {!!message && (
          <div className="text-text-secondary-light dark:text-text-secondary-dark">
            {message}
          </div>
        )}
        <RadioGroup
          className="flex flex-col pt-2 space-y-2"
          onChange={handleChange}
          selectedValue={chosenSetting}
        >
          <Radio className="flex items-center m-0" value={DarkMode.LIGHT}>
            <span className="flex items-center mb-0.5 space-x-2">
              <Sun className="mb-0.5" size={16} />
              <span className="mb-0.5">Light mode</span>
            </span>
          </Radio>
          <Radio className="flex items-center m-0" value={DarkMode.DARK}>
            <span className="flex items-center mb-0.5 space-x-2">
              <Moon size={16} />
              <span>Dark mode</span>
              <Tag minimal>Experimental</Tag>
            </span>
          </Radio>
          <Radio className="flex items-center m-0" value={DarkMode.SYSTEM}>
            <span className="flex items-center mb-0.5 space-x-2">
              <GearSix size={16} />
              <span>Use system settings</span>
              <Tag minimal>Experimental</Tag>
            </span>
          </Radio>
        </RadioGroup>
      </div>
    </div>
  )
}

const DarkModeReloader: React.FC = () => {
  const { classNamesWithDarkMode } = useClassNamesWithDarkMode()

  useEffect(() => {
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }, [])

  return (
    <Dialog
      className={classNamesWithDarkMode(
        'p-8 w-auto text-center flex flex-col space-y-8'
      )}
      isOpen
    >
      <LoadingSpinner />
      <div className="font-semibold">Changing theme</div>
    </Dialog>
  )
}
