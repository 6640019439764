/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { UUID } from 'canvas-shared/lib/types'
import { ItemEdit } from 'canvas-shared/lib/types/Item.types'

/*---------------------------------------------------------------------------*/

export const NO_EDITS: Record<UUID, ItemEdit> = {}
export const NO_SELECTION: Record<UUID, true> = {}
export const NO_SELECTED_ITEM_IDS: UUID[] = []
