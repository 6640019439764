/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { LinePosition } from 'canvas-shared/lib/types/Position.types'

/*---------------------------------------------------------------------------*/

export const getSVGViewBox = (
  { startX, startY, endX, endY }: LinePosition,
  lineThickness: number
) => {
  const minX = Math.min(startX, endX) - lineThickness / 2
  const maxX = Math.max(startX, endX) + lineThickness / 2
  const minY = Math.min(startY, endY) - lineThickness / 2
  const maxY = Math.max(startY, endY) + lineThickness / 2

  return `${minX} ${minY} ${maxX - minX} ${maxY - minY}`
}
