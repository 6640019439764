/*---- External -------------------------------------------------------------*/

import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit'

import uniq from 'lodash.uniq'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  createAdjacentItemAction,
  duplicateItemsAction,
  itemsDatabaseLoadedAction,
  itemsDatabaseRemoveOneAction,
  itemsDatabaseSetOneAction,
  removeItemsAction,
} from '../../../actionCreators/items'

import {
  dynamicItemsDatabaseLoadedAction,
  dynamicItemsDatabaseRemoveOneAction,
  dynamicItemsDatabaseSetOneAction,
  groupItemsAction,
  removeItemsFromGroupAction,
  updateGroupColorAction,
} from '../../../actionCreators/dynamicItems'

import { ItemsStateSlice } from '../../../../types/redux'
import { pendingCommitAllAction } from '../../../actionCreators/pending'
import {
  movePositionsAction,
  movePositionsWithGestureAction,
  positionRemoveAction,
  positionSetAllAction,
  positionSetOneAction,
  setPositionAction,
  setPositionsAction,
  transformLinePositionAction,
  transformPositionsAction,
} from '../../../actionCreators/positions'
import { GUTTER } from '../../../../helpers/CardCoordsFactory'
import { Item, ItemType } from 'canvas-shared/lib/types/Item.types'
import { offsetPosition } from '../../../../helpers/offsetPosition'
import { generateOperation } from '../../../actions/helpers/generateOperation'
import { app } from '../../../../config/firebase'
import { RectPosition } from 'canvas-shared/lib/types/Position.types'

import {
  isBackgroundColorItem,
  isGroup,
  isOptionalBackgroundColorItem,
  isLineItem,
  isTextItem,
  isEstimateableItem,
  isVoteableItem,
} from 'canvas-shared/lib/helpers/itemTypes'
import { cutAction } from '../../../actionCreators/clipboard'
import { resetSessionAction } from '../../../actionCreators/session'

import {
  itemsAddEmojiAction,
  itemsAddEstimateVoteAction,
  itemsAddTextStyleAction,
  itemsAddVoteAction,
  itemsClearAllEmojisAction,
  itemsClearAllEstimateVotesAction,
  itemsClearAllVotesAction,
  itemsClearEmojisAction,
  itemsClearEstimatesAction,
  itemsClearVotesAction,
  itemSetColumnAction,
  itemSetTitleAction,
  itemsRemoveEmojiAction,
  itemsRemoveEstimateVoteAction,
  itemsRemoveTextStyleAction,
  itemsRemoveVoteAction,
  itemsResolveEstimateAction,
  itemsSetBackgroundColorAction,
  itemsSetLineColorAction,
  itemsSetLineThicknessAction,
  itemsSetTextAlignmentAction,
  itemsSetTextColorAction,
  itemsSetTextSizeAction,
} from '../../../actionCreators/itemData'
import { EstimateType } from 'canvas-shared/lib/types/item/estimate.types'

/*---------------------------------------------------------------------------*/

const initialState: ItemsStateSlice = {
  dynamicItems: {
    loaded: false,
    data: {},
  },
  items: {
    loaded: false,
    data: {},
  },
  positions: {
    loaded: false,
    data: {},
  },
}

const extraReducers = (builder: ActionReducerMapBuilder<ItemsStateSlice>) => {
  builder

    .addCase(resetSessionAction, () => {
      return initialState
    })
    // Database: Items
    .addCase(itemsDatabaseLoadedAction, (state) => {
      state.items.loaded = true
    })
    .addCase(itemsDatabaseRemoveOneAction, (state, action) => {
      const id = action.payload
      delete state.items.data[id]
    })
    .addCase(itemsDatabaseSetOneAction, (state, action) => {
      const id = action.payload.key
      state.items.data[id] = action.payload.value
    })

    // Database: Positions
    .addCase(positionSetAllAction, (state, action) => {
      state.positions.data = action.payload || initialState.positions.data
      state.positions.loaded = true
    })
    .addCase(positionSetOneAction, (state, action) => {
      const id = action.payload.key
      if (!!action.payload.value) {
        state.positions.data[id] = action.payload.value
      }
    })
    .addCase(positionRemoveAction, (state, action) => {
      const id = action.payload
      delete state.positions.data[id]
    })

    // Database: Dynamic Items
    .addCase(dynamicItemsDatabaseLoadedAction, (state) => {
      state.dynamicItems.loaded = true
    })
    .addCase(dynamicItemsDatabaseRemoveOneAction, (state, action) => {
      const id = action.payload
      delete state.dynamicItems.data[id]
    })
    .addCase(dynamicItemsDatabaseSetOneAction, (state, action) => {
      const id = action.payload.key
      state.dynamicItems.data[id] = action.payload.value
    })

    .addCase(pendingCommitAllAction.fulfilled, (state, action) => {
      action.payload.items.forEach((item) => {
        state.items.data[item.id] = item
      })

      action.payload.positions.forEach((position) => {
        state.positions.data[position.id] = position
      })
    })

    // Positions
    .addCase(transformPositionsAction.fulfilled, (state, action) => {
      Object.entries(action.payload).forEach(([id, position]) => {
        state.positions.data[id] = position
      })
    })

    .addCase(movePositionsWithGestureAction.fulfilled, (state, action) => {
      Object.entries(action.payload).forEach(([id, position]) => {
        state.positions.data[id] = position
      })
    })

    .addCase(setPositionAction, (state, action) => {
      state.positions.data[action.payload.id] = action.payload
    })

    .addCase(setPositionsAction, (state, action) => {
      const positions = action.payload
      positions.forEach((position) => {
        state.positions.data[position.id] = position
      })
    })

    .addCase(movePositionsAction, (state, action) => {
      const positions = action.payload
      positions.forEach((position) => {
        state.positions.data[position.id] = position
      })
    })

    .addCase(transformLinePositionAction.fulfilled, (state, action) => {
      if (action.payload) {
        const id = action.payload.id
        state.positions.data[id] = action.payload
      }
    })

    .addCase(duplicateItemsAction.fulfilled, (state, action) => {
      const { items, uid, newIdsMap, startZ } = action.payload
      const operation = generateOperation(uid)

      items.forEach((item, i) => {
        const newId = newIdsMap[item.id]
        const position = state.positions.data[item.id]
        state.positions.data[newId] = {
          ...position,
          id: newIdsMap[position.id],
          z: startZ + i,
          ...offsetPosition(position),
          operation,
        }

        state.items.data[newId] = {
          ...item,
          id: newIdsMap[item.id],

          createdAt: app.firestore.Timestamp.now(),
          createdBy: operation,
          updatedAt: app.firestore.Timestamp.now(),
          updatedBy: operation,
        } as Item.AnyItem
      })
    })

    .addCase(createAdjacentItemAction.fulfilled, (state, action) => {
      const { sourceId, newId, uid, canvasWidth, canvasHeight } = action.payload
      const operation = generateOperation(uid)

      const sourceItem = state.items.data[sourceId]
      const sourcePosition = state.positions.data[sourceId] as RectPosition

      if (!sourceItem || !sourcePosition) return

      const positions = Object.values(state.positions.data)
      const startZ =
        (positions.length > 0 ? Math.max(...positions.map((c) => c.z)) : 0) + 1

      // @ts-ignore Typescript is worried about us not having ALL THE FIELDS for ALL THE ITEM TYPES
      state.items.data[newId] = {
        ...sourceItem,
        data: { ...sourceItem.data },
        id: newId,
        operation,
        createdAt: app.firestore.Timestamp.now(),
        createdBy: operation,
        updatedAt: app.firestore.Timestamp.now(),
        updatedBy: operation,
        type: sourceItem.type,
      }
      state.items.data[newId].data.title = ''

      const item = state.items.data[newId]

      if (isVoteableItem(item)) {
        delete item.data.emoji
        delete item.data.votes
      }

      state.positions.data[newId] = {
        ...sourcePosition,
        id: newId,
        top: Math.min(sourcePosition.top, canvasHeight - sourcePosition.height),
        left: Math.min(
          sourcePosition.left + sourcePosition.width + GUTTER,
          canvasWidth - sourcePosition.width
        ),
        z: startZ,
      }
    })

    .addCase(removeItemsAction.fulfilled, (state, action) => {
      const ids = action.payload
      ids.forEach((id) => {
        delete state.items.data[id]
        delete state.positions.data[id]
      })
    })

    // Groups

    .addCase(groupItemsAction.fulfilled, (state, action) => {
      const { add, remove } = action.payload

      state.dynamicItems.data[add.id] = add

      remove.forEach((g) => {
        delete state.dynamicItems.data[g.id]
      })
    })
    .addCase(removeItemsFromGroupAction, (state, action) => {
      const { group, itemIds } = action.payload

      const dynamicItem = state.dynamicItems.data[group.id]

      if (isGroup(dynamicItem)) {
        const newMemberIds = dynamicItem.memberIds.filter(
          (id) => !itemIds.includes(id)
        )

        if (newMemberIds.length < 2) {
          delete state.dynamicItems.data[group.id]
        } else {
          dynamicItem.memberIds = newMemberIds
        }
      }
    })
    .addCase(updateGroupColorAction, (state, action) => {
      const { color, groupId } = action.payload

      if (state.dynamicItems.data[groupId].type === ItemType.Group) {
        ;(state.dynamicItems.data[
          groupId
        ] as Item.CanvasGroup).data.color = color
      }
    })

    // Copy and paste

    .addCase(cutAction.fulfilled, (state, action) => {
      const itemIds = action.payload

      itemIds.forEach((id) => {
        delete state.items.data[id]
        delete state.positions.data[id]
      })
    })

    // Item data

    .addCase(itemSetTitleAction.fulfilled, (state, action) => {
      const { itemId, title, updatedAt, updatedBy } = action.payload
      state.items.data[itemId].data.title = title
      state.items.data[itemId].updatedAt = updatedAt
      state.items.data[itemId].updatedBy = updatedBy
    })
    .addCase(itemSetColumnAction.fulfilled, (state, action) => {
      const { column, itemId, text, updatedAt, updatedBy } = action.payload
      if (state.items.data[itemId].type === ItemType.GoogleSheetsCard) {
        ;(state.items.data[itemId] as Item.GoogleSheetsCard).data.columns[
          column
        ] = text
        state.items.data[itemId].updatedAt = updatedAt
        state.items.data[itemId].updatedBy = updatedBy
      }
    })
    .addCase(itemsSetBackgroundColorAction.fulfilled, (state, action) => {
      const { color, ids, updatedAt, updatedBy } = action.payload
      ids.forEach((itemId) => {
        const item = state.items.data[itemId]
        if (
          isBackgroundColorItem(item) ||
          isOptionalBackgroundColorItem(item)
        ) {
          if (!!color) {
            item.data.backgroundColor = color
          } else if (isOptionalBackgroundColorItem(item)) {
            delete item.data.backgroundColor
          }
          item.updatedAt = updatedAt
          item.updatedBy = updatedBy
        }
      })
    })
    .addCase(itemsSetLineColorAction.fulfilled, (state, action) => {
      const { color, ids, updatedAt, updatedBy } = action.payload
      ids.forEach((itemId) => {
        const item = state.items.data[itemId]
        if (isLineItem(item)) {
          item.data.lineColor = color
          item.updatedAt = updatedAt
          item.updatedBy = updatedBy
        }
      })
    })
    .addCase(itemsSetLineThicknessAction.fulfilled, (state, action) => {
      const { ids, thickness, updatedAt, updatedBy } = action.payload
      ids.forEach((itemId) => {
        const item = state.items.data[itemId]
        if (isLineItem(item)) {
          item.data.lineThickness = thickness
          item.updatedAt = updatedAt
          item.updatedBy = updatedBy
        }
      })
    })
    .addCase(itemsSetTextAlignmentAction.fulfilled, (state, action) => {
      const { alignment, ids, updatedAt, updatedBy } = action.payload
      ids.forEach((itemId) => {
        const item = state.items.data[itemId]
        if (isTextItem(item)) {
          item.data.textAlignment = alignment
          item.updatedAt = updatedAt
          item.updatedBy = updatedBy
        }
      })
    })
    .addCase(itemsSetTextSizeAction.fulfilled, (state, action) => {
      const { ids, size, updatedAt, updatedBy } = action.payload
      ids.forEach((itemId) => {
        const item = state.items.data[itemId]
        if (isTextItem(item)) {
          item.data.textSize = size
          item.updatedAt = updatedAt
          item.updatedBy = updatedBy
        }
      })
    })
    .addCase(itemsSetTextColorAction.fulfilled, (state, action) => {
      const { color, ids, updatedAt, updatedBy } = action.payload
      ids.forEach((itemId) => {
        const item = state.items.data[itemId]
        if (isTextItem(item)) {
          item.data.textColor = color
          item.updatedAt = updatedAt
          item.updatedBy = updatedBy
        }
      })
    })
    .addCase(itemsAddTextStyleAction.fulfilled, (state, action) => {
      const { ids, style, updatedAt, updatedBy } = action.payload
      ids.forEach((itemId) => {
        const item = state.items.data[itemId]
        if (isTextItem(item)) {
          item.data.textStyle = uniq(item.data.textStyle.concat(style))
          item.updatedAt = updatedAt
          item.updatedBy = updatedBy
        }
      })
    })
    .addCase(itemsRemoveTextStyleAction.fulfilled, (state, action) => {
      const { ids, style, updatedAt, updatedBy } = action.payload
      ids.forEach((itemId) => {
        const item = state.items.data[itemId]
        if (isTextItem(item)) {
          item.data.textStyle = item.data.textStyle.filter((s) => s !== style)
          item.updatedAt = updatedAt
          item.updatedBy = updatedBy
        }
      })
    })
    .addCase(itemsAddEstimateVoteAction.fulfilled, (state, action) => {
      const { ids, updatedAt, updatedBy, value } = action.payload
      ids.forEach((itemId) => {
        const item = state.items.data[itemId]
        if (isEstimateableItem(item)) {
          item.data.estimateVotes = {
            ...item.data.estimateVotes,
            [updatedBy.uid]: { estimateType: EstimateType.STORY_POINTS, value },
          }
          item.updatedAt = updatedAt
          item.updatedBy = updatedBy
        }
      })
    })
    .addCase(itemsRemoveEstimateVoteAction.fulfilled, (state, action) => {
      const { ids, updatedAt, updatedBy } = action.payload
      ids.forEach((itemId) => {
        const item = state.items.data[itemId]
        if (
          isEstimateableItem(item) &&
          !!item.data.estimateVotes?.[updatedBy.uid]
        ) {
          delete item.data.estimateVotes[updatedBy.uid]
          item.updatedAt = updatedAt
          item.updatedBy = updatedBy
        }
      })
    })
    .addCase(itemsResolveEstimateAction.fulfilled, (state, action) => {
      const { ids, updatedAt, updatedBy, value } = action.payload
      ids.forEach((itemId) => {
        const item = state.items.data[itemId]
        if (isEstimateableItem(item)) {
          delete item.data.estimateVotes
          item.data.estimate = {
            estimateType: EstimateType.STORY_POINTS,
            value,
          }
          item.updatedAt = updatedAt
          item.updatedBy = updatedBy
        }
      })
    })
    .addCase(itemsClearAllEstimateVotesAction.fulfilled, (state, action) => {
      const { updatedAt, updatedBy } = action.payload
      Object.keys(state.items.data).forEach((itemId) => {
        const item = state.items.data[itemId]
        if (isEstimateableItem(item)) {
          delete item.data.estimateVotes
          item.updatedAt = updatedAt
          item.updatedBy = updatedBy
        }
      })
    })
    .addCase(itemsClearEstimatesAction.fulfilled, (state, action) => {
      const { ids, updatedAt, updatedBy } = action.payload
      ids.forEach((itemId) => {
        const item = state.items.data[itemId]
        if (isEstimateableItem(item)) {
          delete item.data.estimate
          item.updatedAt = updatedAt
          item.updatedBy = updatedBy
        }
      })
    })
    .addCase(itemsAddEmojiAction.fulfilled, (state, action) => {
      const { emojiId, ids, updatedAt, updatedBy } = action.payload
      ids.forEach((itemId) => {
        const item = state.items.data[itemId]
        if (isVoteableItem(item)) {
          item.data.emoji = {
            ...item.data.emoji,
            [emojiId]: uniq(
              (item.data.emoji?.[emojiId] || []).concat(updatedBy.uid)
            ),
          }
          item.updatedAt = updatedAt
          item.updatedBy = updatedBy
        }
      })
    })
    .addCase(itemsRemoveEmojiAction.fulfilled, (state, action) => {
      const { emojiId, ids, updatedAt, updatedBy } = action.payload
      ids.forEach((itemId) => {
        const item = state.items.data[itemId]
        if (isVoteableItem(item)) {
          item.data.emoji = {
            ...item.data.emoji,
            [emojiId]:
              item.data.emoji?.[emojiId].filter((id) => id !== updatedBy.uid) ||
              [],
          }
          item.updatedAt = updatedAt
          item.updatedBy = updatedBy
        }
      })
    })
    .addCase(itemsAddVoteAction.fulfilled, (state, action) => {
      const { ids, updatedAt, updatedBy } = action.payload
      ids.forEach((itemId) => {
        const item = state.items.data[itemId]
        if (isVoteableItem(item)) {
          item.data.votes = uniq((item.data.votes || []).concat(updatedBy.uid))
          item.updatedAt = updatedAt
          item.updatedBy = updatedBy
        }
      })
    })
    .addCase(itemsRemoveVoteAction.fulfilled, (state, action) => {
      const { ids, updatedAt, updatedBy } = action.payload
      ids.forEach((itemId) => {
        const item = state.items.data[itemId]
        if (isVoteableItem(item)) {
          item.data.votes = item.data.votes?.filter((v) => v !== updatedBy.uid)
          item.updatedAt = updatedAt
          item.updatedBy = updatedBy
        }
      })
    })
    .addCase(itemsClearAllEmojisAction.fulfilled, (state, action) => {
      const { updatedAt, updatedBy } = action.payload
      Object.keys(state.items.data).forEach((itemId) => {
        const item = state.items.data[itemId]
        if (isVoteableItem(item)) {
          delete item.data.emoji
          item.updatedAt = updatedAt
          item.updatedBy = updatedBy
        }
      })
    })
    .addCase(itemsClearAllVotesAction.fulfilled, (state, action) => {
      const { updatedAt, updatedBy } = action.payload
      Object.keys(state.items.data).forEach((itemId) => {
        const item = state.items.data[itemId]
        if (isVoteableItem(item)) {
          delete item.data.votes
          item.updatedAt = updatedAt
          item.updatedBy = updatedBy
        }
      })
    })
    .addCase(itemsClearEmojisAction.fulfilled, (state, action) => {
      const { ids, updatedAt, updatedBy } = action.payload
      ids.forEach((itemId) => {
        const item = state.items.data[itemId]
        if (isVoteableItem(item)) {
          delete item.data.emoji
          item.updatedAt = updatedAt
          item.updatedBy = updatedBy
        }
      })
    })
    .addCase(itemsClearVotesAction.fulfilled, (state, action) => {
      const { ids, updatedAt, updatedBy } = action.payload
      ids.forEach((itemId) => {
        const item = state.items.data[itemId]
        if (isVoteableItem(item)) {
          delete item.data.votes
          item.updatedAt = updatedAt
          item.updatedBy = updatedBy
        }
      })
    })
}

export const itemsSlice = createSlice({
  name: 'items',
  reducers: {},
  initialState,
  extraReducers,
})
