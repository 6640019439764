/*---- External -------------------------------------------------------------*/

import { useCallback, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useFirestore, useFirebase } from 'react-redux-firebase'
import { generatePath, useHistory } from 'react-router-dom'

import { DocumentData, DocumentReference } from '@firebase/firestore-types'

import validator from 'validator'

import { Intent } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { useContainerId } from '../../../contexts/Container'

import { containerPath } from 'canvas-shared/lib/paths/containerPath'

import {
  generateInvitationsPath,
  generateTeamsPath,
  generateTeamRolesPath,
  generateUserRolesPath,
} from '../../paths/containerPaths'

import { showToastAction } from '../../actionCreators/toast'

import { selectMyUid } from '../../selectors/auth/auth'
import { useContainerInvitations } from '../../selectorHooks/container/useContainerInvitations'

import { ShareSetting } from 'canvas-shared/lib/types/Board.types'
import { PermissionLevel } from 'canvas-shared/lib/types/permissions.types'
import { UUID } from 'canvas-shared/lib/types'
import { Invitation } from 'canvas-shared/lib/types/invitation.types'

import { Routes } from '../../../router/routes'
import { generateUrlFromPath } from '../../../helpers/generateUrlFromPath'
import { generateOperation } from '../helpers/generateOperation'
import { useItemUpdateActions } from './helpers/useItemUpdateActions'

/*---------------------------------------------------------------------------*/

interface BoardActions {
  archiveBoard: (archived: boolean) => Promise<void>
  addUserToBoard: (userId: UUID) => Promise<[void, void]>
  addUserToBoardByEmail: (
    email: string
  ) => Promise<DocumentReference<DocumentData>>
  deleteInvitation: (invitationId: UUID) => Promise<void>
  removeUserFromBoard: (userId: UUID) => Promise<[void, void]>
  updateBoardSharedSettings: (
    shared: ShareSetting,
    emailDomains?: string[]
  ) => Promise<void>
  updateBoardUrl: (url: string) => Promise<void>
  updateBoardTitle: (title: string) => Promise<void>
  moveBoardToTeam: (teamId: UUID) => Promise<[void, void]>
  removeBoardFromTeam: () => Promise<[void, void]>
}

export const useBoardActions = (): BoardActions => {
  const firestore = useFirestore()
  const database = useFirebase()
  const containerId = useContainerId()
  const uid = useSelector(selectMyUid)
  const invitations = useContainerInvitations()
  const dispatch = useDispatch()
  const { push } = useHistory()

  const { generateItemUpdate } = useItemUpdateActions()

  const boardPath = containerPath(containerId)
  const boardDocRef = firestore.doc(boardPath)
  const invitationsCollectionRef = firestore.collection(
    generateInvitationsPath(containerId)
  )

  const archiveBoard = useCallback(
    async (archived: boolean = true) => {
      const action = {
        text: 'View',
        onClick: () => {
          push(
            archived
              ? Routes.WORKSPACE_ARCHIVED.path
              : Routes.WORKSPACE_PERSONAL.path
          )
        },
      }
      await boardDocRef.update(generateItemUpdate({ archived }))
      dispatch(
        showToastAction({
          intent: Intent.SUCCESS,
          message: archived ? 'Board archived' : 'Board unarchived',
          action,
        })
      )
    },
    [boardDocRef, dispatch, generateItemUpdate, push]
  )

  const addUserToBoard = useCallback(
    (userId: UUID) =>
      Promise.all([
        boardDocRef.update(
          generateItemUpdate({
            [`roles.users.${userId}`]: PermissionLevel.WRITE,
          })
        ),
        database
          .ref(generateUserRolesPath(containerId, userId))
          .set(PermissionLevel.WRITE),
      ]),
    [boardDocRef, containerId, database, generateItemUpdate]
  )

  const addUserToBoardByEmail = useCallback(
    (email: string) => {
      if (
        validator.isEmail(email) &&
        !invitations?.find((i) => i.email === email)
      ) {
        const BOARD_PATH = generatePath(Routes.CANVAS.path, {
          containerId,
        })
        const BOARD_URL = generateUrlFromPath(BOARD_PATH)
        const operation = generateOperation(uid)

        const invitation: Omit<Invitation, 'id'> = {
          email,
          level: PermissionLevel.WRITE,
          invitedBy: uid,
          url: BOARD_URL,
          createdAt: firestore.FieldValue.serverTimestamp(),
          createdBy: operation,
          updatedAt: firestore.FieldValue.serverTimestamp(),
          updatedBy: operation,
        }

        return invitationsCollectionRef.add(generateItemUpdate(invitation))
      }
      return Promise.reject()
    },
    [
      containerId,
      firestore.FieldValue,
      generateItemUpdate,
      invitations,
      invitationsCollectionRef,
      uid,
    ]
  )

  const deleteInvitation = useCallback(
    (invitationId: UUID) => invitationsCollectionRef.doc(invitationId).delete(),
    [invitationsCollectionRef]
  )

  const removeUserFromBoard = useCallback(
    (userId: UUID) =>
      Promise.all([
        boardDocRef.update(
          generateItemUpdate({
            [`roles.users.${userId}`]: firestore.FieldValue.delete(),
          })
        ),
        database.ref(generateUserRolesPath(containerId, userId)).remove(),
      ]),
    [
      boardDocRef,
      containerId,
      database,
      firestore.FieldValue,
      generateItemUpdate,
    ]
  )

  const updateBoardSharedSettings = useCallback(
    (shared: ShareSetting, emailDomains?: string[]) =>
      boardDocRef.update(
        generateItemUpdate({
          'settings.shared': shared,
          'settings.sharedWithDomains':
            emailDomains || firestore.FieldValue.delete(),
        })
      ),
    [boardDocRef, firestore.FieldValue, generateItemUpdate]
  )

  const updateBoardUrl = useCallback(
    (url: string) =>
      boardDocRef.update(
        generateItemUpdate({
          'settings.url': url,
        })
      ),
    [boardDocRef, generateItemUpdate]
  )

  const updateBoardTitle = useCallback(
    (title: string) =>
      boardDocRef.update(generateItemUpdate({ 'data.title': title })),
    [boardDocRef, generateItemUpdate]
  )

  const moveBoardToTeam = useCallback(
    (teamId: UUID) =>
      Promise.all([
        boardDocRef.update(
          generateItemUpdate({
            [`roles.teams.membership`]: teamId,
          })
        ),
        database.ref(generateTeamRolesPath(containerId)).set(teamId),
      ]),
    [boardDocRef, containerId, database, generateItemUpdate]
  )

  const removeBoardFromTeam = useCallback(
    () =>
      Promise.all([
        boardDocRef.update(
          generateItemUpdate({
            [`roles.teams`]: firestore.FieldValue.delete(),
          })
        ),
        database.ref(generateTeamsPath(containerId)).remove(),
      ]),
    [
      boardDocRef,
      containerId,
      database,
      firestore.FieldValue,
      generateItemUpdate,
    ]
  )

  return useMemo(
    () => ({
      archiveBoard,
      addUserToBoard,
      addUserToBoardByEmail,
      deleteInvitation,
      removeUserFromBoard,
      updateBoardSharedSettings,
      updateBoardUrl,
      updateBoardTitle,
      moveBoardToTeam,
      removeBoardFromTeam,
    }),
    [
      archiveBoard,
      addUserToBoard,
      addUserToBoardByEmail,
      deleteInvitation,
      removeUserFromBoard,
      updateBoardSharedSettings,
      updateBoardUrl,
      updateBoardTitle,
      moveBoardToTeam,
      removeBoardFromTeam,
    ]
  )
}
