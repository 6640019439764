/*---- External -------------------------------------------------------------*/

import { createSelectorCreator, defaultMemoize } from 'reselect'
import isEqual from 'lodash.isequal'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

export const createEqualByKeySelector = (key: string, debug: boolean = false) =>
  createSelectorCreator(defaultMemoize, (a: any, b: any) => {
    const aValue = !!a ? a[key] : undefined
    const bValue = !!b ? b[key] : undefined
    const res = isEqual(aValue, bValue)
    if (debug) console.log(key, res, aValue, bValue)
    return res
  })
