/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { RootState } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

export const orgLoadedSelector = (state: RootState) => state.org.loaded
export const orgNameSelector = (state: RootState) => state.org.data.name
