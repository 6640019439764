/*---- External -------------------------------------------------------------*/

import React from 'react'

import {
  Button,
  Classes,
  Divider,
  Intent,
  Radio,
  RadioGroup,
} from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { permissionLevelLabel } from 'canvas-shared/lib/helpers/permissionLevelLabel'

import { PermissionLevel } from 'canvas-shared/lib/types/permissions.types'
import { Popover2 } from '@blueprintjs/popover2'
import classNames from 'classnames'
import { CaretDown } from 'phosphor-react'
import { useClassNamesWithDarkMode } from '../../../hooks/darkMode/useClassNamesWithDarkMode'

/*---------------------------------------------------------------------------*/

interface TeamUserLevelProps {
  disabled?: boolean
  level: PermissionLevel
  onChange: (level: PermissionLevel) => void
  onRemove?: () => void
}

export const TeamUserLevel: React.FC<TeamUserLevelProps> = (props) => {
  const { classNamesWithDarkMode } = useClassNamesWithDarkMode()
  return (
    <Popover2
      className="ml-auto"
      popoverClassName={classNamesWithDarkMode('overflow-hidden')}
      content={<TeamUserLevelMenu {...props} />}
      disabled={props.disabled}
      minimal
      placement="bottom-end"
    >
      <Button
        disabled={props.disabled}
        minimal
        rightIcon={
          <span
            className={classNames(
              Classes.ICON,
              'text-icon-secondary-light dark:text-icon-secondary-dark'
            )}
          >
            <CaretDown weight="fill" />
          </span>
        }
        text={permissionLevelLabel(props.level)}
      />
    </Popover2>
  )
}

const TeamUserLevelMenu: React.FC<TeamUserLevelProps> = ({
  level,
  onChange,
  onRemove,
}) => {
  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    onChange(Number.parseInt(e.currentTarget.value))
  }

  return (
    <div className="bg-background-floating-light dark:bg-background-floating-dark p-4">
      <RadioGroup
        label={<span className="font-semibold">Access</span>}
        onChange={handleChange}
        selectedValue={level}
      >
        {levelOptions.map(({ value, label }) => (
          <Radio
            key={value}
            label={label}
            value={value}
            className="last:mb-0"
          />
        ))}
      </RadioGroup>
      {!!onRemove && (
        <>
          <Divider className="mx-0 my-4" />
          <Button
            className="-m-2.5"
            minimal
            intent={Intent.DANGER}
            onClick={onRemove}
            text="Remove member"
          />
        </>
      )}
    </div>
  )
}

const levelOptions = [PermissionLevel.ADMIN, PermissionLevel.WRITE].map(
  (value) => ({
    value,
    label: permissionLevelLabel(value),
  })
)
