/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import {
  dynamicItemsDatabaseLoadedAction,
  dynamicItemsDatabaseRemoveOneAction,
  dynamicItemsDatabaseSetOneAction,
} from '../../actionCreators/dynamicItems'

import { generateDynamicItemsPath } from '../../paths/dynamicItemsPaths'

import { useFirestoreCollectionConnect } from '../helpers/useFirestoreCollectionConnect'

import { UUID } from 'canvas-shared/lib/types'
import { Item } from 'canvas-shared/lib/types/Item.types'

/*---------------------------------------------------------------------------*/

export const useConnectDynamicItems = (containerId: UUID) => {
  useFirestoreCollectionConnect<Item.DynamicItem>({
    collectionPath: generateDynamicItemsPath(containerId),
    onChildSet: dynamicItemsDatabaseSetOneAction,
    onChildRemoved: dynamicItemsDatabaseRemoveOneAction,
    onInitialValueLoaded: dynamicItemsDatabaseLoadedAction,
  })
}
