/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { InteractionMode } from '../../../types'
import { createContainerSpecificRTDBDataSelector } from '../helpers/containerSpecificSelectors'

/*---------------------------------------------------------------------------*/

const getInteractionModes = createContainerSpecificRTDBDataSelector(
  'interactionModes'
)

// Because of the way the RTDB schema works, it assumes everything is a record
// We store one mode per canvas and so can access it as below

export const selectInteractionMode = createSelector(
  getInteractionModes,
  (interactionModes) =>
    !!interactionModes && interactionModes.allUsers
      ? interactionModes.allUsers
      : DEFAULT_INTERACTION_MODE
)

const DEFAULT_INTERACTION_MODE = InteractionMode.NORMAL
