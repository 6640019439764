/*---- External -------------------------------------------------------------*/

import React from 'react'
import { useSelector } from 'react-redux'

import { BriefcaseMetal, Users } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import { orgNameSelector } from '../../redux/selectors/auth/org'

import { RichRadioButton } from '../utilities/RichRadioButton'

/*---------------------------------------------------------------------------*/

interface TeamSharingToggleProps {
  everyone: boolean
  shareWithEveryone: () => void
  shareWithTeam: () => void
}

export const TeamSharingToggle: React.FC<TeamSharingToggleProps> = ({
  everyone,
  shareWithEveryone,
  shareWithTeam,
}) => {
  const orgName = useSelector(orgNameSelector)

  return (
    <>
      <RichRadioButton
        checked={!everyone}
        description="You can add and remove people at any time"
        icon={BriefcaseMetal}
        label="Invited members only"
        onClick={shareWithTeam}
      />
      <RichRadioButton
        checked={everyone}
        description={`Anyone you invite to join ${orgName} on Qualdesk in future will have access to this team automatically`}
        icon={Users}
        label={`Everyone at ${orgName}`}
        onClick={shareWithEveryone}
      />
    </>
  )
}
