/*---- External -------------------------------------------------------------*/

import React from 'react'

import { Button, Spinner } from '@blueprintjs/core'

interface IssueBrowserImportOptionsProps {
  importIssues: () => void
  loading: boolean
  onDeselectAll: () => void
  onSelectAll: () => void
  selectedIssuesCount: number
  availableIssuesCount: number
}

export const IssueBrowserImportOptions: React.FC<IssueBrowserImportOptionsProps> = ({
  importIssues,
  loading,
  onDeselectAll,
  onSelectAll,
  selectedIssuesCount,
  availableIssuesCount,
}) => {
  if (loading) {
    return (
      <div className="flex items-center space-x-2">
        <Spinner size={22} />
        <div className="text-text-secondary-light dark:text-text-secondary-dark">
          Adding items…
        </div>
      </div>
    )
  }

  const buttonClasses =
    'hover:bg-button-secondary-hovered-light dark:hover:bg-button-secondary-hovered-dark active:bg-button-secondary-active-light dark:active:bg-button-secondary-active-dark'

  if (selectedIssuesCount > 0) {
    return (
      <>
        <Button fill minimal className={buttonClasses} onClick={onDeselectAll}>
          Deselect
        </Button>
        <Button fill minimal intent="primary" onClick={importIssues}>
          Import {selectedIssuesCount}{' '}
          {selectedIssuesCount === 1 ? 'issue' : 'issues'}
        </Button>
      </>
    )
  }

  return (
    <Button fill minimal className={buttonClasses} onClick={onSelectAll}>
      Select {availableIssuesCount}{' '}
      {availableIssuesCount === 1 ? 'issue' : 'issues'}
    </Button>
  )
}
