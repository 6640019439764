/*---- External -------------------------------------------------------------*/

import React from 'react'
import { Button, IPanelProps } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { GoogleConnectButton } from './GoogleConnectButton'
import { AuthorizationIntro } from '../AuthorizationIntro'

import googleIcon from '../../../assets/integrations/google/google-g-icon.svg'

/*---------------------------------------------------------------------------*/

export const GoogleAuthorization: React.FC<IPanelProps> = ({
  closePanel,
  openPanel,
}) => (
  <div className="flex flex-col h-full">
    <div className="flex flex-col flex-grow justify-center p-6">
      <AuthorizationIntro
        title="Connect to Google"
        description="Sign in to give Qualdesk access to Google"
        iconUrl={googleIcon}
        iconAltText="Google icon"
      />
      <div className="mt-5 text-center">
        <GoogleConnectButton />
      </div>
    </div>
    <footer className="bg-background-five-light dark:bg-background-three-dark border-border-weak-light dark:border-border-weak-dark flex items-center justify-center px-6 py-2 h-16 border-t space-x-4">
      <Button
        fill
        minimal
        className="hover:bg-button-secondary-hovered-light dark:hover:bg-button-secondary-hovered-dark active:bg-button-secondary-active-light dark:active:bg-button-secondary-active-dark"
        onClick={closePanel}
      >
        Cancel
      </Button>
    </footer>
  </div>
)
