/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

export const Footer: React.FC = ({ children }) => (
  <footer className="border-border-weak-light dark:border-border-weak-dark bg-background-five-light dark:bg-background-five-dark h-15 flex flex-shrink-0 items-center justify-center px-6 py-2 border-t space-x-4">
    {children}
  </footer>
)
