/*---- External -------------------------------------------------------------*/

import React, { useEffect, useState } from 'react'

import { useLocation, Redirect } from 'react-router-dom'

import queryString from 'query-string'

/*---- Qualdesk -------------------------------------------------------------*/

import { UserConnectionType } from 'canvas-shared/lib/types/UserConnection.types'
import { useTrelloRedirectPath } from '../../hooks/router/useTrelloRedirectPath'
import { useUserConnectionsActions } from '../../redux/actions/auth/useUserConnectionsActions'

/*---------------------------------------------------------------------------*/

const TrelloCallback: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [
    callbackRedirectPath,
    setCallbackRedirectPath,
  ] = useTrelloRedirectPath()
  const [redirectTo, setRedirectTo] = useState('/')

  let { hash } = useLocation()
  const { token } = queryString.parse(hash)

  const { addConnection, removeConnection } = useUserConnectionsActions()

  const parseAndSaveToken = () => {
    if (token && token.length && typeof token === 'string') {
      addConnection({
        type: UserConnectionType.Trello,
        token: token,
        connected: true,
      })
    } else {
      removeConnection('trello')
    }
    setRedirectTo(callbackRedirectPath || '/')
    setCallbackRedirectPath(undefined)
    setLoading(false)
  }
  useEffect(parseAndSaveToken, [
    addConnection,
    callbackRedirectPath,
    removeConnection,
    setCallbackRedirectPath,
    token,
  ])

  return <>{loading ? 'Please wait...' : <Redirect to={redirectTo} />}</>
}

export default TrelloCallback
