/*---- External -------------------------------------------------------------*/

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import classNames from 'classnames'

import { IMenuItemProps, Menu } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'

/*---- Qualdesk -------------------------------------------------------------*/

import MultiLineImportDialog from '../../import/MultilineImportDialog'

import useLocalStorageSettings from '../../../hooks/useLocalStorageSettings'
import { useBoardToolbarHandlers } from '../../../hooks/board/useBoardToolbarHandlers'

import { selectCursorMode } from '../../../redux/selectors/board/cursor'

import { SplitPopoverButton } from './SplitPopoverButton'
import { HotkeyMenuItem } from './HotkeyMenuItem'

import {
  CreateableItemType,
  ItemType,
} from 'canvas-shared/lib/types/Item.types'
import {
  ItemConfigurations,
  itemTypesForMenu,
  itemTypesForMultiple,
} from '../../../config/itemTypes'
import { toolsCreateMultipleItemsAction } from '../../../redux/actionCreators/tools'

/*---------------------------------------------------------------------------*/

export const AddItemButton: React.FC = () => {
  const cursorMode = useSelector(selectCursorMode)
  const { handleAddCardItem, handleAddStickyItem } = useBoardToolbarHandlers()
  const dispatch = useDispatch()

  const { useItemType } = useLocalStorageSettings()
  const [itemType, setItemType] = useItemType()
  const [multiType, showMultiImportDialog] = useState<CreateableItemType>()

  const handleAddItem = (type: CreateableItemType) => {
    switch (type) {
      case ItemType.Card:
        handleAddCardItem()
        break
      case ItemType.Sticky:
        handleAddStickyItem()
        break
    }
  }

  const closeDialog = () => showMultiImportDialog(undefined)

  const handleAddMulti = (values: string[]) => {
    if (multiType) {
      setItemType(multiType)
      closeDialog()
      dispatch(
        toolsCreateMultipleItemsAction({
          type: multiType,
          data: values.map((title) => ({ title })),
        })
      )
    }
  }

  const handleAddDefault = () => handleAddItem(itemType)

  const Icon = ItemConfigurations[itemType].icon
  const active = cursorMode === ItemConfigurations[itemType].cursorMode

  return (
    <>
      <Popover2
        minimal
        placement="top-start"
        autoFocus={false}
        content={
          <Menu>
            {itemTypesForMenu.map((type) => (
              <AddMenuItem
                key={type}
                type={type}
                hotkey={ItemConfigurations[type].hotkey}
                active={cursorMode === ItemConfigurations[type].cursorMode}
                clickHandler={handleAddItem}
              />
            ))}
            <Menu.Divider />
            <Menu.Item text="Add multiple…">
              {itemTypesForMultiple.map((type) => (
                <AddMenuItem
                  key={type}
                  type={type}
                  clickHandler={showMultiImportDialog}
                />
              ))}
            </Menu.Item>
          </Menu>
        }
      >
        <SplitPopoverButton
          title="Content tools"
          className={classNames({
            'text-icon-accent-light dark:text-icon-accent-dark': active,
          })}
          actionTitle={ItemConfigurations[itemType].text}
          actionHotkey={ItemConfigurations[itemType].hotkey}
          actionIcon={
            Icon && (
              <Icon
                size={24}
                weight={active ? 'duotone' : undefined}
                className="toolbar-icon bp3-icon"
              />
            )
          }
          actionOnClick={handleAddDefault}
        />
      </Popover2>
      <MultiLineImportDialog
        isOpen={!!multiType}
        onClose={closeDialog}
        onImport={handleAddMulti}
        initialType={multiType}
      />
    </>
  )
}

type MenuItemProps = Partial<IMenuItemProps> & {
  type: CreateableItemType
  hotkey?: string
  clickHandler: (type: CreateableItemType) => void
}

const AddMenuItem: React.FC<MenuItemProps> = ({
  type,
  hotkey,
  clickHandler,
  ...menuItemProps
}) => {
  const handleClick = () => clickHandler(type)
  const Icon = ItemConfigurations[type].icon

  return (
    <HotkeyMenuItem
      {...menuItemProps}
      icon={Icon && <Icon size={24} />}
      text={ItemConfigurations[type].text}
      hotkey={hotkey}
      onClick={handleClick}
    />
  )
}
