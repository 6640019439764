/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { canBeDuplicated } from '../../../helpers/canBeDuplicated'

import { selectActiveGestures } from './gestures'
import { mySelectedItemsSelector } from './selections'

import { Cursor } from '../../../types'
import { CursorMode } from 'canvas-shared/lib/types/CursorMode.types'
import { RootState } from '../../../types/redux'
import { GestureMode } from 'canvas-shared/lib/types/pointerEvents.types'

/*---------------------------------------------------------------------------*/

export const selectCursorMode = (state: RootState) => state.board.cursorMode

const getCursor = createSelector(
  selectCursorMode,
  mySelectedItemsSelector,
  selectActiveGestures,
  (cursorMode, selectedItems, activeGestures): Cursor => {
    if (activeGestures.some((g) => g.mode === GestureMode.DUPLICATE)) {
      if (canBeDuplicated(selectedItems)) {
        return Cursor.COPY
      } else {
        return Cursor.NOT_ALLOWED
      }
    }

    switch (cursorMode) {
      case CursorMode.PASTE:
        return Cursor.COPY
      case CursorMode.DRAW_RECTANGLE:
      case CursorMode.DRAW_LINE:
        return Cursor.CROSSHAIR
      case CursorMode.ADD_STICKY:
      case CursorMode.ADD_CARD:
        return Cursor.COPY
      case CursorMode.ADD_TEXT:
        return Cursor.TEXT
      default:
        return Cursor.DEFAULT
    }
  }
)

export const selectCursorClassName = createSelector(
  getCursor,
  (cursor) => `cursor-${cursor}`
)
