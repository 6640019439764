/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { selectMyUid } from '../auth/auth'
import { editsByOtherUsersUserIdsSelector } from './edits'

import {
  DEFAULT_CURSOR_POSITION,
  DEFAULT_SCROLL_DIMENSIONS,
  DEFAULT_SCROLL_OFFSETS,
  DEFAULT_ZOOM_LEVEL,
} from '../../../config/canvas'
import { NO_SELECTION, NO_EDITS } from '../config/defaults'

import { userOnline } from 'canvas-shared/lib/helpers/userOnline'

import { RootState } from '../../../types/redux'
import { User } from 'canvas-shared/lib/types/user.types'
import { UUID } from 'canvas-shared/lib/types'

/*---------------------------------------------------------------------------*/

export function getUsersData(state: RootState) {
  return state.board.users.data
}

const mapDefaultUserValues = (user: Partial<User>): User => ({
  isFocused: true,
  isTouchDevice: true,
  scrollDimensions: DEFAULT_SCROLL_DIMENSIONS,
  scrollOffsets: DEFAULT_SCROLL_OFFSETS,
  zoomLevel: DEFAULT_ZOOM_LEVEL,
  selection: NO_SELECTION,
  edits: NO_EDITS,
  position: DEFAULT_CURSOR_POSITION,
  ...user,
})

export const onlineUserIdsSelector = createSelector(getUsersData, (users) => {
  if (!!users) {
    return Object.keys(users).reduce((res: string[], userId) => {
      if (userOnline(users[userId])) {
        res = res.concat(userId)
      }
      return res
    }, NO_IDS)
  }

  return NO_IDS
})

export const presenceUserIdsSelector = createSelector(
  onlineUserIdsSelector,
  selectMyUid,
  editsByOtherUsersUserIdsSelector,
  (onlineUserIdsSelector, uid, otherUserIds) => {
    return onlineUserIdsSelector.filter(
      (id) => id !== uid && !otherUserIds.includes(id)
    )
  }
)

const getUserId = (state: RootState, userId: UUID | undefined) => userId

export const userSelector = createSelector(
  getUserId,
  getUsersData,
  (userId, users) =>
    !!userId && !!users ? mapDefaultUserValues(users[userId]) : undefined
)

export function myUserSelector(state: RootState) {
  return state.board.session.data
}

const NO_IDS: string[] = []
