/*---- External -------------------------------------------------------------*/

import React from 'react'

import { Lightning } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

interface AuthorizationIntroProps {
  title: string
  description?: string
  iconUrl: string
  iconAltText: string
}

export const AuthorizationIntro: React.FC<AuthorizationIntroProps> = ({
  title,
  description,
  iconUrl,
  iconAltText,
}) => (
  <>
    <div className="bg-background-five-light dark:bg-background-four-dark border-border-weak-light dark:border-border-weak-dark relative flex items-center justify-center mx-auto w-16 h-16 border rounded-xl">
      <img className="w-8 h-8" src={iconUrl} alt={iconAltText} />
      <div className="dark:from-cool-gray-600 dark:to-cool-gray-700 text-icon-tertiary-light absolute -bottom-2 -right-2 flex items-center justify-center w-6 h-6 bg-gradient-to-b rounded-md shadow dark:shadow-sm from-blue-500 to-blue-600">
        <Lightning size={16} weight="duotone" />
      </div>
    </div>
    <h2 className="text-text-primary-light dark:text-text-primary-dark mt-6 text-center text-xl font-semibold">
      {title}
    </h2>
    {!!description && (
      <h3 className="text-text-secondary-light dark:text-text-secondary-dark mt-2 text-center text-sm leading-normal">
        {description}
      </h3>
    )}
  </>
)
