/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'

import { assign } from 'lodash'

/*---- Qualdesk -------------------------------------------------------------*/

import { firestoreDataWithIds } from '../helpers/firestoreDataWithIds'
import { firestoreDataToArray } from '../helpers/firestoreDataToArray'
import { createListenerLoadedSelector } from '../helpers/listenerStatus'

import { RootState } from '../../../types/redux'
import { UUID } from 'canvas-shared/lib/types'
import { Team } from 'canvas-shared/lib/types/Team.types'

/*---------------------------------------------------------------------------*/

const getTeamsByInvitation = (state: RootState) =>
  state.firestore.data.teamsByInvitation

const getTeamsEveryone = (state: RootState) =>
  state.firestore.data.teamsEveryone

export const teamsByInvitationLoadedSelector = createListenerLoadedSelector(
  'teamsByInvitation'
)
export const teamsEveryoneLoadedSelector = createListenerLoadedSelector(
  'teamsEveryone'
)

export const teamsReadySelector = createSelector(
  teamsByInvitationLoadedSelector,
  teamsEveryoneLoadedSelector,
  (a, b) => a && b
)

export const teamMembershipsSelector = createSelector(
  getTeamsByInvitation,
  getTeamsEveryone,
  (byInvitation, everyone) => {
    if (byInvitation !== undefined && everyone !== undefined) {
      return assign(
        {},
        firestoreDataWithIds(byInvitation),
        firestoreDataWithIds(everyone)
      )
    }
    return undefined
  }
)

export const teamMembershipsOrderedSelector = createSelector(
  teamMembershipsSelector,
  (teamsData) => {
    if (teamsData === undefined) {
      return undefined
    } else if (teamsData === null) {
      return []
    }

    const teams = firestoreDataToArray<Team>(teamsData)

    teams.sort((a, b) => a.name.localeCompare(b.name))

    return teams
  }
)

export const teamMembershipIdsSelector = createSelector(
  teamMembershipsOrderedSelector,
  (teams) => (!!teams ? teams.map((t) => t.id) : [])
)

export const createTeamSelector = (teamId: UUID) =>
  createSelector(teamMembershipsSelector, (teamsData) => teamsData?.[teamId])

const getTeamInvitations = (state: RootState) =>
  state.firestore.data.teamInvitations

export const teamInvitationsSelector = createSelector(
  getTeamInvitations,
  (invitationsData) => firestoreDataToArray(invitationsData)
)
