/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { Item, ItemType } from 'canvas-shared/lib/types/Item.types'

/*---------------------------------------------------------------------------*/

const checkItem = (item: Item.BaseItem) => {
  switch (item.type) {
    case ItemType.Card:
    case ItemType.Sticky:
    case ItemType.Text:
    case ItemType.Line:
    case ItemType.Rectangle:
      return true
    default:
      return false
  }
}

export const canBeDuplicated = (items: Item.BaseItem[]) =>
  items.length > 0 && items.every((i) => checkItem(i))
