/*---- External -------------------------------------------------------------*/

import React, { useEffect, useState } from 'react'

import { useInterval } from 'react-interval-hook'

import { DateTime } from 'luxon'

import { Intent, Toast, Toaster, Position } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { useTimerActions } from '../../redux/actions/board/useTimerActions'
import { useTimer } from '../../redux/selectorHooks/board/useTimer'

/*---------------------------------------------------------------------------*/

export const CanvasTimerToast: React.FC = () => {
  const timer = useTimer()
  const { stopTimer } = useTimerActions()

  const [time, setTime] = useState<string>()
  const [timedOut, setTimedOut] = useState(false)

  const tick = () => {
    if (!timedOut) {
      if (timer) {
        const l = DateTime.local()
        const t = DateTime.fromISO(timer.referenceTime)
        const diff = timer.countDown ? t.diff(l) : l.diff(t)

        if (diff.milliseconds <= 1000) {
          if (timer.countDown) {
            setTimedOut(true)
          }
          setTime('00:00')
        } else {
          setTime(diff.toFormat('mm:ss'))
        }
      } else {
        setTime(undefined)
      }
    }
  }

  const { start, stop } = useInterval(tick, 1000, {
    autoStart: false,
    immediate: true,
  })

  useEffect(() => {
    setTimedOut(false)

    if (timer) {
      start()
    } else {
      stop()
    }
  }, [timer, start, stop])

  useEffect(() => {
    if (!timer) {
      setTime(undefined)
    }
  }, [timer])

  if (!timer) {
    return null
  }

  return (
    <Toaster position={Position.BOTTOM}>
      <Toast
        action={{
          onClick: stopTimer,
          text: timedOut ? 'Reset' : 'Stop',
        }}
        className="hide-toast-close-x narrow-toast items-center"
        intent={timedOut ? Intent.DANGER : Intent.PRIMARY}
        message={
          <span className="font-nice-mono font-mono text-xl font-semibold">
            {time}
          </span>
        }
        timeout={0}
      />
    </Toaster>
  )
}
