/*---- External -------------------------------------------------------------*/

import { createAction } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

export const tryAutoScrollAction = createAction('autoScroll/try')
export const startAutoScrollAction = createAction('autoScroll/start')
export const tickAutoScrollAction = createAction('autoScroll/tick')
export const stopAutoScrollAction = createAction('autoScroll/stop')
