/*---- External -------------------------------------------------------------*/

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { itemPositionSelector } from '../../selectors/board/positions'
import { createSelectPendingPosition } from '../../selectors/board/pending'

import { UUID } from 'canvas-shared/lib/types'
import { RootState } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

export const useItemPosition = (itemId: UUID) => {
  const committedPosition = useSelector((state: RootState) =>
    itemPositionSelector(state, itemId)
  )
  const selectPendingPosition = createSelectPendingPosition(itemId)
  const pendingPosition = useSelector(selectPendingPosition)

  return committedPosition || pendingPosition
}
