/*---- External -------------------------------------------------------------*/

import { StateObservable } from 'redux-observable'
import { withLatestFrom } from 'rxjs/internal/operators/withLatestFrom'
import { map } from 'rxjs/internal/operators/map'
import { OperatorFunction, pipe } from 'rxjs'

/*---- Qualdesk -------------------------------------------------------------*/

import type {
  ActionWithPayload,
  PayloadWithContainerAndUid,
  RootState,
} from '../../../types/redux'

/*---------------------------------------------------------------------------*/

export const mapPayloadUserAndContainerId = <T extends ActionWithPayload<P>, P>(
  state$: StateObservable<RootState>
): OperatorFunction<T, PayloadWithContainerAndUid<P>> =>
  pipe(
    withLatestFrom(state$),
    map<[T, RootState], PayloadWithContainerAndUid<P>>(
      ([{ payload }, state]) => {
        const {
          firebase: {
            auth: { uid },
          },
          board: {
            container: { containerId },
          },
        } = state

        return { content: payload, uid, containerId, state }
      }
    )
  )
