/*---- External -------------------------------------------------------------*/

import React, { useCallback, useMemo, useState } from 'react'

import classNames from 'classnames'

import {
  Button,
  Divider,
  IButtonProps,
  Radio,
  RadioGroup,
} from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'

import { IconProps } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import { useClassNamesWithDarkMode } from '../../hooks/darkMode/useClassNamesWithDarkMode'

/*---------------------------------------------------------------------------*/

interface Option<T> {
  label: string
  value: T
  icon?: React.ForwardRefExoticComponent<IconProps>
  disabled?: boolean
}

interface SortOption<T> extends Option<T> {
  icon: React.ForwardRefExoticComponent<IconProps>
}

interface OrderSortPopoverProps<S, O> extends IButtonProps {
  sort: S
  order?: O
  sortOptions: Array<SortOption<S>>
  orderOptions?: Array<Option<O>>
  onChangeSort: (value: S) => void
  onChangeOrder?: (value: O) => void
}

export const SortOrderPopoverButton = <S extends string, O extends string>({
  sort,
  order,
  sortOptions,
  orderOptions,
  onChangeSort: _onChangeSort,
  onChangeOrder: _onChangeOrder,
  ...buttonProps
}: OrderSortPopoverProps<S, O>) => {
  const { classNamesWithDarkMode } = useClassNamesWithDarkMode()
  const [isOpen, setIsOpen] = useState(false)

  const onClose = useCallback(() => setIsOpen(false), [])
  const onClick = useCallback(() => setIsOpen(!isOpen), [isOpen])

  const onChangeSort = useCallback(
    (event: React.FormEvent<HTMLInputElement>) =>
      _onChangeSort(event.currentTarget.value as S),
    [_onChangeSort]
  )
  const onChangeOrder = useCallback(
    (event: React.FormEvent<HTMLInputElement>) =>
      !!_onChangeOrder
        ? _onChangeOrder(event.currentTarget.value as O)
        : undefined,
    [_onChangeOrder]
  )

  const selectedOrderOption = useMemo(
    () =>
      !!orderOptions
        ? orderOptions.find((o) => o.value === order) || orderOptions[0]
        : undefined,
    [order, orderOptions]
  )

  const selectedSortOption = useMemo(
    () => sortOptions.find((o) => o.value === sort) || sortOptions[0],
    [sort, sortOptions]
  )

  const { icon: Icon } = selectedSortOption

  return (
    <Popover2
      minimal
      placement="bottom-end"
      popoverClassName={classNamesWithDarkMode('overflow-hidden')}
      isOpen={isOpen}
      onClose={onClose}
      content={
        <div className="bg-background-floating-light dark:bg-background-floating-dark p-4">
          <RadioGroup label="Sort" onChange={onChangeSort} selectedValue={sort}>
            {sortOptions.map(({ value, label, disabled }) => (
              <Radio
                key={value}
                label={label}
                value={value}
                disabled={disabled}
                className="last:mb-0"
              />
            ))}
          </RadioGroup>
          {!!orderOptions && !!selectedOrderOption && (
            <>
              <Divider className="mx-0 my-4" />
              <RadioGroup
                label="Order"
                onChange={onChangeOrder}
                selectedValue={order}
              >
                {orderOptions.map(({ value, label, disabled }) => (
                  <Radio
                    key={value}
                    label={selectedOrderOption.label}
                    value={value}
                    disabled={disabled}
                    className="last:mb-0"
                  />
                ))}
              </RadioGroup>
            </>
          )}
        </div>
      }
    >
      <Button
        className={classNames(
          'text-text-secondary-light dark:text-text-secondary-dark active:bg-background-two-light dark:active:bg-background-two-dark px-3 py-1',
          {
            'hover:bg-background-three-light dark:hover:bg-background-three-dark': !isOpen,
            'bg-background-two-light dark:bg-background-two-dark': isOpen,
          }
        )}
        active={isOpen}
        minimal
        onClick={onClick}
        icon={
          <Icon
            size={16}
            weight="bold"
            className="text-icon-secondary-light dark:text-icon-secondary-dark"
          />
        }
        text={
          !!selectedOrderOption
            ? selectedOrderOption.label
            : selectedSortOption.label
        }
        {...buttonProps}
      />
    </Popover2>
  )
}
