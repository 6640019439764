/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { containerPath } from 'canvas-shared/lib/paths/containerPath'

import { UUID } from '../../types'

/*---------------------------------------------------------------------------*/

export const generateInteractionModesPath = (containerId: UUID) =>
  `${containerPath(containerId)}/interactionModes`
export const generateInteractionModePath = (containerId: UUID) =>
  `${generateInteractionModesPath(containerId)}/allUsers`
