/*---- External -------------------------------------------------------------*/

import React from 'react'

import { RouteProps, match } from 'react-router-dom'

/*---- Qualdesk -------------------------------------------------------------*/

import Home from '../pages/home/Home'
import Canvas from '../pages/canvas/Canvas'

import GoogleCallback from '../pages/connections/GoogleCallback'
import JiraCallback from '../pages/connections/JiraCallback'
import TrelloCallback from '../pages/connections/TrelloCallback'
import { LinearCallback } from '../pages/connections/LinearCallback'

import EmailCallback from '../pages/auth/EmailCallback'
import AuthUserDetails from '../pages/auth/AuthUserDetails'
import SignIn from '../pages/auth/SignIn'
import SignOut from '../pages/auth/SignOut'
import { Workspace } from '../pages/workspace/Workspace'
import { SignUp } from '../pages/signup/SignUp'

/*---------------------------------------------------------------------------*/

export enum RouteAuthType {
  PUBLIC,
  SIGNED_IN,
  DETAILS_COMPLETE,
}

export interface VerifiedRouteProps extends RouteProps {
  auth: RouteAuthType
  maxAuth?: RouteAuthType
  computedMatch?: match<Params>
  excludeFromRouteList?: boolean
  path: string
}

export const Routes: Record<string, VerifiedRouteProps> = {
  HOME: {
    path: '/',
    children: <Home />,
    exact: true,
    auth: RouteAuthType.DETAILS_COMPLETE,
  },
  WORKSPACE_PERSONAL: {
    path: '/w/personal',
    children: <Workspace />,
    exact: true,
    auth: RouteAuthType.DETAILS_COMPLETE,
  },
  WORKSPACE_SHARED_WITH_ME: {
    path: '/w/personal/shared',
    children: <Workspace />,
    exact: true,
    auth: RouteAuthType.DETAILS_COMPLETE,
  },
  WORKSPACE_ARCHIVED: {
    path: '/w/personal/archived',
    children: <Workspace />,
    exact: true,
    auth: RouteAuthType.DETAILS_COMPLETE,
  },
  WORKSPACE_TEAM: {
    path: '/w/team/:teamId',
    children: <Workspace />,
    exact: true,
    auth: RouteAuthType.DETAILS_COMPLETE,
  },
  WORKSPACE_TEAM_SETTINGS: {
    path: '/w/team/:teamId/settings',
    children: <Workspace />,
    exact: true,
    auth: RouteAuthType.DETAILS_COMPLETE,
  },
  ACCOUNT_SETTINGS: {
    path: '/w/personal/settings',
    children: <Workspace />,
    exact: true,
    auth: RouteAuthType.DETAILS_COMPLETE,
  },
  CANVAS: {
    path: '/c/:containerId',
    children: <Canvas />,
    exact: false,
    auth: RouteAuthType.DETAILS_COMPLETE,
  },
  CANVAS_CONNECTIONS: {
    path: '/c/:containerId/connections',
    children: <Canvas />,
    exact: false,
    auth: RouteAuthType.DETAILS_COMPLETE,
    excludeFromRouteList: true,
  },
  GOOGLE_CALLBACK: {
    path: '/connections/google/callback',
    children: <GoogleCallback />,
    exact: true,
    auth: RouteAuthType.SIGNED_IN,
  },
  JIRA_CALLBACK: {
    path: '/connections/jira/callback',
    children: <JiraCallback />,
    exact: true,
    auth: RouteAuthType.SIGNED_IN,
  },
  TRELLO_CALLBACK: {
    path: '/connections/trello/callback',
    children: <TrelloCallback />,
    exact: true,
    auth: RouteAuthType.SIGNED_IN,
  },
  LINEAR_CALLBACK: {
    path: '/connections/linear/callback',
    children: <LinearCallback />,
    exact: true,
    auth: RouteAuthType.SIGNED_IN,
  },
  AUTH_USER_DETAILS: {
    path: '/auth/details',
    children: <AuthUserDetails />,
    exact: true,
    auth: RouteAuthType.SIGNED_IN,
    maxAuth: RouteAuthType.DETAILS_COMPLETE,
  },
  AUTH_EMAIL_CALLBACK: {
    path: '/auth/email/callback',
    children: <EmailCallback />,
    exact: true,
    auth: RouteAuthType.PUBLIC,
    maxAuth: RouteAuthType.SIGNED_IN,
  },
  AUTH_SIGN_IN: {
    path: '/auth/signin',
    children: <SignIn />,
    exact: true,
    auth: RouteAuthType.PUBLIC,
    maxAuth: RouteAuthType.SIGNED_IN,
  },
  AUTH_SIGN_OUT: {
    path: '/auth/signout',
    children: <SignOut />,
    exact: true,
    auth: RouteAuthType.SIGNED_IN,
  },
  SIGN_UP: {
    path: '/signup',
    children: <SignUp />,
    exact: true,
    auth: RouteAuthType.PUBLIC,
    maxAuth: RouteAuthType.PUBLIC,
  },
}

export const Urls: { [key: string]: string } = Object.keys(Routes).reduce(
  (res, key) => ({
    ...res,
    [key]: `${window.location.protocol}//${window.location.host}${
      (Routes as { [key: string]: VerifiedRouteProps })[key].path
    }`,
  }),
  {}
)

export interface Params {
  teamId?: string
  containerId?: string
}

export const routeList: VerifiedRouteProps[] = Object.values(Routes).filter(
  (r) => !r.excludeFromRouteList
)
