/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { RootState } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

export const deployCommitHashSelector = (state: RootState) =>
  state.firebase.data.system?.deploy_commit_hash
