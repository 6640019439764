/*---- External -------------------------------------------------------------*/

import React, { useEffect, useRef, useState } from 'react'

import { Intent, Button } from '@blueprintjs/core'

import { DateTime } from 'luxon'
import { useInterval } from 'react-interval-hook'

/*---- Qualdesk -------------------------------------------------------------*/

import { useLatestVersion } from '../../redux/selectorHooks/system/useLatestVersion'
import { usePersistentToast } from '../usePersistentToast'

/*---------------------------------------------------------------------------*/

const FORCE_UPDATE_WARNING_TIME_IN_MINUTES = 5
const DELAY_UNTIL_NEXT_NOTIFICATION_IN_MINUTES = 30

const refreshToUpdate = () => {
  window.location.reload(true)
}

export const useSystemUpdateNotifications = () => {
  const latestVersion = useLatestVersion()
  const [currentVersion, setCurrentVersion] = useState(latestVersion)
  const { showOrUpdate, dismiss } = usePersistentToast()

  const notifyIfUpdateIsAvailable = () => {
    if (!!latestVersion && latestVersion !== currentVersion) {
      if (!!currentVersion) {
        const handleLater = () => {
          dismiss()
          setTimeout(
            notifyIfUpdateIsAvailable,
            DELAY_UNTIL_NEXT_NOTIFICATION_IN_MINUTES * 60 * 1000
          )
        }

        const message = (
          <div className="flex flex-row items-center">
            <div className="flex flex-col space-y-1 w-64">
              <UpdateCountdown />
            </div>
            <div className="-my-1 -mr-2 p-0 flex flex-col items-end">
              <Button minimal onClick={refreshToUpdate} text="Update now" />
              <Button
                minimal
                onClick={handleLater}
                text={`Try in ${DELAY_UNTIL_NEXT_NOTIFICATION_IN_MINUTES} minutes`}
              />
            </div>
          </div>
        )

        showOrUpdate({
          className: 'hide-toast-close-x items-center',
          icon: 'cloud-download',
          intent: Intent.PRIMARY,
          message,
          timeout: 0,
        })
      }
      setCurrentVersion(latestVersion)
    }
  }

  useEffect(notifyIfUpdateIsAvailable, [
    currentVersion,
    dismiss,
    latestVersion,
    notifyIfUpdateIsAvailable,
    showOrUpdate,
  ])
}

const UpdateCountdown: React.FC = () => {
  const [time, setTime] = useState<string>()

  const referenceTime = useRef(
    DateTime.local().plus({ minutes: FORCE_UPDATE_WARNING_TIME_IN_MINUTES })
  )

  const tick = () => {
    const l = DateTime.local()
    const t = referenceTime.current
    const diff = t.diff(l)

    if (diff.milliseconds <= 1000) {
      refreshToUpdate()
    } else {
      setTime(diff.toFormat('mm:ss'))
    }
  }

  useInterval(tick, 1000, {
    autoStart: true,
    immediate: true,
  })

  return (
    <>
      <div className="font-semibold">Qualdesk update available</div>
      <div>Your browser will refresh in {time}</div>
    </>
  )
}
