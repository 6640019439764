/*---- External -------------------------------------------------------------*/

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { selectZoomLevel } from '../../selectors/board/zoom'

/*---------------------------------------------------------------------------*/

export const useZoomLevel = () => useSelector(selectZoomLevel)
