/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import {
  userRemoveAction,
  userSetAllAction,
  userSetOneAction,
} from '../../actionCreators/users'

import { generateUsersPath } from '../../paths/userPaths'

import { useRTDBConnect } from '../helpers/useRTDBConnect'

import { UUID } from 'canvas-shared/lib/types'

/*---------------------------------------------------------------------------*/

export const useConnectUsers = (containerId: UUID) => {
  useRTDBConnect({
    path: generateUsersPath(containerId),
    onChildSet: userSetOneAction,
    onChildRemoved: userRemoveAction,
    onInitialValue: userSetAllAction,
    splitter: (changes) => changes[0]?.key,
  })
}
