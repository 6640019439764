/*---- External -------------------------------------------------------------*/

import React, { useEffect, useRef, useState } from 'react'
import { useFirebase } from 'react-redux-firebase'

/*---- Qualdesk -------------------------------------------------------------*/

import { useContainerId } from '../../contexts/Container'
import convertToPx from '../../helpers/convertToPx'
import { containerPath } from 'canvas-shared/lib/paths/containerPath'
import { useBoard } from '../../redux/selectorHooks/board/useBoard'

import {
  ItemPosition,
  RectPosition,
} from 'canvas-shared/lib/types/Position.types'
import { isRectPosition } from 'canvas-shared/lib/helpers/isRectPosition'

/*---------------------------------------------------------------------------*/

export const BoardPreview: React.FC = () => {
  const firebase = useFirebase()
  const previewRef = useRef<HTMLDivElement>(null)
  const {
    data: { canvasHeight, canvasWidth },
  } = useBoard()
  const containerId = useContainerId()
  const [positions, setPositions] = useState<RectPosition[]>([])

  const loadPositions = () => {
    firebase
      .database()
      .ref(containerPath(containerId))
      .once('value', (snapshot) => {
        if (!!snapshot) {
          const container = snapshot.val()

          if (!!container) {
            const positions = container.positions as Record<
              string,
              Omit<ItemPosition, 'id'>
            >

            if (!!positions) {
              setPositions(
                Object.keys(positions).reduce((res: RectPosition[], id) => {
                  const pos = positions[id]
                  if (isRectPosition(pos)) {
                    res = res.concat({ id, ...pos })
                  }
                  return res
                }, [])
              )
            }
          }
        }
      })
  }

  useEffect(loadPositions, [containerId, firebase])

  const previewStyle = !!previewRef.current
    ? {
        height: convertToPx(
          (canvasHeight * previewRef.current.clientWidth) / canvasWidth
        ),
        width: convertToPx(previewRef.current.clientWidth),
      }
    : {}

  return (
    <div className="relative overflow-hidden" ref={previewRef}>
      <div style={previewStyle}>
        {positions.map(({ height, width, left, top }, i) => (
          <div
            className="bg-blue-gray-600 dark:bg-cool-gray-400 absolute bg-opacity-50"
            key={i}
            style={{
              left: `${(left / canvasWidth) * 100}%`,
              top: `${(top / canvasHeight) * 100}%`,
              height: `${(height / canvasHeight) * 100}%`,
              width: `${(width / canvasWidth) * 100}%`,
            }}
          />
        ))}
      </div>
    </div>
  )
}
