/*---- External -------------------------------------------------------------*/

import { AsyncThunkAction } from '@reduxjs/toolkit'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { AnyAction } from 'redux'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  movePendingPositionsToAction,
  movePositionsAction,
  movePositionsWithGestureAction,
  setPositionAction,
  setPositionsAction,
  transformLinePositionAction,
  transformPositionsAction,
} from '../actionCreators/positions'

import { isRectPosition } from 'canvas-shared/lib/helpers/isRectPosition'
import {
  generateItemPositionPath,
  generatePositionsPath,
} from '../paths/positionsPaths'

import { ItemPosition } from 'canvas-shared/lib/types/Position.types'
import { pendingCommitAllAction } from '../actionCreators/pending'
import { createRTDBEpic } from './helpers/createRTDBEpic'
import {
  ActionWithPayload,
  EpicWithDifferentActions,
  RootState,
} from '../../types/redux'
import { filter, map, withLatestFrom } from 'rxjs/operators'
import { itemPositionSelector } from '../selectors/board/positions'

import {
  createAdjacentItemAction,
  CreateAdjacentItemActionPayload,
  duplicateItemsAction,
  DuplicateItemsActionPayload,
  removeItemsAction,
} from '../actionCreators/items'
import { UUID } from 'canvas-shared/lib/types'
import { generateOperation } from '../actions/helpers/generateOperation'
import prepareForFirestore from 'canvas-shared/lib/helpers/prepareForFirestore'
import { setShouldSelectAction } from '../actionCreators/shouldSelect'
import { pointerMoveAction } from '../actionCreators/pointers'
import { mySessionSelector } from '../selectors/board/session'
import { MousePosition } from 'canvas-shared/lib/types/scrollAndPosition.types'
import { scrollOrZoomChangedAction } from '../actionCreators/scroll'
import { Gesture } from 'canvas-shared/lib/types/pointerEvents.types'
import { GestureMode } from 'canvas-shared/lib/types/pointerEvents.types'
import { selectPendingPositions } from '../selectors/board/pending'

/*---------------------------------------------------------------------------*/

const positionSetEpic = createRTDBEpic(
  setPositionAction,
  ({ content, containerId, uid, database }) => {
    const positionsPath = generatePositionsPath(containerId)
    const operation = generateOperation(uid)
    const update = { ...content, operation }

    return database().ref(`${positionsPath}/${content.id}`).update(update)
  }
)

const positionsSetEpic = createRTDBEpic(
  setPositionsAction,
  ({ content, containerId, uid, database }) => {
    const positionsPath = generatePositionsPath(containerId)
    const operation = generateOperation(uid)
    const update = content.reduce(
      (res: Record<string, ItemPosition>, position: ItemPosition) => ({
        ...res,
        [`${positionsPath}/${position.id}`]: { ...position, operation },
      }),
      {}
    )
    return database().ref().update(update)
  }
)

const positionsMoveEpic = createRTDBEpic(
  movePositionsAction,
  ({ content, containerId, uid, database }) => {
    const positionsPath = generatePositionsPath(containerId)
    const operation = generateOperation(uid)
    const update = content.reduce(
      (res: Record<string, any>, pos: ItemPosition) => {
        const id = pos.id
        if (isRectPosition(pos)) {
          return {
            ...res,
            [`${positionsPath}/${id}/left`]: pos.left,
            [`${positionsPath}/${id}/top`]: pos.top,
            [`${positionsPath}/${id}/height`]: pos.height,
            [`${positionsPath}/${id}/width`]: pos.width,
            [`${positionsPath}/${id}/z`]: pos.z,
            [`${positionsPath}/${id}/operation`]: operation,
          }
        } else {
          return {
            ...res,
            [`${positionsPath}/${id}/startX`]: pos.startX,
            [`${positionsPath}/${id}/startY`]: pos.startY,
            [`${positionsPath}/${id}/endX`]: pos.endX,
            [`${positionsPath}/${id}/endY`]: pos.endY,
            [`${positionsPath}/${id}/z`]: pos.z,
            [`${positionsPath}/${id}/operation`]: operation,
          }
        }
      },
      {}
    )

    return database().ref().update(update)
  }
)

const positionsTransformEpic = createRTDBEpic(
  [
    movePositionsWithGestureAction.fulfilled,
    transformPositionsAction.fulfilled,
  ],
  ({ content, containerId, uid, database }) => {
    const positionsPath = generatePositionsPath(containerId)
    const operation = generateOperation(uid)
    const update = Object.keys(content).reduce(
      (res: Record<string, any>, id) => {
        const pos = content[id]
        if (isRectPosition(pos)) {
          return {
            ...res,
            [`${positionsPath}/${id}/left`]: pos.left,
            [`${positionsPath}/${id}/top`]: pos.top,
            [`${positionsPath}/${id}/height`]: pos.height,
            [`${positionsPath}/${id}/width`]: pos.width,
            [`${positionsPath}/${id}/z`]: pos.z,
            [`${positionsPath}/${id}/operation`]: operation,
          }
        } else {
          return {
            ...res,
            [`${positionsPath}/${id}/startX`]: pos.startX,
            [`${positionsPath}/${id}/startY`]: pos.startY,
            [`${positionsPath}/${id}/endX`]: pos.endX,
            [`${positionsPath}/${id}/endY`]: pos.endY,
            [`${positionsPath}/${id}/z`]: pos.z,
            [`${positionsPath}/${id}/operation`]: operation,
          }
        }
      },
      {}
    )

    return database().ref().update(update)
  }
)

const linePositionTransformEpic = createRTDBEpic(
  transformLinePositionAction.fulfilled,
  ({ content, containerId, uid, database }) => {
    const positionsPath = generatePositionsPath(containerId)
    const operation = generateOperation(uid)
    const position = content

    if (!!position) {
      const update = {
        [`${positionsPath}/${position.id}/startX`]: position.startX,
        [`${positionsPath}/${position.id}/startY`]: position.startY,
        [`${positionsPath}/${position.id}/endX`]: position.endX,
        [`${positionsPath}/${position.id}/endY`]: position.endY,
        [`${positionsPath}/${position.id}/z`]: position.z,
        [`${positionsPath}/${position.id}/operation`]: operation,
      }

      return database().ref().update(update)
    } else {
      return Promise.resolve()
    }
  }
)

const pendingCommitAllPositionsEpic = createRTDBEpic(
  pendingCommitAllAction.fulfilled,
  ({ content: { positions }, containerId, database, state }) => {
    const db = database()
    const positionsPath = generatePositionsPath(containerId)
    const update = positions.reduce(
      (res: Record<string, ItemPosition>, position: ItemPosition) => {
        res[`${positionsPath}/${position.id}`] = position
        return res
      },
      {}
    )

    return db.ref().update(update)
  }
)

const duplicateItemsEpic: Epic<
  ActionWithPayload<DuplicateItemsActionPayload>,
  ActionWithPayload<DuplicateItemsActionPayload>,
  RootState
> = createRTDBEpic(
  [duplicateItemsAction.fulfilled],
  ({ content, containerId, database, state }) => {
    const update = Object.values(content.newIdsMap).reduce(
      (update: Record<UUID, ItemPosition | undefined>, id: UUID) => {
        const position = itemPositionSelector(state, id)
        if (!!position) {
          update[id] = prepareForFirestore(position)
        }
        return update
      },
      {}
    )

    database().ref(generatePositionsPath(containerId)).update(update)
  }
)

const createAdjacentItemEpic: Epic<
  ActionWithPayload<CreateAdjacentItemActionPayload>,
  ActionWithPayload<CreateAdjacentItemActionPayload>,
  RootState
> = createRTDBEpic(
  createAdjacentItemAction.fulfilled,
  ({ content, containerId, database, state }) => {
    const id = content.newId
    const position = itemPositionSelector(state, id)

    if (!position) return

    database()
      .ref(generateItemPositionPath(containerId, id))
      .set(prepareForFirestore(position))
  }
)

const createAdjacentItemSelectionEpic: EpicWithDifferentActions<
  ActionWithPayload<CreateAdjacentItemActionPayload>,
  AnyAction
> = (action$) =>
  action$.pipe(
    ofType(createAdjacentItemAction.fulfilled),
    map(({ payload }) => setShouldSelectAction(payload.newId))
  )

const removeItemsRTDBEpic: Epic<
  ActionWithPayload<UUID[]>,
  ActionWithPayload<UUID[]>,
  RootState
> = createRTDBEpic(
  removeItemsAction.fulfilled,
  ({ content: ids, containerId, database }) => {
    const db = database()

    if (ids.length > 1) {
      const update = ids.reduce((update: Record<UUID, null>, id) => {
        update[generateItemPositionPath(containerId, id)] = null
        return update
      }, {})

      db.ref().update(update)
    } else if (ids.length === 1) {
      const id = ids[0]
      db.ref(generateItemPositionPath(containerId, id)).set(null)
    }
  }
)

const followPointerWithPendingItemsEpic: EpicWithDifferentActions<
  ActionWithPayload<{ gesture?: Gesture }>,
  AsyncThunkAction<any, any, any>
> = (action$, state$) =>
  action$.pipe(
    ofType<ActionWithPayload<{ gesture?: Gesture }>>(
      pointerMoveAction.fulfilled,
      scrollOrZoomChangedAction.fulfilled
    ),
    withLatestFrom(state$),
    filter(
      ([action, state]) =>
        (!action.payload.gesture ||
          action.payload.gesture?.finished ||
          action.payload.gesture.mode === GestureMode.DUPLICATE) &&
        selectPendingPositions(state).length > 0
    ),
    map(([_action, state]) => {
      const currentPosition = mySessionSelector(state).position as MousePosition
      return movePendingPositionsToAction(currentPosition)
    })
  )

export const positionsEpic = combineEpics(
  positionSetEpic,
  positionsSetEpic,
  positionsMoveEpic,
  positionsTransformEpic,
  linePositionTransformEpic,
  pendingCommitAllPositionsEpic,
  duplicateItemsEpic,
  createAdjacentItemEpic,
  createAdjacentItemSelectionEpic,
  removeItemsRTDBEpic,
  followPointerWithPendingItemsEpic
)
