/*---- External -------------------------------------------------------------*/

import { useEffect } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'

import { list, ListenEvent } from 'rxfire/database'

import { animationFrameScheduler } from 'rxjs'
import { bufferTime, groupBy, map, mergeMap } from 'rxjs/operators'

/*---- Qualdesk -------------------------------------------------------------*/

import { app } from '../../../config/firebase'

import { selectIsFocused } from '../../selectors/board/session'

import { KeyValuePair } from 'canvas-shared/lib/types/utilities.types'
import { ActionHandler } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

interface FirebaseConnectQuery<K extends string | number | symbol, T> {
  path: string
  ignoreOverlapWarning?: boolean
  mapIdsOnValue?: boolean
  onChildSet?: ActionHandler<KeyValuePair<K, T | null>>
  onChildRemoved?: ActionHandler<string>
  onValue?:
    | ActionHandler<K | null>
    | ActionHandler<Record<K, T> | null>
    | ActionHandler<T | null>
  onInitialValue?:
    | ActionHandler<K | null>
    | ActionHandler<Record<K, T> | null>
    | ActionHandler<T | null>
  filterOnChildSet?: (value: T) => boolean
  splitter?: (
    changes: Array<{
      event: ListenEvent
      key: K
      value: K | T | Record<K, T> | null
    }>
  ) => boolean | string | number | symbol | null
}

var paths: string[] = []

export function useRTDBConnect<K extends string | number | symbol, T>({
  path,
  onChildSet,
  onChildRemoved,
  onValue,
  onInitialValue,
  ignoreOverlapWarning = false,
  mapIdsOnValue = false,
  filterOnChildSet,
  splitter,
}: FirebaseConnectQuery<K, T>) {
  const dispatch = useDispatch()

  const isFocused = useSelector(selectIsFocused)

  const handleListener = () => {
    const ref = app.database().ref(path)
    const maybeOverlap = paths.find((p) => path.includes(p))
    if (!!maybeOverlap && !ignoreOverlapWarning) {
      console.error('Overlapping listeners:', path, maybeOverlap)
    }
    paths.push(path)

    list(ref)
      .pipe(
        map((changes) =>
          changes.reduce(
            (
              res: Array<{
                event: ListenEvent
                key: K
                value: K | Record<K, T> | T | null
              }>,
              { event, snapshot }
            ) => {
              let value = snapshot.val()
              const key = snapshot.key as K

              if (!key) {
                return res
              }

              if (
                event === ListenEvent.changed &&
                ((!!filterOnChildSet && !filterOnChildSet(value)) ||
                  !onChildSet)
              ) {
                return res
              }

              if (event === ListenEvent.value && !onValue) {
                return res
              }

              if (event === ListenEvent.value && !!onValue && mapIdsOnValue) {
                value = mapIds(value)
              }

              return res.concat({
                event,
                key,
                value,
              })
            },
            []
          )
        ),
        groupBy((changes) => (!!splitter ? splitter(changes) : true)),
        mergeMap((group) =>
          group.pipe(
            bufferTime(0, animationFrameScheduler),
            map((buffer) => buffer.pop())
          )
        )
      )
      .subscribe((changes) =>
        batch(() => {
          changes?.forEach(({ event, key, value }) => {
            if (event === ListenEvent.changed && !!onChildSet) {
              dispatch(onChildSet({ key, value: { id: key, ...(value as T) } }))
            } else if (event === ListenEvent.value && !!onValue) {
              dispatch(onValue(value as K & Record<K, T> & T))
            }
          })
        })
      )

    if (!!onChildSet) {
      ref.on(ListenEvent.added, (raw) => {
        const key = raw.key as K
        const value = raw.val()
        if (!!key) {
          dispatch(onChildSet({ key, value: { id: key, ...value } }))
        }
      })
    }

    if (!!onChildRemoved) {
      ref.on(ListenEvent.removed, (raw) => {
        if (!!raw.key) {
          dispatch(onChildRemoved(raw.key))
        }
      })
    }

    if (!!onInitialValue) {
      ref.once(ListenEvent.value, (raw) => {
        let data = raw.val()

        if (mapIdsOnValue) {
          data = mapIds(data)
        }

        dispatch(onInitialValue(data))
      })
    }

    return () => {
      paths = paths.filter((p) => p !== path)
      ref.off()
    }
  }
  useEffect(handleListener, [
    dispatch,
    filterOnChildSet,
    ignoreOverlapWarning,
    isFocused,
    mapIdsOnValue,
    onChildRemoved,
    onChildSet,
    onInitialValue,
    onValue,
    path,
    splitter,
  ])
}

function mapIds<K extends string | number | symbol, T>(
  data: Record<K, T> | null
) {
  if (data === null) {
    return data
  }

  return Object.keys(data).reduce(
    (res: Record<K, T & { id: string | number | symbol }>, id) => ({
      ...res,
      [id]: {
        ...data[id as K],
        id,
      },
    }),
    {} as Record<K, T & { id: string | number | symbol }>
  )
}
