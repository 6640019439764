/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { selectZoomLevel } from '../../../../redux/selectors/board/zoom'
import { positionsOrderedFrontToBackSelector } from '../../../../redux/selectors/board/positions'
import {
  scrollDimensionsSelector,
  scrollOffsetsSelector,
} from '../../../../redux/selectors/board/scroll'

import type { RootState } from '../../../../types/redux'
import type { BasicPointerEvent } from 'canvas-shared/lib/types/pointerEvents.types'

import { isRectPosition } from 'canvas-shared/lib/helpers/isRectPosition'
import { isLinePosition } from 'canvas-shared/lib/helpers/isLinePosition'
/*---------------------------------------------------------------------------*/

export const pointerEventInPosition = (
  state: RootState,
  pointer: BasicPointerEvent
) => {
  const positions = positionsOrderedFrontToBackSelector(state)
  const scrollOffsets = scrollOffsetsSelector(state)
  const scrollDimensions = scrollDimensionsSelector(state)
  const zoomLevel = selectZoomLevel(state)
  const { clientX, clientY } = pointer
  const { scrollLeft, scrollTop } = scrollOffsets
  const { offsetLeft, offsetTop } = scrollDimensions

  const canvasX = (clientX - offsetLeft + scrollLeft) / zoomLevel
  const canvasY = (clientY - offsetTop + scrollTop) / zoomLevel

  return positions.find((position) => {
    if (isRectPosition(position)) {
      const left = position.left
      const right = position.left + position.width
      const top = position.top
      const bottom = position.top + position.height

      return (
        canvasX >= left &&
        canvasX <= right &&
        canvasY >= top &&
        canvasY <= bottom
      )
    } else if (isLinePosition(position)) {
      // Handle direct clicks on lines
      if (
        (pointer.target as HTMLElement).dataset['pointerEventLineItemId'] ===
        position.id
      ) {
        return true
      }
      return false
    }
    throw new Error(`Expected RectPosition or LinePosition, got '${position}'.`)
  })
}
