/*---- External -------------------------------------------------------------*/

import React from 'react'

import classNames from 'classnames'

import { IMenuItemProps, Menu, Tag } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

type HotkeyMenuItemProps = Partial<IMenuItemProps> & {
  text: string
  hotkey: string | undefined
}

export const HotkeyMenuItem: React.FC<HotkeyMenuItemProps> = ({
  hotkey,
  className,
  ...menuItemProps
}) => (
  <Menu.Item
    {...menuItemProps}
    className={classNames('flex items-center', className)}
    labelElement={
      hotkey && (
        <Tag
          minimal
          className="white flex items-center justify-center w-5 h-5 text-center uppercase"
        >
          {hotkey}
        </Tag>
      )
    }
  />
)
