/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { Gesture } from 'canvas-shared/lib/types/pointerEvents.types'
import { RootState } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

export const selectGestures = (state: RootState) =>
  state.board.session.localData.gestures
export const selectPointerId = (_state: RootState, pointerId: number) =>
  pointerId

export const selectIsScrollingWithGesture = (state: RootState) =>
  state.board.session.localData.isScrollingWithGesture

export const selectGestureByPointerId = createSelector(
  selectPointerId,
  selectGestures,
  (pointerId, allGestures) => allGestures[pointerId]
)

export const selectActiveGestures = createSelector(selectGestures, (gestures) =>
  Object.values(gestures).filter((g) => !g.finished)
)

export const selectGestureInProgress = createSelector(
  selectGestures,
  (gestures) => Object.values(gestures).some((g) => !g.finished)
)

export const selectActiveGesture = createSelector(
  selectActiveGestures,
  (gestures): Gesture | undefined => gestures[0]
)
