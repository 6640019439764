"use strict";
/*---- External -------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.isLinePosition = void 0;
/*---------------------------------------------------------------------------*/
const isLinePosition = (pos) => {
    const maybeLinePosition = pos;
    return (maybeLinePosition &&
        maybeLinePosition.startX !== undefined &&
        maybeLinePosition.startY !== undefined &&
        maybeLinePosition.endX !== undefined &&
        maybeLinePosition.endY !== undefined);
};
exports.isLinePosition = isLinePosition;
