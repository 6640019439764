/*---- External -------------------------------------------------------------*/

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { selectionRectSelector } from '../../selectors/board/positions'

/*---------------------------------------------------------------------------*/

export const useSelectionRect = () => useSelector(selectionRectSelector)
