/*---- External -------------------------------------------------------------*/

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import classNames from 'classnames'

import { Button, Tooltip } from '@blueprintjs/core'

import { Cursor } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import { HOTKEY_SELECT } from '../../../config/hotkeys'

import { TooltipContent } from './TooltipContent'

import { CursorMode } from 'canvas-shared/lib/types/CursorMode.types'
import { toolsSelectAction } from '../../../redux/actionCreators/tools'
import { selectCursorMode } from '../../../redux/selectors/board/cursor'

/*---------------------------------------------------------------------------*/

export const SelectButton: React.FC = () => {
  const cursorMode = useSelector(selectCursorMode)
  const dispatch = useDispatch()

  const active = cursorMode === CursorMode.SELECT
  const onClick = () => dispatch(toolsSelectAction())

  return (
    <Tooltip
      popoverClassName="tooltip"
      content={<TooltipContent title="Select" hotkey={HOTKEY_SELECT} />}
      hoverOpenDelay={300}
    >
      <Button
        className={classNames(
          { 'text-icon-accent-light dark:text-icon-accent-dark': active },
          'px-2 w-10'
        )}
        icon={
          <Cursor
            size={24}
            weight={active ? 'duotone' : undefined}
            className="toolbar-icon bp3-icon"
          />
        }
        onClick={onClick}
        minimal
        aria-label="Select"
      />
    </Tooltip>
  )
}
