/*---- External -------------------------------------------------------------*/

import { useMemo } from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { useScrollOffsets } from '../../redux/selectorHooks/board/useScrollOffsets'

/*---------------------------------------------------------------------------*/

export const CanvasScroller: React.FC = ({ children }) => {
  const { scrollLeft, scrollTop } = useScrollOffsets()

  const scrollerStyle = useMemo(
    () => ({
      transform: `translate(${-scrollLeft}px, ${-scrollTop}px)`,
    }),
    [scrollLeft, scrollTop]
  )

  return <div style={scrollerStyle}>{children}</div>
}
