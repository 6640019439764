/*---- External -------------------------------------------------------------*/

import React, { useEffect, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import {
  Button,
  Dialog,
  FormGroup,
  IDialogProps,
  InputGroup,
  Intent,
} from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { functions } from '../../config/firebase'

import { sendSuperficialError } from '../../helpers/sendSuperficialError'

import { useClassNamesWithDarkMode } from '../../hooks/darkMode/useClassNamesWithDarkMode'

import { Routes } from '../../router/routes'

import { TeamSharingToggle } from '../team/TeamSharingToggle'

/*---------------------------------------------------------------------------*/

interface WorkspaceNewTeamDialogProps extends IDialogProps {
  onClose: (event: React.SyntheticEvent<any>) => void
}

const createTeam = functions.httpsCallable('https-authCreateTeam')

export const WorkspaceNewTeamDialog: React.FC<WorkspaceNewTeamDialogProps> = (
  dialogProps
) => {
  const { classNamesWithDarkMode } = useClassNamesWithDarkMode()
  const [creating, setCreating] = useState(false)
  const [teamName, setTeamName] = useState('')
  const [everyone, setEveryone] = useState(false)

  const { push } = useHistory()

  useEffect(() => {
    setEveryone(false)
    setTeamName('')
    setCreating(false)
  }, [dialogProps.isOpen])

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTeamName(e.target.value)
  }

  const shareWithTeam = () => {
    setEveryone(false)
  }

  const shareWithEveryone = () => {
    setEveryone(true)
  }

  const handleCreate = async (
    e: React.FormEvent | React.MouseEvent<HTMLElement>
  ) => {
    e.preventDefault()
    setCreating(true)

    try {
      const { data } = await createTeam({ name: teamName, everyone })

      setCreating(false)
      dialogProps.onClose(e)
      push(generatePath(Routes.WORKSPACE_TEAM.path, { teamId: data }))
    } catch (err) {
      sendSuperficialError(err)
      setCreating(false)
    }
  }

  const handleClose = (e: React.SyntheticEvent<HTMLElement>) => {
    if (!creating) {
      dialogProps.onClose(e)
    }
  }

  return (
    <Dialog
      {...dialogProps}
      onClose={handleClose}
      className={classNamesWithDarkMode('p-0 w-auto overflow-hidden')}
    >
      <div className="dark:bg-cool-gray-750 flex flex-col p-6 bg-white space-y-4">
        <form onSubmit={handleCreate}>
          <div className="flex flex-col space-y-8">
            <FormGroup
              className="m-0"
              label={<div className="mb-1 font-semibold">Team name</div>}
              labelFor="name-input"
            >
              <InputGroup
                autoFocus
                id="name-input"
                large
                onChange={handleNameChange}
                placeholder="Enter a team name"
                value={teamName}
              />
            </FormGroup>
            <div>
              <div className="mb-3 font-semibold">Sharing settings</div>
              <div className="w-128 flex space-x-4" role="radiogroup">
                <TeamSharingToggle
                  everyone={everyone}
                  shareWithEveryone={shareWithEveryone}
                  shareWithTeam={shareWithTeam}
                />
              </div>
            </div>
          </div>
        </form>
        <div className="flex flex-row items-center justify-between pt-4">
          <Button
            disabled={creating}
            onClick={dialogProps.onClose}
            minimal
            text="Cancel"
          />
          <Button
            disabled={!teamName}
            intent={Intent.PRIMARY}
            loading={creating}
            onClick={handleCreate}
            text="Save"
          />
        </div>
      </div>
    </Dialog>
  )
}
