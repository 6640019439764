/*---- External -------------------------------------------------------------*/

import React from 'react'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { ItemGenericTextInner } from './ItemGenericTextInner'

import { Item } from 'canvas-shared/lib/types/Item.types'

/*---------------------------------------------------------------------------*/

export const ItemSticky: React.FC<Item.Sticky> = ({ data, id }) => (
  <div
    className={classNames(
      'sticky-note text-lg shadow-md',
      'text-text-primary-light',
      `bg-blue-gray-900`,
      'flex flex-col w-full h-full overflow-hidden'
    )}
  >
    <div
      className={`w-full h-full flex flex-col bg-${data.backgroundColor}-200 dark:bg-opacity-75`}
    >
      <ItemGenericTextInner className="p-5" itemId={id} />
    </div>
  </div>
)
