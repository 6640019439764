/*---- External -------------------------------------------------------------*/

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { singleLineSelectedSelector } from '../../selectors/board/positions'

/*---------------------------------------------------------------------------*/

export const useSingleLineSelected = () =>
  useSelector(singleLineSelectedSelector)
