/*---- External -------------------------------------------------------------*/

import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useFirestore } from 'react-redux-firebase'

/*---- Qualdesk -------------------------------------------------------------*/

import { selectMyUid } from '../../selectors/auth/auth'
import { useUser } from '../../selectorHooks/users/useUser'

import { generateOperation } from '../helpers/generateOperation'

import { UserDetails } from 'canvas-shared/lib/types/user.types'

/*---------------------------------------------------------------------------*/

interface UserActions {
  updateUserDetails: (details: Partial<UserDetails>) => Promise<void>
  setUserPicture: (pictureUrl: string) => Promise<void>
  deleteUserPicture: () => Promise<void>
}

export const useUserActions = (): UserActions => {
  const firestore = useFirestore()
  const uid = useSelector(selectMyUid)
  const user = useUser(uid)

  const updateUserDetails = useCallback(
    (details: Partial<UserDetails>) => {
      const operation = generateOperation(uid)

      const creationTimestamps = !user
        ? {
            createdAt: firestore.FieldValue.serverTimestamp(),
            createdBy: operation,
          }
        : {}

      return firestore
        .collection('users')
        .doc(uid)
        .set(
          {
            ...details,
            updatedAt: firestore.FieldValue.serverTimestamp(),
            updatedBy: operation,
            ...creationTimestamps,
          },
          { merge: true }
        )
    },
    [firestore, uid, user]
  )

  const setUserPicture = useCallback(
    (pictureUrl: string) => updateUserDetails({ pictureUrl }),
    [updateUserDetails]
  )

  const deleteUserPicture = useCallback(() => {
    const operation = generateOperation(uid)
    return firestore
      .collection('users')
      .doc(uid)
      .update({ operation, pictureUrl: firestore.FieldValue.delete() })
  }, [firestore, uid])

  return useMemo(
    () => ({
      updateUserDetails,
      setUserPicture,
      deleteUserPicture,
    }),
    [deleteUserPicture, setUserPicture, updateUserDetails]
  )
}
