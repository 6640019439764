/*---- External -------------------------------------------------------------*/

import React, { useCallback, useState } from 'react'

import { Button, Intent, Spinner } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { functions } from '../../../config/firebase'
import { Urls } from '../../../router/routes'

import { useStatusReporter } from '../../../hooks/useStatusReporter'
import { useLinearRedirectPath } from '../../../hooks/router/useLinearRedirectPath'

import { CallbackState } from 'canvas-shared/src/types/connections/Linear.types'

/*---------------------------------------------------------------------------*/

const generateAuthUrl = functions.httpsCallable('https-linearGenerateAuthUrl')

export const LinearConnectButton: React.FC = ({ children }) => {
  const { reportError } = useStatusReporter()
  const [loading, setLoading] = useState(false)
  const [, setLinearRedirectPath] = useLinearRedirectPath()

  const redirectToLinearAuth = useCallback(async () => {
    setLoading(true)

    const state: CallbackState = {
      redirectPath: window.location.pathname,
    }

    try {
      const {
        data: { url },
      } = await generateAuthUrl({
        callbackUrl: Urls.LINEAR_CALLBACK,
        state,
      })
      setLinearRedirectPath(state.redirectPath)
      window.location.href = url
    } catch (error) {
      setLoading(false)
      reportError(error, 'Failed to redirect to Linear')
    }

    return false
  }, [setLinearRedirectPath, reportError])

  return (
    <div>
      <Button
        intent={Intent.PRIMARY}
        icon={loading ? <Spinner size={16} /> : undefined}
        disabled={loading}
        text={loading ? 'Please wait…' : 'Sign in with Linear'}
        onClick={redirectToLinearAuth}
      />
    </div>
  )
}
