/*---- External -------------------------------------------------------------*/

import React, { useMemo } from 'react'

import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import getBoundingRectFromPositions from '../../helpers/getBoundingRectFromPositions'
import { getHexFromColorName } from '../../helpers/getHexFromColorName'
import getStyleFromDOMRect from '../../helpers/getStyleFromDOMRect'

import { selectInteractionMode } from '../../redux/selectors/board/interactionModes'
import { useOnlineUserIds } from '../../redux/selectorHooks/board/useOnlineUserIds'
import { useSelectionsByOtherUsers } from '../../redux/selectorHooks/board/useSelectionsByOtherUsers'
import { useUser } from '../../redux/selectorHooks/users/useUser'

import { positionsOrderedSelector } from '../../redux/selectors/board/positions'

import { InteractionMode, PresenceSelection } from '../../types'
import { selectZoomScale } from '../../redux/selectors/board/zoom'

/*---------------------------------------------------------------------------*/

const CanvasPresenceSelections: React.FC = () => {
  const onlineUserIds = useOnlineUserIds()
  const otherUserSelections = useSelectionsByOtherUsers()
  const interactionMode = useSelector(selectInteractionMode)

  const onlineSelections = useMemo(
    () => otherUserSelections.filter((s) => onlineUserIds.includes(s.userId)),
    [onlineUserIds, otherUserSelections]
  )

  if (interactionMode === InteractionMode.VOTING) {
    return null
  }

  return (
    <>
      {onlineSelections.map(({ selectedItemIds, userId }) => (
        <CanvasPresenceSelectionRect
          selectedItemIds={selectedItemIds}
          key={userId}
          userId={userId}
        />
      ))}
    </>
  )
}

const CanvasPresenceSelectionRect: React.FC<PresenceSelection> = ({
  selectedItemIds,
  userId,
}) => {
  const user = useUser(userId)
  const zoomScale = useSelector(selectZoomScale)

  const selector = createSelector(positionsOrderedSelector, (positions) =>
    positions.filter((p) => selectedItemIds.includes(p.id))
  )

  const selectedItemsPositions = useSelector(selector)

  const selectionRect = useMemo(
    () => getBoundingRectFromPositions(selectedItemsPositions),
    [selectedItemsPositions]
  )

  const style = useMemo(() => {
    if (!!user) {
      return {
        ...getStyleFromDOMRect(selectionRect),
        outlineWidth: 2 * zoomScale,
        outlineColor: getHexFromColorName(user.color),
      }
    } else {
      return undefined
    }
  }, [user, selectionRect, zoomScale])

  if (!user || !user.color || selectedItemIds.length < 1) {
    return null
  }

  return (
    <div
      className="selected-canvas-item absolute opacity-50 pointer-events-none"
      style={style}
    >
      <CanvasPresenceSelectionHandle
        className={`absolute -top-4 -left-4 bg-${user.color}-600`}
      />
      <CanvasPresenceSelectionHandle
        className={`absolute -top-4 -right-4 bg-${user.color}-600`}
      />
      <CanvasPresenceSelectionHandle
        className={`absolute -bottom-4 -left-4 bg-${user.color}-600`}
      />
      <CanvasPresenceSelectionHandle
        className={`absolute -bottom-4 -right-4 bg-${user.color}-600`}
      />
      <div
        className={classNames(
          `bg-${user.color}-600`,
          'absolute -ml-4 -mt-14 px-2 py-1 text-white text-xs font-bold rounded'
        )}
        style={{
          transform: `scale(${zoomScale})`,
          transformOrigin: 'bottom left',
        }}
      >
        {user.name}
      </div>
    </div>
  )
}

const CanvasPresenceSelectionHandle: React.FC<{ className: string }> = ({
  className,
}) => {
  const zoomScale = useSelector(selectZoomScale)

  return (
    <div
      className={classNames(
        'border-white',
        'border',
        'h-3',
        'w-3',
        'z-above-selection-rect',
        className
      )}
      style={{
        transform: `scale(${zoomScale})`,
      }}
    />
  )
}

export default CanvasPresenceSelections
