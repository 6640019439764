/*---- External -------------------------------------------------------------*/

import { NavLink } from 'react-router-dom'

import { IconProps } from 'phosphor-react'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

interface WorkspaceNavigationButtonProps {
  phosphorIcon?: React.ForwardRefExoticComponent<IconProps>
  iconElement?: JSX.Element
  exact?: boolean
  forPath?: string
  extraPath?: string
  extraIcon?: React.ForwardRefExoticComponent<IconProps>
  onClick?: React.MouseEventHandler
  secondary?: true
  text: string
}

export const WorkspaceNavigationButton: React.FC<WorkspaceNavigationButtonProps> = ({
  exact = true,
  ...props
}) => {
  const { forPath, extraPath, extraIcon: ExtraIcon, onClick } = props

  const baseClassName =
    'px-6 py-3 flex flex-row items-center hover:no-underline hover:bg-button-secondary-hovered-light dark:hover:bg-button-secondary-hovered-dark active:bg-button-secondary-active-light dark:active:bg-button-secondary-active-dark group'

  if (!!forPath) {
    return (
      <NavLink
        activeClassName={
          'bg-button-secondary-selected-light dark:bg-button-secondary-selected-dark'
        }
        className={baseClassName}
        exact={exact}
        to={forPath}
        role="button"
      >
        <ButtonInner {...props} />
        {!!extraPath && !!ExtraIcon && (
          <NavLink className="ml-auto" to={extraPath}>
            <div className="text-icon-secondary-light dark:text-icon-secondary-dark hover:text-icon-primary-light dark:hover:text-icon-primary-dark hover:bg-blue-gray-200 dark:hover:bg-cool-gray-800 -mr-3 -my-2 p-2 rounded-full invisible group-hover:visible">
              <ExtraIcon className="w-3 h-3" weight="fill" />
            </div>
          </NavLink>
        )}
      </NavLink>
    )
  } else {
    return (
      <div
        className={baseClassName}
        onClick={onClick}
        role={!!onClick ? 'button' : undefined}
      >
        <ButtonInner {...props} />
      </div>
    )
  }
}

const ButtonInner: React.FC<WorkspaceNavigationButtonProps> = ({
  phosphorIcon: PhosphorIcon,
  iconElement,
  secondary,
  text,
}) => (
  <>
    {!!PhosphorIcon && (
      <PhosphorIcon
        className={classNames(
          'w-5 h-5',
          secondary
            ? 'text-icon-secondary-light dark:text-icon-secondary-dark'
            : 'text-icon-primary-light dark:text-icon-primary-dark'
        )}
      />
    )}
    {iconElement}
    <div
      className={classNames(
        'ml-4 whitespace-nowrap overflow-hidden overflow-ellipsis',
        secondary
          ? 'text-text-secondary-light dark:text-text-secondary-dark'
          : 'text-text-primary-light dark:text-text-primary-dark'
      )}
    >
      {text}
    </div>
  </>
)
