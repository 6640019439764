/*---- External -------------------------------------------------------------*/

import React, { useEffect } from 'react'

import { useHistory } from 'react-router-dom'

/*---- Qualdesk -------------------------------------------------------------*/

import { functions } from '../../config/firebase'
import { Urls } from '../../router/routes'

import { useStatusReporter } from '../../hooks/useStatusReporter'
import { useLinearRedirectPath } from '../../hooks/router/useLinearRedirectPath'

import Loading from '../../components/loading/Loading'

/*---------------------------------------------------------------------------*/

const generateTokens = functions.httpsCallable('https-linearGenerateTokens')

export const LinearCallback: React.FC = () => {
  const { replace } = useHistory()
  const { reportSuccess, reportError } = useStatusReporter()
  const [linearRedirectPath, setLinearRedirectPath] = useLinearRedirectPath()

  useEffect(() => {
    const redirectOnLoad = async () => {
      try {
        const url = new URL(window.location.href)
        const code = url.searchParams.get('code')
        const state = url.searchParams.get('state')

        if (code === null || state === null) {
          throw new Error('Missing URL parameters')
        }
        await generateTokens({
          callbackUrl: Urls.LINEAR_CALLBACK,
          code,
        })

        const { redirectPath } = JSON.parse(state)
        replace(redirectPath ?? '/')
        reportSuccess('Successfully connected to Linear', { timeout: 5000 })
      } catch (error) {
        replace(linearRedirectPath ?? '/')
        reportError(error, 'Failed to connect to Linear', { timeout: 5000 })
      } finally {
        setLinearRedirectPath(undefined)
      }
    }

    redirectOnLoad()
  }, [
    linearRedirectPath,
    replace,
    reportSuccess,
    reportError,
    setLinearRedirectPath,
  ])

  return <Loading />
}
