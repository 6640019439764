/*---- External -------------------------------------------------------------*/

import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { IMenuItemProps } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { setPositionsAction } from '../../../redux/actionCreators/positions'
import { usePositions } from '../../../redux/selectorHooks/board/usePositions'

import { Item } from 'canvas-shared/lib/types/Item.types'

/*---------------------------------------------------------------------------*/

interface NewZ {
  [key: string]: number
}

type Move = 'forward' | 'backward' | 'front' | 'back'

export const useZIndexMenuItems = (
  actionItems: Item.BaseItem[]
): IMenuItemProps[] => {
  const actionItemIds = useMemo(() => actionItems.map((i) => i.id), [
    actionItems,
  ])
  const dispatch = useDispatch()
  const positions = usePositions()

  const getNewZ = useCallback(
    (move: Move): NewZ => {
      let newZ: NewZ = {}

      if (actionItems.length === 0) {
        return newZ
      }

      const direction =
        move === 'back' || move === 'backward' ? 'backward' : 'forward'

      const bottomActionItemIndex =
        move === 'back'
          ? 0
          : positions.findIndex((p) => p.id === actionItems[0].id)
      const topActionItemIndex =
        move === 'front'
          ? positions.length - 1
          : positions.findIndex(
              (p) => p.id === actionItems[actionItems.length - 1].id
            )

      const bottomTransformationIndex = Math.max(
        (bottomActionItemIndex || 0) + (direction === 'backward' ? -1 : 0),
        0
      )
      const topTransformationIndex = Math.min(
        (topActionItemIndex || 0) + (direction === 'backward' ? 0 : 1),
        positions.length - 1
      )

      const positionsToTransform = positions.slice(
        bottomTransformationIndex,
        topTransformationIndex + 1
      )
      const positionsToMove = positionsToTransform.filter((o) =>
        actionItemIds.includes(o.id)
      )
      const positionsToSwap = positionsToTransform.filter(
        (o) => !actionItemIds.includes(o.id)
      )

      if (direction === 'forward') {
        positionsToMove.reverse().forEach((o, i) => {
          const nz = positionsToTransform[positionsToTransform.length - 1].z - i
          if (nz !== o.z) {
            newZ[o.id] = nz
          }
        })
        positionsToSwap.forEach((o, i) => {
          const nz = positionsToTransform[0].z + i
          if (nz !== o.z) {
            newZ[o.id] = nz
          }
        })
      } else {
        positionsToMove.forEach((o, i) => {
          const nz = positionsToTransform[0].z + i
          if (nz !== o.z) {
            newZ[o.id] = nz
          }
        })
        positionsToSwap.reverse().forEach((o, i) => {
          const nz = positionsToTransform[positionsToTransform.length - 1].z - i
          if (nz !== o.z) {
            newZ[o.id] = nz
          }
        })
      }

      return newZ
    },
    [actionItemIds, actionItems, positions]
  )

  const setNewZ = useCallback(
    (newZ: NewZ) => {
      dispatch(
        setPositionsAction(
          positions.map((o) => {
            if (newZ[o.id]) {
              return {
                ...o,
                z: newZ[o.id],
              }
            } else {
              return o
            }
          })
        )
      )
    },
    [dispatch, positions]
  )

  const backwardZTranformation = useMemo(() => getNewZ('backward'), [getNewZ])
  const forwardZTranformation = useMemo(() => getNewZ('forward'), [getNewZ])
  const backZTransformation = useMemo(() => getNewZ('back'), [getNewZ])
  const frontZTransformation = useMemo(() => getNewZ('front'), [getNewZ])

  const moveBackward = useCallback(() => setNewZ(backwardZTranformation), [
    backwardZTranformation,
    setNewZ,
  ])
  const moveForward = useCallback(() => setNewZ(forwardZTranformation), [
    forwardZTranformation,
    setNewZ,
  ])
  const moveToBack = useCallback(() => setNewZ(backZTransformation), [
    backZTransformation,
    setNewZ,
  ])
  const moveToFront = useCallback(() => setNewZ(frontZTransformation), [
    frontZTransformation,
    setNewZ,
  ])

  const zIndexMenuItems = useMemo(
    () => [
      {
        disabled: Object.keys(frontZTransformation).length < 1,
        onClick: moveToFront,
        text: 'Move to front',
      },
      {
        disabled: Object.keys(forwardZTranformation).length < 1,
        onClick: moveForward,
        text: 'Move forward',
      },
      {
        disabled: Object.keys(backwardZTranformation).length < 1,
        onClick: moveBackward,
        text: 'Move backward',
      },
      {
        disabled: Object.keys(backZTransformation).length < 1,
        onClick: moveToBack,
        text: 'Move to back',
      },
    ],
    [
      backZTransformation,
      backwardZTranformation,
      forwardZTranformation,
      frontZTransformation,
      moveBackward,
      moveForward,
      moveToBack,
      moveToFront,
    ]
  )

  return zIndexMenuItems
}
