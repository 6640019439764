/*---- External -------------------------------------------------------------*/

import { useDispatch } from 'react-redux'

import { Button, IconName, NumericInput } from '@blueprintjs/core'

import clamp from 'lodash.clamp'

/*---- Qualdesk -------------------------------------------------------------*/

import useLocalStorageSettings from '../hooks/useLocalStorageSettings'

import {
  colors,
  colorsWithBlackAndWhite,
} from 'canvas-shared/lib/config/colors'

import { textAlignments } from '../config/textAlignments'
import { textStyles } from '../config/textStyles'
import { MIN_LINE_THICKNESS, MAX_LINE_THICKNESS } from '../config/lines'

import colorIcon from '../helpers/colorIcon'

import { DynamicToolbarItem } from '../types'

import { Item, ItemType } from 'canvas-shared/lib/types/Item.types'
import { ColorKey } from 'canvas-shared/lib/types/color.types'
import {
  TextAlignmentKey,
  TextStyleKey,
} from 'canvas-shared/lib/types/text.types'
import { useGroupMenuItems } from './board/rightClickMenu/useGroupMenuItems'
import {
  itemsAddTextStyleAction,
  itemsRemoveTextStyleAction,
  itemsSetBackgroundColorAction,
  itemsSetLineColorAction,
  itemsSetLineThicknessAction,
  itemsSetTextAlignmentAction,
  itemsSetTextColorAction,
  itemsSetTextSizeAction,
} from '../redux/actionCreators/itemData'

/*---------------------------------------------------------------------------*/

export const useItemToolbar = (
  maybeItems: Item.BaseItem[]
): DynamicToolbarItem[] => {
  const dispatch = useDispatch()

  const {
    useCardBackgroundColor,
    useStickyBackgroundColor,
    useRectangleBackgroundColor,
    useTextAlignment,
    useTextColor,
    useTextSize,
    useTextStyle,
    useLineColor,
    useLineThickness,
    useRectangleTextAlignment,
    useRectangleTextColor,
    useRectangleTextSize,
    useRectangleTextStyle,
  } = useLocalStorageSettings()
  const [, setCardBackgroundColor] = useCardBackgroundColor()
  const [, setStickyBackgroundColor] = useStickyBackgroundColor()
  const [, setRectangleBackgroundColor] = useRectangleBackgroundColor()
  const [, setTextAlignment] = useTextAlignment()
  const [, setTextColor] = useTextColor()
  const [, setTextSize] = useTextSize()
  const [, setTextStyle] = useTextStyle()
  const [, setRectangleTextAlignment] = useRectangleTextAlignment()
  const [, setRectangleTextColor] = useRectangleTextColor()
  const [, setRectangleTextSize] = useRectangleTextSize()
  const [, setRectangleTextStyle] = useRectangleTextStyle()
  const [, setLineColor] = useLineColor()
  const [, setLineThickness] = useLineThickness()

  const groupItems = useGroupMenuItems(maybeItems)

  if (maybeItems.length === 0) {
    return []
  }

  const items = maybeItems as Item.BaseItem[]
  const allItemsSameType = maybeItems.every((i) => i.type === items[0].type)

  let toolbarItems = [] as DynamicToolbarItem[]

  const selectedItemIds = items.map((i) => i?.id)

  const isCard = items[0].type === ItemType.Card
  const isSticky = items[0].type === ItemType.Sticky
  const isText = items[0].type === ItemType.Text
  const isRectangle = items[0].type === ItemType.Rectangle
  const isLine = items[0].type === ItemType.Line

  const updateBackgroundColor = (color: ColorKey | null) => {
    dispatch(itemsSetBackgroundColorAction({ ids: selectedItemIds, color }))
    if (!!color) {
      if (isCard) {
        setCardBackgroundColor(color)
      }
      if (isSticky) {
        setStickyBackgroundColor(color)
      }
      if (isRectangle) {
        setRectangleBackgroundColor(color)
      }
    }
  }

  const updateLineColor = (color: ColorKey) => {
    dispatch(itemsSetLineColorAction({ ids: selectedItemIds, color }))
    if (!!color) {
      setLineColor(color)
    }
  }

  const updateLineThickness = (thickness: number) => {
    const clampedThickness = clamp(
      thickness,
      MIN_LINE_THICKNESS,
      MAX_LINE_THICKNESS
    )
    dispatch(
      itemsSetLineThicknessAction({
        ids: selectedItemIds,
        thickness: clampedThickness,
      })
    )
    setLineThickness(clampedThickness)
  }

  const updateTextColor = (color: ColorKey) => {
    dispatch(itemsSetTextColorAction({ ids: selectedItemIds, color }))
    if (isRectangle) {
      setRectangleTextColor(color)
    } else {
      setTextColor(color)
    }
  }

  const updateTextAlignment = (alignment: TextAlignmentKey) => {
    dispatch(itemsSetTextAlignmentAction({ ids: selectedItemIds, alignment }))
    if (isRectangle) {
      setRectangleTextAlignment(alignment)
    } else {
      setTextAlignment(alignment)
    }
  }

  const updateTextSize = (size: number) => {
    dispatch(itemsSetTextSizeAction({ ids: selectedItemIds, size }))
    if (isRectangle) {
      setRectangleTextSize(size)
    } else {
      setTextSize(size)
    }
  }

  const toggleTextStyle = (style: TextStyleKey) => {
    if (
      items.every((i) => (i as Item.TextItem).data.textStyle?.includes(style))
    ) {
      dispatch(itemsRemoveTextStyleAction({ ids: selectedItemIds, style }))
      const updateDefault = (styles: TextStyleKey[]) =>
        styles.filter((s) => s !== style)
      if (isRectangle) {
        setRectangleTextStyle(updateDefault)
      } else {
        setTextStyle(updateDefault)
      }
    } else {
      dispatch(itemsAddTextStyleAction({ ids: selectedItemIds, style }))
      const updateDefault = (styles: TextStyleKey[]) => [style].concat(styles)
      if (isRectangle) {
        setRectangleTextStyle(updateDefault)
      } else {
        setTextStyle(updateDefault)
      }
    }
  }

  const textItems = items as Item.TextItem[]
  const lineItems = items as Item.Line[]

  if (allItemsSameType) {
    if (isLine) {
      const allSameLineColor = lineItems.every(
        (i) => i.data.lineColor === lineItems[0].data.lineColor
      )

      toolbarItems.push({
        textInMenu: 'Line color',
        icon: allSameLineColor
          ? colorIcon(lineItems[0].data.lineColor, true, 500)
          : 'style',
        childElement: (
          <div className="flex flex-wrap p-1 w-32">
            {colorsWithBlackAndWhite.map(({ color }) => (
              <Button
                active={
                  allSameLineColor && color === lineItems[0].data.lineColor
                }
                className="px-2 py-1"
                icon={colorIcon(color, false, 500)}
                key={color}
                minimal
                onClick={() => updateLineColor(color)}
              />
            ))}
          </div>
        ),
      })

      toolbarItems.push({
        textInMenu: 'Line thickness',
        childElement: (
          <div className="w-20">
            <NumericInput
              className="shrink-input"
              clampValueOnBlur
              majorStepSize={1}
              max={MAX_LINE_THICKNESS}
              min={MIN_LINE_THICKNESS}
              minorStepSize={1}
              onValueChange={updateLineThickness}
              value={lineItems[0].data.lineThickness}
            />
          </div>
        ),
        renderChildElementInToolbar: true,
      })
    }
    if (isCard || isSticky || isRectangle) {
      const allSameBackgroundColor = textItems.every(
        (i) => i.data.backgroundColor === textItems[0].data.backgroundColor
      )

      toolbarItems.push({
        textInMenu: 'Background color',
        icon: allSameBackgroundColor
          ? colorIcon(
              textItems[0].data.backgroundColor,
              true,
              isCard ? 500 : 300
            )
          : 'circle',
        childElement: (
          <div className="flex flex-wrap p-1 w-32">
            {isCard && (
              <Button
                active={
                  !textItems[0].data.backgroundColor ||
                  textItems[0].data.backgroundColor === null
                }
                icon="circle"
                minimal
                onClick={() => updateBackgroundColor(null)}
              />
            )}
            {colors.map(({ color }) => (
              <Button
                active={
                  allSameBackgroundColor &&
                  color === textItems[0].data.backgroundColor
                }
                icon={colorIcon(color, false, isCard ? 500 : 300)}
                key={color}
                minimal
                onClick={() => updateBackgroundColor(color)}
              />
            ))}
          </div>
        ),
      })
    }
    if (isText || isRectangle) {
      const allSameTextColor = textItems.every(
        (i) => i.data.textColor === textItems[0].data.textColor
      )

      toolbarItems.push({
        textInMenu: 'Text color',
        icon: allSameTextColor
          ? colorIcon(textItems[0].data.textColor, true, 500)
          : 'style',
        childElement: (
          <div className="flex flex-wrap p-1 w-32">
            {colorsWithBlackAndWhite.map(({ color }) => (
              <Button
                active={
                  allSameTextColor && color === textItems[0].data.textColor
                }
                className="px-2 py-1"
                icon={colorIcon(color, false, 500)}
                key={color}
                minimal
                onClick={() => updateTextColor(color)}
              />
            ))}
          </div>
        ),
      })

      const allSameTextAlignment = textItems.every(
        (i) => i.data.textAlignment === textItems[0].data.textAlignment
      )

      toolbarItems.push({
        textInMenu: 'Align',
        children: textAlignments.map(({ id, icon }) => ({
          active:
            allSameTextAlignment && id === textItems[0].data.textAlignment,
          icon: icon as IconName,
          key: id,
          onClick: () => updateTextAlignment(id),
        })),
      })

      toolbarItems.push({
        textInMenu: 'Style',
        children: textStyles.map(({ id, icon }) => {
          const allSameTextStyle = textItems.every(
            (i) =>
              i.data.textStyle?.includes(id) &&
              textItems[0].data.textStyle?.includes(id)
          )

          return {
            active:
              allSameTextStyle && textItems[0].data.textStyle?.includes(id),
            icon: icon as IconName,
            key: id,
            onClick: () => toggleTextStyle(id),
          }
        }),
      })

      toolbarItems.push({
        textInMenu: 'Size',
        childElement: (
          <div className="w-20">
            <NumericInput
              className="shrink-input"
              clampValueOnBlur
              majorStepSize={4}
              max={120}
              min={8}
              minorStepSize={1}
              onValueChange={updateTextSize}
              value={textItems[0].data.textSize}
            />
          </div>
        ),
        renderChildElementInToolbar: true,
      })
    }
  }

  if (groupItems.some((i) => !i.disabled)) {
    toolbarItems.push(
      ...groupItems.map((i) => ({
        ...i,
        text: i.text?.toString(),
        children: undefined,
      }))
    )
  }

  return toolbarItems
}
