/*---- External -------------------------------------------------------------*/

import { useCallback, useMemo } from 'react'

import {
  IMenuItemProps,
  IMenuDividerProps,
  MenuItem,
  MenuDivider,
} from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { usePositionAlignmentActions } from '../../../redux/actions/board/usePositionAlignmentActions'

import { ItemPosition } from 'canvas-shared/lib/types/Position.types'

/*---------------------------------------------------------------------------*/

export const useAlignmentMenuItems = (
  actionItemPositions: ItemPosition[]
): Array<IMenuItemProps | IMenuDividerProps> => {
  const {
    alignItemsLeft,
    alignItemsRight,
    alignItemsTop,
    alignItemsBottom,
    alignItemsVerticalCenter,
    alignItemsHorizontalCenter,
  } = usePositionAlignmentActions()

  const handleAlignLeft = useCallback(() => {
    alignItemsLeft(actionItemPositions)
  }, [actionItemPositions, alignItemsLeft])

  const handleAlignRight = useCallback(() => {
    alignItemsRight(actionItemPositions)
  }, [actionItemPositions, alignItemsRight])

  const handleAlignTop = useCallback(() => {
    alignItemsTop(actionItemPositions)
  }, [actionItemPositions, alignItemsTop])

  const handleAlignBottom = useCallback(() => {
    alignItemsBottom(actionItemPositions)
  }, [actionItemPositions, alignItemsBottom])

  const handleAlignItemsVerticalCenter = useCallback(() => {
    alignItemsVerticalCenter(actionItemPositions)
  }, [actionItemPositions, alignItemsVerticalCenter])

  const handleAlignItemsHorizontalCenter = useCallback(() => {
    alignItemsHorizontalCenter(actionItemPositions)
  }, [actionItemPositions, alignItemsHorizontalCenter])

  return useMemo(
    () => [
      {
        text: 'Align',
        disabled: actionItemPositions.length < 2,
        children: [
          <MenuItem
            text="Align left"
            icon="alignment-left"
            onClick={handleAlignLeft}
          />,
          <MenuItem
            text="Align right"
            icon="alignment-right"
            onClick={handleAlignRight}
          />,
          <MenuItem
            text="Align top"
            icon="alignment-top"
            onClick={handleAlignTop}
          />,
          <MenuItem
            text="Align bottom"
            icon="alignment-bottom"
            onClick={handleAlignBottom}
          />,
          <MenuDivider />,
          <MenuItem
            text="Align vertical centers"
            icon="alignment-vertical-center"
            onClick={handleAlignItemsVerticalCenter}
          />,
          <MenuItem
            text="Align horizontal centers"
            icon="alignment-horizontal-center"
            onClick={handleAlignItemsHorizontalCenter}
          />,
        ],
      } as IMenuItemProps,
    ],
    [
      actionItemPositions.length,
      handleAlignBottom,
      handleAlignItemsHorizontalCenter,
      handleAlignItemsVerticalCenter,
      handleAlignLeft,
      handleAlignRight,
      handleAlignTop,
    ]
  )
}
