/*---- External -------------------------------------------------------------*/

import React, { useState, useEffect } from 'react'

import { EditableText } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { useBoardActions } from '../../redux/actions/board/useBoardActions'
import { useBoard } from '../../redux/selectorHooks/board/useBoard'

/*---------------------------------------------------------------------------*/

interface BoardTitleProps {
  className?: string
}

const BoardTitle: React.FC<BoardTitleProps> = ({ className }) => {
  const {
    id,
    data: { title },
  } = useBoard()
  const { updateBoardTitle } = useBoardActions()

  const serverTitle = !!title ? title : undefined

  const [localTitle, setLocalTitle] = useState(serverTitle)

  useEffect(() => {
    setLocalTitle(serverTitle)
  }, [serverTitle])

  const handleUpdate = () => {
    if (localTitle !== undefined && localTitle !== title) {
      updateBoardTitle(localTitle)
    }
  }

  if (!id) {
    return null
  }

  return (
    <div className="p-2 overflow-hidden">
      <EditableText
        className={className}
        onChange={(value) => setLocalTitle(value)}
        onConfirm={handleUpdate}
        placeholder="Untitled Board"
        value={localTitle}
      />
    </div>
  )
}

export default BoardTitle
