/*---- External -------------------------------------------------------------*/

import React from 'react'

import { Icon } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { ReactComponent as GoogleIcon } from '../../../assets/integrations/google/google-g-icon.svg'
import { QualdeskIcon } from '../../../assets/brand/QualdeskIcon'

/*---------------------------------------------------------------------------*/

export const GoogleAuthenticationBanner: React.FC = () => (
  <div className="flex flex-row text-gray-600 space-x-4">
    <QualdeskIcon size={60} />
    <Icon icon="more" iconSize={60} />
    <GoogleIcon height="60px" width="60px" />
  </div>
)
