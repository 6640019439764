/*---- External -------------------------------------------------------------*/

import React from 'react'
import { Link } from 'react-router-dom'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { Routes } from '../../router/routes'

import { QualdeskIcon } from '../../assets/brand/QualdeskIcon'

/*---------------------------------------------------------------------------*/

const BORDER_RADIUS = 16

interface MinimalLayoutProps {
  headerLeft?: React.ReactNode
  headerRight?: React.ReactNode
  highlight?: boolean
}

export const MinimalLayout: React.FC<MinimalLayoutProps> = ({
  children,
  headerLeft,
  headerRight,
  highlight = false,
}) => (
  <main className="bg-background-five-light dark:bg-background-five-dark sm:dark:bg-background-four-dark sm:bg-background-four-light h-full sm:flex sm:flex-col sm:items-stretch">
    <header className="grid gap-x-4 grid-cols-2 items-center p-5 sm:absolute sm:left-0 sm:right-0 sm:top-0 sm:grid-cols-3">
      <div className="justify-self-start sr-only sm:not-sr-only">
        {headerLeft}
      </div>
      <Link
        className="justify-self-start sm:justify-self-center"
        to={Routes.HOME.path}
      >
        <QualdeskIcon size={24} />
      </Link>
      <div className="justify-self-end sr-only sm:not-sr-only">
        {headerRight}
      </div>
    </header>
    <div className="sm:flex sm:flex-grow sm:items-center sm:justify-center sm:px-5 sm:py-16">
      <div
        className={classNames(
          'sm:bg-border-weak-light sm:dark:bg-border-weak-dark sm:p-0.25 sm:flex-grow sm:max-w-md',
          {
            'sm:to-teal-400 sm:bg-gradient-to-br sm:from-blue-600': highlight,
          }
        )}
        style={{ borderRadius: BORDER_RADIUS }}
      >
        <div
          className="sm:dark:bg-background-five-dark sm:bg-background-five-light px-5 py-8 sm:p-8"
          style={{ borderRadius: BORDER_RADIUS - 1 }}
        >
          {children}
        </div>
      </div>
    </div>
  </main>
)
