"use strict";
/*---- External -------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.userOnline = void 0;
const luxon_1 = require("luxon");
/*---------------------------------------------------------------------------*/
const MINUTES_BEFORE_USER_DEEMED_OFFLINE = 15;
const userOnline = (user) => !!user.lastSeen &&
    // This line can be removed if all lastSeen data is migrated
    typeof user.lastSeen === 'number' &&
    luxon_1.DateTime.fromMillis(user.lastSeen) >=
        luxon_1.DateTime.local().minus({ minutes: MINUTES_BEFORE_USER_DEEMED_OFFLINE });
exports.userOnline = userOnline;
