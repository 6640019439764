/*---- External -------------------------------------------------------------*/

import { useMedia } from 'use-media'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

export const useSystemDarkMode = () =>
  useMedia('(prefers-color-scheme: dark)', false)
