/*---- External -------------------------------------------------------------*/

import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  Button,
  H3,
  HTMLTable,
  ControlGroup,
  Intent,
  Tag,
  Classes,
  Spinner,
} from '@blueprintjs/core'

import { PaperPlaneTilt, UserCircleMinus } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import UserIcon from '../user/UserIcon'
import UserName from '../user/UserName'

import { SuggestUser } from './SuggestUser'

import { useBoardActions } from '../../redux/actions/board/useBoardActions'

import { selectMyUid } from '../../redux/selectors/auth/auth'
import { useBoard } from '../../redux/selectorHooks/board/useBoard'
import { useContainerInvitations } from '../../redux/selectorHooks/container/useContainerInvitations'

import { PermissionLevel } from 'canvas-shared/lib/types/permissions.types'
import { UUID } from 'canvas-shared/lib/types'

/*---------------------------------------------------------------------------*/

export const BoardInviteDialogPeople = () => {
  const {
    addUserToBoard,
    addUserToBoardByEmail,
    deleteInvitation,
    removeUserFromBoard,
  } = useBoardActions()

  const { roles } = useBoard()
  const uid = useSelector(selectMyUid)

  const userIdsWithAccess = useMemo(() => Object.keys(roles.users), [
    roles.users,
  ])
  const invitations = useContainerInvitations()

  const alreadyInvitedEmail = useCallback(
    (email: string) => !!invitations?.find((i) => i.email === email),
    [invitations]
  )

  const alreadyInvitedUser = useCallback(
    (userId: UUID) => userIdsWithAccess.includes(userId),
    [userIdsWithAccess]
  )

  return (
    <>
      <H3>Share with people</H3>
      <ControlGroup className="flex flex-col">
        <SuggestUser
          alreadyInvitedEmail={alreadyInvitedEmail}
          alreadyInvitedUser={alreadyInvitedUser}
          onSelectEmail={addUserToBoardByEmail}
          onSelectUser={addUserToBoard}
        />
        <div className="border-border-blueprint-input-light dark:border-border-blueprint-input-dark w-full border border-t-0 rounded-bl-sm rounded-br-sm rounded-t-none overflow-hidden">
          <HTMLTable className="-mt-0.5 w-full" interactive striped>
            <tbody className="border-t-0">
              {userIdsWithAccess.map((userId) => {
                const isYou = userId === uid
                const isOwner = roles.users[userId] >= PermissionLevel.CREATOR
                const canDelete = !isYou && !isOwner
                return (
                  <tr key={userId} className="h-12">
                    <td className="align-middle py-0">
                      <span className="flex flex-row items-center justify-start space-x-2">
                        <UserIcon className="w-8 h-8" uid={userId} />
                        <UserName uid={userId} />
                      </span>
                    </td>
                    <td className="align-middle p-0 pr-3 text-right">
                      <span className="flex flex-row justify-end">
                        {isYou && (
                          <Tag className="mr-1" intent={Intent.SUCCESS} minimal>
                            You
                          </Tag>
                        )}
                        {isOwner && (
                          <Tag className="mr-1" intent={Intent.PRIMARY} minimal>
                            Owner
                          </Tag>
                        )}
                        {canDelete && (
                          <Button
                            className="rounded-sm"
                            icon={
                              <UserCircleMinus
                                className={Classes.ICON}
                                size={18}
                              />
                            }
                            minimal
                            onClick={() => removeUserFromBoard(userId)}
                          />
                        )}
                      </span>
                    </td>
                  </tr>
                )
              })}
              {invitations?.map((invitation) => (
                <tr key={invitation.id} className="h-12">
                  <td className="align-middle py-0">
                    <span className="flex flex-row items-center justify-start space-x-2">
                      <PaperPlaneTilt
                        className={Classes.TEXT_MUTED}
                        size={18}
                      />
                      <span>{invitation.email}</span>
                    </span>
                  </td>
                  <td className="align-middle p-0 pr-3 text-right">
                    <Tag
                      className="mr-1"
                      minimal
                      rightIcon={
                        !!invitation.emailSent ? undefined : (
                          <Spinner size={8} />
                        )
                      }
                    >
                      {!!invitation.emailSent ? 'Invited' : 'Inviting'}
                    </Tag>
                    <Button
                      className="rounded-sm"
                      icon={
                        <UserCircleMinus className={Classes.ICON} size={18} />
                      }
                      minimal
                      onClick={() => deleteInvitation(invitation.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </HTMLTable>
        </div>
      </ControlGroup>
    </>
  )
}
