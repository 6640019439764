/*---- External -------------------------------------------------------------*/

import { useFirebaseConnect } from 'react-redux-firebase'

/*---- Qualdesk -------------------------------------------------------------*/

import { commitIdPath } from 'canvas-shared/src/paths/commitId'

/*---------------------------------------------------------------------------*/

export const useSystemConnectors = () => {
  useFirebaseConnect(commitIdPath)
}
