/*---- External -------------------------------------------------------------*/

import { matchPath } from 'react-router-dom'
import { AnyAction } from 'redux'
import { LOCATION_CHANGE } from 'redux-first-history'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { filter, map } from 'rxjs/operators'
import { Params, Routes } from '../../router/routes'

/*---- Qualdesk -------------------------------------------------------------*/

import { resetSessionAction } from '../actionCreators/session'
import { pointerMoveAction } from '../actionCreators/pointers'
import { scrollOrZoomChangedAction } from '../actionCreators/scroll'
import { tickAutoScrollAction } from '../actionCreators/autoScroll'

import { generateUserPath } from '../paths/userPaths'
import { mySessionSelector } from '../selectors/board/session'
import { createRTDBEpic } from './helpers/createRTDBEpic'

import { ActionWithPayload, RootState } from '../../types/redux'

/*---------------------------------------------------------------------------*/

export const movePointerRTDBEpic = createRTDBEpic<any>(
  [
    pointerMoveAction.fulfilled,
    scrollOrZoomChangedAction.fulfilled,
    tickAutoScrollAction,
  ],
  ({ containerId, uid, state, database }) => {
    const session = mySessionSelector(state)
    database().ref(generateUserPath(containerId, uid)).update({
      position: session.position,
      lastSeen: database.ServerValue.TIMESTAMP,
    })
  }
)

const resetSessionEpic: Epic<AnyAction, AnyAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    filter((action) => {
      const previousContainerId = state$.value.params.containerId

      const pathname = (action as ActionWithPayload<{ location: Location }>)
        .payload.location.pathname
      const match = matchPath<Params>(pathname, Routes.CANVAS.path)
      const newContainerId = match?.params.containerId

      return !!newContainerId && newContainerId !== previousContainerId
    }),
    map(resetSessionAction)
  )

export const sessionEpic = combineEpics(movePointerRTDBEpic, resetSessionEpic)
