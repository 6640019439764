/*---- External -------------------------------------------------------------*/

import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { IMenuItemProps, IMenuDividerProps } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { Item } from 'canvas-shared/lib/types/Item.types'
import { removeItemsAction } from '../../../redux/actionCreators/items'

/*---------------------------------------------------------------------------*/

export const useTrashMenuItems = (
  actionItems: Item.BaseItem[]
): Array<IMenuItemProps | IMenuDividerProps> => {
  const dispatch = useDispatch()

  const handleTrash = useCallback(() => {
    dispatch(removeItemsAction(actionItems.map((item) => item.id)))
  }, [actionItems, dispatch])

  return useMemo(
    () => [
      {
        icon: 'trash' as 'trash',
        onClick: handleTrash,
        text: 'Delete',
      } as IMenuItemProps,
    ],
    [handleTrash]
  )
}
