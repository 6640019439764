/*---- External -------------------------------------------------------------*/

import { Action } from 'redux'
import { combineEpics, ofType } from 'redux-observable'
import { mapTo } from 'rxjs/operators'

/*---- Qualdesk -------------------------------------------------------------*/

import { ActionWithPayload, EpicWithDifferentActions } from '../../types/redux'
import { focusSetAction } from '../actionCreators/focus'
import { stopAllGesturesAction } from '../actionCreators/session'
import {
  generateScrollDimensionsPath,
  generateScrollOffsetsPath,
} from '../paths/scrollPaths'
import { generateIsFocusedPath } from '../paths/user/isFocusedPath'
import { createRTDBEpic } from './helpers/createRTDBEpic'

/*---------------------------------------------------------------------------*/

export const focusSetRTDBEpic = createRTDBEpic<boolean>(
  focusSetAction,
  ({ content, containerId, uid, database, state }) => {
    database().ref(generateIsFocusedPath(containerId, uid)).set(content)
    database()
      .ref(generateScrollDimensionsPath(containerId, uid))
      .set(state.board.session.data.scrollDimensions)
    database()
      .ref(generateScrollOffsetsPath(containerId, uid))
      .set(state.board.session.data.scrollOffsets)
  }
)

export const focusSetEpic: EpicWithDifferentActions<
  ActionWithPayload<boolean>,
  Action
> = (action$) =>
  action$.pipe(ofType(focusSetAction), mapTo(stopAllGesturesAction()))

export const focusEpic = combineEpics(focusSetRTDBEpic, focusSetEpic)
