/*---- External -------------------------------------------------------------*/

import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { Button, Intent, Spinner } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { trelloAuthUrl } from '../../../config/trello'

import { useTrelloRedirectPath } from '../../../hooks/router/useTrelloRedirectPath'

import { selectTrelloUserConnections } from '../../../redux/selectors/auth/userConnections'
import { useUserConnectionsActions } from '../../../redux/actions/auth/useUserConnectionsActions'

/*---------------------------------------------------------------------------*/

export const TrelloConnectButton: React.FC = ({ children }) => {
  const trello = useSelector(selectTrelloUserConnections)
  const { removeConnection } = useUserConnectionsActions()
  const [loading, setLoading] = useState(false)
  const [, setCallbackRedirectPath] = useTrelloRedirectPath()

  const saveLocationAndRedirect = () => {
    setLoading(true)
    setCallbackRedirectPath(window.location.pathname)
    window.location.href = trelloAuthUrl
  }

  const handleLogout = () => {
    removeConnection('trello')
  }

  if (trello && trello.connected) {
    if (children) {
      return <>{children}</>
    } else {
      return (
        <div>
          <Button
            icon="disable"
            intent={Intent.DANGER}
            text="Disconnect Trello"
            onClick={handleLogout}
          />
        </div>
      )
    }
  } else {
    return (
      <div>
        <Button
          intent={Intent.PRIMARY}
          icon={loading ? <Spinner size={16} /> : undefined}
          disabled={loading}
          text={loading ? 'Please wait…' : 'Sign in with Trello'}
          onClick={saveLocationAndRedirect}
        />
      </div>
    )
  }
}
