/*---- External -------------------------------------------------------------*/

import { combineReducers } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { clipboardSlice } from './slices/clipboard'
import { containerSlice } from './slices/container'
import { contextMenuSlice } from './slices/contextMenu'
import { cursorModeSlice } from './slices/cursorMode'
import { itemsSlice } from './slices/items'
import { pendingSlice } from './slices/pending'
import { sessionSlice } from './slices/session'
import { shouldSelectItemIdSlice } from './slices/shouldSelectItemId'
import { usersSlice } from './slices/users'

/*---------------------------------------------------------------------------*/

export const boardReducer = combineReducers({
  clipboard: clipboardSlice.reducer,
  container: containerSlice.reducer,
  contextMenu: contextMenuSlice.reducer,
  cursorMode: cursorModeSlice.reducer,
  items: itemsSlice.reducer,
  pending: pendingSlice.reducer,
  session: sessionSlice.reducer,
  shouldSelectItemId: shouldSelectItemIdSlice.reducer,
  users: usersSlice.reducer,
})
