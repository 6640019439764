/*---- External -------------------------------------------------------------*/

import React, { useCallback, useEffect, useState } from 'react'

import orderBy from 'lodash.orderby'
import { SortAscending, SortDescending } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/
import { SortOrderPopoverButton } from '../../../utilities/SortOrderPopoverButton'
import { PreviewItemWithBoardPresence } from 'canvas-shared/lib/types/PreviewItem.types'

/*---------------------------------------------------------------------------*/

enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

enum SortField {
  Created = 'created',
  Key = 'key',
  Summary = 'summary',
  Type = 'type',
  Updated = 'updated',
}

interface SortFieldOptions {
  value: SortField
  label: string
  fieldAccessor: (
    Card: PreviewItemWithBoardPresence.JiraCard
  ) => string | number | undefined
}

const DEFAULT_SORT_FIELD: SortField = SortField.Created
const DEFAULT_SORT_DIRECTION: SortDirection = SortDirection.Desc

const SORT_FIELDS_OPTIONS: SortFieldOptions[] = [
  {
    value: SortField.Created,
    label: 'Created',
    fieldAccessor: (card) => card.data.createdAt,
  },
  {
    value: SortField.Key,
    label: 'Key',
    fieldAccessor: (card) => card.data.id,
  },
  {
    value: SortField.Summary,
    label: 'Summary',
    fieldAccessor: (card) => card.data.title,
  },
  {
    value: SortField.Type,
    label: 'Type',
    fieldAccessor: (card) => card.data.type.name,
  },
  {
    value: SortField.Updated,
    label: 'Updated',
    fieldAccessor: (card) => card.data.updatedAt,
  },
]

interface ResultSorterProps {
  setSortedCards: (cards: PreviewItemWithBoardPresence.JiraCard[]) => void
  cards: PreviewItemWithBoardPresence.JiraCard[]
}

export const JiraContentBrowserResultSorter: React.FC<ResultSorterProps> = ({
  setSortedCards,
  cards,
}) => {
  const [sortFieldOptions, setSortFieldOptions] = useState<SortFieldOptions>(
    SORT_FIELDS_OPTIONS.find((o) => o.value === DEFAULT_SORT_FIELD)!
  )
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    DEFAULT_SORT_DIRECTION
  )

  useEffect(() => {
    const sorter = (
      cards: PreviewItemWithBoardPresence.JiraCard[]
    ): PreviewItemWithBoardPresence.JiraCard[] => {
      return orderBy(cards, [sortFieldOptions.fieldAccessor], [sortDirection])
    }
    const sortedCards = sorter(cards)
    setSortedCards(sortedCards)
  }, [sortFieldOptions, sortDirection, setSortedCards, cards])

  const onChangeSortField = useCallback(
    (value) => {
      setSortFieldOptions(SORT_FIELDS_OPTIONS.find((o) => o.value === value)!)
    },
    [setSortFieldOptions]
  )

  const onChangeSortDirection = useCallback(
    (value) => {
      setSortDirection(value)
    },
    [setSortDirection]
  )

  return (
    <SortOrderPopoverButton
      sort={sortDirection}
      order={sortFieldOptions.value}
      sortOptions={[
        {
          label: 'Ascending',
          value: SortDirection.Asc,
          icon: SortAscending,
        },
        {
          label: 'Descending',
          value: SortDirection.Desc,
          icon: SortDescending,
        },
      ]}
      orderOptions={SORT_FIELDS_OPTIONS.map((s) => ({
        label: s.label,
        value: s.value,
      }))}
      onChangeSort={onChangeSortDirection}
      onChangeOrder={onChangeSortField}
    />
  )
}
