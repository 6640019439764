/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

export const usersPath = 'users'
export const generateUserPath = (userId: string) => `${usersPath}/${userId}`
export const generateUserConnectionsPath = (userId: string) =>
  `${generateUserPath(userId)}/userConnections`
