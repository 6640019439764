/*---- External -------------------------------------------------------------*/

import React, { useEffect, useMemo, useState } from 'react'
import { generatePath, NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { firstBy } from 'thenby'

import { Helmet } from 'react-helmet-async'

import { useInView } from 'react-intersection-observer'

import { Gear } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import { useSearchContext } from '../../contexts/Search'
import { selectMyUid } from '../../redux/selectors/auth/auth'
import { teamsReadySelector } from '../../redux/selectors/auth/teams'

import { WorkspaceBoardCard } from '../../components/workspace/WorkspaceBoardCard'
import { WorkspaceCardWrapper } from '../../components/workspace/WorkspaceCardWrapper'
import { WorkspaceActionCard } from './WorkspaceActionCard'
import { WorkspaceFilterButton } from './WorkspaceFilterButton'
import { WorkspaceCard } from './WorkspaceCard'
import { WorkspaceEmptyStateCard } from './WorkspaceEmptyStateCard'
import { WorkspaceTeamMembers } from './WorkspaceTeamMembers'

import { NoResults } from '../utilities/NoResults'
import LoadingSpinner from '../loading/LoadingSpinner'

import { Routes } from '../../router/routes'

import {
  useWorkspaceOrder,
  useWorkspaceSort,
} from '../../hooks/localStorage/workspaceSort'

import { compareBoardTitles } from 'canvas-shared/lib/sort/compareBoardTitles'
import { compareCreatedAt } from 'canvas-shared/lib/sort/compareCreatedAt'
import { compareUpdatedAt } from 'canvas-shared/lib/sort/compareUpdatedAt'

import { Board } from 'canvas-shared/lib/types/Board.types'
import {
  WorkspaceActionProps,
  WorkspaceEmptyState,
  WorkspaceOrder,
} from '../../types/workspace'
import { Team } from 'canvas-shared/lib/types/Team.types'
import { PermissionLevel } from 'canvas-shared/lib/types/permissions.types'

/*---------------------------------------------------------------------------*/

interface WorkspaceScrollViewProps {
  actions?: WorkspaceActionProps[]
  boards: Board[] | undefined
  emptyState?: WorkspaceEmptyState
  title: string
  team?: Team
}

export const WorkspaceScrollView: React.FC<WorkspaceScrollViewProps> = ({
  actions,
  boards,
  emptyState,
  title,
  team,
}) => {
  const uid = useSelector(selectMyUid)
  const [scrollTrigger, inView, entry] = useInView()
  const [showScrolledHeader, setShowScrolledHeader] = useState(false)

  const ready = useSelector(teamsReadySelector)

  useEffect(() => {
    setShowScrolledHeader(!!entry && !inView)
  }, [inView, entry])

  const [order] = useWorkspaceOrder()
  const [sort] = useWorkspaceSort()
  const { searchQuery } = useSearchContext()

  const sortedBoards = useMemo(() => {
    if (!boards) {
      return []
    }

    switch (order) {
      case WorkspaceOrder.Created:
        return [...boards].sort(
          firstBy(compareCreatedAt, sort).thenBy(compareBoardTitles)
        )
      case WorkspaceOrder.Updated:
        return [...boards].sort(
          firstBy(compareUpdatedAt, sort).thenBy(compareBoardTitles)
        )
    }
  }, [boards, order, sort])

  const filteredBoards = useMemo(() => {
    if (!searchQuery) {
      return sortedBoards
    } else {
      return sortedBoards.filter(
        (b) =>
          !!b.data.title &&
          b.data.title
            .toLocaleLowerCase()
            .includes(searchQuery.toLocaleLowerCase())
      )
    }
  }, [searchQuery, sortedBoards])

  const teamSettingsPath = useMemo(() => {
    if (!team || (team?.roles.users[uid] || 0) < PermissionLevel.ADMIN) {
      return undefined
    }

    return generatePath(Routes.WORKSPACE_TEAM_SETTINGS.path, {
      teamId: team.id,
    })
  }, [team, uid])

  return (
    <div className="bg-background-four-light dark:bg-background-four-dark relative flex flex-col flex-grow h-full overflow-hidden">
      <Helmet title={title} />
      <div className="flex-grow flex-shrink overflow-auto">
        <div
          className={
            showScrolledHeader
              ? 'z-20 absolute left-0 right-0 bg-white dark:bg-cool-gray-800 flex-grow px-6 py-4 shadow flex items-center'
              : 'flex-grow mt-12 mx-12 flex flex-row justify-between items-end'
          }
        >
          <div className="flex flex-col space-y-4">
            <div className="flex flex-row items-center space-x-2">
              <div
                className={
                  showScrolledHeader
                    ? 'text-lg font-semibold'
                    : 'text-3xl font-semibold'
                }
              >
                {title}
              </div>
              {!!teamSettingsPath && (
                <NavLink to={teamSettingsPath}>
                  <div className="text-icon-secondary-light dark:text-icon-secondary-dark hover:text-icon-primary-light dark:hover:text-icon-primary-dark hover:bg-blue-gray-200 dark:hover:bg-cool-gray-800 p-2 rounded-full">
                    <Gear className="w-4 h-4" weight="fill" />
                  </div>
                </NavLink>
              )}
            </div>
            {!!team && !showScrolledHeader && (
              <WorkspaceTeamMembers {...team} />
            )}
          </div>
          <WorkspaceFilterButton
            className={showScrolledHeader ? 'ml-auto' : undefined}
            disabled={!ready}
            hideSort={showScrolledHeader}
          />
        </div>
        {showScrolledHeader && (
          <div className="flex flex-col space-y-4">
            <div className="mt-12 mx-12 text-3xl font-semibold">{title}</div>
            {!!team && (
              <div className="mx-12">
                <WorkspaceTeamMembers {...team} />
              </div>
            )}
          </div>
        )}
        <div ref={scrollTrigger} />
        {ready ? (
          <>
            <WorkspaceCardWrapper>
              {!searchQuery &&
                actions?.map((action) => (
                  <WorkspaceActionCard key={action.text} {...action} />
                ))}
              {!searchQuery && !!emptyState && filteredBoards.length === 0 && (
                <WorkspaceEmptyStateCard {...emptyState} />
              )}
              {filteredBoards.map((board) => (
                <WorkspaceBoardCard boardId={board.id} key={board.id} />
              ))}
              {!!searchQuery && filteredBoards.length === 0 && (
                <WorkspaceCard className="ring-border-normal-light dark:ring-border-normal-dark ring-1">
                  <NoResults className="flex-grow" />
                </WorkspaceCard>
              )}
            </WorkspaceCardWrapper>
          </>
        ) : (
          <div className="absolute bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center">
            <LoadingSpinner />
          </div>
        )}
      </div>
    </div>
  )
}
