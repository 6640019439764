/*---- External -------------------------------------------------------------*/
import React from 'react'
import { Check } from 'phosphor-react'

export const PreviewCardAddedToBoard: React.FC = () => {
  return (
    <div className="flex items-center space-x-1">
      <Check
        className="text-icon-accent-light dark:text-icon-accent-dark"
        size={16}
        weight="bold"
      />
      <span className="text-text-secondary-light dark:text-text-primary-dark text-sm">
        Added to the board
      </span>
    </div>
  )
}
