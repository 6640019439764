/*---- External -------------------------------------------------------------*/

import { useDispatch } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { pointerDownAction } from '../../../redux/actionCreators/pointers'

/*---------------------------------------------------------------------------*/

export const usePointerDownHandler = () => {
  const dispatch = useDispatch()

  const onPointerDown: React.PointerEventHandler = (e: React.PointerEvent) => {
    // If this is a right button non-click, stop processing here and prevent drag
    if (e.ctrlKey || e.nativeEvent.button === 2) {
      e.stopPropagation()
      return
    }
    // If a Blueprint overlay is open, prevent events from propagating through to canvas

    if ((e.target as HTMLElement).closest('.bp3-portal')) {
      e.stopPropagation()
      return
    }

    // Disable browser selection so that Safari displays the correct cursor
    document.onselectstart = () => false

    dispatch(pointerDownAction(e))
  }

  return onPointerDown
}
