/*---- External -------------------------------------------------------------*/

import React from 'react'

import { useHistory, useRouteMatch } from 'react-router-dom'

import { Button, IPanelProps } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { Header } from './Header'

import jiraIcon from '../../assets/integrations/jira/jira-icon.svg'
import googleGIcon from '../../assets/integrations/google/google-g-icon.svg'
import trelloIcon from '../../assets/integrations/trello/trello-icon.svg'
import linearIcon from '../../assets/integrations/linear/linear-icon.svg'

/*---------------------------------------------------------------------------*/

export const Onboarding: React.FC<IPanelProps> = () => {
  const { url } = useRouteMatch()
  const { push } = useHistory()

  return (
    <div className="px-6 py-14">
      <Header title="Get started" subtitle="Create your first connection" />
      <ul className="mt-10 space-y-4">
        <li>
          <CreateConnectionTile
            title="Google"
            iconUrl={googleGIcon}
            iconAltText="Google icon"
            onConnect={() => push(`${url}/google`)}
          />
        </li>
        <li>
          <CreateConnectionTile
            title="Jira"
            iconUrl={jiraIcon}
            iconAltText="Jira icon"
            onConnect={() => push(`${url}/jira`)}
          />
        </li>
        <li>
          <CreateConnectionTile
            title="Linear"
            iconUrl={linearIcon}
            iconAltText="Linear icon"
            onConnect={() => push(`${url}/linear`)}
          />
        </li>
        <li>
          <CreateConnectionTile
            title="Trello"
            iconUrl={trelloIcon}
            iconAltText="Trello icon"
            onConnect={() => push(`${url}/trello`)}
          />
        </li>
      </ul>
    </div>
  )
}

interface CreateConnectionTileProps {
  title: string
  iconUrl: string
  iconAltText: string
  onConnect: () => void
}

const CreateConnectionTile: React.FC<CreateConnectionTileProps> = ({
  title,
  iconUrl,
  iconAltText,
  onConnect,
}) => (
  <div className="flex items-center space-x-3">
    <div className="bg-background-five-light dark:bg-background-four-dark border-border-weak-light dark:border-border-weak-dark flex items-center justify-center w-12 h-12 border rounded-xl">
      <img className="w-5 h-5" src={iconUrl} alt={iconAltText} />
    </div>
    <div className="text-text-primary-light dark:text-text-primary-dark flex-grow text-base">
      {title}
    </div>
    <Button intent="primary" minimal onClick={() => onConnect()}>
      Connect
    </Button>
  </div>
)
