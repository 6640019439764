/*---- External -------------------------------------------------------------*/

import {
  AsyncThunkPayloadCreator,
  createAction,
  createAsyncThunk,
  nanoid,
} from '@reduxjs/toolkit'
import { matchPath } from 'react-router-dom'

/*---- Qualdesk -------------------------------------------------------------*/

import { QUALDESK_MIME_TYPE } from 'canvas-shared/lib/config/data'
import { CURRENT_TYPE_VERSION } from 'canvas-shared/lib/config/migration'

import { app } from '../../config/firebase'

import { generateOperation } from '../actions/helpers/generateOperation'

import {
  positionsDataSelector,
  selectedItemsPositionsSelector,
  selectStartZ,
} from '../selectors/board/positions'
import {
  mySelectedItemIdsSelector,
  mySelectedItemsSelector,
} from '../selectors/board/selections'
import { selectMyUid } from '../selectors/auth/auth'

import { canBeCopied } from '../../helpers/canBeCopied'

import { Routes } from '../../router/routes'

import { UUID } from 'canvas-shared/lib/types'
import { PendingStateSlice, ThunkAPI } from '../../types/redux'
import { Item } from 'canvas-shared/lib/types/Item.types'
import { ItemPosition } from 'canvas-shared/lib/types/Position.types'

/*---------------------------------------------------------------------------*/

export const clearClipboardAction = createAction('board/clearClipboard')

const handleCutOrCopy: AsyncThunkPayloadCreator<
  UUID[],
  ClipboardEvent,
  ThunkAPI
> = async (e, { getState }) => {
  const state = getState()
  const selectedItems = mySelectedItemsSelector(state)
  const selectedItemIds = mySelectedItemIdsSelector(state)
  const selectedItemPositions = selectedItemsPositionsSelector(state)

  const targetType = (e.srcElement as HTMLElement).nodeName

  if (targetType === 'INPUT' || targetType === 'TEXTAREA') {
    return []
  }

  if (canBeCopied(selectedItems)) {
    e.preventDefault()

    e.clipboardData?.setData(
      QUALDESK_MIME_TYPE,
      JSON.stringify({
        items: selectedItems,
        positions: selectedItemPositions,
      })
    )

    return selectedItemIds
  } else {
    return Promise.reject()
  }
}

export const cutAction = createAsyncThunk<UUID[], ClipboardEvent, ThunkAPI>(
  'board/cut',
  handleCutOrCopy
)
export const copyAction = createAsyncThunk<UUID[], ClipboardEvent, ThunkAPI>(
  'board/copy',
  handleCutOrCopy
)

interface PasteActionPayload extends Partial<PendingStateSlice> {
  text?: string
}

export const pasteAction = createAsyncThunk<
  PasteActionPayload,
  ClipboardEvent,
  ThunkAPI
>('board/paste', async (e, { getState }) => {
  const state = getState()

  const targetType = (e.srcElement as HTMLElement).nodeName

  const onCanvas = !!matchPath(state.router.location.pathname, {
    path: Routes.CANVAS.path,
    exact: true,
  })

  if (!onCanvas) {
    // Don’t intercept paste events in RoutableDialog or when search results are displaying
    return {}
  }

  if (targetType === 'INPUT' || targetType === 'TEXTAREA') {
    // Don’t intercept paste events in text fields
    return {}
  }

  e.preventDefault()

  const qualdeskData = e.clipboardData?.getData(QUALDESK_MIME_TYPE)
  const text = e.clipboardData?.getData('text/plain')

  if (qualdeskData) {
    const parsed = JSON.parse(qualdeskData)
    const parsedItems = parsed.items as Item.AnyItem[]
    const parsedPositions = parsed.positions as ItemPosition[]

    if (!parsedItems || !parsedPositions) {
      return Promise.reject()
    }

    const uid = selectMyUid(state)
    const startZ = selectStartZ(state)
    const allPositions = positionsDataSelector(state)
    const operation = generateOperation(uid)

    return parsedItems.reduce(
      (res: PendingStateSlice, item, i) => {
        const newId = nanoid()

        const newItem = {
          ...item,
          id: newId,
          typeVersion: CURRENT_TYPE_VERSION,
          createdAt: app.firestore.Timestamp.now(),
          createdBy: operation,
          updatedAt: app.firestore.Timestamp.now(),
          updatedBy: operation,
        }

        const newPosition: ItemPosition = {
          ...allPositions[item.id],
          id: newId,
          z: startZ + i,
          operation,
        }

        return {
          items: { ...res.items, [newId]: newItem },
          positions: { ...res.positions, [newId]: newPosition },
        }
      },
      { items: {}, positions: {} }
    )
  } else if (text) {
    return { text }
  } else {
    return Promise.reject()
  }
})
