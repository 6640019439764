/*---- External -------------------------------------------------------------*/

import React, { useMemo } from 'react'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  getBackgroundColorClassName,
  getTextColorClassName,
} from 'canvas-shared/lib/config/colors'

import { ItemGenericTextInner } from './ItemGenericTextInner'

import { Item } from 'canvas-shared/lib/types/Item.types'

/*---------------------------------------------------------------------------*/

export const ItemRectangle: React.FC<Item.Rectangle> = (item) => {
  const { data } = item

  const style: React.CSSProperties = useMemo(
    () => ({
      fontSize: data.textSize,
    }),
    [data.textSize]
  )

  return (
    <div
      className={classNames(
        data.textStyle,
        `text-${data.textAlignment}`,
        getTextColorClassName(data.textColor, 500),
        getBackgroundColorClassName(data.backgroundColor, 200),
        'bg-opacity-75',
        'flex flex-col w-full h-full overflow-hidden'
      )}
      style={style}
    >
      <ItemGenericTextInner
        className="p-3"
        itemId={item.id}
        selectTextOnCreation={false}
      />
    </div>
  )
}
