"use strict";
/*---- External -------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContainerType = void 0;
/*---- Qualdesk -------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
var ContainerType;
(function (ContainerType) {
    ContainerType["Board"] = "Board";
})(ContainerType = exports.ContainerType || (exports.ContainerType = {}));
