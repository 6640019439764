/*---- External -------------------------------------------------------------*/

import React, { useState, useEffect } from 'react'

import { Redirect } from 'react-router-dom'

import { useFirebase } from 'react-redux-firebase'

/*---- Qualdesk -------------------------------------------------------------*/

import { Routes } from '../../router/routes'
import Loading from '../../components/loading/Loading'

/*---------------------------------------------------------------------------*/

const SignOut: React.FC = () => {
  const [processing, setProcessing] = useState(true)
  const firebase = useFirebase()

  const handleSignOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => setProcessing(false))
  }

  useEffect(handleSignOut, [firebase])

  if (processing) {
    return <Loading />
  }

  return <Redirect to={Routes.HOME.path} />
}

export default SignOut
