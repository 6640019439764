/*---- External -------------------------------------------------------------*/

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { scrollDimensionsSelector } from '../../selectors/board/scroll'

/*---------------------------------------------------------------------------*/

export const useScrollDimensions = () => useSelector(scrollDimensionsSelector)
