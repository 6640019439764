/*---- External -------------------------------------------------------------*/

import { useCallback, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import queryString from 'query-string'

/*---- Qualdesk -------------------------------------------------------------*/

import { functions } from '../../config/firebase'
import { useBoard } from '../../redux/selectorHooks/board/useBoard'
import { useStatusReporter } from '../useStatusReporter'

/*---------------------------------------------------------------------------*/

const fetchTrelloBoard = functions.httpsCallable('https-trelloFetchTrelloBoard')

export const useImportFromUrl = () => {
  const { id: canvasId } = useBoard()
  const { push } = useHistory()
  const { reportProgress, reportSuccess, reportError } = useStatusReporter()

  const importFromExternalService = useCallback(async () => {
    const { import: service, externalId: id } = queryString.parse(
      window.location.search
    )

    if (service === 'trello' && id !== undefined) {
      reportProgress('Importing your board from Trello…')

      try {
        await fetchTrelloBoard({ canvasId, id })
        reportSuccess('Trello board imported successfully')
      } catch (error) {
        reportError(error, 'Failed to import Trello board')
      } finally {
        push(window.location.pathname)
      }
    }
  }, [canvasId, reportProgress, reportSuccess, reportError, push])

  useEffect(() => {
    importFromExternalService()
  }, [importFromExternalService])
}
