/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { RootState } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

export const contextMenuOpenSelector = (state: RootState) =>
  !!state.board.contextMenu.coords
