/*---- External -------------------------------------------------------------*/

import { useReducer } from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

export const useToggle = (initialState: boolean) =>
  useReducer((state) => !state, initialState)
