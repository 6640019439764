/*---- External -------------------------------------------------------------*/

import React from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import FullScreenModalBackdrop from '../utilities/FullScreenModalBackdrop'
import LoadingSpinner from './LoadingSpinner'

/*---------------------------------------------------------------------------*/

interface LoadingProps {
  text?: string
  value?: number
}

const Loading: React.FC<LoadingProps> = ({ text, value }) => (
  <FullScreenModalBackdrop>
    <LoadingSpinner value={value} />
    <div className="absolute mt-24 text-gray-800 font-semibold">{text}</div>
  </FullScreenModalBackdrop>
)

export default Loading
