/*---- External -------------------------------------------------------------*/

import React, { useMemo } from 'react'

import classNames from 'classnames'
import tinycolor from '@ctrl/tinycolor'

/*---- Qualdesk -------------------------------------------------------------*/

import { useDarkMode } from '../../../hooks/darkMode/useDarkMode'

/*---------------------------------------------------------------------------*/

interface LinearIssueLabelProps {
  text: string
  color?: string
  small?: boolean
}

export const LinearIssueLabel: React.FC<LinearIssueLabelProps> = ({
  text,
  color,
  small = false,
}) => {
  const darkMode = useDarkMode()

  const style = useMemo(() => {
    if (color) {
      // linear colors are user-defined; this ensures legibility
      if (darkMode) {
        return {
          color: tinycolor(color).lighten(15).toString(),
        }
      } else {
        return {
          color: tinycolor(color).darken(40).toString(),
          backgroundColor: tinycolor(color).setAlpha(0.5).toString(),
        }
      }
    } else {
      return {}
    }
  }, [darkMode, color])

  return (
    <span
      className={classNames(
        'block border border-transparent rounded-full truncate',
        {
          'px-3 text-base leading-6': !small,
          'px-2 text-xs leading-4': small,
          'border-border-normal-light dark:border-border-normal-dark': darkMode,
          'bg-background-two-light dark:bg-background-two-dark':
            !color && !darkMode,
        }
      )}
      style={style}
    >
      {text}
    </span>
  )
}
