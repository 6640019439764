/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { Item, ItemType } from 'canvas-shared/lib/types/Item.types'
import { ItemConfigurations } from '../config/itemTypes'
import { defaultTextSize } from '../config/textSizes'

/*---------------------------------------------------------------------------*/
export const getItemDimensions = (type: ItemType, _data: Item.BaseData) => {
  let width = DEFAULT_WIDTH
  let height = DEFAULT_HEIGHT

  const { defaultWidth, defaultHeight } = ItemConfigurations[type]

  if (defaultWidth && defaultHeight) {
    width = defaultWidth
    height = defaultHeight
  }

  if (type === ItemType.Text) {
    height = defaultHeight
      ? (defaultHeight / defaultTextSize) * 10
      : DEFAULT_HEIGHT
    width = defaultWidth ? (defaultWidth / defaultTextSize) * 10 : DEFAULT_WIDTH
  }

  return { width, height }
}

const DEFAULT_HEIGHT = 200
const DEFAULT_WIDTH = 350
