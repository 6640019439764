/*---- External -------------------------------------------------------------*/

import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Vector2 } from 'react-use-gesture/dist/types'

import clamp from 'lodash.clamp'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  DEFAULT_SCROLL_OFFSETS,
  MAX_ZOOM_LEVEL,
  MIN_ZOOM_LEVEL,
} from '../../config/canvas'

import { itemPositionSelector } from '../selectors/board/positions'
import {
  scrollDimensionsSelector,
  scrollOffsetsSelector,
} from '../selectors/board/scroll'
import { selectZoomLevel } from '../selectors/board/zoom'

import { addRectDimensionsToPositionIfRequired } from 'canvas-shared/lib/helpers/addRectDimensionsToPositionIfRequired'

import {
  ScrollDimensions,
  ScrollOffsets,
} from 'canvas-shared/lib/types/scrollAndPosition.types'
import { RootState, ThunkAPI } from '../../types/redux'
import { UUID } from 'canvas-shared/lib/types'
import { Gesture } from 'canvas-shared/lib/types/pointerEvents.types'
import { selectActiveGesture } from '../selectors/board/gestures'
import { containerIdSelector } from '../selectors/board/container'
import { containerSelector } from '../selectors/container/containers'

/*---------------------------------------------------------------------------*/

export const scrollDimensionsSetAllAction = createAsyncThunk<
  ScrollDimensions,
  ScrollDimensions,
  ThunkAPI
>('scrollDimensions/setAll', (originalScrollDimensions, { getState }) => {
  const state = getState()
  const boardId = containerIdSelector(state)
  const boardSelector = containerSelector(boardId)
  const board = boardSelector(state, boardId)

  if (!board) {
    return originalScrollDimensions
  }

  return {
    ...originalScrollDimensions,
    scrollHeight: board?.data.canvasHeight,
    scrollWidth: board?.data.canvasWidth,
  }
})

export const scrollToAction = createAction<ScrollOffsets>('scroll/to')
export const scrollAndZoomAction = createAsyncThunk<
  {
    scrollOffsets: ScrollOffsets
    zoomLevel: number
  },
  { zoomLevel: number; pageX?: number; pageY?: number },
  ThunkAPI
>(
  'scroll/andZoom',
  ({ zoomLevel: desiredZoomLevel, pageX, pageY }, { getState }) => {
    const state = getState()
    const newZoomLevel = clamp(desiredZoomLevel, MIN_ZOOM_LEVEL, MAX_ZOOM_LEVEL)
    const scrollOffsets = calculateScrollOffsetsFromOptionalPageXPageY(
      state,
      newZoomLevel,
      pageX,
      pageY
    )
    return { zoomLevel: newZoomLevel, scrollOffsets }
  }
)
export const scrollAndZoomByAction = createAsyncThunk<
  {
    scrollOffsets: ScrollOffsets
    zoomLevel: number
  },
  { zoomDelta: number; pageX?: number; pageY?: number },
  ThunkAPI
>('scroll/andZoomBy', ({ zoomDelta, pageX, pageY }, { getState }) => {
  const state = getState()
  const zoomLevel = selectZoomLevel(state)
  const newZoomLevel = clamp(
    zoomLevel + zoomDelta * zoomLevel,
    MIN_ZOOM_LEVEL,
    MAX_ZOOM_LEVEL
  )
  const scrollOffsets = calculateScrollOffsetsFromOptionalPageXPageY(
    state,
    newZoomLevel,
    pageX,
    pageY
  )
  return { zoomLevel: newZoomLevel, scrollOffsets }
})

export interface ScrollToCenterOnPayload {
  left: number
  top: number
  width?: number
  height?: number
}

export const scrollToCenterOnAction = createAsyncThunk<
  ScrollOffsets,
  ScrollToCenterOnPayload,
  ThunkAPI
>('scroll/centerOn', (payload, { getState }) => {
  const state = getState()
  return calculateScrollOffsetsFromPayload(payload, state)
})

export const scrollToCenterOnItemIdAction = createAsyncThunk<
  ScrollOffsets,
  UUID,
  ThunkAPI
>('scroll/centerOnItemId', (itemId, { getState }) => {
  const state = getState()
  const position = itemPositionSelector(state, itemId)
  if (!!position) {
    return calculateScrollOffsetsFromPayload(
      addRectDimensionsToPositionIfRequired(position),
      state
    )
  } else {
    return DEFAULT_SCROLL_OFFSETS
  }
})

export const scrollByAction = createAsyncThunk<
  ScrollOffsets,
  Vector2,
  ThunkAPI
>('scroll/scrollBy', ([x, y], { getState }) => {
  const state = getState()
  let { scrollLeft, scrollTop } = scrollOffsetsSelector(state)

  scrollLeft += x
  scrollTop += y

  return { scrollLeft, scrollTop }
})

export const startScrollGestureAction = createAction('scroll/gesture/start')
export const endScrollGestureAction = createAction('scroll/gesture/end')

const calculateScrollOffsetsFromPayload = (
  { left, top, width, height }: ScrollToCenterOnPayload,
  state: RootState
): ScrollOffsets => {
  const scrollDimensions = scrollDimensionsSelector(state)
  const zoomLevel = selectZoomLevel(state)

  const {
    scrollHeight,
    scrollWidth,
    clientHeight,
    clientWidth,
  } = scrollDimensions

  let desiredScrollLeft = left * zoomLevel - clientWidth * 0.5
  let desiredScrollTop = top * zoomLevel - clientHeight * 0.5

  if (width && height) {
    desiredScrollLeft += width / 2
    desiredScrollTop += height / 2
  }

  const scrollLeft = Math.max(
    Math.min(desiredScrollLeft, scrollWidth - clientWidth),
    0
  )
  const scrollTop = Math.max(
    Math.min(desiredScrollTop, scrollHeight - clientHeight),
    0
  )

  return { scrollLeft, scrollTop }
}

const calculateScrollOffsetsFromOptionalPageXPageY = (
  state: RootState,
  newZoomLevel: number,
  pageX?: number,
  pageY?: number
) => {
  const { scrollLeft, scrollTop } = scrollOffsetsSelector(state)
  const {
    clientWidth,
    clientHeight,
    canvasLeft,
    canvasTop,
  } = scrollDimensionsSelector(state)
  const zoomLevel = selectZoomLevel(state)

  const midpointLeft =
    scrollLeft + (pageX !== undefined ? pageX - canvasLeft : clientWidth / 2)
  const midpointTop =
    scrollTop + (pageY !== undefined ? pageY - canvasTop : clientHeight / 2)

  const desiredMidpointLeft = (midpointLeft * newZoomLevel) / zoomLevel
  const desiredMidpointTop = (midpointTop * newZoomLevel) / zoomLevel

  const newScrollLeft =
    desiredMidpointLeft -
    (pageX !== undefined ? pageX - canvasLeft : clientWidth / 2)
  const newScrollTop =
    desiredMidpointTop -
    (pageY !== undefined ? pageY - canvasTop : clientHeight / 2)

  return { scrollLeft: newScrollLeft, scrollTop: newScrollTop }
}

export const scrollOrZoomChangedAction = createAsyncThunk<
  { gesture?: Gesture },
  void,
  ThunkAPI
>('scroll/changed', (_, { getState }) => {
  const gesture = selectActiveGesture(getState())

  return { gesture }
})
