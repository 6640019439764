/*---- External -------------------------------------------------------------*/

import { combineEpics, ofType } from 'redux-observable'
import { filter, map } from 'rxjs/operators'

/*---- Qualdesk -------------------------------------------------------------*/

import { altKeyAction } from '../actionCreators/keys'
import {
  duplicateItemsWithDragAction,
  pendingCancelAllAction,
} from '../actionCreators/pending'

import { selectGestureInProgress } from '../selectors/board/gestures'

import type {
  ActionWithPayload,
  EpicWithDifferentActions,
} from '../../types/redux'

/*---------------------------------------------------------------------------*/

const altKeyDuplicationEpic: EpicWithDifferentActions<
  ActionWithPayload<boolean>,
  | ReturnType<typeof duplicateItemsWithDragAction>
  | ReturnType<typeof pendingCancelAllAction>
> = (action$, state$) =>
  action$.pipe(
    ofType(altKeyAction),
    filter(() => selectGestureInProgress(state$.value)),
    map((action$) => {
      if (action$.payload === true) {
        return duplicateItemsWithDragAction()
      } else {
        return pendingCancelAllAction()
      }
    })
  )

export const duplicationEpic = combineEpics(altKeyDuplicationEpic)
