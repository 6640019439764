/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { containerPath } from 'canvas-shared/lib/paths/containerPath'

import { UUID } from '../../types'

/*---------------------------------------------------------------------------*/

export const generatePositionsPath = (containerId: UUID) =>
  `${containerPath(containerId)}/positions`
export const generateItemPositionPath = (containerId: UUID, itemId: UUID) =>
  `${generatePositionsPath(containerId)}/${itemId}`
