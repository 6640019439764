import app from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/functions'
import 'firebase/auth'
import 'firebase/storage'

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  region: process.env.REACT_APP_FIREBASE_REGION,
}

const runningLocally =
  window.location.hostname === 'localhost' ||
  window.location.hostname.startsWith('192.168.')

if (runningLocally) {
  config.databaseURL = `http://${window.location.hostname}:9000?ns=qualdesk-canvas-dev`
}

const firebase = app
const firebaseApp = firebase.initializeApp(config)
const firestore = firebaseApp.firestore()
const functions = firebaseApp.functions(config.region)
const storage = firebase.storage()

if (runningLocally) {
  firestore.settings({
    ignoreUndefinedProperties: true,
    host: `${window.location.hostname}:8081`,
    ssl: false,
    // @ts-ignore
    experimentalForceLongPolling: !!window.Cypress,
  })

  functions.useFunctionsEmulator(`http://${window.location.hostname}:5001`)
  process.env.REACT_APP_USE_FIREBASE_AUTH_EMULATOR === 'true' &&
    firebaseApp.auth().useEmulator(`http://${window.location.hostname}:9099/`)
} else {
  firestore.settings({ ignoreUndefinedProperties: true })
}

export { app, functions, storage }
