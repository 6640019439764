"use strict";
/*---- External -------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserConnectionType = void 0;
var UserConnectionType;
(function (UserConnectionType) {
    UserConnectionType["Google"] = "Google";
    UserConnectionType["Trello"] = "Trello";
    UserConnectionType["Jira"] = "Jira";
    UserConnectionType["Linear"] = "Linear";
})(UserConnectionType = exports.UserConnectionType || (exports.UserConnectionType = {}));
