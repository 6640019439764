/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'
import { createCachedSelector } from 're-reselect'

/*---- Qualdesk -------------------------------------------------------------*/

import { getItemsArray, getItemId } from './items'

import { myUserSelector } from './users'
import { NO_SELECTED_ITEM_IDS } from '../config/defaults'
import { createEqualByKeySelector } from '../helpers/createEqualByKeySelector'
import { selectActiveGesture } from './gestures'

/*---------------------------------------------------------------------------*/

const createSelectedItemIdsSelector = createEqualByKeySelector('selection')

export const mySelection = createSelectedItemIdsSelector(
  myUserSelector,
  (user) => user.selection
)

export const mySelectedItemIdsSelector = createSelectedItemIdsSelector(
  myUserSelector,
  (user) =>
    !!user && !!user.selection
      ? Object.keys(user.selection)
      : NO_SELECTED_ITEM_IDS
)

export const mySelectedItemsSelector = createSelector(
  mySelectedItemIdsSelector,
  getItemsArray,
  (selectedItemIds, items) =>
    items.filter((item) => selectedItemIds.includes(item.id))
)

export const itemIsSelectedSelector = createCachedSelector(
  getItemId,
  mySelectedItemIdsSelector,
  (itemId, selectedItemIds) => selectedItemIds.includes(itemId)
)((state, itemId) => itemId)

export const selectSelectedItemIdsInActiveGesture = createSelector(
  selectActiveGesture,
  (activeGesture) =>
    activeGesture?.selectedItemPositions.map((item) => item.id) || []
)
