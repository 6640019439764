/*---- External -------------------------------------------------------------*/

import React from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { LinearIssuePriorityIcon } from '../../../connections/linear/LinearIssuePriorityIcon'
import { LinearIssueLabel } from '../../../connections/linear/LinearIssueLabel'

import { PreviewItemWithBoardPresence } from 'canvas-shared/lib/types/PreviewItem.types'

/*---------------------------------------------------------------------------*/

interface LinearPreviewCardBodyProps {
  issue: PreviewItemWithBoardPresence.LinearCard
}

export const LinearPreviewCardBody: React.FC<LinearPreviewCardBodyProps> = ({
  issue,
}) => {
  return (
    <>
      <ul className="flex flex-wrap items-center -mx-1 -my-0.5">
        <li className="m-0.5 min-w-0">
          <LinearIssueLabel
            text={issue.data.state.name}
            color={issue.data.state.color}
            small
          />
        </li>
        {issue.data.cycle && (
          <li className="m-0.5 min-w-0">
            <LinearIssueLabel
              text={
                issue.data.cycle?.name ?? `Cycle ${issue.data.cycle.number}`
              }
              small
            />
          </li>
        )}
        {issue.data.labels.nodes.map((label) => (
          <li key={label.id} className="m-0.5 min-w-0">
            <LinearIssueLabel text={label.name} color={label.color} small />
          </li>
        ))}
      </ul>
      <div className="flex items-center mt-4 space-x-1">
        <LinearIssuePriorityIcon
          priority={issue.data.priority}
          priorityLabel={issue.data.priorityLabel}
          small
        />
        <span className="text-text-secondary-light dark:text-text-secondary-dark flex-grow text-xs">
          {issue.data.identifier}
        </span>
        {issue.data.assignee?.name && issue.data.assignee?.avatarUrl && (
          <img
            className="w-4 h-4 rounded-full"
            src={issue.data.assignee.avatarUrl}
            alt={issue.data.assignee.name}
            title={`Assigned to ${issue.data.assignee.name}`}
          />
        )}
      </div>
    </>
  )
}
