/*---- External -------------------------------------------------------------*/

import React from 'react'

import { IRadioProps } from '@blueprintjs/core'
import { IconProps } from 'phosphor-react'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

interface RichRadioButtonProps extends IRadioProps {
  description: string
  icon: React.ForwardRefExoticComponent<IconProps>
}

export const RichRadioButton: React.FC<RichRadioButtonProps> = ({
  checked,
  description,
  disabled = false,
  icon: Icon,
  label,
  onClick,
}) => (
  <div
    className={classNames(
      'border-border-weak-light dark:border-border-weak-dark active:bg-button-secondary-active-light dark:active:bg-button-secondary-active-dark flex flex-1 flex-col p-5 border rounded cursor-pointer space-y-2',
      {
        'hover:border-border-normal-light dark:hover:border-border-normal-dark':
          !checked && !disabled,
        'bg-button-secondary-selected-light dark:bg-button-secondary-selected-dark border-border-accent-light dark:border-border-accent-dark': checked,
      },
      {
        'border-opacity-50 cursor-not-allowed': disabled,
      }
    )}
    onClick={disabled ? undefined : onClick}
    role={disabled ? undefined : 'radio'}
  >
    <div
      className={classNames(
        checked
          ? 'text-icon-accent-light dark:text-icon-accent-dark'
          : 'text-icon-primary-light dark:text-icon-primary-dark'
      )}
    >
      <Icon
        className={classNames('w-8 h-8', {
          'opacity-50': disabled,
        })}
      />
    </div>
    <span
      className={classNames('font-semibold', {
        'text-text-disabled-light dark:text-text-disabled-dark text-opacity-50': disabled,
      })}
    >
      {label}
    </span>
    <span
      className={classNames(
        'text-xs',
        disabled
          ? 'text-text-disabled-light dark:text-text-disabled-dark text-opacity-50'
          : 'text-text-secondary-light dark:text-text-secondary-dark '
      )}
    >
      {description}
    </span>
  </div>
)
