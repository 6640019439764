/*---- External -------------------------------------------------------------*/

import React from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { functions } from '../../../config/firebase'

import { TeamPanelProps } from '../TeamSettings'
import { TeamSharingToggle } from '../../team/TeamSharingToggle'

/*---------------------------------------------------------------------------*/

const updateTeam = functions.httpsCallable('https-authUpdateTeam')

export const TeamSharingPanel: React.FC<TeamPanelProps> = ({
  className,
  queueUpdate,
  team,
}) => {
  const handleUpdate = (everyone: boolean) => {
    if (everyone !== team.everyone) {
      const f = () => updateTeam({ teamId: team.id, update: { everyone } })

      if (queueUpdate) {
        queueUpdate(f)
      } else {
        f()
      }
    }
  }

  const handleTeam = () => handleUpdate(false)
  const handleEveryone = () => handleUpdate(true)

  return (
    <div className={className}>
      <div className="mb-2.5 font-semibold">Sharing settings</div>
      <div className="flex space-x-4">
        <TeamSharingToggle
          everyone={team.everyone}
          shareWithEveryone={handleEveryone}
          shareWithTeam={handleTeam}
        />
      </div>
    </div>
  )
}
