/*---- External -------------------------------------------------------------*/

import intersection from 'lodash.intersection'

/*---- Qualdesk -------------------------------------------------------------*/

import { GROUP_RECT_PADDING } from '../../../../config/canvas'

import type { BasicPointerEvent } from 'canvas-shared/lib/types/pointerEvents.types'
import type { RootState } from '../../../../types/redux'
import { selectGroups } from '../../../../redux/selectors/board/groups'
import {
  scrollDimensionsSelector,
  scrollOffsetsSelector,
} from '../../../../redux/selectors/board/scroll'
import { selectZoomLevel } from '../../../../redux/selectors/board/zoom'
import { selectEditsItemIds } from '../../../../redux/selectors/board/edits'

/*---------------------------------------------------------------------------*/
export const pointerEventInGroup = (
  state: RootState,
  pointer: BasicPointerEvent
) => {
  const groups = selectGroups(state)
  const scrollOffsets = scrollOffsetsSelector(state)
  const scrollDimensions = scrollDimensionsSelector(state)
  const zoomLevel = selectZoomLevel(state)
  const editsItemIds = selectEditsItemIds(state)

  const { clientX, clientY } = pointer
  const { scrollLeft, scrollTop } = scrollOffsets
  const { offsetLeft, offsetTop } = scrollDimensions

  const canvasX = (clientX - offsetLeft + scrollLeft) / zoomLevel
  const canvasY = (clientY - offsetTop + scrollTop) / zoomLevel

  return groups?.find((group) => {
    if (intersection(editsItemIds, group.memberIds).length > 0) {
      return false
    }

    return (
      canvasX >= group.groupRect.left - GROUP_RECT_PADDING &&
      canvasX <= group.groupRect.right + GROUP_RECT_PADDING &&
      canvasY >= group.groupRect.top - GROUP_RECT_PADDING &&
      canvasY <= group.groupRect.bottom + GROUP_RECT_PADDING
    )
  })
}
