/*---- External -------------------------------------------------------------*/

import React, { useCallback, useState } from 'react'

import classNames from 'classnames'

import { ImageSquare } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import { useToggle } from '../utilities/useToggle'
import {
  PictureHandlerProps,
  PictureUploaderDialog,
} from './PictureUploaderDialog'

/*---------------------------------------------------------------------------*/

export const MinimalPictureChooser: React.FC<PictureHandlerProps> = ({
  storagePath,
  handleUploadComplete,
}) => {
  const [dialogOpen, toggleDialog] = useToggle(false)
  const [hover, setHover] = useState(false)
  const [file, setFile] = useState<File | undefined>(undefined)
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined)

  const onDragLeave = useCallback(
    (event: React.DragEvent<HTMLButtonElement>) => {
      event.preventDefault()
      setHover(false)
    },
    []
  )
  const onDragOver = useCallback(
    (event: React.DragEvent<HTMLButtonElement>) => {
      event.preventDefault()
      setHover(true)
    },
    []
  )
  const onDrop = useCallback(
    (event: React.DragEvent<HTMLButtonElement>) => {
      event.preventDefault()
      event.stopPropagation()
      setFile(event.dataTransfer.files[0])
      setHover(false)
      toggleDialog()
    },
    [toggleDialog]
  )

  const onUploadComplete = useCallback(
    (pictureUrl, storagePath) => {
      setFileUrl(pictureUrl)
      return handleUploadComplete(pictureUrl, storagePath)
    },
    [handleUploadComplete]
  )

  return (
    <>
      <button
        onClick={toggleDialog}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        className={classNames(
          'text-button-primary-light dark:text-button-success-dark flex flex-shrink-0 items-center justify-center w-24 h-24 rounded-full',
          {
            'bg-blue-50 dark:bg-background-four-dark hover:bg-blue-100 dark:hover:bg-background-three-dark': !hover,
            'bg-blue-100 dark:bg-background-three-dark': hover,
          }
        )}
        type="button"
      >
        {fileUrl ? (
          <img
            className="w-full h-full rounded-full"
            src={fileUrl}
            alt="Upload"
          />
        ) : (
          <ImageSquare size={36} weight="duotone" />
        )}
      </button>
      <PictureUploaderDialog
        isOpen={dialogOpen}
        onClose={toggleDialog}
        storagePath={storagePath}
        handleUploadComplete={onUploadComplete}
        initialFile={file}
      />
    </>
  )
}
