/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { WorkspaceCard } from './WorkspaceCard'
import { WorkspaceActionProps } from '../../types/workspace'

/*---------------------------------------------------------------------------*/

export const WorkspaceActionCard: React.FC<WorkspaceActionProps> = ({
  iconElement,
  phosphorIcon: Icon,
  onClick,
  text,
}) => (
  <WorkspaceCard
    className="dark:bg-background-five-dark ring-border-normal-light dark:ring-border-normal-dark hover:ring-border-strong-light dark:hover:ring-border-strong-dark text-text-accent-light dark:text-text-secondary-dark flex flex-col items-center justify-center hover:bg-blue-100 bg-blue-50 space-y-4 ring-1"
    onClick={onClick}
    role="button"
  >
    {Icon && (
      <Icon
        className="text-icon-accent-light dark:text-icon-secondary-dark w-20 h-20 xl:w-24 xl:h-24"
        weight="duotone"
      />
    )}
    {iconElement}
    <span className="text-base">{text}</span>
  </WorkspaceCard>
)
