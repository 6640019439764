import { IMenuItemProps, Menu } from '@blueprintjs/core'
import orderBy from 'lodash.orderby'

import { Item } from 'canvas-shared/lib/types/Item.types'
import { LinearCycle } from 'canvas-shared/lib/types/connections/Linear.types'
import { UUID } from '../../../../types'

interface ChangeLinearCycleProps {
  cards: Item.LinearCard[]
  linearSetCycle: (ids: UUID[], cycle: LinearCycle) => Promise<void>
  linearUnsetCycle: (ids: UUID[]) => Promise<void>
}

export const changeLinearCycle = ({
  cards,
  linearSetCycle,
  linearUnsetCycle,
}: ChangeLinearCycleProps): IMenuItemProps | null => {
  const uniqCycles = (cycle: LinearCycle, idx: number, list: LinearCycle[]) =>
    list.findIndex((s) => s.id === cycle.id) === idx

  const cycles = cards
    .flatMap((c) => c.data?.linear?.team?.cycles?.nodes ?? [])
    .filter(uniqCycles)

  if (cycles.length === 0) {
    return null
  }

  const noCycle: LinearCycle = { id: 'nocycle', name: 'No Cycle', number: 0 }

  const cyclesWithBacklog = [...cycles, noCycle]

  const changeCycle = (cycle: LinearCycle) => {
    if (cycle.id === 'nocycle') {
      linearUnsetCycle(cards.map((c) => c.id))
    } else {
      linearSetCycle(
        cards.map((c) => c.id),
        cycle
      )
    }
  }

  return {
    text: 'Cycle',
    icon: 'automatic-updates',
    children: orderBy(
      cyclesWithBacklog,
      ['number'],
      ['desc']
    ).map((s: LinearCycle) => (
      <Menu.Item
        key={s.number}
        text={s.name ?? s.number}
        onClick={() => changeCycle(s)}
      />
    )),
  }
}
