/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { ORIGIN } from '../../store'
import { UserOperation, UUID } from 'canvas-shared/lib/types'

/*---------------------------------------------------------------------------*/

export const generateOperation = (uid: UUID): UserOperation => ({
  origin: ORIGIN,
  uid,
})
