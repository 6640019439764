/*---- External -------------------------------------------------------------*/

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import classNames from 'classnames'

import sanitizeHtml from 'sanitize-html'
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable'

import { detect } from 'detect-browser'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

interface MultilineTextInputProps {
  className?: string
  disabled?: boolean
  isEditing: boolean
  onCancel: () => void
  onChange: (value: string) => void
  onConfirm: (value: string) => void
  placeholder?: string
  selectAllOnFocus?: boolean
  value: string
}

const cleanValue = (value: string | undefined) => {
  if (!value) return ''

  return sanitizeHtml(value, {
    allowedAttributes: {},
    allowedTags: ['br'],
  }).replaceAll('<br />', '\n')
}

export const MultilineTextInput: React.FC<MultilineTextInputProps> = React.memo(
  ({
    className,
    disabled,
    isEditing,
    onCancel,
    onChange,
    onConfirm,
    placeholder,
    selectAllOnFocus,
    value,
  }) => {
    const textareaRef = useRef<HTMLElement>(null)
    const [localValue, setLocalValue] = useState(value)

    useEffect(() => {
      if (!isEditing) {
        setLocalValue(value.split('\n').join('<br />'))
      }
    }, [isEditing, value])

    const handleChange = (e: ContentEditableEvent) => {
      setLocalValue(e.target.value)
      onChange(cleanValue(e.target.value))
    }

    const handleConfirm = useCallback(() => {
      onConfirm(cleanValue(textareaRef.current?.innerHTML))
    }, [onConfirm])

    const handleFocus = useCallback(() => {
      if (!textareaRef.current) {
        return
      }

      const browser = detect()

      if (
        browser?.name === 'firefox' &&
        textareaRef.current.lastChild?.textContent?.endsWith('\n\n')
      ) {
        textareaRef.current.lastChild.textContent = textareaRef.current.lastChild.textContent.replace(
          /\n$/,
          ''
        )
      }

      const target = document.createTextNode('')
      textareaRef.current.appendChild(target)

      const sel = window.getSelection()
      if (!!sel) {
        if (selectAllOnFocus) {
          sel.selectAllChildren(textareaRef.current)
        } else if (target.nodeValue !== null) {
          const range = document.createRange()
          range.setStart(target, target.nodeValue.length)
          range.collapse(true)
          sel.removeAllRanges()
          sel.addRange(range)
        }
      }
    }, [selectAllOnFocus])

    const stopPropagation = useCallback(
      (e: React.SyntheticEvent) => {
        if (isEditing) e.stopPropagation()
      },
      [isEditing]
    )

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent) => {
        if (e.key === 'Escape') {
          stopPropagation(e)
          onCancel()
          textareaRef.current?.blur()
        } else if (e.key === 'Enter') {
          e.preventDefault()
          document.execCommand('insertlinebreak')
        }
      },
      [onCancel, stopPropagation]
    )

    const handlePaste = useCallback(
      (e: React.ClipboardEvent) => {
        stopPropagation(e)
        e.preventDefault()
        const text = e.clipboardData.getData('text/plain')
        document.execCommand('insertText', false, text)
      },
      [stopPropagation]
    )

    useEffect(() => {
      if (isEditing) {
        textareaRef.current?.focus()
      } else {
        textareaRef.current?.blur()
      }
    }, [isEditing])

    const displayPlaceholder = !isEditing && !localValue && !!placeholder

    const innerClassName = useMemo(
      () =>
        classNames(
          className,
          'h-full break-words whitespace-pre-wrap bg-transparent resize-none',
          { 'cursor-text select-auto': isEditing },
          { 'opacity-50': displayPlaceholder }
        ),
      [className, displayPlaceholder, isEditing]
    )

    if (displayPlaceholder) {
      return <div className={innerClassName}>{placeholder}</div>
    }

    return (
      <ContentEditable
        className={innerClassName}
        disabled={disabled}
        onBlur={handleConfirm}
        onChange={handleChange}
        onCopy={stopPropagation}
        onCut={stopPropagation}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        onPointerDown={stopPropagation}
        onPaste={handlePaste}
        innerRef={textareaRef}
        html={localValue}
        tagName="span"
      />
    )
  }
)
