/*---- External -------------------------------------------------------------*/

import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  clearClipboardAction,
  pasteAction,
} from '../../../actionCreators/clipboard'

import { ClipboardStateSlice } from '../../../../types/redux'

/*---------------------------------------------------------------------------*/

const initialState: ClipboardStateSlice = null

const extraReducers = (
  builder: ActionReducerMapBuilder<ClipboardStateSlice>
) => {
  builder
    .addCase(pasteAction.fulfilled, (state, action) => {
      return {
        text: action.payload.text,
      }
    })
    .addCase(clearClipboardAction, (state) => {
      return initialState
    })
}

export const clipboardSlice = createSlice({
  name: 'clipboard',
  reducers: {},
  initialState,
  extraReducers,
})
