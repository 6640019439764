"use strict";
/*---- External -------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionLevel = void 0;
var PermissionLevel;
(function (PermissionLevel) {
    PermissionLevel[PermissionLevel["CREATOR"] = 40] = "CREATOR";
    PermissionLevel[PermissionLevel["ADMIN"] = 30] = "ADMIN";
    PermissionLevel[PermissionLevel["WRITE"] = 20] = "WRITE";
    PermissionLevel[PermissionLevel["READ"] = 10] = "READ";
})(PermissionLevel = exports.PermissionLevel || (exports.PermissionLevel = {}));
