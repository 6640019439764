/*---- External -------------------------------------------------------------*/

import { useEffect, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { generatePath } from 'react-router-dom'

/*---- Qualdesk -------------------------------------------------------------*/

import { useContainerId } from '../../contexts/Container'

import { useBoardActions } from '../actions/board/useBoardActions'
import { useShouldSelectActions } from '../actions/board/useShouldSelectActions'

import { useBoard } from '../selectorHooks/board/useBoard'

import { Routes } from '../../router/routes'

import { generateUrlFromPath } from '../../helpers/generateUrlFromPath'
import { pendingCancelAllAction } from '../actionCreators/pending'
import { stopAutoScrollAction } from '../actionCreators/autoScroll'

/*---------------------------------------------------------------------------*/

export const useBoardEffects = () => {
  const dispatch = useDispatch()
  const containerId = useContainerId()

  const {
    settings: { url },
  } = useBoard()

  const { updateBoardUrl } = useBoardActions()
  const { clearShouldSelect } = useShouldSelectActions()

  const boardUrl = useMemo(
    () =>
      generateUrlFromPath(
        generatePath(Routes.CANVAS.path, {
          containerId,
        })
      ),
    [containerId]
  )

  // HACK: The backend doesn’t know what the full URL is so we set it here
  useEffect(() => {
    if (url !== boardUrl) {
      updateBoardUrl(boardUrl)
    }
  }, [boardUrl, containerId, updateBoardUrl, url])

  const handleBlur = useCallback(() => {
    dispatch(pendingCancelAllAction())
    dispatch(stopAutoScrollAction())
  }, [dispatch])

  const manageFocusListeners = () => {
    window.addEventListener('blur', handleBlur)

    return () => {
      window.removeEventListener('blur', handleBlur)
    }
  }

  useEffect(clearShouldSelect, [clearShouldSelect, containerId])
  useEffect(manageFocusListeners, [handleBlur])
}
