/*---- External -------------------------------------------------------------*/

import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { IMenuItemProps } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { selectGroups } from '../../../redux/selectors/board/groups'

import { Item } from 'canvas-shared/lib/types/Item.types'
import {
  groupItemsAction,
  removeItemsFromGroupAction,
} from '../../../redux/actionCreators/dynamicItems'

/*---------------------------------------------------------------------------*/

export const useGroupMenuItems = (
  actionItems: Item.BaseItem[]
): IMenuItemProps[] => {
  const groups = useSelector(selectGroups)
  const dispatch = useDispatch()

  const actionItemGroups = useMemo(() => {
    if (!!groups) {
      return actionItems.reduce((res: Item.CanvasGroup[], i) => {
        const group = groups.find((g) => g.memberIds.includes(i.id))

        if (!!group) {
          res = res.concat(group)
        }

        return res
      }, [])
    }

    return []
  }, [actionItems, groups])

  const allInSameGroup = useMemo(
    () =>
      actionItemGroups.length > 0 &&
      actionItemGroups.every((g) => g?.id && g.id === actionItemGroups[0]?.id),
    [actionItemGroups]
  )

  const wholeGroupSelected = useMemo(() => {
    return (
      allInSameGroup &&
      actionItems.length === actionItemGroups[0].memberIds.length
    )
  }, [actionItemGroups, actionItems.length, allInSameGroup])

  const handleGroup = useCallback(() => {
    dispatch(groupItemsAction(actionItems))
  }, [actionItems, dispatch])

  const handleRemoveFromGroup = useCallback(() => {
    if (actionItemGroups[0]) {
      dispatch(
        removeItemsFromGroupAction({
          itemIds: actionItems.map((i) => i.id),
          group: actionItemGroups[0],
        })
      )
    }
  }, [actionItemGroups, actionItems, dispatch])

  const cannotGroup = useMemo(
    () =>
      actionItems.length < 2 ||
      actionItems.every((i) => groups?.find((g) => g.memberIds.includes(i.id))),
    [actionItems, groups]
  )

  const groupUngroupItem: IMenuItemProps = useMemo(() => {
    if (wholeGroupSelected) {
      return {
        onClick: handleRemoveFromGroup,
        text: 'Ungroup',
      }
    } else if (allInSameGroup) {
      return {
        onClick: handleRemoveFromGroup,
        text: 'Remove from group',
      }
    } else {
      return {
        disabled: cannotGroup,
        onClick: handleGroup,
        text: 'Group',
      }
    }
  }, [
    allInSameGroup,
    cannotGroup,
    handleGroup,
    handleRemoveFromGroup,
    wholeGroupSelected,
  ])

  const groupMenuItems = useMemo(() => [groupUngroupItem], [groupUngroupItem])

  return groupMenuItems
}
