/*---- External -------------------------------------------------------------*/

import { useMemo } from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import googleGIcon from '../../assets/integrations/google/google-g-icon.svg'
import jiraIcon from '../../assets/integrations/jira/jira-icon.svg'
import linearIcon from '../../assets/integrations/linear/linear-icon.svg'
import trelloIcon from '../../assets/integrations/trello/trello-icon.svg'

import convertToPx from '../../helpers/convertToPx'

import {
  BoardConnection,
  BoardConnectionType,
} from 'canvas-shared/lib/types/BoardConnection.types'
import {
  UserConnection,
  UserConnectionType,
} from 'canvas-shared/lib/types/UserConnection.types'

/*---------------------------------------------------------------------------*/

interface IntegrationIconProps {
  className?: string
  connection: UserConnection | BoardConnection
  size?: number
}

export const IntegrationIcon: React.FC<IntegrationIconProps> = ({
  className,
  connection,
  size = 16,
}) => {
  const imageUrl = useMemo(() => {
    switch (connection.type) {
      case UserConnectionType.Google:
      case BoardConnectionType.Google:
        return googleGIcon
      case UserConnectionType.Jira:
      case BoardConnectionType.Jira:
        return jiraIcon
      case UserConnectionType.Linear:
      case BoardConnectionType.Linear:
        return linearIcon
      case UserConnectionType.Trello:
      case BoardConnectionType.Trello:
        return trelloIcon
    }
  }, [connection.type])

  return (
    <img
      alt={`${connection.type} icon`}
      className={className}
      height={convertToPx(size)}
      src={imageUrl}
      width={convertToPx(size)}
    />
  )
}
