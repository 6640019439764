/*---- External -------------------------------------------------------------*/

import { useMemo } from 'react'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'

import { firstBy } from 'thenby'

/*---- Qualdesk -------------------------------------------------------------*/

import { useGoogleSheetsRecentSort } from '../../../../hooks/localStorage/googleSheetsRecentSort'

import { selectMyUid } from '../../../../redux/selectors/auth/auth'

import { compareUpdatedAt } from 'canvas-shared/lib/sort/compareUpdatedAt'

import { GoogleSheetsBoardConnection } from 'canvas-shared/lib/types/BoardConnection.types'
import { MimeTypes } from 'canvas-shared/lib/types/file.types'
import { UserConnectionType } from 'canvas-shared/lib/types/UserConnection.types'
import { RootState } from '../../../../types/redux'

/*---------------------------------------------------------------------------*/

const compareGoogleFileName = (
  a: GoogleSheetsBoardConnection,
  b: GoogleSheetsBoardConnection
) => a.google.file.name.localeCompare(b.google.file.name)

const compareGoogleSheetName = (
  a: GoogleSheetsBoardConnection,
  b: GoogleSheetsBoardConnection
) =>
  (
    a.google.options.sheetOptions[a.google.options.selectedSheetId]
      ?.sheetName || ''
  ).localeCompare(
    b.google.options.sheetOptions[b.google.options.selectedSheetId]
      ?.sheetName || ''
  )

export const useGoogleSheetsRecentConnections = () => {
  const uid = useSelector(selectMyUid)

  const recentConnections = useSelector(
    createSelector(
      (state: RootState) => state.firestore.data.recentConnectionsGoogleSheets,
      (data) =>
        data !== undefined
          ? Object.values(data || {}).filter((d) => !!d)
          : undefined
    )
  )

  useFirestoreConnect({
    collection: 'users',
    doc: uid,
    subcollections: [
      {
        collection: 'userConnections',
        doc: UserConnectionType.Google,
        subcollections: [
          {
            collection: 'recentUserConnections',
            where: ['google.file.mimeType', '==', MimeTypes.GOOGLE_SHEETS],
          },
        ],
      },
    ],
    storeAs: 'recentConnectionsGoogleSheets',
  })

  const [sort] = useGoogleSheetsRecentSort()

  const sortedConnections = useMemo(() => {
    if (!recentConnections) {
      return undefined
    }

    return [...recentConnections].sort(
      firstBy(compareUpdatedAt, sort)
        .thenBy(compareGoogleFileName)
        .thenBy(compareGoogleSheetName)
    )
  }, [recentConnections, sort])

  return sortedConnections
}
