"use strict";
/*---- External -------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.isRectPosition = void 0;
/*---------------------------------------------------------------------------*/
const isRectPosition = (pos) => {
    const maybeRectPosition = pos;
    return (maybeRectPosition &&
        maybeRectPosition.left !== undefined &&
        maybeRectPosition.top !== undefined &&
        maybeRectPosition.height !== undefined &&
        maybeRectPosition.width !== undefined);
};
exports.isRectPosition = isRectPosition;
