/*---- External -------------------------------------------------------------*/

import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { app } from '../../../config/firebase'

import { selectSignedIn } from '../../selectors/auth/auth'

import { setOrgAction, setOrgLoadedAction } from '../../actionCreators/org'

import { ORG_ID } from '../../../config/org'
import { Org } from 'canvas-shared/lib/types/Org.types'

/*---------------------------------------------------------------------------*/

const orgDocRef = app.firestore().collection('orgs').doc(ORG_ID)

export const useOrgConnector = () => {
  const authenticated = useSelector(selectSignedIn)
  const dispatch = useDispatch()

  const handleDoc = useCallback(
    (doc: app.firestore.DocumentSnapshot) => {
      if (doc.exists) {
        dispatch(
          setOrgAction({
            ...doc.data(),
            id: doc.id,
          } as Org)
        )
      } else {
        dispatch(setOrgLoadedAction())
      }
    },
    [dispatch]
  )

  const handleListener = () => {
    if (authenticated) {
      orgDocRef.get({ source: 'server' }).then(handleDoc)

      const unsubscribe = orgDocRef.onSnapshot(handleDoc)

      return () => {
        unsubscribe()
      }
    }

    return undefined
  }

  useEffect(handleListener, [authenticated, dispatch, handleDoc])
}
