/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'
import { isEmpty, isLoaded } from 'react-redux-firebase'

/*---- Qualdesk -------------------------------------------------------------*/

import { safeString } from '../../../helpers/safeString'
import { RootState } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

export const selectAuth = (state: RootState) => state.firebase.auth
export const selectMyProfile = (state: RootState) => state.firebase.profile

export const selectAuthLoaded = createSelector(selectAuth, (auth) =>
  isLoaded(auth)
)

export const selectProfileLoaded = createSelector(selectMyProfile, (profile) =>
  isLoaded(profile)
)

export const selectSignedIn = createSelector(
  selectAuthLoaded,
  selectAuth,
  (loaded, auth) => loaded && !isEmpty(auth)
)

export const selectMyEmail = createSelector(
  (state: RootState) => state.firebase.auth.email,
  (email) => safeString(email)
)
export const selectMyUid = (state: RootState) => state.firebase.auth.uid
export const selectMyName = (state: RootState) => state.firebase.profile.name
