/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { positionsOrderedSelector } from './positions'
import { scrollDimensionsSelector, scrollOffsetsSelector } from './scroll'
import { selectZoomLevel } from './zoom'

import { createDeepEqualSelector } from '../helpers/createDeepEqualSelector'

import { addRectDimensionsToPositionIfRequired } from 'canvas-shared/lib/helpers/addRectDimensionsToPositionIfRequired'

import { UUID } from 'canvas-shared/lib/types'

/*---------------------------------------------------------------------------*/

export const selectVisibleItemIds = createDeepEqualSelector(
  createSelector(
    positionsOrderedSelector,
    scrollDimensionsSelector,
    scrollOffsetsSelector,
    selectZoomLevel,
    (positions, scrollDimensions, scrollOffsets, zoomLevel) => {
      const { scrollLeft, scrollTop } = scrollOffsets
      const {
        clientWidth,
        clientHeight,
        canvasLeft,
        canvasTop,
        offsetLeft,
        offsetTop,
      } = scrollDimensions

      const viewportLeft = scrollLeft - offsetLeft + canvasLeft
      const viewportTop = scrollTop - offsetTop + canvasTop
      const viewportRight = viewportLeft + clientWidth
      const viewportBottom = viewportTop + clientHeight
      const zoomedViewport = {
        left: viewportLeft / zoomLevel,
        right: viewportRight / zoomLevel,
        top: viewportTop / zoomLevel,
        bottom: viewportBottom / zoomLevel,
      }

      return positions.reduce((res: UUID[], pos) => {
        const rectPos = addRectDimensionsToPositionIfRequired(pos)
        if (
          !(
            rectPos.left + rectPos.width < zoomedViewport.left ||
            rectPos.left > zoomedViewport.right ||
            rectPos.top + rectPos.height < zoomedViewport.top ||
            rectPos.top > zoomedViewport.bottom
          )
        ) {
          res = res.concat(pos.id)
        }
        return res
      }, [])
    }
  ),
  (ids) => ids
)
