/*---- External -------------------------------------------------------------*/

import React, { useMemo, useState } from 'react'
import { nanoid } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

import { useAsync, useAsyncEffect } from '@react-hook/async'

import { Button, Classes } from '@blueprintjs/core'

import { sheets_v4 } from 'googleapis'

import times from 'lodash.times'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { useContainerId } from '../../../contexts/Container'

import { functions, storage } from '../../../config/firebase'

import { SidebarPanel } from '../SidebarPanel'
import { GoogleSheetsSettingsPopover } from './GoogleSheetsSettingsPopover'
import { NoResults } from '../../utilities/NoResults'

import { useStatusReporter } from '../../../hooks/useStatusReporter'

import { selectMyUid } from '../../../redux/selectors/auth/auth'
import { createGoogleSheetsBoardConnectionSelector } from '../../../redux/selectors/board/connections'

import { getGrid } from 'canvas-shared/lib/helpers/google/getGrid'

import googleSheetsIcon from '../../../assets/integrations/google/google-sheets-icon.svg'

import { PreviewCard } from '../../connections/google/GooglePreview'

import { tempFilePath } from 'canvas-shared/lib/paths/tempFilePath'

import { ControlledPanelProps } from '../SidebarPanelStack'
import { Footer } from '../Footer'

import { RootState } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

const fetchSpreadsheet = functions.httpsCallable(
  'https-googleSheetsFetchSpreadsheet'
)
const syncSpreadsheet = functions.httpsCallable(
  'https-googleSheetsSyncSpreadsheet'
)

interface GoogleChooseDataProps extends ControlledPanelProps {
  fileId: string
  connectionId: string
  name: string
}

export const GoogleChooseData: React.FC<GoogleChooseDataProps> = ({
  fileId,
  connectionId,
  name,
  ...panelProps
}) => {
  const uid = useSelector(selectMyUid)
  const boardId = useContainerId()

  const { reportError, reportProgress, reportSuccess } = useStatusReporter()
  const [sheets, setSheets] = useState<sheets_v4.Schema$Sheet[]>([])

  const connection = useSelector((state: RootState) =>
    createGoogleSheetsBoardConnectionSelector(state, connectionId)
  )

  const { status: fetchStatus } = useAsyncEffect(async () => {
    try {
      const hash = nanoid()

      await fetchSpreadsheet({
        spreadsheetId: fileId,
        hash,
      })

      const resultFile = storage.ref().child(tempFilePath(uid, fileId, hash))

      const dataUrl = await resultFile.getDownloadURL()

      const rawData = await fetch(dataUrl)
      const data = (await rawData.json()) as sheets_v4.Schema$Spreadsheet

      if (!data.sheets)
        throw new Error(`Missing data in file with ID: ${fileId}`)

      setSheets(data.sheets)

      await resultFile.delete()
    } catch (error) {
      reportError(error, error.message)
    }
  }, [fileId])

  const [{ status: importStatus }, importRows] = useAsync(async () => {
    try {
      reportProgress('Importing Google Sheets data…')

      await syncSpreadsheet({
        boardId,
        connectionId,
        addRowsNotPresentOnBoard: true,
      })

      reportSuccess('Google Sheets data imported successfully', {
        timeout: 2000,
      })
    } catch (error) {
      reportError(error, 'Failed to get Google Sheets data')
    }
  })

  const sheetOptions =
    connection?.google.options.sheetOptions[
      connection.google.options.selectedSheetId
    ]

  const grid = useMemo(
    () =>
      !!connection && !!sheetOptions
        ? getGrid(
            sheets,
            connection.google.options.selectedSheetId,
            sheetOptions.headerRow
          )
        : undefined,
    [connection, sheetOptions, sheets]
  )

  const rowsWithHeader = useMemo(
    () => (sheetOptions?.headerRow ? grid?.rows.slice(1) : grid?.rows),
    [grid?.rows, sheetOptions?.headerRow]
  )

  return (
    <SidebarPanel
      {...panelProps}
      text="Google Sheets"
      iconUrl={googleSheetsIcon}
      iconAltText="Google Sheets icon"
      footer={
        !!grid && (
          <Footer>
            <Button
              disabled={importStatus === 'loading'}
              fill
              minimal
              loading={importStatus === 'loading'}
              onClick={importRows}
              text={`Import ${rowsWithHeader?.length} rows`}
            />
          </Footer>
        )
      }
    >
      <div className="flex items-center pt-6 px-3 space-x-2">
        <div className="flex flex-col space-y-1">
          <div className="text-lg font-semibold">{name}</div>
          <div className="text-text-secondary-light dark:text-text-secondary-dark text-xs">
            {sheetOptions?.sheetName}
          </div>
        </div>
      </div>
      <div className="pt-6 px-3">
        {!!grid && !!connection ? (
          <GoogleSheetsSettingsPopover
            connection={connection}
            grid={grid}
            sheets={sheets}
          />
        ) : (
          <Button fill loading outlined />
        )}
      </div>
      <div className="text-text-secondary-light dark:text-text-secondary-dark pb-3 pt-8 px-6 text-sm">
        {fetchStatus === 'loading' || !grid ? (
          <>Loading data…</>
        ) : (
          `${rowsWithHeader?.length} ${
            rowsWithHeader?.length === 1 ? 'row' : 'rows'
          }`
        )}
      </div>
      {fetchStatus !== 'loading' && grid?.rows.length === 0 && (
        <NoResults className="my-10" title="No issues found" />
      )}
      <ul className="p-3 pb-6 pt-0 space-y-3">
        {fetchStatus === 'loading' &&
          times(3).map((i) => (
            <li key={i} className={classNames('h-20', Classes.SKELETON)} />
          ))}
        {fetchStatus !== 'loading' &&
          !!grid &&
          !!connection &&
          !!sheetOptions &&
          rowsWithHeader?.map((r, i) => (
            <PreviewCard
              columns={sheetOptions.columns}
              columnHeaders={grid.columnHeaders}
              fileTitle={name}
              key={i}
              numCols={grid.columnCount}
              row={r}
            />
          ))}
      </ul>
    </SidebarPanel>
  )
}
