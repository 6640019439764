/*---- External -------------------------------------------------------------*/

import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { selectionSetAction } from './selection'

import { selectedItemsPositionsSelector } from '../selectors/board/positions'
import { mySelectedItemsSelector } from '../selectors/board/selections'
import { itemSelector } from '../selectors/board/items'

import { getCoordsFromPointerEvent } from '../../hooks/board/pointerEvents/helpers/getCoordsFromPointerEvent'
import { pointerEventIntersections } from '../../hooks/board/pointerEvents/intersectionTests/pointerEventIntersections'

import { ContextMenuStateSlice, ThunkAPI } from '../../types/redux'

/*---------------------------------------------------------------------------*/

export const openContextMenuAction = createAsyncThunk<
  ContextMenuStateSlice,
  React.MouseEvent,
  ThunkAPI
>('board/openContextMenu', (e, { dispatch, getState }) => {
  e.preventDefault()

  const state = getState()
  let actionItems = mySelectedItemsSelector(state)
  let actionItemsPositions = selectedItemsPositionsSelector(state)

  const { position, group, selectionRect } = pointerEventIntersections(
    state,
    e as React.PointerEvent
  )

  if (!selectionRect) {
    if (!!position) {
      dispatch(selectionSetAction([position.id]))

      actionItems = [itemSelector(state, position.id)]
      actionItemsPositions = [position]
    } else if (group) {
      dispatch(selectionSetAction(group.memberIds))

      actionItems = group.members
      actionItemsPositions = group.memberPositions
    } else {
      dispatch(selectionSetAction([]))

      actionItems = []
      actionItemsPositions = []
    }
  }

  const newPayload: ContextMenuStateSlice = {
    coords: getCoordsFromPointerEvent(e as React.PointerEvent),
    actionItems,
    actionItemsPositions,
    actionGroup: group,
  }

  return newPayload
})
export const closeContextMenuAction = createAction('board/closeContextMenu')
