/*---- External -------------------------------------------------------------*/

import React, { ReactChild } from 'react'

import {
  Boundary,
  Button,
  ButtonGroup,
  Divider,
  Menu,
  OverflowList,
  IButtonProps,
} from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { DynamicToolbarItem } from '../../types'

/*---------------------------------------------------------------------------*/

interface Props {
  buttonProps?: IButtonProps
  className?: string
  style?: React.CSSProperties
  dividersBetweenAllItems?: boolean
  items: DynamicToolbarItem[]
}

export const DynamicToolbar: React.FC<Props> = ({
  className,
  style,
  items,
  buttonProps,
  dividersBetweenAllItems,
}) => (
  <OverflowList
    className={className}
    collapseFrom={Boundary.END}
    items={items}
    overflowRenderer={(items) => overflowRenderer(items, buttonProps)}
    style={style}
    visibleItemRenderer={(item, index) =>
      visibleItemRenderer(
        item,
        index,
        buttonProps,
        !!dividersBetweenAllItems && index < items.length - 1
      )
    }
  />
)

const mapMenuItems = (items: DynamicToolbarItem[]) =>
  items.map(
    ({ children, childElement, separator, textInMenu, ...item }, index) => {
      if (separator) {
        return <Menu.Divider key={index} title={item.text} />
      } else {
        if (children || childElement) {
          return (
            <React.Fragment key={index}>
              {item.spacerBefore && <Menu.Divider />}
              <Menu.Item
                children={
                  childElement
                    ? childElement
                    : mapMenuItems(children as DynamicToolbarItem[])
                }
                key={index}
                text={item.text ? item.text : textInMenu}
              />
            </React.Fragment>
          )
        } else {
          return renderMenuItem(item, index)
        }
      }
    }
  )

const overflowRenderer = (
  items: DynamicToolbarItem[],
  buttonProps: IButtonProps | undefined
) => (
  <Popover2 autoFocus={false} content={<Menu>{mapMenuItems(items)}</Menu>}>
    <Button icon="more" {...buttonProps} />
  </Popover2>
)

const renderButton = (
  { spacerBefore, ...item }: DynamicToolbarItem,
  index: number,
  buttonProps: IButtonProps | undefined
) => (
  <Button
    {...buttonProps}
    {...item}
    className={classNames({ spacer: spacerBefore }, 'flex-shrink-0')}
    key={index}
  />
)

const renderMenuItem = (
  { spacerBefore, textInMenu, rightIcon, ...item }: DynamicToolbarItem,
  index: number
) => (
  <React.Fragment key={index}>
    {spacerBefore && <Menu.Divider />}
    <Menu.Item
      {...item}
      labelElement={rightIcon}
      text={item.text ? item.text : textInMenu}
    />
  </React.Fragment>
)

const visibleItemRenderer = (
  dynamicItem: DynamicToolbarItem,
  index: number,
  buttonProps: IButtonProps | undefined,
  showDividerAfterThisItem: boolean
): ReactChild => {
  const {
    children,
    childElement,
    displayChildrenAsSubmenu,
    separator,
    spacerBefore,
    renderChildElementInToolbar,
    textInMenu,
    ...item
  } = dynamicItem

  if (renderChildElementInToolbar && childElement) {
    return (
      <React.Fragment key={index}>
        {childElement}
        {showDividerAfterThisItem && <Divider />}
      </React.Fragment>
    )
  }
  if (separator) {
    return <Divider key={index} />
  } else {
    if (childElement) {
      return (
        <React.Fragment key={index}>
          <Popover2
            className={spacerBefore ? 'spacer' : undefined}
            key={index}
            autoFocus={false}
            content={childElement}
          >
            <Button {...item} {...buttonProps} rightIcon="caret-down" />
          </Popover2>
          {showDividerAfterThisItem && <Divider />}
        </React.Fragment>
      )
    } else if (children) {
      if (displayChildrenAsSubmenu) {
        return (
          <React.Fragment key={index}>
            <Popover2
              className={spacerBefore ? 'spacer' : undefined}
              autoFocus={false}
              content={<Menu>{mapMenuItems(children)}</Menu>}
            >
              <Button {...item} {...buttonProps} rightIcon="caret-down" />
            </Popover2>
            {showDividerAfterThisItem && <Divider />}
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment key={index}>
            <ButtonGroup
              className={spacerBefore ? 'spacer' : undefined}
              {...item}
              {...buttonProps}
            >
              {children.map((child, index) =>
                renderButton(child, index, buttonProps)
              )}
            </ButtonGroup>
            {showDividerAfterThisItem && <Divider />}
          </React.Fragment>
        )
      }
    } else {
      return renderButton({ spacerBefore, ...item }, index, buttonProps)
    }
  }
}

export default DynamicToolbar
