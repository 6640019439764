/*---- External -------------------------------------------------------------*/

import { useEffect, useRef } from 'react'
import { batch, useDispatch } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { app } from '../../../config/firebase'

import { UUID } from 'canvas-shared/lib/types'
import { ActionHandler } from '../../../types/redux'
import { KeyValuePair } from 'canvas-shared/lib/types/utilities.types'

/*---------------------------------------------------------------------------*/

export interface FirestoreCollectionConnectQuery<T, R extends T = T> {
  collectionPath: string
  onChildSet: ActionHandler<KeyValuePair<UUID, T>, KeyValuePair<UUID, R>>
  onChildRemoved: ActionHandler<UUID>
  onInitialValueLoaded: ActionHandler<void>
}

export function useFirestoreCollectionConnect<
  T extends { id: string },
  R extends T = T
>({
  collectionPath,
  onChildSet,
  onChildRemoved,
  onInitialValueLoaded,
}: FirestoreCollectionConnectQuery<T, R>) {
  const dispatch = useDispatch()

  const onChildSetRef = useRef<Function>()
  onChildSetRef.current = onChildSet

  const onInitialValueLoadedRef = useRef<Function>()
  onInitialValueLoadedRef.current = onInitialValueLoaded

  const onChildRemovedRef = useRef<Function>()
  onChildRemovedRef.current = onChildRemoved

  const handleListener = () => {
    const listeners: (() => void)[] = []
    const ref = app.firestore().collection(collectionPath)

    if (!!onChildSetRef.current || !!onChildRemovedRef.current) {
      const onChildUnsubscribe = ref.onSnapshot((querySnapshot) => {
        batch(() => {
          querySnapshot.docChanges().forEach((change, i) => {
            const doc = change.doc
            if (change.type === 'removed') {
              if (!!onChildRemovedRef.current) {
                dispatch(onChildRemovedRef.current(change.doc.id))
              }
            } else {
              // Checking for hasPendingWrites can only be turned back on
              // when all operations on items go through redux, and therefore local
              // ones don't have to be applied from remote.
              //if (!doc.metadata.hasPendingWrites && !doc.metadata.fromCache) {
              if (!doc.metadata.fromCache) {
                const key = doc.id as UUID
                const value = { id: doc.id, ...doc.data() } as T

                if (!!onChildSetRef.current) {
                  dispatch(
                    onChildSetRef.current({
                      key,
                      value,
                    })
                  )
                }
              }
            }
          })

          if (!!onInitialValueLoadedRef.current) {
            dispatch(onInitialValueLoadedRef.current())
            onInitialValueLoadedRef.current = undefined
          }
        })
      })

      listeners.push(onChildUnsubscribe)
    }

    return () => {
      listeners.forEach((unsubscribe) => unsubscribe())
    }
  }

  useEffect(handleListener, [collectionPath, dispatch])
}
