/*---- External -------------------------------------------------------------*/

import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'

import { Dialog } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { useClassNamesWithDarkMode } from '../../hooks/darkMode/useClassNamesWithDarkMode'

/*---------------------------------------------------------------------------*/

interface DialogChildrenProps {
  setTitle: (title: string) => void
  closeDialog: () => void
}
type DialogChildrenFactory = (props: DialogChildrenProps) => React.ReactNode

interface RoutableDialogProps {
  origin: string
  children: DialogChildrenFactory | React.ReactNode
  title?: string
}

/**
 * Dialog to be rendered as a Route match in a Router
 *
 * @component
 * @param {object} props
 * @param {string} props.origin Original URL from which Dialog is rendered - used for navigation when Dialog is closed
 */
const RoutableDialog: React.FC<RoutableDialogProps> = ({
  children,
  origin,
  title: defaultTitle,
}) => {
  const [title, setTitle] = useState(defaultTitle)

  const { classNamesWithDarkMode } = useClassNamesWithDarkMode()

  const { push } = useHistory()
  const handleClose = () => push(origin)

  // Stop propagating cursor movements to canvas
  const stopPropagation = (e: React.MouseEvent) => e.stopPropagation()

  return (
    <div onMouseMove={stopPropagation}>
      <Dialog
        isOpen={true}
        title={title}
        onClose={handleClose}
        className={classNamesWithDarkMode(
          'p-0 flex flex-col w-1/2 overflow-hidden'
        )}
        portalClassName="z-above-everything"
      >
        {isCallableChildren(children)
          ? children({
              setTitle,
              closeDialog: handleClose,
            })
          : children}
      </Dialog>
    </div>
  )
}

const isCallableChildren = (children: any): children is DialogChildrenFactory =>
  typeof children === 'function'

export default RoutableDialog
