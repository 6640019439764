/*---- External -------------------------------------------------------------*/

import { useFirebase } from 'react-redux-firebase'

/*---- Qualdesk -------------------------------------------------------------*/

import { firebaseEmailCallback } from '../config/auth'

import { useSignInEmail } from './localStorage/useSignInEmail'

/*---------------------------------------------------------------------------*/

export const useSignIn = () => {
  const firebase = useFirebase()
  const [, setSignInEmail] = useSignInEmail()

  const signIn = async (email: string) => {
    await firebase.auth().sendSignInLinkToEmail(email, {
      url: firebaseEmailCallback || '',
      handleCodeInApp: true,
    })

    setSignInEmail(email)
  }

  return { signIn }
}
