"use strict";
/*---- External -------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.randomColor = void 0;
/*---- Qualdesk -------------------------------------------------------------*/
const colors_1 = require("../config/colors");
/*---------------------------------------------------------------------------*/
const randomColor = (hex = false) => {
    const c = colors_1.colors[Math.floor(Math.random() * colors_1.colors.length)];
    return hex ? c.hex : c.color;
};
exports.randomColor = randomColor;
