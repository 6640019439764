"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BoardConnectionType = void 0;
var BoardConnectionType;
(function (BoardConnectionType) {
    BoardConnectionType["Google"] = "Google";
    BoardConnectionType["Jira"] = "Jira";
    BoardConnectionType["Trello"] = "Trello";
    BoardConnectionType["Linear"] = "Linear";
})(BoardConnectionType = exports.BoardConnectionType || (exports.BoardConnectionType = {}));
