/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { XYPosition } from 'canvas-shared/lib/types/scrollAndPosition.types'

/*---------------------------------------------------------------------------*/

export const getCoordsFromPointerEvent = (
  e: React.PointerEvent
): XYPosition => {
  const { clientX, clientY, pageX, pageY } = e
  return { clientX, clientY, pageX, pageY }
}
