/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { RootState } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

export const scrollDimensionsSelector = (state: RootState) =>
  state.board.session.data.scrollDimensions
export const scrollOffsetsSelector = (state: RootState) =>
  state.board.session.data.scrollOffsets

export const selectAutoScrollStatus = (state: RootState) =>
  state.board.session.localData.autoScroll.status
