/*---- External -------------------------------------------------------------*/

import { useState, useEffect, useCallback, useMemo } from 'react'

import debounce from 'lodash.debounce'

/*---- Qualdesk -------------------------------------------------------------*/

import { functions } from '../../config/firebase'
import { useContainerId } from '../../contexts/Container'
import { useStatusReporter } from '../useStatusReporter'

/*---------------------------------------------------------------------------*/

const searchIssues = functions.httpsCallable('https-jiraSearchIssues')

const useJiraIssueSearch = (query: string) => {
  const [issues, setIssues] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const containerId = useContainerId()
  const { reportError } = useStatusReporter()

  const fetchIssues = useCallback(
    async (query: string) => {
      try {
        setLoading(true)
        const { data } = await searchIssues({
          containerId,
          query: query,
          type: 'Epic',
        })
        setIssues(data)
      } catch (error) {
        reportError(error, 'Failed to get epics')
      } finally {
        setLoading(false)
      }
    },
    [containerId, reportError]
  )

  const debouncedFetchIssues = useMemo(() => debounce(fetchIssues, 500), [
    fetchIssues,
  ])

  const onQueryChange = () => {
    if (query) {
      debouncedFetchIssues(query)
    }
  }
  useEffect(onQueryChange, [debouncedFetchIssues, query])

  return {
    issues,
    loading,
  }
}

export default useJiraIssueSearch
