/*---- External -------------------------------------------------------------*/

import { combineEpics } from 'redux-observable'

/*---- Qualdesk -------------------------------------------------------------*/

import { NO_SELECTION } from '../selectors/config/defaults'

import {
  selectionRemoveAction,
  selectionAddAction,
  selectionSetAction,
  selectionClearAction,
  selectionToggleAction,
  selectionSelectAllAction,
  selectionSetWithRectAction,
  selectionExtendWithRectAction,
} from '../actionCreators/selection'

import { generateUserSelectionPath } from '../paths/selectionPaths'
import { createRTDBEpic } from './helpers/createRTDBEpic'
import type { UUID } from 'canvas-shared/lib/types'
import { convertItemIdsToSelectionRecord } from '../actionCreators/helpers/convertItemIdsToSelectionRecord'
import { mySelection } from '../selectors/board/selections'

/*---------------------------------------------------------------------------*/

const selectionAddEpic = createRTDBEpic<UUID>(
  selectionAddAction,
  ({ content, uid, containerId, database }) => {
    database()
      .ref(generateUserSelectionPath(containerId, uid))
      .child(content)
      .set(true)
  }
)

const selectionRemoveEpic = createRTDBEpic<UUID>(
  selectionRemoveAction,
  ({ content, uid, containerId, database }) =>
    database()
      .ref(generateUserSelectionPath(containerId, uid))
      .child(content)
      .remove()
)

const selectionClearEpic = createRTDBEpic<UUID>(
  selectionClearAction,
  ({ uid, containerId, database }) =>
    database()
      .ref(generateUserSelectionPath(containerId, uid))
      .set(NO_SELECTION)
)

const selectionSetEpic = createRTDBEpic<Record<UUID, true>>(
  [
    selectionToggleAction.fulfilled,
    selectionSelectAllAction.fulfilled,
    selectionSetWithRectAction.fulfilled,
    selectionExtendWithRectAction.fulfilled,
  ],
  ({ uid, containerId, database, state }) =>
    database()
      .ref(generateUserSelectionPath(containerId, uid))
      .set(mySelection(state) || {})
)

const selectionSetEpicWithoutThunk = createRTDBEpic<UUID[]>(
  selectionSetAction,
  ({ content: itemIds, uid, containerId, database }) =>
    database()
      .ref(generateUserSelectionPath(containerId, uid))
      .set(convertItemIdsToSelectionRecord(itemIds))
)

export const selectionEpic = combineEpics(
  selectionRemoveEpic,
  selectionAddEpic,
  selectionClearEpic,
  selectionSetEpic,
  selectionSetEpicWithoutThunk
)
