/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { RootState } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

export const shouldSelectSelector = (state: RootState) =>
  state.board.shouldSelectItemId
