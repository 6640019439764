"use strict";
/*---- External -------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.compareBoardTitles = void 0;
/*---------------------------------------------------------------------------*/
const compareBoardTitles = (a, b) => {
    const aTitle = a.data.title || '';
    const bTitle = b.data.title || '';
    return bTitle.localeCompare(aTitle);
};
exports.compareBoardTitles = compareBoardTitles;
