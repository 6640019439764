import { StatusCategoryKey } from 'canvas-shared/lib/types/connections/Jira.types'

export const jiraApiUrl = `${process.env.REACT_APP_JIRA_API_URL}`

export const STATUS_CATEGORY_COLOR_MAP: Record<
  StatusCategoryKey,
  string | undefined
> = {
  [StatusCategoryKey.New]: undefined,
  [StatusCategoryKey.Indeterminate]: '#93c5fd',
  [StatusCategoryKey.Done]: '#6ee7b7',
}
