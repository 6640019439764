/*---- External -------------------------------------------------------------*/

import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  userSetOneAction,
  userSetAllAction,
  userRemoveAction,
} from '../../../actionCreators/users'

import { UsersStateSlice } from '../../../../types/redux'

/*---------------------------------------------------------------------------*/

const initialState: UsersStateSlice = {
  loaded: false,
  data: {},
}

const extraReducers = (builder: ActionReducerMapBuilder<UsersStateSlice>) => {
  builder

    // user
    .addCase(userSetOneAction.fulfilled, (state, action) => {
      const id = action.payload.key
      if (!!action.payload.value) {
        state.data[id] = action.payload.value
      } else {
        delete state.data[id]
      }
    })
    .addCase(userSetAllAction, (state, action) => {
      if (!!action.payload) {
        state.data = action.payload
      } else {
        state.data = initialState.data
      }
      state.loaded = true
    })
    .addCase(userRemoveAction, (state, action) => {
      const id = action.payload
      delete state.data[id]
    })
}

export const usersSlice = createSlice({
  name: 'users',
  reducers: {},
  initialState,
  extraReducers,
})
