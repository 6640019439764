export function firestoreDataToArray<T extends { id: string }>(
  data: Record<string, Omit<T, 'id'>> | null | undefined
) {
  return !!data
    ? Object.keys(data).reduce((res: Array<T>, id) => {
        if (!!data[id]) {
          return res.concat({ ...data[id], id } as T)
        }
        return res
      }, [])
    : []
}
