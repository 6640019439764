/*---- External -------------------------------------------------------------*/

import { createAction, createAsyncThunk, nanoid } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { isRectPosition } from 'canvas-shared/lib/helpers/isRectPosition'
import type { UUID } from 'canvas-shared/lib/types'
import { Item } from 'canvas-shared/lib/types/Item.types'
import type { KeyValuePair } from 'canvas-shared/lib/types/utilities.types'
import { canBeDuplicated } from '../../helpers/canBeDuplicated'
import { ThunkAPI } from '../../types/redux'
import { selectMyUid } from '../selectors/auth/auth'
import { containerIdSelector } from '../selectors/board/container'
import {
  itemPositionSelector,
  selectStartZ,
} from '../selectors/board/positions'
import {
  mySelectedItemIdsSelector,
  mySelectedItemsSelector,
} from '../selectors/board/selections'
import { containerSelector } from '../selectors/container/containers'

/*---------------------------------------------------------------------------*/

export const itemsDatabaseSetOneAction = createAction<
  KeyValuePair<UUID, Item.AnyItem>
>('items/database/setOne')

export const itemsDatabaseLoadedAction = createAction('items/database/loaded')

export const itemsDatabaseRemoveOneAction = createAction<UUID>(
  'items/database/removeOne'
)

export interface DuplicateItemsActionPayload {
  items: Item.BaseItem[]
  uid: UUID
  newIdsMap: Record<UUID, UUID>
  startZ: number
}

export const duplicateItemsAction = createAsyncThunk<
  DuplicateItemsActionPayload,
  Pick<DuplicateItemsActionPayload, 'items'> | undefined,
  ThunkAPI
>('items/duplicate', (payload, { getState, rejectWithValue }) => {
  const state = getState()
  const uid = selectMyUid(state)
  const selectedItems = mySelectedItemsSelector(state)
  const startZ = selectStartZ(state)
  const items = payload?.items || selectedItems
  const newIdsMap = items.reduce((res: Record<UUID, UUID>, { id }) => {
    const newId = nanoid()
    res[id] = newId
    return res
  }, {})

  if (!canBeDuplicated(items)) {
    return rejectWithValue(payload)
  }

  return { items, uid, newIdsMap, startZ }
})

export interface CreateAdjacentItemActionPayload {
  sourceId: UUID
  newId: UUID
  uid: UUID
  canvasWidth: number
  canvasHeight: number
}

export const createAdjacentItemAction = createAsyncThunk<
  CreateAdjacentItemActionPayload,
  UUID,
  ThunkAPI
>('items/createAdjacent', (payload, { getState, rejectWithValue }) => {
  const state = getState()
  const uid = selectMyUid(state)
  const boardId = containerIdSelector(state)
  const boardSelector = containerSelector(boardId)
  const board = boardSelector(state, boardId)

  const position = itemPositionSelector(state, payload)

  if (!board || !position || !isRectPosition(position)) {
    return rejectWithValue(payload)
  }
  const {
    data: { canvasWidth, canvasHeight },
  } = board

  return { sourceId: payload, newId: nanoid(), uid, canvasWidth, canvasHeight }
})

export const removeItemsAction = createAsyncThunk<
  UUID[],
  UUID[] | undefined,
  ThunkAPI
>('items/remove', (itemIds, { getState }) => {
  if (!itemIds) {
    const state = getState()
    const selectedItemIds = mySelectedItemIdsSelector(state)
    return selectedItemIds
  } else {
    return itemIds
  }
})
