/*---- External -------------------------------------------------------------*/

import { createCachedSelector } from 're-reselect'
import { isEmpty, isLoaded } from 'react-redux-firebase'
import { createSelector } from '@reduxjs/toolkit'
import sampleSize from 'lodash.samplesize'

/*---- Qualdesk -------------------------------------------------------------*/

import { UUID } from '../../../types'
import { UserDetails } from 'canvas-shared/lib/types/user.types'
import { RootState } from '../../../types/redux'
import { RolesMap } from 'canvas-shared/lib/types/permissions.types'

/*---------------------------------------------------------------------------*/

export const usersSelector = (state: RootState) => state.firestore.data.users

export const usersWithIdsSelector = createSelector(usersSelector, (users) => {
  if (!users) {
    return NO_USERS
  } else {
    return Object.keys(users)
      .reduce((res: UserDetails[], uid) => {
        res = res.concat({
          uid,
          ...users[uid],
        })
        return res
      }, [])
      .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
  }
})

const getUserId = (state: RootState, userId: UUID | undefined) => userId

export const userSelector = createCachedSelector(
  getUserId,
  usersSelector,
  (userId, users) => {
    if (!!userId && isLoaded(users) && !isEmpty(users) && !!users[userId]) {
      return users[userId]
    }

    return undefined
  }
)((state, userId) => (!!userId ? userId : 'undefined'))

export const randomUserRoleMapSelector = createSelector(
  usersWithIdsSelector,
  (users) =>
    sampleSize(users, 5).reduce(
      (res: RolesMap, user) => ({
        users: {
          ...res.users,
          [user.uid]: 0,
        },
      }),
      { users: {} }
    )
)

export const userIdsInAlphabeticalOrderSelector = createSelector(
  usersWithIdsSelector,
  (users) => users.map((u) => u.uid)
)

const NO_USERS: UserDetails[] = []
