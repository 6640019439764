/*---- External -------------------------------------------------------------*/

import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { matchPath } from 'react-router'
import { generatePath, useHistory, useLocation } from 'react-router-dom'

import { PanelStack, IPanelProps, IPanel } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { useContainerId } from '../../contexts/Container'

import { Routes } from '../../router/routes'
import { selectUserConnections } from '../../redux/selectors/auth/userConnections'
import { TrelloAuthorization } from './trello/TrelloAuthorization'
import { TrelloBoardPicker } from './trello/TrelloBoardPicker'
import { JiraAuthorization } from './jira/JiraAuthorization'
import { JiraBoardPicker } from './jira/JiraBoardPicker'
import { LinearAuthorization } from './linear/LinearAuthorization'
import { LinearContentBrowser } from './linear/LinearContentBrowser'
import { GoogleAuthorization } from './google/GoogleAuthorization'
import { GoogleSelectFile } from './google/GoogleSelectFile'

/*---------------------------------------------------------------------------*/

interface Props {
  initialPanelComponent: React.ComponentType<IPanelProps>
}

enum ConnectionType {
  Trello = 'trello',
  Jira = 'jira',
  Linear = 'linear',
  Google = 'google',
}

type PanelControlOverride = {
  openPanel: (panel: IPanel<PanelControlOverride>) => void
  closePanel: () => void
}

export type ControlledPanelProps = IPanelProps & PanelControlOverride

const connectionTypeIsSidebarType = (
  type: ConnectionType | string | undefined
): type is ConnectionType =>
  !!type && Object.values(ConnectionType).includes(type as ConnectionType)

export const SidebarPanelStack: React.FC<Props> = ({
  initialPanelComponent,
}) => {
  const userConnections = useSelector(selectUserConnections)
  const { push } = useHistory()
  const { pathname } = useLocation()
  const containerId = useContainerId()
  const connectionType = matchPath<{ connectionType: string }>(pathname, {
    path: `${Routes.CANVAS_CONNECTIONS.path}/:connectionType`,
  })?.params.connectionType as ConnectionType | string | undefined

  const connectionPanels: Record<
    ConnectionType,
    IPanel<ControlledPanelProps>
  > = useMemo(
    () => ({
      [ConnectionType.Linear]: {
        component: userConnections.linear?.connected
          ? LinearContentBrowser
          : LinearAuthorization,
      },
      [ConnectionType.Jira]: {
        component: userConnections.jira?.connected
          ? JiraBoardPicker
          : JiraAuthorization,
      },
      [ConnectionType.Google]: {
        component: userConnections.google?.connected
          ? GoogleSelectFile
          : GoogleAuthorization,
      },
      [ConnectionType.Trello]: {
        component: userConnections.trello?.connected
          ? TrelloBoardPicker
          : TrelloAuthorization,
      },
    }),
    [
      userConnections.google?.connected,
      userConnections.jira?.connected,
      userConnections.linear?.connected,
      userConnections.trello?.connected,
    ]
  )

  const panelsFromRouteAndConnectionState: IPanel<any>[] = useMemo(
    () => [
      { component: initialPanelComponent },
      ...(connectionTypeIsSidebarType(connectionType)
        ? [connectionPanels[connectionType]]
        : []),
    ],
    [connectionPanels, connectionType, initialPanelComponent]
  )

  const [panels, setPanels] = useState<IPanel<any>[]>(
    panelsFromRouteAndConnectionState
  )

  useEffect(() => {
    setPanels(panelsFromRouteAndConnectionState)
  }, [panelsFromRouteAndConnectionState])

  const closePanel = () => {
    setPanels((panels) => panels.slice(0, panels.length - 1))

    if (
      stack.length === 2 &&
      !matchPath(pathname, {
        path: Routes.CANVAS_CONNECTIONS.path,
        exact: true,
      })
    ) {
      push(generatePath(Routes.CANVAS_CONNECTIONS.path, { containerId }))
    }
  }
  const openPanel = (panel: IPanel<any>) => {
    setPanels((panels) => panels.concat(panel))
  }

  const stack: IPanel<ControlledPanelProps>[] = panels.map((p) => ({
    ...p,
    props: {
      ...p.props,
      openPanel,
      closePanel,
    },
  }))

  return <PanelStack className="h-full" stack={stack} showPanelHeader={false} />
}
