/*---- External -------------------------------------------------------------*/

import React from 'react'

import { SortAscending, SortDescending } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import { SortOrderPopoverButton } from '../utilities/SortOrderPopoverButton'
import { SearchInputGroup } from '../utilities/SearchInputGroup'

import {
  useWorkspaceOrder,
  useWorkspaceSort,
} from '../../hooks/localStorage/workspaceSort'

import { useSearchContext } from '../../contexts/Search'

import { WorkspaceSort, WorkspaceOrder } from '../../types/workspace'

/*---------------------------------------------------------------------------*/

interface WorkspaceFilterButtonProps {
  className?: string
  disabled?: boolean
  hideSort?: boolean
}

export const WorkspaceFilterButton: React.FC<WorkspaceFilterButtonProps> = ({
  className,
  disabled,
  hideSort,
}) => {
  const [order, setOrder] = useWorkspaceOrder()
  const [sort, setSort] = useWorkspaceSort()
  const { searchQuery, updateQuery } = useSearchContext()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateQuery(e.target.value)
  }

  const handleClear = () => {
    updateQuery('')
  }

  return (
    <div className={className}>
      <div className="flex items-center space-x-2">
        <SearchInputGroup
          className={!!searchQuery ? 'w-48' : 'w-20'}
          disabled={disabled}
          onChange={handleChange}
          onClear={handleClear}
          minimal
          placeholder="Find"
          value={searchQuery}
        />
        {!hideSort && (
          <SortOrderPopoverButton
            className="text-text-secondary-light dark:text-text-secondary-dark flex-shrink-0"
            disabled={disabled}
            sort={sort}
            order={order}
            sortOptions={[
              {
                label: 'Most recent first',
                value: WorkspaceSort.Asc,
                icon: SortAscending,
              },
              {
                label: 'Least recent first',
                value: WorkspaceSort.Desc,
                icon: SortDescending,
              },
            ]}
            orderOptions={[
              {
                label: 'Last updated',
                value: WorkspaceOrder.Updated,
              },
              { label: 'Created', value: WorkspaceOrder.Created },
            ]}
            onChangeSort={setSort}
            onChangeOrder={setOrder}
          />
        )}
      </div>
    </div>
  )
}
