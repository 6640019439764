/*---- External -------------------------------------------------------------*/

import { useCallback, useMemo } from 'react'
import { useFirestore } from 'react-redux-firebase'

/*---- Qualdesk -------------------------------------------------------------*/

import { generateItemPath } from '../../../../paths/itemsPaths'

import { useContainerId } from '../../../../../contexts/Container'

import { useItemUpdateActions } from '../../helpers/useItemUpdateActions'

import { UUID } from 'canvas-shared/lib/types'
import { TrelloBoardConnection } from 'canvas-shared/lib/types/BoardConnection.types'

import { Item, ItemType } from 'canvas-shared/lib/types/Item.types'
import * as Trello from 'canvas-shared/lib/types/connections/Trello.types'

/*---------------------------------------------------------------------------*/

interface ItemTrelloActions {
  trelloSetList: (ids: UUID[], list: Trello.List) => void
  trelloAddLabel: (ids: UUID[], label: Trello.Label) => void
  trelloRemoveLabel: (ids: UUID[], label: Trello.Label) => void
  trelloAddMember: (ids: UUID[], member: Trello.Member) => void
  trelloRemoveMember: (ids: UUID[], member: Trello.Member) => void
  convertItemsToTrello: (
    items: Item.BaseItem[],
    connection: TrelloBoardConnection
  ) => void
}

export const useItemTrelloActions = (): ItemTrelloActions => {
  const firestore = useFirestore()
  const containerId = useContainerId()

  const { generateItemUpdate } = useItemUpdateActions()

  const trelloSetList = useCallback(
    (ids: UUID[], list: Trello.List) => {
      const batch = firestore.batch()
      ids.forEach((id) => {
        const ref = firestore.doc(generateItemPath(containerId, id))
        batch.update(
          ref,
          generateItemUpdate({
            'data.trello.list.id': list.id,
            'data.trello.list.name': list.name,
          })
        )
      })
      batch.commit()
    },
    [containerId, firestore, generateItemUpdate]
  )

  const trelloAddLabel = useCallback(
    (ids: UUID[], label: Trello.Label) => {
      const batch = firestore.batch()
      ids.forEach((id) => {
        const ref = firestore.doc(generateItemPath(containerId, id))
        batch.update(
          ref,
          generateItemUpdate({
            'data.trello.labels': firestore.FieldValue.arrayUnion(label),
          })
        )
      })
      batch.commit()
    },
    [containerId, firestore, generateItemUpdate]
  )

  const trelloRemoveLabel = useCallback(
    (ids: UUID[], label: Trello.Label) => {
      const batch = firestore.batch()
      ids.forEach((id) => {
        const ref = firestore.doc(generateItemPath(containerId, id))
        batch.update(
          ref,
          generateItemUpdate({
            'data.trello.labels': firestore.FieldValue.arrayRemove(label),
          })
        )
      })
      batch.commit()
    },
    [containerId, firestore, generateItemUpdate]
  )

  const trelloAddMember = useCallback(
    (ids: UUID[], member: Trello.Member) => {
      const batch = firestore.batch()
      ids.forEach((id) => {
        const ref = firestore.doc(generateItemPath(containerId, id))
        batch.update(
          ref,
          generateItemUpdate({
            'data.trello.memberIds': firestore.FieldValue.arrayUnion(member.id),
          })
        )
      })
      batch.commit()
    },
    [containerId, firestore, generateItemUpdate]
  )

  const trelloRemoveMember = useCallback(
    (ids: UUID[], member: Trello.Member) => {
      const batch = firestore.batch()
      ids.forEach((id) => {
        const ref = firestore.doc(generateItemPath(containerId, id))
        batch.update(
          ref,
          generateItemUpdate({
            'data.trello.memberIds': firestore.FieldValue.arrayRemove(
              member.id
            ),
          })
        )
      })
      batch.commit()
    },
    [containerId, firestore, generateItemUpdate]
  )

  const convertItemsToTrello = useCallback(
    (items: Item.BaseItem[], connection: TrelloBoardConnection) => {
      const { board, context } = connection.trello
      const list = context.lists[0]

      const batch = firestore.batch()

      items.forEach((item) => {
        const ref = firestore.doc(generateItemPath(containerId, item.id))
        batch.update(
          ref,
          generateItemUpdate({
            type: ItemType.TrelloCard,
            loading: true,
            'data.trello': {
              list,
              board,
              context,
              labels: [],
              memberIds: [],
              cardId: null,
              importedBy: null,
            },
          })
        )
      })
      batch.commit()
    },
    [containerId, firestore, generateItemUpdate]
  )

  return useMemo(
    () => ({
      trelloSetList,
      trelloAddLabel,
      trelloRemoveLabel,
      trelloAddMember,
      trelloRemoveMember,
      convertItemsToTrello,
    }),
    [
      convertItemsToTrello,
      trelloAddLabel,
      trelloAddMember,
      trelloRemoveLabel,
      trelloRemoveMember,
      trelloSetList,
    ]
  )
}
