/*---- External -------------------------------------------------------------*/

import { createAction, createAsyncThunk, nanoid } from '@reduxjs/toolkit'

import { IToastProps } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { ToastPiece, ThunkAPI } from '../../types/redux'
import { UUID } from 'canvas-shared/lib/types'

/*---------------------------------------------------------------------------*/

export const showToastAction = createAsyncThunk<
  ToastPiece,
  IToastProps,
  ThunkAPI
>('toast/show', (toast) => {
  const id = nanoid()
  return { id, toast }
})

export const updateToastAction = createAction<ToastPiece>('toast/update')

export const dismissToastAction = createAction<UUID>('toast/dismiss')
