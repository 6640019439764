/*---- External -------------------------------------------------------------*/

import React, { useMemo, useState } from 'react'

import { Button, InputGroup, Intent } from '@blueprintjs/core'

import isURL from 'validator/lib/isURL'

/*---- Qualdesk -------------------------------------------------------------*/

import { useContainerId } from '../../../contexts/Container'

import { functions } from '../../../config/firebase'

import { useStatusReporter } from '../../../hooks/useStatusReporter'

import { GoogleChooseData } from './GoogleChooseData'

import { ControlledPanelProps } from '../SidebarPanelStack'

/*---------------------------------------------------------------------------*/

const connectFile = functions.httpsCallable('https-googleConnectFile')

interface GoogleSelectFileFormProps
  extends Pick<ControlledPanelProps, 'openPanel'> {
  className?: string
  fill?: boolean
  placeholder?: string
}

export const GoogleSelectFileForm: React.FC<GoogleSelectFileFormProps> = ({
  className,
  fill,
  openPanel,
  placeholder,
}) => {
  const { reportError } = useStatusReporter()

  const boardId = useContainerId()

  const [url, setUrl] = useState('')
  const [loading, setLoading] = useState(false)

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value)
  }

  const isGoogleUrl = useMemo(
    () => !!url && isURL(url, { host_whitelist: ['docs.google.com'] }),
    [url]
  )

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    setLoading(true)

    if (isGoogleUrl) {
      try {
        const {
          data: { fileId, connectionId, name },
        } = await connectFile({
          url,
          boardId,
        })

        if (!!fileId) {
          openPanel({
            component: GoogleChooseData,
            props: {
              fileId,
              connectionId,
              name,
            },
          })
        }
      } catch (err) {
        reportError(err, err.message)
        setLoading(false)
      }
    }
  }

  return (
    <form className={className} onSubmit={handleSubmit}>
      <InputGroup
        id="token"
        onChange={handleUrlChange}
        placeholder={placeholder}
        value={url}
        disabled={loading}
      />
      <Button
        disabled={!isGoogleUrl}
        fill={fill}
        intent={Intent.PRIMARY}
        loading={loading}
        onClick={handleSubmit}
        text="Connect"
        type="submit"
      />
    </form>
  )
}
