/*---- External -------------------------------------------------------------*/

import { combineEpics, ofType } from 'redux-observable'
import { map } from 'rxjs/operators'

import { Intent } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { copyAction, cutAction, pasteAction } from '../actionCreators/clipboard'
import { showToastAction } from '../actionCreators/toast'

import { EpicWithDifferentActions, ActionWithPayload } from '../../types/redux'
import { UUID } from 'canvas-shared/lib/types'
import { Item } from 'canvas-shared/lib/types/Item.types'

/*---------------------------------------------------------------------------*/

const copyRejectedEpic: EpicWithDifferentActions<
  ActionWithPayload<UUID[]>,
  ReturnType<typeof showToastAction>
> = (action$) =>
  action$.pipe(
    ofType(copyAction.rejected),
    map(() =>
      showToastAction({
        icon: 'clipboard',
        intent: Intent.WARNING,
        message: 'You can’t copy synced items',
      })
    )
  )

const cutRejectedEpic: EpicWithDifferentActions<
  ActionWithPayload<UUID[]>,
  ReturnType<typeof showToastAction>
> = (action$) =>
  action$.pipe(
    ofType(cutAction.rejected),
    map(() =>
      showToastAction({
        icon: 'cut',
        intent: Intent.WARNING,
        message: 'You can’t cut synced items',
      })
    )
  )

const pasteRejectedEpic: EpicWithDifferentActions<
  ActionWithPayload<{ items?: Item.AnyItem; text?: string }>,
  ReturnType<typeof showToastAction>
> = (action$) =>
  action$.pipe(
    ofType(pasteAction.rejected),
    map(() =>
      showToastAction({
        icon: 'clipboard',
        intent: Intent.WARNING,
        message: 'The data on the clipboard couldn’t be pasted',
      })
    )
  )

export const clipboardEpic = combineEpics(
  copyRejectedEpic,
  cutRejectedEpic,
  pasteRejectedEpic
)
