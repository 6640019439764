/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  UserConnectionType,
  GoogleUserConnection,
  JiraUserConnection,
  TrelloUserConnection,
  LinearUserConnection,
  UserConnections,
} from 'canvas-shared/lib/types/UserConnection.types'
import { RootState } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

const getUserConnections = (state: RootState) =>
  state.firestore.ordered.userConnections

export const selectUserConnections = createSelector(
  [getUserConnections],
  (userConnections): UserConnections => ({
    google: userConnections?.find(
      (c) => c.type === UserConnectionType.Google
    ) as GoogleUserConnection | undefined,
    jira: userConnections?.find((c) => c.type === UserConnectionType.Jira) as
      | JiraUserConnection
      | undefined,
    trello: userConnections?.find(
      (c) => c.type === UserConnectionType.Trello
    ) as TrelloUserConnection | undefined,
    linear: userConnections?.find(
      (c) => c.type === UserConnectionType.Linear
    ) as LinearUserConnection | undefined,
  })
)

export const selectJiraUserConnections = createSelector(
  selectUserConnections,
  (connections) => connections.jira
)
export const selectTrelloUserConnections = createSelector(
  selectUserConnections,
  (connections) => connections.trello
)

export const selectUserConnectionsRequiringReauthentication = createSelector(
  selectUserConnections,
  (userConnections) => {
    const keys = Object.keys(userConnections) as Array<keyof UserConnections>

    return keys.reduce((res: UserConnections, key) => {
      if (userConnections[key]?.reauthenticationRequired) {
        return { ...res, [key]: userConnections[key] }
      }
      return res
    }, {})
  }
)
