/*---- External -------------------------------------------------------------*/

import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPreparedPayload,
} from '@reduxjs/toolkit'
import { OneOrMany } from 'canvas-shared/lib/types'
import { UpdateTimestamps } from 'canvas-shared/lib/types/timestamps.types'
import { Epic } from 'redux-observable'

/*---- Qualdesk -------------------------------------------------------------*/

import type { ActionWithPayload } from '../../../types/redux'
import type { RootState } from '../../../types/redux'
import { generateItemPath } from '../../paths/itemsPaths'
import { createFirestoreEpic } from './createFirestoreEpic'

/*---------------------------------------------------------------------------*/

interface MultiItemUpdatePayload extends UpdateTimestamps {
  ids: string[]
}

export function createFirestoreMultiItemUpdateEpic<
  Payload extends MultiItemUpdatePayload
>(
  actionCreator: OneOrMany<
    ActionCreatorWithPreparedPayload<any, Payload> | ActionCreatorWithoutPayload
  >,
  updateGenerator: (payload: Payload) => Record<string, any>
): Epic<ActionWithPayload<Payload>, never, RootState> {
  return createFirestoreEpic(
    actionCreator,
    ({ content, containerId, firestore }) => {
      const { ids, updatedAt, updatedBy } = content

      const fs = firestore()
      const batch = fs.batch()

      ids.forEach((itemId) => {
        batch.update(fs.doc(generateItemPath(containerId, itemId)), {
          ...updateGenerator(content),
          updatedAt,
          updatedBy,
        })
      })

      return batch.commit()
    }
  )
}
