/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import {
  editRemoveAction,
  editSetOneAction,
  editUpdateOneAction,
} from '../actionCreators/edits'

import { generateUserEditsPath } from '../paths/editsPaths'
import { createRTDBEpic } from './helpers/createRTDBEpic'
import { selectMyEdits } from '../selectors/board/edits'

import type { UpdatePayload } from '../../types/redux'
import type { ItemEdit } from 'canvas-shared/lib/types/Item.types'
import type { UUID } from 'canvas-shared/lib/types'

/*---------------------------------------------------------------------------*/

const editsRTDBEpic = createRTDBEpic<UUID | UpdatePayload<ItemEdit>>(
  [editSetOneAction, editRemoveAction, editUpdateOneAction],
  ({ uid, containerId, database, state }) => {
    const edits = selectMyEdits(state)
    database().ref(generateUserEditsPath(containerId, uid)).set(edits)
  }
)

export const editEpic = editsRTDBEpic
