/*---- External -------------------------------------------------------------*/

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { useBoard } from '../../redux/selectorHooks/board/useBoard'

import { UUID } from 'canvas-shared/lib/types'
import { createTeamSelector } from '../../redux/selectors/auth/teams'

/*---------------------------------------------------------------------------*/

export const BoardSharedIn: React.FC = () => {
  const {
    roles: { teams },
  } = useBoard()

  if (!teams) {
    return null
  }

  return <BoardSharedInTeam teamId={teams.membership} />
}

interface BoardSharedInTeamProps {
  teamId: UUID
}

const BoardSharedInTeam: React.FC<BoardSharedInTeamProps> = ({ teamId }) => {
  const teamSelector = useMemo(() => createTeamSelector(teamId), [teamId])
  const team = useSelector(teamSelector)

  if (!team) {
    return null
  }

  return (
    <div className="text-xxs text-text-secondary-light dark:text-text-secondary-dark whitespace-nowrap font-light overflow-hidden overflow-ellipsis">
      Shared in {team.name}
    </div>
  )
}
