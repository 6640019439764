/*---- External -------------------------------------------------------------*/

import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { IMenuItemProps, IMenuDividerProps, MenuItem } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { useJiraIssueSearchContext } from '../../../contexts/JiraIssueSearch'

import GoogleSheetsIcon from '../../../assets/integrations/google/fileTypeIcons/sheets.png'
import { ReactComponent as JiraIcon } from '../../../assets/integrations/jira/jira-icon.svg'
import { ReactComponent as TrelloIcon } from '../../../assets/integrations/trello/trello-icon.svg'
import { ReactComponent as LinearIcon } from '../../../assets/integrations/linear/linear-icon.svg'

import TrelloContextMenu from '../../../components/connections/trello/TrelloContextMenu'
import JiraContextMenu from '../../../components/connections/jira/JiraContextMenu'
import { LinearContextMenu } from '../../../components/connections/linear/LinearContextMenu'

import { insertMenuItemDividers } from '../../../helpers/insertMenuItemDividers'
import { isConvertibleItem } from 'canvas-shared/lib/helpers/itemTypes'
import { getTitleFromData } from 'canvas-shared/lib/helpers/getTitleFromData'

import { useItemGoogleSheetsActions } from '../../../redux/actions/board/connections/google/useItemGoogleSheetsActions'
import { useItemJiraActions } from '../../../redux/actions/board/connections/jira/useItemJiraActions'
import { useItemTrelloActions } from '../../../redux/actions/board/connections/trello/useItemTrelloActions'
import { useItemLinearActions } from '../../../redux/actions/board/connections/linear/useItemLinearActions'

import {
  selectGoogleSheetsBoardConnections,
  selectJiraBoardConnections,
  selectLinearBoardConnections,
  selectTrelloBoardConnections,
} from '../../../redux/selectors/board/connections'

import { useStatusReporter } from '../../useStatusReporter'

import { NO_MENU_ITEMS } from '../../../constants/menus'

import {
  TrelloBoardConnection,
  JiraBoardConnection,
  LinearBoardConnection,
} from 'canvas-shared/lib/types/BoardConnection.types'

import { Item, ItemType } from 'canvas-shared/lib/types/Item.types'
import * as JiraAPI from 'canvas-shared/lib/types/connections/JiraAPI.types'
import * as Jira from 'canvas-shared/lib/types/connections/Jira.types'

/*---------------------------------------------------------------------------*/

export const useConnectionMenuItems = (
  actionItems: Item.BaseItem[]
): Array<IMenuItemProps | IMenuDividerProps> => {
  const googleSheetsConnections = useSelector(
    selectGoogleSheetsBoardConnections
  )
  const jiraConnections = useSelector(selectJiraBoardConnections)
  const trelloConnections = useSelector(selectTrelloBoardConnections)
  const linearConnections = useSelector(selectLinearBoardConnections)
  const statusReporter = useStatusReporter()

  const {
    convertItemsToTrello,
    trelloSetList,
    trelloAddLabel,
    trelloRemoveLabel,
    trelloAddMember,
    trelloRemoveMember,
  } = useItemTrelloActions()

  const {
    jiraTransition,
    jiraAssignUser,
    jiraSetSprint,
    jiraUnsetSprint,
    jiraPublishEstimates,
    jiraAssignParent,
  } = useItemJiraActions()

  const { convertItemsToGoogleSheetsRow } = useItemGoogleSheetsActions()
  const {
    convertItemsToLinear,
    linearAddLabel,
    linearAssignUser,
    linearChangeState,
    linearRemoveLabel,
    linearSetCycle,
    linearSetPriority,
    linearSetProject,
    linearUnsetCycle,
    linearUnsetProject,
  } = useItemLinearActions()

  const { showJiraIssueSearchBar } = useJiraIssueSearchContext()

  const trelloCards = useMemo(
    () =>
      actionItems.filter(
        (item) => item.type === ItemType.TrelloCard
      ) as Item.TrelloCard[],
    [actionItems]
  )
  const jiraCards = useMemo(
    () =>
      actionItems.filter(
        (item) => item.type === ItemType.JiraCard
      ) as Item.JiraCard[],
    [actionItems]
  )
  const googleSheetsCards = useMemo(
    () => actionItems.filter((item) => item.type === ItemType.GoogleSheetsCard),
    [actionItems]
  )
  const linearCards = useMemo(
    () =>
      actionItems.filter(
        (item) => item.type === ItemType.LinearCard
      ) as Item.LinearCard[],
    [actionItems]
  )

  const everyActionItemConvertible = useMemo(
    () =>
      actionItems.every(
        (item) =>
          isConvertibleItem(item) &&
          !!getTitleFromData(item.data, { removeNewlines: true })
      ),
    [actionItems]
  )

  const handleConvertToTrello = useCallback(() => {
    if (!!trelloConnections) {
      convertItemsToTrello(actionItems, trelloConnections[0])
    }
  }, [actionItems, convertItemsToTrello, trelloConnections])

  const trelloItems: (IMenuItemProps | IMenuDividerProps)[] = useMemo(() => {
    if (
      trelloCards.length < 1 &&
      !!trelloConnections &&
      everyActionItemConvertible
    ) {
      if (trelloConnections.length === 1) {
        return [
          {
            icon: 'add',
            text: 'Convert to Trello card',
            onClick: handleConvertToTrello,
          },
        ]
      } else if (trelloConnections.length > 1) {
        return [
          {
            icon: 'add',
            text: 'Convert to Trello card',
            children: trelloConnections.map((connection) => (
              <ConvertToTrelloItem
                actionItems={actionItems}
                connection={connection}
                key={connection.id}
              />
            )),
          },
        ]
      }
    } else if (trelloCards.length >= 1) {
      return TrelloContextMenu({
        trelloCards,
        trelloSetList,
        trelloAddLabel,
        trelloRemoveLabel,
        trelloAddMember,
        trelloRemoveMember,
      })
    }
    return NO_MENU_ITEMS
  }, [
    actionItems,
    everyActionItemConvertible,
    handleConvertToTrello,
    trelloAddLabel,
    trelloAddMember,
    trelloCards,
    trelloConnections,
    trelloRemoveLabel,
    trelloRemoveMember,
    trelloSetList,
  ])

  const trelloItemsWithHeader: Array<
    IMenuItemProps | IMenuDividerProps
  > = useMemo(() => {
    if (trelloItems.length > 0) {
      const headerItem = {
        title: (
          <span className="flex items-center space-x-1">
            <TrelloIcon style={{ width: '12px', height: '12px' }} />
            <span>Trello</span>
          </span>
        ),
      }
      return ([headerItem] as Array<IMenuItemProps | IMenuDividerProps>).concat(
        trelloItems
      )
    } else {
      return trelloItems
    }
  }, [trelloItems])

  const jiraItems: Array<IMenuItemProps | IMenuDividerProps> = useMemo(() => {
    if (jiraCards.length < 1) {
      if (!!jiraConnections && everyActionItemConvertible) {
        return jiraConnections.map((connection: JiraBoardConnection) => {
          const { project, issueTypes } = connection.jira
          return {
            icon: 'add',
            text: `Convert to Jira (${connection.displayName})`,
            children: issueTypes
              .filter((t) => !t.subtask)
              .map((issueType) => (
                <ConvertToJiraItem
                  actionItems={actionItems}
                  connection={connection}
                  issueType={issueType}
                  project={project}
                  key={connection.id}
                />
              )),
          }
        })
      } else {
        return NO_MENU_ITEMS
      }
    } else {
      return JiraContextMenu({
        jiraCards,
        jiraAssignUser,
        jiraTransition,
        jiraSetSprint,
        jiraUnsetSprint,
        jiraPublishEstimates,
        jiraAssignParent,
        showJiraIssueSearchBar,
        statusReporter,
      })
    }
  }, [
    actionItems,
    everyActionItemConvertible,
    jiraAssignParent,
    jiraAssignUser,
    jiraCards,
    jiraConnections,
    jiraPublishEstimates,
    jiraSetSprint,
    jiraTransition,
    jiraUnsetSprint,
    showJiraIssueSearchBar,
    statusReporter,
  ])

  const jiraItemsWithHeader = useMemo(() => {
    if (jiraItems.length > 0) {
      const headerItem = {
        title: (
          <span className="flex items-center space-x-1">
            <JiraIcon style={{ width: '12px', height: '12px' }} />
            <span>Jira</span>
          </span>
        ),
      }
      return ([headerItem] as Array<IMenuDividerProps | IMenuItemProps>).concat(
        jiraItems
      )
    } else {
      return jiraItems
    }
  }, [jiraItems])

  const googleSheetsItems: Array<
    IMenuItemProps | IMenuDividerProps
  > = useMemo(() => {
    if (
      googleSheetsCards.length < 1 &&
      !!googleSheetsConnections &&
      everyActionItemConvertible
    ) {
      if (googleSheetsConnections?.length === 1) {
        return [
          {
            icon: 'add',
            text: 'Convert to row in Google Sheets',
            onClick: () =>
              convertItemsToGoogleSheetsRow(
                actionItems,
                googleSheetsConnections[0]
              ),
          },
        ]
      } else if (googleSheetsConnections.length > 1) {
        return [
          {
            icon: 'add',
            text: 'Convert to row in Google Sheets',
            children: googleSheetsConnections?.map((connection) => (
              <MenuItem
                text={connection.google.file.name}
                onClick={() =>
                  convertItemsToGoogleSheetsRow(actionItems, connection)
                }
              />
            )),
          },
        ]
      }
    }

    return NO_MENU_ITEMS
  }, [
    actionItems,
    convertItemsToGoogleSheetsRow,
    everyActionItemConvertible,
    googleSheetsCards.length,
    googleSheetsConnections,
  ])

  const googleSheetsItemsWithHeader = useMemo(() => {
    if (googleSheetsItems.length > 0) {
      const headerItem = {
        title: (
          <span className="flex items-center space-x-1">
            <img
              alt="Google Sheets"
              style={{ width: '12px', height: '12px' }}
              src={GoogleSheetsIcon}
            />
            <span>Google Sheets</span>
          </span>
        ),
      }
      return ([headerItem] as Array<IMenuDividerProps | IMenuItemProps>).concat(
        googleSheetsItems
      )
    } else {
      return googleSheetsItems
    }
  }, [googleSheetsItems])

  const handleConvertToLinear = useCallback(() => {
    if (!!linearConnections) {
      convertItemsToLinear(actionItems, linearConnections[0])
    }
  }, [actionItems, convertItemsToLinear, linearConnections])

  const linearItems: Array<IMenuItemProps | IMenuDividerProps> = useMemo(() => {
    if (linearCards.length < 1) {
      if (
        !!linearConnections &&
        linearConnections.length > 0 &&
        everyActionItemConvertible
      ) {
        if (linearConnections.length === 1) {
          return [
            {
              icon: 'add',
              text: 'Convert to Linear issue',
              onClick: handleConvertToLinear,
            },
          ]
        } else {
          return [
            {
              icon: 'add',
              text: 'Convert to Linear issue',
              children: linearConnections.map((connection) => (
                <ConvertToLinearItem
                  actionItems={actionItems}
                  connection={connection}
                  key={connection.id}
                />
              )),
            },
          ]
        }
      } else {
        return NO_MENU_ITEMS
      }
    } else {
      return LinearContextMenu({
        linearAddLabel,
        linearAssignUser,
        linearCards,
        linearChangeState,
        linearRemoveLabel,
        linearSetCycle,
        linearSetPriority,
        linearSetProject,
        linearUnsetCycle,
        linearUnsetProject,
      })
    }
  }, [
    linearCards,
    linearConnections,
    everyActionItemConvertible,
    handleConvertToLinear,
    actionItems,
    linearAddLabel,
    linearAssignUser,
    linearChangeState,
    linearRemoveLabel,
    linearSetCycle,
    linearSetPriority,
    linearSetProject,
    linearUnsetCycle,
    linearUnsetProject,
  ])

  const linearItemsWithHeader = useMemo(() => {
    if (linearItems.length > 0) {
      const headerItem = {
        title: (
          <span className="flex items-center space-x-1">
            <LinearIcon style={{ width: '12px', height: '12px' }} />
            <span>Linear</span>
          </span>
        ),
      }
      return ([headerItem] as Array<IMenuDividerProps | IMenuItemProps>).concat(
        linearItems
      )
    } else {
      return linearItems
    }
  }, [linearItems])

  return useMemo(
    () =>
      insertMenuItemDividers([
        googleSheetsItemsWithHeader,
        jiraItemsWithHeader,
        trelloItemsWithHeader,
        linearItemsWithHeader,
      ]),
    [
      googleSheetsItemsWithHeader,
      jiraItemsWithHeader,
      trelloItemsWithHeader,
      linearItemsWithHeader,
    ]
  )
}

interface ConvertItemProps {
  actionItems: Item.BaseItem[]
}

interface JiraItemProps extends ConvertItemProps {
  connection: JiraBoardConnection
  issueType: JiraAPI.IssueType
  project: Jira.Project
}

interface TrelloItemProps extends ConvertItemProps {
  connection: TrelloBoardConnection
}

interface LinearItemProps extends ConvertItemProps {
  connection: LinearBoardConnection
}

const ConvertToJiraItem: React.FC<JiraItemProps> = ({
  actionItems,
  connection,
  issueType,
  project,
}) => {
  const { convertItemsToJira } = useItemJiraActions()
  const handleClick = () =>
    convertItemsToJira(
      actionItems.map((i) => i.id),
      connection,
      issueType,
      project
    )

  return <MenuItem text={issueType.name} onClick={handleClick} />
}

const ConvertToTrelloItem: React.FC<TrelloItemProps> = ({
  actionItems,
  connection,
}) => {
  const { convertItemsToTrello } = useItemTrelloActions()
  const handleClick = () => convertItemsToTrello(actionItems, connection)

  return <MenuItem text={connection.trello.board.name} onClick={handleClick} />
}

const ConvertToLinearItem: React.FC<LinearItemProps> = ({
  actionItems,
  connection,
}) => {
  const { convertItemsToLinear } = useItemLinearActions()
  const handleClick = () => convertItemsToLinear(actionItems, connection)

  return <MenuItem text={connection.linear.team.name} onClick={handleClick} />
}
