/*---- External -------------------------------------------------------------*/

import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useHistory, useRouteMatch } from 'react-router-dom'
import { firstBy } from 'thenby'
import times from 'lodash.times'

import { Classes, IPanelProps } from '@blueprintjs/core'

import { SortAscending, SortDescending } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import { functions } from '../../../config/firebase'

import { selectTrelloBoardConnections } from '../../../redux/selectors/board/connections'
import useTrelloConnection from '../../../hooks/connections/useTrelloConnection'
import { useStatusReporter } from '../../../hooks/useStatusReporter'
import { usePartialMatchFilter } from '../../../hooks/usePartialMatchFilter'
import { useContainerId } from '../../../contexts/Container'

import { SearchInputGroup } from '../../utilities/SearchInputGroup'
import { NoResults } from '../../utilities/NoResults'
import { SortOrderPopoverButton } from '../../utilities/SortOrderPopoverButton'
import { SidebarPanel } from '../SidebarPanel'
import { TrelloBoardButton } from './TrelloBoardButton'

import trelloIcon from '../../../assets/integrations/trello/trello-icon.svg'

/*---------------------------------------------------------------------------*/

const fetchTrelloBoard = functions.httpsCallable('https-trelloFetchTrelloBoard')

interface TrelloBoardPrefs {
  backgroundTopColor: string
  backgroundBottomColor: string
  backgroundImage: string
  backgroundImageScaled: any[]
}

export interface TrelloBoard {
  id: string
  name: string
  prefs: TrelloBoardPrefs
}

enum Order {
  Recent = 'recent',
  Name = 'name',
}

enum Sort {
  Asc = 'asc',
  Desc = 'desc',
}

export const TrelloBoardPicker: React.FC<IPanelProps> = (panelProps) => {
  const { url } = useRouteMatch()
  const { push } = useHistory()
  const [boards, setBoards] = useState<TrelloBoard[]>([])
  const [isLoaded, setIsLoaded] = useState(false)
  const { connected, client: trelloClient } = useTrelloConnection()
  const canvasId = useContainerId()
  const { reportProgress, reportSuccess, reportError } = useStatusReporter()

  const [query, setQuery] = useState('')
  const [order, setOrder] = useState<Order>(Order.Name)
  const [sort, setSort] = useState<Sort>(Sort.Asc)
  const trelloConnections = useSelector(selectTrelloBoardConnections)

  const hasTrelloConnection = useMemo(
    () => trelloConnections && trelloConnections.some((c) => !!c.updatedAt),
    [trelloConnections]
  )

  useEffect(() => {
    if (hasTrelloConnection) {
      setOrder(Order.Recent)
      setSort(Sort.Desc)
    }
  }, [hasTrelloConnection])

  const cmpRecent = useCallback(
    (a, b) => {
      if (trelloConnections) {
        const aUpdatedAt =
          trelloConnections.find((c) => c.trello.board.id === a.id)
            ?.updatedAt ?? 0
        const bUpdatedAt =
          trelloConnections.find((c) => c.trello.board.id === b.id)
            ?.updatedAt ?? 0

        return aUpdatedAt - bUpdatedAt
      } else {
        return 0
      }
    },
    [trelloConnections]
  )

  const cmpName = useCallback((a, b) => a.name.localeCompare(b.name), [])

  const sortedBoards = useMemo(() => {
    let cmp
    switch (order) {
      case Order.Recent:
        cmp = firstBy(cmpRecent, sort).thenBy(cmpName)
        break
      case Order.Name:
        cmp = firstBy(cmpName, sort)
        break
    }
    return [...boards].sort(cmp)
  }, [boards, order, sort, cmpRecent, cmpName])

  const filteredBoards = usePartialMatchFilter<TrelloBoard>(
    sortedBoards,
    query,
    (board) => board.name
  )

  useEffect(() => {
    let isSubscribed = true
    const initBoards = async () => {
      if (connected) {
        try {
          if (isSubscribed) {
            const boards = await trelloClient.getBoards()
            setBoards(boards)
            setIsLoaded(true)
          }
        } catch (error) {
          reportError(error, 'Failed to get Trello boards')
        }
      }
    }

    initBoards()

    return () => {
      isSubscribed = false
    }
  }, [connected, trelloClient, reportProgress, reportSuccess, reportError])

  const onClick = useCallback(
    async (id: string) => {
      push(url)
      reportProgress('Importing your board from Trello…')

      try {
        await fetchTrelloBoard({
          canvasId,
          id,
        })
        reportSuccess('Trello board imported successfully')
      } catch (error) {
        reportError(error, 'Error importing from Trello')
      }
    },
    [canvasId, push, reportProgress, reportSuccess, reportError, url]
  )

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }, [])

  const onClear = useCallback(() => {
    setQuery('')
  }, [])

  const onChangeOrder = useCallback((value) => {
    switch (value) {
      case Order.Recent:
        setOrder(value)
        setSort(Sort.Desc)
        break
      case Order.Name:
        setOrder(value)
        setSort(Sort.Asc)
        break
    }
  }, [])

  const onChangeSort = useCallback((value) => {
    switch (value) {
      case Sort.Asc:
        setSort(value)
        break
      case Sort.Desc:
        setSort(value)
        break
    }
  }, [])

  return (
    <SidebarPanel
      {...panelProps}
      text="Trello"
      iconUrl={trelloIcon}
      iconAltText="Trello icon"
    >
      <div className="flex-grow p-6 overflow-y-auto">
        <SearchInputGroup
          className="mb-6"
          value={query}
          onChange={onChange}
          onClear={onClear}
          placeholder="Find a board"
          changeBackgroundOnFocus={false}
          small
        />
        <div className="flex items-center justify-between mt-6 space-x-4">
          <div className="text-text-secondary-light dark:text-text-secondary-dark text-sm font-semibold">
            {isLoaded &&
              `${filteredBoards.length} ${
                filteredBoards.length === 1 ? 'board' : 'boards'
              }`}
          </div>
          <div className="flex-shrink-0">
            <div className="-mr-2">
              <SortOrderPopoverButton
                sort={sort}
                order={order}
                sortOptions={[
                  { label: 'Ascending', value: Sort.Asc, icon: SortAscending },
                  {
                    label: 'Descending',
                    value: Sort.Desc,
                    icon: SortDescending,
                  },
                ]}
                orderOptions={[
                  {
                    label: 'Recently used',
                    value: Order.Recent,
                    disabled: !hasTrelloConnection,
                  },
                  { label: 'Board name', value: Order.Name },
                ]}
                onChangeSort={onChangeSort}
                onChangeOrder={onChangeOrder}
              />
            </div>
          </div>
        </div>
        {filteredBoards.length > 0 || !isLoaded ? (
          <ul className="mt-4 space-y-3">
            {!isLoaded &&
              times(3).map((i) => (
                <li key={i}>
                  <TrelloBoardButton
                    className={Classes.SKELETON}
                    name="Loading…"
                  />
                </li>
              ))}
            {filteredBoards.map((board: TrelloBoard) => (
              <li key={board.id}>
                <TrelloBoardButton {...board} onClick={onClick} />
              </li>
            ))}
          </ul>
        ) : (
          <NoResults className="my-10" />
        )}
      </div>
    </SidebarPanel>
  )
}
