"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function prepareForFirestore(o) {
    return Object.keys(o).reduce((res, key) => {
        if (!!o[key]) {
            res[key] = o[key];
        }
        return res;
    }, {});
}
exports.default = prepareForFirestore;
