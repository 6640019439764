/*---- External -------------------------------------------------------------*/

import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  setCursorModePasteAction,
  setCursorModeDrawRectangleAction,
  setCursorModeDrawLineAction,
  setCursorModeAddStickyAction,
  setCursorModeAddCardAction,
  setCursorModeAddTextAction,
  clearCursorModeAction,
  setCursorModeSelect,
} from '../../../actionCreators/cursorMode'

import { CursorMode } from 'canvas-shared/lib/types/CursorMode.types'

import { CursorModeStateSlice } from '../../../../types/redux'
import {
  pendingCancelAllAction,
  pendingCommitAllAction,
} from '../../../actionCreators/pending'
import { pasteAction } from '../../../actionCreators/clipboard'

/*---------------------------------------------------------------------------*/

const initialState: CursorModeStateSlice = CursorMode.SELECT

const extraReducers = (
  builder: ActionReducerMapBuilder<CursorModeStateSlice>
) => {
  builder
    .addCase(setCursorModePasteAction, (state) => {
      return CursorMode.PASTE
    })
    .addCase(setCursorModeDrawRectangleAction, (state) => {
      return CursorMode.DRAW_RECTANGLE
    })
    .addCase(setCursorModeDrawLineAction, (state) => {
      return CursorMode.DRAW_LINE
    })
    .addCase(setCursorModeAddStickyAction, (state) => {
      return CursorMode.ADD_STICKY
    })
    .addCase(setCursorModeAddCardAction, (state) => {
      return CursorMode.ADD_CARD
    })
    .addCase(setCursorModeAddTextAction, (state) => {
      return CursorMode.ADD_TEXT
    })

    .addCase(clearCursorModeAction, (state) => {
      return CursorMode.SELECT
    })
    .addCase(setCursorModeSelect, () => {
      return CursorMode.SELECT
    })

    // respond to different actions
    .addCase(pendingCancelAllAction, () => {
      return CursorMode.SELECT
    })
    .addCase(pendingCommitAllAction.fulfilled, () => {
      return CursorMode.SELECT
    })
    .addCase(pasteAction.fulfilled, () => {
      return CursorMode.PASTE
    })
}

export const cursorModeSlice = createSlice({
  name: 'cursorMode',
  reducers: {},
  initialState,
  extraReducers,
})
