/*---- External -------------------------------------------------------------*/

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { ItemCard } from '../items/ItemCard'
import { ItemSticky } from '../items/ItemSticky'
import { ItemText } from '../items/ItemText'
import { ItemRectangle } from '../items/ItemRectangle'
import { ItemLine } from '../items/ItemLine'

import { JiraCard } from '../connections/jira/JiraCard'
import { TrelloCard } from '../connections/trello/TrelloCard'
import GoogleSheetsCard from '../connections/google/GoogleSheetsCard'
import { LinearCard } from '../connections/linear/LinearCard'

import CanvasItemReactions from './CanvasItemReactions'

import convertToPx from '../../helpers/convertToPx'
import { addRectDimensionsToPositionIfRequired } from 'canvas-shared/lib/helpers/addRectDimensionsToPositionIfRequired'
import { isVoteableItem } from 'canvas-shared/lib/helpers/itemTypes'

import { useItem } from '../../redux/selectorHooks/board/useItems'
import { useItemPosition } from '../../redux/selectorHooks/board/useItemPosition'
import { useItemIsSelected } from '../../redux/selectorHooks/board/useItemIsSelected'
import { useSearchResults } from '../../redux/selectorHooks/board/useSearchResults'
import { selectZoomScale } from '../../redux/selectors/board/zoom'

import { UUID } from '../../types'

import { Item, ItemType } from 'canvas-shared/lib/types/Item.types'

/*---------------------------------------------------------------------------*/

interface CanvasItemProps {
  itemId: UUID
  pending?: boolean
}

export const CanvasItem: React.FC<CanvasItemProps> = ({ itemId, pending }) => {
  const item = useItem(itemId)
  const position = useItemPosition(itemId)
  const searchResults = useSearchResults()
  const selected = useItemIsSelected(itemId)
  const zoomScale = useSelector(selectZoomScale)

  const style = useMemo(() => {
    if (!item || !position) {
      return {}
    }

    const {
      height,
      left,
      top,
      width,
      z,
    } = addRectDimensionsToPositionIfRequired(position, item)

    const boostZ =
      searchResults.findIndex((r) => r.id === item.id) >= 0 ? 10100 : 0

    return {
      outlineWidth: selected ? 2 * zoomScale : undefined,
      height: convertToPx(height),
      left: 0,
      top: 0,
      transform: `translate(${left}px, ${top}px)`,
      width: convertToPx(width),
      zIndex: z + 10 + boostZ,
    }
  }, [item, position, searchResults, selected, zoomScale])

  if (!item || !position) {
    return null
  }

  return (
    <div
      className={classNames(
        'absolute',
        { 'selected-canvas-item': selected && item.type !== ItemType.Line },
        { 'pointer-events-none': item.type === ItemType.Line },
        { 'opacity-50': pending }
      )}
      style={style}
      id={item.id}
    >
      <CanvasItemInner {...item} pending={pending} />
      {!selected && !pending && isVoteableItem(item) && (
        <CanvasItemReactions
          className="reaction-badges"
          itemClassName="shadow-md"
          item={item}
        />
      )}
    </div>
  )
}

interface CanvasItemInnerProps extends Item.BaseItem {
  pending?: boolean
}

const CanvasItemInner: React.FC<CanvasItemInnerProps> = React.memo(
  ({ pending, ...item }) => {
    switch (item.type) {
      case ItemType.TrelloCard:
        return <TrelloCard {...(item as Item.TrelloCard)} />
      case ItemType.JiraCard:
        return <JiraCard {...(item as Item.JiraCardMaybeMissingData)} />
      case ItemType.GoogleSheetsCard:
        return <GoogleSheetsCard {...(item as Item.GoogleSheetsCard)} />
      case ItemType.LinearCard:
        return <LinearCard {...(item as Item.LinearCardMaybeMissingData)} />
      case ItemType.Card:
        return <ItemCard {...(item as Item.Card)} />
      case ItemType.Sticky:
        return <ItemSticky {...(item as Item.Sticky)} />
      case ItemType.Text:
        return <ItemText {...(item as Item.Text)} pending={pending} />
      case ItemType.Rectangle:
        return <ItemRectangle {...(item as Item.Rectangle)} />
      case ItemType.Line:
        return <ItemLine {...(item as Item.Line)} />
      default:
        return null
    }
  }
)
