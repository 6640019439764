/*---- External -------------------------------------------------------------*/

import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import {
  Icon,
  IMenuItemProps,
  IMenuDividerProps,
  MenuItem,
} from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { colors } from 'canvas-shared/lib/config/colors'

import { NO_MENU_ITEMS } from '../../../constants/menus'

import {
  itemsClearEmojisAction,
  itemsClearVotesAction,
} from '../../../redux/actionCreators/itemData'

import { isVoteableItem } from 'canvas-shared/lib/helpers/itemTypes'

import { Item } from 'canvas-shared/lib/types/Item.types'
import { ColorKey } from 'canvas-shared/lib/types/color.types'
import { updateGroupColorAction } from '../../../redux/actionCreators/dynamicItems'

/*---------------------------------------------------------------------------*/

export const useDataMenuItems = (
  actionItems: Item.BaseItem[],
  actionGroup: Item.CanvasGroupWithMemberData | undefined
): Array<IMenuItemProps | IMenuDividerProps> => {
  const dispatch = useDispatch()

  const groupItems = useMemo(() => {
    if (!!actionGroup) {
      return [
        { title: 'Group' },
        {
          text: 'Group color',
          children: colors.map(({ color, displayName }) => (
            <ColorMenuItem
              actionGroup={actionGroup}
              color={color}
              displayName={displayName}
              key={displayName}
            />
          )),
        },
      ]
    } else {
      return NO_MENU_ITEMS
    }
  }, [actionGroup])

  const hasEmojis = useMemo(
    () =>
      actionItems.some(
        (item) =>
          isVoteableItem(item) &&
          item.data.emoji &&
          Object.values(item.data.emoji).some((e) => (e as string[]).length > 0)
      ),
    [actionItems]
  )
  const hasVotes = useMemo(
    () =>
      actionItems.some(
        (item) => isVoteableItem(item) && (item.data.votes?.length || 0) > 0
      ),
    [actionItems]
  )

  const actionItemIds = useMemo(() => actionItems.map((i) => i.id), [
    actionItems,
  ])

  const handleClearEmojis = useCallback(() => {
    dispatch(itemsClearEmojisAction({ ids: actionItemIds }))
  }, [actionItemIds, dispatch])

  const handleClearVotes = useCallback(() => {
    dispatch(itemsClearVotesAction({ ids: actionItemIds }))
  }, [actionItemIds, dispatch])

  const standardItems: Array<IMenuItemProps | IMenuDividerProps> = useMemo(
    () => [
      { title: 'Reactions' },
      {
        text: 'Clear emojis',
        disabled: !hasEmojis,
        onClick: handleClearEmojis,
      },
      {
        text: 'Clear votes',
        disabled: !hasVotes,
        onClick: handleClearVotes,
      },
    ],
    [handleClearEmojis, handleClearVotes, hasEmojis, hasVotes]
  )

  return useMemo(() => groupItems.concat(standardItems), [
    groupItems,
    standardItems,
  ])
}

interface ColorItemProps {
  actionGroup: Item.CanvasGroupWithMemberData
  color: ColorKey
  displayName: string
}

const ColorMenuItem: React.FC<ColorItemProps> = ({
  actionGroup,
  color,
  displayName,
}) => {
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(updateGroupColorAction({ groupId: actionGroup.id, color }))
  }

  return (
    <MenuItem
      icon={
        <span className={`text-${color}-400`}>
          <Icon icon="full-circle" />
        </span>
      }
      text={displayName}
      onClick={handleClick}
    />
  )
}
