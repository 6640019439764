/*---- External -------------------------------------------------------------*/

import { usePinch, useWheel } from 'react-use-gesture'
import { Vector2 } from 'react-use-gesture/dist/types'

import { useDispatch } from 'react-redux'

import rafSchd from 'raf-schd'

/*---- Qualdesk -------------------------------------------------------------*/

import { useCanvasWindowContext } from '../../contexts/CanvasWindow'

import { useEffect, useRef } from 'react'
import {
  endScrollGestureAction,
  scrollAndZoomByAction,
  scrollByAction,
  startScrollGestureAction,
} from '../../redux/actionCreators/scroll'

/*---------------------------------------------------------------------------*/

const WHEEL_ZOOM_SPEED = 0.008
const PINCH_ZOOM_SPEED = 0.08

export const useWheelEventHandlers = () => {
  const { canvasRef } = useCanvasWindowContext()

  const dispatch = useDispatch()

  const scheduleDispatchScrollAndZoomBy = useRef(
    rafSchd((payload: { zoomDelta: number; pageX?: number; pageY?: number }) =>
      dispatch(scrollAndZoomByAction(payload))
    )
  ).current

  const scheduleDispatchScrollBy = useRef(
    rafSchd((payload: Vector2) => dispatch(scrollByAction(payload)))
  ).current

  usePinch(
    ({ event, intentional, origin, vdva, first, last }) => {
      if (first) {
        dispatch(startScrollGestureAction())
      }

      event.preventDefault()
      if (intentional) {
        scheduleDispatchScrollAndZoomBy({
          zoomDelta: vdva[0] * PINCH_ZOOM_SPEED,
          pageX: origin[0],
          pageY: origin[1],
        })
      }

      if (last) {
        dispatch(endScrollGestureAction())
      }
    },
    {
      domTarget: canvasRef,
      eventOptions: { passive: false },
    }
  )

  useWheel(
    ({ ctrlKey, delta, metaKey, event, intentional, first, last }) => {
      if (first) {
        dispatch(startScrollGestureAction())
      }

      if (intentional) {
        event.preventDefault()

        if (ctrlKey || metaKey) {
          // Zoom
          const { pageX, pageY } = event
          scheduleDispatchScrollAndZoomBy({
            zoomDelta: -delta[1] * WHEEL_ZOOM_SPEED,
            pageX,
            pageY,
          })
        } else {
          // Scroll
          scheduleDispatchScrollBy(delta)
        }
      }

      if (last) {
        dispatch(endScrollGestureAction())
      }
    },
    {
      domTarget: canvasRef,
      eventOptions: { passive: false },
    }
  )

  useEffect(() => {
    document.addEventListener('gesturestart', (e) => e.preventDefault())
    document.addEventListener('gesturechange', (e) => e.preventDefault())

    return () => {
      document.removeEventListener('gesturestart', (e) => e.preventDefault())
      document.removeEventListener('gesturechange', (e) => e.preventDefault())
    }
  }, [])
}
