/*---- External -------------------------------------------------------------*/

import { useMemo } from 'react'

import emojiRegex from 'emoji-regex'
import GraphemeSplitter from 'grapheme-splitter'

import { User } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

interface InitialsProps {
  iconWidth?: number
  name: string
}

const splitter = new GraphemeSplitter()

export const Initials: React.FC<Partial<InitialsProps>> = ({
  iconWidth,
  name,
}) => {
  const trimmedName = name?.trim()

  if (trimmedName) {
    return <TextInitials name={trimmedName} />
  } else {
    return <User weight="fill" width={iconWidth} />
  }
}

export const TextInitials: React.FC<InitialsProps> = ({ name }) => {
  const isEmoji = useMemo(() => emojiRegex().test(name), [name])
  const graphemes = useMemo(() => splitter.splitGraphemes(name), [name])

  if (isEmoji) {
    return <>{graphemes[0]}</>
  } else {
    return <>{graphemes[0].toLocaleUpperCase()}</>
  }
}
