/*---- External -------------------------------------------------------------*/

import { IconProps } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

export interface WorkspaceActionProps {
  iconElement?: JSX.Element
  phosphorIcon?: React.ForwardRefExoticComponent<IconProps>
  text: string
  onClick: React.MouseEventHandler
}

export enum WorkspaceOrder {
  Updated = 'updated',
  Created = 'created',
}

export enum WorkspaceSort {
  Asc = 'asc',
  Desc = 'desc',
}

export interface WorkspaceEmptyState {
  title: string
  description: string
}
