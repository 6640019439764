/*---- External -------------------------------------------------------------*/

import { Location } from 'history'
import { matchPath } from 'react-router-dom'

import { Epic, ofType } from 'redux-observable'
import { map } from 'rxjs/operators'

/*---- Qualdesk -------------------------------------------------------------*/

import { setParamsAction } from '../actionCreators/params'

import { Params, Routes } from '../../router/routes'

import { ActionWithPayload } from '../../types/redux'
import { RootState } from '../../types/redux'
import { LOCATION_CHANGE } from 'redux-first-history'

/*---------------------------------------------------------------------------*/

export const paramsEpic: Epic<
  | ActionWithPayload<{ location: Location }>
  | ActionWithPayload<Params | undefined>,
  ActionWithPayload<Params | undefined>,
  RootState
> = (action) =>
  action.pipe(
    ofType(LOCATION_CHANGE),
    map((action) => {
      const pathname = (action as ActionWithPayload<{ location: Location }>)
        .payload.location.pathname

      const match = {
        ...matchPath<Params>(pathname, Routes.CANVAS.path),
      }

      return setParamsAction(match?.params)
    })
  )
