/*---- External -------------------------------------------------------------*/

import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import useLocalStorageSettings from '../useLocalStorageSettings'

import { ItemType } from 'canvas-shared/lib/types/Item.types'

import {
  toolsCreateItemAction,
  toolsDrawShapeAction,
} from '../../redux/actionCreators/tools'

/*---------------------------------------------------------------------------*/

interface BoardToolbarHandlers {
  handleAddTextItem: () => Promise<void>
  handleAddRectangleItem: () => Promise<void>
  handleAddLineItem: () => Promise<void>
  handleAddStickyItem: () => Promise<void>
  handleAddCardItem: () => Promise<void>
}

export const useBoardToolbarHandlers = (): BoardToolbarHandlers => {
  const dispatch = useDispatch()

  const { useItemType, useShapeItemType } = useLocalStorageSettings()
  const [, setItemType] = useItemType()
  const [, setShapeItemType] = useShapeItemType()

  const handleAddTextItem = useCallback(async () => {
    dispatch(toolsDrawShapeAction(ItemType.Text))
  }, [dispatch])

  const handleAddRectangleItem = useCallback(async () => {
    setShapeItemType(ItemType.Rectangle)
    dispatch(toolsDrawShapeAction(ItemType.Rectangle))
  }, [dispatch, setShapeItemType])

  const handleAddLineItem = useCallback(async () => {
    setShapeItemType(ItemType.Line)
    dispatch(toolsDrawShapeAction(ItemType.Line))
  }, [dispatch, setShapeItemType])

  const handleAddStickyItem = useCallback(async () => {
    setItemType(ItemType.Sticky)
    dispatch(toolsCreateItemAction(ItemType.Sticky))
  }, [dispatch, setItemType])

  const handleAddCardItem = useCallback(async () => {
    setItemType(ItemType.Card)
    dispatch(toolsCreateItemAction(ItemType.Card))
  }, [dispatch, setItemType])

  return useMemo(
    () => ({
      handleAddTextItem,
      handleAddRectangleItem,
      handleAddLineItem,
      handleAddStickyItem,
      handleAddCardItem,
    }),
    [
      handleAddCardItem,
      handleAddLineItem,
      handleAddRectangleItem,
      handleAddStickyItem,
      handleAddTextItem,
    ]
  )
}
