/*---- External -------------------------------------------------------------*/

import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useBeforeunload } from 'react-beforeunload'

/*---- Qualdesk -------------------------------------------------------------*/

import { focusSetAction } from '../redux/actionCreators/focus'

/*---------------------------------------------------------------------------*/

export const useUserPresence = () => {
  const dispatch = useDispatch()

  const focus = useCallback(() => {
    dispatch(focusSetAction(true))
  }, [dispatch])

  const blur = useCallback(() => {
    dispatch(focusSetAction(false))
  }, [dispatch])

  const handleUserPresence = useCallback(() => {
    focus()

    return () => {
      blur()
    }
  }, [focus, blur])

  const manageUserPresenceListeners = useCallback(() => {
    window.addEventListener('focus', focus)
    window.addEventListener('blur', blur)

    return () => {
      window.removeEventListener('focus', focus)
      window.removeEventListener('blur', blur)
    }
  }, [focus, blur])

  useEffect(handleUserPresence, [handleUserPresence])
  useEffect(manageUserPresenceListeners, [manageUserPresenceListeners])

  useBeforeunload(() => {
    blur()
  })
}
