import { IMenuItemProps, Menu } from '@blueprintjs/core'

import { Item } from 'canvas-shared/lib/types/Item.types'
import { LinearPriority } from 'canvas-shared/lib/types/connections/Linear.types'
import { UUID } from '../../../../types'

interface ChangeLinearPriorityProps {
  cards: Item.LinearCard[]
  linearSetPriority: (ids: UUID[], priority: LinearPriority) => Promise<void>
}

interface PriorityOption {
  priority: LinearPriority
  name: string
  key: string
}

const PRIORITY_OPTIONS: PriorityOption[] = [
  { priority: LinearPriority.Urgent, name: 'Urgent', key: 'urgent' },
  { priority: LinearPriority.High, name: 'High', key: 'high' },
  { priority: LinearPriority.Medium, name: 'Medium', key: 'medium' },
  { priority: LinearPriority.Low, name: 'Low', key: 'low' },
  { priority: LinearPriority.NoPriority, name: 'None', key: 'nopriority' },
]

export const changeLinearPriority = ({
  cards,
  linearSetPriority,
}: ChangeLinearPriorityProps): IMenuItemProps | null => {
  const changePriority = (priority: LinearPriority) => {
    linearSetPriority(
      cards.map((c) => c.id),
      priority
    )
  }

  return {
    text: 'Priority',
    icon: 'warning-sign',
    children: PRIORITY_OPTIONS.map((option) => (
      <Menu.Item
        key={option.key}
        text={option.name}
        onClick={() => changePriority(option.priority)}
      />
    )),
  }
}
