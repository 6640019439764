/*---- External -------------------------------------------------------------*/

import { Dispatch } from 'react'
import { MaybeElement, IconName } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { ItemPosition } from 'canvas-shared/lib/types/Position.types'
import { UserDetails } from 'canvas-shared/lib/types/user.types'

/*---------------------------------------------------------------------------*/

export type UUID = string

export type PositionMap = {
  [key: string]: ItemPosition
}

export type CanvasMouseMovement = [
  number,
  React.Dispatch<React.SetStateAction<number>>
]

export type DragHandleRefs = {
  [key: string]: HTMLDivElement
}

export type PresenceSelection = {
  userId: UUID
  selectedItemIds: UUID[]
}

export type SignedInUser = {
  email: string
  uid: string
  userDetails: UserDetails
}

export enum ConnectionType {
  Google = 'Google',
  Jira = 'Jira',
  Trello = 'Trello',
}

export interface DynamicToolbarItem {
  active?: boolean
  children?: DynamicToolbarItem[]
  childElement?: JSX.Element
  disabled?: boolean
  displayChildrenAsSubmenu?: boolean
  icon?: IconName | MaybeElement
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  renderChildElementInToolbar?: boolean
  rightIcon?: IconName
  separator?: boolean
  spacerBefore?: boolean
  text?: string
  textInMenu?: string
}

export enum InteractionMode {
  NORMAL = 'Off',
  VOTING = 'Voting',
  ESTIMATION = 'Estimation',
  ESTIMATE_RESOLUTION = 'Resolve Estimates',
}

export type Timer = {
  countDown: boolean
  referenceTime: string
}

export enum EstimateType {
  STORY_POINTS = 'Story points',
}

type EstimateStoryPoints = {
  estimateType: EstimateType.STORY_POINTS
  value: number
}

export type Estimate = EstimateStoryPoints

export type WizardSubmit<WizardState> = (
  state: WizardState,
  setWizardState: Dispatch<Partial<WizardState>>
) => Promise<boolean | string>

export interface WizardStep<WizardState> {
  id: string
  title: string
  handleSubmit?: WizardSubmit<WizardState>
  submitEnabled?: (state: WizardState) => boolean
}

export interface WizardProps {
  done: () => void
  onTitleUpdate: (title: string) => void
  finishButtonText?: string
}

export interface WizardComponentProps extends WizardProps {
  next: () => void
  setState: React.SetStateAction<any>
}

export enum Cursor {
  DEFAULT = 'default',
  COPY = 'copy',
  NOT_ALLOWED = 'not-allowed',
  CROSSHAIR = 'crosshair',
  TEXT = 'text',
}

export enum GenericSort {
  Asc = 'asc',
  Desc = 'desc',
}
