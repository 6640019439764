/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { colors } from 'canvas-shared/lib/config/colors'

/*---------------------------------------------------------------------------*/

export const getHexFromColorName = (color: string) =>
  (colors.find((c) => c.color === color) || {}).hex
