"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BoardFeatureId = exports.BoardType = void 0;
var BoardType;
(function (BoardType) {
    BoardType["SCRUM"] = "scrum";
    BoardType["KANBAN"] = "kanban";
    BoardType["SIMPLE"] = "simple";
})(BoardType = exports.BoardType || (exports.BoardType = {}));
var BoardFeatureId;
(function (BoardFeatureId) {
    BoardFeatureId["Backlog"] = "jsw.agility.backlog";
    BoardFeatureId["Estimation"] = "jsw.agility.estimation";
    BoardFeatureId["Sprints"] = "jsw.agility.sprints";
})(BoardFeatureId = exports.BoardFeatureId || (exports.BoardFeatureId = {}));
