/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { createContainerSpecificFirestoreDataSelector } from '../helpers/containerSpecificSelectors'
import { Invitation } from 'canvas-shared/lib/types/invitation.types'

/*---------------------------------------------------------------------------*/

const getContainerInvitations = createContainerSpecificFirestoreDataSelector(
  'invitations'
)

export const containerInvitationsSelector = createSelector(
  getContainerInvitations,
  (invitationsData) => {
    if (!invitationsData) {
      return undefined
    }

    return Object.keys(invitationsData).reduce((res: Invitation[], id) => {
      if (!!invitationsData[id]) {
        res = res.concat({
          id,
          ...invitationsData[id],
        })
      }

      return res
    }, [])
  }
)
