/*---- External -------------------------------------------------------------*/

import React, { useEffect, useRef, useState } from 'react'

import { Button, Dialog, FileInput, Intent } from '@blueprintjs/core'
import type { IDialogProps } from '@blueprintjs/core'

import ReactCrop, { Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import { ImageSquare } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import { useClassNamesWithDarkMode } from '../../hooks/darkMode/useClassNamesWithDarkMode'

import {
  MIN_USER_PHOTO_DIMENSION,
  USER_PHOTO_INPUT_FORMATS,
} from 'canvas-shared/lib/config/userPhoto'
import { storage } from '../../config/firebase'

import { getCroppedImage } from './getCroppedImage'

/*---------------------------------------------------------------------------*/

interface PictureUploaderDialogProps extends IDialogProps {
  onClose: (event: React.SyntheticEvent<HTMLElement>) => void
}

export interface PictureHandlerProps {
  handleUploadComplete: (
    url: string,
    storagePath: string
  ) => PromiseLike<void> | void
  storagePath: string
  initialFile?: File | undefined
}

export const PictureUploaderDialog: React.FC<
  PictureUploaderDialogProps & PictureHandlerProps
> = ({ handleUploadComplete, storagePath, initialFile, ...dialogProps }) => {
  const [fileName, setFileName] = useState(FILENAME_PLACEHOLDER)
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined)
  const [crop, setCrop] = useState<Crop>(INITIAL_CROP)
  const [saving, setSaving] = useState(false)

  const fileInputRef = useRef<HTMLInputElement>(null)
  const imageRef = useRef<HTMLImageElement>()

  const { classNamesWithDarkMode } = useClassNamesWithDarkMode()

  useEffect(() => {
    imageRef.current = undefined
    setFileName(FILENAME_PLACEHOLDER)
    setFileUrl(undefined)
    setCrop(INITIAL_CROP)
    setSaving(false)
  }, [dialogProps.isOpen])

  useEffect(() => {
    if (initialFile) {
      setFileName(initialFile.name)
      setFileUrl(URL.createObjectURL(initialFile))
    }
  }, [initialFile])

  const handleClose = (e: React.SyntheticEvent<HTMLElement>) => {
    if (!saving) {
      dialogProps.onClose(e)
    }
  }

  const handlePlaceholderClick = () => {
    fileInputRef.current?.click()
  }

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!!e.target.files && !!e.target.files[0]) {
      setFileName(e.target.files[0].name)
      setFileUrl(URL.createObjectURL(e.target.files[0]))
    }
  }

  const handleImageLoad = (img: HTMLImageElement) => {
    imageRef.current = img

    const height = img.width >= img.height ? img.height : img.width
    const width = img.width < img.height ? img.width : img.height
    const x = (img.width - width) / 2
    const y = (img.height - height) / 2

    setCrop({ unit: 'px', aspect: 1, x, y, width, height })
    return false
  }

  const handleSave = async (e: React.MouseEvent<HTMLElement>) => {
    setSaving(true)

    try {
      const blob = await getCroppedImage(imageRef.current, crop)
      const file = new File([blob], fileName)

      const fileRef = storage.ref().child(storagePath)
      await fileRef.put(file, { contentType: 'image/jpeg' })

      const pictureUrl = await fileRef.getDownloadURL()
      await handleUploadComplete(pictureUrl, storagePath)
    } catch {
      setSaving(false)
    }

    handleClose(e)
  }

  const fileInputProps = {
    accept: USER_PHOTO_INPUT_FORMATS,
    ref: fileInputRef,
  }

  return (
    <Dialog
      {...dialogProps}
      onClose={handleClose}
      className={classNamesWithDarkMode('p-6 flex flex-col space-y-4 w-auto')}
    >
      <div className="w-70 flex flex-col space-y-4">
        <FileInput
          inputProps={fileInputProps}
          onInputChange={handleFile}
          text={fileName}
        />
        <div className="h-70 bg-background-one-light dark:bg-background-one-dark flex flex-col">
          {fileUrl ? (
            <ReactCrop
              src={fileUrl}
              crop={crop}
              minHeight={MIN_USER_PHOTO_DIMENSION}
              minWidth={MIN_USER_PHOTO_DIMENSION}
              onChange={setCrop}
              onImageLoaded={handleImageLoad}
            />
          ) : (
            <div
              className="flex flex-col flex-grow items-center justify-center"
              onClick={handlePlaceholderClick}
              role="button"
            >
              <ImageSquare className="text-icon-secondary-light dark:text-icon-secondary-dark w-24 h-24" />
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-row items-center justify-between">
        <Button
          disabled={saving}
          onClick={dialogProps.onClose}
          minimal
          text="Cancel"
        />
        <Button
          disabled={!fileUrl}
          intent={Intent.PRIMARY}
          loading={saving}
          onClick={handleSave}
          text="Save"
        />
      </div>
    </Dialog>
  )
}

const FILENAME_PLACEHOLDER = 'Choose picture...'
const INITIAL_CROP = {
  aspect: 1,
}
