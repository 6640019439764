/*---- External -------------------------------------------------------------*/

import React from 'react'

import { TextT, Note, Article, Rectangle, IconProps } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  HOTKEY_CARD,
  HOTKEY_LINE,
  HOTKEY_RECTANGLE,
  HOTKEY_STICKY,
  HOTKEY_TEXT,
} from './hotkeys'

import { ReactComponent as Line } from '../assets/icons/line.svg'

import {
  DEFAULT_CARD_WIDTH,
  DEFAULT_CARD_HEIGHT,
} from 'canvas-shared/lib/config/positions'

import {
  ItemType,
  CreateableItemType,
} from 'canvas-shared/lib/types/Item.types'
import { CursorMode } from 'canvas-shared/lib/types/CursorMode.types'

/*---------------------------------------------------------------------------*/

interface ItemConfiguration {
  icon?: React.FC<IconProps>
  text: string
  hideFromNewItemMenu?: true
  canAddMultiple?: true
  defaultWidth?: number
  defaultHeight?: number
  hotkey?: string
  cursorMode?: CursorMode
}

export const ItemConfigurations: Record<ItemType, ItemConfiguration> = {
  Text: {
    text: 'Text',
    icon: (props) => <TextT {...props} />,
    defaultWidth: 400,
    defaultHeight: 36,
    hideFromNewItemMenu: true,
    hotkey: HOTKEY_TEXT,
    cursorMode: CursorMode.ADD_TEXT,
  },
  Sticky: {
    text: 'Sticky',
    icon: (props) => <Note {...props} />,
    canAddMultiple: true,
    defaultWidth: 220,
    defaultHeight: 220,
    hotkey: HOTKEY_STICKY,
    cursorMode: CursorMode.ADD_STICKY,
  },
  Card: {
    text: 'Card',
    icon: (props) => <Article {...props} />,
    canAddMultiple: true,
    defaultWidth: DEFAULT_CARD_WIDTH,
    defaultHeight: DEFAULT_CARD_HEIGHT,
    hotkey: HOTKEY_CARD,
    cursorMode: CursorMode.ADD_CARD,
  },
  Group: {
    text: 'Group',
    hideFromNewItemMenu: true,
  },
  Rectangle: {
    text: 'Rectangle',
    icon: (props) => <Rectangle {...props} />,
    hideFromNewItemMenu: true,
    hotkey: HOTKEY_RECTANGLE,
    cursorMode: CursorMode.DRAW_RECTANGLE,
  },
  Line: {
    text: 'Line',
    icon: (props: IconProps) => <Line {...props} />,
    hideFromNewItemMenu: true,
    hotkey: HOTKEY_LINE,
    cursorMode: CursorMode.DRAW_LINE,
  },
  JiraCard: {
    text: 'Jira issue',
    hideFromNewItemMenu: true,
  },
  TrelloCard: {
    text: 'Trello card',
    hideFromNewItemMenu: true,
  },
  GoogleSheetsCard: {
    text: 'Google Sheets card',
    hideFromNewItemMenu: true,
  },
  LinearCard: {
    text: 'Linear card',
    hideFromNewItemMenu: true,
  },
}

export const DEFAULT_MULTIPLE_ITEM_TYPE = 'Card'

export const itemTypesForMenu = Object.keys(ItemConfigurations).filter(
  (t) => !ItemConfigurations[t as ItemType].hideFromNewItemMenu
) as CreateableItemType[]

export const itemTypesForMultiple = Object.keys(ItemConfigurations).filter(
  (t) => ItemConfigurations[t as ItemType].canAddMultiple
) as CreateableItemType[]
