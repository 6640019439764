/*---- External -------------------------------------------------------------*/

import React, { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'

/*---- Qualdesk -------------------------------------------------------------*/

import { Routes } from '../../router/routes'

/*---------------------------------------------------------------------------*/

const JiraCallback: React.FC = () => {
  const { search } = useLocation()
  const { replace } = useHistory()
  const { next } = queryString.parse(search)

  const redirectOnLoad = () => {
    if (next && next !== '' && typeof next === 'string') {
      replace(next)
    } else {
      replace(Routes.HOME.path)
    }
  }
  useEffect(redirectOnLoad, [next, replace])

  return <>Please wait...</>
}

export default JiraCallback
