/*---- External -------------------------------------------------------------*/

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import classNames from 'classnames'

import {
  FormGroup,
  InputGroup,
  Button,
  Intent,
  Spinner,
} from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { functions } from '../../../config/firebase'

import { selectMyEmail } from '../../../redux/selectors/auth/auth'
import { useUserConnectionsActions } from '../../../redux/actions/auth/useUserConnectionsActions'
import { useStatusReporter } from '../../../hooks/useStatusReporter'

import { AuthorizationIntro } from '../AuthorizationIntro'
import { ControlledPanelProps } from '../SidebarPanelStack'

import jiraIcon from '../../../assets/integrations/jira/jira-icon.svg'

import { UserConnectionType } from 'canvas-shared/lib/types/UserConnection.types'

/*---------------------------------------------------------------------------*/

const verifyConnection = functions.httpsCallable('https-jiraVerifyConnection')

export const JiraAuthorization: React.FC<ControlledPanelProps> = ({
  closePanel,
}) => {
  const { addConnection } = useUserConnectionsActions()
  const email = useSelector(selectMyEmail)

  const [jiraEmail, setEmail] = useState(email)
  const [jiraDomain, setDomain] = useState<string>()
  const [jiraToken, setToken] = useState<string>()
  const [submitEnabled, setEnabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const { reportError } = useStatusReporter()

  useEffect(() => {
    const tokenPresent = !!jiraToken && jiraToken.length > 0
    const emailPresent = !!jiraEmail && jiraEmail.length > 0
    const domainPresent = !!jiraDomain && jiraDomain.length > 0

    setEnabled(tokenPresent && emailPresent && domainPresent)
  }, [jiraEmail, jiraDomain, jiraToken])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!!jiraToken && !!jiraEmail && !!jiraDomain) {
      setLoading(true)

      try {
        await addConnection({
          type: UserConnectionType.Jira,
          token: jiraToken,
          email: jiraEmail,
          domain: jiraDomain,
          connected: false,
        })

        const {
          data: { verified, error },
        } = await verifyConnection()

        if (!verified) {
          throw new Error(error)
        }
      } catch (error) {
        setLoading(false)
        reportError(
          error,
          'Oops, that didn’t work - please check your details and try again',
          { timeout: 10000 }
        )
      }
    }
  }

  return (
    <form className="flex flex-col h-full" onSubmit={handleSubmit}>
      <div className="flex-grow overflow-y-auto">
        <div className="bg-background-four-light dark:bg-background-four-dark border-border-weak-light dark:border-border-weak-dark px-6 py-8 border-b">
          <AuthorizationIntro
            title="Connect to Jira"
            description="Follow these steps to allow Qualdesk to access Jira on your behalf"
            iconUrl={jiraIcon}
            iconAltText="Jira icon"
          />
        </div>
        <div className="p-6">
          <ol className="text-sm leading-normal list-inside list-decimal space-y-6">
            <li>
              <strong className="text-text-primary-light dark:text-text-primary-dark">
                Log in to your{' '}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://id.atlassian.com/manage-profile/security/api-tokens"
                  className="text-link-light dark:text-link-dark underline"
                >
                  Jira account
                </a>
              </strong>
              <div className="text-text-secondary-light dark:text-text-secondary-dark">
                You will be redirected to the Jira{' '}
                <em className="whitespace-nowrap">API Tokens</em> page after
                log-in
              </div>
            </li>
            <li>
              <strong className="text-text-primary-light dark:text-text-primary-dark">
                Create an API token
              </strong>
              <div className="text-text-secondary-light dark:text-text-secondary-dark">
                Click <em>‘Create API token’</em>, choose a label, then click{' '}
                <em>‘Copy’</em>
              </div>
              <FormGroup
                className="mt-2"
                helperText="Paste the token on your clipboard here"
                label="API token"
                labelFor="token"
                labelInfo="(required)"
                disabled={loading}
              >
                <InputGroup
                  id="token"
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    setToken(e.currentTarget.value)
                  }
                  value={jiraToken}
                  disabled={loading}
                />
              </FormGroup>
            </li>
            <li>
              <strong className="text-text-primary-light dark:text-text-primary-dark">
                Provide your Jira details
              </strong>
              <FormGroup
                className="mt-2"
                helperText="The email you use to access Jira"
                label="Email"
                labelFor="email"
                labelInfo="(required)"
                disabled={loading}
              >
                <InputGroup
                  id="email"
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    setEmail(e.currentTarget.value)
                  }
                  value={jiraEmail}
                  disabled={loading}
                />
              </FormGroup>
              <FormGroup
                helperText="The domain you use to access Jira"
                label="Atlassian domain"
                labelFor="domain"
                labelInfo="(required)"
                disabled={loading}
              >
                <InputGroup
                  id="domain"
                  className="text-right"
                  placeholder="qualdesk"
                  rightElement={
                    <div className="text-text-secondary-light dark:text-text-secondary-dark mt-1 pr-2 leading-normal">
                      .atlassian.net
                    </div>
                  }
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    setDomain(e.currentTarget.value)
                  }
                  value={jiraDomain}
                  disabled={loading}
                />
              </FormGroup>
            </li>
          </ol>
        </div>
      </div>
      <footer
        className={classNames(
          'border-border-weak-light dark:border-border-weak-dark flex items-center justify-center px-6 py-2 h-16 border-t space-x-4',
          {
            'bg-background-four-light dark:bg-background-four-dark': loading,
            'bg-background-five-light dark:bg-background-three-dark': !loading,
          }
        )}
      >
        {loading ? (
          <div className="flex items-center space-x-2">
            <Spinner size={22} />
            <div className="text-text-secondary-light dark:text-text-secondary-dark">
              Verifying your details…
            </div>
          </div>
        ) : (
          <>
            <Button
              className="hover:bg-button-secondary-hovered-light dark:hover:bg-button-secondary-hovered-dark active:bg-button-secondary-active-light dark:active:bg-button-secondary-active-dark"
              onClick={closePanel}
              fill
              minimal
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              fill
              disabled={!submitEnabled}
              intent={Intent.PRIMARY}
              type="submit"
            >
              Connect
            </Button>
          </>
        )}
      </footer>
    </form>
  )
}
