/*---- External -------------------------------------------------------------*/

import { useCallback, useMemo } from 'react'

import { useFirestore } from 'react-redux-firebase'

/*---- Qualdesk -------------------------------------------------------------*/

import { generateItemPath } from '../../../../paths/itemsPaths'

import { useContainerId } from '../../../../../contexts/Container'

import { useItemUpdateActions } from '../../helpers/useItemUpdateActions'

import { MimeTypes } from 'canvas-shared/lib/types/file.types'

import { GoogleBoardConnection } from 'canvas-shared/lib/types/BoardConnection.types'

import { Item, ItemType } from 'canvas-shared/lib/types/Item.types'

/*---------------------------------------------------------------------------*/

interface ItemGoogleSheetsActions {
  convertItemsToGoogleSheetsRow: (
    items: Item.BaseItem[],
    connection: GoogleBoardConnection
  ) => Promise<void>
}

export const useItemGoogleSheetsActions = (): ItemGoogleSheetsActions => {
  const firestore = useFirestore()
  const containerId = useContainerId()

  const { generateItemUpdate } = useItemUpdateActions()

  const convertItemsToGoogleSheetsRow = useCallback(
    async (items: Item.BaseItem[], connection: GoogleBoardConnection) => {
      const { file, options } = connection.google

      if (file.mimeType !== MimeTypes.GOOGLE_SHEETS) {
        return Promise.resolve()
      }

      const sheetOptions = options.sheetOptions[options.selectedSheetId]

      const firstVisibleColumnIndex =
        sheetOptions.columns?.findIndex((c) => c.visible) || 0

      const batch = firestore.batch()

      items.forEach((item) => {
        const ref = firestore.doc(generateItemPath(containerId, item.id))
        batch.update(
          ref,
          generateItemUpdate({
            type: ItemType.GoogleSheetsCard,
            'data.title': firestore.FieldValue.delete(),
            [`data.columns.${firstVisibleColumnIndex}`]: item.data?.title || '',
            'data.google.connectionId': connection.id,
            'data.google.file': file,
            'data.google.options': sheetOptions,
          })
        )
      })

      await batch.commit()
    },
    [containerId, firestore, generateItemUpdate]
  )

  return useMemo(
    () => ({
      convertItemsToGoogleSheetsRow,
    }),
    [convertItemsToGoogleSheetsRow]
  )
}
