/*---- External -------------------------------------------------------------*/

import { intersect, shape } from 'svg-intersections'

/*---- Qualdesk -------------------------------------------------------------*/

import { isRectPosition } from 'canvas-shared/lib/helpers/isRectPosition'
import { isLinePosition } from 'canvas-shared/lib/helpers/isLinePosition'

import type { ItemPosition } from 'canvas-shared/lib/types/Position.types'

/*---------------------------------------------------------------------------*/

export const positionInRect = (
  pos: ItemPosition,
  rect: Record<'startX' | 'startY' | 'endX' | 'endY', number>
): boolean => {
  const { startX, startY, endX, endY } = rect
  const canvasLeft = Math.min(startX, endX)
  const canvasTop = Math.min(startY, endY)
  const canvasRight = Math.max(startX, endX)
  const canvasBottom = Math.max(startY, endY)

  if (isRectPosition(pos)) {
    return !(
      pos.left >= canvasRight ||
      pos.left + pos.width <= canvasLeft ||
      pos.top >= canvasBottom ||
      pos.top + pos.height <= canvasTop
    )
  } else if (isLinePosition(pos)) {
    const lineSVG = shape('line', {
      x1: pos.startX,
      x2: pos.endX,
      y1: pos.startY,
      y2: pos.endY,
    })
    const rectSVG = shape('rect', {
      x: canvasLeft,
      y: canvasTop,
      width: canvasRight - canvasLeft,
      height: canvasBottom - canvasTop,
    })

    const intersections = intersect(lineSVG, rectSVG)
    const intersects = intersections.points.length > 0

    const left = Math.min(pos.startX, pos.endX)
    const right = Math.max(pos.startX, pos.endX)
    const top = Math.min(pos.startY, pos.endY)
    const bottom = Math.max(pos.startY, pos.endY)

    const surrounds =
      left >= canvasLeft &&
      right <= canvasRight &&
      top >= canvasTop &&
      bottom <= canvasBottom

    return intersects || surrounds
  }

  throw new Error(`Expected RectPosition or LinePosition, got '${pos}'.`)
}
