/*---- External -------------------------------------------------------------*/

import React from 'react'
import { Button, IPanelProps } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { TrelloConnectButton } from './TrelloConnectButton'
import { AuthorizationIntro } from '../AuthorizationIntro'

import trelloIcon from '../../../assets/integrations/trello/trello-icon.svg'

/*---------------------------------------------------------------------------*/

export const TrelloAuthorization: React.FC<IPanelProps> = ({
  closePanel,
  openPanel,
}) => (
  <div className="flex flex-col h-full">
    <div className="flex flex-col flex-grow justify-center p-6">
      <AuthorizationIntro
        title="Connect to Trello"
        description="Sign in to give Qualdesk access to Trello"
        iconUrl={trelloIcon}
        iconAltText="Trello icon"
      />
      <div className="mt-5 text-center">
        <TrelloConnectButton />
      </div>
    </div>
    <footer className="border-border-weak-light dark:border-border-weak-dark flex items-center justify-center px-6 py-2 h-16 border-t space-x-4">
      <Button fill minimal onClick={() => closePanel()}>
        Cancel
      </Button>
    </footer>
  </div>
)
