/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import {
  itemsDatabaseLoadedAction,
  itemsDatabaseRemoveOneAction,
  itemsDatabaseSetOneAction,
} from '../../actionCreators/items'

import { generateItemsPath } from '../../paths/itemsPaths'

import { useFirestoreCollectionConnect } from '../helpers/useFirestoreCollectionConnect'

import { UUID } from 'canvas-shared/lib/types'
import { Item } from 'canvas-shared/lib/types/Item.types'

/*---------------------------------------------------------------------------*/

export const useConnectItems = (containerId: UUID) => {
  useFirestoreCollectionConnect<Item.AnyItem>({
    collectionPath: generateItemsPath(containerId),
    onChildSet: itemsDatabaseSetOneAction,
    onChildRemoved: itemsDatabaseRemoveOneAction,
    onInitialValueLoaded: itemsDatabaseLoadedAction,
  })
}
