/*---- External -------------------------------------------------------------*/

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { mySelectedItemsSelector } from '../../selectors/board/selections'

/*---------------------------------------------------------------------------*/

export const useSelectedItems = () => useSelector(mySelectedItemsSelector)
