/*---- External -------------------------------------------------------------*/

import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { FormGroup, InputGroup, Intent } from '@blueprintjs/core'

import { nanoid } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { functions, storage } from '../../../config/firebase'

import { PictureChooser } from '../../pictureChooser/PictureChooser'
import { TeamIcon } from '../../team/TeamIcon'

import { selectMyUid } from '../../../redux/selectors/auth/auth'

import { tempFilePath } from 'canvas-shared/lib/paths/tempFilePath'
import { teamPicturePath } from 'canvas-shared/lib/paths/teamPicturePath'

import { TeamPanelProps } from '../TeamSettings'

/*---------------------------------------------------------------------------*/

const storeTeamPicture = functions.httpsCallable(
  'https-storageHandleTeamPictureFromTemp'
)

const updateTeam = functions.httpsCallable('https-authUpdateTeam')

export const TeamDetailsPanel: React.FC<TeamPanelProps> = ({
  className,
  queueUpdate,
  team,
}) => {
  const { name } = team

  const [nameValue, setNameValue] = useState(name)

  const uid = useSelector(selectMyUid)

  const updateNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameValue(e.target.value)
  }

  const handleUpdate = () => {
    if (!!nameValue && nameValue !== name) {
      const f = () =>
        updateTeam({ teamId: team.id, update: { name: nameValue } })
      if (queueUpdate) {
        queueUpdate(f)
      } else {
        f()
      }
    }
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    handleUpdate()
  }

  const storagePath = useMemo(() => tempFilePath(uid, team.id, nanoid()), [
    team.id,
    uid,
  ])

  const handlePicture = async (_: string, tempStoragePath: string) => {
    await storeTeamPicture({ tempStoragePath, teamId: team.id })
    const fileRef = storage.ref().child(teamPicturePath(team.id))
    const pictureUrl = await fileRef.getDownloadURL()
    await updateTeam({ teamId: team.id, update: { pictureUrl } })
  }

  const deletePicture = async () => {
    await updateTeam({ teamId: team.id, update: { pictureUrl: null } })
  }

  return (
    <div className={className}>
      <div className="flex flex-col space-y-4">
        <form onSubmit={handleSubmit}>
          <FormGroup
            className="m-0"
            label={<div className="mb-1 font-semibold">Team name</div>}
            labelFor="team-name-input"
          >
            <InputGroup
              id="team-name-input"
              intent={!!nameValue ? Intent.NONE : Intent.WARNING}
              large
              onChange={updateNameInput}
              onBlur={handleSubmit}
              placeholder="Team name is required"
              value={nameValue}
            />
          </FormGroup>
        </form>
        <div className="pt-4">
          <PictureChooser
            deletePicture={deletePicture}
            description="Choose a picture to represent your team"
            handleUploadComplete={handlePicture}
            renderPicture={
              <TeamIcon className="w-16 h-16" hideTooltip teamId={team.id} />
            }
            storagePath={storagePath}
            title="Team picture"
          />
        </div>
      </div>
    </div>
  )
}
