/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { RootState } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

const getPendingItems = (state: RootState) => state.board.pending.items

const getPendingPositions = (state: RootState) => state.board.pending.positions

export const selectPendingItems = createSelector(getPendingItems, Object.values)
export const selectPendingPositions = createSelector(
  getPendingPositions,
  Object.values
)

export const selectPendingItemIds = createSelector(
  selectPendingItems,
  (items) => items.map((i) => i.id)
)

export const createSelectPendingItem = (itemId: string) =>
  createSelector(selectPendingItems, (items) =>
    items.find((p) => p.id === itemId)
  )

export const createSelectPendingPosition = (itemId: string) =>
  createSelector(selectPendingPositions, (positions) =>
    positions.find((p) => p.id === itemId)
  )
