/*---- External -------------------------------------------------------------*/

import { useCallback, useMemo } from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

export function usePartialMatchFilter<T>(
  array: T[],
  match: string,
  valueGetter: (element: T) => string
) {
  const lowerMatch = useMemo(() => match.toLocaleLowerCase(), [match])

  const includesMatch = useCallback(
    (element) => valueGetter(element).toLocaleLowerCase().includes(lowerMatch),
    [valueGetter, lowerMatch]
  )

  const minMatchIndex = useCallback(
    (a, b) =>
      valueGetter(a).toLocaleLowerCase().indexOf(lowerMatch) -
      valueGetter(b).toLocaleLowerCase().indexOf(lowerMatch),
    [valueGetter, lowerMatch]
  )

  return useMemo(() => {
    if (lowerMatch.length > 0) {
      return array.filter(includesMatch).sort(minMatchIndex)
    } else {
      return array
    }
  }, [array, lowerMatch, includesMatch, minMatchIndex])
}
