"use strict";
/*---- External -------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.GestureMode = void 0;
var GestureMode;
(function (GestureMode) {
    GestureMode["DEFAULT"] = "DEFAULT";
    GestureMode["DRAW"] = "DRAW";
    GestureMode["MOVE"] = "MOVE";
    GestureMode["DUPLICATE"] = "DUPLICATE";
    GestureMode["RESIZE"] = "RESIZE";
    GestureMode["DRAG_SELECT"] = "DRAG_SELECT";
})(GestureMode = exports.GestureMode || (exports.GestureMode = {}));
