"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cleanEmail = void 0;
const withDisplayName = new RegExp(/<([^@<>]+@[^@<>]+\.[^@<>]+)>/);
const cleanEmail = (emailWithOptionalDisplayName) => {
    const extractedFromEmailWithDisplayName = withDisplayName.exec(emailWithOptionalDisplayName);
    if (!!extractedFromEmailWithDisplayName) {
        return extractedFromEmailWithDisplayName[1];
    }
    else {
        return emailWithOptionalDisplayName;
    }
};
exports.cleanEmail = cleanEmail;
