/*---- External -------------------------------------------------------------*/

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useFirestoreConnect } from 'react-redux-firebase'

/*---- Qualdesk -------------------------------------------------------------*/

import { firestoreQueryFromPath } from './helpers/firestoreQueryFromPath'
import { useOrgConnector } from './org/useOrgConnector'

import { containersPath } from 'canvas-shared/lib/paths/containersPath'
import { teamsPath } from 'canvas-shared/lib/paths/teamsPath'
import {
  usersPath,
  generateUserConnectionsPath,
} from '../paths/usersPaths'

import { selectMyUid, selectSignedIn } from '../selectors/auth/auth'

import { teamMembershipIdsSelector } from '../selectors/auth/teams'

import { PermissionLevel } from 'canvas-shared/lib/types/permissions.types'

/*---------------------------------------------------------------------------*/

export const useAuthConnectors = () => {
  const uid = useSelector(selectMyUid)
  const authenticated = useSelector(selectSignedIn)
  const teamMembershipIds = useSelector(teamMembershipIdsSelector)

  const boardsByUidQuery = useMemo(
    () =>
      firestoreQueryFromPath(
        containersPath,
        [`roles.users.${uid}`, '>=', PermissionLevel.READ],
        () => 'containersByUid'
      ),
    [uid]
  )
  const boardsByTeamMembershipQuery = useMemo(
    () =>
      firestoreQueryFromPath(
        containersPath,
        [`roles.teams.membership`, 'in', teamMembershipIds],
        () => 'containersByTeamMembership'
      ),
    [teamMembershipIds]
  )
  const userConnectionsQuery = useMemo(
    () => firestoreQueryFromPath(generateUserConnectionsPath(uid)),
    [uid]
  )
  const teamEveryoneQuery = useMemo(
    () =>
      firestoreQueryFromPath(
        teamsPath,
        ['everyone', '==', true],
        () => 'teamsEveryone'
      ),
    []
  )
  const teamsByInvitationQuery = useMemo(
    () =>
      firestoreQueryFromPath(
        teamsPath,
        [`roles.users.${uid}`, '>=', PermissionLevel.READ],
        () => 'teamsByInvitation'
      ),
    [uid]
  )

  const usersQuery = useMemo(() => firestoreQueryFromPath(usersPath), [])

  const queries = []

  if (authenticated) {
    queries.push(
      usersQuery,
      boardsByUidQuery,
      userConnectionsQuery,
      teamEveryoneQuery,
      teamsByInvitationQuery
    )
    if (teamMembershipIds.length > 0) {
      queries.push(boardsByTeamMembershipQuery)
    }
  }

  useFirestoreConnect(queries)
  useOrgConnector()
}
