"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CursorModesWithStickyPendingItems = exports.CursorMode = void 0;
var CursorMode;
(function (CursorMode) {
    CursorMode[CursorMode["SELECT"] = 0] = "SELECT";
    CursorMode[CursorMode["DUPLICATE"] = 1] = "DUPLICATE";
    CursorMode[CursorMode["ATTEMPTING_DUPLICATE"] = 2] = "ATTEMPTING_DUPLICATE";
    CursorMode[CursorMode["PASTE"] = 3] = "PASTE";
    CursorMode[CursorMode["DRAW_RECTANGLE"] = 4] = "DRAW_RECTANGLE";
    CursorMode[CursorMode["DRAW_LINE"] = 5] = "DRAW_LINE";
    CursorMode[CursorMode["ADD_STICKY"] = 6] = "ADD_STICKY";
    CursorMode[CursorMode["ADD_CARD"] = 7] = "ADD_CARD";
    CursorMode[CursorMode["ADD_TEXT"] = 8] = "ADD_TEXT";
})(CursorMode = exports.CursorMode || (exports.CursorMode = {}));
exports.CursorModesWithStickyPendingItems = [
    CursorMode.ADD_CARD,
    CursorMode.ADD_STICKY,
    CursorMode.PASTE,
];
