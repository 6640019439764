/*---- External -------------------------------------------------------------*/

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirestore } from 'react-redux-firebase'

import { Spinner } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { useContainerId } from '../../../contexts/Container'

import googleSheetsIcon from '../../../assets/integrations/google/google-sheets-icon.svg'

import { generateOperation } from '../../../redux/actions/helpers/generateOperation'
import { selectMyUid } from '../../../redux/selectors/auth/auth'

import { ControlledPanelProps } from '../SidebarPanelStack'
import { GoogleChooseData } from './GoogleChooseData'

import { containerConnectionPath } from 'canvas-shared/lib/paths/containerConnectionPath'

import { GoogleSheetsBoardConnection } from 'canvas-shared/lib/types/BoardConnection.types'

/*---------------------------------------------------------------------------*/

interface GoogleSheetsRecentConnectionProps {
  recentConnection: GoogleSheetsBoardConnection
  openPanel: ControlledPanelProps['openPanel']
}

export const GoogleSheetsRecentConnection: React.FC<GoogleSheetsRecentConnectionProps> = ({
  recentConnection,
  openPanel,
}) => {
  const containerId = useContainerId()
  const uid = useSelector(selectMyUid)
  const firestore = useFirestore()

  const [loading, setLoading] = useState(false)

  const {
    google: {
      file: { fileId, name },
      options,
    },
  } = recentConnection

  const sheetName =
    options.sheetOptions[options.selectedSheetId]?.sheetName || ''

  const operation = generateOperation(uid)

  const timestamp = {
    operation,
    updatedBy: operation,
    updatedAt: firestore.FieldValue.serverTimestamp(),
  }

  const handleClick = async () => {
    setLoading(true)
    await firestore
      .doc(containerConnectionPath(containerId, recentConnection.id))
      .update({
        'google.options.selectedSheetId': options.selectedSheetId,
        ...timestamp,
      })
    openPanel({
      component: GoogleChooseData,
      props: {
        fileId,
        connectionId: recentConnection.id,
        name,
      },
    })
  }

  return (
    <div
      className="hover:bg-button-secondary-hovered-light dark:hover:bg-button-secondary-hovered-dark active:bg-button-secondary-active-light dark:active:bg-button-secondary-active-dark flex items-center p-3 rounded-sm"
      onClick={handleClick}
      role="button"
    >
      <div className="flex flex-col space-y-1">
        <div>{name}</div>
        <div className="text-text-secondary-light dark:text-text-secondary-dark text-xs">
          {sheetName}
        </div>
      </div>
      {loading ? (
        <Spinner className="ml-auto" size={20} />
      ) : (
        <img
          alt="Google Sheets icon"
          className="ml-auto w-5 h-5"
          src={googleSheetsIcon}
        />
      )}
    </div>
  )
}
