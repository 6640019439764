/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { functions } from '../../../../config/firebase'

import { Urls } from '../../../../router/routes'

import { sendSuperficialError } from '../../../../helpers/sendSuperficialError'

/*---------------------------------------------------------------------------*/

const generateAuthUrl = functions.httpsCallable('https-googleGenerateAuthUrl')

export const redirectToGoogleAuth = async () => {
  const state = {
    redirectPath: window.location.pathname,
  }

  try {
    const {
      data: { url },
    } = await generateAuthUrl({
      callbackUrl: Urls.GOOGLE_CALLBACK,
      state,
    })
    window.location.href = url
  } catch (error) {
    const message = 'Failed to redirect to Google'
    sendSuperficialError(error, message)
    return message
  }

  return false
}
