/*---- External -------------------------------------------------------------*/

import React, { useCallback } from 'react'
import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { JiraPreviewCardBody } from './jiraPreviewCard/JiraPreviewCardBody'
import { PreviewCardAddedToBoard } from '../PreviewCardAddedToBoard'

import { safeString } from '../../../helpers/safeString'

import { PreviewItemWithBoardPresence } from 'canvas-shared/lib/types/PreviewItem.types'

/*---------------------------------------------------------------------------*/

interface Props {
  issue: PreviewItemWithBoardPresence.JiraCard
  checked: boolean
  onChange: (value: PreviewItemWithBoardPresence.JiraCard) => void
}

export const JiraPreviewCard: React.FC<Props> = ({
  issue,
  checked,
  onChange,
}) => {
  const { data: jiraData, presentOnBoard } = issue
  const title = safeString(jiraData.title)
  const onClick = useCallback(() => {
    onChange(issue)
  }, [issue, onChange])

  const onKeyPress = useCallback(
    (event) => {
      if (event.code === 'Space') {
        onChange(issue)
        event.preventDefault()
      }
    },
    [issue, onChange]
  )

  return (
    <div
      className={classNames(
        'border-border-weak-light dark:border-border-weak-dark p-3 border rounded',
        {
          'hover:border-border-normal-light dark:hover:border-border-normal-dark':
            !checked && !presentOnBoard,
          'bg-button-secondary-selected-light dark:bg-button-secondary-selected-dark border-border-accent-light dark:border-border-accent-dark': checked,
          'active:bg-button-secondary-selected-light dark:active:bg-button-secondary-selected-dark cursor-pointer': !presentOnBoard,
          'pointer-events-none cursor-not-allowed': presentOnBoard,
        }
      )}
      tabIndex={0}
      aria-checked={checked}
      aria-labelledby={issue.id}
      aria-disabled={presentOnBoard}
      onClick={onClick}
      onKeyPress={onKeyPress}
    >
      <h3
        id={issue.id}
        className={classNames(
          'text-text-primary-light dark:text-text-primary-dark mb-3 text-sm',
          {
            'text-text-disabled-light dark:text-text-disabled-dark text-opacity-60': presentOnBoard,
          }
        )}
      >
        {title}
      </h3>
      {presentOnBoard ? (
        <PreviewCardAddedToBoard />
      ) : (
        <JiraPreviewCardBody jiraData={jiraData} />
      )}
    </div>
  )
}
