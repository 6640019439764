/*---- External -------------------------------------------------------------*/

import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { UUID } from '../../../types'
import {
  selectionAddAction,
  selectionClearAction,
  selectionRemoveAction,
  selectionSelectAllAction,
  selectionSetAction,
  selectionToggleAction,
} from '../../actionCreators/selection'

/*---------------------------------------------------------------------------*/

interface SelectionActions {
  selectionSet: (itemIds: UUID[]) => void
  selectionAdd: (itemId: UUID) => void
  selectionClear: () => void
  selectionRemove: (itemId: UUID) => void
  selectionToggle: (itemIds: UUID[]) => void
  selectAll: () => void
}

export const useSelectionActions = (): SelectionActions => {
  const dispatch = useDispatch()

  const selectionSet = useCallback(
    (itemIds: UUID[]) => dispatch(selectionSetAction(itemIds)),
    [dispatch]
  )
  const selectionAdd = useCallback(
    (itemId: UUID) => dispatch(selectionAddAction(itemId)),
    [dispatch]
  )
  const selectionClear = useCallback(() => dispatch(selectionClearAction()), [
    dispatch,
  ])
  const selectionRemove = useCallback(
    (itemId: UUID) => dispatch(selectionRemoveAction(itemId)),
    [dispatch]
  )
  const selectionToggle = useCallback(
    (itemIds: UUID[]) => dispatch(selectionToggleAction(itemIds)),
    [dispatch]
  )
  const selectAll = useCallback(() => dispatch(selectionSelectAllAction()), [
    dispatch,
  ])

  return useMemo(
    () => ({
      selectionSet,
      selectionAdd,
      selectionClear,
      selectionRemove,
      selectionToggle,
      selectAll,
    }),
    [
      selectAll,
      selectionAdd,
      selectionClear,
      selectionRemove,
      selectionSet,
      selectionToggle,
    ]
  )
}
