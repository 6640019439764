/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { isLinePosition } from 'canvas-shared/lib/helpers/isLinePosition'
import { isRectPosition } from 'canvas-shared/lib/helpers/isRectPosition'
import type { ItemPosition } from 'canvas-shared/lib/types/Position.types'

/*---------------------------------------------------------------------------*/

export const offsetPosition = (
  position: ItemPosition,
  offset = DUPLICATION_POSITION_OFFSET
) => {
  if (isRectPosition(position)) {
    return {
      left: position.left + offset,
      top: position.top + offset,
    }
  } else if (isLinePosition(position)) {
    return {
      startX: position.startX + offset,
      startY: position.startY + offset,
      endX: position.endX + offset,
      endY: position.endY + offset,
    }
  } else {
    return {}
  }
}

const DUPLICATION_POSITION_OFFSET = 50
