/*---- External -------------------------------------------------------------*/

import React from 'react'

import { Provider as ReduxProvider } from 'react-redux'

import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'

import { Switch, Router } from 'react-router-dom'

import { FocusStyleManager } from '@blueprintjs/core'

import { DndProvider } from 'react-dnd'
import { TouchBackend } from 'react-dnd-touch-backend'

import { Helmet, HelmetProvider } from 'react-helmet-async'

/*---- Qualdesk -------------------------------------------------------------*/

import { app } from './config/firebase'
import { history, store } from './redux/store'

import { AreYouSureProvider } from './contexts/AreYouSure'

import { routeList } from './router/routes'
import VerifiedRoute from './router/VerifiedRoute'

import { AppToaster } from './components/utilities/AppToaster'
import { BrowserWarning } from './components/browserWarning/BrowserWarning'

import NoAccess from './pages/auth/NoAccess'

import { useAuthConnectors } from './redux/connectors/useAuthConnectors'
import { useSystemConnectors } from './redux/connectors/useSystemConnectors'

import { useBrowserCheck } from './hooks/system/useBrowserCheck'
import { useDarkModeToggle } from './hooks/darkMode/useDarkModeToggle'
import { useClassNamesWithDarkMode } from './hooks/darkMode/useClassNamesWithDarkMode'
import { useSystemUpdateNotifications } from './hooks/system/useSystemUpdateNotifications'
import { useWindowKeyboardInteractions } from './hooks/window/useWindowKeyboardInteractions'
import { useConnectionMonitor } from './hooks/connections/useConnectionMonitor'

import { StyleLoader } from './styles/loader/Loader'

import './index.css'

import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/800.css'

/*---------------------------------------------------------------------------*/

FocusStyleManager.onlyShowFocusOnTabs()

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
}

const rrfProps = {
  firebase: app,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
}

const App: React.FC = () => {
  const browserOK = useBrowserCheck()

  if (!browserOK) {
    return <BrowserWarning />
  }

  return (
    <HelmetProvider>
      <StyleLoader />
      <Helmet defaultTitle="Qualdesk" titleTemplate="%s — Qualdesk" />
      <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
        <ReduxProvider store={store}>
          <Router history={history}>
            <ReactReduxFirebaseProvider {...rrfProps}>
              <AreYouSureProvider>
                <ConnectedApp />
              </AreYouSureProvider>
            </ReactReduxFirebaseProvider>
          </Router>
        </ReduxProvider>
      </DndProvider>
    </HelmetProvider>
  )
}

const ConnectedApp: React.FC = () => {
  useSystemConnectors()
  useAuthConnectors()
  useSystemUpdateNotifications()
  useConnectionMonitor()

  useWindowKeyboardInteractions()

  useDarkModeToggle()

  const { classNamesWithDarkMode } = useClassNamesWithDarkMode()

  return (
    <>
      <AppToaster />
      <div className={classNamesWithDarkMode('flex flex-col h-full')}>
        <Switch>
          {routeList.map((route) => (
            <VerifiedRoute {...route} key={`${route.path}`} />
          ))}
          <NoAccess />
        </Switch>
      </div>
    </>
  )
}

export default App
