/*---- External -------------------------------------------------------------*/

import React, { ClipboardEvent } from 'react'
import { useSelector } from 'react-redux'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { selectUserConnections } from '../../redux/selectors/auth/userConnections'

import { SidebarPanelStack } from './SidebarPanelStack'
import { ConnectionList } from './ConnectionList'
import { Onboarding } from './Onboarding'

/*---------------------------------------------------------------------------*/

const stopPropagation = (e: ClipboardEvent) => {
  e.stopPropagation()
}

export const Sidebar: React.FC = () => {
  const userConnections = useSelector(selectUserConnections)

  return (
    <div
      className={classNames(
        'bg-background-five-light dark:bg-background-five-dark border-border-weak-light dark:border-border-weak-dark z-sidebar w-80 border-l'
      )}
      onPaste={stopPropagation}
    >
      <div className="h-full overflow-y-auto">
        <SidebarPanelStack
          initialPanelComponent={
            Object.values(userConnections).some((u) => u !== undefined)
              ? ConnectionList
              : Onboarding
          }
        />
      </div>
    </div>
  )
}
