"use strict";
/*---- External -------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.addRectDimensionsToPositionIfRequired = void 0;
/*---- Qualdesk -------------------------------------------------------------*/
const isLinePosition_1 = require("./isLinePosition");
const isRectPosition_1 = require("./isRectPosition");
const Item_types_1 = require("../types/Item.types");
/*---------------------------------------------------------------------------*/
const addRectDimensionsToPositionIfRequired = (pos, item) => {
    if (isRectPosition_1.isRectPosition(pos)) {
        return pos;
    }
    else if (isLinePosition_1.isLinePosition(pos)) {
        const thickness = (item === null || item === void 0 ? void 0 : item.type) === Item_types_1.ItemType.Line ? item.data.lineThickness : 0;
        return Object.assign(Object.assign({}, pos), { left: Math.min(pos.startX, pos.endX) - thickness / 2, top: Math.min(pos.startY, pos.endY) - thickness / 2, width: Math.abs(pos.endX - pos.startX) + thickness, height: Math.abs(pos.endY - pos.startY) + thickness });
    }
    throw new Error(`Expected RectPosition or LinePosition, got '${pos}'.`);
};
exports.addRectDimensionsToPositionIfRequired = addRectDimensionsToPositionIfRequired;
