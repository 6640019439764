/*---- External -------------------------------------------------------------*/

import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useFirestore } from 'react-redux-firebase'

/*---- Qualdesk -------------------------------------------------------------*/

import { generateOperation } from '../helpers/generateOperation'

import { selectMyUid } from '../../selectors/auth/auth'

import { UserConnection } from 'canvas-shared/lib/types/UserConnection.types'
import { WithoutTimestamps } from 'canvas-shared/lib/types/timestamps.types'

/*---------------------------------------------------------------------------*/

interface UserConnectionsActions {
  addConnection: (conn: WithoutTimestamps<UserConnection>) => Promise<void>
  removeConnection: (id: string) => Promise<void>
  disconnectConnection: (id: string) => Promise<void>
}

export const useUserConnectionsActions = (): UserConnectionsActions => {
  const uid = useSelector(selectMyUid)
  const firestore = useFirestore()

  const userConnectionsCollection = firestore
    .collection('users')
    .doc(uid)
    .collection('userConnections')

  const addConnection = useCallback(
    (conn: WithoutTimestamps<UserConnection>) => {
      const operation = generateOperation(uid)

      const connectionWithTimestamp: UserConnection = {
        ...conn,
        createdAt: firestore.FieldValue.serverTimestamp(),
        createdBy: operation,
        updatedAt: firestore.FieldValue.serverTimestamp(),
        updatedBy: operation,
      }

      return userConnectionsCollection
        .doc(conn.type)
        .set(connectionWithTimestamp)
    },
    [firestore.FieldValue, uid, userConnectionsCollection]
  )
  const removeConnection = useCallback(
    (id: string) => userConnectionsCollection.doc(id).delete(),
    [userConnectionsCollection]
  )
  const disconnectConnection = useCallback(
    (id: string) => {
      const operation = generateOperation(uid)

      return userConnectionsCollection.doc(id).update({
        connected: firestore.FieldValue.delete(),
        updatedAt: firestore.FieldValue.serverTimestamp(),
        updatedBy: operation,
      })
    },
    [firestore.FieldValue, uid, userConnectionsCollection]
  )

  return useMemo(
    () => ({
      addConnection,
      removeConnection,
      disconnectConnection,
    }),
    [addConnection, removeConnection, disconnectConnection]
  )
}
