/*---- External -------------------------------------------------------------*/

import React from 'react'

import classNames from 'classnames'

import { Button, IButtonProps } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

interface JiraBoardButtonProps extends IButtonProps {
  text: string
  iconUrl?: string
  iconAltText?: string
}

export const JiraBoardButton: React.FC<JiraBoardButtonProps> = ({
  text,
  iconUrl,
  iconAltText,
  className,
  ...buttonProps
}) => (
  <Button
    className={classNames(
      'jira-board-button hover:bg-button-secondary-hovered-light dark:hover:bg-button-secondary-hovered-dark active:bg-button-secondary-active-light dark:active:bg-button-secondary-active-dark p-2.5',
      className
    )}
    minimal
    fill
    {...buttonProps}
  >
    <div className="flex items-center justify-between space-x-6">
      <div className="leading-snug">
        <div className="text-text-primary-light dark:text-text-primary-dark text-sm">
          {text}
        </div>
        <div className="text-text-secondary-light dark:text-text-secondary-dark mt-1 text-xs">
          Software project
        </div>
      </div>
      {iconUrl && iconAltText && (
        <img src={iconUrl} alt={iconAltText} className="w-6 h-6 rounded" />
      )}
    </div>
  </Button>
)
