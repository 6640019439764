/*---- External -------------------------------------------------------------*/

import React from 'react'

import { Route, useRouteMatch, useHistory } from 'react-router-dom'

import { Button } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import RoutableDialog from '../dialog/RoutableDialog'

import { BoardInviteDialog } from './BoardInviteDialog'

/*---------------------------------------------------------------------------*/

export const BoardInviteButton: React.FC = () => {
  const { url, path } = useRouteMatch()
  const { push } = useHistory()

  const open = () => {
    push(`${url}${INVITE_PATH_FRAGMENT}`)
  }

  return (
    <span>
      <Button
        className="border-border-weak-light dark:border-border-weak-dark rounded-lg"
        outlined
        onClick={open}
      >
        Invite
      </Button>
      <Route path={`${path}${INVITE_PATH_FRAGMENT}`}>
        <RoutableDialog origin={url} title="Invite">
          <BoardInviteDialog />
        </RoutableDialog>
      </Route>
    </span>
  )
}

const INVITE_PATH_FRAGMENT = '/invite'
