/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { containerPath } from 'canvas-shared/lib/paths/containerPath'

import { UUID } from '../../types'

/*---------------------------------------------------------------------------*/

export const generateInvitationsPath = (containerId: UUID) =>
  `${containerPath(containerId)}/invitations`
export const generateUserRolesPath = (containerId: UUID, userId: UUID) =>
  `${containerPath(containerId)}/roles/users/${userId}`
export const generateTeamsPath = (containerId: UUID) =>
  `${containerPath(containerId)}/roles/teams`
export const generateTeamRolesPath = (containerId: UUID) =>
  `${generateTeamsPath(containerId)}/membership`
