/*---- External -------------------------------------------------------------*/

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Button } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { functions } from '../../config/firebase'

import { useContainerId } from '../../contexts/Container'

import { SyncArrows } from '../utilities/SyncArrows'
import { SyncStatus } from 'canvas-shared/lib/config/connections/sync'

import { createSelectConnection } from '../../redux/selectors/board/connections'
import { connectionRequiresManualSync } from 'canvas-shared/lib/types/BoardConnection.helpers'
import {
  connectionDescriptor,
  connectionTitle,
} from 'canvas-shared/lib/config/connections/labels'
import { connectionImageUrl } from '../../helpers/connectionImageUrl'

/*---------------------------------------------------------------------------*/

interface ConnectionProps {
  connectionId: string
}

const syncData = functions.httpsCallable('https-connectionsSyncData')

export const Connection: React.FC<ConnectionProps> = ({ connectionId }) => {
  const selectConnection = createSelectConnection(connectionId)
  const connection = useSelector(selectConnection)
  const containerId = useContainerId()

  const [localSyncStatus, setLocalSyncStatus] = useState(connection?.syncStatus)
  useEffect(() => {
    setLocalSyncStatus(connection?.syncStatus)
  }, [connection?.syncStatus])

  if (!connection) {
    return null
  }

  const title = connectionTitle(connection)
  const descriptor = connectionDescriptor(connection)
  const imageUrl = connectionImageUrl(connection)

  const handleSync = () => {
    setLocalSyncStatus(SyncStatus.IN_PROGRESS)
    syncData({ containerId, connectionIds: [connectionId] })
  }

  return (
    <div className="flex items-center p-2.5 space-x-3">
      <div className="flex flex-shrink-0 items-center justify-center w-5 h-5 rounded overflow-hidden">
        <img className="w-5 h-5" src={imageUrl} alt="Icon" />
      </div>
      <div className="flex-grow flex-shrink overflow-hidden">
        <div className="text-text-primary-light dark:text-text-primary-dark whitespace-nowrap text-sm leading-snug overflow-hidden overflow-ellipsis">
          {title}
        </div>
        <div className="text-text-secondary-light dark:text-text-secondary-dark mt-0.5 text-xs">
          {descriptor}
        </div>
      </div>
      {connectionRequiresManualSync(connection) && (
        <Button
          icon={
            <SyncArrows
              className={
                localSyncStatus === SyncStatus.ERROR
                  ? 'text-icon-danger-light dark:text-icon-danger-dark'
                  : 'text-icon-secondary-light dark:text-icon-secondary-dark'
              }
              weight="bold"
              syncStatus={localSyncStatus}
            />
          }
          minimal
          onClick={handleSync}
          title="Synchronize data"
          className="hover:bg-button-secondary-hovered-light dark:hover:bg-button-secondary-hovered-dark active:bg-button-secondary-active-light dark:active:bg-button-secondary-active-dark relative left-2 p-0"
        />
      )}
    </div>
  )
}
