/*---- External -------------------------------------------------------------*/

import { createAsyncThunk } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  setCursorModeAddCardAction,
  setCursorModeAddStickyAction,
  setCursorModeAddTextAction,
  setCursorModeDrawLineAction,
  setCursorModeDrawRectangleAction,
  setCursorModeSelect,
} from './cursorMode'
import {
  createEmptyPendingItemAction,
  createMultiplePendingItemsAction,
} from './pending'
import { pendingCancelAllAction } from './pending'
import { selectionClearAction } from './selection'

import type { ThunkAPI } from '../../types/redux'
import {
  CreateableItemType,
  ItemType,
} from 'canvas-shared/lib/types/Item.types'

/*---------------------------------------------------------------------------*/

export const toolsCreateItemAction = createAsyncThunk<
  void,
  CreateableItemType,
  ThunkAPI
>('toolbar/createItem', async (type, { dispatch }) => {
  await dispatch(pendingCancelAllAction())
  dispatch(selectionClearAction())

  switch (type) {
    case ItemType.Sticky:
      dispatch(setCursorModeAddStickyAction())
      break
    case ItemType.Card:
      dispatch(setCursorModeAddCardAction())
      break
  }

  dispatch(createEmptyPendingItemAction(type))
})

export const toolsCreateMultipleItemsAction = createAsyncThunk<
  void,
  Parameters<typeof createMultiplePendingItemsAction>[0],
  ThunkAPI
>('toolbar/createItem', async (payload, { dispatch }) => {
  await dispatch(pendingCancelAllAction())
  dispatch(selectionClearAction())
  const type = payload.type

  switch (type) {
    case ItemType.Sticky:
      dispatch(setCursorModeAddStickyAction())
      break
    case ItemType.Card:
      dispatch(setCursorModeAddCardAction())
      break
  }

  dispatch(createMultiplePendingItemsAction(payload))
})

export const toolsDrawShapeAction = createAsyncThunk<
  void,
  CreateableItemType,
  ThunkAPI
>('toolbar/drawShape', async (type, { dispatch }) => {
  await dispatch(pendingCancelAllAction())
  dispatch(selectionClearAction())

  switch (type) {
    case ItemType.Line:
      dispatch(setCursorModeDrawLineAction())
      break
    case ItemType.Rectangle:
      dispatch(setCursorModeDrawRectangleAction())
      break
    case ItemType.Text:
      dispatch(setCursorModeAddTextAction())
      break
  }
})

export const toolsSelectAction = createAsyncThunk<void, void, ThunkAPI>(
  'toolbar/select',
  async (_, { dispatch }) => {
    await dispatch(pendingCancelAllAction())
    dispatch(selectionClearAction())
    dispatch(setCursorModeSelect())
  }
)
