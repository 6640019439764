/*---- External -------------------------------------------------------------*/

import React, { createContext, useState } from 'react'

import { useLocation, useHistory } from 'react-router'

import { parse, stringifyUrl } from 'query-string'

/*---- Qualdesk -------------------------------------------------------------*/

import ensureContextValue from '../helpers/ensureContextValue'

/*---------------------------------------------------------------------------*/

interface SearchContextProps {
  searchQuery?: string
  updateQuery: (s?: string) => void
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}

export const SearchProvider: React.FC = ({ children }) => {
  const { pathname, search } = useLocation()
  const parsedQuery = parse(search)

  const { replace } = useHistory()

  const searchQuery = typeof parsedQuery.s === 'string' ? parsedQuery.s : ''

  const [isOpen, setIsOpen] = useState(!!searchQuery)

  const updateQuery = (s?: string) => {
    replace(
      stringifyUrl({
        url: pathname,
        query: { ...parsedQuery, s: s || undefined },
      })
    )
  }

  const value = {
    searchQuery,
    updateQuery,
    isOpen,
    setIsOpen,
  }

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  )
}

const SearchContext = createContext<Partial<SearchContextProps>>({})
export const useSearchContext = ensureContextValue<SearchContextProps>(
  SearchContext,
  'useSearchContext'
)
