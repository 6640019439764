/*---- External -------------------------------------------------------------*/

import React from 'react'

import { Helmet } from 'react-helmet-async'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

export const NoSettingsAccess: React.FC = () => (
  <div className="bg-background-four-light dark:bg-background-four-dark relative flex flex-col flex-grow h-full">
    <Helmet title="No access" />
    <div className="flex flex-col m-12 overflow-auto space-y-4">
      <div className="text-3xl font-semibold">No access</div>
      <div>You don’t have permission to edit these settings.</div>
    </div>
  </div>
)
