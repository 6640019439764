/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { RootState, SchemaFirestore } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

export const getListenersAllIds = (state: RootState) =>
  state.firestore.listeners.allIds

export const getStatusRequested = (state: RootState) =>
  state.firestore.status.requested

export const getStatusRequesting = (state: RootState) =>
  state.firestore.status.requesting

export const createListenerExistsSelector = (key: keyof SchemaFirestore) =>
  createSelector(getListenersAllIds, (allIds) => allIds.includes(key))

export const createListenerLoadedSelector = (key: keyof SchemaFirestore) =>
  createSelector(
    getListenersAllIds,
    getStatusRequested,
    getStatusRequesting,
    (allIds, requested, requesting) =>
      allIds.includes(key)
        ? requested[key] === true && requesting[key] === false
        : true
  )
