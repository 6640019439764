/*---- External -------------------------------------------------------------*/

import React from 'react'

import { Button, Classes } from '@blueprintjs/core'
import { Classes as Popover2Classes } from '@blueprintjs/popover2'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { GoogleSelectFileForm } from './GoogleSelectFileForm'
import { ControlledPanelProps } from '../SidebarPanelStack'

/*---------------------------------------------------------------------------*/

export const GoogleSelectFilePopover: React.FC<ControlledPanelProps> = ({
  openPanel,
}) => (
  <div className="bg-background-four-light dark:bg-background-four-dark">
    <div className="bg-background-five-light dark:bg-background-five-dark flex items-center p-2 shadow-sm">
      <div className="mx-2 font-semibold">Connect new sheet</div>
      <Button
        className={classNames(
          'self-center ml-auto p-2',
          Classes.DIALOG_CLOSE_BUTTON,
          Popover2Classes.POPOVER2_DISMISS
        )}
        icon="cross"
        minimal
      />
    </div>
    <div className="m-4">
      <div className="text-text-secondary-light dark:text-text-secondary-dark mb-4">
        Copy and paste link to Sheets doc
      </div>
      <GoogleSelectFileForm
        className="space-y-2"
        openPanel={openPanel}
        placeholder="Paste link here"
      />
    </div>
  </div>
)
