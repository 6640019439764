/*---- External -------------------------------------------------------------*/

import React from 'react'

import { Tag } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

interface TooltipContentProps {
  title: string
  hotkey?: string
}

export const TooltipContent: React.FC<TooltipContentProps> = ({
  title,
  hotkey,
}) => (
  <div className="flex items-baseline space-x-2">
    <span>{title}</span>
    {hotkey && (
      <Tag className="white flex items-center justify-center w-5 h-5 text-center uppercase">
        {hotkey}
      </Tag>
    )}
  </div>
)
