/*---- External -------------------------------------------------------------*/

import React from 'react'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import ItemEstimate from './ItemEstimate'
import { ItemGenericTextInner } from './ItemGenericTextInner'

import { isEstimateableItem } from 'canvas-shared/lib/helpers/itemTypes'

import { Item } from 'canvas-shared/lib/types/Item.types'
import { Card } from '../card/Card'

/*---------------------------------------------------------------------------*/

export const ItemCard: React.FC<Item.Card> = (item) => (
  <Card>
    <div
      className={classNames(
        item.data.backgroundColor
          ? `border-${item.data.backgroundColor}-500 dark:border-opacity-75`
          : '',
        'border-b-8',
        'flex flex-col w-full h-full overflow-hidden'
      )}
    >
      <ItemGenericTextInner className="p-5 text-lg" itemId={item.id} />
    </div>
    {isEstimateableItem(item) && <ItemEstimate itemId={item.id} />}
  </Card>
)
