/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import googleSheetsIcon from '../assets/integrations/google/google-sheets-icon.svg'
import linearIcon from '../assets/integrations/linear/linear-icon.svg'
import trelloIcon from '../assets/integrations/trello/trello-icon.svg'

import { BoardConnection } from 'canvas-shared/lib/types/BoardConnection.types'
import {
  boardConnectionIsGoogleSheets,
  boardConnectionIsJira,
  boardConnectionIsLinear,
  boardConnectionIsTrello,
} from 'canvas-shared/lib/types/BoardConnection.helpers'

/*---------------------------------------------------------------------------*/

export const connectionImageUrl = (connection: BoardConnection): string => {
  if (boardConnectionIsGoogleSheets(connection)) {
    return googleSheetsIcon
  } else if (boardConnectionIsJira(connection)) {
    return connection.jira.project.avatarUrls['24x24']
  } else if (boardConnectionIsLinear(connection)) {
    return connection.linear.team.organization.logoUrl ?? linearIcon
  } else if (boardConnectionIsTrello(connection)) {
    return (
      connection.trello.board.prefs?.backgroundImageScaled?.[0].url ??
      connection.trello.board.prefs?.backgroundImage ??
      trelloIcon
    )
  } else {
    throw Error(
      `Couldn’t get connection icon for connection type ${connection.type}`
    )
  }
}
