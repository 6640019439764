/*---- External -------------------------------------------------------------*/

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'

/*---- Qualdesk -------------------------------------------------------------*/

import { Routes } from '../../router/routes'

import { createTeamSelector } from '../../redux/selectors/auth/teams'

import { WorkspaceNavigationButton } from './WorkspaceNavigationButton'
import { TeamIcon } from '../team/TeamIcon'

import { UUID } from 'canvas-shared/lib/types'

/*---------------------------------------------------------------------------*/

interface WorkspaceTeamButtonProps {
  teamId: UUID
}

export const WorkspaceTeamButton: React.FC<WorkspaceTeamButtonProps> = ({
  teamId,
}) => {
  const teamSelector = useMemo(() => createTeamSelector(teamId), [teamId])
  const team = useSelector(teamSelector)

  const path = useMemo(
    () => generatePath(Routes.WORKSPACE_TEAM.path, { teamId: teamId }),
    [teamId]
  )

  if (!team) {
    return null
  }

  return (
    <WorkspaceNavigationButton
      exact={false}
      forPath={path}
      iconElement={<TeamIcon className="w-5 h-5" teamId={teamId} />}
      text={team.name}
    />
  )
}
