/*---- External -------------------------------------------------------------*/

import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { createMultiplePendingItemsAction } from '../../redux/actionCreators/pending'
import { clearClipboardAction } from '../../redux/actionCreators/clipboard'

import MultilineImportDialog from '../../components/import/MultilineImportDialog'

import useLocalStorageSettings from '../../hooks/useLocalStorageSettings'

import { RootState } from '../../types/redux'

/*---------------------------------------------------------------------------*/

const CanvasPasteHandler = () => {
  const dispatch = useDispatch()

  const { useItemType } = useLocalStorageSettings()
  const [itemType] = useItemType()

  const pasteableContent = useSelector(
    (state: RootState) => state.board.clipboard?.text
  )

  const handleImport = (values: string[]) => {
    dispatch(
      createMultiplePendingItemsAction({
        type: itemType,
        data: values.map((title) => ({ title })),
      })
    )
  }

  const clearInternalClipboard = () => {
    dispatch(clearClipboardAction())
  }

  return (
    <MultilineImportDialog
      initialValue={pasteableContent}
      isOpen={pasteableContent !== undefined}
      onClose={clearInternalClipboard}
      onImport={handleImport}
    />
  )
}

export default CanvasPasteHandler
