/*---- External -------------------------------------------------------------*/

import React from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

const FullScreenModalBackdrop: React.FC = ({ children }) => (
  <div className="bg-background-app-light dark:bg-background-app-dark flex flex-col items-center justify-center h-full">
    {children}
  </div>
)

export default FullScreenModalBackdrop
