/*---- External -------------------------------------------------------------*/

import { detect } from 'detect-browser'
import { createLocalStorageStateHook } from 'use-local-storage-state/dist'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

export const useBrowserCheckDismissed = createLocalStorageStateHook(
  'browserCheckDismissed',
  false
)

export const useBrowserCheck = () => {
  const browser = detect()
  const [dismissed] = useBrowserCheckDismissed()

  if (dismissed) {
    return true
  }

  if (browser?.name === 'ie') {
    return false
  }

  if (browser?.name === 'edge' && getMajorVersion(browser.version) <= 18) {
    return false
  }

  return true
}

const getMajorVersion = (version: string): number =>
  Number.parseInt(version.split('.')[0])
