/*---- External -------------------------------------------------------------*/

import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import type { CSSProperties } from 'react'

import { Popover2 } from '@blueprintjs/popover2'

import { RemoveScroll } from 'react-remove-scroll'

import { nanoid } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { useClassNamesWithDarkMode } from '../../hooks/darkMode/useClassNamesWithDarkMode'

import { XYPosition } from 'canvas-shared/lib/types/scrollAndPosition.types'

/*---------------------------------------------------------------------------*/

interface ContextMenuWrapperProps {
  menu: JSX.Element
  onMenuVisibilityChange?: (visible: boolean) => void
}

export const ContextMenuWrapper: React.FC<ContextMenuWrapperProps> = ({
  children,
  menu,
  onMenuVisibilityChange,
}) => {
  const { classNamesWithDarkMode } = useClassNamesWithDarkMode()

  const [contextMenuCoords, setContextMenuCoords] = useState<
    XYPosition | undefined
  >()

  const handleVisibilityChange = useRef(onMenuVisibilityChange)
  const [key, resetKey] = useReducer(() => nanoid(), nanoid())

  const handleContextClick = (e: React.MouseEvent) => {
    e.preventDefault()
    resetKey()
    const { clientX, clientY, pageX, pageY } = e
    setContextMenuCoords({ clientX, clientY, pageX, pageY })
  }

  const handleClose = () => {
    setContextMenuCoords(undefined)
  }

  useEffect(() => {
    if (!!handleVisibilityChange.current) {
      handleVisibilityChange.current(!!contextMenuCoords)
    }
  }, [contextMenuCoords])

  const targetStyle: CSSProperties = useMemo(
    () =>
      contextMenuCoords
        ? {
            position: 'fixed',
            left: contextMenuCoords.clientX,
            top: contextMenuCoords.clientY,
          }
        : {
            display: 'none',
          },
    [contextMenuCoords]
  )

  return (
    <>
      <div style={targetStyle}>
        <Popover2
          autoFocus={false}
          content={<RemoveScroll>{menu}</RemoveScroll>}
          enforceFocus={false}
          isOpen={!!contextMenuCoords}
          key={key}
          minimal
          placement="right-start"
          portalClassName={classNamesWithDarkMode()}
          onClose={handleClose}
          transitionDuration={0}
        >
          <span />
        </Popover2>
      </div>
      <div onContextMenu={handleContextClick}>{children}</div>
    </>
  )
}
