/*---- External -------------------------------------------------------------*/

import React from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { SidebarPanel } from '../SidebarPanel'

import { GoogleSelectFileFirst } from './GoogleSelectFileFirst'
import { GoogleSelectFileList } from './GoogleSelectFileList'

import googleSheetsIcon from '../../../assets/integrations/google/google-sheets-icon.svg'

import { useGoogleSheetsRecentConnections } from './helpers/useGoogleSheetsRecentConnections'

import { ControlledPanelProps } from '../SidebarPanelStack'

import LoadingSpinner from '../../loading/LoadingSpinner'

/*---------------------------------------------------------------------------*/

export const GoogleSelectFile: React.FC<ControlledPanelProps> = (
  panelProps
) => {
  const recentConnections = useGoogleSheetsRecentConnections()

  return (
    <SidebarPanel
      {...panelProps}
      text="Google Sheets"
      iconUrl={googleSheetsIcon}
      iconAltText="Google Sheets icon"
    >
      {!!recentConnections ? (
        recentConnections.length > 0 ? (
          <GoogleSelectFileList
            {...panelProps}
            recentConnections={recentConnections}
          />
        ) : (
          <GoogleSelectFileFirst {...panelProps} />
        )
      ) : (
        <div className="flex flex-col flex-grow items-center justify-center">
          <LoadingSpinner />
        </div>
      )}
    </SidebarPanel>
  )
}
