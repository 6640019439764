/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { generateUserPath } from './userPaths'

import { UUID } from '../../types'

/*---------------------------------------------------------------------------*/

export const generateScrollDimensionsPath = (containerId: UUID, uid: string) =>
  `${generateUserPath(containerId, uid)}/scrollDimensions`
export const generateScrollOffsetsPath = (containerId: UUID, uid: string) =>
  `${generateUserPath(containerId, uid)}/scrollOffsets`
