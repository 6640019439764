/*---- External -------------------------------------------------------------*/

import React from 'react'

import { Lightning } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

interface HeaderProps {
  title: string
  subtitle?: string
}

export const Header: React.FC<HeaderProps> = ({ title, subtitle }) => (
  <header>
    <div className="bg-background-five-light dark:bg-background-four-dark border-border-weak-light dark:border-border-weak-dark text-icon-accent-light dark:text-icon-accent-dark flex items-center justify-center w-12 h-12 border rounded-xl">
      <Lightning size={22} weight="duotone" />
    </div>
    <h2 className="text-text-primary-light dark:text-text-primary-dark mt-6 text-2xl font-semibold">
      {title}
    </h2>
    {subtitle && (
      <h3 className="text-text-secondary-light dark:text-text-secondary-dark mt-2 text-base">
        {subtitle}
      </h3>
    )}
  </header>
)
