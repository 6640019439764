/*---- External -------------------------------------------------------------*/

import React from 'react'
import { useSelector } from 'react-redux'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { ReactComponent as Cursor } from './Cursor.svg'

import { getHexFromColorName } from '../../helpers/getHexFromColorName'

import { useUser as useBoardUser } from '../../redux/selectorHooks/board/useUser'
import { useScrollDimensions } from '../../redux/selectorHooks/board/useScrollDimensions'
import { useUser } from '../../redux/selectorHooks/users/useUser'
import { selectZoomScale } from '../../redux/selectors/board/zoom'

import type { UUID } from '../../types'

/*---------------------------------------------------------------------------*/

interface PresenceCursorProps {
  userId: UUID
}

const PresenceCursor: React.FC<PresenceCursorProps> = ({ userId }) => {
  const boardUser = useBoardUser(userId)
  const user = useUser(userId)

  const { pageLeft, pageTop } = useScrollDimensions()
  const zoomScale = useSelector(selectZoomScale)

  if (!boardUser || !user) {
    return null
  }

  const { isFocused, position } = boardUser

  if (!position || !isFocused) {
    return null
  }

  const { name, color } = user

  const [x, y] = position

  const left = x / zoomScale + pageLeft
  const top = y / zoomScale + pageTop

  const style = {
    top: 0,
    left: 0,
    transform: `translate(${left}px, ${top}px)`,
  }

  return (
    <div className="presence-cursor absolute pointer-events-none" style={style}>
      <Cursor
        fill={getHexFromColorName(color)}
        className="presence-cursor-icon"
      />
      <div
        className={classNames(
          `bg-${color}-600`,
          `text-${color}-200`,
          'presence-cursor-label px-2 py-1 text-xs font-bold rounded'
        )}
      >
        {name}
      </div>
    </div>
  )
}

export default PresenceCursor
