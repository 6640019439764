/*---- External -------------------------------------------------------------*/

import React, { useState } from 'react'

import { CaretDown, Timer } from 'phosphor-react'

import { Button, Dialog, Divider, Menu, Tooltip } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'

import { TimePicker, TimePrecision } from '@blueprintjs/datetime'

import { DateTime } from 'luxon'

/*---- Qualdesk -------------------------------------------------------------*/

import { useTimerActions } from '../../../redux/actions/board/useTimerActions'
import { useTimer } from '../../../redux/selectorHooks/board/useTimer'

import { TooltipContent } from './TooltipContent'

/*---------------------------------------------------------------------------*/

enum TimerMode {
  TIMER = 'timer',
  COUNTDOWN = 'countdown',
}

interface TimerModesConfig {
  text: string
  stopText: string
}

const timerModesConfig: Record<TimerMode, Readonly<TimerModesConfig>> = {
  [TimerMode.TIMER]: {
    text: 'Start timer',
    stopText: 'Stop timer',
  },
  [TimerMode.COUNTDOWN]: {
    text: 'Set countdown…',
    stopText: 'Stop countdown',
  },
}

export const TimerTools: React.FC = () => {
  const timer = useTimer()
  const { setCountdownTimer, startTimer, stopTimer } = useTimerActions()

  const [dialogOpen, setDialogOpen] = useState(false)
  const [countdownDate, setCountdownDate] = useState<Date>(
    startOfDay.set({ hour: 0, minute: 30, second: 0 }).toJSDate()
  )

  const handleCountdownFromDate = () => {
    const diff = DateTime.fromJSDate(countdownDate).diff(startOfDay)
    const seconds = diff.milliseconds / 1000
    setCountdownTimer(seconds)
    setDialogOpen(false)
  }

  const handleCountdownFromSeconds = (seconds: number) => {
    setCountdownTimer(seconds)
    setDialogOpen(false)
  }

  const timerActive = timer && timer.countDown === false
  const countdownTimerActive = timer && timer.countDown === true

  return (
    <>
      <Popover2
        minimal
        placement="top-start"
        autoFocus={false}
        content={
          <Menu>
            <Menu.Item
              text={
                timerActive
                  ? timerModesConfig[TimerMode.TIMER].stopText
                  : timerModesConfig[TimerMode.TIMER].text
              }
              active={timerActive}
              onClick={() => {
                if (timerActive) {
                  stopTimer()
                } else {
                  startTimer()
                }
              }}
            />
            <Menu.Item
              text={
                countdownTimerActive
                  ? timerModesConfig[TimerMode.COUNTDOWN].stopText
                  : timerModesConfig[TimerMode.COUNTDOWN].text
              }
              active={countdownTimerActive}
              onClick={() => {
                stopTimer()
                if (!countdownTimerActive) setDialogOpen(true)
              }}
            />
          </Menu>
        }
      >
        <Tooltip
          popoverClassName="tooltip"
          content={<TooltipContent title="Timer tools" />}
          hoverOpenDelay={300}
        >
          <Button
            className="popover-button px-2"
            icon={<Timer size={24} className="toolbar-icon mr-0.5" />}
            rightIcon={
              <CaretDown
                weight="bold"
                className="popover-button-caret text-icon-secondary-light dark:text-icon-secondary-dark"
              />
            }
            minimal
            aria-label="Timer tools"
          />
        </Tooltip>
      </Popover2>
      <Dialog
        className="flex flex-col p-3 w-auto min-w-0"
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <div className="flex flex-row space-x-1">
          <Button
            text="1 minute"
            onClick={() => handleCountdownFromSeconds(1 * 60)}
          />
          <Button
            text="5 minutes"
            onClick={() => handleCountdownFromSeconds(5 * 60)}
          />
          <Button
            text="10 minutes"
            onClick={() => handleCountdownFromSeconds(10 * 60)}
          />
        </div>
        <Divider />
        <div className="flex flex-row items-center justify-center space-x-1">
          <TimePicker
            onChange={setCountdownDate}
            precision={TimePrecision.SECOND}
            showArrowButtons
            value={countdownDate}
          />
          <Button onClick={handleCountdownFromDate} text="Start" />
        </div>
      </Dialog>
    </>
  )
}

const startOfDay = DateTime.local().startOf('day')
