/*---- External -------------------------------------------------------------*/

import React from 'react'

import { Button } from '@blueprintjs/core'

import { Trash } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import { useToggle } from '../utilities/useToggle'

import { SettingsPanelProps } from '../../types/settings'
import {
  PictureHandlerProps,
  PictureUploaderDialog,
} from './PictureUploaderDialog'

/*---------------------------------------------------------------------------*/

interface PictureChooserProps extends SettingsPanelProps {
  deletePicture: () => PromiseLike<void>
  description?: string
  renderPicture: JSX.Element
  title: string
}

export const PictureChooser: React.FC<
  PictureChooserProps & PictureHandlerProps
> = ({ deletePicture, renderPicture, title, description, ...handlerProps }) => {
  const [dialogOpen, toggleDialog] = useToggle(false)

  return (
    <div className="flex flex-row items-center space-x-4">
      {renderPicture}
      <div className="flex flex-col">
        <div className="font-semibold">{title}</div>
        {description && (
          <div className="text-text-secondary-light dark:text-text-secondary-dark mt-1 text-xs">
            {description}
          </div>
        )}
        <div className="flex flex-row mt-2 space-x-2">
          <Button onClick={toggleDialog} text="Upload new picture" />
          <PictureUploaderDialog
            isOpen={dialogOpen}
            onClose={toggleDialog}
            {...handlerProps}
          />
          <Button
            icon={
              <Trash
                className="text-icon-secondary-light dark:text-icon-secondary-dark w-5 h-5"
                weight="fill"
              />
            }
            minimal
            onClick={deletePicture}
          />
        </div>
      </div>
    </div>
  )
}
