/*---- External -------------------------------------------------------------*/

import React from 'react'
import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { selectInteractionMode } from '../../redux/selectors/board/interactionModes'
import { usePresenceUserIds } from '../../redux/selectorHooks/board/usePresenceUserIds'

import PresenceCursor from '../presence/PresenceCursor'

import { InteractionMode } from '../../types'

/*---------------------------------------------------------------------------*/

const CanvasPresenceCursors: React.FC = () => {
  const presenceUserIds = usePresenceUserIds()
  const interactionMode = useSelector(selectInteractionMode)

  if (
    interactionMode === InteractionMode.VOTING ||
    interactionMode === InteractionMode.ESTIMATION
  ) {
    return null
  }

  return (
    <>
      {presenceUserIds.map((userId) => (
        <PresenceCursor key={userId} userId={userId} />
      ))}
    </>
  )
}

export default CanvasPresenceCursors
