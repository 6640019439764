/*---- External -------------------------------------------------------------*/

import React from 'react'

import classNames from 'classnames'

import { Button, Classes, Tab, Tabs } from '@blueprintjs/core'
import { Classes as Popover2Classes } from '@blueprintjs/popover2'

/*---- Qualdesk -------------------------------------------------------------*/

import { SidebarPopover } from '../SidebarPopover'
import { GoogleSheetsDataSettings } from './GoogleSheetsDataSettings'

import { GoogleBoardConnection } from 'canvas-shared/lib/types/BoardConnection.types'
import { GoogleSheetsGrid } from 'canvas-shared/lib/types/connections/Google.types'
import { sheets_v4 } from 'googleapis'

/*---------------------------------------------------------------------------*/

enum TabId {
  Data = 'data',
}

export interface GoogleSheetsSettingsPopoverProps {
  connection: GoogleBoardConnection
  grid: GoogleSheetsGrid
  sheets: sheets_v4.Schema$Sheet[]
}

export const GoogleSheetsSettingsPopover: React.FC<GoogleSheetsSettingsPopoverProps> = (
  popoverProps
) => (
  <SidebarPopover
    fill
    buttonText="Sheet settings"
    content={<PopoverContent {...popoverProps} />}
  />
)

const PopoverContent: React.FC<GoogleSheetsSettingsPopoverProps> = (
  popoverProps
) => (
  <Tabs
    className="tabs-header dark:bg-background-four-dark"
    id="filter"
    selectedTabId={TabId.Data}
  >
    <Tab
      id={TabId.Data}
      title="Data settings"
      panel={<GoogleSheetsDataSettings {...popoverProps} />}
    />
    <Button
      className={classNames(
        'self-center ml-auto p-2',
        Classes.DIALOG_CLOSE_BUTTON,
        Popover2Classes.POPOVER2_DISMISS
      )}
      icon="cross"
      minimal
    />
  </Tabs>
)
