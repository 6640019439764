/*---- External -------------------------------------------------------------*/

import { createLocalStorageStateHook } from 'use-local-storage-state'

/*---- Qualdesk -------------------------------------------------------------*/

import { GenericSort } from '../../types'
/*---------------------------------------------------------------------------*/

export const useGoogleSheetsRecentSort = createLocalStorageStateHook<
  GenericSort
>('googleSheetsRecentSort', GenericSort.Desc)
