/*---- External -------------------------------------------------------------*/

import { createAction } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { User } from 'canvas-shared/lib/types/user.types'

/*---------------------------------------------------------------------------*/

export const databaseSessionSetAction = createAction<Partial<User> | null>(
  'db/session/set'
)

export const resetSessionAction = createAction('session/reset')

export const stopAllGesturesAction = createAction('gestures/stopAll')
