/*---- External -------------------------------------------------------------*/

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { Position } from '@blueprintjs/core'
import { Placement } from '@blueprintjs/popover2'

import difference from 'lodash.difference'
import intersection from 'lodash.intersection'

/*---- Qualdesk -------------------------------------------------------------*/

import { CanvasHoverToolbarSection } from './canvasHoverToolbar/CanvasHoverToolbarSection'

import DynamicToolbar from '../dynamicToolbar/DynamicToolbar'

import { isVoteableItem } from 'canvas-shared/lib/helpers/itemTypes'

import { useItemToolbar } from '../../hooks/useItemToolbar'

import { selectInteractionMode } from '../../redux/selectors/board/interactionModes'
import { useSelectedItems } from '../../redux/selectorHooks/board/useSelectedItems'
import { useSelectedItemIds } from '../../redux/selectorHooks/board/useSelectedItemIds'
import { selectEditsItemIds } from '../../redux/selectors/board/edits'
import { useScrollOffsets } from '../../redux/selectorHooks/board/useScrollOffsets'
import { useSingleLineSelected } from '../../redux/selectorHooks/board/useSingleLineSelected'
import { selectVisibleItemIds } from '../../redux/selectors/board/visibility'
import { useZoomLevel } from '../../redux/selectorHooks/board/useZoomLevel'

import { contextMenuOpenSelector } from '../../redux/selectors/board/contextMenu'

import { useCanvasWindowContext } from '../../contexts/CanvasWindow'

import CanvasItemReactions from './CanvasItemReactions'

import { UUID, InteractionMode } from '../../types'
import { ItemType } from 'canvas-shared/lib/types/Item.types'
import {
  selectGestureInProgress,
  selectIsScrollingWithGesture,
} from '../../redux/selectors/board/gestures'

/*---------------------------------------------------------------------------*/

export const CanvasHoverToolbar: React.FC = React.memo(() => {
  const editingItemIds = useSelector(selectEditsItemIds)
  const selectedItemIds = useSelectedItemIds()
  const selectedItems = useSelectedItems()
  const singleLineSelected = useSingleLineSelected()
  const visibleItemIds = useSelector(selectVisibleItemIds)

  const interactionMode = useSelector(selectInteractionMode)
  const zoomLevel = useZoomLevel()
  const scrollOffsets = useScrollOffsets()

  const contextMenuOpen = useSelector(contextMenuOpenSelector)

  const { canvasRef } = useCanvasWindowContext()

  const isScrollingWithGesture = useSelector(selectIsScrollingWithGesture)
  const gestureInProgress = useSelector(selectGestureInProgress)

  const shouldRender =
    intersection(selectedItemIds, editingItemIds).length === 0 &&
    interactionMode !== InteractionMode.ESTIMATION &&
    interactionMode !== InteractionMode.ESTIMATE_RESOLUTION &&
    intersection(selectedItemIds, visibleItemIds).length > 0 &&
    !isScrollingWithGesture &&
    !contextMenuOpen &&
    !gestureInProgress

  const shouldRenderReactionToolbar =
    selectedItems.length === 1 &&
    selectedItems.every((i) => i.type !== ItemType.Line)

  const renderTimer = useRef<number>()
  const [ready, setReady] = useState(false)

  const lastSelectedItemIds = useRef<UUID[]>([])

  // Delay rendering to avoid flickering effect when changing selection and/or dragging

  const checkReady = () => {
    let delay = 50

    if (difference(lastSelectedItemIds.current, selectedItemIds).length > 0) {
      setReady(false)
    }

    if (shouldRender) {
      renderTimer.current = window.setTimeout(() => {
        setReady(true)
      }, delay)
    } else {
      setReady(false)
    }

    lastSelectedItemIds.current = selectedItemIds

    return () => {
      if (renderTimer.current) {
        renderTimer.current = undefined
      }
    }
  }

  useEffect(checkReady, [shouldRender, selectedItemIds, scrollOffsets])

  const hoverToolbarItems = useItemToolbar(selectedItems)

  const hoverToolbarPopoverPlacement: Placement = useMemo(() => {
    if (!!singleLineSelected) {
      const { startX, startY, endX, endY } = singleLineSelected.position

      const se = endX > startX && endY > startY
      const nw = endX <= startX && endY <= startY

      if (se || nw) {
        return 'right-end'
      } else {
        return 'left-end'
      }
    }

    return Position.TOP
  }, [singleLineSelected])

  const hoverToolbarPositioningClassName = useMemo(() => {
    if (!!singleLineSelected) {
      return 'w-auto h-full absolute -left-4 -right-4'
    } else {
      return 'w-auto h-full absolute -top-10 -right-8 -left-8'
    }
  }, [singleLineSelected])

  const hoverToolbarModifiers = useMemo(() => {
    if (!canvasRef.current) {
      return undefined
    }

    const allowedAutoPlacements = !!singleLineSelected
      ? (['left', 'right'] as Placement[])
      : (['top', 'left', 'right'] as Placement[])

    return {
      flip: {
        enabled: true,
        options: {
          allowedAutoPlacements,
        },
      },
      preventOverflow: {
        options: {
          boundary: canvasRef.current,
          padding: 10,
          altAxis: true,
        },
      },
    }
  }, [canvasRef, singleLineSelected])

  const reactionToolbarModifiers = useMemo(() => {
    if (!canvasRef.current) {
      return undefined
    }
    return {
      flip: {
        enabled: true,
        options: {
          allowedAutoPlacements: ['bottom', 'right', 'left'] as Placement[],
        },
      },
      preventOverflow: {
        options: {
          boundary: canvasRef.current,
          padding: 10,
          altAxis: true,
        },
      },
    }
  }, [canvasRef])

  const toolbarButtonProps = { minimal: true }

  // Create a key to ensure that the popover re-renders in the
  // correct position when the selection or zoomLevel change

  const popoverKey = useMemo(
    () => selectedItemIds.join().concat(zoomLevel.toString()),
    [selectedItemIds, zoomLevel]
  )

  // Only show toolbar when user is ‘at rest’

  if (!shouldRender || !ready) {
    return null
  }

  return (
    <div className="absolute w-full h-full">
      {shouldRenderReactionToolbar && isVoteableItem(selectedItems[0]) && (
        <CanvasHoverToolbarSection
          className="absolute -bottom-8 -right-8 w-full h-full"
          modifiers={reactionToolbarModifiers}
          placement="bottom-end"
          key={`reactions-toolbar-${popoverKey}`}
          content={<CanvasItemReactions showButtons item={selectedItems[0]} />}
        />
      )}
      {hoverToolbarItems.length > 0 && (
        <CanvasHoverToolbarSection
          className={hoverToolbarPositioningClassName}
          modifiers={hoverToolbarModifiers}
          placement={hoverToolbarPopoverPlacement}
          key={`dynamic-toolbar-${popoverKey}`}
          content={
            <DynamicToolbar
              buttonProps={toolbarButtonProps}
              items={hoverToolbarItems}
              dividersBetweenAllItems
            />
          }
        />
      )}
    </div>
  )
})
