/*---- External -------------------------------------------------------------*/

import React, { useState } from 'react'
import { useAsync } from '@react-hook/async'

import { ArrowRight } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import { validateEmail } from '../../helpers/validateEmail'

import { useSignIn } from '../../hooks/useSignIn'

import { MinimalLayout } from '../../components/layouts/MinimalLayout'
import { Input } from '../../components/utilities/Input'
import { Button } from '../../components/utilities/Button'
import { CheckEmail } from '../../components/auth/CheckEmail'
import { Link } from 'react-router-dom'
import { Routes } from '../../router/routes'

/*---------------------------------------------------------------------------*/

const SignIn: React.FC = () => {
  const [email, setEmail] = useState('')
  const [showConfirm, setShowConfirm] = useState(false)
  const { signIn } = useSignIn()
  const [{ status }, handleSignIn] = useAsync(async (event) => {
    event.preventDefault()
    await signIn(email)
    setShowConfirm(true)
  })

  if (showConfirm) {
    return (
      <MinimalLayout highlight>
        <CheckEmail email={email} handleRetry={() => signIn(email)} />
      </MinimalLayout>
    )
  }

  return (
    <MinimalLayout
      headerRight={
        <Link
          className="text-text-secondary-light dark:text-text-secondary-dark"
          to={Routes.SIGN_UP.path}
        >
          Sign up
        </Link>
      }
    >
      <h1 className="text-text-primary-light dark:text-text-primary-dark text-2xl font-extrabold leading-tight sm:text-3xl">
        Sign in to Qualdesk
      </h1>
      <p className="text-text-secondary-light dark:text-text-secondary-dark mb-6 mt-3 text-sm leading-normal">
        We’ll email you a link to access your Qualdesk account
      </p>
      <form onSubmit={handleSignIn}>
        <Input
          label="Enter your email"
          type="email"
          placeholder="you@example.com"
          onChange={(event) => setEmail(event.target.value)}
          value={email}
          autoFocus
        />
        <Button
          className="flex mt-4 w-full text-left space-x-2"
          disabled={status === 'loading' || !validateEmail(email)}
          loading={status === 'loading'}
        >
          <span className="flex-grow">Continue</span>
          <ArrowRight className="flex-shrink-0 w-4 h-4" />
        </Button>
      </form>
    </MinimalLayout>
  )
}

export default SignIn
