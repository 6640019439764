/*---- External -------------------------------------------------------------*/

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { scrollOffsetsSelector } from '../../selectors/board/scroll'

/*---------------------------------------------------------------------------*/

export const useScrollOffsets = () => useSelector(scrollOffsetsSelector)
