/*---- External -------------------------------------------------------------*/

import { createAsyncThunk } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { app } from '../../../config/firebase'

import { generateOperation } from '../../actions/helpers/generateOperation'
import { selectMyUid } from '../../selectors/auth/auth'

import { UserUpdateTimestamps } from 'canvas-shared/lib/types/timestamps.types'
import { ThunkAPI } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

type WithUpdateMetadata<T> = T & UserUpdateTimestamps

export function createAsyncThunkWithUpdateMetadata<T = void>(type: string) {
  return createAsyncThunk<WithUpdateMetadata<T>, T, ThunkAPI>(
    type,
    (payload, { getState }) => {
      const state = getState()
      const uid = selectMyUid(state)
      const operation = generateOperation(uid)
      return {
        ...payload,
        updatedAt: app.firestore.Timestamp.now(),
        updatedBy: operation,
      }
    }
  )
}
