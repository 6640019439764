/*---- External -------------------------------------------------------------*/

import { AsyncThunkAction } from '@reduxjs/toolkit'
import { combineEpics, ofType } from 'redux-observable'
import { filter, map, withLatestFrom } from 'rxjs/operators'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  duplicateItemsWithDragAction,
  pendingCommitAllAction,
} from '../actionCreators/pending'
import {
  pointerDownAction,
  pointerMoveAction,
  PointerPayload,
  pointerUpAction,
} from '../actionCreators/pointers'
import {
  ActionWithPayload,
  EpicWithDifferentActions,
  ThunkAPI,
} from '../../types/redux'
import {
  BasicPointerEvent,
  Gesture,
  GestureMode,
} from 'canvas-shared/lib/types/pointerEvents.types'
import {
  startDrawingLineAction,
  startDrawingRectangleAction,
  DrawShapeAction,
  startDrawingTextboxAction,
} from '../actionCreators/shapes'
import { CursorMode } from 'canvas-shared/lib/types/CursorMode.types'
import { UUID } from '../../types'
import {
  selectionExtendWithRectAction,
  selectionSetWithRectAction,
} from '../actionCreators/selection'
import {
  movePositionsWithGestureAction,
  transformPositionsAction,
} from '../actionCreators/positions'
import { selectGestureByPointerId } from '../selectors/board/gestures'
import { scrollOrZoomChangedAction } from '../actionCreators/scroll'

/*---------------------------------------------------------------------------*/

const pointerDownEpic: EpicWithDifferentActions<
  ActionWithPayload<PointerPayload>,
  | AsyncThunkAction<DrawShapeAction, BasicPointerEvent, ThunkAPI>
  | AsyncThunkAction<UUID[], void, ThunkAPI>
  | ReturnType<typeof duplicateItemsWithDragAction>
  | null
> = (action$, state$) =>
  action$.pipe(
    ofType(pointerDownAction.fulfilled),
    withLatestFrom(state$),
    map(([action, state]) => {
      const { cursorMode, pointer } = action.payload
      const gesture = selectGestureByPointerId(state, pointer.pointerId)

      if (gesture?.mode === GestureMode.DRAW) {
        if (cursorMode === CursorMode.DRAW_RECTANGLE) {
          return startDrawingRectangleAction(action.payload.pointer)
        } else if (cursorMode === CursorMode.DRAW_LINE) {
          return startDrawingLineAction(action.payload.pointer)
        } else if (cursorMode === CursorMode.ADD_TEXT) {
          return startDrawingTextboxAction(action.payload.pointer)
        }
      } else if (gesture?.altKey) {
        return duplicateItemsWithDragAction()
      }
      return null
    }),
    filter((a) => !!a)
  )

const pointerMoveSelectItemsEpic: EpicWithDifferentActions<
  ActionWithPayload<{ gesture?: Gesture }>,
  AsyncThunkAction<
    any,
    Record<'startX' | 'startY' | 'endX' | 'endY', number> | Gesture,
    ThunkAPI
  > | null
> = (action$) =>
  action$.pipe(
    ofType<any>(
      pointerMoveAction.fulfilled,
      scrollOrZoomChangedAction.fulfilled
    ),
    filter(({ payload }) => !!payload.gesture && !payload.gesture.finished),
    map(({ payload }) => {
      if (!payload.gesture) return null

      switch (payload.gesture.mode) {
        case GestureMode.DRAG_SELECT:
          const {
            gesture: { startCoords, endCoords },
          } = payload

          const selectionPayload = {
            startX: startCoords.canvasX,
            startY: startCoords.canvasY,
            endX: endCoords.canvasX,
            endY: endCoords.canvasY,
          }

          if (payload.gesture.shiftKey) {
            return selectionExtendWithRectAction(selectionPayload)
          } else {
            return selectionSetWithRectAction(selectionPayload)
          }

        case GestureMode.RESIZE:
          return transformPositionsAction(payload.gesture)

        case GestureMode.MOVE:
          return movePositionsWithGestureAction(payload.gesture)
        default:
          return null
      }
    }),
    filter((a) => !!a)
  )

const pointerUpEpic: EpicWithDifferentActions<
  ActionWithPayload<PointerPayload>,
  ReturnType<typeof pendingCommitAllAction> | null
> = (action$) =>
  action$.pipe(
    ofType(pointerUpAction.fulfilled),
    map((action) => {
      if (!!action.payload.gesture && !action.payload.gesture.finished) {
        return pendingCommitAllAction()
      } else {
        return null
      }
    }),
    filter((a) => !!a)
  )

export const pointersEpic = combineEpics(
  pointerDownEpic,
  pointerMoveSelectItemsEpic,
  pointerUpEpic
)
