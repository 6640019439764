/*---- External -------------------------------------------------------------*/

import { useMemo } from 'react'

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { useContainerId } from '../../../contexts/Container'

import { containerSelector } from '../../selectors/container/containers'

import { RootState } from '../../../types/redux'
import { Board } from 'canvas-shared/lib/types/Board.types'

/*---------------------------------------------------------------------------*/

export const useBoard = (): Board => {
  const id = useContainerId()
  const boardSelector = useMemo(() => containerSelector(id), [id])
  const board = useSelector((state: RootState) =>
    boardSelector(state, id)
  ) as Board
  return board
}
