/*---- External -------------------------------------------------------------*/

import React, { useEffect, useReducer, useRef } from 'react'

import { MenuItem, Classes } from '@blueprintjs/core'

import { Suggest, IItemRendererProps } from '@blueprintjs/select'

import { CheckCircle, PaperPlaneTilt, PlusCircle } from 'phosphor-react'

import isEmail from 'validator/es/lib/isEmail'
import { nanoid } from 'nanoid'

/*---- Qualdesk -------------------------------------------------------------*/

import UserIcon from '../user/UserIcon'
import UserName from '../user/UserName'

import { cleanEmail } from 'canvas-shared/lib/helpers/cleanEmail'

import { useUserList } from '../../redux/selectorHooks/users/useUsers'

import { UserDetails } from 'canvas-shared/lib/types/user.types'
import { UUID } from 'canvas-shared/lib/types'

/*---------------------------------------------------------------------------*/

interface SuggestUserProps {
  alreadyInvitedEmail: (email: string) => boolean
  alreadyInvitedUser: (userId: UUID) => boolean
  onSelectEmail: (email: string) => PromiseLike<any>
  onSelectUser: (userId: UUID) => PromiseLike<any>
  rightElement?: JSX.Element
}

export const SuggestUser: React.FC<SuggestUserProps> = ({
  alreadyInvitedEmail,
  alreadyInvitedUser,
  onSelectEmail,
  onSelectUser,
  rightElement,
}) => {
  const users: Array<UserDetails | RequestedInvite> = useUserList()

  // HACK: The input and placeholder were being set in a strange way when iterms
  // were selected. This allows us to force a re-render of the component.
  const [key, resetKey] = useReducer(() => nanoid(), '')
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (key && inputRef.current) {
      inputRef.current.focus()
    }
  }, [key])

  const itemPredicate = (
    query: string,
    maybeUser: UserDetails | RequestedInvite
  ) =>
    isUser(maybeUser) &&
    !!maybeUser.name &&
    maybeUser.name.toLocaleLowerCase().includes(query.toLocaleLowerCase())

  const renderInputValue = (maybeUser: UserDetails | RequestedInvite) =>
    isUser(maybeUser) ? maybeUser.name : maybeUser.email

  const renderItem = (
    maybeUser: UserDetails | RequestedInvite,
    { modifiers: { active } }: IItemRendererProps
  ) => {
    if (isUser(maybeUser)) {
      const alreadyInvited = alreadyInvitedUser(maybeUser.uid)
      const handleClick = () => onSelectUser(maybeUser.uid)

      const labelElement = alreadyInvited ? (
        <CheckCircle
          className={Classes.INTENT_SUCCESS}
          size={18}
          weight="fill"
        />
      ) : (
        <PlusCircle size={18} weight="fill" />
      )

      return (
        <MenuItem
          active={active && !alreadyInvited}
          className="items-center"
          icon={<UserIcon className="w-8 h-8" uid={maybeUser.uid} />}
          text={<UserName uid={maybeUser.uid} />}
          disabled={alreadyInvited}
          key={maybeUser.uid}
          labelElement={labelElement}
          onClick={handleClick}
        />
      )
    } else {
      return null
    }
  }

  const createNewItemFromQuery = (email: string) => ({ email })

  const createNewItemRenderer = (query: string, active: boolean) => {
    const email = cleanEmail(query)
    const alreadyInvited = alreadyInvitedEmail(email)
    const handleClick = !!email ? () => onSelectEmail(email) : undefined
    const disabled =
      !isEmail(query, { allow_display_name: true }) || alreadyInvited

    const labelElement = alreadyInvited ? (
      <CheckCircle className={Classes.INTENT_SUCCESS} size={18} weight="fill" />
    ) : undefined

    return (
      <MenuItem
        active={active}
        disabled={disabled}
        icon={<PaperPlaneTilt className={Classes.ICON} size={18} />}
        labelElement={labelElement}
        onClick={handleClick}
        text={
          <>
            Invite <b>{email}</b> by email
          </>
        }
      />
    )
  }

  const handleSuggestClick = (userOrInvite: UserDetails | RequestedInvite) => {
    if (isUser(userOrInvite)) {
      onSelectUser(userOrInvite.uid)
    } else if (isEmail(cleanEmail(userOrInvite.email))) {
      onSelectEmail(cleanEmail(userOrInvite.email))
    }
    resetKey()
  }

  const suggestInputProps = {
    className: 'rounded-tl-sm rounded-tr-sm',
    large: true,
    placeholder: 'Type a name or email to start inviting…',
    inputRef,
    rightElement,
  }

  const suggestPopoverProps = {
    minimal: true,
  }

  return (
    <Suggest
      createNewItemFromQuery={createNewItemFromQuery}
      createNewItemRenderer={createNewItemRenderer}
      fill
      inputProps={suggestInputProps}
      inputValueRenderer={renderInputValue}
      itemPredicate={itemPredicate}
      itemRenderer={renderItem}
      items={users}
      key={key}
      onItemSelect={handleSuggestClick}
      popoverProps={suggestPopoverProps}
      resetOnClose
      resetOnSelect
    />
  )
}

interface RequestedInvite {
  email: string
}

const isUser = (
  userOrInvite: UserDetails | RequestedInvite
): userOrInvite is UserDetails => !!(userOrInvite as UserDetails).uid
