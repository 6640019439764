/*---- External -------------------------------------------------------------*/

import { createAction } from '@reduxjs/toolkit'
import { UUID } from 'canvas-shared/lib/types'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

export const setContainerIdAction = createAction<UUID>('board/container/setId')
