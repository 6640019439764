/*---- External -------------------------------------------------------------*/

import { useCallback, useMemo } from 'react'

import { useDispatch } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  setShouldSelectAction,
  clearShouldSelectAction,
} from '../../actionCreators/shouldSelect'

import { UUID } from '../../../types'

/*---------------------------------------------------------------------------*/

interface ShouldSelectActions {
  setShouldSelect: (itemId: UUID) => void
  clearShouldSelect: () => void
}

export const useShouldSelectActions = (): ShouldSelectActions => {
  const dispatch = useDispatch()

  const setShouldSelect = useCallback(
    (itemId: UUID) => {
      dispatch(setShouldSelectAction(itemId))
    },
    [dispatch]
  )

  const clearShouldSelect = useCallback(() => {
    dispatch(clearShouldSelectAction())
  }, [dispatch])

  return useMemo(
    () => ({
      setShouldSelect,
      clearShouldSelect,
    }),
    [clearShouldSelect, setShouldSelect]
  )
}
