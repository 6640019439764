/*---- External -------------------------------------------------------------*/

import React from 'react'

import { IMenuDividerProps, IMenuItemProps, Menu } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import TrelloLabelPill from './TrelloLabelPill'

import getExternalItemUrl from '../../../helpers/getExternalCardUrl'

import { UUID } from '../../../types'

import { Item } from 'canvas-shared/lib/types/Item.types'
import * as Trello from 'canvas-shared/lib/types/connections/Trello.types'

/*---------------------------------------------------------------------------*/

interface Identifable {
  id: string
}

interface TrelloContextMenuProps {
  trelloCards: Item.TrelloCard[]

  trelloSetList: (ids: UUID[], list: Trello.List) => void
  trelloAddLabel: (ids: UUID[], label: Trello.Label) => void
  trelloRemoveLabel: (ids: UUID[], label: Trello.Label) => void
  trelloAddMember: (ids: UUID[], member: Trello.Member) => void
  trelloRemoveMember: (ids: UUID[], member: Trello.Member) => void
}

const TrelloContextMenu = ({
  trelloCards,
  trelloSetList,
  trelloAddLabel,
  trelloRemoveLabel,
  trelloAddMember,
  trelloRemoveMember,
}: TrelloContextMenuProps) => {
  const uniqObjects = <T extends Identifable>(
    label: T,
    idx: number,
    list: T[]
  ) => list.findIndex((l) => l.id === label.id) === idx
  const allMembers = trelloCards
    .flatMap((c) => c.data?.trello?.context?.members)
    .filter(uniqObjects)

  const allLabels = trelloCards
    .flatMap((c) => c.data?.trello?.context?.labels) // pick all available labels
    .filter(uniqObjects) // uniq

  const allLists = trelloCards
    .flatMap((c) => c.data?.trello?.context?.lists) // pick all available labels
    .filter(uniqObjects) // uniq

  const addOrRemoveLabel = async (label: Trello.Label) => {
    const cardsWithoutLabel = trelloCards.filter(
      (c) =>
        c.data.trello.labels.filter((l: Trello.Label) => l.id === label.id)
          .length === 0
    )
    if (cardsWithoutLabel.length > 0) {
      trelloAddLabel(
        trelloCards.map((c) => c.id),
        label
      )
    } else {
      trelloRemoveLabel(
        trelloCards.map((c) => c.id),
        label
      )
    }
  }

  const addOrRemoveMember = async (member: Trello.Member) => {
    const cardsWithoutMember = trelloCards.filter(
      (c) => !c.data.trello.memberIds.includes(member.id)
    )

    if (cardsWithoutMember.length > 0) {
      trelloAddMember(
        trelloCards.map((c) => c.id),
        member
      )
    } else {
      trelloRemoveMember(
        trelloCards.map((c) => c.id),
        member
      )
    }
  }

  const changeList = async (list: Trello.List) => {
    trelloSetList(
      trelloCards.map((c) => c.id),
      list
    )
  }

  const memberStyle = (memberId: string) => {
    const matches = trelloCards.map((c) =>
      c.data.trello.memberIds.includes(memberId)
    )

    if (matches.every((x) => x)) {
      return 'font-bold'
    } else if (matches.every((x) => !x)) {
      return ''
    } else {
      return 'italic'
    }
  }

  const menuItems: (IMenuItemProps | IMenuDividerProps)[] = [
    {
      text: 'Move to column',
      icon: 'two-columns',
      children: allLists.map((l: Trello.List) => (
        <Menu.Item key={l.name} text={l.name} onClick={() => changeList(l)} />
      )),
    },
    {
      text: 'Labels',
      icon: 'tag',
      children: allLabels.map((l: Trello.Label) => (
        <Menu.Item
          key={l.name}
          text={l.name || l.color}
          className={!l.name ? 'italic' : ''}
          icon={
            <TrelloLabelPill
              allowBlank={true}
              className="mr-2 my-auto px-4"
              label={l}
            />
          }
          onClick={() => addOrRemoveLabel(l)}
        />
      )),
    },
    {
      text: 'Members',
      icon: 'new-person',
      children: allMembers.map((m: Trello.Member) => (
        <Menu.Item
          key={m.name}
          text={m.name}
          className={memberStyle(m.id)}
          onClick={() => addOrRemoveMember(m)}
        />
      )),
    },
  ]

  if (trelloCards.length === 1) {
    const url = getExternalItemUrl(trelloCards[0])

    if (url) {
      menuItems.push({
        text: 'Open in Trello',
        icon: 'link',
        href: url,
        target: '_blank',
      })
    }
  }

  return menuItems
}

export default TrelloContextMenu
