/*---- External -------------------------------------------------------------*/

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { selectedItemsPositionsSelector } from '../../selectors/board/positions'

/*---------------------------------------------------------------------------*/

export const useSelectedItemPositions = () =>
  useSelector(selectedItemsPositionsSelector)
