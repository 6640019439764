/*---- External -------------------------------------------------------------*/

import React, { useState } from 'react'
import { generatePath, NavLink } from 'react-router-dom'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { ContainerIdProvider, useContainerId } from '../../contexts/Container'

import { useBoard } from '../../redux/selectorHooks/board/useBoard'

import { Routes } from '../../router/routes'

import BoardTitle from '../board/BoardTitle'
import { BoardMenu } from './BoardMenu'
import { BoardPreview } from './BoardPreview'
import { UserGallery } from '../user/UserGallery'
import { WorkspaceCard } from './WorkspaceCard'
import { UpdatedAt } from '../updatedAt/UpdatedAt'
import { BoardSharedIn } from './BoardSharedIn'

import { ContextMenuWrapper } from '../contextMenuWrapper/ContextMenuWrapper'

import { UUID } from 'canvas-shared/lib/types'

/*---------------------------------------------------------------------------*/

interface WorkspaceBoardCardProps {
  boardId: UUID
}

export const WorkspaceBoardCard: React.FC<WorkspaceBoardCardProps> = ({
  boardId,
}) => (
  <ContainerIdProvider containerId={boardId}>
    <WorkspaceBoardCardInner />
  </ContainerIdProvider>
)

const WorkspaceBoardCardInner = () => {
  const containerId = useContainerId()
  const { roles } = useBoard()

  const [contextMenuVisible, setContextMenuVisible] = useState(false)

  const boardPath = generatePath(Routes.CANVAS.path, {
    containerId,
  })

  return (
    <ContextMenuWrapper
      menu={<BoardMenu />}
      onMenuVisibilityChange={setContextMenuVisible}
    >
      <WorkspaceCard
        className={classNames(
          'bg-background-floating-light dark:bg-background-five-dark ring-border-normal-light dark:ring-border-normal-dark flex flex-col justify-between ring-1',
          {
            'ring-button-primary-light dark:ring-button-primary-dark': contextMenuVisible,
            'hover:ring-border-strong-light dark:hover:ring-border-strong-dark': !contextMenuVisible,
            'ring-2': contextMenuVisible,
          }
        )}
      >
        <NavLink
          className="relative flex-grow p-1 overflow-hidden"
          to={boardPath}
        >
          <BoardPreview />
        </NavLink>
        <div className="bg-background-floating-light dark:bg-background-five-dark border-border-normal-light dark:border-border-normal-dark flex flex-col flex-shrink-0 px-1.5 border-t">
          <BoardTitle className="mt-1 text-base font-medium" />
          <div className="flex flex-row items-center justify-between mb-3 ml-1.5 mr-2 space-x-1">
            <div className="flex flex-row items-center overflow-hidden space-x-1">
              <UserGallery roles={roles} />
              <BoardSharedIn />
            </div>
            <UpdatedAt className="text-xxs text-text-secondary-light dark:text-text-secondary-dark whitespace-nowrap font-light" />
          </div>
        </div>
      </WorkspaceCard>
    </ContextMenuWrapper>
  )
}
