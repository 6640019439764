/*---- External -------------------------------------------------------------*/

import React from 'react'

import { IMenuDividerProps, IMenuItemProps, Menu } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { JiraIssueSearchAction } from '../../../contexts/JiraIssueSearch'

import { useStatusReporter } from '../../../hooks/useStatusReporter'

import { isJiraBoardFeatureEnabledForItem } from '../../../helpers/isJiraBoardFeatureEnabledForItem'
import getExternalItemUrl from '../../../helpers/getExternalCardUrl'

import { Item } from 'canvas-shared/lib/types/Item.types'
import * as Jira from 'canvas-shared/lib/types/connections/Jira.types'
import * as JiraAPI from 'canvas-shared/lib/types/connections/JiraAPI.types'

import { UUID } from '../../../types'
import { CirclesThreePlus } from 'phosphor-react'

/*---------------------------------------------------------------------------*/

interface JiraContextMenuProps {
  jiraCards: Item.JiraCard[]
  jiraAssignUser: (ids: UUID[], account: Jira.Account) => Promise<void>
  jiraTransition: (
    ids: UUID[],
    transition: Jira.IssueTransition
  ) => Promise<void>
  jiraSetSprint: (ids: UUID[], sprint: JiraAPI.Sprint) => Promise<void>
  jiraUnsetSprint: (ids: UUID[]) => Promise<void>
  jiraPublishEstimates: (items: Item.JiraCard[]) => Promise<void>
  jiraAssignParent: (ids: UUID[], issueId: string) => Promise<void>
  showJiraIssueSearchBar: (newAction: JiraIssueSearchAction) => void
  statusReporter: ReturnType<typeof useStatusReporter>
}

const JiraContextMenu = ({
  jiraCards,
  jiraAssignUser,
  jiraTransition,
  jiraSetSprint,
  jiraUnsetSprint,
  jiraPublishEstimates,
  jiraAssignParent,
  showJiraIssueSearchBar,
  statusReporter: { reportError },
}: JiraContextMenuProps) => {
  const uniqTransitions = (
    column: Jira.IssueTransition,
    idx: number,
    list: Jira.IssueTransition[]
  ) => list.findIndex((c) => c.id === column.id) === idx
  const uniqAccounts = (
    account: Jira.Account,
    idx: number,
    list: Jira.Account[]
  ) => list.findIndex((a) => a.accountId === account.accountId) === idx
  const uniqSprints = (
    sprint: JiraAPI.Sprint,
    idx: number,
    list: JiraAPI.Sprint[]
  ) => list.findIndex((s) => s.id === sprint.id) === idx

  const activeJiraCards = jiraCards.filter((c) => !c.loading)

  const allTransitions = activeJiraCards
    .flatMap((c) => c.data?.jira?.context?.transitions)
    .filter(uniqTransitions)

  const allAccounts = activeJiraCards
    .flatMap((c) => c.data?.jira?.context?.accounts)
    .filter(uniqAccounts)

  const allSprints = activeJiraCards
    .flatMap((c) => c.data?.jira?.context?.sprints)
    .filter(uniqSprints)
    .map((sprint: JiraAPI.Sprint) => ({
      key: sprint.id,
      text: `${sprint.name} (${sprint.state})`,
      sprint,
    }))

  const backlog = { key: 'backlog', text: 'Backlog', sprint: null }

  const accountStyle = (id: string) => {
    return ''
  }

  const assignAccount = (account: Jira.Account) => {
    jiraAssignUser(
      activeJiraCards.map((c) => c.id),
      account
    )
  }

  const setSprint = (sprint: JiraAPI.Sprint | null) => {
    if (sprint) {
      jiraSetSprint(
        activeJiraCards.map((c) => c.id),
        sprint
      )
    } else {
      jiraUnsetSprint(activeJiraCards.map((c) => c.id))
    }
  }

  const transition = async (t: Jira.IssueTransition) => {
    try {
      await jiraTransition(
        activeJiraCards.map((c) => c.id),
        t
      )
    } catch (error) {
      reportError(error, 'Failed to move issue')
    }
  }

  const assignParent = () => {
    showJiraIssueSearchBar(async (item: JiraAPI.Issue) => {
      try {
        await jiraAssignParent(
          activeJiraCards.map((c) => c.id),
          item.id
        )
      } catch (error) {
        reportError(error, 'Failed to set parent issue')
      }
    })
  }

  const menuItems: (IMenuDividerProps | IMenuItemProps)[] = [
    {
      text: 'Move to',
      icon: 'two-columns',
      disabled: allTransitions.length < 1,
      children: allTransitions.map((t: Jira.IssueTransition) => (
        <Menu.Item
          key={t.id}
          text={t.name}
          disabled={!t.isAvailable}
          onClick={() => transition(t)}
        />
      )),
    },
    {
      text: 'Assign to',
      icon: 'new-person',
      disabled: allAccounts.length < 1,
      children: allAccounts.map((m: Jira.Account) => (
        <Menu.Item
          key={m.accountId}
          text={m.displayName}
          className={accountStyle(m.accountId)}
          onClick={() => assignAccount(m)}
        />
      )),
    },
  ]

  if (allSprints.length > 0) {
    menuItems.push({
      text: 'Sprint',
      icon: 'automatic-updates',
      disabled: activeJiraCards.length < 1,
      children: [...allSprints, backlog].map((s) => {
        return (
          <Menu.Item
            key={s.key}
            text={s.text}
            onClick={() => setSprint(s.sprint)}
          />
        )
      }),
    })
  }

  const isJiraBoardEstimationEnabled = jiraCards.some((i) =>
    isJiraBoardFeatureEnabledForItem(i, JiraAPI.BoardFeatureId.Estimation)
  )
  if (isJiraBoardEstimationEnabled) {
    menuItems.push({
      text: 'Publish estimates',
      icon: <CirclesThreePlus />,
      onClick: () => jiraPublishEstimates(activeJiraCards),
      disabled: activeJiraCards.length < 1,
    })
  }

  menuItems.push({
    text: 'Set parent',
    icon: 'diagram-tree',
    onClick: assignParent,
    disabled: activeJiraCards.length < 1,
  })

  if (jiraCards.length === 1 && activeJiraCards.length === 1) {
    const url = getExternalItemUrl(activeJiraCards[0])

    if (url) {
      menuItems.push({
        text: 'Open in Jira',
        icon: 'link',
        href: url,
        target: '_blank',
      })
    }
  }

  return menuItems
}

export default JiraContextMenu
