/*---- External -------------------------------------------------------------*/

import React, { useContext } from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

type ReturnFunc<T> = () => T

/**
 * Useful for all contexts without default value and therefore
 * with Partial type definition. Instead of having to validate
 * the value in the Provider every time it's being accessed,
 * you can use this hook to create a custom hook, returning the
 * value from the provider, cast to the correct type (or an error).
 *
 * Example usage:
 * ```typescript
 * type Context {
 *   title: string
 * }
 *
 * const ExampleContext = createContext<Partial<Context>>({})
 *
 * // Provider code omitted
 *
 * export const useExampleContext = ensureContextValue<Context>(ExampleContext, 'useExampleContext')
 * ```
 *
 * @typeParam Context   Type of the context
 * @param context       Context object, created with `createContext`
 * @param name          Name of the hook (for friendly error messages)
 * @returns             A function that returns an object of type Context
 */
const ensureContextValue = <T,>(
  context: React.Context<Partial<T>>,
  name: string
): ReturnFunc<T> => {
  return () => {
    const ctx = useContext(context)

    if (!ctx) {
      throw new Error(`${name} cannot be used with Provider without value`)
    }

    return ctx as T
  }
}

export default ensureContextValue
