/*---- External -------------------------------------------------------------*/

import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  createEmptyPendingItemAction,
  createMultiplePendingItemsAction,
  duplicateItemsWithDragAction,
} from '../../../actionCreators/pending'

import { PendingStateSlice } from '../../../../types/redux'
import {
  pendingCancelAllAction,
  pendingCommitAllAction,
} from '../../../actionCreators/pending'
import { movePendingPositionsToAction } from '../../../actionCreators/positions'
import {
  ItemPosition,
  LinePosition,
  PseudoDOMRect,
} from 'canvas-shared/lib/types/Position.types'
import { isRectPosition } from 'canvas-shared/lib/helpers/isRectPosition'
import { isLinePosition } from 'canvas-shared/lib/helpers/isLinePosition'
import getBoundingRectFromPositions from '../../../../helpers/getBoundingRectFromPositions'
import {
  MousePosition,
  ScrollDimensions,
} from 'canvas-shared/lib/types/scrollAndPosition.types'
import { resetSessionAction } from '../../../actionCreators/session'

import { generatePseudoDOMRect } from 'canvas-shared/lib/helpers/generatePseudoDOMRect'
import { CURRENT_TYPE_VERSION } from 'canvas-shared/lib/config/migration'
import {
  DEFAULT_TEXTBOX_WIDTH,
  DEFAULT_TEXTBOX_HEIGHT,
} from 'canvas-shared/lib/config/positions'
import { Item, ItemType } from 'canvas-shared/lib/types/Item.types'
import { app } from '../../../../config/firebase'
import {
  startDrawingTextboxAction,
  startDrawingRectangleAction,
  startDrawingLineAction,
} from '../../../actionCreators/shapes'
import { generateOperation } from '../../../actions/helpers/generateOperation'
import { pasteAction } from '../../../actionCreators/clipboard'
import { pointerMoveAction } from '../../../actionCreators/pointers'
import { scrollOrZoomChangedAction } from '../../../actionCreators/scroll'
import { CursorMode } from 'canvas-shared/lib/types/CursorMode.types'

/*---------------------------------------------------------------------------*/

const initialState: PendingStateSlice = {
  items: {},
  positions: {},
}

const extraReducers = (builder: ActionReducerMapBuilder<PendingStateSlice>) => {
  builder

    .addCase(resetSessionAction, () => initialState)
    .addCase(pendingCancelAllAction, () => initialState)
    .addCase(pendingCommitAllAction.fulfilled, () => initialState)

    .addCase(createEmptyPendingItemAction.fulfilled, (state, action) => {
      state.items[action.payload.item.id] = action.payload.item
      state.positions[action.payload.position.id] = action.payload.position
    })

    .addCase(createMultiplePendingItemsAction.fulfilled, (state, action) => {
      state.items = action.payload.items
      state.positions = action.payload.positions
    })

    .addCase(movePendingPositionsToAction.fulfilled, (state, action) => {
      const {
        position: [x, y],
        canvas,
      } = action.payload

      const rect = getBoundingRectFromPositions(Object.values(state.positions))
      const newRect = centerRectAroundPosition(rect, [x, y], canvas)

      Object.values(state.positions).forEach((position) => {
        if (isRectPosition(position)) {
          position.left += newRect.left - rect.left
          position.top += newRect.top - rect.top
        } else if (isLinePosition(position)) {
          const relativeStartX = position.startX - rect.left
          const relativeStartY = position.startY - rect.top
          const relativeEndX = position.endX - rect.left
          const relativeEndY = position.endY - rect.top
          position.startX = relativeStartX + newRect.left
          position.startY = relativeStartY + newRect.top
          position.endX = relativeEndX + newRect.left
          position.endY = relativeEndY + newRect.top
        }
      })
    })

    .addCase(startDrawingTextboxAction.fulfilled, (state, action) => {
      const { gesture, uid, data, startZ } = action.payload
      const itemId = gesture.uniqueId

      const operation = generateOperation(uid)

      const startLeft = gesture.startCoords.canvasX
      const startTop = gesture.startCoords.canvasY
      const endLeft = gesture.endCoords.canvasX
      const endTop = gesture.endCoords.canvasY
      const position: ItemPosition = {
        id: itemId,
        operation,
        left: startLeft,
        top: startTop,
        width: Math.max(DEFAULT_TEXTBOX_WIDTH, endLeft - startLeft),
        height: Math.max(DEFAULT_TEXTBOX_HEIGHT, endTop - startTop),
        z: startZ,
      }
      const item: Item.Text = {
        id: itemId,
        operation,
        typeVersion: CURRENT_TYPE_VERSION,
        createdAt: app.firestore.Timestamp.now(),
        createdBy: operation,
        updatedAt: app.firestore.Timestamp.now(),
        updatedBy: operation,
        type: ItemType.Text,
        data: data as Item.TextItemData,
      }

      state.positions[position.id] = position
      state.items[item.id] = item
    })
    .addCase(startDrawingRectangleAction.fulfilled, (state, action) => {
      const { gesture, uid, data, startZ } = action.payload
      const itemId = gesture.uniqueId

      const operation = generateOperation(uid)

      const startLeft = gesture.startCoords.canvasX
      const startTop = gesture.startCoords.canvasY
      const endLeft = gesture.endCoords.canvasX
      const endTop = gesture.endCoords.canvasY
      const position: ItemPosition = {
        id: itemId,
        operation,
        left: startLeft,
        top: startTop,
        width: endLeft - startLeft,
        height: endTop - startTop,
        z: startZ,
      }
      const item: Item.Rectangle = {
        id: itemId,
        operation,
        typeVersion: CURRENT_TYPE_VERSION,
        createdAt: app.firestore.Timestamp.now(),
        createdBy: operation,
        updatedAt: app.firestore.Timestamp.now(),
        updatedBy: operation,
        type: ItemType.Rectangle,
        data: data as Item.TextItemData,
      }

      state.positions[position.id] = position
      state.items[item.id] = item
    })

    .addCase(startDrawingLineAction.fulfilled, (state, action) => {
      const { gesture, uid, data, startZ } = action.payload
      const itemId = gesture.uniqueId

      const operation = generateOperation(uid)

      const startLeft = gesture.startCoords.canvasX
      const startTop = gesture.startCoords.canvasY
      const endLeft = gesture.endCoords.canvasX
      const endTop = gesture.endCoords.canvasY
      const position: LinePosition = {
        id: itemId,
        operation,
        startX: startLeft,
        startY: startTop,
        endX: endLeft,
        endY: endTop,
        z: startZ,
      }
      const item: Item.Line = {
        id: itemId,
        operation,
        typeVersion: CURRENT_TYPE_VERSION,
        createdAt: app.firestore.Timestamp.now(),
        createdBy: operation,
        updatedAt: app.firestore.Timestamp.now(),
        updatedBy: operation,
        type: ItemType.Line,
        data: data as Item.LineItemData,
      }

      state.positions[position.id] = position
      state.items[item.id] = item
    })

    .addCase(duplicateItemsWithDragAction.fulfilled, (state, action) => {
      state.items = action.payload.items
      state.positions = action.payload.positions
    })

    .addCase(pasteAction.fulfilled, (state, action) => {
      const items = action.payload.items
      const positions = action.payload.positions

      if (!!items && !!positions) {
        state.items = items
        state.positions = positions
      }
    })

    .addCase(pointerMoveAction.fulfilled, (state, action) => {
      const { gesture } = action.payload
      if (!!gesture && !gesture.finished) {
        const { cursorMode } = gesture
        const position = state.positions[gesture.uniqueId]

        if (!position) return

        if (
          (cursorMode === CursorMode.DRAW_RECTANGLE ||
            cursorMode === CursorMode.ADD_TEXT) &&
          isRectPosition(position)
        ) {
          const startLeft = gesture.startCoords.canvasX
          const startTop = gesture.startCoords.canvasY
          let endLeft = gesture.endCoords.canvasX
          let endTop = gesture.endCoords.canvasY

          if (gesture.shiftKey) {
            const delta = Math.max(endLeft - startLeft, endTop - startTop)
            endLeft = startLeft + delta
            endTop = startTop + delta
          }
          position.width = Math.abs(endLeft - startLeft)
          position.height = Math.abs(endTop - startTop)

          if (startLeft > endLeft) {
            position.left = endLeft
          }
          if (startTop > endTop) {
            position.top = endTop
          }
        } else if (
          cursorMode === CursorMode.DRAW_LINE &&
          isLinePosition(position)
        ) {
          const startLeft = position.startX
          const startTop = position.startY
          let endLeft = gesture.endCoords.canvasX
          let endTop = gesture.endCoords.canvasY

          if (gesture.shiftKey) {
            const distanceLeft = Math.abs(endLeft - startLeft)
            const distanceTop = Math.abs(endTop - startTop)

            if (distanceLeft < distanceTop) {
              endLeft = startLeft
            } else {
              endTop = startTop
            }
          }
          position.endX = endLeft
          position.endY = endTop
        }
      }
    })

    .addCase(scrollOrZoomChangedAction.fulfilled, (state, action) => {
      const { gesture } = action.payload
      if (!!gesture && !gesture.finished) {
        const { cursorMode } = gesture
        const position = state.positions[gesture.uniqueId]

        if (!position) return

        if (
          (cursorMode === CursorMode.DRAW_RECTANGLE ||
            cursorMode === CursorMode.ADD_TEXT) &&
          isRectPosition(position)
        ) {
          const startLeft = gesture.startCoords.canvasX
          const startTop = gesture.startCoords.canvasY
          let endLeft = gesture.endCoords.canvasX
          let endTop = gesture.endCoords.canvasY

          if (gesture.shiftKey) {
            const delta = Math.max(endLeft - startLeft, endTop - startTop)
            endLeft = startLeft + delta
            endTop = startTop + delta
          }
          position.width = endLeft - startLeft
          position.height = endTop - startTop
        } else if (
          cursorMode === CursorMode.DRAW_LINE &&
          isLinePosition(position)
        ) {
          const startLeft = position.startX
          const startTop = position.startY
          let endLeft = gesture.endCoords.canvasX
          let endTop = gesture.endCoords.canvasY

          if (gesture.shiftKey) {
            const distanceLeft = Math.abs(endLeft - startLeft)
            const distanceTop = Math.abs(endTop - startTop)

            if (distanceLeft < distanceTop) {
              endLeft = startLeft
            } else {
              endTop = startTop
            }
          }
          position.endX = endLeft
          position.endY = endTop
        }
      }
    })
}

const centerRectAroundPosition = (
  rect: PseudoDOMRect,
  [x, y]: MousePosition,
  { scrollWidth, scrollHeight }: ScrollDimensions
) => {
  const { width, height } = rect

  const newX = Math.max(Math.floor(x - width / 2), 0)
  const newY = Math.max(Math.floor(y - height / 2), 0)

  let newRect = generatePseudoDOMRect(newX, newY, width, height)

  if (newRect.right > scrollWidth) {
    newRect = generatePseudoDOMRect(
      newRect.x - (newRect.right - scrollWidth),
      newRect.y,
      width,
      height
    )
  }

  if (newRect.bottom > scrollHeight) {
    newRect = generatePseudoDOMRect(
      newRect.x,
      newRect.y - (newRect.bottom - scrollHeight),
      width,
      height
    )
  }

  return newRect
}

export const pendingSlice = createSlice({
  name: 'pending',
  reducers: {},
  initialState,
  extraReducers,
})
