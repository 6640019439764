/*---- External -------------------------------------------------------------*/

import React, { useCallback } from 'react'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/
import { LinearPreviewCardBody } from './linearPreviewCard/LinearPreviewCardBody'
import { PreviewCardAddedToBoard } from '../PreviewCardAddedToBoard'

import { PreviewItemWithBoardPresence } from 'canvas-shared/lib/types/PreviewItem.types'

/*---------------------------------------------------------------------------*/

interface LinearPreviewCardProps {
  className?: string
  checked: boolean
  onChange: (value: PreviewItemWithBoardPresence.LinearCard) => void
  issue: PreviewItemWithBoardPresence.LinearCard
}

export const LinearPreviewCard: React.FC<LinearPreviewCardProps> = ({
  className,
  checked,
  onChange,
  issue,
}) => {
  const { presentOnBoard } = issue
  const onClick = useCallback(() => {
    onChange(issue)
  }, [issue, onChange])

  const onKeyPress = useCallback(
    (event) => {
      if (event.code === 'Space') {
        onChange(issue)
        event.preventDefault()
      }
    },
    [issue, onChange]
  )

  return (
    <div
      className={classNames(
        'border-border-weak-light dark:border-border-weak-dark p-3 border rounded',
        {
          'hover:border-border-normal-light dark:hover:border-border-normal-dark':
            !checked && !presentOnBoard,
          'bg-button-secondary-selected-light dark:bg-button-secondary-selected-dark border-border-accent-light dark:border-border-accent-dark': checked,
          'active:bg-button-secondary-selected-light dark:active:bg-button-secondary-selected-dark cursor-pointer': !presentOnBoard,
          'pointer-events-none cursor-not-allowed': presentOnBoard,
        }
      )}
      tabIndex={0}
      aria-checked={checked}
      aria-labelledby={issue.id}
      onClick={onClick}
      onKeyPress={onKeyPress}
    >
      <h3
        id={issue.id}
        className="text-text-primary-light dark:text-text-primary-dark mb-3 text-sm"
      >
        {issue.data.title}
      </h3>
      {presentOnBoard ? (
        <PreviewCardAddedToBoard />
      ) : (
        <LinearPreviewCardBody issue={issue} />
      )}
    </div>
  )
}
