import React from 'react'
import { Spinner } from '@blueprintjs/core'
import { FunnelSimple } from 'phosphor-react'

interface Props {
  customJqlPresent: boolean
  visibleFilterCount: number
  backgroundDataRefreshing: boolean
  loading: boolean
  resultCount: number | undefined
}

export const JiraContentBrowserResultSummary: React.FC<Props> = ({
  customJqlPresent,
  visibleFilterCount,
  backgroundDataRefreshing,
  loading,
  resultCount,
}) => {
  let resultCountText: string

  switch (resultCount) {
    case 0:
      resultCountText = backgroundDataRefreshing
        ? 'Loading results...'
        : '0 results'
      break
    case 1:
      resultCountText = '1 result'
      break
    default:
      resultCountText = `${resultCount} results`
      break
  }

  return (
    <div className="flex items-center space-x-2">
      {customJqlPresent && (
        <>
          <em>Custom JQL</em>
          <span>&middot;</span>
        </>
      )}
      {visibleFilterCount > 0 && (
        <>
          <FunnelSimple
            className="text-icon-primary-light dark:text-icon-secondary-dark"
            size={16}
            weight="bold"
          />
          <span>
            {visibleFilterCount} filter
            {visibleFilterCount > 1 ? 's' : ''}
          </span>
          <span>&middot;</span>
        </>
      )}
      {loading ? (
        <span>Updating results… </span>
      ) : (
        <>
          <span className="text-text-secondary-light dark:text-text-secondary-dark text-sm">
            {resultCountText}
          </span>
          {backgroundDataRefreshing && <Spinner size={14} />}
        </>
      )}
    </div>
  )
}
