/*---- External -------------------------------------------------------------*/

import { Crop } from 'react-image-crop'

/*---- Qualdesk -------------------------------------------------------------*/

import { USER_PHOTO_OUTPUT_FORMAT } from 'canvas-shared/lib/config/userPhoto'

/*---------------------------------------------------------------------------*/

export function getCroppedImage(
  image: HTMLImageElement | undefined,
  crop: Crop
): Promise<Blob> {
  if (!image) {
    return Promise.reject(new Error('Missing image'))
  }

  if (
    crop.width === undefined ||
    crop.height === undefined ||
    crop.x === undefined ||
    crop.y === undefined
  ) {
    return Promise.reject(
      new Error(`Missing crop data, received ${JSON.stringify(crop)}`)
    )
  }

  const canvas = document.createElement('canvas')
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  canvas.width = crop.width
  canvas.height = crop.height
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return Promise.reject()
  }

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  )

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (!!blob) {
          resolve(blob)
        } else {
          reject()
        }
      },
      USER_PHOTO_OUTPUT_FORMAT,
      1
    )
  })
}
