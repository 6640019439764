/*---- External -------------------------------------------------------------*/

import { Epic } from 'redux-observable'
import { delayWhen, filter, map } from 'rxjs/operators'
import { timer } from 'rxjs'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  dismissToastAction,
  showToastAction,
  updateToastAction,
} from '../actionCreators/toast'

import { ActionWithPayload, RootState, ToastPiece } from '../../types/redux'
import { UUID } from 'canvas-shared/lib/types'

/*---------------------------------------------------------------------------*/

const DEFAULT_TOAST_TIMEOUT = 5000

const isShowOrUpdateAction = (
  a: ActionWithPayload<ToastPiece> | ActionWithPayload<UUID>
): a is ActionWithPayload<ToastPiece> =>
  showToastAction.fulfilled.match(a) || updateToastAction.match(a)

export const toastEpic: Epic<
  ActionWithPayload<ToastPiece> | ActionWithPayload<UUID>,
  ActionWithPayload<UUID>,
  RootState
> = (action) =>
  action.pipe(
    filter(isShowOrUpdateAction),
    filter(
      (a) =>
        a.payload.toast.timeout === undefined || a.payload.toast.timeout > 0
    ),
    delayWhen((a) => timer(a.payload.toast.timeout || DEFAULT_TOAST_TIMEOUT)),
    map((a) => dismissToastAction(a.payload.id))
  )
