/*---- External -------------------------------------------------------------*/

import React, { useEffect, useMemo, useState } from 'react'

import { Redirect, Route, useLocation } from 'react-router-dom'

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { useRedirectPath } from '../hooks/router/useRedirectPath'

import {
  selectAuthLoaded,
  selectMyName,
  selectProfileLoaded,
  selectSignedIn,
} from '../redux/selectors/auth/auth'

import { VerifiedRouteProps, RouteAuthType, Routes } from './routes'

import Loading from '../components/loading/Loading'

import AuthenticatedRoute from './AuthenticatedRoute'
import RedirectWithReturn from './RedirectWithReturn'

/*---------------------------------------------------------------------------*/

const VerifiedRoute: React.FC<VerifiedRouteProps> = ({
  auth,
  maxAuth,
  children,
  computedMatch,
  ...props
}) => {
  const authenticated = useSelector(selectSignedIn)
  const authLoaded = useSelector(selectAuthLoaded)
  const userDetailsLoaded = useSelector(selectProfileLoaded)
  const name = useSelector(selectMyName)

  const currentAuth = useMemo(() => {
    if (authenticated) {
      if (userDetailsLoaded && !!name) {
        return RouteAuthType.DETAILS_COMPLETE
      } else {
        return RouteAuthType.SIGNED_IN
      }
    } else {
      return RouteAuthType.PUBLIC
    }
  }, [authenticated, name, userDetailsLoaded])

  const { pathname } = useLocation()

  const [redirectPath, setRedirectPath] = useRedirectPath()
  const [thisRedirectPath, setThisRedirectPath] = useState<string>()

  const shouldRedirect =
    authenticated && maxAuth !== undefined && currentAuth >= maxAuth

  useEffect(() => {
    if (shouldRedirect) {
      const newRedirectPath = !!redirectPath ? redirectPath : Routes.HOME.path

      if (thisRedirectPath !== newRedirectPath) {
        setThisRedirectPath(newRedirectPath)
      }
      setRedirectPath(undefined)
    }
  }, [redirectPath, setRedirectPath, shouldRedirect, thisRedirectPath])

  if (!authLoaded) {
    // Wait for auth to load
    return <Loading />
  }

  if (auth < RouteAuthType.SIGNED_IN && !authenticated) {
    // Immediately render a route if it doesn’t require auth and user is not signed in
    return <Route {...props}>{children}</Route>
  }

  // In these next two scenarios, we capture the users’ current pathname
  // so that we can redirect them back once they’ve completed the task

  if (!authenticated) {
    // If not signed in, redirect to sign in page
    return <RedirectWithReturn from={pathname} to={Routes.AUTH_SIGN_IN.path} />
  }

  if (!userDetailsLoaded) {
    // Wait for user details to load
    return <Loading />
  }

  if (
    auth >= RouteAuthType.DETAILS_COMPLETE &&
    currentAuth < RouteAuthType.DETAILS_COMPLETE
  ) {
    // If route requires it, and user profile is missing details, redirect
    return (
      <RedirectWithReturn from={pathname} to={Routes.AUTH_USER_DETAILS.path} />
    )
  }

  if (shouldRedirect) {
    // User is ‘too authenticated’
    // (e.g. trying to view user details page when they’ve already been provided)

    if (!!thisRedirectPath) {
      return <Redirect to={thisRedirectPath} />
    } else {
      return <Loading />
    }
  }

  // Ready to render authenticated route
  return (
    <Route {...props}>
      <AuthenticatedRoute>{children}</AuthenticatedRoute>
    </Route>
  )
}

export default VerifiedRoute
