"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.guessNameFromEmail = void 0;
const capitalizeFirstLetter_1 = require("./capitalizeFirstLetter");
const guessNameFromEmail = (maybeEmail) => {
    if (!maybeEmail) {
        return '';
    }
    const namePart = maybeEmail.split('@')[0];
    if (namePart.length < 3) {
        return '';
    }
    const splitByDot = namePart.split('.');
    if (splitByDot.length > 1) {
        return splitByDot.map((s) => capitalizeFirstLetter_1.capitalizeFirstLetter(s)).join(' ');
    }
    const splitByUnderscore = namePart.split('_');
    if (splitByUnderscore.length > 1) {
        return splitByUnderscore.map((s) => capitalizeFirstLetter_1.capitalizeFirstLetter(s)).join(' ');
    }
    return capitalizeFirstLetter_1.capitalizeFirstLetter(namePart);
};
exports.guessNameFromEmail = guessNameFromEmail;
