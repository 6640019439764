/*---- External -------------------------------------------------------------*/

import { createAsyncThunkWithUpdateMetadata } from './helpers/createAsyncThunkWithUpdateMetadata'

/*---- Qualdesk -------------------------------------------------------------*/

import { ColorKey } from 'canvas-shared/lib/types/color.types'
import {
  TextAlignmentKey,
  TextStyleKey,
} from 'canvas-shared/lib/types/text.types'

/*---------------------------------------------------------------------------*/

export const itemSetTitleAction = createAsyncThunkWithUpdateMetadata<{
  itemId: string
  title: string
}>('item/setTitle')

export const itemSetColumnAction = createAsyncThunkWithUpdateMetadata<{
  column: number
  itemId: string
  text: string
}>('item/setColumn')

export const itemsSetBackgroundColorAction = createAsyncThunkWithUpdateMetadata<{
  ids: string[]
  color: ColorKey | null
}>('item/setBackgroundColor')

export const itemsSetLineColorAction = createAsyncThunkWithUpdateMetadata<{
  ids: string[]
  color: ColorKey
}>('item/setLineColor')

export const itemsSetLineThicknessAction = createAsyncThunkWithUpdateMetadata<{
  ids: string[]
  thickness: number
}>('item/setLineThickness')

export const itemsSetTextAlignmentAction = createAsyncThunkWithUpdateMetadata<{
  ids: string[]
  alignment: TextAlignmentKey
}>('item/setTextAlignment')

export const itemsSetTextSizeAction = createAsyncThunkWithUpdateMetadata<{
  ids: string[]
  size: number
}>('item/setTextSize')

export const itemsSetTextColorAction = createAsyncThunkWithUpdateMetadata<{
  ids: string[]
  color: ColorKey
}>('item/setTextColor')

export const itemsAddTextStyleAction = createAsyncThunkWithUpdateMetadata<{
  ids: string[]
  style: TextStyleKey
}>('item/addTextStyle')

export const itemsRemoveTextStyleAction = createAsyncThunkWithUpdateMetadata<{
  ids: string[]
  style: TextStyleKey
}>('item/removeTextStyle')

export const itemsAddEstimateVoteAction = createAsyncThunkWithUpdateMetadata<{
  ids: string[]
  value: number
}>('item/addEstimateVote')

export const itemsRemoveEstimateVoteAction = createAsyncThunkWithUpdateMetadata<{
  ids: string[]
}>('item/removeEstimateVote')

export const itemsResolveEstimateAction = createAsyncThunkWithUpdateMetadata<{
  ids: string[]
  value: number
}>('item/resolveEstimate')

export const itemsClearAllEstimateVotesAction = createAsyncThunkWithUpdateMetadata(
  'item/clearAllEstimateVotes'
)

export const itemsClearEstimatesAction = createAsyncThunkWithUpdateMetadata<{
  ids: string[]
}>('item/clearEstimates')

export const itemsAddEmojiAction = createAsyncThunkWithUpdateMetadata<{
  ids: string[]
  emojiId: string
}>('item/addEmoji')

export const itemsRemoveEmojiAction = createAsyncThunkWithUpdateMetadata<{
  ids: string[]
  emojiId: string
}>('item/removeEmoji')

export const itemsAddVoteAction = createAsyncThunkWithUpdateMetadata<{
  ids: string[]
}>('item/addVote')

export const itemsRemoveVoteAction = createAsyncThunkWithUpdateMetadata<{
  ids: string[]
}>('item/removeVote')

export const itemsClearAllEmojisAction = createAsyncThunkWithUpdateMetadata(
  'item/clearAllEmojis'
)

export const itemsClearAllVotesAction = createAsyncThunkWithUpdateMetadata(
  'item/clearAllVotes'
)

export const itemsClearEmojisAction = createAsyncThunkWithUpdateMetadata<{
  ids: string[]
}>('item/clearEmojis')

export const itemsClearVotesAction = createAsyncThunkWithUpdateMetadata<{
  ids: string[]
}>('item/clearVotes')
