/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import {
  zoomInAction,
  zoomOutAction,
  zoomSetAction,
  zoomToDefaultAction,
} from '../actionCreators/zoom'

import { generateZoomLevelPath } from '../paths/zoomLevelPath'
import { generateScrollOffsetsPath } from '../paths/scrollPaths'

import { scrollOffsetsSelector } from '../selectors/board/scroll'
import { selectZoomLevel } from '../selectors/board/zoom'

import { createRTDBEpic } from './helpers/createRTDBEpic'

/*---------------------------------------------------------------------------*/

export const zoomEpic = createRTDBEpic<number>(
  [zoomSetAction, zoomToDefaultAction, zoomInAction, zoomOutAction],
  ({ state, uid, containerId, database }) => {
    database()
      .ref(generateZoomLevelPath(containerId, uid))
      .set(selectZoomLevel(state))
    database()
      .ref(generateScrollOffsetsPath(containerId, uid))
      .set(scrollOffsetsSelector(state))
  }
)
