/*---- External -------------------------------------------------------------*/

import React from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { useUser } from '../../redux/selectorHooks/users/useUser'

/*---------------------------------------------------------------------------*/

interface UserEmailProps {
  className?: string
  uid: string
}

export const UserEmail: React.FC<UserEmailProps> = ({ className, uid }) => {
  const user = useUser(uid)
  const name = !!user && !!user.email ? user.email : 'Unknown email'
  return <div className={className}>{name}</div>
}
