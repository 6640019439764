/*---- External -------------------------------------------------------------*/

import React, { useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import classNames from 'classnames'
import { Route } from 'react-router-dom'

import { Helmet } from 'react-helmet-async'

/*---- Qualdesk -------------------------------------------------------------*/

import { Routes } from '../../router/routes'
import {
  CanvasWindowProvider,
  useCanvasWindowContext,
} from '../../contexts/CanvasWindow'
import { SearchProvider, useSearchContext } from '../../contexts/Search'
import { CanvasInteractionModeToast } from '../../components/canvas/CanvasInteractionModeToast'
import { CanvasTimerToast } from '../../components/canvas/CanvasTimerToast'

import { JiraIssueSearchProvider } from '../../contexts/JiraIssueSearch'

import NoAccess from '../auth/NoAccess'

import { ContainerIdProvider, useContainerId } from '../../contexts/Container'

import { useTypedParams } from '../../hooks/helpers/useTypedParams'

import { useKeyboardEvents } from '../../hooks/board/useKeyboardEvents'
import { useWindowPointerUpHandlers } from '../../hooks/window/useWindowPointerUpHandlers'

import CanvasContextMenu from '../../components/canvas/CanvasContextMenu'
import CanvasDragSelectionRect from '../../components/canvas/CanvasDragSelectionRect'
import { CanvasItems } from '../../components/canvas/CanvasItems'
import CanvasPasteHandler from '../../components/canvas/CanvasPasteHandler'
import CanvasPresenceCursors from '../../components/canvas/CanvasPresenceCursors'
import { CanvasViewControls } from '../../components/canvas/CanvasViewControls'

import { BoardNavigation } from '../../components/navigation/BoardNavigation'
import Loading from '../../components/loading/Loading'
import { Fab as SidebarFab } from '../../components/connectionsSidebar/Fab'
import { CanvasScroller } from '../../components/canvas/CanvasScroller'

import { useBoardConnectors } from '../../redux/connectors/useBoardConnectors'

import { openContextMenuAction } from '../../redux/actionCreators/contextMenu'

import { useCanvasGoToItem } from '../../hooks/useCanvasGoToItem'
import { useBoardEffects } from '../../redux/effects/useBoardEffects'
import { useScrollEventHandlers } from '../../hooks/board/useScrollEventHandlers'
import { useWheelEventHandlers } from '../../hooks/board/useWheelEventHandlers'
import { containerSelector } from '../../redux/selectors/container/containers'
import { sessionLoadedSelector } from '../../redux/selectors/board/session'
import { usePositionsLoaded } from '../../redux/selectorHooks/board/usePositionsLoaded'
import { selectCursorClassName } from '../../redux/selectors/board/cursor'
import { selectItemsLoaded } from '../../redux/selectors/board/items'
import { selectDynamicItemsLoaded } from '../../redux/selectors/board/groups'

import { usePointerDownHandler } from '../../hooks/board/pointerEvents/usePointerDownHandler'
import { usePointerMoveHandler } from '../../hooks/board/pointerEvents/usePointerMoveHandler'

import { RootState } from '../../types/redux'
import { useUserPresence } from '../../hooks/useUserPresence'
import { useImportFromUrl } from '../../hooks/connections/useImportFromUrl'
import { Sidebar } from '../../components/connectionsSidebar/Sidebar'

/*---------------------------------------------------------------------------*/

const Canvas: React.FC = () => {
  const { containerId } = useTypedParams()

  if (!containerId) {
    return <NoAccess />
  }

  return (
    <SearchProvider>
      <ContainerIdProvider containerId={containerId}>
        <CanvasWindowProvider>
          <div className="flex flex-col h-screen">
            <BoardLoader />
          </div>
        </CanvasWindowProvider>
      </ContainerIdProvider>
    </SearchProvider>
  )
}

const BoardLoader: React.FC = () => {
  const containerId = useContainerId()
  const boardSelector = containerSelector(containerId)
  const board = useSelector((state: RootState) =>
    boardSelector(state, containerId)
  )

  if (!!board) {
    return (
      <>
        <Helmet title={board.data.title || 'Untitled Board'} />
        <BoardWrapper />
      </>
    )
  }

  return (
    <div className="absolute left-0 top-0 w-full h-full">
      <Loading />
    </div>
  )
}

const BoardWrapper: React.FC = () => {
  const containerId = useContainerId()
  const positionsLoaded = usePositionsLoaded()
  const itemsLoaded = useSelector(selectItemsLoaded)
  const dynamicItemsLoaded = useSelector(selectDynamicItemsLoaded)
  const sessionLoaded = useSelector(sessionLoadedSelector)

  useBoardConnectors(containerId)

  if (positionsLoaded && itemsLoaded && dynamicItemsLoaded && sessionLoaded) {
    return (
      <JiraIssueSearchProvider>
        <BoardNavigation />
        <CanvasInner />
      </JiraIssueSearchProvider>
    )
  }

  return <LoadingWithNavigation />
}

const CanvasInner: React.FC = () => {
  useBoardEffects()
  useKeyboardEvents()
  useScrollEventHandlers()
  useWheelEventHandlers()
  useUserPresence()
  useCanvasGoToItem()
  useImportFromUrl()
  useWindowPointerUpHandlers()

  const { canvasRef } = useCanvasWindowContext()
  const { searchQuery } = useSearchContext()
  const onPointerDown = usePointerDownHandler()
  const onPointerMove = usePointerMoveHandler()
  const cursorClassName = useSelector(selectCursorClassName)
  const dispatch = useDispatch()

  const onContextMenu = useCallback(
    (e: React.MouseEvent) => dispatch(openContextMenuAction(e)),
    [dispatch]
  )

  return (
    <>
      <CanvasInteractionModeToast />
      <CanvasTimerToast />
      <div className="flex flex-col flex-grow">
        <CanvasViewControls />
        <div className="flex flex-1">
          <div
            id="canvas-gutter"
            className={classNames(
              'relative flex flex-col flex-grow overflow-hidden',
              !!searchQuery
                ? 'bg-blue-gray-700 dark:bg-cool-gray-900'
                : 'bg-blue-gray-200 dark:bg-cool-gray-900',
              cursorClassName
            )}
            onContextMenu={onContextMenu}
            onPointerDown={onPointerDown}
            onPointerMove={onPointerMove}
            ref={canvasRef}
          >
            <CanvasScroller>
              <CanvasItems />
              <CanvasDragSelectionRect />
              <CanvasPresenceCursors />
            </CanvasScroller>
            <div className="z-above-items fixed bottom-2 right-2">
              <SidebarFab />
            </div>
          </div>
          <Route path={Routes.CANVAS_CONNECTIONS.path}>
            <Sidebar />
          </Route>
        </div>
        <CanvasContextMenu />
        <CanvasPasteHandler />
      </div>
    </>
  )
}

interface LoadingProps {
  spinnerValue?: number
  text?: string
}

const LoadingWithNavigation: React.FC<LoadingProps> = ({
  spinnerValue,
  text,
}) => (
  <>
    <BoardNavigation />
    <div className="absolute left-0 top-0 w-full h-full">
      <Loading value={spinnerValue} text={text} />
    </div>
  </>
)

export default Canvas
