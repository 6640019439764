/*---- External -------------------------------------------------------------*/

import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Classes } from '@blueprintjs/core'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { STATUS_CATEGORY_COLOR_MAP } from '../../../config/jira'

import { safeString } from '../../../helpers/safeString'

import { itemSetTitleAction } from '../../../redux/actionCreators/itemData'
import { useEditsActions } from '../../../redux/actions/board/useEditsActions'
import { useSelectedItemIds } from '../../../redux/selectorHooks/board/useSelectedItemIds'
import { selectInteractionMode } from '../../../redux/selectors/board/interactionModes'

import { JiraIssueLabel } from './JiraIssueLabel'
import { EstimationControls, EstimationValue } from '../../items/ItemEstimate'
import { MultilineTextInput } from '../../multilineTextInput/MultilineTextInput'

import { InteractionMode } from '../../../types'

import { Item } from 'canvas-shared/lib/types/Item.types'
import { StatusCategoryKey } from 'canvas-shared/lib/types/connections/Jira.types'

import jiraIcon from '../../../assets/integrations/jira/jira-icon.svg'

/*---------------------------------------------------------------------------*/

export const JiraCard = ({
  id,
  data,
  loading,
  operation,
}: Item.JiraCardMaybeMissingData) => {
  const titleFromData = safeString(data.title)
  const { jira: jiraData } = data

  const [title, setTitle] = useState(titleFromData)
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    setTitle(titleFromData)
  }, [titleFromData])

  const dispatch = useDispatch()
  const { addEdit, removeEdit } = useEditsActions()
  const selectedItemIds = useSelectedItemIds()

  const handleChange = (value: string) => {
    setTitle(value)
  }

  const handleConfirm = (value: string) => {
    stopEditing()
    dispatch(itemSetTitleAction({ itemId: id, title: value }))
  }

  const startEditing = useCallback(() => {
    setEditing(true)
    addEdit(id)
  }, [addEdit, id])

  const stopEditing = () => {
    setEditing(false)
    removeEdit(id)
  }

  const startEditingIfEmptyAndSelected = () => {
    if (!title && selectedItemIds.includes(id)) {
      startEditing()
    }
  }

  useEffect(startEditingIfEmptyAndSelected, [
    id,
    selectedItemIds,
    startEditing,
    title,
  ])

  const interactionMode = useSelector(selectInteractionMode)

  const isEstimatingOrResolving = [
    InteractionMode.ESTIMATE_RESOLUTION,
    InteractionMode.ESTIMATION,
  ].includes(interactionMode)

  return (
    <div className="flex flex-col h-full" onDoubleClick={startEditing}>
      <div className="bg-background-five-light dark:bg-background-five-dark border-border-weak-light dark:border-border-weak-dark flex flex-col flex-grow p-5 border border-b-0 rounded-t-xl">
        <div className="flex flex-col flex-grow">
          <MultilineTextInput
            className="text-text-primary-light dark:text-text-primary-dark flex-grow text-lg leading-normal"
            disabled={!editing}
            isEditing={editing}
            key={operation?.origin}
            onCancel={stopEditing}
            onChange={handleChange}
            onConfirm={handleConfirm}
            placeholder=""
            value={title}
          />
          <div className="flex-shrink-0 mt-6">
            <ul
              className={classNames('flex flex-wrap items-center space-x-2', {
                [Classes.SKELETON]: loading,
                '-mx-1': !loading,
              })}
            >
              {jiraData.status && (
                <li className="min-w-0">
                  <JiraIssueLabel
                    text={jiraData.status.name}
                    color={
                      jiraData.status?.statusCategory?.key
                        ? STATUS_CATEGORY_COLOR_MAP[
                            jiraData.status.statusCategory
                              .key as StatusCategoryKey
                          ]
                        : undefined
                    }
                  />
                </li>
              )}
              {jiraData.sprint && (
                <li className="min-w-0">
                  <JiraIssueLabel text={jiraData.sprint.name} />
                </li>
              )}
              {jiraData.labels?.map((label) => (
                <li key={label} className="min-w-0">
                  <JiraIssueLabel text={label} />
                </li>
              ))}
              {jiraData.parent && (
                <li className="min-w-0">
                  {/* TODO: use actual epic color (CA-1103) */}
                  <JiraIssueLabel text={jiraData.parent.name} color="#d8b4fe" />
                </li>
              )}
            </ul>
          </div>
          <div
            className={classNames(
              'flex flex-shrink-0 items-end mt-4 pointer-events-none space-x-2',
              {
                [Classes.SKELETON]: loading,
              }
            )}
          >
            <div className="flex flex-grow items-center space-x-2">
              {jiraData.type && (
                <img
                  className="w-6 h-6"
                  src={jiraData.type.icon}
                  alt={jiraData.type.name}
                  title={jiraData.type.name}
                />
              )}
              <span className="text-text-secondary-light dark:text-text-secondary-dark flex-grow text-base">
                {jiraData.key}
              </span>
            </div>
            <div
              className={classNames('self-stretch', {
                [Classes.SKELETON]: loading,
              })}
            >
              <EstimationValue itemId={id} />
            </div>
            {jiraData.assignee && (
              <img
                className="w-8 h-8 rounded-full"
                src={jiraData.assignee.avatar['48x48']}
                alt={jiraData.assignee.name}
                title={jiraData.assignee.name}
              />
            )}
          </div>
        </div>
      </div>
      <footer className="bg-background-four-light dark:bg-background-five-dark border-border-weak-light dark:border-border-weak-dark flex flex-shrink-0 items-center px-5 py-4 border rounded-b-xl pointer-events-none">
        <div className="flex flex-grow items-center space-x-2">
          {jiraData.project && (
            <img
              className="w-6 h-6 rounded"
              src={jiraData.project.avatar['48x48']}
              alt={jiraData.project.name}
              title={jiraData.project.name}
            />
          )}
          <div className="text-text-secondary-light dark:text-text-secondary-dark text-base">
            {jiraData?.project?.name}
          </div>
        </div>
        {isEstimatingOrResolving ? (
          <EstimationControls itemId={id} />
        ) : (
          <img alt="Jira" className="w-6 h-6" src={jiraIcon} />
        )}
      </footer>
    </div>
  )
}
