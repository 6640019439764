"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.spreadsheetColumnLetter = void 0;
const LETTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const LETTER_COUNT = LETTERS.length;
const spreadsheetColumnLetter = (n) => {
    var digits = [];
    do {
        var v = n % LETTER_COUNT;
        digits.push(v);
        n = Math.floor(n / LETTER_COUNT);
    } while (n-- > 0);
    var chars = [];
    while (digits.length) {
        const letterIndex = digits.pop();
        chars.push(LETTERS[letterIndex]);
    }
    return chars.join('');
};
exports.spreadsheetColumnLetter = spreadsheetColumnLetter;
