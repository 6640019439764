/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { Item, ItemType } from 'canvas-shared/lib/types/Item.types'

/*---------------------------------------------------------------------------*/

const getExternalItemUrl = (
  item: Item.JiraCard | Item.TrelloCard | Item.LinearCard
): string | undefined => {
  switch (item.type) {
    case ItemType.JiraCard:
      return `https://${item.data.jira.connection.domain}.atlassian.net/browse/${item.data.jira.key}`

    case ItemType.TrelloCard:
      if (item.data.trello.shortLink) {
        return `https://trello.com/c/${item.data.trello.shortLink}`
      }
      return
    case ItemType.LinearCard:
      return item.data.linear.url
  }
}

export default getExternalItemUrl
