/*---- External -------------------------------------------------------------*/

import { createAsyncThunk, nanoid } from '@reduxjs/toolkit'
import difference from 'lodash.difference'

/*---- Qualdesk -------------------------------------------------------------*/

import { UUID } from '../../types'
import { CursorMode } from 'canvas-shared/lib/types/CursorMode.types'
import { ThunkAPI } from '../../types/redux'
import { selectCursorMode } from '../selectors/board/cursor'
import { selectGestureByPointerId } from '../selectors/board/gestures'
import {
  selectedItemsPositionsSelector,
  selectionRectSelector,
} from '../selectors/board/positions'
import {
  selectionClearAction,
  selectionSetAction,
  selectionToggleAction,
} from './selection'
import { Item } from 'canvas-shared/lib/types/Item.types'
import {
  BasicPointerEvent,
  DragHandle,
  Gesture,
} from 'canvas-shared/lib/types/pointerEvents.types'
import {
  ItemPosition,
  LinePosition,
} from 'canvas-shared/lib/types/Position.types'
import { pointerEventIntersections } from '../../hooks/board/pointerEvents/intersectionTests/pointerEventIntersections'
import {
  scrollDimensionsSelector,
  scrollOffsetsSelector,
} from '../selectors/board/scroll'
import { selectZoomLevel } from '../selectors/board/zoom'

/*---------------------------------------------------------------------------*/

export interface PointerPayload {
  cursorMode: CursorMode
  gesture?: Gesture
  pointer: BasicPointerEvent
}

export interface PointerDownPayload extends PointerPayload {
  uniqueId: UUID
  selectedItemPositions: ItemPosition[]
  intersections: {
    dragHandle?: DragHandle
    position?: ItemPosition
    group?: Item.CanvasGroupWithMemberData
  }
}

export const pointerMoveAction = createAsyncThunk<
  PointerPayload,
  BasicPointerEvent,
  ThunkAPI
>('pointer/move', (pointer, { getState }) => {
  const state = getState()
  const gesture = selectGestureByPointerId(state, pointer.pointerId)
  const cursorMode = selectCursorMode(state)

  return { cursorMode, gesture, pointer }
})

export const pointerUpAction = createAsyncThunk<
  PointerPayload,
  BasicPointerEvent,
  ThunkAPI
>('pointer/up', (pointer, { getState, dispatch }) => {
  const state = getState()
  const gesture = selectGestureByPointerId(state, pointer.pointerId)
  const cursorMode = selectCursorMode(state)

  // If we're not in select mode, then this is a gesture start
  if (cursorMode !== CursorMode.SELECT) {
    return { cursorMode, gesture, pointer }
  }

  if (gesture && !gesture.finished) {
    return { cursorMode, gesture, pointer }
  }

  return Promise.reject()
})

export const pointerDownAction = createAsyncThunk<
  PointerDownPayload,
  BasicPointerEvent,
  ThunkAPI
>('pointer/down', async (pointer, { getState, dispatch }) => {
  const state = getState()
  const cursorMode = selectCursorMode(state)
  const selectedItemPositions = selectedItemsPositionsSelector(state)
  const uniqueId = nanoid()

  // If we're not in select mode, then this is a gesture start
  if (cursorMode !== CursorMode.SELECT) {
    return {
      cursorMode,
      pointer,
      uniqueId,
      selectedItemPositions: [],
      intersections: {},
    }
  }

  const {
    dragHandle,
    position,
    group,
    backdrop,
    gutter,
  } = pointerEventIntersections(state, pointer)

  if (!!dragHandle) {
    const { scrollLeft, scrollTop } = scrollOffsetsSelector(state)
    const { offsetLeft, offsetTop } = scrollDimensionsSelector(state)
    const selectionRect = selectionRectSelector(state)
    const zoomLevel = selectZoomLevel(state)
    const newPointer = Object.assign(pointer)
    const position = selectedItemPositions[0] as LinePosition

    if ((dragHandle.start || dragHandle.end) && !!position) {
      if (dragHandle.start) {
        newPointer.clientX =
          position.startX * zoomLevel + offsetLeft - scrollLeft
        newPointer.clientY = position.startY * zoomLevel + offsetTop - scrollTop
      } else {
        newPointer.clientX = position.endX * zoomLevel + offsetLeft - scrollLeft
        newPointer.clientY = position.endY * zoomLevel + offsetTop - scrollTop
      }
    } else if (!!selectionRect) {
      if (dragHandle.top) {
        newPointer.clientY =
          selectionRect.top * zoomLevel + offsetTop - scrollTop
      } else {
        newPointer.clientY =
          selectionRect.bottom * zoomLevel + offsetTop - scrollTop
      }

      if (dragHandle.left) {
        newPointer.clientX =
          selectionRect.left * zoomLevel + offsetLeft - scrollLeft
      } else {
        newPointer.clientX =
          selectionRect.right * zoomLevel + offsetLeft - scrollLeft
      }
    }

    return {
      cursorMode,
      pointer: newPointer,
      uniqueId,
      selectedItemPositions,
      intersections: {
        dragHandle,
      },
    }
  }

  // only toggling selection, no gestures
  if (pointer.shiftKey) {
    if (position) {
      await dispatch(selectionToggleAction([position.id]))
    } else if (group) {
      await dispatch(selectionToggleAction(group.memberIds))
    } else if (backdrop || gutter) {
      return {
        cursorMode,
        pointer,
        uniqueId,
        selectedItemPositions,
        intersections: {
          dragHandle,
          position,
          group,
        },
      }
    }

    return Promise.reject()
  } else {
    if (position) {
      const alreadySelected = selectedItemPositions
        .map((p) => p.id)
        .includes(position.id)

      if (!alreadySelected) {
        dispatch(selectionSetAction([position.id]))
      }

      return {
        cursorMode,
        pointer,
        uniqueId,
        selectedItemPositions: selectedItemsPositionsSelector(getState()),
        intersections: {
          dragHandle,
          position,
          group,
        },
      }
    } else if (group) {
      const alreadySelected =
        difference(
          group.memberIds,
          selectedItemPositions.map((p) => p.id)
        ).length === 0

      if (!alreadySelected) {
        dispatch(selectionSetAction(group.memberIds))
      }

      return {
        cursorMode,
        pointer,
        uniqueId,
        selectedItemPositions: selectedItemsPositionsSelector(getState()),
        intersections: {
          dragHandle,
          position,
          group,
        },
      }
    } else if (backdrop || gutter) {
      dispatch(selectionClearAction())

      return {
        cursorMode,
        pointer,
        uniqueId,
        selectedItemPositions: selectedItemsPositionsSelector(getState()),
        intersections: {
          dragHandle,
          position,
          group,
        },
      }
    }
  }

  const updatedSelectedItemPositions = selectedItemsPositionsSelector(
    getState()
  )

  return {
    cursorMode,
    pointer,
    uniqueId,
    selectedItemPositions: updatedSelectedItemPositions,
    intersections: {
      dragHandle,
      position,
      group,
    },
  }
})
