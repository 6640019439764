"use strict";
/*---- External -------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.connectionDescriptor = exports.connectionTitle = void 0;
const BoardConnection_helpers_1 = require("../../types/BoardConnection.helpers");
/*---------------------------------------------------------------------------*/
const connectionTitle = (connection) => {
    if (BoardConnection_helpers_1.boardConnectionIsGoogleSheets(connection)) {
        return connection.displayName;
    }
    else if (BoardConnection_helpers_1.boardConnectionIsJira(connection)) {
        return connection.jira.project.name;
    }
    else if (BoardConnection_helpers_1.boardConnectionIsLinear(connection)) {
        return connection.linear.team.name;
    }
    else if (BoardConnection_helpers_1.boardConnectionIsTrello(connection)) {
        return connection.displayName;
    }
    else {
        throw Error(`Couldn’t get connection title for connection type ${connection.type}`);
    }
};
exports.connectionTitle = connectionTitle;
const connectionDescriptor = (connection) => {
    if (BoardConnection_helpers_1.boardConnectionIsGoogleSheets(connection)) {
        return 'Google Sheets spreadsheet';
    }
    else if (BoardConnection_helpers_1.boardConnectionIsJira(connection)) {
        return 'Jira project';
    }
    else if (BoardConnection_helpers_1.boardConnectionIsLinear(connection)) {
        return 'Linear team';
    }
    else if (BoardConnection_helpers_1.boardConnectionIsTrello(connection)) {
        return 'Trello board';
    }
    else {
        throw Error(`Couldn’t get connection descriptor for connection type ${connection.type}`);
    }
};
exports.connectionDescriptor = connectionDescriptor;
