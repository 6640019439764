/*---- External -------------------------------------------------------------*/

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, EditableText } from '@blueprintjs/core'
import { MagnifyingGlassMinus, MagnifyingGlassPlus } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  zoomInAction,
  zoomOutAction,
  zoomSetAction,
} from '../../redux/actionCreators/zoom'
import {
  selectCanZoomIn,
  selectCanZoomOut,
  selectZoomLevel,
} from '../../redux/selectors/board/zoom'

/*---------------------------------------------------------------------------*/

const CanvasZoomControls: React.FC = () => {
  const dispatch = useDispatch()

  const canZoomIn = useSelector(selectCanZoomIn)
  const canZoomOut = useSelector(selectCanZoomOut)
  const zoomLevel = useSelector(selectZoomLevel)

  const [localZoomLevel, setLocalZoomLevel] = useState(toPercent(zoomLevel))

  useEffect(() => {
    setLocalZoomLevel(toPercent(zoomLevel))
  }, [zoomLevel])

  const handleConfirm = () => {
    const parsedZoomLevel = parseInt(localZoomLevel, 10)

    if (Number.isInteger(parsedZoomLevel)) {
      const newZoomLevel = parsedZoomLevel / 100
      dispatch(zoomSetAction(newZoomLevel))
      setLocalZoomLevel(toPercent(newZoomLevel))
    } else {
      setLocalZoomLevel(toPercent(zoomLevel))
    }
  }

  const zoomIn = () => {
    dispatch(zoomInAction())
  }

  const zoomOut = () => {
    dispatch(zoomOutAction())
  }

  return (
    <div className="flex items-center px-1 space-x-2">
      <Button
        disabled={!canZoomOut}
        icon={
          <MagnifyingGlassMinus
            weight="regular"
            className="bp3-icon"
            size={20}
          />
        }
        minimal
        onClick={zoomOut}
      />
      <EditableText
        className="zoom-level self-center text-center"
        value={localZoomLevel}
        onChange={setLocalZoomLevel}
        onConfirm={handleConfirm}
        alwaysRenderInput
        selectAllOnFocus
        confirmOnEnterKey
      />
      <Button
        disabled={!canZoomIn}
        icon={
          <MagnifyingGlassPlus
            weight="regular"
            className="bp3-icon"
            size={20}
          />
        }
        minimal
        onClick={zoomIn}
      />
    </div>
  )
}

const toPercent = (number: number): string => `${Math.round(number * 100)}%`

export default CanvasZoomControls
