/*---- External -------------------------------------------------------------*/

import React from 'react'
import { useSelector } from 'react-redux'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { selectZoomScale } from '../../redux/selectors/board/zoom'
import convertToPx from '../../helpers/convertToPx'

/*---------------------------------------------------------------------------*/

interface Props {
  className?: string
  handleId: string
  style?: React.CSSProperties
}

export const CanvasDragHandle: React.FC<Props> = ({
  className,
  handleId,
  style,
}) => {
  const zoomScale = useSelector(selectZoomScale)

  const handleStyle = {
    ...style,
    width: convertToPx(DRAG_HANDLE_WIDTH),
    height: convertToPx(DRAG_HANDLE_WIDTH),
    backgroundColor: '#000000',
    border: '1px solid #ffffff',
    transform: `scale(${zoomScale})`,
  }

  return (
    <div
      className={classNames(className, 'z-above-selection-rect')}
      style={handleStyle}
      data-drag-handle-id={handleId}
    ></div>
  )
}

export const DRAG_HANDLE_WIDTH = 12
