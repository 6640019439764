/*---- External -------------------------------------------------------------*/

import { useCallback, useEffect, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import {
  useFirebaseConnect as useRRFFirebaseConnect,
  useFirestoreConnect,
} from 'react-redux-firebase'

/*---- Qualdesk -------------------------------------------------------------*/

import { containerConnectionsPath } from 'canvas-shared/lib/paths/containerConnectionsPath'
import { generateInteractionModesPath } from '../paths/interactionModePaths'
import { generateInvitationsPath } from '../paths/containerPaths'
import { generateTimersPath } from '../paths/timerPaths'

import { firestoreQueryFromPath } from './helpers/firestoreQueryFromPath'
import { generateStoreAs } from './helpers/generateStoreAs'

import { useConnectPositions } from './board/useConnectPositions'
import { useConnectSession } from './board/useConnectSession'
import { useConnectUsers } from './board/useConnectUsers'
import { useConnectItems } from './board/useConnectItems'
import { useConnectDynamicItems } from './board/useConnectDynamicItems'

import { SchemaRTDB, SchemaFirestore } from '../../types/redux'
import { UUID } from 'canvas-shared/lib/types'
import { selectMyUid } from '../selectors/auth/auth'
import { setContainerIdAction } from '../actionCreators/container'

/*---------------------------------------------------------------------------*/

export const useBoardConnectors = (containerId: UUID) => {
  const uid = useSelector(selectMyUid)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setContainerIdAction(containerId))
  }, [containerId, dispatch])

  const interactionModesPath = useMemo(
    () => generateInteractionModesPath(containerId),
    [containerId]
  )
  const timersPath = useMemo(() => generateTimersPath(containerId), [
    containerId,
  ])

  const storeAs = useCallback(
    (key: keyof SchemaRTDB | keyof SchemaFirestore) =>
      generateStoreAs(key, containerId),
    [containerId]
  )

  useRRFFirebaseConnect([
    {
      path: interactionModesPath,
      storeAs: storeAs('interactionModes'),
    },
    {
      path: timersPath,
      storeAs: storeAs('timers'),
    },
  ])

  useConnectUsers(containerId)
  useConnectPositions(containerId)
  useConnectSession(containerId, uid)
  useConnectItems(containerId)
  useConnectDynamicItems(containerId)

  const connectionsQuery = useMemo(
    () =>
      firestoreQueryFromPath(
        containerConnectionsPath(containerId),
        undefined,
        storeAs
      ),
    [containerId, storeAs]
  )

  const invitationsQuery = useMemo(
    () =>
      firestoreQueryFromPath(
        generateInvitationsPath(containerId),
        undefined,
        storeAs
      ),
    [containerId, storeAs]
  )

  useFirestoreConnect([connectionsQuery, invitationsQuery])
}
