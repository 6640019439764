/*---- External -------------------------------------------------------------*/

import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { UpdateData } from '@firebase/firestore-types'

/*---- Qualdesk -------------------------------------------------------------*/

import { app } from '../../../../config/firebase'

import { generateOperation } from '../../helpers/generateOperation'

import { selectMyUid } from '../../../selectors/auth/auth'

import { UpdateDataWithTimestamps } from 'canvas-shared/lib/types/timestamps.types'

/*---------------------------------------------------------------------------*/

interface ItemUpdateActions {
  generateItemUpdate: (update: UpdateData) => UpdateDataWithTimestamps
}

export const useItemUpdateActions = (): ItemUpdateActions => {
  const uid = useSelector(selectMyUid)

  const operation = useMemo(() => generateOperation(uid), [uid])

  const generateItemUpdate = useCallback(
    (update: UpdateData): UpdateDataWithTimestamps => ({
      ...update,
      operation,
      updatedAt: app.firestore.Timestamp.now(),
      updatedBy: operation,
    }),
    [operation]
  )

  return useMemo(
    () => ({
      generateItemUpdate,
    }),
    [generateItemUpdate]
  )
}
