/*---- External -------------------------------------------------------------*/

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { getSVGViewBox } from '../../helpers/getSVGViewBox'

import { useDarkMode } from '../../hooks/darkMode/useDarkMode'

import { useItemPosition } from '../../redux/selectorHooks/board/useItemPosition'
import { selectZoomScale } from '../../redux/selectors/board/zoom'

import { getColor } from 'canvas-shared/lib/config/colors'

import { Item } from 'canvas-shared/lib/types/Item.types'
import { LinePosition } from 'canvas-shared/lib/types/Position.types'

/*---------------------------------------------------------------------------*/

export const ItemLine: React.FC<Item.Line> = ({
  data: { lineColor, lineThickness },
  id,
}) => {
  const position = useItemPosition(id) as LinePosition
  const zoomScale = useSelector(selectZoomScale)
  const darkMode = useDarkMode()

  const stroke = useMemo(() => {
    if (darkMode) {
      if (lineColor === 'white') {
        return getColor('black').hex
      } else if (lineColor === 'black') {
        return getColor('white').hex
      }
    }

    return getColor(lineColor).hex
  }, [darkMode, lineColor])

  const lineStyle: React.CSSProperties = useMemo(
    () => ({
      stroke,
      strokeWidth: lineThickness,
    }),
    [lineThickness, stroke]
  )

  const clickableLineStyle: React.CSSProperties = useMemo(
    () => ({
      stroke: 'transparent',
      strokeWidth: lineThickness + 5 * zoomScale,
      strokeLinecap: 'round',
    }),
    [lineThickness, zoomScale]
  )

  const svgViewBox = useMemo(() => getSVGViewBox(position, lineThickness), [
    lineThickness,
    position,
  ])

  const { startX, startY, endX, endY } = position

  return (
    <svg
      className="w-full h-full overflow-visible"
      pointerEvents="none"
      viewBox={svgViewBox}
    >
      <line
        x1={startX}
        x2={endX}
        y1={startY}
        y2={endY}
        style={clickableLineStyle}
        pointerEvents="visiblePainted"
        data-pointer-event-line-item-id={id}
      />
      <line
        x1={startX}
        x2={endX}
        y1={startY}
        y2={endY}
        style={lineStyle}
        pointerEvents="none"
      />
    </svg>
  )
}
