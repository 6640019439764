/*---- External -------------------------------------------------------------*/

import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { useAsync } from '@react-hook/async'

/*---- Qualdesk -------------------------------------------------------------*/

import { useUserActions } from '../../redux/actions/auth/useUserActions'
import { selectMyEmail, selectMyUid } from '../../redux/selectors/auth/auth'

import { MinimalLayout } from '../../components/layouts/MinimalLayout'
import { Input } from '../../components/utilities/Input'
import { Button } from '../../components/utilities/Button'
import { MinimalPictureChooser } from '../../components/pictureChooser/MinimalPictureChooser'

import { userProfilePicturePath } from 'canvas-shared/lib/paths/userProfilePicturePath'
import { guessNameFromEmail } from 'canvas-shared/lib/helpers/guessNameFromEmail'

/*---------------------------------------------------------------------------*/

const AuthUserDetails: React.FC = () => {
  const uid = useSelector(selectMyUid)
  const email = useSelector(selectMyEmail)
  const { setUserPicture, updateUserDetails } = useUserActions()

  const [name, setName] = useState(guessNameFromEmail(email) || '')
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined)

  const [{ status: skipStatus }, handleSkip] = useAsync(async (event) => {
    event.preventDefault()
    await updateUserDetails({ name: email })
  })

  const [{ status }, handleUpdateUserDetails] = useAsync(async (event) => {
    event.preventDefault()
    if (fileUrl) await setUserPicture(fileUrl)
    await updateUserDetails({ name })
  })

  const storagePath = useMemo(() => userProfilePicturePath(uid), [uid])

  return (
    <MinimalLayout>
      <h1 className="text-text-primary-light dark:text-text-primary-dark text-2xl font-extrabold leading-tight sm:text-3xl">
        Edit your profile
      </h1>
      <p className="text-text-secondary-light dark:text-text-secondary-dark mb-8 mt-3 text-sm leading-normal">
        Help your team recognize you in Qualdesk
      </p>
      <form onSubmit={handleUpdateUserDetails}>
        <div className="flex items-center space-x-5">
          <MinimalPictureChooser
            handleUploadComplete={setFileUrl}
            storagePath={storagePath}
          />
          <Input
            className="flex-grow"
            type="text"
            label="Your name"
            onChange={(event) => setName(event.target.value)}
            value={name}
            autoFocus
          />
        </div>
        <div className="flex mt-10 space-x-5">
          <Button
            className="flex justify-center w-32 text-center"
            type="button"
            minimal
            onClick={handleSkip}
            disabled={skipStatus === 'loading'}
            loading={skipStatus === 'loading'}
          >
            Skip
          </Button>
          <Button
            className="flex justify-center w-full text-center space-x-2"
            disabled={
              skipStatus === 'loading' ||
              status === 'loading' ||
              name.length === 0
            }
            loading={status === 'loading'}
          >
            Save and continue
          </Button>
        </div>
      </form>
    </MinimalLayout>
  )
}

export default AuthUserDetails
