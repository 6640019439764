/*---- External -------------------------------------------------------------*/

import { createAction } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

export const setCursorModeDrawRectangleAction = createAction(
  'board/setCursorModeDrawRectangle'
)
export const setCursorModeDrawLineAction = createAction(
  'board/setCursorModeDrawLine'
)

export const setCursorModePasteAction = createAction('board/setCursorModePaste')

export const setCursorModeAddStickyAction = createAction(
  'board/setCursorModeAddSticky'
)
export const setCursorModeAddCardAction = createAction(
  'board/setCursorModeAddCard'
)
export const setCursorModeAddTextAction = createAction(
  'board/setCursorModeAddText'
)

export const setCursorModeSelect = createAction('board/setCursorModeSelect')

export const clearCursorModeAction = createAction('board/clearCursorMode')
