/*---- External -------------------------------------------------------------*/

import React from 'react'
import { useSelector } from 'react-redux'

import { NavLink } from 'react-router-dom'

import { Archive, GridFour, Plus, ShareNetwork } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import { QualdeskIcon } from '../../assets/brand/QualdeskIcon'

import { useWorkspaceActions } from '../../redux/actions/workspace/useWorkspaceActions'
import { selectMyName, selectMyUid } from '../../redux/selectors/auth/auth'

import { Routes } from '../../router/routes'

import { WorkspaceNavigationButton } from './WorkspaceNavigationButton'
import { WorkspaceNavigationOrg } from './WorkspaceNavigationOrg'
import UserIcon from '../user/UserIcon'

/*---------------------------------------------------------------------------*/

export const WorkspaceNavigation: React.FC = () => {
  const uid = useSelector(selectMyUid)
  const name = useSelector(selectMyName)

  const { createBoard } = useWorkspaceActions()

  const handleCreate = async () => {
    await createBoard()
  }

  return (
    <div className="z-above-everything w-62 border-border-normal-light dark:border-border-normal-dark bg-background-five-light dark:bg-background-five-dark relative flex flex-col flex-grow-0 flex-shrink-0 pt-3 border-r select-none overflow-hidden">
      <WorkspaceNavigationButton
        iconElement={
          <UserIcon
            className="dark:bg-background-five-dark w-5 h-5"
            uid={uid}
          />
        }
        forPath={Routes.ACCOUNT_SETTINGS.path}
        text={name}
      />
      <WorkspaceNavigationButton
        phosphorIcon={GridFour}
        forPath={Routes.WORKSPACE_PERSONAL.path}
        text="My boards"
      />
      <WorkspaceNavigationButton
        phosphorIcon={ShareNetwork}
        forPath={Routes.WORKSPACE_SHARED_WITH_ME.path}
        text="Shared with me"
      />
      <WorkspaceNavigationButton
        phosphorIcon={Archive}
        forPath={Routes.WORKSPACE_ARCHIVED.path}
        text="Archive"
      />
      <WorkspaceNavigationButton
        phosphorIcon={Plus}
        onClick={handleCreate}
        text="New board"
        secondary
      />
      <WorkspaceNavigationOrg />
      <div className="flex-shrink-0 mt-auto p-6">
        <NavLink to="/">
          <QualdeskIcon />
        </NavLink>
      </div>
    </div>
  )
}
