/*---- External -------------------------------------------------------------*/

import { combineEpics, Epic, ofType } from 'redux-observable'
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators'
import { from } from 'rxjs'

/*---- Qualdesk -------------------------------------------------------------*/

import { generateItemPath } from '../paths/itemsPaths'

import { pendingCommitAllAction } from '../actionCreators/pending'
import {
  createAdjacentItemAction,
  CreateAdjacentItemActionPayload,
  duplicateItemsAction,
  DuplicateItemsActionPayload,
  removeItemsAction,
} from '../actionCreators/items'
import { setShouldSelectAction } from '../actionCreators/shouldSelect'
import type {
  ActionWithPayload,
  EpicWithDifferentActions,
  RootState,
} from '../../types/redux'
import type { UUID } from 'canvas-shared/lib/types'

import { createFirestoreEpic } from './helpers/createFirestoreEpic'
import { selectionSetAction } from '../actionCreators/selection'
import { itemSelector } from '../selectors/board/items'
import prepareForFirestore from 'canvas-shared/lib/helpers/prepareForFirestore'
import {
  groupItemsAction,
  removeItemsFromGroupAction,
  updateGroupColorAction,
} from '../actionCreators/dynamicItems'
import { generateDynamicItemPath } from '../paths/dynamicItemsPaths'
import { generateOperation } from '../actions/helpers/generateOperation'
import { cutAction } from '../actionCreators/clipboard'
import {
  createGroupSelector,
  createSelectUnfilteredGroupsWithMemberIds,
} from '../selectors/board/groups'

/*---------------------------------------------------------------------------*/

const pendingCommitAllItemsEpic = createFirestoreEpic(
  pendingCommitAllAction.fulfilled,
  ({ content: { items }, containerId, firestore, state }) => {
    const fs = firestore()
    const batch = fs.batch()

    items.forEach((item) => {
      const itemRef = fs.doc(generateItemPath(containerId, item.id))
      batch.set(itemRef, item)
    })

    return batch.commit()
  }
)

const pendingCommitAllItemsShouldSelectEpic: EpicWithDifferentActions<
  ReturnType<typeof pendingCommitAllAction.fulfilled>,
  ActionWithPayload<UUID>
> = (action$) =>
  action$.pipe(
    filter(pendingCommitAllAction.fulfilled.match),
    filter((action) => action.payload.items.length === 1),
    map((action) => {
      return setShouldSelectAction(action.payload.items[0].id)
    })
  )

const setSelectionForDuplicatedItems: EpicWithDifferentActions<
  ActionWithPayload<DuplicateItemsActionPayload>,
  ActionWithPayload<UUID[]>
> = (action$) =>
  action$.pipe(
    ofType(duplicateItemsAction.fulfilled),
    map((action) => {
      const newIds = Object.values(action.payload.newIdsMap)
      return selectionSetAction(newIds)
    })
  )

const duplicateItemsEpic: Epic<
  ActionWithPayload<DuplicateItemsActionPayload>,
  ActionWithPayload<DuplicateItemsActionPayload>,
  RootState
> = createFirestoreEpic(
  [duplicateItemsAction.fulfilled],
  ({ content, containerId, firestore, state }) => {
    const fs = firestore()
    const batch = fs.batch()
    Object.values(content.newIdsMap).forEach((id) => {
      const ref = fs.doc(generateItemPath(containerId, id))
      const item = itemSelector(state, id)
      if (!!item) {
        batch.set(ref, prepareForFirestore(item))
      }
    })
    batch.commit()
  }
)

const createAdjacentItemEpic: Epic<
  ActionWithPayload<CreateAdjacentItemActionPayload>,
  ActionWithPayload<CreateAdjacentItemActionPayload>,
  RootState
> = createFirestoreEpic(
  createAdjacentItemAction.fulfilled,
  ({ content, containerId, firestore, state }) => {
    const fs = firestore()
    const id = content.newId
    const ref = fs.doc(generateItemPath(containerId, id))
    const item = itemSelector(state, id)

    ref.set(item)
  }
)

const removeItemsFirestoreEpic: Epic<
  ActionWithPayload<UUID[]>,
  ActionWithPayload<UUID[]>,
  RootState
> = createFirestoreEpic(
  [removeItemsAction.fulfilled, cutAction.fulfilled],
  ({ content: ids, containerId, firestore }) => {
    if (ids.length > 1) {
      const fs = firestore()
      const batch = fs.batch()
      ids.forEach((id) => {
        const ref = fs.doc(generateItemPath(containerId, id))
        batch.delete(ref)
      })

      batch.commit()
    } else if (ids.length === 1) {
      const ref = firestore().doc(generateItemPath(containerId, ids[0]))
      ref.delete()
    }
  }
)

const removeItemsGroupCleanupEpic: EpicWithDifferentActions<
  ActionWithPayload<UUID[]>,
  ReturnType<typeof removeItemsFromGroupAction>
> = (action$, state$) =>
  action$.pipe(
    filter(
      (action) =>
        removeItemsAction.fulfilled.match(action) ||
        cutAction.fulfilled.match(action)
    ),
    withLatestFrom(state$),
    map(([{ payload }, state]) => {
      const groupSelector = createSelectUnfilteredGroupsWithMemberIds(payload)
      const groups = groupSelector(state)
      return groups.map((group) => ({ itemIds: payload, group }))
    }),
    mergeMap((payloads) => from(payloads).pipe(map(removeItemsFromGroupAction)))
  )

const groupItemsFirestoreEpic = createFirestoreEpic(
  groupItemsAction.fulfilled,
  ({ content: { add, remove }, containerId, firestore }) => {
    const fs = firestore()
    const batch = fs.batch()

    const addRef = fs.doc(generateDynamicItemPath(containerId, add.id))
    batch.set(addRef, add)

    remove.forEach((g) => {
      const removeRef = fs.doc(generateDynamicItemPath(containerId, g.id))
      batch.delete(removeRef)
    })

    batch.commit()
  }
)

const removeItemsFromGroupFirestoreEpic = createFirestoreEpic(
  removeItemsFromGroupAction,
  ({ content: { itemIds, group }, containerId, firestore, uid, state }) => {
    const groupRef = firestore().doc(
      generateDynamicItemPath(containerId, group.id)
    )

    const groupSelector = createGroupSelector(group.id)
    const groupInState = groupSelector(state)

    if (!groupInState) {
      groupRef.delete()
    } else {
      const operation = generateOperation(uid)
      groupRef.update({
        memberIds: firestore.FieldValue.arrayRemove(...itemIds),
        updatedAt: firestore.FieldValue.serverTimestamp(),
        updatedBy: operation,
        operation,
      })
    }
  }
)

const updateGroupColorFirestoreEpic = createFirestoreEpic(
  updateGroupColorAction,
  ({ content: { color, groupId }, containerId, firestore, uid }) => {
    const groupRef = firestore().doc(
      generateDynamicItemPath(containerId, groupId)
    )
    const operation = generateOperation(uid)

    groupRef.update({
      'data.color': color,
      updatedAt: firestore.FieldValue.serverTimestamp(),
      updatedBy: operation,
      operation,
    })
  }
)

export const itemsEpic = combineEpics(
  pendingCommitAllItemsEpic,
  pendingCommitAllItemsShouldSelectEpic,
  setSelectionForDuplicatedItems,
  duplicateItemsEpic,
  createAdjacentItemEpic,
  removeItemsFirestoreEpic,
  removeItemsGroupCleanupEpic,
  groupItemsFirestoreEpic,
  removeItemsFromGroupFirestoreEpic,
  updateGroupColorFirestoreEpic
)
