/*---- External -------------------------------------------------------------*/

import React, { useMemo } from 'react'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { useDarkMode } from '../../hooks/darkMode/useDarkMode'

import { getTextColorClassName } from 'canvas-shared/lib/config/colors'

import { ItemGenericTextInner } from './ItemGenericTextInner'

import { Item } from 'canvas-shared/lib/types/Item.types'

/*---------------------------------------------------------------------------*/

interface ItemTextProps extends Item.Text {
  pending?: boolean
}

export const ItemText: React.FC<ItemTextProps> = ({ data, id, pending }) => {
  const darkMode = useDarkMode()

  const style: React.CSSProperties = useMemo(
    () => ({
      fontSize: data.textSize,
    }),
    [data.textSize]
  )

  return (
    <div
      className={classNames(
        data.textStyle,
        `text-${data.textAlignment}`,
        getTextColorClassName(data.textColor, 500, darkMode),
        'flex flex-col w-full h-full',
        {
          'ring-2 ring-border-normal-light dark:ring-border-normal-dark': pending,
        }
      )}
      style={style}
    >
      <ItemGenericTextInner itemId={id} />
    </div>
  )
}
