/*---- External -------------------------------------------------------------*/

import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { Button, Divider } from '@blueprintjs/core'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { useCanvasWindowContext } from '../../contexts/CanvasWindow'
import { MINIMAP_WIDTH } from '../../config/canvas'

import convertToPx from '../../helpers/convertToPx'

import { selectGestureInProgress } from '../../redux/selectors/board/gestures'

import useLocalStorageSettings from '../../hooks/useLocalStorageSettings'

import { CanvasNavigator } from './CanvasNavigator'
import CanvasZoomControls from './CanvasZoomControls'

/*---------------------------------------------------------------------------*/

const viewControlsStyle = {
  width: convertToPx(MINIMAP_WIDTH),
}

export const CanvasViewControls: React.FC = () => {
  const { canvasViewControlsRef } = useCanvasWindowContext()
  const { useShowCanvasNavigator } = useLocalStorageSettings()
  const [isShowing, setIsShowing] = useShowCanvasNavigator()
  const gestureInProgress = useSelector(selectGestureInProgress)
  const [isTransitioning, setIsTransitioning] = useState(false)

  const handleShowHideToggle = () => {
    setIsTransitioning(true)
    setIsShowing(!isShowing)
  }

  const endTransition = () => {
    setIsTransitioning(false)
  }

  return (
    <div
      className={classNames({
        'absolute bottom-0 left-0 z-above-items': true,
        'pointer-events-none': gestureInProgress || !isShowing,
      })}
      ref={canvasViewControlsRef}
    >
      <div className="relative p-3 pb-0 overflow-hidden">
        <div
          className={classNames({
            'transform translate-y-full': !isShowing,
            'transition duration-200 ease-in-out': true,
            'bg-background-five-light dark:bg-background-five-dark rounded-t-lg border border-border-weak-light dark:border-border-weak-dark overflow-hidden border-b-0': true,
          })}
          onTransitionEnd={endTransition}
        >
          {(isShowing || isTransitioning) && <CanvasNavigator />}
        </div>
      </div>
      <div className="p-3 pt-0 overflow-hidden">
        <div
          className={classNames({
            'rounded-t-lg': !isShowing && !isTransitioning,
            'pointer-events-auto': !gestureInProgress,
            'pointer-events-none': gestureInProgress,
            'bg-background-five-light dark:bg-background-five-dark rounded-b-lg border border-border-weak-light dark:border-border-weak-dark': true,
          })}
        >
          <div
            className="flex items-stretch p-2 space-x-2"
            style={viewControlsStyle}
          >
            <Button
              className="flex-grow"
              minimal
              onClick={handleShowHideToggle}
            >
              {isShowing ? 'Hide map' : 'Show map'}
            </Button>
            <Divider />
            <CanvasZoomControls />
          </div>
        </div>
      </div>
    </div>
  )
}
