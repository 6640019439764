/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { containerPath } from 'canvas-shared/lib/paths/containerPath'

import { UUID } from '../../types'

/*---------------------------------------------------------------------------*/

export const generateItemsPath = (containerId: UUID) =>
  `${containerPath(containerId)}/items`
export const generateItemPath = (containerId: UUID, itemId: UUID) =>
  `${generateItemsPath(containerId)}/${itemId}`
