/*---- External -------------------------------------------------------------*/

import { useCallback } from 'react'

import { Intent, IToastProps } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { sendSuperficialError } from '../helpers/sendSuperficialError'

import { usePersistentToast } from './usePersistentToast'

/*---------------------------------------------------------------------------*/

type ToastOptions = Partial<IToastProps>

export const useStatusReporter = () => {
  const { showOrUpdate } = usePersistentToast()

  const reportProgress = useCallback(
    async (message: string, toastProps: ToastOptions = {}) => {
      showOrUpdate({
        message,
        timeout: 0,
        intent: Intent.PRIMARY,
        icon: 'cloud-download',
        ...toastProps,
      })
    },
    [showOrUpdate]
  )

  const reportSuccess = useCallback(
    (message: string, toastProps: ToastOptions = {}) => {
      showOrUpdate({
        message,
        timeout: 1000,
        intent: Intent.SUCCESS,
        icon: 'tick',
        ...toastProps,
      })
    },
    [showOrUpdate]
  )

  const reportError = useCallback(
    (error: Error, message: string, toastProps: ToastOptions = {}) => {
      showOrUpdate({
        message,
        timeout: 2000,
        intent: Intent.DANGER,
        icon: 'warning-sign',
        ...toastProps,
      })

      sendSuperficialError(error, message)
    },
    [showOrUpdate]
  )

  return { reportProgress, reportSuccess, reportError }
}
