/*---- External -------------------------------------------------------------*/

import React from 'react'

import { Popover2, IPopover2Props } from '@blueprintjs/popover2'

/*---- Qualdesk -------------------------------------------------------------*/

import { useClassNamesWithDarkMode } from '../../../hooks/darkMode/useClassNamesWithDarkMode'

/*---------------------------------------------------------------------------*/

export const CanvasHoverToolbarSection: React.FC<IPopover2Props> = (
  popoverProps
) => {
  const { classNamesWithDarkMode } = useClassNamesWithDarkMode()

  return (
    <Popover2
      {...popoverProps}
      enforceFocus={false}
      popoverClassName="p-2 shadow-lg rounded-lg bg-background-floating-light dark:bg-background-floating-dark flex justify-center-hidden"
      portalClassName={classNamesWithDarkMode()}
      minimal
      isOpen
    >
      <div className="block w-full h-full" />
    </Popover2>
  )
}
