/*---- External -------------------------------------------------------------*/

import React from 'react'
import { useSelector } from 'react-redux'

import classNames from 'classnames'

import { Menu } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'

/*---- Qualdesk -------------------------------------------------------------*/

import { ItemConfigurations } from '../../../config/itemTypes'

import { selectCursorMode } from '../../../redux/selectors/board/cursor'
import useLocalStorageSettings from '../../../hooks/useLocalStorageSettings'
import { useBoardToolbarHandlers } from '../../../hooks/board/useBoardToolbarHandlers'

import { SplitPopoverButton } from './SplitPopoverButton'
import { HotkeyMenuItem } from './HotkeyMenuItem'

import {
  ItemType,
  CreateableItemType,
} from 'canvas-shared/lib/types/Item.types'

/*---------------------------------------------------------------------------*/

export const AddShapeItemButton: React.FC = () => {
  const {
    handleAddLineItem,
    handleAddRectangleItem,
  } = useBoardToolbarHandlers()
  const cursorMode = useSelector(selectCursorMode)

  const { useShapeItemType } = useLocalStorageSettings()
  const [shapeItemType] = useShapeItemType()

  const handleAddItem = (type: CreateableItemType) => {
    switch (type) {
      case ItemType.Rectangle:
        handleAddRectangleItem()
        break
      case ItemType.Line:
        handleAddLineItem()
        break
    }
  }

  const handleAddDefault = () => handleAddItem(shapeItemType)

  const Icon = ItemConfigurations[shapeItemType].icon
  const active = cursorMode === ItemConfigurations[shapeItemType].cursorMode

  const IconRectangle = ItemConfigurations.Rectangle.icon
  const IconLine = ItemConfigurations.Line.icon

  return (
    <Popover2
      minimal
      placement="top-start"
      autoFocus={false}
      content={
        <Menu>
          <HotkeyMenuItem
            text={ItemConfigurations.Rectangle.text}
            icon={IconRectangle && <IconRectangle size={24} />}
            hotkey={ItemConfigurations.Rectangle.hotkey}
            onClick={() => handleAddItem(ItemType.Rectangle)}
            active={cursorMode === ItemConfigurations.Rectangle.cursorMode}
          />
          <HotkeyMenuItem
            text={ItemConfigurations.Line.text}
            icon={IconLine && <IconLine size={24} />}
            hotkey={ItemConfigurations.Line.hotkey}
            onClick={() => handleAddItem(ItemType.Line)}
            active={cursorMode === ItemConfigurations.Line.cursorMode}
          />
        </Menu>
      }
    >
      <SplitPopoverButton
        title="Shape tools"
        className={classNames({
          'text-icon-accent-light dark:text-icon-accent-dark': active,
        })}
        actionTitle={ItemConfigurations[shapeItemType].text}
        actionHotkey={ItemConfigurations[shapeItemType].hotkey}
        actionIcon={
          Icon && (
            <Icon
              size={24}
              weight={active ? 'duotone' : undefined}
              className="toolbar-icon bp3-icon"
            />
          )
        }
        actionOnClick={handleAddDefault}
      />
    </Popover2>
  )
}
