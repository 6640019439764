/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import type { UUID } from 'canvas-shared/lib/types'

/*---------------------------------------------------------------------------*/

export const convertItemIdsToSelectionRecord = (itemIds: UUID[]) =>
  itemIds.reduce(
    (res, itemId) => ({
      ...res,
      [itemId]: true,
    }),
    {}
  )
