/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

export const WorkspaceCardWrapper: React.FC = ({ children }) => (
  <div className="grid gap-6 grid-cols-2 2xl:grid-cols-4 p-12 xl:grid-cols-3">
    {children}
  </div>
)
