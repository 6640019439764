/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { containerPath } from 'canvas-shared/lib/paths/containerPath'

import { UUID } from '../../types'

/*---------------------------------------------------------------------------*/

export const generateTimersPath = (containerId: UUID) =>
  `${containerPath(containerId)}/timers`
export const generateTimerPath = (containerId: UUID) =>
  `${generateTimersPath(containerId)}/allUsers`
