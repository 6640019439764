/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { generateUserPath } from './userPaths'

import { UUID } from '../../types'

/*---------------------------------------------------------------------------*/

export const generateUserEditsPath = (containerId: UUID, uid: string) =>
  `${generateUserPath(containerId, uid)}/edits`
export const generateItemEditsPath = (
  containerId: UUID,
  uid: string,
  itemId: UUID
) => `${generateUserEditsPath(containerId, uid)}/${itemId}`
