/*---- External -------------------------------------------------------------*/

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { Aspect32 } from '../utilities/Aspect32'

/*---------------------------------------------------------------------------*/

export const WorkspaceCard: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...divProps
}) => (
  <Aspect32>
    <div
      {...divProps}
      className={classNames(
        className,
        'h-full rounded-md select-none overflow-hidden'
      )}
    >
      {children}
    </div>
  </Aspect32>
)
