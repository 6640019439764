/*---- External -------------------------------------------------------------*/

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  positionRemoveAction,
  positionSetAllAction,
  positionSetOneAction,
} from '../../actionCreators/positions'

import { generatePositionsPath } from '../../paths/positionsPaths'

import { selectMyUid } from '../../selectors/auth/auth'

import { useRTDBConnect } from '../helpers/useRTDBConnect'

import { UserOperation, UUID } from 'canvas-shared/lib/types'
import {
  LinePosition,
  RectPosition,
} from 'canvas-shared/lib/types/Position.types'

/*---------------------------------------------------------------------------*/

export const useConnectPositions = (containerId: UUID) => {
  const uid = useSelector(selectMyUid)

  useRTDBConnect({
    path: generatePositionsPath(containerId),
    onChildSet: positionSetOneAction,
    onChildRemoved: positionRemoveAction,
    onInitialValue: positionSetAllAction,
    mapIdsOnValue: true,
    filterOnChildSet: (value) =>
      !(
        value?.operation?.origin === 'user/web' && value?.operation?.uid === uid
      ),
    splitter: (changes) => {
      const value = changes[0]?.value as RectPosition | LinePosition
      return (value?.operation as UserOperation)?.uid
    },
  })
}
