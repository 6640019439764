/*---- External -------------------------------------------------------------*/

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  positionsDataSelector,
  positionsOrderedFrontToBackSelector,
  positionsOrderedSelector,
} from '../../selectors/board/positions'

/*---------------------------------------------------------------------------*/

export const usePositionsData = () => useSelector(positionsDataSelector)
export const usePositions = () => useSelector(positionsOrderedSelector)
export const usePositionsFromFrontToBack = () =>
  useSelector(positionsOrderedFrontToBackSelector)
