/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { ItemPosition } from 'canvas-shared/lib/types/Position.types'

/*---------------------------------------------------------------------------*/

interface NextCoordsArgs {
  index: number
  startTop: number
  startLeft: number
  height: number
  width: number
  itemCount?: number
  positions: ItemPosition[]
}

export const GUTTER = 20
export const GOLDEN_RATIO = 1.61803

export const nextCoords = ({
  index,
  startTop,
  startLeft,
  height,
  width,
  itemCount,
  positions,
}: NextCoordsArgs) => {
  const numCols = itemCount
    ? Math.round(Math.sqrt(itemCount * GOLDEN_RATIO))
    : 5

  const topModifier = Math.floor(index / numCols)
  const leftModifier = index % numCols

  const startZ =
    (positions.length > 0 ? Math.max(...positions.map((c) => c.z)) : 0) + 1

  return {
    z: startZ + index + 1,
    left: startLeft + leftModifier * (width + GUTTER),
    top: startTop + topModifier * (height + GUTTER),
    height: height,
    width: width,
  }
}
