/*---- External -------------------------------------------------------------*/

import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  closeContextMenuAction,
  openContextMenuAction,
} from '../../../actionCreators/contextMenu'

import { ContextMenuStateSlice } from '../../../../types/redux'

/*---------------------------------------------------------------------------*/

const initialState: ContextMenuStateSlice = {
  coords: undefined,
  actionItems: [],
  actionItemsPositions: [],
  actionGroup: undefined,
}

const extraReducers = (
  builder: ActionReducerMapBuilder<ContextMenuStateSlice>
) => {
  builder
    .addCase(closeContextMenuAction, () => initialState)
    .addCase(openContextMenuAction.fulfilled, (_, action) => action.payload)
}

export const contextMenuSlice = createSlice({
  name: 'contextMenu',
  reducers: {},
  initialState,
  extraReducers,
})
