/*---- External -------------------------------------------------------------*/

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  generatePath,
  Link,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom'

import classNames from 'classnames'

import { Popover2 } from '@blueprintjs/popover2'

import { Lightning, ArrowLineRight } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import { functions } from '../../config/firebase'

import { useContainerId } from '../../contexts/Container'

import { useClassNamesWithDarkMode } from '../../hooks/darkMode/useClassNamesWithDarkMode'

import { useItemIds } from '../../redux/selectorHooks/board/useItems'

import jiraIcon from '../../assets/integrations/jira/jira-icon.svg'
import googleGIcon from '../../assets/integrations/google/google-g-icon.svg'
import trelloIcon from '../../assets/integrations/trello/trello-icon.svg'
import linearIcon from '../../assets/integrations/linear/linear-icon.svg'

import { SyncArrows } from '../utilities/SyncArrows'
import { SyncStatus } from 'canvas-shared/lib/config/connections/sync'

import {
  selectBoardConnections,
  selectBoardConnectionsLoaded,
  selectConnectionOverviewSyncStatus,
} from '../../redux/selectors/board/connections'

import { Routes } from '../../router/routes'
import { connectionRequiresManualSync } from 'canvas-shared/lib/types/BoardConnection.helpers'

/*---------------------------------------------------------------------------*/

const syncData = functions.httpsCallable('https-connectionsSyncData')

export const Fab: React.FC = () => {
  const connections = useSelector(selectBoardConnections)
  const connectionsLoaded = useSelector(selectBoardConnectionsLoaded)
  const itemIds = useItemIds()
  const { url } = useRouteMatch()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const preventPopoverOpen = useRef(false)
  const containerId = useContainerId()

  const { classNamesWithDarkMode } = useClassNamesWithDarkMode()

  useEffect(() => {
    const isRootUrl = window.location.pathname === url
    const hasConnections = connections.length > 0
    const hasItemIds = itemIds.length > 0

    const isPopoverOpen =
      isRootUrl && connectionsLoaded && !hasConnections && !hasItemIds

    if (!preventPopoverOpen.current) setIsPopoverOpen(isPopoverOpen)
    if (isPopoverOpen) preventPopoverOpen.current = true
  }, [connectionsLoaded, connections.length, itemIds.length, url])

  const closePopover = () => {
    setIsPopoverOpen(false)
  }

  const syncStatus = useSelector(selectConnectionOverviewSyncStatus)
  const [localSyncStatus, setLocalSyncStatus] = useState(syncStatus)
  useEffect(() => {
    setLocalSyncStatus(syncStatus)
  }, [syncStatus])

  const handleSync = async () => {
    setLocalSyncStatus([SyncStatus.IN_PROGRESS])
    await syncData({ containerId })
  }

  const showSyncButton = useMemo(
    () => connections.some((c) => connectionRequiresManualSync(c)),
    [connections]
  )

  return (
    <Popover2
      popoverClassName="w-80"
      isOpen={isPopoverOpen}
      onClose={closePopover}
      placement="top-end"
      portalClassName={classNamesWithDarkMode()}
      content={
        <div className="p-6 overflow-hidden">
          <h2 className="text-text-primary-light dark:text-text-primary-dark mb-2 text-base font-semibold leading-normal">
            Connections
          </h2>
          <p className="text-text-secondary-light dark:text-text-secondary-dark mb-9 text-sm leading-normal">
            Connect your favorite apps to access and update your content from
            Qualdesk
          </p>
          <div className="flex justify-between transform origin-center scale-125">
            <IntegrationIcon iconUrl={jiraIcon} iconAltText="Jira icon" />
            <IntegrationIcon
              iconUrl={googleGIcon}
              iconAltText="Google icon"
              className="mt-10 transform origin-center scale-90"
            />
            <IntegrationIcon
              iconUrl={linearIcon}
              iconAltText="Linear icon"
              className="mt-4"
            />
            <IntegrationIcon
              iconUrl={trelloIcon}
              iconAltText="Trello icon"
              className="mt-8 transform origin-center scale-90"
            />
          </div>
        </div>
      }
    >
      <Switch>
        <Route path={Routes.CANVAS_CONNECTIONS.path}>
          <div className="flex flex-col space-y-2">
            {showSyncButton && (
              <div
                className="bg-background-five-light dark:bg-background-five-dark hover:bg-background-four-light dark:hover:bg-button-background-four-dark text-icon-primary-light dark:text-icon-primary-dark border-border-weak-light dark:border-border-weak-dark flex items-center justify-center mr-80 w-12 h-12 border rounded-lg"
                onClick={handleSync}
                role="button"
              >
                <SyncArrows
                  className={
                    localSyncStatus.includes(SyncStatus.ERROR)
                      ? 'text-icon-danger-light dark:text-icon-danger-dark'
                      : 'text-icon-primary-light dark:text-icon-primary-dark'
                  }
                  size={20}
                  weight="bold"
                  syncStatus={localSyncStatus}
                />
              </div>
            )}
            <Link to={generatePath(Routes.CANVAS.path, { containerId })}>
              <div className="bg-background-five-light dark:bg-background-five-dark hover:bg-background-four-light dark:hover:bg-button-background-four-dark text-icon-primary-light dark:text-icon-primary-dark border-border-weak-light dark:border-border-weak-dark flex items-center justify-center mr-80 w-12 h-12 border rounded-lg">
                <ArrowLineRight size={20} weight="bold" />
              </div>
            </Link>
          </div>
        </Route>
        <Link
          className="border-border-weak-light dark:border-border-weak-dark bg-background-five-light dark:bg-background-five-dark hover:bg-background-four-light dark:hover:bg-button-background-four-dark flex items-center pl-3 pr-4 h-12 hover:no-underline border rounded-lg space-x-2"
          to={generatePath(Routes.CANVAS_CONNECTIONS.path, { containerId })}
        >
          <Lightning
            className="text-icon-accent-light dark:text-icon-accent-dark"
            size={20}
            weight="fill"
          />
          <span className="text-text-primary-light dark:text-text-primary-dark">
            Connections
          </span>
        </Link>
      </Switch>
    </Popover2>
  )
}

interface IntegrationIconProps {
  iconUrl: string
  iconAltText: string
  className?: string
}

const IntegrationIcon: React.FC<IntegrationIconProps> = ({
  iconUrl,
  iconAltText,
  className,
}) => (
  <div
    className={classNames(
      'bg-background-five-light dark:bg-background-four-dark border-border-weak-light dark:border-border-weak-dark flex items-center justify-center w-14 h-14 border rounded-xl',
      className
    )}
  >
    <img className="w-8 h-8" src={iconUrl} alt={iconAltText} />
  </div>
)
