/*---- External -------------------------------------------------------------*/

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Button, Intent, Spinner } from '@blueprintjs/core'

import PQueue from 'p-queue'

import { Helmet } from 'react-helmet-async'

/*---- Qualdesk -------------------------------------------------------------*/

import { useAreYouSureContext } from '../../contexts/AreYouSure'

import { UserDetailsPanel } from './panels/UserDetailsPanel'
import { DarkModePanel } from './panels/DarkModePanel'

import { SettingsPanelProps } from '../../types/settings'
import { Routes } from '../../router/routes'

/*---------------------------------------------------------------------------*/

const settingsUpdateQueue = new PQueue()

export const AccountSettings: React.FC = () => {
  const [synced, setSynced] = useState<boolean | null>(null)

  const { showAlert } = useAreYouSureContext()
  const { push } = useHistory()

  const handleSignOut = () =>
    showAlert(
      () => {
        push(Routes.AUTH_SIGN_OUT.path)
      },
      'Are you sure you want to sign out?',
      { confirmButtonText: 'Sign out' }
    )

  const panelProps: SettingsPanelProps = {
    className: 'rounded-lg bg-white dark:bg-cool-gray-750 shadow p-6',
    queueUpdate: (fn) => settingsUpdateQueue.add(fn),
  }

  useEffect(() => {
    settingsUpdateQueue.on('add', () => {
      setSynced(false)
    })
    settingsUpdateQueue.on('idle', () => {
      setSynced(true)
    })
  }, [])

  return (
    <div className="bg-background-four-light dark:bg-background-four-dark relative flex flex-col flex-grow h-full">
      <Helmet title="Account settings" />
      <div className="overflow-auto">
        <div className="flex flex-row items-end justify-between mt-12 mx-12">
          <div className="text-3xl font-semibold">Account settings</div>
          <div className="text-text-secondary-light dark:text-text-secondary-dark">
            {synced && 'Changes saved'}
            {synced === false && (
              <div className="flex items-center space-x-1">
                <Spinner size={12} />
                <div>Saving changes</div>
              </div>
            )}
          </div>
        </div>
        <div className="grid gap-6 grid-cols-1 m-12 lg:grid-cols-2">
          <UserDetailsPanel {...panelProps} />
          <DarkModePanel {...panelProps} />
          <div>
            <Button intent={Intent.DANGER} minimal onClick={handleSignOut}>
              Sign out
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
