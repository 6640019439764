import { IMenuItemProps, Menu } from '@blueprintjs/core'
import { Item } from 'canvas-shared/lib/types/Item.types'
import { LinearUser } from 'canvas-shared/lib/types/connections/Linear.types'
import { UUID } from '../../../../types'

interface AssignUserProps {
  cards: Item.LinearCard[]
  linearAssignUser: (ids: UUID[], user: LinearUser) => Promise<void>
}

export const assignLinearUser = ({
  cards,
  linearAssignUser,
}: AssignUserProps): IMenuItemProps => {
  const uniqMembers = (account: LinearUser, idx: number, list: LinearUser[]) =>
    list.findIndex((a) => a.id === account.id) === idx
  const allMembers = cards
    .flatMap((c) => c.data?.linear?.team?.members?.nodes ?? [])
    .filter(uniqMembers)

  const assignUser = (user: LinearUser) => {
    linearAssignUser(
      cards.map((c) => c.id),
      user
    )
  }

  return {
    text: 'Assign to',
    icon: 'new-person',
    disabled: allMembers.length < 1,
    children: allMembers.map((m: LinearUser) => (
      <Menu.Item
        key={m.id}
        text={m.displayName}
        onClick={() => assignUser(m)}
      />
    )),
  }
}
