/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import useLocalStorageSettings from '../useLocalStorageSettings'
import { useSystemDarkMode } from './useSystemDarkMode'

import { DarkMode } from 'canvas-shared/lib/types/settings.types'

/*---------------------------------------------------------------------------*/

export const useDarkMode = () => {
  const { useDarkModeSetting } = useLocalStorageSettings()
  const [userDarkModeSetting] = useDarkModeSetting()
  const systemDarkMode = useSystemDarkMode()

  switch (userDarkModeSetting) {
    case DarkMode.LIGHT:
      return false
    case DarkMode.DARK:
      return true
    case DarkMode.SYSTEM:
      return systemDarkMode
    default:
      return false
  }
}
