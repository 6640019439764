import React from 'react'
import { useDarkMode } from '../../hooks/darkMode/useDarkMode'

interface QualdeskIconProps {
  size?: number
}

export const QualdeskIcon: React.FC<QualdeskIconProps> = ({ size = 24 }) => {
  const darkMode = useDarkMode()

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.707 4.837C1.077 4.207 0 4.653 0 5.544v17.469a1 1 0 001 1h17.468c.891 0 1.337-1.077.707-1.707L1.707 4.837z"
        fill={darkMode ? '#3C83F6' : '#5DEAD5'}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.6 1.2A1 1 0 0010 2v16a1 1 0 001.6.8l10.667-8a1 1 0 000-1.6L11.6 1.2z"
        fill={darkMode ? '#2BD4BD' : '#2463EB'}
      />
    </svg>
  )
}
