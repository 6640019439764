/*---- External -------------------------------------------------------------*/

import React from 'react'

import classNames from 'classnames'

import { NonIdealState, INonIdealStateProps } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { ReactComponent as NoResultsIcon } from '../../assets/icons/no-results.svg'

/*---------------------------------------------------------------------------*/

interface NoResultsProps extends INonIdealStateProps {
  title?: React.ReactNode
}

export const NoResults: React.FC<NoResultsProps> = ({
  title = 'No results',
  className,
}) => (
  <NonIdealState
    className={classNames(
      'no-results text-icon-secondary-light dark:text-icon-secondary-dark py-6',
      className
    )}
    icon={<NoResultsIcon />}
    title={
      <div className="text-text-secondary-light dark:text-text-secondary-dark text-sm font-semibold">
        {title}
      </div>
    }
  />
)
