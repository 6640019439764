/*---- External -------------------------------------------------------------*/

import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPreparedPayload,
} from '@reduxjs/toolkit'
import { OneOrMany } from 'canvas-shared/lib/types'
import { Epic, ofType } from 'redux-observable'
import { asyncScheduler, OperatorFunction } from 'rxjs'
import { tap } from 'rxjs/internal/operators/tap'
import { ignoreElements, map, observeOn } from 'rxjs/operators'

/*---- Qualdesk -------------------------------------------------------------*/

import { app } from '../../../config/firebase'
import type {
  ActionWithPayload,
  PayloadWithContainerAndUid,
} from '../../../types/redux'
import type { RootState } from '../../../types/redux'

import { mapPayloadUserAndContainerId } from './mapPayloadUserAndContainerId'

/*---------------------------------------------------------------------------*/

export type PayloadWithRTDB<T> = T & { database: typeof app.database }

export function withRTDB<T extends {}>(): OperatorFunction<
  T,
  PayloadWithRTDB<T>
> {
  return map<T, PayloadWithRTDB<T>>((value) => ({
    ...value,
    database: app.database,
  }))
}

export function createRTDBEpic<Payload>(
  actionCreator: OneOrMany<
    ActionCreatorWithPreparedPayload<any, Payload> | ActionCreatorWithoutPayload
  >,
  handler: OneOrMany<
    (value: PayloadWithRTDB<PayloadWithContainerAndUid<Payload>>) => void
  >
): Epic<ActionWithPayload<Payload>, never, RootState> {
  // a hack to allow for Epic to support multiple actions
  const actionCreators = Array.isArray(actionCreator)
    ? actionCreator
    : [actionCreator]

  const handlers = Array.isArray(handler) ? handler : [handler]

  return (action$, state$) => {
    const operations = [
      observeOn(asyncScheduler, 25),
      ofType(...actionCreators),
      mapPayloadUserAndContainerId<ActionWithPayload<Payload>, Payload>(state$),
      withRTDB<PayloadWithContainerAndUid<Payload>>(),
      ...handlers.map((h) =>
        tap<PayloadWithRTDB<PayloadWithContainerAndUid<Payload>>>(h)
      ),
    ]

    // @ts-ignore: Unfortunately, cannot use spread operator for .pipe() but we have to if we
    // want to support multiple handlers. It fails, becuase .pipe() expects a known number of
    // arguments, and TS has no way of knowing how many are there going to be in the array.
    return action$.pipe(...operations, ignoreElements()) // added ignoreElements() to ensure type is right
  }
}
