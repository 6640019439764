/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

const convertToPx = (val: number | undefined) =>
  val !== undefined ? `${val}px` : undefined

export default convertToPx
