/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { generateUserPath } from './userPaths'

import { UUID } from '../../types'

/*---------------------------------------------------------------------------*/

export const generateZoomLevelPath = (containerId: UUID, uid: string) =>
  `${generateUserPath(containerId, uid)}/zoomLevel`
