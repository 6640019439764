/*---- External -------------------------------------------------------------*/

import { Classes } from '@blueprintjs/core'

import classNames from 'classnames'
import { ClassValue } from 'classnames/types'

/*---- Qualdesk -------------------------------------------------------------*/

import { useDarkMode } from './useDarkMode'

/*---------------------------------------------------------------------------*/

export const useClassNamesWithDarkMode = () => {
  const darkMode = useDarkMode()
  const classNamesWithDarkMode = (...classes: ClassValue[]) =>
    classNames(classes, {
      [Classes.DARK]: darkMode,
      dark: darkMode,
    })
  return { classNamesWithDarkMode }
}
