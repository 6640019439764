/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import {
  BasicPointerEvent,
  PointerEventIntersections,
} from 'canvas-shared/lib/types/pointerEvents.types'
import { selectEditsItemIds } from '../../../../redux/selectors/board/edits'
import type { RootState } from '../../../../types/redux'
import { pointerEventInGroup } from './pointerEventInGroup'
import { pointerEventInPosition } from './pointerEventInPosition'
import { pointerEventInDragHandle } from './pointerEventInDragHandle'
import { pointerEventInSelectionRect } from './pointerEventInSelectionRect'

/*---------------------------------------------------------------------------*/

export const pointerEventIntersections = (
  state: RootState,
  pointer: BasicPointerEvent
): PointerEventIntersections => {
  const editsItemIds = selectEditsItemIds(state)
  const dragHandle = pointerEventInDragHandle(state, pointer)
  const position =
    editsItemIds.length > 0 ? undefined : pointerEventInPosition(state, pointer)
  const group =
    editsItemIds.length > 0 ? undefined : pointerEventInGroup(state, pointer)
  const backdrop =
    (pointer.target as Element).id === 'canvas-backdrop' &&
    !dragHandle &&
    !position &&
    !group
  const gutter = (pointer.target as Element).id === 'canvas-gutter' && !backdrop
  const selectionRect = pointerEventInSelectionRect(state, pointer)

  return {
    dragHandle,
    position,
    group,
    backdrop,
    gutter,
    selectionRect,
  }
}
