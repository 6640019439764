/*---- External -------------------------------------------------------------*/

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { positionsLoadedSelector } from '../../selectors/board/positions'

/*---------------------------------------------------------------------------*/

export const usePositionsLoaded = () => useSelector(positionsLoadedSelector)
