/*---- External -------------------------------------------------------------*/

import React from 'react'

import { useInView } from 'react-intersection-observer'

import { IPanelProps } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { SidebarPanelStackHeader } from './SidebarPanelStackHeader'

/*---------------------------------------------------------------------------*/

export interface SidebarPanelStackHeaderProps extends IPanelProps {
  text: string
  iconUrl: string
  iconAltText: string
  back?: () => void
  footer?: React.ReactNode
}

export const SidebarPanel: React.FC<SidebarPanelStackHeaderProps> = ({
  children,
  footer,
  ...headerProps
}) => {
  const [scrollTrigger, inView, entry] = useInView()
  const scrolled = !!entry && !inView

  return (
    <div className="flex flex-col h-full">
      <SidebarPanelStackHeader {...headerProps} scrolled={scrolled} />
      <div className="flex-grow overflow-x-hidden overflow-y-auto">
        <div ref={scrollTrigger} />
        {children}
      </div>
      {footer}
    </div>
  )
}
