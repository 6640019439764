/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'
import { createCachedSelector } from 're-reselect'

/*---- Qualdesk -------------------------------------------------------------*/

import { getItemId } from './items'
import { mySelectedItemIdsSelector } from './selections'

import { createDeepEqualSelector } from '../helpers/createDeepEqualSelector'

import getBoundingRectFromPositions from '../../../helpers/getBoundingRectFromPositions'
import { isRectPosition } from 'canvas-shared/lib/helpers/isRectPosition'
import { isLinePosition } from 'canvas-shared/lib/helpers/isLinePosition'

import {
  ItemPosition,
  LinePosition,
  RectPosition,
} from 'canvas-shared/lib/types/Position.types'
import { RootState } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

export const positionsDataSelector = (state: RootState) =>
  state.board.items.positions.data

export const positionsOrderedSelector = createDeepEqualSelector(
  positionsDataSelector,
  (positions): ItemPosition[] =>
    !!positions
      ? Object.values(positions).sort((a, b) => a.z - b.z)
      : NO_POSITIONS
)

export const positionsOrderedFrontToBackSelector = createSelector(
  positionsDataSelector,
  (positions): ItemPosition[] =>
    !!positions
      ? Object.values(positions).sort((a, b) => b.z - a.z)
      : NO_POSITIONS
)

export const selectedItemsPositionsSelector = createDeepEqualSelector(
  positionsDataSelector,
  mySelectedItemIdsSelector,
  (positions, selectedItemIds) =>
    selectedItemIds.reduce(
      (res: ItemPosition[], id) =>
        !!positions[id] ? res.concat(positions[id]) : res,
      []
    )
)

export const singleLineSelectedSelector = createSelector(
  selectedItemsPositionsSelector,
  (positions) => {
    if (positions.length === 1 && isLinePosition(positions[0])) {
      const startAtLeft = positions[0].startX <= positions[0].endX
      const startAtTop = positions[0].startY <= positions[0].endY

      return {
        start: {
          left: startAtLeft,
          right: !startAtLeft,
          top: startAtTop,
          bottom: !startAtTop,
        },
        end: {
          left: !startAtLeft,
          right: startAtLeft,
          top: !startAtTop,
          bottom: startAtTop,
        },
        position: positions[0],
      }
    }
    return false
  }
)

export const itemPositionSelector = createCachedSelector(
  getItemId,
  positionsDataSelector,
  (itemId, allPositions) => {
    if (!!allPositions && !!allPositions[itemId]) {
      if (isRectPosition(allPositions[itemId])) {
        return allPositions[itemId] as RectPosition
      } else if (isLinePosition(allPositions[itemId])) {
        return allPositions[itemId] as LinePosition
      }
    }

    return undefined
  }
)((_state, itemId) => itemId)

export const selectionRectSelector = createSelector(
  selectedItemsPositionsSelector,
  (positions) =>
    positions.length >= 1 ? getBoundingRectFromPositions(positions) : undefined
)

export const positionsLoadedSelector = (state: RootState) =>
  state.board.items.positions.loaded

export const selectRectPositionIds = createSelector(
  positionsOrderedSelector,
  (positions) => positions.filter((p) => isRectPosition(p)).map((p) => p.id)
)

export const selectLinePositionIds = createSelector(
  positionsOrderedSelector,
  (positions) => positions.filter((p) => isLinePosition(p)).map((p) => p.id)
)

export const selectStartZ = createSelector(
  positionsOrderedSelector,
  (positions) =>
    (positions.length > 0 ? Math.max(...positions.map((c) => c.z)) : 0) + 1
)

const NO_POSITIONS: ItemPosition[] = []
