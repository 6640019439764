/*---- External -------------------------------------------------------------*/

import React from 'react'

import { DotsThree, WarningCircle } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import { LinearPriority } from 'canvas-shared/lib/types/connections/Linear.types'
import { LinearPreviewData } from 'canvas-shared/lib/types/previewItem/LinearPreviewData.types'

/*---------------------------------------------------------------------------*/

interface LinearIssuePriorityIconProps
  extends Pick<LinearPreviewData, 'priority' | 'priorityLabel'> {
  small?: boolean
}

export const LinearIssuePriorityIcon: React.FC<LinearIssuePriorityIconProps> = ({
  priority,
  priorityLabel,
  small = false,
}) => {
  const size = small ? 16 : 20

  return (
    <span
      className="text-icon-primary-light dark:text-icon-primary-dark"
      title={
        priority > LinearPriority.NoPriority
          ? `${priorityLabel} priority`
          : priorityLabel
      }
    >
      {priority === LinearPriority.NoPriority && (
        <DotsThree size={size} weight="bold" />
      )}
      {priority === LinearPriority.Urgent && (
        <WarningCircle size={size} weight="bold" />
      )}
      {priority > LinearPriority.Urgent && (
        <svg fill="currentColor" width={size} height={size} viewBox="0 0 16 16">
          <rect
            x="1"
            y="8"
            width="3"
            height="6"
            rx="1"
            fillOpacity={priority <= LinearPriority.Low ? '1.0' : '0.2'}
          ></rect>
          <rect
            x="6"
            y="5"
            width="3"
            height="9"
            rx="1"
            fillOpacity={priority <= LinearPriority.Medium ? '1.0' : '0.2'}
          ></rect>
          <rect
            x="11"
            y="2"
            width="3"
            height="12"
            rx="1"
            fillOpacity={priority <= LinearPriority.High ? '1.0' : '0.2'}
          ></rect>
        </svg>
      )}
    </span>
  )
}
