/*---- External -------------------------------------------------------------*/

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import convertToPx from '../../helpers/convertToPx'
import getStyleFromDOMRect from '../../helpers/getStyleFromDOMRect'

import { CanvasHoverToolbar } from './CanvasHoverToolbar'
import { CanvasDragHandle, DRAG_HANDLE_WIDTH } from './CanvasDragHandle'

import { useSelectedItems } from '../../redux/selectorHooks/board/useSelectedItems'
import { useSelectedItemPositions } from '../../redux/selectorHooks/board/useSelectedItemPositions'
import { useSelectionRect } from '../../redux/selectorHooks/board/useSelectionRect'
import { useSingleLineSelected } from '../../redux/selectorHooks/board/useSingleLineSelected'
import { selectZoomScale } from '../../redux/selectors/board/zoom'
import { getBoundingRectFromPositions } from '../../helpers/getBoundingRectFromPositions'

/*---------------------------------------------------------------------------*/

const CanvasSelectionRect: React.FC = () => {
  const selectionRect = useSelectionRect()
  const zoomScale = useSelector(selectZoomScale)
  const selectedItems = useSelectedItems()
  const singleLineSelected = useSingleLineSelected()

  const style = useMemo(() => {
    if (!!selectionRect) {
      return {
        ...getStyleFromDOMRect(selectionRect),
        outlineWidth: 2 * zoomScale,
      }
    } else {
      return undefined
    }
  }, [selectionRect, zoomScale])

  if (!selectionRect || selectedItems.length < 1) {
    return null
  }

  return (
    <>
      <LineDragHandles />
      <RectDragHandles />
      <div
        className={classNames('z-selection-rect absolute pointer-events-none', {
          'selected-canvas-item-bold': !singleLineSelected,
        })}
        style={style}
      >
        <CanvasHoverToolbar />
      </div>
    </>
  )
}

const RectDragHandles: React.FC = () => {
  const selectedPositions = useSelectedItemPositions()
  const singleLineSelected = useSingleLineSelected()

  const rect = getBoundingRectFromPositions(selectedPositions)

  const topLeftStyle: React.CSSProperties = useMemo(
    () => ({ top: convertToPx(rect.top), left: convertToPx(rect.left) }),
    [rect.left, rect.top]
  )
  const topRightStyle: React.CSSProperties = useMemo(
    () => ({
      top: convertToPx(rect.top),
      left: convertToPx(rect.left + rect.width - DRAG_HANDLE_WIDTH),
    }),
    [rect.left, rect.top, rect.width]
  )

  const bottomLeftStyle: React.CSSProperties = useMemo(
    () => ({
      top: convertToPx(rect.top + rect.height - DRAG_HANDLE_WIDTH),
      left: convertToPx(rect.left),
    }),
    [rect.height, rect.left, rect.top]
  )
  const bottomRightStyle: React.CSSProperties = useMemo(
    () => ({
      top: convertToPx(rect.top + rect.height - DRAG_HANDLE_WIDTH),
      left: convertToPx(rect.left + rect.width - DRAG_HANDLE_WIDTH),
    }),
    [rect.height, rect.left, rect.top, rect.width]
  )

  if (!!singleLineSelected) {
    return null
  }

  return (
    <>
      <CanvasDragHandle
        className="absolute -ml-4 -mt-4"
        style={topLeftStyle}
        handleId="dh-top-left"
      />
      <CanvasDragHandle
        className="absolute -mt-4 ml-4"
        style={topRightStyle}
        handleId="dh-top-right"
      />
      <CanvasDragHandle
        className="absolute -ml-4 mt-4"
        style={bottomLeftStyle}
        handleId="dh-bottom-left"
      />
      <CanvasDragHandle
        className="absolute ml-4 mt-4"
        style={bottomRightStyle}
        handleId="dh-bottom-right"
      />
    </>
  )
}

const LineDragHandles: React.FC = () => {
  const singleLineSelected = useSingleLineSelected()

  const startStyle: React.CSSProperties = useMemo(() => {
    if (!singleLineSelected) {
      return {}
    }

    return {
      position: 'absolute',
      top: convertToPx(
        singleLineSelected.position.startY - LINE_DRAG_HANDLE_INSET
      ),
      left: convertToPx(
        singleLineSelected.position.startX - LINE_DRAG_HANDLE_INSET
      ),
    }
  }, [singleLineSelected])

  const endStyle: React.CSSProperties = useMemo(() => {
    if (!singleLineSelected) {
      return {}
    }

    return {
      position: 'absolute',
      top: convertToPx(
        singleLineSelected.position.endY - LINE_DRAG_HANDLE_INSET
      ),
      left: convertToPx(
        singleLineSelected.position.endX - LINE_DRAG_HANDLE_INSET
      ),
    }
  }, [singleLineSelected])

  if (!singleLineSelected) {
    return null
  }

  return (
    <>
      <CanvasDragHandle handleId="dh-start" style={startStyle} />
      <CanvasDragHandle handleId="dh-end" style={endStyle} />
    </>
  )
}

const LINE_DRAG_HANDLE_INSET = DRAG_HANDLE_WIDTH / 2

export default CanvasSelectionRect
