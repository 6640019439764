"use strict";
/*---- External -------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.permissionLevelLabel = void 0;
/*---- Qualdesk -------------------------------------------------------------*/
const permissions_types_1 = require("../types/permissions.types");
/*---------------------------------------------------------------------------*/
const permissionLevelLabel = (level) => {
    switch (level) {
        case permissions_types_1.PermissionLevel.CREATOR:
            return 'Owner';
        case permissions_types_1.PermissionLevel.ADMIN:
            return 'Admin';
        case permissions_types_1.PermissionLevel.WRITE:
            return 'Can edit';
        case permissions_types_1.PermissionLevel.READ:
            return 'View only';
        default:
            return 'No access';
    }
};
exports.permissionLevelLabel = permissionLevelLabel;
