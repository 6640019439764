/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import {
  MousePosition,
  ScrollDimensions,
  ScrollOffsets,
} from 'canvas-shared/lib/types/scrollAndPosition.types'

/*---------------------------------------------------------------------------*/

export const DEFAULT_CANVAS_HEIGHT = 6330
export const DEFAULT_CANVAS_WIDTH = 10240

export const DEFAULT_ZOOM_LEVEL = 0.8

export const MIN_ZOOM_LEVEL = 0.1
export const MAX_ZOOM_LEVEL = 1.6

export const DEFAULT_CURSOR_POSITION: MousePosition = [200, 200]

export const DEFAULT_SCROLL_DIMENSIONS: ScrollDimensions = {
  clientHeight: DEFAULT_CANVAS_HEIGHT,
  clientWidth: DEFAULT_CANVAS_WIDTH,
  scrollHeight: 0,
  scrollWidth: 0,
  canvasLeft: 0,
  canvasTop: 0,
  offsetLeft: 0,
  offsetTop: 0,
  pageLeft: 0,
  pageTop: 0,
}

export const DEFAULT_SCROLL_OFFSETS: ScrollOffsets = {
  scrollLeft: 0,
  scrollTop: 0,
}

export const CANVAS_GUTTER = 16

export const GROUP_RECT_PADDING = 25

export const MINIMAP_WIDTH = 256
