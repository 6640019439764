/*---- External -------------------------------------------------------------*/

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { mySelectedItemIdsSelector } from '../../selectors/board/selections'

/*---------------------------------------------------------------------------*/

export const useSelectedItemIds = () => useSelector(mySelectedItemIdsSelector)
