/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { containersWithIdSelector } from './containers'
import { selectMyUid } from '../auth/auth'

import { PermissionLevel } from 'canvas-shared/lib/types/permissions.types'
import { UUID } from 'canvas-shared/lib/types'

/*---------------------------------------------------------------------------*/

export const boardsSelector = containersWithIdSelector

export const archivedBoardsSelector = createSelector(
  boardsSelector,
  selectMyUid,
  (boards, uid) =>
    boards?.filter(
      (b) =>
        b.roles.users[uid as string] >= PermissionLevel.CREATOR && b.archived
    )
)
export const activeBoardsSelector = createSelector(boardsSelector, (boards) =>
  boards?.filter((b) => !b.archived)
)

export const myBoardsSelector = createSelector(
  activeBoardsSelector,
  selectMyUid,
  (boards, uid) =>
    boards?.filter(
      (b) => b.roles.users[uid as string] >= PermissionLevel.CREATOR
    )
)

export const boardsSharedWithMeSelector = createSelector(
  activeBoardsSelector,
  selectMyUid,
  (boards, uid) =>
    boards?.filter(
      (b) =>
        b.roles.users[uid as string] < PermissionLevel.CREATOR &&
        b.roles.users[uid as string] >= PermissionLevel.READ
    )
)

export const createTeamBoardsSelector = (teamId: UUID) =>
  createSelector(activeBoardsSelector, (boards) =>
    boards?.filter((b) => b.roles.teams?.membership === teamId)
  )
