/*---- External -------------------------------------------------------------*/

import React from 'react'

import { sheets_v4 } from 'googleapis'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import GoogleFileIcon from './GoogleFileIcon'

import { GoogleSheetsColumn } from 'canvas-shared/lib/types/connections/Google.types'

/*---------------------------------------------------------------------------*/

interface PreviewCardProps {
  fileTitle: string
  row: sheets_v4.Schema$RowData
  columns: GoogleSheetsColumn[]
  columnHeaders: string[]
  numCols: number
}

export const PreviewCard: React.FC<PreviewCardProps> = ({
  fileTitle,
  row,
  columns,
  columnHeaders,
  numCols,
}) => {
  let columnIndexes: number[] = []

  for (let columnIndex = 0; columnIndex < numCols; columnIndex++) {
    columnIndexes.push(columnIndex)
  }

  return (
    <div className="bg-background-five-light dark:bg-background-five-dark border-border-normal-light dark:border-border-normal-dark flex flex-col w-full h-full border rounded-md shadow-sm">
      <div className="flex flex-col flex-grow py-1 overflow-hidden">
        {columnIndexes.map((columnIndex) => {
          if (columns[columnIndex].visible) {
            return (
              <div
                className={classNames(
                  'border-border-normal-light dark:border-border-normal-dark px-3 py-1 border-b last:border-b-0',
                  { 'pb-2 font-semibold': columnIndex === 0 }
                )}
                key={columnIndex}
              >
                {row.values && row.values[columnIndex]?.formattedValue ? (
                  row.values[columnIndex].formattedValue
                ) : (
                  <span className="text-text-secondary-light">
                    {columnHeaders[columnIndex]}
                  </span>
                )}
              </div>
            )
          } else {
            return null
          }
        })}
      </div>
      <div className="bg-background-four-light dark:bg-background-four-dark border-border-normal-light dark:border-border-normal-dark flex flex-row flex-shrink-0 items-center mt-auto pl-2 pr-1 py-1 border-t rounded-b-md select-none overflow-hidden space-x-1">
        <GoogleFileIcon size={12} />
        <span className="text-text-secondary-light dark:text-text-secondary-dark text-xs">
          {fileTitle}
        </span>
      </div>
    </div>
  )
}
