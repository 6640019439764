/*---- External -------------------------------------------------------------*/

import React from 'react'

import { NavLink } from 'react-router-dom'

/*---- Qualdesk -------------------------------------------------------------*/

import BoardTitle from '../board/BoardTitle'
import { BoardTeam } from '../board/BoardTeam'
import { BoardInviteButton } from '../invite/BoardInviteButton'

import CanvasTools from '../canvas/CanvasTools'
import CanvasPresenceIndicator from '../canvas/CanvasPresenceIndicator'

import { QualdeskIcon } from '../../assets/brand/QualdeskIcon'

/*---------------------------------------------------------------------------*/

export const BoardNavigation: React.FC = () => (
  <div className="z-above-everything bg-background-five-light dark:bg-background-five-dark border-border-weak-light dark:border-border-weak-dark relative flex flex-grow-0 flex-shrink-0 items-center px-6 h-14 border-b select-none overflow-hidden">
    <div className="flex flex-1 items-center justify-start mr-6 overflow-hidden space-x-4">
      <NavLink to="/">
        <QualdeskIcon size={20} />
      </NavLink>
      <div className="flex flex-shrink items-center overflow-hidden">
        <BoardTeam />
        <BoardTitle className="text-lg" />
      </div>
    </div>
    <div className="flex items-center justify-center">
      <CanvasTools />
    </div>
    <div className="flex flex-1 items-center justify-end ml-6 space-x-4">
      <CanvasPresenceIndicator />
      <BoardInviteButton />
    </div>
  </div>
)
