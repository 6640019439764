/*---- External -------------------------------------------------------------*/

import React, { useMemo } from 'react'

import { Plus, SortAscending, SortDescending } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import googleSheetsIcon from '../../../assets/integrations/google/google-sheets-icon.svg'

import { useGoogleSheetsRecentSort } from '../../../hooks/localStorage/googleSheetsRecentSort'

import {
  CustomSidebarPopoverButtonProps,
  SidebarPopover,
} from '../SidebarPopover'

import { ControlledPanelProps } from '../SidebarPanelStack'
import { GoogleSelectFilePopover } from './GoogleSelectFilePopover'
import { GoogleSheetsRecentConnection } from './GoogleSheetsRecentConnection'

import { SortOrderPopoverButton } from '../../utilities/SortOrderPopoverButton'
import { compareUpdatedAt } from 'canvas-shared/lib/sort/compareUpdatedAt'

import { GoogleSheetsBoardConnection } from 'canvas-shared/lib/types/BoardConnection.types'
import { GenericSort } from '../../../types'
import { firstBy } from 'thenby'

/*---------------------------------------------------------------------------*/

interface GoogleSelectFileListProps extends ControlledPanelProps {
  recentConnections: GoogleSheetsBoardConnection[]
}

const compareGoogleFileName = (
  a: GoogleSheetsBoardConnection,
  b: GoogleSheetsBoardConnection
) => a.google.file.name.localeCompare(b.google.file.name)

const compareGoogleSheetName = (
  a: GoogleSheetsBoardConnection,
  b: GoogleSheetsBoardConnection
) =>
  (
    a.google.options.sheetOptions[a.google.options.selectedSheetId]
      ?.sheetName || ''
  ).localeCompare(
    b.google.options.sheetOptions[b.google.options.selectedSheetId]
      ?.sheetName || ''
  )

export const GoogleSelectFileList: React.FC<GoogleSelectFileListProps> = ({
  recentConnections,
  ...panelProps
}) => {
  const [sort, setSort] = useGoogleSheetsRecentSort()

  const sortedConnections = useMemo(
    () =>
      [...recentConnections].sort(
        firstBy(compareUpdatedAt, sort)
          .thenBy(compareGoogleFileName)
          .thenBy(compareGoogleSheetName)
      ),
    [recentConnections, sort]
  )

  return (
    <div className="flex flex-col flex-grow justify-start p-3 space-y-6">
      <div className="flex flex-col space-y-4">
        <div className="text-text-secondary-light dark:text-text-secondary-dark mt-3 mx-3 text-sm font-semibold">
          New
        </div>
        <SidebarPopover
          customButton={PopoverButton}
          content={<GoogleSelectFilePopover {...panelProps} />}
          fill
        />
      </div>
      <div className="flex flex-col space-y-4">
        <div className="flex items-center justify-between ml-3">
          <div className="text-text-secondary-light dark:text-text-secondary-dark text-sm font-semibold">
            Recently connected
          </div>
          <SortOrderPopoverButton
            className="text-text-secondary-light dark:text-text-secondary-dark flex-shrink-0"
            sort={sort}
            sortOptions={[
              {
                label: 'Most recent first',
                value: GenericSort.Asc,
                icon: SortAscending,
              },
              {
                label: 'Least recent first',
                value: GenericSort.Desc,
                icon: SortDescending,
              },
            ]}
            onChangeSort={setSort}
            text="Recent"
          />
        </div>
        <div className="flex flex-col space-y-2">
          {sortedConnections.map((c) => (
            <GoogleSheetsRecentConnection
              key={c.id + c.google.options.selectedSheetId}
              openPanel={panelProps.openPanel}
              recentConnection={c}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const PopoverButton: React.FC<CustomSidebarPopoverButtonProps> = ({
  onClick,
}) => (
  <div
    className="hover:bg-button-secondary-hovered-light dark:hover:bg-button-secondary-hovered-dark active:bg-button-secondary-active-light dark:active:bg-button-secondary-active-dark flex items-center p-3 rounded-sm"
    onClick={onClick}
    role="button"
  >
    <img
      alt="Google Sheets icon"
      className="mr-3 w-5 h-5"
      src={googleSheetsIcon}
    />
    <div>Connect new sheet</div>
    <div className="ml-auto">
      <Plus
        className="text-icon-secondary-light dark:text-icon-secondary-dark w-4 h-4"
        weight="bold"
      />
    </div>
  </div>
)
