/*---- External -------------------------------------------------------------*/

import { useCallback, useMemo } from 'react'

import { useFirebase } from 'react-redux-firebase'
import { DataSnapshot } from '@firebase/database-types'

/*---- Qualdesk -------------------------------------------------------------*/

import { generateInteractionModePath } from '../../paths/interactionModePaths'

import { useContainerId } from '../../../contexts/Container'

import { InteractionMode } from '../../../types'

/*---------------------------------------------------------------------------*/

interface InteractionModeActions {
  setInteractionMode: (mode: InteractionMode) => Promise<DataSnapshot>
  resetInteractionMode: () => Promise<DataSnapshot>
}

export const useInteractionModeActions = (): InteractionModeActions => {
  const firebase = useFirebase()
  const containerId = useContainerId()

  const setInteractionMode = useCallback(
    (mode: InteractionMode) =>
      firebase.set(generateInteractionModePath(containerId), mode),
    [containerId, firebase]
  )

  const resetInteractionMode = useCallback(
    () =>
      firebase.set(
        generateInteractionModePath(containerId),
        InteractionMode.NORMAL
      ),
    [containerId, firebase]
  )

  return useMemo(
    () => ({
      setInteractionMode,
      resetInteractionMode,
    }),
    [setInteractionMode, resetInteractionMode]
  )
}
