/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import type { BasicPointerEvent } from 'canvas-shared/lib/types/pointerEvents.types'
import type { RootState } from '../../../../types/redux'
import {
  scrollDimensionsSelector,
  scrollOffsetsSelector,
} from '../../../../redux/selectors/board/scroll'
import { selectZoomLevel } from '../../../../redux/selectors/board/zoom'
import { selectionRectSelector } from '../../../../redux/selectors/board/positions'

/*---------------------------------------------------------------------------*/

export const pointerEventInSelectionRect = (
  state: RootState,
  pointer: BasicPointerEvent
) => {
  const selectionRect = selectionRectSelector(state)

  if (!selectionRect) {
    return false
  }

  const scrollOffsets = scrollOffsetsSelector(state)
  const scrollDimensions = scrollDimensionsSelector(state)
  const zoomLevel = selectZoomLevel(state)

  const { clientX, clientY } = pointer
  const { scrollLeft, scrollTop } = scrollOffsets
  const { offsetLeft, offsetTop } = scrollDimensions

  const canvasX = (clientX - offsetLeft + scrollLeft) / zoomLevel
  const canvasY = (clientY - offsetTop + scrollTop) / zoomLevel

  return (
    canvasX >= selectionRect.left &&
    canvasX <= selectionRect.right &&
    canvasY >= selectionRect.top &&
    canvasY <= selectionRect.bottom
  )
}
