/*---- External -------------------------------------------------------------*/

import {
  combineEpics,
  createEpicMiddleware,
  EpicMiddleware,
} from 'redux-observable'

/*---- Qualdesk -------------------------------------------------------------*/

import { autoScrollEpic } from './sideEffects/autoScroll'
import { clipboardEpic } from './sideEffects/clipboard'
import { duplicationEpic } from './sideEffects/duplication'
import { editEpic } from './sideEffects/edits'
import { focusEpic } from './sideEffects/focus'
import { itemsEpic } from './sideEffects/items'
import { itemDataEpic } from './sideEffects/itemData'
import { pointersEpic } from './sideEffects/pointers'
import { positionsEpic } from './sideEffects/positions'
import { scrollEpic } from './sideEffects/scroll'
import { selectionEpic } from './sideEffects/selection'
import { sessionEpic } from './sideEffects/session'
import { zoomEpic } from './sideEffects/zoom'

import { paramsEpic } from './epics/params'
import { toastEpic } from './epics/toast'

import { RootState } from '../types/redux'
import { ActionWithPayload } from '../types/redux'

/*---------------------------------------------------------------------------*/

export const rootEpic = combineEpics(
  // board
  autoScrollEpic,
  clipboardEpic,
  duplicationEpic,
  editEpic,
  focusEpic,
  itemsEpic,
  itemDataEpic,
  pointersEpic,
  positionsEpic,
  scrollEpic,
  selectionEpic,
  sessionEpic,
  zoomEpic,

  // system
  paramsEpic,
  toastEpic
)

export const epicMiddleware: EpicMiddleware<
  ActionWithPayload<any>,
  ActionWithPayload<any>,
  RootState
> = createEpicMiddleware()
