/*---- External -------------------------------------------------------------*/

import React from 'react'

import { PaperPlaneTilt } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import { functions } from '../../../config/firebase'

import { UUID } from 'canvas-shared/lib/types'
import { PermissionLevel } from 'canvas-shared/lib/types/permissions.types'
import { TeamUserLevel } from './TeamUserLevel'

/*---------------------------------------------------------------------------*/

const updateTeamInvitationLevel = functions.httpsCallable(
  'https-authUpdateTeamInvitationLevel'
)
const deleteTeamInvitation = functions.httpsCallable(
  'https-authDeleteTeamInvitation'
)

interface TeamUserInvitationProps {
  email: string
  emailSent?: boolean
  invitationId: UUID
  level: PermissionLevel
  queueUpdate?: (fn: (() => PromiseLike<any>) | (() => any)) => Promise<any>
  teamId: UUID
}

export const TeamUserInvitation: React.FC<TeamUserInvitationProps> = ({
  email,
  emailSent,
  invitationId,
  level,
  queueUpdate,
  teamId,
}) => {
  const handleUpdate = (newLevel: PermissionLevel | null) => {
    const f = () =>
      updateTeamInvitationLevel({
        teamId,
        invitationId,
        level: newLevel,
      })

    if (queueUpdate) {
      queueUpdate(f)
    } else {
      f()
    }
  }

  const onRemove = () => {
    const f = () =>
      deleteTeamInvitation({
        teamId,
        invitationId,
      })

    if (queueUpdate) {
      queueUpdate(f)
    } else {
      f()
    }
  }

  return (
    <div className="flex items-center">
      <PaperPlaneTilt className="text-icon-secondary-light dark:text-icon-secondary-dark m-1 w-6 h-6" />
      <div className="flex flex-col ml-4">
        <div>{email}</div>
        <div className="text-text-secondary-light dark:text-text-secondary-dark text-xs">
          {emailSent ? 'Invited' : 'Sending invitation'}
        </div>
      </div>
      <TeamUserLevel
        level={level}
        onChange={handleUpdate}
        onRemove={onRemove}
      />
    </div>
  )
}
