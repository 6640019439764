"use strict";
/*---- External -------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.generatePseudoDOMRect = void 0;
/*---------------------------------------------------------------------------*/
const generatePseudoDOMRect = (x, y, width, height) => {
    const bottom = y + height;
    const right = x + width;
    const left = x;
    const top = y;
    return {
        bottom,
        height,
        left,
        right,
        top,
        width,
        x,
        y,
    };
};
exports.generatePseudoDOMRect = generatePseudoDOMRect;
