/*---- External -------------------------------------------------------------*/

import React, { useEffect, useRef, useState } from 'react'

import { Spinner } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

interface LoadingSpinnerProps {
  value?: number
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ value }) => {
  const defaultValue = value !== undefined ? value : 0.1
  const [internalValue, setInternalValue] = useState<number | undefined>(
    defaultValue
  )
  const valueAnimation = useRef<number | null>()

  useEffect(() => {
    setInternalValue(value)
  }, [value])

  const startAnimating = () => {
    if (value === undefined) {
      setInternalValue(0.2)
      valueAnimation.current = window.setInterval(() => {
        setInternalValue((v) => (v || 0) + 0.1)
      }, 500)
    }
    return () => {
      cleanup()
    }
  }

  const stopAnimating = () => {
    if (value === undefined && !!internalValue && internalValue >= 0.9) {
      cleanup()
      setInternalValue(undefined)
    }
  }

  const cleanup = () => {
    if (valueAnimation.current !== null) {
      window.clearInterval(valueAnimation.current)
    }
  }

  useEffect(startAnimating, [value])
  useEffect(stopAnimating, [internalValue, value])

  return <Spinner value={internalValue} />
}

export default LoadingSpinner
