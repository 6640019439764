/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { databaseSessionSetAction } from '../../actionCreators/session'

import { generateUserPath } from '../../paths/userPaths'

import { useRTDBConnect } from '../helpers/useRTDBConnect'

import { UUID } from 'canvas-shared/lib/types'
import { User } from 'canvas-shared/lib/types/user.types'

/*---------------------------------------------------------------------------*/

export const useConnectSession = (containerId: UUID, userId: UUID) => {
  useRTDBConnect<string, Partial<User>>({
    path: generateUserPath(containerId, userId),
    ignoreOverlapWarning: true,

    onInitialValue: databaseSessionSetAction,
  })
}
