/*---- External -------------------------------------------------------------*/

import React from 'react'
import { useAsync } from '@react-hook/async'

import { CheckCircle, Spinner, XCircle } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

interface CheckEmailProps {
  email: string
  handleRetry: () => Promise<void>
}

export const CheckEmail: React.FC<CheckEmailProps> = ({
  email,
  handleRetry,
}) => {
  const [{ status }, doRetry] = useAsync(handleRetry)

  return (
    <>
      <h1 className="text-text-primary-light dark:text-text-primary-dark text-2xl font-extrabold leading-tight sm:text-3xl">
        Check your email
      </h1>
      <p className="text-text-primary-light dark:text-text-primary-dark mt-3 leading-normal">
        We’ve sent a temporary sign-in link to your email
        <br />
        {email}
      </p>
      <p className="text-text-secondary-light dark:text-text-secondary-dark mb-0 mt-8 text-xs leading-snug">
        No email? Check your junk or{' '}
        <button
          className="text-button-primary-light dark:text-button-success-dark inline-flex items-center hover:underline space-x-0.5"
          onClick={doRetry}
          disabled={status === 'success'}
        >
          <span>resend email</span>
          {status === 'loading' && <Spinner />}
          {status === 'success' && <CheckCircle />}
          {status === 'error' && <XCircle />}
        </button>
      </p>
    </>
  )
}
