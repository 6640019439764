/*---- External -------------------------------------------------------------*/

import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Position, Toast, Toaster } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { toastSelector } from '../../redux/selectors/system/toast'
import { dismissToastAction } from '../../redux/actionCreators/toast'

import { ToastPiece } from '../../types/redux'

/*---------------------------------------------------------------------------*/

export const AppToaster: React.FC = () => {
  const toasts = useSelector(toastSelector)

  return (
    <Toaster
      canEscapeKeyClear={false}
      className="z-above-above-everything"
      position={Position.BOTTOM}
    >
      {toasts.map((t) => (
        <AppToast key={t.id} toast={t.toast} id={t.id} />
      ))}
    </Toaster>
  )
}

const AppToast: React.FC<ToastPiece> = ({ id, toast }) => {
  const dispatch = useDispatch()

  const handleDismiss = useCallback(() => {
    dispatch(dismissToastAction(id))
  }, [dispatch, id])

  return <Toast {...toast} onDismiss={handleDismiss} />
}
