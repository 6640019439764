/*---- External -------------------------------------------------------------*/

import React, { useEffect, useState, useCallback } from 'react'

import { useLocation, useHistory } from 'react-router-dom'

import queryString from 'query-string'

import { Button } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import FullScreenModalBackdrop from '../../components/utilities/FullScreenModalBackdrop'
import Loading from '../../components/loading/Loading'
import { GoogleAuthenticationBanner } from '../../components/connections/google/GoogleAuthentication'

import { functions } from '../../config/firebase'

import { Urls } from '../../router/routes'
import { sendSuperficialError } from '../../helpers/sendSuperficialError'

/*---------------------------------------------------------------------------*/

const generateTokens = functions.httpsCallable('https-googleGenerateTokens')

const GoogleCallback: React.FC = () => {
  const { search } = useLocation()
  const { replace } = useHistory()

  const [error, setError] = useState(false)

  const { code, scope, state } = queryString.parse(search)
  const { redirectPath } = JSON.parse(decodeURIComponent(state as string))

  const handleRedirect = useCallback(() => {
    replace(redirectPath || '/')
  }, [redirectPath, replace])

  useEffect(() => {
    const redirectOnLoad = async () => {
      try {
        if (
          code === undefined ||
          scope === undefined ||
          redirectPath === undefined
        ) {
          throw new Error('Missing URL parameters')
        }
        await generateTokens({
          callbackUrl: Urls.GOOGLE_CALLBACK,
          code,
        })
        handleRedirect()
      } catch (error) {
        setError(true)
        sendSuperficialError(error, 'Failed to authenticate with Google')
      }
    }

    redirectOnLoad()
  }, [code, scope, redirectPath, handleRedirect])

  if (error) {
    return (
      <FullScreenModalBackdrop>
        <div className="small-dialog bg-background-three-light dark:bg-background-three-dark flex flex-col items-center justify-center my-auto p-10 rounded shadow-xl space-y-6">
          <GoogleAuthenticationBanner />
          <h2 className="text-xl font-semibold">Couldn’t connect to Google</h2>
          <Button onClick={handleRedirect}>Try again</Button>
        </div>
      </FullScreenModalBackdrop>
    )
  } else {
    return <Loading />
  }
}

export default GoogleCallback
