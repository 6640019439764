/*---- External -------------------------------------------------------------*/

import React from 'react'
import { useHistory } from 'react-router'

import { Prohibit } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import { MinimalLayout } from '../../components/layouts/MinimalLayout'
import { Button } from '../../components/utilities/Button'

/*---------------------------------------------------------------------------*/

const NoAccess: React.FC = () => {
  const history = useHistory()

  return (
    <MinimalLayout>
      <Prohibit
        className="text-icon-secondary-light dark:text-icon-secondary-dark mb-4 mx-auto"
        size={80}
        weight="light"
      />
      <h1 className="text-text-primary-light dark:text-text-primary-dark text-center text-xl font-extrabold sm:text-3xl">
        No access
      </h1>
      <p className="text-text-primary-light dark:text-text-primary-dark mb-0 mt-3 text-center text-sm leading-normal">
        You don’t have permission to access this page
      </p>
      <Button
        className="flex justify-center mt-8 w-full"
        onClick={() => history.push('/')}
      >
        Go to my workspace
      </Button>
    </MinimalLayout>
  )
}

export default NoAccess
