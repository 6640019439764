/*---- External -------------------------------------------------------------*/

import { createLocalStorageStateHook } from 'use-local-storage-state'

/*---- Qualdesk -------------------------------------------------------------*/

import { WorkspaceOrder, WorkspaceSort } from '../../types/workspace'

/*---------------------------------------------------------------------------*/

export const useWorkspaceOrder = createLocalStorageStateHook<WorkspaceOrder>(
  'workspaceOrder',
  WorkspaceOrder.Updated
)

export const useWorkspaceSort = createLocalStorageStateHook<WorkspaceSort>(
  'workspaceSort',
  WorkspaceSort.Desc
)
