/*---- External -------------------------------------------------------------*/

import React from 'react'

import { Menu } from '@blueprintjs/core'

import { generatePath, useHistory } from 'react-router-dom'

/*---- Qualdesk -------------------------------------------------------------*/

import { useSearchContext } from '../../contexts/Search'

import highlightText from '../../helpers/highlightText'
import { safeString } from '../../helpers/safeString'

import { Routes } from '../../router/routes'

import LoadingSpinner from '../loading/LoadingSpinner'
import { NoResults } from '../utilities/NoResults'

import { useItems } from '../../redux/selectorHooks/board/useItems'
import { useSearchResults } from '../../redux/selectorHooks/board/useSearchResults'

import { useContainerId } from '../../contexts/Container'

import { Item } from 'canvas-shared/lib/types/Item.types'

/*---------------------------------------------------------------------------*/

export const SearchResults: React.FC = () => {
  const searchResults = useSearchResults()
  const { searchQuery } = useSearchContext()
  const items = useItems()

  if (!searchQuery) {
    return null
  }

  if (!items) {
    return (
      <div className="pb-8 pt-10">
        <LoadingSpinner />
      </div>
    )
  }

  if (searchResults.length > 0) {
    return (
      <Menu className="mt-4 p-0 overflow-y-scroll">
        {searchResults.map((result) => (
          <SearchResult item={result} key={result.id} />
        ))}
      </Menu>
    )
  }

  return (
    <NoResults
      className="pt-8 break-words"
      title={`No results for ‘${searchQuery}’`}
    />
  )
}

interface SearchResultProps {
  item: Item.BaseItem
}

const SearchResult: React.FC<SearchResultProps> = ({ item }) => {
  const { searchQuery } = useSearchContext()
  const {
    replace,
    location: { search },
  } = useHistory()
  const containerId = useContainerId()

  if (!searchQuery) {
    return null
  }

  const goToItem = (event: React.MouseEvent) => {
    event.stopPropagation()

    const canvasPath = generatePath(Routes.CANVAS.path, {
      containerId,
    })

    replace(`${canvasPath}${search}#${item.id}`)
  }

  return (
    <Menu.Item
      className="items-center text-base space-x-1"
      onClick={goToItem}
      text={highlightText(safeString(item.data.title), searchQuery)}
    />
  )
}
