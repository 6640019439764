/*---- External -------------------------------------------------------------*/

import React, { useRef } from 'react'

import classNames from 'classnames'

import { Tooltip2 } from '@blueprintjs/popover2'

/*---- Qualdesk -------------------------------------------------------------*/

import convertToPx from '../../helpers/convertToPx'

import { useUser } from '../../redux/selectorHooks/users/useUser'

import { Initials } from './Initials'
import UserName from './UserName'

/*---------------------------------------------------------------------------*/

interface UserIconProps {
  className?: string
  hideTooltip?: true
  uid: string
  style?: React.CSSProperties
  onClick?: () => void
}

const UserIcon: React.FC<UserIconProps> = (props) =>
  props.hideTooltip ? (
    <UserIconInner {...props} />
  ) : (
    <Tooltip2
      popoverClassName="tooltip"
      content={<UserName uid={props.uid} />}
      hoverOpenDelay={300}
      targetTagName="div"
    >
      <UserIconInner {...props} />
    </Tooltip2>
  )

const UserIconInner: React.FC<UserIconProps> = ({
  className,
  onClick,
  style,
  uid,
}) => {
  const user = useUser(uid)

  const userIconRef = useRef<HTMLDivElement>(null)

  const iconWidth =
    (userIconRef.current
      ? userIconRef.current.clientWidth
      : MIN_USER_ICON_DIMENSION) * 0.5
  const fontSize = convertToPx(iconWidth)

  const name = user?.name
  const color = user?.color ?? 'gray'

  const iconStyle = {
    ...style,
    minHeight: convertToPx(MIN_USER_ICON_DIMENSION),
    minWidth: convertToPx(MIN_USER_ICON_DIMENSION),
    fontSize,
  }

  const isButton = !!onClick

  return (
    <div
      className={classNames(
        { [`bg-${color}-50`]: !user?.pictureUrl },
        { [`text-${color}-700 dark:text-${color}-300`]: !user?.pictureUrl },
        'dark:ring-border-weak-dark dark:bg-transparent ring-transparent ring-1',
        'flex flex-shrink-0 items-center justify-center font-semibold rounded-full select-none overflow-hidden',
        className,
        { 'cursor-pointer': isButton }
      )}
      ref={userIconRef}
      style={iconStyle}
      onClick={onClick}
      role={classNames('listitem', { button: isButton })}
      aria-label={name}
    >
      {user?.pictureUrl ? (
        <img src={user?.pictureUrl} alt={name} />
      ) : (
        <Initials name={name} iconWidth={iconWidth} />
      )}
    </div>
  )
}

export const MIN_USER_ICON_DIMENSION = 16

export default UserIcon
