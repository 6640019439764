/*---- External -------------------------------------------------------------*/

import React, { createContext } from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import ensureContextValue from '../helpers/ensureContextValue'

import { UUID } from '../types'

/*---------------------------------------------------------------------------*/

interface ContainerIdProviderProps {
  containerId: UUID
}

export const ContainerIdProvider: React.FC<ContainerIdProviderProps> = ({
  children,
  containerId,
}) => {
  const value = containerId

  return (
    <ContainerIdContext.Provider value={value}>
      {children}
    </ContainerIdContext.Provider>
  )
}

const ContainerIdContext = createContext<Partial<UUID>>('')
export const useContainerId = ensureContextValue<UUID>(
  ContainerIdContext,
  'useContainerId'
)
