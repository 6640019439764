import { IMenuItemProps, Menu } from '@blueprintjs/core'
import orderBy from 'lodash.orderby'

import { Item } from 'canvas-shared/lib/types/Item.types'
import { LinearLabel } from 'canvas-shared/lib/types/connections/Linear.types'
import { UUID } from '../../../../types'

interface ChangeLinearLabelsProps {
  cards: Item.LinearCard[]
  linearAddLabel: (ids: UUID[], label: LinearLabel) => Promise<void>
  linearRemoveLabel: (ids: UUID[], label: LinearLabel) => Promise<void>
}

export const changeLinearLabels = ({
  cards,
  linearAddLabel,
  linearRemoveLabel,
}: ChangeLinearLabelsProps): IMenuItemProps | null => {
  const uniqLabels = (label: LinearLabel, idx: number, list: LinearLabel[]) =>
    list.findIndex((l) => l.id === label.id) === idx

  const labels = cards
    .flatMap((c) => c.data?.linear?.team?.labels?.nodes ?? [])
    .filter(uniqLabels)

  if (labels.length === 0) {
    return null
  }

  const addOrRemoveLabel = async (label: LinearLabel) => {
    const cardsWithoutLabel = cards.filter((c) => {
      const labels = c.data.linear.labels?.nodes?.filter(
        (l: LinearLabel) => l.id === label.id
      )
      return labels === undefined || labels.length === 0
    })

    if (cardsWithoutLabel.length > 0) {
      linearAddLabel(
        cards.map((c) => c.id),
        label
      )
    } else {
      linearRemoveLabel(
        cards.map((c) => c.id),
        label
      )
    }
  }

  return {
    text: 'Labels',
    icon: 'tag',
    children: orderBy(labels, ['name']).map((l: LinearLabel) => (
      <Menu.Item
        key={l.name}
        text={l.name}
        onClick={() => addOrRemoveLabel(l)}
      />
    )),
  }
}
