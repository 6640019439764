/*---- External -------------------------------------------------------------*/

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { presenceUserIdsSelector } from '../../selectors/board/users'

/*---------------------------------------------------------------------------*/

export const usePresenceUserIds = () => useSelector(presenceUserIdsSelector)
