"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.connectionRequiresManualSync = exports.boardConnectionIsLinear = exports.boardConnectionIsGoogleSheets = exports.boardConnectionIsGoogle = exports.boardConnectionIsJira = exports.boardConnectionIsTrello = void 0;
const BoardConnection_types_1 = require("./BoardConnection.types");
const file_types_1 = require("./file.types");
const boardConnectionIsTrello = (conn) => (conn === null || conn === void 0 ? void 0 : conn.type) === BoardConnection_types_1.BoardConnectionType.Trello;
exports.boardConnectionIsTrello = boardConnectionIsTrello;
const boardConnectionIsJira = (conn) => (conn === null || conn === void 0 ? void 0 : conn.type) === BoardConnection_types_1.BoardConnectionType.Jira;
exports.boardConnectionIsJira = boardConnectionIsJira;
const boardConnectionIsGoogle = (conn) => (conn === null || conn === void 0 ? void 0 : conn.type) === BoardConnection_types_1.BoardConnectionType.Google;
exports.boardConnectionIsGoogle = boardConnectionIsGoogle;
const boardConnectionIsGoogleSheets = (conn) => (conn === null || conn === void 0 ? void 0 : conn.type) === BoardConnection_types_1.BoardConnectionType.Google &&
    conn.google.file.mimeType ===
        file_types_1.MimeTypes.GOOGLE_SHEETS;
exports.boardConnectionIsGoogleSheets = boardConnectionIsGoogleSheets;
const boardConnectionIsLinear = (conn) => (conn === null || conn === void 0 ? void 0 : conn.type) === BoardConnection_types_1.BoardConnectionType.Linear;
exports.boardConnectionIsLinear = boardConnectionIsLinear;
const connectionRequiresManualSync = (connection) => connection.type === BoardConnection_types_1.BoardConnectionType.Google ||
    connection.type === BoardConnection_types_1.BoardConnectionType.Jira;
exports.connectionRequiresManualSync = connectionRequiresManualSync;
