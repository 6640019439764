/*---- External -------------------------------------------------------------*/

import React, { createContext, useState } from 'react'

import { Alert, Intent } from '@blueprintjs/core'

import type { IAlertProps, IconName } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import ensureContextValue from '../helpers/ensureContextValue'
import { useClassNamesWithDarkMode } from '../hooks/darkMode/useClassNamesWithDarkMode'

/*---------------------------------------------------------------------------*/

type AlertMessage = React.ReactNode | string

interface AreYouSure {
  alertProps: Partial<IAlertProps>
  message: AlertMessage
  showAlert: (
    callback: () => void,
    message: AlertMessage,
    alertProps?: Partial<IAlertProps>
  ) => void
}

export const AreYouSureProvider: React.FC = ({ children }) => {
  const defaultAlertProps: Partial<IAlertProps> = {
    cancelButtonText: 'Cancel',
    icon: 'warning-sign' as IconName,
    intent: Intent.WARNING,
    isOpen: false,
    canEscapeKeyCancel: true,
    onCancel: () => null,
  }
  const defaultMessage: AlertMessage = 'Are you sure?'

  const [alertProps, setAlertProps] = useState<Partial<IAlertProps>>(
    defaultAlertProps
  )
  const [message, setMessage] = useState<AlertMessage>(defaultMessage)

  const showAlert = (
    callback: () => void,
    message: AlertMessage,
    alertProps?: Partial<IAlertProps>
  ) => {
    setMessage(message ? message : defaultMessage)

    setAlertProps({
      ...defaultAlertProps,
      ...alertProps,
      isOpen: true,
      onCancel,
      onConfirm: () => handleConfirm(callback),
    })
  }

  const handleConfirm = (callback: () => void) => {
    setAlertProps({
      isOpen: false,
    })

    Promise.all([callback ? callback() : true]).then(() => {
      setAlertProps({
        isOpen: false,
      })
    })

    return null
  }

  const onCancel = () => {
    setAlertProps({
      isOpen: false,
    })

    return null
  }

  const provider = {
    alertProps,
    message,
    showAlert,
  }

  return (
    <AreYouSureContext.Provider value={provider}>
      <AreYouSureAlert />
      {children}
    </AreYouSureContext.Provider>
  )
}

const AreYouSureAlert = () => {
  const { alertProps, message } = useAreYouSureContext()
  const { classNamesWithDarkMode } = useClassNamesWithDarkMode()

  return (
    <Alert {...alertProps} className={classNamesWithDarkMode()}>
      <p className="mb-2 font-bold text-lg">{alertProps.confirmButtonText}</p>
      <p className="mb-2">{message}</p>
    </Alert>
  )
}

const AreYouSureContext = createContext<Partial<AreYouSure>>({})
export const useAreYouSureContext = ensureContextValue<AreYouSure>(
  AreYouSureContext,
  'useAreYouSureContext'
)
