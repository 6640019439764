/*---- External -------------------------------------------------------------*/

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { shouldSelectSelector } from '../../selectors/board/shouldSelect'

/*---------------------------------------------------------------------------*/

export const useShouldSelect = () => useSelector(shouldSelectSelector)
