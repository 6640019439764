/*---- External -------------------------------------------------------------*/

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CaretDown, CirclesThreePlus, Trash } from 'phosphor-react'

import { Button, Menu, Tooltip } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'

/*---- Qualdesk -------------------------------------------------------------*/

import { useAreYouSureContext } from '../../../contexts/AreYouSure'

import {
  itemsClearAllEmojisAction,
  itemsClearAllEstimateVotesAction,
  itemsClearAllVotesAction,
} from '../../../redux/actionCreators/itemData'
import { useInteractionModeActions } from '../../../redux/actions/board/useInteractionModeActions'

import { selectInteractionMode } from '../../../redux/selectors/board/interactionModes'
import { useItemsHaveReactions } from '../../../redux/selectorHooks/board/useItems'

import { TooltipContent } from './TooltipContent'

import { InteractionMode } from '../../../types'

/*---------------------------------------------------------------------------*/

type SpecialInteractionMode =
  | InteractionMode.VOTING
  | InteractionMode.ESTIMATION
  | InteractionMode.ESTIMATE_RESOLUTION

interface SpecialInteractionModesConfig {
  text: string
  stopText: string
}

const specialInteractionModesConfig: Record<
  SpecialInteractionMode,
  Readonly<SpecialInteractionModesConfig>
> = {
  [InteractionMode.VOTING]: {
    text: 'Vote…',
    stopText: 'Stop voting',
  },
  [InteractionMode.ESTIMATION]: {
    text: 'Estimate…',
    stopText: 'Stop estimating',
  },
  [InteractionMode.ESTIMATE_RESOLUTION]: {
    text: 'Resolve estimates…',
    stopText: 'Stop resolving estimates',
  },
}

export const ReactionTools: React.FC = () => {
  const dispatch = useDispatch()
  const interactionMode = useSelector(selectInteractionMode)
  const {
    setInteractionMode,
    resetInteractionMode,
  } = useInteractionModeActions()
  const {
    itemsHaveVotes,
    itemsHaveEstimateVotes,
    itemsHaveEmojis,
  } = useItemsHaveReactions()

  const { showAlert } = useAreYouSureContext()

  const clearAllEstimateVotes = () => {
    showAlert(
      () => {
        dispatch(itemsClearAllEstimateVotesAction())
      },
      'Are you sure you want to clear all estimates?',
      { confirmButtonText: 'Clear all estimates' }
    )
  }

  const clearAllEmojis = () => {
    showAlert(
      () => {
        dispatch(itemsClearAllEmojisAction())
      },
      'Are you sure you want to clear all emojis?',
      { confirmButtonText: 'Clear all emojis' }
    )
  }

  const clearAllVotes = () => {
    showAlert(
      () => {
        dispatch(itemsClearAllVotesAction())
      },
      'Are you sure you want to clear all votes?',
      { confirmButtonText: 'Clear all votes' }
    )
  }

  return (
    <Popover2
      minimal
      placement="top-start"
      autoFocus={false}
      content={
        <Menu>
          {Object.entries(specialInteractionModesConfig).map(
            ([mode, { text, stopText }], i) => (
              <React.Fragment key={mode}>
                <Menu.Item
                  text={interactionMode === mode ? stopText : text}
                  onClick={() => {
                    if (interactionMode === mode) {
                      resetInteractionMode()
                    } else {
                      setInteractionMode(mode as InteractionMode)
                    }
                  }}
                />
                {i === 0 && <Menu.Divider />}
              </React.Fragment>
            )
          )}
          <Menu.Divider />
          <Menu.Item text="Clear all…" icon={<Trash size={20} />}>
            <Menu.Item
              disabled={!itemsHaveVotes}
              text="Clear all votes"
              onClick={clearAllVotes}
            />
            <Menu.Item
              disabled={!itemsHaveEstimateVotes}
              text="Clear all estimates"
              onClick={clearAllEstimateVotes}
            />
            <Menu.Item
              disabled={!itemsHaveEmojis}
              text="Clear all emojis"
              onClick={clearAllEmojis}
            />
          </Menu.Item>
        </Menu>
      }
    >
      <Tooltip
        popoverClassName="tooltip"
        content={<TooltipContent title="Evaluation tools" />}
        hoverOpenDelay={300}
      >
        <Button
          className="popover-button px-2"
          icon={<CirclesThreePlus size={24} className="toolbar-icon mr-0.5" />}
          rightIcon={
            <CaretDown
              weight="bold"
              className="popover-button-caret text-icon-secondary-light dark:text-icon-secondary-dark"
            />
          }
          minimal
          aria-label="Evaluation tools"
        />
      </Tooltip>
    </Popover2>
  )
}
