/*---- External -------------------------------------------------------------*/

import { createAction } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

export const zoomSetAction = createAction<number>('zoom/set')
export const zoomInAction = createAction('zoom/in')
export const zoomOutAction = createAction('zoom/out')
export const zoomToDefaultAction = createAction('zoom/toDefault')
