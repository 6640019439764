/*---- External -------------------------------------------------------------*/

import { createLocalStorageStateHook } from 'use-local-storage-state'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

export const useLinearRedirectPath = createLocalStorageStateHook<
  string | undefined
>('linearRedirectPath')
