/*---- External -------------------------------------------------------------*/

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { generatePath, NavLink } from 'react-router-dom'

/*---- Qualdesk -------------------------------------------------------------*/

import { useBoard } from '../../redux/selectorHooks/board/useBoard'
import { createTeamSelector } from '../../redux/selectors/auth/teams'
import { Routes } from '../../router/routes'

/*---------------------------------------------------------------------------*/

export const BoardTeam: React.FC = () => {
  const {
    roles: { teams },
  } = useBoard()

  if (!teams) {
    return null
  }

  return <BoardTeamInner teamId={teams.membership} />
}

interface BoardTeamInnerProps {
  teamId: string
}

const BoardTeamInner: React.FC<BoardTeamInnerProps> = ({ teamId }) => {
  const teamSelector = createTeamSelector(teamId)
  const team = useSelector(teamSelector)

  const teamPath = useMemo(
    () => generatePath(Routes.WORKSPACE_TEAM.path, { teamId }),
    [teamId]
  )

  if (!team) {
    return null
  }

  return (
    <>
      <NavLink
        className="text-text-disabled-light dark:text-text-disabled-dark hover:text-text-secondary-light dark:hover:text-text-secondary-dark flex-shrink hover:no-underline whitespace-nowrap text-lg overflow-hidden overflow-ellipsis"
        to={teamPath}
      >
        {team.name}
      </NavLink>
      <span className="text-text-disabled-light dark:text-text-disabled-dark flex-shrink-0 -mr-0.5 ml-1.5 text-lg">
        /
      </span>
    </>
  )
}
