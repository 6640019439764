/*---- External -------------------------------------------------------------*/

import React, { useMemo, useState } from 'react'

import { Button, Classes } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { useClassNamesWithDarkMode } from '../../hooks/darkMode/useClassNamesWithDarkMode'
import { useCanvasWindowContext } from '../../contexts/CanvasWindow'

/*---------------------------------------------------------------------------*/

export interface CustomSidebarPopoverButtonProps {
  active?: boolean
  onClick: (e: React.MouseEvent<HTMLElement>) => void
}

interface SidebarPopoverProps {
  fill?: boolean
  buttonText?: string
  content: JSX.Element
  customButton?: React.FC<CustomSidebarPopoverButtonProps>
}

export const SidebarPopover: React.FC<SidebarPopoverProps> = ({
  fill,
  customButton: ButtonElement,
  buttonText,
  content,
}) => {
  const { canvasRef } = useCanvasWindowContext()
  const [overlayIsOpen, setOverlayIsOpen] = useState(false)

  const { classNamesWithDarkMode } = useClassNamesWithDarkMode()

  const handleClose = () => {
    if (overlayIsOpen) {
      setOverlayIsOpen(false)
    }
  }

  const handleButton = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    e.preventDefault()
    setOverlayIsOpen((o) => !o)
  }

  const popoverModifiers = useMemo(() => {
    if (!canvasRef.current) {
      return undefined
    }

    return {
      flip: {
        enabled: true,
      },
      preventOverflow: {
        options: {
          altAxis: true,
          boundary: canvasRef.current,
          padding: { left: 16, top: 68, right: -4, bottom: 16 },
          tether: false,
        },
      },
    }
  }, [canvasRef])

  const popoverContent = (
    <div
      className={classNames(
        Classes.DIALOG,
        'bg-background-five-light dark:bg-background-five-dark m-0 p-0 w-80 rounded overflow-hidden'
      )}
    >
      {content}
    </div>
  )

  return (
    <>
      <Popover2
        content={popoverContent}
        isOpen={overlayIsOpen}
        fill={fill}
        minimal
        modifiers={popoverModifiers}
        onClose={handleClose}
        popoverClassName={classNamesWithDarkMode('shadow-none')}
        placement="left-start"
      >
        {!!ButtonElement ? (
          <ButtonElement active={overlayIsOpen} onClick={handleButton} />
        ) : (
          <Button
            active={overlayIsOpen}
            className={classNames(
              'border-border-weak-light dark:border-border-weak-dark active:bg-background-two-light dark:active:bg-background-two-dark px-4 py-1',
              {
                'hover:bg-background-three-light dark:hover:bg-background-three-dark': !overlayIsOpen,
                'bg-background-two-light dark:bg-background-two-dark': overlayIsOpen,
              }
            )}
            fill={fill}
            onClick={handleButton}
            outlined
            text={buttonText}
          />
        )}
      </Popover2>
    </>
  )
}
