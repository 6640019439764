/*---- External -------------------------------------------------------------*/

import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit'

import clamp from 'lodash.clamp'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  DEFAULT_SCROLL_DIMENSIONS,
  DEFAULT_SCROLL_OFFSETS,
  DEFAULT_ZOOM_LEVEL,
  MAX_ZOOM_LEVEL,
  MIN_ZOOM_LEVEL,
  DEFAULT_CURSOR_POSITION,
} from '../../../../config/canvas'
import { NO_SELECTION, NO_EDITS } from '../../../selectors/config/defaults'
import {
  databaseSessionSetAction,
  resetSessionAction,
  stopAllGesturesAction,
} from '../../../actionCreators/session'
import { AutoScrollStatus, SessionStateSlice } from '../../../../types/redux'
import {
  zoomInAction,
  zoomOutAction,
  zoomSetAction,
  zoomToDefaultAction,
} from '../../../actionCreators/zoom'
import {
  selectionAddAction,
  selectionClearAction,
  selectionRemoveAction,
  selectionSelectAllAction,
  selectionSetWithRectAction,
  selectionExtendWithRectAction,
  selectionSetAction,
  selectionToggleAction,
} from '../../../actionCreators/selection'
import {
  editRemoveAction,
  editSetOneAction,
  editUpdateOneAction,
} from '../../../actionCreators/edits'
import {
  endScrollGestureAction,
  scrollAndZoomAction,
  scrollAndZoomByAction,
  scrollByAction,
  scrollDimensionsSetAllAction,
  scrollOrZoomChangedAction,
  scrollToAction,
  scrollToCenterOnAction,
  scrollToCenterOnItemIdAction,
  startScrollGestureAction,
} from '../../../actionCreators/scroll'
import { focusSetAction } from '../../../actionCreators/focus'
import {
  pointerDownAction,
  pointerMoveAction,
  pointerUpAction,
} from '../../../actionCreators/pointers'

import { zoomInLevel, zoomOutLevel } from '../../../../helpers/zoom'
import { convertItemIdsToSelectionRecord } from '../../../actionCreators/helpers/convertItemIdsToSelectionRecord'

import {
  CursorPosition,
  ScrollDimensions,
  ScrollOffsets,
} from 'canvas-shared/lib/types/scrollAndPosition.types'
import { CursorMode } from 'canvas-shared/lib/types/CursorMode.types'
import { GestureMode } from 'canvas-shared/lib/types/pointerEvents.types'
import {
  startAutoScrollAction,
  stopAutoScrollAction,
  tickAutoScrollAction,
  tryAutoScrollAction,
} from '../../../actionCreators/autoScroll'
import { altKeyAction } from '../../../actionCreators/keys'

import {
  AUTOSCROLL_BUMPER_SIZE,
  AUTOSCROLL_SPEED_MULTIPLIER,
} from '../../../../config/autoScroll'

/*---------------------------------------------------------------------------*/

const initialState: SessionStateSlice = {
  loaded: false,
  data: {
    isFocused: true,
    isTouchDevice: true,
    scrollDimensions: DEFAULT_SCROLL_DIMENSIONS,
    scrollOffsets: DEFAULT_SCROLL_OFFSETS,
    zoomLevel: DEFAULT_ZOOM_LEVEL,
    selection: NO_SELECTION,
    edits: NO_EDITS,
    position: DEFAULT_CURSOR_POSITION,
  },
  localData: {
    isScrollingWithGesture: false,
    gestures: {},
    autoScroll: { status: AutoScrollStatus.STOPPED },
  },
}

const extraReducers = (builder: ActionReducerMapBuilder<SessionStateSlice>) => {
  builder
    .addCase(resetSessionAction, () => {
      return initialState
    })

    // Database actions ============
    .addCase(databaseSessionSetAction, (state, action) => {
      state.loaded = true

      if (!!action.payload) {
        state.data = {
          ...state.data,
          ...action.payload,
        }
      }
    })

    // Zoom actions ============
    .addCase(zoomSetAction, (state, action) => {
      const newZoomLevel = clamp(action.payload, MIN_ZOOM_LEVEL, MAX_ZOOM_LEVEL)
      state.data.scrollOffsets = scrollOffsetsForNewZoomLevel(
        state.data.scrollOffsets,
        state.data.scrollDimensions,
        state.data.zoomLevel,
        newZoomLevel
      )
      state.data.zoomLevel = newZoomLevel
    })
    .addCase(zoomInAction, (state) => {
      const newZoomLevel = clamp(
        zoomInLevel(state.data.zoomLevel),
        MIN_ZOOM_LEVEL,
        MAX_ZOOM_LEVEL
      )
      state.data.scrollOffsets = scrollOffsetsForNewZoomLevel(
        state.data.scrollOffsets,
        state.data.scrollDimensions,
        state.data.zoomLevel,
        newZoomLevel
      )
      state.data.zoomLevel = newZoomLevel
    })
    .addCase(zoomOutAction, (state) => {
      const newZoomLevel = clamp(
        zoomOutLevel(state.data.zoomLevel),
        MIN_ZOOM_LEVEL,
        MAX_ZOOM_LEVEL
      )
      state.data.scrollOffsets = scrollOffsetsForNewZoomLevel(
        state.data.scrollOffsets,
        state.data.scrollDimensions,
        state.data.zoomLevel,
        newZoomLevel
      )
      state.data.zoomLevel = newZoomLevel
    })
    .addCase(zoomToDefaultAction, (state) => {
      const newZoomLevel = DEFAULT_ZOOM_LEVEL
      state.data.scrollOffsets = scrollOffsetsForNewZoomLevel(
        state.data.scrollOffsets,
        state.data.scrollDimensions,
        state.data.zoomLevel,
        newZoomLevel
      )
      state.data.zoomLevel = newZoomLevel
    })

    // Selection actions ============
    .addCase(selectionAddAction, (state, action) => {
      const id = action.payload
      state.data.selection = {
        ...(state.data.selection || {}),
        [id]: true,
      }
    })
    .addCase(selectionRemoveAction, (state, action) => {
      const id = action.payload
      delete (state.data.selection || {})[id]
    })
    .addCase(selectionClearAction, (state, _) => {
      state.data.selection = {}
    })
    .addCase(selectionSetAction, (state, action) => {
      state.data.selection = convertItemIdsToSelectionRecord(action.payload)
    })
    .addCase(selectionSetWithRectAction.fulfilled, (state, action) => {
      state.data.selection = action.payload
    })
    .addCase(selectionExtendWithRectAction.fulfilled, (state, action) => {
      state.data.selection = action.payload
    })
    .addCase(selectionSelectAllAction.fulfilled, (state, action) => {
      state.data.selection = action.payload
    })
    .addCase(selectionToggleAction.fulfilled, (state, action) => {
      state.data.selection = action.payload
    })

    // Edit actions ============
    .addCase(editSetOneAction, (state, action) => {
      const id = action.payload.key

      state.data.edits = {
        [id]: action.payload.value,
      }
    })

    .addCase(editRemoveAction, (state, action) => {
      const id = action.payload

      delete (state.data.edits || {})[id]
    })

    .addCase(editUpdateOneAction, (state, action) => {
      const id = action.payload.key
      const value = action.payload.value

      if (state.data.edits?.[id]) {
        state.data.edits = {
          ...state.data.edits,
          [id]: value,
        }
      }
    })

    // Scroll actions ============
    .addCase(scrollDimensionsSetAllAction.fulfilled, (state, action) => {
      state.data.scrollDimensions = action.payload
    })
    .addCase(scrollToAction, (state, action) => {
      state.data.scrollOffsets = applyScrollOffsetsBoundary(
        action.payload,
        state.data.scrollDimensions,
        state.data.zoomLevel
      )
    })
    .addCase(scrollAndZoomAction.fulfilled, (state, action) => {
      state.data.scrollOffsets = applyScrollOffsetsBoundary(
        action.payload.scrollOffsets,
        state.data.scrollDimensions,
        state.data.zoomLevel
      )
      state.data.zoomLevel = action.payload.zoomLevel
    })
    .addCase(scrollAndZoomByAction.fulfilled, (state, action) => {
      state.data.scrollOffsets = applyScrollOffsetsBoundary(
        action.payload.scrollOffsets,
        state.data.scrollDimensions,
        state.data.zoomLevel
      )
      state.data.zoomLevel = action.payload.zoomLevel
    })
    .addCase(scrollToCenterOnAction.fulfilled, (state, action) => {
      state.data.scrollOffsets = applyScrollOffsetsBoundary(
        action.payload,
        state.data.scrollDimensions,
        state.data.zoomLevel
      )
    })
    .addCase(scrollToCenterOnItemIdAction.fulfilled, (state, action) => {
      state.data.scrollOffsets = applyScrollOffsetsBoundary(
        action.payload,
        state.data.scrollDimensions,
        state.data.zoomLevel
      )
    })
    .addCase(scrollByAction.fulfilled, (state, action) => {
      state.data.scrollOffsets = applyScrollOffsetsBoundary(
        action.payload,
        state.data.scrollDimensions,
        state.data.zoomLevel
      )
    })

    // Focus actions ============
    .addCase(focusSetAction, (state, action) => {
      state.data.isFocused = action.payload
    })

    // Pointer actions ==========
    .addCase(pointerMoveAction.fulfilled, (state, action) => {
      const { zoomLevel, scrollOffsets, scrollDimensions } = state.data
      const {
        pointer: { shiftKey, pointerId, clientX, clientY },
      } = action.payload
      const gesture = state.localData.gestures[pointerId]

      const cursor = calculateCursor(
        scrollOffsets,
        scrollDimensions,
        zoomLevel,
        clientX,
        clientY
      )
      state.data.position = [cursor.canvasX, cursor.canvasY]
      state.localData.cursor = cursor

      // if we're in the middle of a gesture
      if (!!gesture && !gesture.finished) {
        let canvasX = cursor.canvasX
        let canvasY = cursor.canvasY

        switch (gesture.mode) {
          case GestureMode.DRAW:
          case GestureMode.RESIZE:
            canvasX = clamp(canvasX, 0, state.data.scrollDimensions.scrollWidth)
            canvasY = clamp(
              canvasY,
              0,
              state.data.scrollDimensions.scrollHeight
            )
            break
        }

        gesture.endCoords = {
          canvasX,
          canvasY,
        }
        gesture.shiftKey = shiftKey

        if (gesture.mode === GestureMode.DRAG_SELECT) {
          state.localData.selectionRect = {
            startX: gesture.startCoords.canvasX,
            startY: gesture.startCoords.canvasY,
            endX: gesture.endCoords.canvasX,
            endY: gesture.endCoords.canvasY,
          }
        }
      }
    })

    .addCase(scrollOrZoomChangedAction.fulfilled, (state, action) => {
      if (!state.localData.cursor) return
      const { zoomLevel, scrollOffsets, scrollDimensions } = state.data
      const { clientX, clientY } = state.localData.cursor
      const { gesture } = action.payload

      const cursor = calculateCursor(
        scrollOffsets,
        scrollDimensions,
        zoomLevel,
        clientX,
        clientY
      )
      state.data.position = [cursor.canvasX, cursor.canvasY]
      state.localData.cursor = cursor

      if (gesture) {
        const activeGesture = state.localData.gestures[gesture.pointerId]

        if (activeGesture) {
          let canvasX = cursor.canvasX
          let canvasY = cursor.canvasY

          switch (gesture.mode) {
            case GestureMode.DRAW:
            case GestureMode.RESIZE:
              canvasX = clamp(
                canvasX,
                0,
                state.data.scrollDimensions.scrollWidth
              )
              canvasY = clamp(
                canvasY,
                0,
                state.data.scrollDimensions.scrollHeight
              )
              break
          }

          activeGesture.endCoords = {
            canvasX,
            canvasY,
          }

          if (activeGesture.mode === GestureMode.DRAG_SELECT) {
            state.localData.selectionRect = {
              startX: activeGesture.startCoords.canvasX,
              startY: activeGesture.startCoords.canvasY,
              endX: activeGesture.endCoords.canvasX,
              endY: activeGesture.endCoords.canvasY,
            }
          }
        }
      }
    })

    .addCase(pointerDownAction.fulfilled, (state, action) => {
      const { scrollOffsets, scrollDimensions, zoomLevel } = state.data
      const {
        altKey,
        shiftKey,
        pointerId,
        clientX,
        clientY,
      } = action.payload.pointer
      const {
        cursorMode,
        uniqueId,
        selectedItemPositions,

        intersections: { dragHandle, position, group },
      } = action.payload

      const { canvasX, canvasY } = calculateCursor(
        scrollOffsets,
        scrollDimensions,
        zoomLevel,
        clientX,
        clientY
      )

      const gesture = {
        uniqueId,
        pointerId,
        startCoords: { canvasX, canvasY },
        endCoords: { canvasX, canvasY },
        finished: false,
        altKey,
        shiftKey,
        dragHandle,
        selectedItemPositions,
        cursorMode,
        mode: GestureMode.DEFAULT,
      }

      if (cursorMode === CursorMode.SELECT) {
        if (!!dragHandle) {
          gesture.mode = GestureMode.RESIZE
        } else if (!!position || !!group) {
          if (altKey) {
            gesture.mode = GestureMode.DUPLICATE
          } else {
            gesture.mode = GestureMode.MOVE
          }
        } else if (
          !state.data.edits ||
          Object.keys(state.data.edits).length === 0
        ) {
          gesture.mode = GestureMode.DRAG_SELECT
        }
      } else {
        gesture.mode = GestureMode.DRAW
      }

      state.localData.gestures[pointerId] = gesture
    })

    .addCase(pointerUpAction.fulfilled, (state, action) => {
      const { pointerId } = action.payload.pointer
      if (!!state.localData.gestures[pointerId]) {
        state.localData.gestures[pointerId].finished = true
      }
      if (!!state.localData.selectionRect) {
        delete state.localData.selectionRect
      }
    })

    .addCase(stopAllGesturesAction, (state, action) => {
      const gestureIds: number[] = Object.keys(
        state.localData.gestures
      ).map((i) => parseInt(i))

      gestureIds.forEach((id) => {
        state.localData.gestures[id].finished = true
      })

      if (!!state.localData.selectionRect) {
        delete state.localData.selectionRect
      }
    })

    .addCase(startScrollGestureAction, (state) => {
      state.localData.isScrollingWithGesture = true
    })
    .addCase(endScrollGestureAction, (state) => {
      state.localData.isScrollingWithGesture = false
    })

    .addCase(tryAutoScrollAction, (state) => {
      if (
        !state.localData.cursor ||
        state.localData.autoScroll.status !== AutoScrollStatus.STOPPED
      )
        return

      const {
        clientHeight,
        clientWidth,
        canvasLeft,
        canvasTop,
      } = state.data.scrollDimensions
      const { clientX, clientY } = state.localData.cursor

      const x = clientX - canvasLeft
      const y = clientY - canvasTop

      const shouldAutoScroll =
        !(
          x > AUTOSCROLL_BUMPER_SIZE && x < clientWidth - AUTOSCROLL_BUMPER_SIZE
        ) ||
        !(
          y > AUTOSCROLL_BUMPER_SIZE &&
          y < clientHeight - AUTOSCROLL_BUMPER_SIZE
        )

      if (shouldAutoScroll) {
        state.localData.autoScroll.status = AutoScrollStatus.SHOULD_START
      }
    })

    .addCase(startAutoScrollAction, (state) => {
      if (state.localData.autoScroll.status === AutoScrollStatus.SHOULD_START) {
        state.localData.autoScroll.status = AutoScrollStatus.STARTED
      }
    })
    .addCase(stopAutoScrollAction, (state) => {
      state.localData.autoScroll.status = AutoScrollStatus.STOPPED
    })
    .addCase(tickAutoScrollAction, (state) => {
      if (
        state.localData.autoScroll.status !== AutoScrollStatus.STARTED ||
        !state.localData.cursor
      )
        return

      const scrollOffsets = state.data.scrollOffsets
      const scrollDimensions = state.data.scrollDimensions
      const zoomLevel = state.data.zoomLevel

      const { scrollLeft, scrollTop } = scrollOffsets
      const {
        clientHeight,
        clientWidth,
        canvasLeft,
        canvasTop,
        scrollHeight,
        scrollWidth,
      } = scrollDimensions
      const { clientX, clientY } = state.localData.cursor

      const x = clientX - canvasLeft
      const y = clientY - canvasTop

      const canScrollUp = scrollTop > 0
      const canScrollDown = scrollTop < scrollHeight - clientHeight
      const canScrollLeft = scrollLeft > 0
      const canScrollRight = scrollLeft < scrollWidth - clientWidth

      let deltaX = 0
      let deltaY = 0

      if (y < AUTOSCROLL_BUMPER_SIZE && canScrollUp) {
        deltaY = (AUTOSCROLL_BUMPER_SIZE - y) * AUTOSCROLL_SPEED_MULTIPLIER * -1
      } else if (clientHeight - y < AUTOSCROLL_BUMPER_SIZE && canScrollDown) {
        deltaY =
          (AUTOSCROLL_BUMPER_SIZE - (clientHeight - y)) *
          AUTOSCROLL_SPEED_MULTIPLIER
      }

      if (clientWidth - x < AUTOSCROLL_BUMPER_SIZE && canScrollRight) {
        deltaX =
          (AUTOSCROLL_BUMPER_SIZE - (clientWidth - x)) *
          AUTOSCROLL_SPEED_MULTIPLIER
      } else if (x < AUTOSCROLL_BUMPER_SIZE && canScrollLeft) {
        deltaX = (AUTOSCROLL_BUMPER_SIZE - x) * AUTOSCROLL_SPEED_MULTIPLIER * -1
      }

      if (deltaX !== 0 || deltaY !== 0) {
        state.data.scrollOffsets.scrollLeft += deltaX
        state.data.scrollOffsets.scrollTop += deltaY
        const cursor = calculateCursor(
          scrollOffsets,
          scrollDimensions,
          zoomLevel,
          clientX,
          clientY
        )
        state.data.position = [cursor.canvasX, cursor.canvasY]
        state.localData.cursor = cursor
      } else {
        state.localData.autoScroll.status = AutoScrollStatus.STOPPED
      }
    })
    .addCase(altKeyAction, (state, action) => {
      Object.values(state.localData.gestures).forEach((g) => {
        if (!g.finished) {
          const altKeyDown = action.payload
          g.altKey = altKeyDown

          if (altKeyDown && g.mode === GestureMode.MOVE) {
            g.mode = GestureMode.DUPLICATE
          } else if (!altKeyDown && g.mode === GestureMode.DUPLICATE) {
            g.mode = GestureMode.MOVE
          }
        }
      })
    })
}

const calculateCursor = (
  scrollOffsets: ScrollOffsets | undefined,
  scrollDimensions: ScrollDimensions | undefined,
  zoomLevel: number | undefined,
  clientX: number | undefined,
  clientY: number | undefined
): CursorPosition => {
  if (
    !scrollOffsets ||
    !scrollDimensions ||
    zoomLevel === undefined ||
    clientX === undefined ||
    clientY === undefined
  ) {
    return { clientX: 0, clientY: 0, canvasX: 0, canvasY: 0 }
  }

  const { scrollLeft, scrollTop } = scrollOffsets
  const { offsetLeft, offsetTop } = scrollDimensions
  const canvasX = Math.floor((clientX - offsetLeft + scrollLeft) / zoomLevel)
  const canvasY = Math.floor((clientY - offsetTop + scrollTop) / zoomLevel)

  return {
    clientX,
    clientY,
    canvasX,
    canvasY,
  }
}

const applyScrollOffsetsBoundary = (
  scrollOffsets: ScrollOffsets,
  {
    clientHeight,
    clientWidth,
    scrollHeight,
    scrollWidth,
    offsetLeft,
  }: ScrollDimensions,
  zoomLevel: number
): ScrollOffsets => {
  let { scrollLeft, scrollTop } = scrollOffsets

  scrollLeft = clamp(
    scrollLeft,
    -clientWidth + offsetLeft * 2,
    scrollWidth * zoomLevel - clientWidth + offsetLeft * 2 + clientWidth
  )
  scrollTop = clamp(
    scrollTop,
    -clientHeight + offsetLeft * 2,
    scrollHeight * zoomLevel - clientHeight + offsetLeft * 2 + clientHeight
  )

  return { scrollLeft, scrollTop }
}

const scrollOffsetsForNewZoomLevel = (
  { scrollLeft, scrollTop }: ScrollOffsets,
  scrollDimensions: ScrollDimensions,
  zoomLevel: number,
  newZoomLevel: number
) => {
  const { clientHeight, clientWidth } = scrollDimensions
  const midpointLeft = scrollLeft + clientWidth / 2
  const midpointTop = scrollTop + clientHeight / 2

  const desiredMidpointLeft = (midpointLeft * newZoomLevel) / zoomLevel
  const desiredMidpointTop = (midpointTop * newZoomLevel) / zoomLevel

  const newScrollLeft = desiredMidpointLeft - clientWidth / 2
  const newScrollTop = desiredMidpointTop - clientHeight / 2

  return applyScrollOffsetsBoundary(
    { scrollLeft: newScrollLeft, scrollTop: newScrollTop },
    scrollDimensions,
    newZoomLevel
  )
}

export const sessionSlice = createSlice({
  name: 'session',
  reducers: {},
  initialState,
  extraReducers,
})
