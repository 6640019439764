/*---- External -------------------------------------------------------------*/

import { Icon } from '@blueprintjs/core'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { getTextColorClassName } from 'canvas-shared/lib/config/colors'
import { ColorKey } from 'canvas-shared/lib/types/color.types'

/*---------------------------------------------------------------------------*/

const colorIcon = (
  color: ColorKey,
  pad: boolean = false,
  saturation: number
) => (
  <span
    className={
      color === 'white'
        ? 'text-gray-600'
        : getTextColorClassName(color, saturation)
    }
  >
    <Icon
      className={classNames('override-icon-color', pad && 'px-2')}
      icon={color === 'white' ? 'circle' : 'full-circle'}
    />
  </span>
)

export default colorIcon
