/*---- External -------------------------------------------------------------*/

import React, { useState } from 'react'

import { Button, Intent, Spinner } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { redirectToGoogleAuth } from '../../connections/google/helpers/redirectToGoogleAuth'

/*---------------------------------------------------------------------------*/

export const GoogleConnectButton: React.FC = () => {
  const [loading, setLoading] = useState(false)

  const handleClick = async () => {
    setLoading(true)

    await redirectToGoogleAuth()
  }

  return (
    <div>
      <Button
        intent={Intent.PRIMARY}
        icon={loading ? <Spinner size={16} /> : undefined}
        disabled={loading}
        text={loading ? 'Please wait…' : 'Sign in with Google'}
        onClick={handleClick}
      />
    </div>
  )
}
