/*---- External -------------------------------------------------------------*/

import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { setContainerIdAction } from '../../../actionCreators/container'
import { ContainerStateSlice } from '../../../../types/redux'

/*---------------------------------------------------------------------------*/

const initialState: ContainerStateSlice = {
  containerId: '',
}

const extraReducers = (
  builder: ActionReducerMapBuilder<ContainerStateSlice>
) => {
  builder.addCase(setContainerIdAction, (state, action) => {
    state.containerId = action.payload
  })
}

export const containerSlice = createSlice({
  name: 'container',
  reducers: {},
  initialState,
  extraReducers,
})
