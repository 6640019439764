/*---- External -------------------------------------------------------------*/

import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { Link, useHistory } from 'react-router-dom'

import { WarningCircle } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import { MinimalLayout } from '../../components/layouts/MinimalLayout'
import VerifyEmailForm from '../../components/auth/VerifyEmailForm'
import { Button } from '../../components/utilities/Button'
import Loading from '../../components/loading/Loading'

import { selectSignedIn } from '../../redux/selectors/auth/auth'
import { useSignInEmail } from '../../hooks/localStorage/useSignInEmail'

import { Routes } from '../../router/routes'

/*---------------------------------------------------------------------------*/

const EmailAuth: React.FC = () => {
  const authenticated = useSelector(selectSignedIn)
  const firebase = useFirebase()
  const history = useHistory()

  const [emailNeeded, setEmailNeeded] = useState(false)
  const [authError, setAuthError] = useState(false)

  const [signInEmail, setSignInEmail] = useSignInEmail()

  const doSignIn = useCallback(
    async (email: string) => {
      try {
        await firebase.auth().signInWithEmailLink(email, window.location.href)
        setSignInEmail(undefined)
        return true
      } catch {
        setAuthError(true)
        return false
      }
    },
    [firebase, setSignInEmail]
  )

  const performSignIn = () => {
    if (!authenticated) {
      if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        if (!signInEmail) {
          setEmailNeeded(true)
          setAuthError(false)
        } else {
          doSignIn(signInEmail)
        }
      } else {
        setAuthError(true)
      }
    }
  }

  useEffect(performSignIn, [authenticated, doSignIn, firebase, signInEmail])

  if (authError) {
    return (
      <MinimalLayout
        headerRight={
          <Link
            className="text-text-secondary-light dark:text-text-secondary-dark"
            to={Routes.AUTH_SIGN_IN.path}
          >
            Sign in
          </Link>
        }
      >
        <WarningCircle
          className="text-icon-secondary-light dark:text-icon-secondary-dark mb-4 mx-auto"
          size={80}
          weight="light"
        />
        <h1 className="text-text-primary-light dark:text-text-primary-dark text-center text-xl font-extrabold sm:text-3xl">
          Problem signing you in
        </h1>
        <p className="text-text-primary-light dark:text-text-primary-dark mb-0 mt-3 text-center text-sm leading-normal">
          The link you used is invalid or has expired
        </p>
        <Button
          className="flex justify-center mt-8 w-full"
          onClick={() => history.push(Routes.AUTH_SIGN_IN.path)}
        >
          Sign in again
        </Button>
      </MinimalLayout>
    )
  }

  if (emailNeeded) {
    return (
      <MinimalLayout>
        <VerifyEmailForm onSubmit={doSignIn} />
      </MinimalLayout>
    )
  }

  return <Loading />
}

export default EmailAuth
