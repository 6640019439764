import { IMenuItemProps, Menu } from '@blueprintjs/core'
import orderBy from 'lodash.orderby'

import { Item } from 'canvas-shared/lib/types/Item.types'
import { LinearState } from 'canvas-shared/lib/types/connections/Linear.types'
import { UUID } from '../../../../types'

interface ChangeLinearStateProps {
  cards: Item.LinearCard[]
  linearChangeState: (ids: UUID[], state: LinearState) => Promise<void>
}

export const changeLinearState = ({
  cards,
  linearChangeState,
}: ChangeLinearStateProps): IMenuItemProps => {
  const uniqStates = (state: LinearState, idx: number, list: LinearState[]) =>
    list.findIndex((s) => s.id === state.id) === idx

  const allStates = cards
    .flatMap((c) => c.data?.linear?.team?.states?.nodes ?? [])
    .filter(uniqStates)

  const changeState = (state: LinearState) => {
    linearChangeState(
      cards.map((c) => c.id),
      state
    )
  }

  return {
    text: 'Move to',
    icon: 'two-columns',
    disabled: allStates.length < 1,
    children: orderBy(allStates, ['position']).map((s: LinearState) => (
      <Menu.Item key={s.id} text={s.name} onClick={() => changeState(s)} />
    )),
  }
}
