/*---- External -------------------------------------------------------------*/

import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { User } from 'canvas-shared/lib/types/user.types'
import { UUID } from 'canvas-shared/lib/types'
import { KeyValuePair } from 'canvas-shared/lib/types/utilities.types'
import { ThunkAPI } from '../../types/redux'
import { selectMyUid } from '../selectors/auth/auth'

/*---------------------------------------------------------------------------*/

export const userSetOneAction = createAsyncThunk<
  KeyValuePair<UUID, User | null>,
  KeyValuePair<UUID, User | null>,
  ThunkAPI
>('users/db/setOne', (payload) => payload, {
  condition: (payload, { getState }) => payload.key !== selectMyUid(getState()),
})
export const userRemoveAction = createAction<UUID>('users/remove')
export const userSetAllAction = createAction<Record<UUID, User> | null>(
  'users/setAll'
)
