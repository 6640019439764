"use strict";
/*---- External -------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemType = void 0;
/*---------------------------------------------------------------------------*/
var ItemType;
(function (ItemType) {
    ItemType["Text"] = "Text";
    ItemType["Sticky"] = "Sticky";
    ItemType["Card"] = "Card";
    ItemType["Rectangle"] = "Rectangle";
    ItemType["Line"] = "Line";
    ItemType["Group"] = "Group";
    ItemType["JiraCard"] = "JiraCard";
    ItemType["TrelloCard"] = "TrelloCard";
    ItemType["GoogleSheetsCard"] = "GoogleSheetsCard";
    ItemType["LinearCard"] = "LinearCard";
})(ItemType = exports.ItemType || (exports.ItemType = {}));
