/*---- External -------------------------------------------------------------*/

import { createAction } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { Org } from 'canvas-shared/lib/types/Org.types'

/*---------------------------------------------------------------------------*/

export const setOrgAction = createAction<Org>('org/db/set')
export const setOrgLoadedAction = createAction('org/db/loaded')
