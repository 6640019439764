/*---- External -------------------------------------------------------------*/

import React, { useState, useCallback } from 'react'

import { RouteProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { functions } from '../config/firebase'

import Loading from '../components/loading/Loading'
import NoAccess from '../pages/auth/NoAccess'

import { sendSuperficialError } from '../helpers/sendSuperficialError'

import { useTypedParams } from '../hooks/helpers/useTypedParams'
import { useBoards } from '../redux/selectorHooks/workspace/boards'

import { createListenerLoadedSelector } from '../redux/selectors/helpers/listenerStatus'
import { teamMembershipsSelector } from '../redux/selectors/auth/teams'

/*---------------------------------------------------------------------------*/

const requestContainerAccess = functions.httpsCallable(
  'https-authRequestContainerAccess'
)

const containersByUidLoadedSelector = createListenerLoadedSelector(
  'containersByUid'
)
const containersByTeamMembershipLoadedSelector = createListenerLoadedSelector(
  'containersByTeamMembership'
)

const AuthenticatedRoute: React.FC<RouteProps> = ({ children, ...props }) => {
  const boards = useBoards()
  const teamMemberships = useSelector(teamMembershipsSelector)
  const { containerId, teamId } = useTypedParams()
  const [accessRequestResult, setAccessRequestResult] = useState<
    boolean | undefined
  >()
  const [requestingPermissions, setRequestingPermissions] = useState(false)

  const containersByUidLoaded = useSelector(containersByUidLoadedSelector)
  const containersByTeamMembershipLoaded = useSelector(
    containersByTeamMembershipLoadedSelector
  )

  const requestPermission = useCallback(async () => {
    try {
      const { data } = await requestContainerAccess({ containerId })
      setAccessRequestResult(data)
    } catch (error) {
      sendSuperficialError(error, 'Request for access failed')
    }
  }, [containerId])

  if (teamId) {
    if (!!teamMemberships) {
      if (!teamMemberships[teamId]) {
        return <NoAccess />
      }
    } else {
      return <Loading />
    }
  }

  if (containerId) {
    if (
      containersByUidLoaded &&
      containersByTeamMembershipLoaded &&
      !!boards &&
      !!teamMemberships
    ) {
      if (boards.findIndex((board) => board.id === containerId) < 0) {
        if (accessRequestResult === false) {
          return <NoAccess />
        } else if (accessRequestResult === undefined) {
          if (requestingPermissions === false) {
            requestPermission()
            setRequestingPermissions(true)
          }
          return <Loading />
        }
      }
    } else {
      return <Loading />
    }
  }

  return <>{children}</>
}

export default AuthenticatedRoute
