/*---- External -------------------------------------------------------------*/

import { useParams } from 'react-router-dom'

/*---- Qualdesk -------------------------------------------------------------*/

import { Params } from '../../router/routes'

/*---------------------------------------------------------------------------*/

export const useTypedParams = () => useParams<Params>()
