/*---- External -------------------------------------------------------------*/

import React from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { useSearchContext } from '../../contexts/Search'

/*---------------------------------------------------------------------------*/

const CanvasSearchResultsMask: React.FC = () => {
  const { searchQuery } = useSearchContext()

  if (!searchQuery) {
    return null
  }

  return (
    <div className="z-above-items absolute w-full h-full bg-black bg-opacity-50 pointer-events-none" />
  )
}

export default CanvasSearchResultsMask
