/*---- External -------------------------------------------------------------*/

import React from 'react'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import * as Trello from 'canvas-shared/lib/types/connections/Trello.types'

/*---------------------------------------------------------------------------*/

interface TrelloLabelPillProps {
  label: Trello.Label
  className?: string
  allowBlank?: boolean
  style?: React.CSSProperties
}
const TrelloLabelPill: React.FC<TrelloLabelPillProps> = ({
  label,
  allowBlank,
  className,
  style,
}) => (
  <>
    {label.realColor || allowBlank ? (
      <div
        key={label.name}
        className={classNames('mr-1 rounded-full', className, {
          'px-3': !style?.width,
          'py-1': !style?.height,
        })}
        style={{ backgroundColor: label.realColor, ...style }}
        title={label.name}
      ></div>
    ) : (
      ''
    )}
  </>
)

export default TrelloLabelPill
