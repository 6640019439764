/*---- External -------------------------------------------------------------*/

import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  setShouldSelectAction,
  clearShouldSelectAction,
} from '../../../actionCreators/shouldSelect'

import { ShouldSelectItemIdStateSlice } from '../../../../types/redux'

/*---------------------------------------------------------------------------*/

const initialState: ShouldSelectItemIdStateSlice = null

const extraReducers = (
  builder: ActionReducerMapBuilder<ShouldSelectItemIdStateSlice>
) => {
  builder
    .addCase(setShouldSelectAction, (state, action) => {
      return action.payload
    })
    .addCase(clearShouldSelectAction, (state) => {
      return initialState
    })
}

export const shouldSelectItemIdSlice = createSlice({
  name: 'shouldSelectItemId',
  reducers: {},
  initialState,
  extraReducers,
})
