/*---- External -------------------------------------------------------------*/

import React from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { useUser } from '../../redux/selectorHooks/users/useUser'

/*---------------------------------------------------------------------------*/

interface UserNameProps {
  className?: string
  uid: string
}

const UserName: React.FC<UserNameProps> = ({ className, uid }) => {
  const user = useUser(uid)
  const name = !!user && user.name ? user.name : 'Unknown user'
  return <span className={className}>{name}</span>
}

export default UserName
