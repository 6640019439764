/*---- External -------------------------------------------------------------*/

import { createAction } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { UUID } from '../../types'

/*---------------------------------------------------------------------------*/

export const setShouldSelectAction = createAction<UUID>('board/setShouldSelect')
export const clearShouldSelectAction = createAction('board/clearShouldSelect')
