/*---- External -------------------------------------------------------------*/

import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { timer } from 'rxjs'
import {
  filter,
  map,
  mapTo,
  mergeMap,
  throttleTime,
  withLatestFrom,
} from 'rxjs/operators'
import { CursorModesWithStickyPendingItems } from 'canvas-shared/lib/types/CursorMode.types'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  ActionWithPayload,
  AutoScrollStatus,
  EpicWithDifferentActions,
  ThunkAPI,
} from '../../types/redux'
import {
  startAutoScrollAction,
  stopAutoScrollAction,
  tickAutoScrollAction,
  tryAutoScrollAction,
} from '../actionCreators/autoScroll'
import { pointerMoveAction, PointerPayload } from '../actionCreators/pointers'
import { scrollOrZoomChangedAction } from '../actionCreators/scroll'
import { selectAutoScrollStatus } from '../selectors/board/scroll'
import { AsyncThunkAction } from '@reduxjs/toolkit'
import { selectActiveGesture } from '../selectors/board/gestures'
import { selectCursorMode } from '../selectors/board/cursor'

/*---------------------------------------------------------------------------*/

const pointerMoveAutoScrollEpic: EpicWithDifferentActions<
  ActionWithPayload<PointerPayload>,
  Action
> = (action$) =>
  action$.pipe(
    ofType(pointerMoveAction.fulfilled),
    throttleTime(50), // figure out what this number should be
    filter(
      ({ payload }) =>
        (!!payload.gesture && !payload.gesture.finished) ||
        CursorModesWithStickyPendingItems.includes(payload.cursorMode)
    ),
    mapTo(tryAutoScrollAction())
  )

const tryAutoScrollEpic: Epic<Action, Action> = (action$, state$) =>
  action$.pipe(
    ofType(tryAutoScrollAction),
    withLatestFrom(state$),
    filter(
      ([_, state]) =>
        selectAutoScrollStatus(state) === AutoScrollStatus.SHOULD_START
    ),
    mapTo(startAutoScrollAction())
  )

const startAutoScrollEpic: Epic<Action, Action> = (action$, state$) =>
  action$.pipe(
    ofType(startAutoScrollAction),
    withLatestFrom(state$),
    filter(
      ([_, state]) => selectAutoScrollStatus(state) === AutoScrollStatus.STARTED
    ),
    mapTo(tickAutoScrollAction())
  )

const tickAutoScrollTriggerChangeEpic: EpicWithDifferentActions<
  Action,
  AsyncThunkAction<any, void, ThunkAPI>
> = (action$) =>
  action$.pipe(ofType(tickAutoScrollAction), mapTo(scrollOrZoomChangedAction()))

const tickAutoScrollEpic: Epic<Action, any> = (action$, state$) =>
  action$.pipe(
    ofType(tickAutoScrollAction),
    mergeMap(() => {
      return timer(10).pipe(
        withLatestFrom(state$),
        map(([_, state]) => {
          const activeGesture = selectActiveGesture(state)
          const cursorMode = selectCursorMode(state)
          if (
            !activeGesture &&
            !CursorModesWithStickyPendingItems.includes(cursorMode)
          )
            return stopAutoScrollAction()

          switch (selectAutoScrollStatus(state)) {
            case AutoScrollStatus.SHOULD_STOP:
              return stopAutoScrollAction()
            case AutoScrollStatus.STARTED:
              return tickAutoScrollAction()
            default:
              return null
          }
        }),
        filter((a) => !!a)
      )
    })
  )

export const autoScrollEpic = combineEpics(
  pointerMoveAutoScrollEpic,
  tryAutoScrollEpic,
  startAutoScrollEpic,
  tickAutoScrollEpic,
  tickAutoScrollTriggerChangeEpic
)
