/*---- External -------------------------------------------------------------*/

import React from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { STATUS_CATEGORY_COLOR_MAP } from '../../../../config/jira'

import { JiraIssueLabel } from '../../../connections/jira/JiraIssueLabel'

import { StatusCategoryKey } from 'canvas-shared/lib/types/connections/Jira.types'
import { JiraPreviewData } from 'canvas-shared/lib/types/previewItem/JiraPreviewData.types'

/*---------------------------------------------------------------------------*/

interface Props {
  jiraData: JiraPreviewData
}

export const JiraPreviewCardBody: React.FC<Props> = ({ jiraData }) => {
  return (
    <>
      <ul className="flex flex-wrap items-center -mx-1 -my-0.5">
        {jiraData.status.name && (
          <li className="m-0.5 min-w-0">
            <JiraIssueLabel
              small
              text={jiraData.status.name}
              color={
                STATUS_CATEGORY_COLOR_MAP[
                  jiraData.status.key as StatusCategoryKey
                ]
              }
            />
          </li>
        )}
        {jiraData.sprintName && (
          <li className="m-0.5 min-w-0">
            <JiraIssueLabel small text={jiraData.sprintName} />
          </li>
        )}
        {jiraData.labels.map((label) => (
          <li key={label} className="m-0.5 min-w-0">
            <JiraIssueLabel small text={label} />
          </li>
        ))}
        {jiraData.parentName && (
          <li className="m-0.5 min-w-0">
            {/* TODO: use actual epic color (CA-1103) */}
            <JiraIssueLabel small text={jiraData.parentName} color="#d8b4fe" />
          </li>
        )}
      </ul>
      <div className="flex items-center mt-4 space-x-1">
        <img
          className="h-4"
          src={jiraData.type.icon}
          alt={jiraData.type.name}
          title={jiraData.type.name}
        />
        <span className="text-text-secondary-light dark:text-text-secondary-dark flex-grow text-xs">
          {jiraData.key}
        </span>
        {jiraData.assigneeName && jiraData?.assigneeAvatarUrls?.['24x24'] && (
          <img
            className="w-4 h-4 rounded-full"
            src={jiraData.assigneeAvatarUrls['24x24']}
            alt={jiraData.assigneeName}
            title={`Assigned to ${jiraData.assigneeName}`}
          />
        )}
      </div>
    </>
  )
}
