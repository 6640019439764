/*---- External -------------------------------------------------------------*/

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import classNames from 'classnames'
import { DateTime } from 'luxon'

import { Classes } from '@blueprintjs/core'
import {
  ChatCircle,
  CheckSquareOffset,
  Clock,
  Paperclip,
  TextAlignLeft,
} from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import { safeString } from '../../../helpers/safeString'

import useTitleStream from '../../../hooks/useTitleStream'

import { selectInteractionMode } from '../../../redux/selectors/board/interactionModes'
import { itemSetTitleAction } from '../../../redux/actionCreators/itemData'
import { useEditsActions } from '../../../redux/actions/board/useEditsActions'
import { useSelectedItemIds } from '../../../redux/selectorHooks/board/useSelectedItemIds'

import { EstimationControls, EstimationValue } from '../../items/ItemEstimate'
import { TrelloCardLabel } from './TrelloCardLabel'
import { MultilineTextInput } from '../../multilineTextInput/MultilineTextInput'

import trelloIcon from '../../../assets/integrations/trello/trello-icon.svg'

import { InteractionMode } from '../../../types'
import { Item } from 'canvas-shared/lib/types/Item.types'

/*---------------------------------------------------------------------------*/

export const TrelloCard = ({
  id,
  data: { archived, title: currentTitle, trello: card },
  loading,
  operation,
}: Item.TrelloCard) => {
  const titleFromData = safeString(currentTitle)

  const [title, setTitle] = useState(titleFromData)
  const [editing, setEditing] = useState(false)

  useTitleStream({ id, title, setTitle, editing })

  useEffect(() => {
    setTitle(titleFromData)
  }, [titleFromData])

  const dispatch = useDispatch()
  const { addEdit, removeEdit } = useEditsActions()
  const selectedItemIds = useSelectedItemIds()

  const handleChange = (value: string) => {
    setTitle(value)
  }

  const handleConfirm = (value: string) => {
    stopEditing()
    dispatch(itemSetTitleAction({ itemId: id, title: value }))
  }

  const startEditing = useCallback(() => {
    setEditing(true)
    addEdit(id)
  }, [addEdit, id])

  const stopEditing = () => {
    setEditing(false)
    removeEdit(id)
  }

  const startEditingIfEmptyAndSelected = () => {
    if (!title && selectedItemIds.includes(id)) {
      startEditing()
    }
  }

  useEffect(startEditingIfEmptyAndSelected, [
    id,
    selectedItemIds,
    startEditing,
    title,
  ])

  const interactionMode = useSelector(selectInteractionMode)

  const isEstimatingOrResolving = [
    InteractionMode.ESTIMATE_RESOLUTION,
    InteractionMode.ESTIMATION,
  ].includes(interactionMode)

  const membersWithAvatars = useMemo(
    () =>
      card.context.members.filter(
        (member) => member.avatarUrl && card.memberIds.includes(member.id)
      ),
    [card]
  )

  return (
    <div className="flex flex-col h-full" onDoubleClick={startEditing}>
      <div className="bg-background-five-light dark:bg-background-five-dark border-border-weak-light dark:border-border-weak-dark flex flex-col flex-grow p-5 border border-b-0 rounded-t-xl">
        <div className="flex flex-col flex-grow">
          <MultilineTextInput
            className="text-text-primary-light dark:text-text-primary-dark flex-grow text-lg leading-normal"
            disabled={!editing}
            isEditing={editing}
            key={operation?.origin}
            onCancel={stopEditing}
            onChange={handleChange}
            onConfirm={handleConfirm}
            placeholder=""
            value={title}
          />
          <div className="flex-shrink-0 mt-6">
            <ul
              className={classNames(
                'flex flex-wrap items-stretch -mx-2 -my-1',
                {
                  [Classes.SKELETON]: loading,
                }
              )}
            >
              <li className="m-1 min-w-0">
                <TrelloCardLabel text={card.list.name} />
              </li>
              {card.labels.map((label) => (
                <li key={label.id} className="m-1 min-w-0">
                  <TrelloCardLabel
                    text={label.name}
                    label={label.color}
                    color={label.realColor}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div
            className={classNames('flex-shrink-0 pointer-events-none', {
              [Classes.SKELETON]: loading,
            })}
          >
            <div className="flex items-end mt-4 space-x-2">
              <div className="flex flex-grow items-center space-x-2">
                {card.badges?.due && (
                  <span
                    title={`Due ${DateTime.fromISO(
                      card.badges.due
                    ).toLocaleString(DateTime.DATETIME_MED)}`}
                  >
                    <Clock className="text-icon-primary-light dark:text-icon-primary-dark w-5 h-5" />
                  </span>
                )}
                {card.badges?.description && (
                  <span title="Description">
                    <TextAlignLeft className="text-icon-primary-light dark:text-icon-primary-dark w-5 h-5" />
                  </span>
                )}
                {(card.badges?.comments ?? 0) > 0 && (
                  <span
                    title={`${card.badges?.comments ?? 0} ${
                      (card.badges?.comments ?? 0) === 1
                        ? 'comment'
                        : 'comments'
                    }`}
                  >
                    <ChatCircle className="text-icon-primary-light dark:text-icon-primary-dark w-5 h-5" />
                  </span>
                )}
                {(card.badges?.attachments ?? 0) > 0 && (
                  <span
                    title={`${card.badges?.attachments ?? 0} ${
                      (card.badges?.attachments ?? 0) === 1
                        ? 'attachment'
                        : 'attachments'
                    }`}
                  >
                    <Paperclip className="text-icon-primary-light dark:text-icon-primary-dark w-5 h-5" />
                  </span>
                )}
                {(card.badges?.checkItems ?? 0) > 0 && (
                  <span title="Checklist">
                    <CheckSquareOffset className="text-icon-primary-light dark:text-icon-primary-dark w-5 h-5" />
                  </span>
                )}
              </div>
              <div
                className={classNames('self-stretch', {
                  [Classes.SKELETON]: loading,
                })}
              >
                <EstimationValue itemId={id} />
              </div>
              <ul className="flex flex-row flex-shrink-0 items-center -my-1.5 -space-x-5">
                {membersWithAvatars.map((member, i, arr) => (
                  <li
                    className={classNames('p-1.5 rounded-full', {
                      'clip-person': i + 1 < arr.length,
                      'pl-0': i === 0,
                      'pr-0': i + 1 === arr.length,
                    })}
                    key={member.id}
                  >
                    <img
                      className="w-8 h-8 rounded-full"
                      src={`${member.avatarUrl}/170.png`}
                      alt={member.name}
                      title={`Assigned to ${member.name}`}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <footer className="bg-background-four-light dark:bg-background-five-dark border-border-weak-light dark:border-border-weak-dark flex flex-shrink-0 items-center px-5 py-4 border rounded-b-xl pointer-events-none">
        <div className="flex flex-grow items-center space-x-2">
          {card.board.prefs?.backgroundImageScaled?.[0].url && (
            <img
              className="w-6 h-6 rounded"
              src={card.board.prefs?.backgroundImageScaled?.[0].url}
              alt={card.board.name}
            />
          )}
          <div className="text-text-secondary-light dark:text-text-secondary-dark text-base">
            {card.board.name}
          </div>
        </div>
        {isEstimatingOrResolving ? (
          <EstimationControls itemId={id} />
        ) : (
          <img alt="Trello" className="w-6 h-6" src={trelloIcon} />
        )}
      </footer>
    </div>
  )
}
