/*---- External -------------------------------------------------------------*/

import React, { useCallback, useRef, useState, useEffect } from 'react'

import classNames from 'classnames'
import { Classes } from '@blueprintjs/core'

import { CaretDown } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

interface RadioSelectProps {
  className?: string
  selectedValue: string
  options: Array<{ value: string; label: React.ReactNode; hideRadio?: boolean }>
  onChange: (value: string) => void
}

export const RadioSelect: React.FC<RadioSelectProps> = ({
  className,
  selectedValue,
  options,
  onChange,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDownOutside)

    return () => {
      document.removeEventListener('mousedown', onMouseDownOutside)
    }
  })

  const onMouseDownOutside = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false)
      }
    },
    [ref]
  )

  const onKeyPress = useCallback((event) => {
    if (event.code === 'Space') {
      setIsOpen((isOpen) => !isOpen)
      event.preventDefault()
    }
  }, [])

  const onKeyDown = useCallback(
    (event) => {
      switch (event.code) {
        case 'Enter':
        case 'Escape':
          setIsOpen(false)
          event.preventDefault()
          break

        case 'ArrowUp':
          if (event.altKey || event.metaKey) {
            onChange(options[0].value)
          } else {
            const idx = options.findIndex((o) => o.value === selectedValue) - 1
            if (options[idx]) onChange(options[idx].value)
          }
          event.preventDefault()
          break

        case 'ArrowDown':
          if (event.altKey || event.metaKey) {
            onChange(options[options.length - 1].value)
          } else {
            const idx = options.findIndex((o) => o.value === selectedValue) + 1
            if (options[idx]) onChange(options[idx].value)
          }
          event.preventDefault()
          break
      }
    },
    [options, selectedValue, onChange]
  )

  return (
    <div ref={ref} className={classNames('relative', className)}>
      <div
        className="border-border-weak-light dark:border-border-weak-dark hover:border-border-normal-light dark:hover:border-border-normal-dark active:bg-button-secondary-active-light dark:active:bg-button-secondary-active-dark flex items-center p-3 font-semibold border rounded-lg cursor-pointer overflow-hidden space-x-4"
        tabIndex={0}
        onClick={() => setIsOpen(true)}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
      >
        <span className="flex-grow flex-shrink overflow-hidden">
          {
            options.find((valueOption) => valueOption.value === selectedValue)
              ?.label
          }
        </span>
        <CaretDown
          className="text-icon-secondary-light dark:text-icon-secondary-dark"
          size={16}
          weight="bold"
        />
      </div>
      <div
        className={classNames(
          'z-above-above-above-everything bg-background-five-light dark:bg-background-five-dark border-border-weak-light dark:border-border-normal-dark absolute -left-1.5 -right-1.5 -top-1.5 p-1.5 border rounded-xl shadow-xl overflow-hidden space-y-1.5',
          {
            'sr-only': !isOpen,
          }
        )}
      >
        {options.map(({ label, value, hideRadio }) => {
          const isSelected = value === selectedValue

          return (
            <label
              key={value}
              className={classNames(
                'flex items-center mb-0 p-3 rounded-lg overflow-hidden',
                {
                  'font-semibold bg-button-secondary-selected-light dark:bg-button-secondary-selected-dark': isSelected,
                  'hover:bg-button-secondary-hovered-light dark:hover:bg-button-secondary-hovered-dark active:bg-button-secondary-active-light dark:active:bg-button-secondary-active-dark': !isSelected,
                },
                Classes.CONTROL,
                Classes.RADIO
              )}
            >
              <input
                type="radio"
                value={value}
                checked={isSelected}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(event.target.value)
                }}
                onClick={() => {
                  setIsOpen(false)
                }}
              />
              <span
                className={classNames(
                  'order-last m-0 ml-4',
                  Classes.CONTROL_INDICATOR,
                  { hidden: hideRadio }
                )}
              />
              <span className="flex-grow flex-shrink overflow-hidden">
                {label}
              </span>
            </label>
          )
        })}
      </div>
    </div>
  )
}
