/*---- External -------------------------------------------------------------*/

import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  defaultLineColor,
  defaultRectangleBackgroundColor,
  defaultTextColor,
} from 'canvas-shared/lib/config/colors'

/*---- Qualdesk -------------------------------------------------------------*/

import type { UUID } from 'canvas-shared/lib/types'
import { ColorKey } from 'canvas-shared/lib/types/color.types'
import { Item } from 'canvas-shared/lib/types/Item.types'
import {
  TextAlignmentKey,
  TextStyleKey,
} from 'canvas-shared/lib/types/text.types'
import type {
  BasicPointerEvent,
  Gesture,
} from 'canvas-shared/lib/types/pointerEvents.types'
import { DEFAULT_LINE_THICKNESS } from '../../config/lines'
import { defaultTextAlignment } from '../../config/textAlignments'
import { defaultTextSize } from '../../config/textSizes'
import { defaultTextStyle } from '../../config/textStyles'
import type { ThunkAPI } from '../../types/redux'
import { selectMyUid } from '../selectors/auth/auth'
import { getLocalStorageOption } from './helpers/getLocalStorageOption'
import { selectGestureByPointerId } from '../selectors/board/gestures'
import { selectStartZ } from '../selectors/board/positions'

/*---------------------------------------------------------------------------*/

export interface DrawShapeAction {
  gesture: Gesture
  uid: UUID
  data: Item.TextItemData | Item.LineItemData
  startZ: number
}

export const startDrawingRectangleAction = createAsyncThunk<
  DrawShapeAction,
  BasicPointerEvent,
  ThunkAPI
>('shapes/drawRectangle', (payload, { getState }) => {
  const state = getState()
  const uid = selectMyUid(state)
  const gesture = selectGestureByPointerId(state, payload.pointerId)
  const startZ = selectStartZ(state)

  const data = {
    textAlignment: getLocalStorageOption<TextAlignmentKey>(
      'rectangleTextAlignment',
      defaultTextAlignment.id
    ),
    textSize: getLocalStorageOption<number>(
      'rectangleTextSize',
      defaultTextSize
    ),
    textColor: getLocalStorageOption<ColorKey>(
      'rectangleTextColor',
      defaultTextColor.color
    ),
    textStyle: getLocalStorageOption<TextStyleKey[]>(
      'rectangleTextStyle',
      defaultTextStyle
    ),
    backgroundColor: getLocalStorageOption<ColorKey>(
      'rectangleBackgroundColor',
      defaultRectangleBackgroundColor.color
    ),
  }

  return { gesture, uid, data, startZ }
})

export const startDrawingLineAction = createAsyncThunk<
  DrawShapeAction,
  BasicPointerEvent,
  ThunkAPI
>('shapes/drawLine', (payload, { getState }) => {
  const state = getState()
  const gesture = selectGestureByPointerId(state, payload.pointerId)
  const uid = selectMyUid(state)
  const startZ = selectStartZ(state)

  const data = {
    lineColor: getLocalStorageOption<ColorKey>(
      'lineColor',
      defaultLineColor.color
    ),
    lineThickness: getLocalStorageOption<number>(
      'lineThickness',
      DEFAULT_LINE_THICKNESS
    ),
  }

  return { gesture, uid, data, startZ }
})

export const startDrawingTextboxAction = createAsyncThunk<
  DrawShapeAction,
  BasicPointerEvent,
  ThunkAPI
>('shapes/drawTextbox', (payload, { getState }) => {
  const state = getState()
  const gesture = selectGestureByPointerId(state, payload.pointerId)
  const uid = selectMyUid(state)
  const startZ = selectStartZ(state)

  const data = {
    textAlignment: getLocalStorageOption<TextAlignmentKey>(
      'textAlignment',
      defaultTextAlignment.id
    ),
    textSize: getLocalStorageOption<number>('textSize', defaultTextSize),
    textColor: getLocalStorageOption<ColorKey>(
      'textColor',
      defaultTextColor.color
    ),
    textStyle: getLocalStorageOption<TextStyleKey[]>(
      'textStyle',
      defaultTextStyle
    ),
  } as Item.TextItemData

  return { gesture, uid, data, startZ }
})
