/*---- External -------------------------------------------------------------*/

import { useCallback, useEffect } from 'react'

import { useDispatch } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { altKeyAction } from '../../redux/actionCreators/keys'

/*---------------------------------------------------------------------------*/

export const useWindowKeyboardInteractions = () => {
  const dispatch = useDispatch()

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Alt') {
        dispatch(altKeyAction(true))
      }
    },
    [dispatch]
  )

  const handleKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Alt') {
        dispatch(altKeyAction(false))
      }
    },
    [dispatch]
  )

  const handleKeyEvents = () => {
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }

  useEffect(handleKeyEvents, [handleKeyDown, handleKeyUp])
}
