/*---- External -------------------------------------------------------------*/

import React, { createContext, useMemo, useRef, useState } from 'react'

import { MenuItem, Spinner } from '@blueprintjs/core'

import { ItemRenderer, Omnibar } from '@blueprintjs/select'

/*---- Qualdesk -------------------------------------------------------------*/

import ensureContextValue from '../helpers/ensureContextValue'
import highlightText from '../helpers/highlightText'

import useJiraIssueSearch from '../hooks/connections/useJiraIssueSearch'

import * as JiraAPI from 'canvas-shared/lib/types/connections/JiraAPI.types'

/*---------------------------------------------------------------------------*/

interface JiraIssueSearch {
  showJiraIssueSearchBar: (newAction: JiraIssueSearchAction) => void
}

export type JiraIssueSearchAction = (issue: JiraAPI.Issue) => void

export const JiraIssueSearchProvider: React.FC = ({ children }) => {
  const [isOpen, setOpen] = useState(false)
  const [query, setQuery] = useState('')
  const action = useRef<JiraIssueSearchAction>()

  const { issues, loading } = useJiraIssueSearch(query)

  const showJiraIssueSearchBar = (newAction: JiraIssueSearchAction) => {
    action.current = newAction
    setOpen(true)
  }

  const provider = useMemo(
    () => ({
      showJiraIssueSearchBar,
    }),
    []
  )

  const onClose = () => {
    setOpen(false)
    setQuery('')
    action.current = undefined
  }

  const onItemSelect = (issue: JiraAPI.Issue) => {
    if (action.current) {
      action.current(issue)
    }

    onClose()
  }

  const renderEmpty = () => {
    if (loading) {
      return (
        <MenuItem icon={<Spinner size={16} />} text="Loading..." disabled />
      )
    }
    return <MenuItem text="No results" disabled />
  }
  const renderIssue: ItemRenderer<JiraAPI.Issue> = (
    issue,
    { handleClick, modifiers, query }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null
    }
    const text = highlightText(`[${issue.key}] ${issue.fields.summary}`, query)

    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={issue.id}
        onClick={handleClick}
        text={text}
      />
    )
  }

  const JiraIssueSearchOmnibar = Omnibar.ofType<JiraAPI.Issue>()

  return (
    <JiraIssueSearchContext.Provider value={provider}>
      {children}
      <JiraIssueSearchOmnibar
        isOpen={isOpen}
        items={issues}
        onItemSelect={onItemSelect}
        itemRenderer={renderIssue}
        query={query}
        onQueryChange={setQuery}
        noResults={renderEmpty()}
        onClose={onClose}
      />
    </JiraIssueSearchContext.Provider>
  )
}

const JiraIssueSearchContext = createContext<Partial<JiraIssueSearch>>({})
export const useJiraIssueSearchContext = ensureContextValue<JiraIssueSearch>(
  JiraIssueSearchContext,
  'useJiraIssueSearchContext'
)
