/*---- External -------------------------------------------------------------*/

import React from 'react'

import classNames from 'classnames'
import { Spinner } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean
  minimal?: boolean
}

export const Button: React.FC<ButtonProps> = ({
  children,
  className,
  minimal = false,
  loading = false,
  ...buttonProps
}) => (
  <button
    className={classNames(
      'inline-flex items-center p-4 h-14 border border-transparent rounded-lg',
      'disabled:bg-button-primary-disabled-light disabled:text-text-disabled-light dark:disabled:bg-button-success-disabled-dark dark:disabled:text-text-disabled-dark',
      {
        'bg-button-primary-light dark:bg-button-success-dark dark:text-background-five-dark hover:bg-button-primary-hovered-light active:bg-button-primary-active-light dark:active:bg-button-success-active-dark dark:hover:bg-button-success-hovered-dark text-white': !minimal,
        'text-text-primary-light dark:text-text-primary-dark border-border-normal-light dark:border-border-normal-dark hover:bg-button-secondary-hovered-light dark:hover:bg-button-secondary-hovered-dark active:bg-button-secondary-active-light dark:active:bg-button-secondary-active-dark': minimal,
        'justify-center': loading,
      },
      className
    )}
    {...buttonProps}
  >
    {loading ? <Spinner size={24} /> : children}
  </button>
)
