/*---- External -------------------------------------------------------------*/

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Helmet } from 'react-helmet-async'

import { Button, Classes, Intent, Spinner } from '@blueprintjs/core'
import { BriefcaseMetal } from 'phosphor-react'

import PQueue from 'p-queue'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { useAreYouSureContext } from '../../contexts/AreYouSure'

import { showToastAction } from '../../redux/actionCreators/toast'

import { createTeamSelector } from '../../redux/selectors/auth/teams'
import { selectMyUid } from '../../redux/selectors/auth/auth'

import { TeamDetailsPanel } from './panels/TeamDetailsPanel'
import { TeamSharingPanel } from './panels/TeamSharingPanel'
import { TeamMembersPanel } from './panels/TeamMembersPanel'
import { TeamIcon } from '../team/TeamIcon'

import { functions } from '../../config/firebase'

import { useTypedParams } from '../../hooks/helpers/useTypedParams'

import { NoSettingsAccess } from './NoSettingsAccess'

import { Team } from 'canvas-shared/lib/types/Team.types'
import { PermissionLevel } from 'canvas-shared/lib/types/permissions.types'
import { SettingsPanelProps } from '../../types/settings'
import { Routes } from '../../router/routes'

/*---------------------------------------------------------------------------*/

const deleteTeam = functions.httpsCallable('https-authDeleteTeam')

const settingsUpdateQueue = new PQueue()

export interface TeamPanelProps extends SettingsPanelProps {
  team: Team
}

export const TeamSettings: React.FC = () => {
  const { teamId } = useTypedParams()
  const uid = useSelector(selectMyUid)

  if (!teamId) {
    throw new Error('Can’t access team settings without team ID')
  }

  const teamSelector = createTeamSelector(teamId)
  const team = useSelector(teamSelector)

  if (!team) {
    return null
  }

  const userRole = team.roles.users[uid]

  if (!userRole || userRole < PermissionLevel.ADMIN) {
    return <NoSettingsAccess />
  }

  return <TeamSettingsInner {...team} />
}

export const TeamSettingsInner: React.FC<Team> = (team) => {
  const { showAlert } = useAreYouSureContext()
  const dispatch = useDispatch()
  const { push } = useHistory()
  const [synced, setSynced] = useState<boolean | null>(null)

  const panelProps: TeamPanelProps = {
    className:
      'col-span-1 rounded-lg bg-white dark:bg-cool-gray-750 shadow p-6',
    queueUpdate: (fn) => settingsUpdateQueue.add(fn),
    team,
  }

  useEffect(() => {
    settingsUpdateQueue.on('add', () => {
      setSynced(false)
    })
    settingsUpdateQueue.on('idle', () => {
      setSynced(true)
    })
  }, [])

  const handleDeleteTeam = () => {
    showAlert(
      async () => {
        await deleteTeam({ teamId: team.id })
        dispatch(
          showToastAction({
            intent: Intent.DANGER,
            message: `Team ${team.name} deleted`,
          })
        )
        push(Routes.WORKSPACE_PERSONAL.path)
      },
      <>
        <p>Are you sure you want to delete this team?</p>
        <p>
          Any boards in this team will still be accessible by the person who
          created them.
        </p>
      </>,
      {
        confirmButtonText: 'Delete team',
        icon: (
          <div
            className={classNames(
              Classes.ICON,
              'text-icon-danger-light dark:text-icon-danger-dark'
            )}
          >
            <BriefcaseMetal weight="duotone" />
          </div>
        ),
        intent: Intent.DANGER,
      }
    )
  }

  return (
    <div className="bg-background-four-light dark:bg-background-four-dark relative flex flex-col flex-grow h-full">
      <Helmet title="Team settings" />
      <div className="overflow-auto">
        <div className="flex flex-row items-end justify-between mt-12 mx-12">
          <div className="flex flex-col space-y-4">
            <div className="text-3xl font-semibold">Team settings</div>
            <div className="flex items-center space-x-2">
              <TeamIcon className="w-5 h-5" teamId={team.id} />
              <div className="text-text-secondary-light dark:text-text-secondary-dark text-xs font-light">
                {team.name}
              </div>
            </div>
          </div>
          <div className="text-text-secondary-light dark:text-text-secondary-dark">
            {synced && 'Changes saved'}
            {synced === false && (
              <div className="flex items-center space-x-1">
                <Spinner size={12} />
                <div>Saving changes</div>
              </div>
            )}
          </div>
        </div>
        <div className="grid gap-6 grid-cols-1 m-12 lg:grid-cols-2">
          <TeamDetailsPanel {...panelProps} />
          <TeamMembersPanel
            {...panelProps}
            className={classNames(panelProps.className, 'row-span-2')}
          />
          <TeamSharingPanel {...panelProps} />
          <div>
            <Button intent={Intent.DANGER} minimal onClick={handleDeleteTeam}>
              Delete team
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
