/*---- External -------------------------------------------------------------*/

import React from 'react'
import { useSelector } from 'react-redux'

import classNames from 'classnames'

import { Button, Tooltip } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { HOTKEY_TEXT } from '../../../config/hotkeys'
import { ItemConfigurations } from '../../../config/itemTypes'

import { useBoardToolbarHandlers } from '../../../hooks/board/useBoardToolbarHandlers'
import { selectCursorMode } from '../../../redux/selectors/board/cursor'

import { TooltipContent } from './TooltipContent'

/*---------------------------------------------------------------------------*/

export const AddTextItemButton: React.FC = () => {
  const { handleAddTextItem } = useBoardToolbarHandlers()
  const cursorMode = useSelector(selectCursorMode)

  const Icon = ItemConfigurations.Text.icon
  const active = cursorMode === ItemConfigurations.Text.cursorMode

  return (
    <Tooltip
      popoverClassName="tooltip"
      content={
        <TooltipContent
          title={ItemConfigurations.Text.text}
          hotkey={HOTKEY_TEXT}
        />
      }
      hoverOpenDelay={300}
    >
      <Button
        className={classNames(
          { 'text-icon-accent-light dark:text-icon-accent-dark': active },
          'mr-1.5 px-2 w-10'
        )}
        icon={
          Icon && (
            <Icon
              size={24}
              weight={active ? 'duotone' : undefined}
              className="toolbar-icon bp3-icon"
            />
          )
        }
        onClick={handleAddTextItem}
        minimal
        aria-label={ItemConfigurations.Text.text}
      />
    </Tooltip>
  )
}
