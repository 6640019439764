/*---- External -------------------------------------------------------------*/

import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Intent, Menu, MenuDivider, MenuItem } from '@blueprintjs/core'
import {
  Archive,
  BriefcaseMetal,
  Check,
  Copy,
  MinusCircle,
} from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import { showToastAction } from '../../redux/actionCreators/toast'
import { useBoardActions } from '../../redux/actions/board/useBoardActions'

import { teamMembershipsOrderedSelector } from '../../redux/selectors/auth/teams'

import { selectMyUid } from '../../redux/selectors/auth/auth'
import { useBoard } from '../../redux/selectorHooks/board/useBoard'

import { TeamIcon } from '../team/TeamIcon'

import { PermissionLevel } from 'canvas-shared/lib/types/permissions.types'
import { Team } from 'canvas-shared/lib/types/Team.types'
import { UUID } from 'canvas-shared/lib/types'

/*---------------------------------------------------------------------------*/

export const BoardMenu: React.FC = () => {
  const {
    settings: { url },
    archived,
    roles: { users, teams },
  } = useBoard()
  const uid = useSelector(selectMyUid)
  const {
    archiveBoard,
    moveBoardToTeam,
    removeBoardFromTeam,
  } = useBoardActions()

  const toggleArchivedStatus = () => {
    archiveBoard(!archived)
  }

  const canManage = users[uid] >= PermissionLevel.CREATOR

  const teamMemberships = useSelector(teamMembershipsOrderedSelector)

  const dispatch = useDispatch()

  const copyLinkToClipboard = async () => {
    if (url) {
      try {
        await navigator.clipboard.writeText(url)
        dispatch(
          showToastAction({
            intent: Intent.SUCCESS,
            message: 'Share link copied to clipboard',
          })
        )
      } catch {
        dispatch(
          showToastAction({
            intent: Intent.WARNING,
            message: 'Couldn’t copy share link to clipboard',
          })
        )
      }
    }
  }

  const currentTeamName = useMemo(
    () => teamMemberships?.find((t) => t.id === teams?.membership)?.name,
    [teamMemberships, teams?.membership]
  )

  return (
    <Menu>
      <MenuDivider title="Share" />
      <MenuItem
        icon={
          <Copy
            className="bp3-icon text-icon-secondary-light dark:text-icon-secondary-dark w-4 h-4"
            weight="fill"
          />
        }
        text="Copy share link"
        onClick={copyLinkToClipboard}
      />
      <MenuDivider title="Manage" />
      <MenuItem
        disabled={
          !canManage || !teamMemberships || teamMemberships.length === 0
        }
        icon={
          <BriefcaseMetal
            className="bp3-icon text-icon-secondary-light dark:text-icon-secondary-dark w-4 h-4"
            weight="fill"
          />
        }
        text="Team access"
      >
        <MenuDivider title="Move to team" />
        {teamMemberships?.map((team) => (
          <TeamMenuItem
            active={team.id === teams?.membership}
            disabled={team.id === teams?.membership}
            key={team.id}
            onClick={moveBoardToTeam}
            team={team}
          />
        ))}
        {!!teams && (
          <>
            <MenuDivider />
            <MenuItem
              icon={
                <MinusCircle
                  className="bp3-icon text-icon-secondary-light dark:text-icon-secondary-dark w-4 h-4"
                  weight="fill"
                />
              }
              onClick={removeBoardFromTeam}
              text={`Remove from ${currentTeamName}`}
            />
          </>
        )}
      </MenuItem>
      <MenuItem
        disabled={!canManage}
        icon={
          <Archive
            className="bp3-icon text-icon-secondary-light dark:text-icon-secondary-dark w-4 h-4"
            weight="fill"
          />
        }
        text={archived ? 'Unarchive board' : 'Archive board'}
        onClick={toggleArchivedStatus}
      />
    </Menu>
  )
}

interface TeamMenuItemProps {
  active?: boolean
  disabled?: boolean
  onClick: (teamId: UUID) => PromiseLike<any>
  team: Team
}

const TeamMenuItem: React.FC<TeamMenuItemProps> = ({
  active,
  disabled,
  onClick,
  team: { id, name },
}) => {
  const handleClick = () => onClick(id)

  return (
    <MenuItem
      className="items-center"
      disabled={disabled}
      icon={<TeamIcon className="w-4 h-4" teamId={id} />}
      labelElement={
        active ? (
          <Check className="text-icon-secondary-light dark:text-icon-secondary-dark w-4 h-4" />
        ) : undefined
      }
      onClick={handleClick}
      text={name}
    />
  )
}
