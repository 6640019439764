import { IMenuDividerProps, IMenuItemProps } from '@blueprintjs/core'
import compact from 'lodash.compact'

import { assignLinearUser } from './linearContextMenu/assignLinearUser'
import { changeLinearCycle } from './linearContextMenu/changeLinearCycle'
import { changeLinearLabels } from './linearContextMenu/changeLinearLabels'
import { changeLinearPriority } from './linearContextMenu/changeLinearPriority'
import { changeLinearProject } from './linearContextMenu/changeLinearProject'
import { changeLinearState } from './linearContextMenu/changeLinearState'
import { openInLinear } from './linearContextMenu/openInLinear'

import { Item } from 'canvas-shared/lib/types/Item.types'
import {
  LinearCycle,
  LinearLabel,
  LinearPriority,
  LinearProject,
  LinearState,
  LinearUser,
} from 'canvas-shared/lib/types/connections/Linear.types'
import { UUID } from '../../../types'

interface LinearContextMenuProps {
  linearAddLabel: (ids: UUID[], label: LinearLabel) => Promise<void>
  linearAssignUser: (ids: UUID[], user: LinearUser) => Promise<void>
  linearCards: Item.LinearCard[]
  linearChangeState: (ids: UUID[], state: LinearState) => Promise<void>
  linearRemoveLabel: (ids: UUID[], label: LinearLabel) => Promise<void>
  linearSetCycle: (ids: UUID[], cycle: LinearCycle) => Promise<void>
  linearSetPriority: (ids: UUID[], priority: LinearPriority) => Promise<void>
  linearSetProject: (ids: UUID[], project: LinearProject) => Promise<void>
  linearUnsetCycle: (ids: UUID[]) => Promise<void>
  linearUnsetProject: (ids: UUID[]) => Promise<void>
}

export const LinearContextMenu = ({
  linearAddLabel,
  linearAssignUser,
  linearCards,
  linearChangeState,
  linearRemoveLabel,
  linearSetCycle,
  linearSetPriority,
  linearSetProject,
  linearUnsetCycle,
  linearUnsetProject,
}: LinearContextMenuProps) => {
  const activeLinearCards = linearCards.filter((c) => !c.loading)

  const menuItems: (IMenuDividerProps | IMenuItemProps)[] = compact([
    assignLinearUser({ cards: activeLinearCards, linearAssignUser }),
    changeLinearState({ cards: activeLinearCards, linearChangeState }),
    changeLinearCycle({
      cards: activeLinearCards,
      linearSetCycle,
      linearUnsetCycle,
    }),
    changeLinearLabels({
      cards: activeLinearCards,
      linearAddLabel,
      linearRemoveLabel,
    }),
    changeLinearPriority({
      cards: activeLinearCards,
      linearSetPriority,
    }),
    changeLinearProject({
      cards: activeLinearCards,
      linearSetProject,
      linearUnsetProject,
    }),
  ])

  if (linearCards.length === 1 && activeLinearCards.length === 1) {
    menuItems.push(openInLinear({ card: activeLinearCards[0] }))
  }

  return menuItems
}
