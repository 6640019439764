/*---- External -------------------------------------------------------------*/

import { SchemaFirestore, SchemaRTDB } from '../../../types/redux'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

export const generateStoreAs = (
  key: keyof SchemaRTDB | keyof SchemaFirestore,
  containerId: string
) => `${key}-${containerId}`
