"use strict";
/*---- External -------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.isVoteableItem = exports.isGroup = exports.isConvertibleItem = exports.isEstimateableItem = exports.isLineItem = exports.isTextItem = exports.isOptionalBackgroundColorItem = exports.isBackgroundColorItem = void 0;
/*---- Qualdesk -------------------------------------------------------------*/
const Item_types_1 = require("../types/Item.types");
/*---------------------------------------------------------------------------*/
const BackgroundColorItemTypes = [
    Item_types_1.ItemType.Rectangle,
    Item_types_1.ItemType.Sticky,
    Item_types_1.ItemType.Text,
];
const OptionalBackgroundColorItemTypes = [Item_types_1.ItemType.Card];
const isBackgroundColorItem = (item) => BackgroundColorItemTypes.includes(item.type);
exports.isBackgroundColorItem = isBackgroundColorItem;
const isOptionalBackgroundColorItem = (item) => OptionalBackgroundColorItemTypes.includes(item.type);
exports.isOptionalBackgroundColorItem = isOptionalBackgroundColorItem;
const TextItemTypes = [Item_types_1.ItemType.Rectangle, Item_types_1.ItemType.Text];
const isTextItem = (item) => TextItemTypes.includes(item.type);
exports.isTextItem = isTextItem;
const LineItemTypes = [Item_types_1.ItemType.Line];
const isLineItem = (item) => LineItemTypes.includes(item.type);
exports.isLineItem = isLineItem;
const EstimateableItemTypes = [
    Item_types_1.ItemType.Card,
    Item_types_1.ItemType.JiraCard,
    Item_types_1.ItemType.TrelloCard,
];
const isEstimateableItem = (item) => EstimateableItemTypes.includes(item.type);
exports.isEstimateableItem = isEstimateableItem;
const ConvertibleItemTypes = [
    Item_types_1.ItemType.Sticky,
    Item_types_1.ItemType.Card,
    Item_types_1.ItemType.JiraCard,
    Item_types_1.ItemType.TrelloCard,
    Item_types_1.ItemType.GoogleSheetsCard,
    Item_types_1.ItemType.LinearCard,
];
const VoteableItemTypes = ConvertibleItemTypes;
const isConvertibleItem = (item) => ConvertibleItemTypes.includes(item.type);
exports.isConvertibleItem = isConvertibleItem;
const isGroup = (item) => item.type === Item_types_1.ItemType.Group;
exports.isGroup = isGroup;
const isVoteableItem = (item) => VoteableItemTypes.includes(item.type);
exports.isVoteableItem = isVoteableItem;
