/*---- External -------------------------------------------------------------*/

import React, { useMemo, useState, useCallback, useRef } from 'react'

import times from 'lodash.times'
import classNames from 'classnames'
import { useHistory, useLocation } from 'react-router-dom'
import { useAsync, useAsyncEffect } from '@react-hook/async'

import { Classes, IPanelProps } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { functions } from '../../../config/firebase'

import { useTypedParams } from '../../../hooks/helpers/useTypedParams'
import { useStatusReporter } from '../../../hooks/useStatusReporter'
import { usePartialMatchFilter } from '../../../hooks/usePartialMatchFilter'

import { RadioSelect } from '../../utilities/RadioSelect'
import { NoResults } from '../../utilities/NoResults'
import { SearchInputGroup } from '../../utilities/SearchInputGroup'
import { IssueBrowserImportOptions } from '../IssueBrowserImportOptions'
import { SidebarPanel } from '../SidebarPanel'
import { LinearPreviewCard } from './LinearPreviewCard'
import { ResultSorter } from './linearContentBrowser/ResultSorter'
import { Footer } from '../Footer'

import linearIcon from '../../../assets/integrations/linear/linear-icon.svg'

import { LinearTeam } from 'canvas-shared/lib/types/connections/Linear.types'
import { PreviewItemWithBoardPresence } from 'canvas-shared/lib/types/PreviewItem.types'

/*---------------------------------------------------------------------------*/

interface Nodes<T> {
  nodes: T[]
}

interface FetchPreviewContentResponse {
  data: {
    selectedTeamId: string | undefined
    selectedTeamKey: string | undefined
    teams: { nodes: LinearTeam[] }
    issues: PreviewItemWithBoardPresence.LinearCard[]
  }
}

const fetchPreviewContent: (params: {
  selectedTeamKey?: string
  containerId: string | undefined
}) => Promise<FetchPreviewContentResponse> = functions.httpsCallable(
  'https-linearFetchPreviewContent'
)
const fetchContent = functions.httpsCallable('https-linearFetchContent')

export const LinearContentBrowser: React.FC<IPanelProps> = (panelProps) => {
  const { containerId } = useTypedParams()
  const history = useHistory()
  const { search } = useLocation()
  const { reportProgress, reportSuccess, reportError } = useStatusReporter()

  const selectedTeamId = useRef<string | undefined>()

  const [selectedIssueIds, setSelectedIssueIds] = useState<Set<string>>(
    new Set()
  )
  const [teams, setTeams] = useState<Nodes<LinearTeam>>({ nodes: [] })
  const [issues, setIssues] = useState<
    PreviewItemWithBoardPresence.LinearCard[]
  >([])
  const [freeTextQuery, setFreeTextQuery] = useState('')
  const [sortedIssues, setSortedCards] = useState<
    PreviewItemWithBoardPresence.LinearCard[]
  >(issues)

  const [{ status: importStatus }, importIssues] = useAsync(async () => {
    try {
      reportProgress('Importing Linear content…')

      await fetchContent({
        containerId,
        selectedTeamId: selectedTeamId.current,
        selectedIssueIds: Array.from(selectedIssueIds),
      })

      reportSuccess('Linear content imported successfully', { timeout: 2000 })
      const issuesWithUpdatedBoardPresence = issues.map((i) => ({
        ...i,
        presentOnBoard: selectedIssueIds.has(i.data.id) || i.presentOnBoard,
      }))
      setIssues(issuesWithUpdatedBoardPresence)
      setSelectedIssueIds(new Set())
    } catch (error) {
      reportError(error, 'Failed to get Linear issues')
    }
  })

  const [
    { status: fetchStatus, cancel: cancelFetchIssues },
    fetchIssues,
  ] = useAsync(async (selectedTeamKey) => {
    try {
      const {
        data: {
          teams,
          issues,
          selectedTeamId: teamId,
          selectedTeamKey: teamKey,
        },
      } = await fetchPreviewContent({ containerId, selectedTeamKey })

      selectedTeamId.current = teamId
      if (!selectedTeamKey) history.replace(`?team=${teamKey}`)
      setTeams(teams)
      setIssues(issues)
      setSelectedIssueIds(new Set())
    } catch (error) {
      reportError(error, 'Failed to get Linear issues')
    }
  })

  const freeTextFilteredIssues = usePartialMatchFilter<
    PreviewItemWithBoardPresence.LinearCard
  >(sortedIssues, freeTextQuery, ({ data }) =>
    [
      data.identifier,
      data.title,
      data.state.name,
      data.assignee?.name,
      data.labels.nodes.map((l) => l.name).join(' '),
      data.cycle?.name,
      data.priorityLabel,
    ].join(' ')
  )

  const selectedTeamKey = useMemo(
    () => new URLSearchParams(search).get('team') ?? undefined,
    [search]
  )
  const selectedTeam = useMemo(
    () => teams.nodes.find((team) => team.key === selectedTeamKey),
    [teams.nodes, selectedTeamKey]
  )
  const teamOptions = useMemo(() => {
    if (teams.nodes.length > 0) {
      return teams.nodes.map((team) => ({
        value: team.key,
        label: (
          <span className="flex items-center space-x-4">
            <span className="flex-grow">{team.name}</span>
            <span className="text-text-secondary-light dark:text-text-secondary-dark font-normal">
              {team.key}
            </span>
          </span>
        ),
      }))
    } else {
      return [
        {
          value: 'loading',
          label: <span className={Classes.SKELETON}>Loading…</span>,
        },
      ]
    }
  }, [teams])

  const onChangeTeam = useCallback(
    (teamKey) => {
      history.replace(`?team=${teamKey}`)
      cancelFetchIssues()
      fetchIssues(teamKey)
    },
    [history, cancelFetchIssues, fetchIssues]
  )

  const onChangeIssue = useCallback(
    (issue) => {
      const newSelectedIssueIds = new Set(selectedIssueIds)
      if (newSelectedIssueIds.has(issue.data.id)) {
        newSelectedIssueIds.delete(issue.data.id)
      } else {
        newSelectedIssueIds.add(issue.data.id)
      }
      setSelectedIssueIds(newSelectedIssueIds)
    },
    [selectedIssueIds]
  )

  const onSelectAll = useCallback(() => {
    const newSelectedIssueIds = new Set(
      freeTextFilteredIssues.map((issue) => issue.data.id)
    )
    setSelectedIssueIds(newSelectedIssueIds)
  }, [freeTextFilteredIssues])

  const onDeselectAll = useCallback(() => {
    setSelectedIssueIds(new Set())
  }, [])

  const onFreeTextQueryChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFreeTextQuery(event.target.value)
    },
    []
  )

  const onFreeTextQueryClear = useCallback(() => {
    setFreeTextQuery('')
  }, [])

  useAsyncEffect(() => fetchIssues(selectedTeamKey), [])

  return (
    <SidebarPanel
      {...panelProps}
      text="Linear"
      iconUrl={linearIcon}
      iconAltText="Linear icon"
      footer={
        freeTextFilteredIssues.length > 0 && (
          <Footer>
            <IssueBrowserImportOptions
              importIssues={importIssues}
              loading={importStatus === 'loading'}
              onDeselectAll={onDeselectAll}
              onSelectAll={onSelectAll}
              selectedIssuesCount={selectedIssueIds.size}
              availableIssuesCount={freeTextFilteredIssues.length}
            />
          </Footer>
        )
      }
    >
      <div className="flex-grow overflow-y-auto">
        <div className="pt-6 px-3">
          <RadioSelect
            selectedValue={
              selectedTeamKey && selectedTeam ? selectedTeamKey : 'loading'
            }
            options={teamOptions}
            onChange={onChangeTeam}
          />
        </div>
        <div className="pt-5 px-3">
          <SearchInputGroup
            value={freeTextQuery}
            onChange={onFreeTextQueryChange}
            onClear={onFreeTextQueryClear}
            placeholder={
              selectedTeam ? `Find in ‘${selectedTeam.name}’…` : 'Find…'
            }
            changeBackgroundOnFocus={false}
            small
          />
        </div>
        <div className="text-text-secondary-light dark:text-text-secondary-dark flex items-center p-6 pb-3 pr-3 text-sm space-x-2">
          <div className="flex-grow">
            {fetchStatus === 'loading' ? (
              <>Loading issues…</>
            ) : (
              `${freeTextFilteredIssues.length} ${
                freeTextFilteredIssues.length === 1 ? 'issue' : 'issues'
              }`
            )}
          </div>
          <ResultSorter setSortedCards={setSortedCards} cards={issues} />
        </div>
        {fetchStatus !== 'loading' && freeTextFilteredIssues.length === 0 && (
          <NoResults className="my-10" title="No issues found" />
        )}
        <ul className="p-3 pb-6 pt-0 space-y-3">
          {fetchStatus === 'loading' &&
            times(3).map((i) => (
              <li key={i} className={classNames('h-20', Classes.SKELETON)} />
            ))}
          {fetchStatus !== 'loading' &&
            freeTextFilteredIssues.map((issue) => (
              <li key={issue.id}>
                <LinearPreviewCard
                  checked={selectedIssueIds.has(issue.data.id)}
                  onChange={onChangeIssue}
                  issue={issue}
                />
              </li>
            ))}
        </ul>
      </div>
    </SidebarPanel>
  )
}
