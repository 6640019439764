/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { generatePseudoDOMRect } from 'canvas-shared/lib/helpers/generatePseudoDOMRect'
import { PseudoDOMRect } from 'canvas-shared/lib/types/Position.types'

/*---------------------------------------------------------------------------*/

export const padRect = (rect: PseudoDOMRect, padding: number): PseudoDOMRect =>
  generatePseudoDOMRect(
    rect.x - padding,
    rect.y - padding,
    rect.width + padding * 2,
    rect.height + padding * 2
  )
