/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { generateUserPath } from './userPaths'

import { UUID } from '../../types'

/*---------------------------------------------------------------------------*/

export const generateUserSelectionPath = (containerId: UUID, uid: string) =>
  `${generateUserPath(containerId, uid)}/selection`
