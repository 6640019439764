/*---- External -------------------------------------------------------------*/

import { useRef, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { IToastProps } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  dismissToastAction,
  showToastAction,
  updateToastAction,
} from '../redux/actionCreators/toast'

/*---------------------------------------------------------------------------*/

export const usePersistentToast = () => {
  const dispatch = useDispatch()
  const toastKey = useRef<string>()

  const showOrUpdate = useCallback(
    async (toast: IToastProps) => {
      if (toastKey.current) {
        dispatch(
          updateToastAction({
            id: toastKey.current,
            toast,
          })
        )
      } else {
        // TODO: Make dispatch properly typed
        const result = await dispatch<any>(showToastAction(toast))
        toastKey.current = result.payload.id
      }
    },
    [dispatch]
  )

  const dismiss = useCallback(() => {
    if (toastKey.current) {
      dispatch(dismissToastAction(toastKey.current))
      toastKey.current = undefined
    }
  }, [dispatch])

  return { showOrUpdate, dismiss }
}
