export function firestoreDataWithIds<T extends { id: string }>(
  data: Record<string, Omit<T, 'id'>> | null | undefined
) {
  return !!data
    ? Object.keys(data).reduce((res: Record<string, T>, id) => {
        if (!data[id]) {
          return res
        }

        const item = {
          ...data[id],
          id,
        } as T

        return {
          ...res,
          [id]: item,
        }
      }, {})
    : null
}
