/*---- External -------------------------------------------------------------*/

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { createItemEditsSelector } from '../../selectors/board/edits'

import { UUID } from '../../../types'

/*---------------------------------------------------------------------------*/

export const useItemEdits = (itemId: UUID) =>
  useSelector(createItemEditsSelector(itemId))
