"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinearPriority = void 0;
var LinearPriority;
(function (LinearPriority) {
    LinearPriority[LinearPriority["NoPriority"] = 0] = "NoPriority";
    LinearPriority[LinearPriority["Urgent"] = 1] = "Urgent";
    LinearPriority[LinearPriority["High"] = 2] = "High";
    LinearPriority[LinearPriority["Medium"] = 3] = "Medium";
    LinearPriority[LinearPriority["Low"] = 4] = "Low";
})(LinearPriority = exports.LinearPriority || (exports.LinearPriority = {}));
