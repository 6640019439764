/*---- External -------------------------------------------------------------*/

import { useCallback, useMemo } from 'react'

import { useDispatch } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { editRemoveAction, editSetOneAction } from '../../actionCreators/edits'

import { UUID } from '../../../types'

/*---------------------------------------------------------------------------*/

interface EditsActions {
  addEdit: (itemId: UUID) => void
  removeEdit: (itemId: UUID) => void
}

export const useEditsActions = (): EditsActions => {
  const dispatch = useDispatch()

  const addEdit = useCallback(
    (itemId: UUID) =>
      dispatch(
        editSetOneAction({
          key: itemId,
          value: {
            editing: true,
          },
        })
      ),
    [dispatch]
  )
  const removeEdit = useCallback(
    (itemId: UUID) => dispatch(editRemoveAction(itemId)),
    [dispatch]
  )

  return useMemo(
    () => ({
      addEdit,
      removeEdit,
    }),
    [addEdit, removeEdit]
  )
}
