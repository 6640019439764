/*---- External -------------------------------------------------------------*/

import { useCallback, useMemo } from 'react'

import { useFirebase } from 'react-redux-firebase'
import { DataSnapshot } from '@firebase/database-types'

import { DateTime } from 'luxon'

/*---- Qualdesk -------------------------------------------------------------*/

import { generateTimerPath } from '../../paths/timerPaths'

import { useContainerId } from '../../../contexts/Container'

import { Timer } from '../../../types'

/*---------------------------------------------------------------------------*/

interface TimerActions {
  setCountdownTimer: (seconds: number) => Promise<DataSnapshot>
  startTimer: () => Promise<DataSnapshot>
  stopTimer: () => Promise<DataSnapshot>
}

export const useTimerActions = (): TimerActions => {
  const firebase = useFirebase()
  const containerId = useContainerId()

  const setTimer = useCallback(
    (t: Timer) => firebase.set(generateTimerPath(containerId), t),
    [containerId, firebase]
  )
  const stopTimer = useCallback(
    () => firebase.remove(generateTimerPath(containerId)),
    [containerId, firebase]
  )

  const setCountdownTimer = useCallback(
    (seconds: number) =>
      setTimer({
        countDown: true,
        referenceTime: DateTime.local().plus({ seconds }).toString(),
      }),
    [setTimer]
  )

  const startTimer = useCallback(
    () =>
      setTimer({
        countDown: false,
        referenceTime: DateTime.local().toString(),
      }),
    [setTimer]
  )

  return useMemo(
    () => ({
      setCountdownTimer,
      startTimer,
      stopTimer,
    }),
    [setCountdownTimer, startTimer, stopTimer]
  )
}
