/*---- External -------------------------------------------------------------*/

import React from 'react'

import { Button, Intent, Tooltip } from '@blueprintjs/core'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { Plus } from 'phosphor-react'

/*---------------------------------------------------------------------------*/

interface VoteCountButtonProps {
  itemClassName?: string
  voteCount: number
  toggleVote: () => void
}

const VoteCountButton: React.FC<VoteCountButtonProps> = ({
  itemClassName,
  voteCount,
  toggleVote,
}) => (
  <Button
    className={classNames('rounded-full', itemClassName)}
    icon={
      voteCount > 0 ? (
        <>{voteCount.toString()}</>
      ) : (
        <Plus size={12} weight="bold" />
      )
    }
    intent={Intent.PRIMARY}
    onClick={toggleVote}
    onPointerDown={(e) => {
      // Catch bubbling events to stop them reaching the canvas
      e.stopPropagation()
    }}
  />
)
interface Props {
  tooltipContent: JSX.Element | null
  itemClassName?: string
  voteCount: number
  toggleVote: () => void
}

const CanvasVoteCount: React.FC<Props> = ({
  tooltipContent,
  voteCount,
  itemClassName,
  toggleVote,
}) => {
  const voteCountButton = (
    <VoteCountButton
      itemClassName={itemClassName}
      voteCount={voteCount}
      toggleVote={toggleVote}
    />
  )

  if (!tooltipContent) {
    return voteCountButton
  }

  return (
    <Tooltip content={tooltipContent} disabled={voteCount < 1}>
      {voteCountButton}
    </Tooltip>
  )
}

export default CanvasVoteCount
