import { IMenuItemProps } from '@blueprintjs/core'

import getExternalItemUrl from '../../../../helpers/getExternalCardUrl'
import { Item } from 'canvas-shared/lib/types/Item.types'

interface OpenInLinearProps {
  card: Item.LinearCard
}

export const openInLinear = ({ card }: OpenInLinearProps): IMenuItemProps => {
  const url = getExternalItemUrl(card)
  return {
    text: 'Open in Linear',
    icon: 'link',
    href: url,
    target: '_blank',
  }
}
