/*---- External -------------------------------------------------------------*/

import { useMemo } from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { useBoard } from '../../redux/selectorHooks/board/useBoard'

import { dateTimeFromFirestoreTimestamp } from 'canvas-shared/lib/helpers/dateTimeFromFirestoreTimestamp'

/*---------------------------------------------------------------------------*/

export const UpdatedAt: React.FC<React.HTMLProps<HTMLDivElement>> = (props) => {
  const { updatedAt } = useBoard()
  const renderedTimestamp = useMemo(() => {
    const dt = dateTimeFromFirestoreTimestamp(updatedAt)
    const relative = dt?.toRelative({ padding: 999, style: 'short' })
    return !!relative ? relative.replace(' ago', '').replace(' ', ' ') : null
  }, [updatedAt])
  return !!renderedTimestamp ? <div {...props}>{renderedTimestamp}</div> : null
}
