/*---- External -------------------------------------------------------------*/

import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import rafSchd from 'raf-schd'

/*---- Qualdesk -------------------------------------------------------------*/

import { preventDefault } from '../../helpers/preventDefault'
import { getScrollDimensionsAndOffsetsFromRefs } from '../../helpers/getScrollDimensionsAndOffsetsFromRefs'

import { useCanvasWindowContext } from '../../contexts/CanvasWindow'

import { scrollDimensionsSetAllAction } from '../../redux/actionCreators/scroll'

/*---------------------------------------------------------------------------*/

export const useScrollEventHandlers = () => {
  const {
    canvasRef,
    canvasPageRef,
    canvasViewControlsRef,
  } = useCanvasWindowContext()

  const dispatch = useDispatch()

  const updateDimensions = () => {
    if (canvasRef.current && canvasPageRef.current) {
      const { newScrollDimensions } = getScrollDimensionsAndOffsetsFromRefs({
        canvasRefCurrent: canvasRef.current,
        canvasPageRefCurrent: canvasPageRef.current,
      })

      dispatch(scrollDimensionsSetAllAction(newScrollDimensions))
    }
  }

  const { pathname } = useLocation()
  useEffect(updateDimensions, [canvasPageRef, canvasRef, dispatch, pathname])

  const schedule = rafSchd(updateDimensions)

  const manageEventListeners = () => {
    var currentCanvas = canvasRef.current
    var currentViewControls = canvasViewControlsRef.current

    if (currentCanvas && currentViewControls) {
      window.addEventListener('resize', schedule)
      window.addEventListener('focus', schedule)
      window.addEventListener('DOMContentLoaded', schedule)
      currentCanvas.addEventListener('touchmove', preventDefault, {
        passive: false,
      })
      currentViewControls.addEventListener('touchmove', preventDefault, {
        passive: false,
      })
    }

    return () => {
      if (currentCanvas && currentViewControls) {
        window.removeEventListener('resize', schedule)
        window.removeEventListener('focus', schedule)
        window.removeEventListener('DOMContentLoaded', schedule)
        currentCanvas.removeEventListener('touchmove', preventDefault)
        currentViewControls.addEventListener('touchmove', preventDefault, {
          passive: false,
        })
      }
    }
  }

  useEffect(manageEventListeners, [canvasRef, canvasViewControlsRef, schedule])
}
