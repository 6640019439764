/*---- External -------------------------------------------------------------*/

import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { FormGroup, InputGroup, Intent } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { userProfilePicturePath } from 'canvas-shared/lib/paths/userProfilePicturePath'

import { useUserActions } from '../../../redux/actions/auth/useUserActions'
import {
  selectMyEmail,
  selectMyName,
  selectMyUid,
} from '../../../redux/selectors/auth/auth'

import { PictureChooser } from '../../pictureChooser/PictureChooser'
import UserIcon from '../../user/UserIcon'

import { SettingsPanelProps } from '../../../types/settings'

/*---------------------------------------------------------------------------*/

export const UserDetailsPanel: React.FC<SettingsPanelProps> = ({
  className,
  queueUpdate,
}) => {
  const {
    deleteUserPicture,
    setUserPicture,
    updateUserDetails,
  } = useUserActions()
  const uid = useSelector(selectMyUid)
  const email = useSelector(selectMyEmail)
  const name = useSelector(selectMyName)

  const [nameValue, setNameValue] = useState(name)

  const updateNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameValue(e.target.value)
  }

  const handleUpdate = () => {
    if (!!nameValue && nameValue !== name) {
      const f = () => updateUserDetails({ name: nameValue })
      if (queueUpdate) {
        queueUpdate(f)
      } else {
        f()
      }
    }
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    handleUpdate()
  }

  const storagePath = useMemo(() => userProfilePicturePath(uid), [uid])

  return (
    <div className={className}>
      <div className="flex flex-col space-y-4">
        <form onSubmit={handleSubmit}>
          <FormGroup
            className="m-0"
            label={<div className="mb-1 font-semibold">Full name</div>}
            labelFor="name-input"
          >
            <InputGroup
              id="name-input"
              intent={!!nameValue ? Intent.NONE : Intent.WARNING}
              large
              onChange={updateNameInput}
              onBlur={handleSubmit}
              placeholder="Name is required"
              value={nameValue}
            />
          </FormGroup>
        </form>
        <FormGroup
          className="m-0"
          label={<div className="mb-1 font-semibold">Email address</div>}
          labelFor="email-input"
        >
          <InputGroup disabled id="email-input" large value={email} />
        </FormGroup>
        <div className="pt-4">
          <PictureChooser
            deletePicture={deleteUserPicture}
            handleUploadComplete={setUserPicture}
            renderPicture={
              <UserIcon
                className="dark:bg-background-four-dark w-16 h-16"
                hideTooltip
                uid={uid}
              />
            }
            storagePath={storagePath}
            title="Profile picture"
          />
        </div>
      </div>
    </div>
  )
}
