/*---- External -------------------------------------------------------------*/

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Intent, Tag, Tooltip } from '@blueprintjs/core'
import { CheckCircle, WarningCircle } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import { isJiraBoardFeatureEnabledForItem } from '../../helpers/isJiraBoardFeatureEnabledForItem'

import { selectMyUid } from '../../redux/selectors/auth/auth'
import { selectInteractionMode } from '../../redux/selectors/board/interactionModes'
import { useItem } from '../../redux/selectorHooks/board/useItems'

import {
  itemsAddEstimateVoteAction,
  itemsRemoveEstimateVoteAction,
  itemsResolveEstimateAction,
} from '../../redux/actionCreators/itemData'

import { estimateValues } from '../../config/estimateValues'

import UserName from '../user/UserName'

import { Item, ItemType } from 'canvas-shared/lib/types/Item.types'
import { BoardFeatureId as JiraBoardFeatureId } from 'canvas-shared/lib/types/connections/JiraAPI.types'
import { JiraData } from 'canvas-shared/lib/types/item/JiraData.types'
import { UUID } from 'canvas-shared/lib/types'
import { Estimate, InteractionMode } from '../../types'

/*---------------------------------------------------------------------------*/

interface ItemEstimateProps {
  itemId: UUID
}

interface EstimateVoteUserNamesProps {
  estimateVotes: any
  value: number
}

const EstimateVoteUserNames: React.FC<EstimateVoteUserNamesProps> = ({
  estimateVotes,
  value,
}) => {
  const uids = Object.keys(estimateVotes || {}).filter(
    (uid) => estimateVotes[uid]?.value === value
  )

  return (
    <>
      {uids.map((uid) => (
        <UserName uid={uid} key={uid} />
      ))}
    </>
  )
}

export const EstimationValue: React.FC<ItemEstimateProps> = ({ itemId }) => {
  const item = useItem(itemId)
  const { type, data } = item as Item.EstimateableItem

  const estimate = data.estimate as Estimate
  if (!estimate) return null

  if (type === ItemType.JiraCard) {
    // this is the only system we synchronize estimates with
    const currentValue = estimate.value
    const externalStringValue = (data as JiraData).jira.estimate
    const externalValue = externalStringValue
      ? Number.parseFloat(externalStringValue)
      : undefined

    let tooltip = <></>
    if (currentValue === externalValue) {
      tooltip = (
        <Tooltip
          className="flex items-center"
          content="Estimate synchronized with external system"
        >
          <CheckCircle
            className="text-button-success-light dark:text-button-success-dark"
            size={16}
          />
        </Tooltip>
      )
    } else {
      tooltip = (
        <Tooltip
          className="flex items-center"
          content="Estimate not yet synchronized"
        >
          <WarningCircle
            className="text-button-warning-light dark:text-button-warning-dark"
            size={16}
          />
        </Tooltip>
      )
    }
    if (
      !isJiraBoardFeatureEnabledForItem(
        item as Item.JiraCard,
        JiraBoardFeatureId.Estimation
      )
    ) {
      tooltip = (
        <Tooltip
          className="flex items-center"
          content="Estimate can’t be sent to Jira because estimation is not configured for your board"
        >
          <WarningCircle
            className="text-button-warning-light dark:text-button-warning-dark"
            size={16}
          />
        </Tooltip>
      )
    }

    return (
      <div className="flex items-center h-full space-x-1">
        {tooltip}
        <Tag
          className="self-stretch px-4 text-base"
          minimal
          round
          intent="primary"
        >
          {estimate.value}
        </Tag>
      </div>
    )
  } else {
    return (
      <div className="flex items-center h-full space-x-1">
        <Tag
          className="self-stretch px-4 text-base"
          minimal
          round
          intent="primary"
        >
          {estimate.value}
        </Tag>
      </div>
    )
  }
}

export const EstimationControls: React.FC<ItemEstimateProps> = ({ itemId }) => {
  const item = useItem(itemId) as Item.EstimateableItem
  const uid = useSelector(selectMyUid)
  const interactionMode = useSelector(selectInteractionMode)
  const dispatch = useDispatch()

  const { data } = item as Item.EstimateableItem

  const isResolving = interactionMode === InteractionMode.ESTIMATE_RESOLUTION
  const hasVotesToShow = Object.keys(data.estimateVotes || {}).length > 0

  const isMyEstimateVote = (value: number) =>
    (data.estimateVotes || {})[uid as string]?.value === value

  const handleClick = (value: number) => {
    if (isResolving) {
      dispatch(itemsResolveEstimateAction({ ids: [item.id], value }))
    } else {
      if (isMyEstimateVote(value)) {
        dispatch(itemsRemoveEstimateVoteAction({ ids: [item.id] }))
      } else {
        dispatch(itemsAddEstimateVoteAction({ ids: [item.id], value }))
      }
    }
  }

  return (
    <div className="flex flex-row items-center pointer-events-auto space-x-1">
      {estimateValues.map((value) => {
        const voteCount = (Object.values(
          data.estimateVotes || {}
        ) as Estimate[]).filter((v) => v.value === value).length
        const isMinimal = isResolving
          ? voteCount < 1 && value !== data.estimate?.value
          : !isMyEstimateVote(value)
        const intent =
          isResolving && value === data.estimate?.value
            ? Intent.SUCCESS
            : Intent.PRIMARY

        return (
          <div className="flex flex-col space-y-1" key={value}>
            <Tag
              className="estimate text-center text-base leading-none"
              intent={intent}
              interactive
              minimal={isMinimal}
              onClick={() => handleClick(value)}
            >
              {value}
            </Tag>
            {isResolving && hasVotesToShow && voteCount > 0 && (
              <Tooltip
                content={
                  <EstimateVoteUserNames
                    value={value}
                    estimateVotes={data.estimateVotes}
                  />
                }
                disabled={!data.estimateVotes || voteCount < 1}
                hoverOpenDelay={500}
                targetClassName="w-full"
              >
                <Tag className="w-full text-center text-xs" minimal>
                  ×{voteCount}
                </Tag>
              </Tooltip>
            )}
          </div>
        )
      })}
    </div>
  )
}

const ItemEstimate: React.FC<ItemEstimateProps> = ({ itemId }) => {
  const interactionMode = useSelector(selectInteractionMode)

  const isEstimatingOrResolving = [
    InteractionMode.ESTIMATE_RESOLUTION,
    InteractionMode.ESTIMATION,
  ].includes(interactionMode)

  const {
    data: { estimate },
  } = useItem(itemId) as Item.EstimateableItem

  if (!estimate && !isEstimatingOrResolving) {
    return null
  }

  return (
    <div className="border-border-weak-light dark:border-border-weak-dark flex flex-row items-center mt-auto px-3 py-2 text-sm font-normal border-t space-x-1">
      <div>Estimate</div>
      {isEstimatingOrResolving ? (
        <EstimationControls itemId={itemId} />
      ) : (
        <EstimationValue itemId={itemId} />
      )}
    </div>
  )
}

export default ItemEstimate
