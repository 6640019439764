/*---- External -------------------------------------------------------------*/

import { combineReducers } from 'redux'

import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'
import { createReduxHistoryContext } from 'redux-first-history'
import { createBrowserHistory, LocationState } from 'history'
import { configureStore } from '@reduxjs/toolkit'

import * as Sentry from '@sentry/react'
import produce from 'immer'

/*---- Qualdesk -------------------------------------------------------------*/

import { boardReducer } from './reducers/board/boardReducer'
import { orgReducer } from './reducers/orgReducer'
import { paramsReducer } from './reducers/paramsReducer'
import { toastReducer } from './reducers/toastReducer'
import { epicMiddleware, rootEpic } from './epics'

import { UserOrigin } from 'canvas-shared/lib/types'
import { RootState } from '../types/redux'

/*---------------------------------------------------------------------------*/

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: () =>
    produce((state: RootState) => ({
      uid: state.firebase.auth.uid,
    })),
})

const {
  createReduxHistory,
  routerReducer,
  routerMiddleware,
} = createReduxHistoryContext({
  history: createBrowserHistory<LocationState>(),
})

export const createRootReducer = () =>
  combineReducers({
    router: routerReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    board: boardReducer,
    params: paramsReducer,
    toast: toastReducer,
    org: orgReducer,
  })

const initStore = () => {
  const store = configureStore({
    reducer: createRootReducer(),
    devTools: {
      actionsBlacklist: ['pointer/move'],
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        // Don’t check for serializability –
        // the Firebase auth object isn’t and
        // this throws an error
        serializableCheck: false,
      }).concat(routerMiddleware, epicMiddleware),
    enhancers: [sentryReduxEnhancer],
  })

  epicMiddleware.run(rootEpic)

  return store
}

export const store = initStore()

export type AppDispatch = typeof store.dispatch

export const history = createReduxHistory(store)

export const ORIGIN: UserOrigin = 'user/web'
