/*---- External -------------------------------------------------------------*/

import React from 'react'
import { useSelector } from 'react-redux'

import classNames from 'classnames'

import { Tooltip2 } from '@blueprintjs/popover2'

/*---- Qualdesk -------------------------------------------------------------*/

import { createTeamSelector } from '../../redux/selectors/auth/teams'

import { UUID } from 'canvas-shared/lib/types'
import { Team } from 'canvas-shared/lib/types/Team.types'

/*---------------------------------------------------------------------------*/

interface TeamIconProps {
  className?: string
  hideTooltip?: boolean
  teamId: UUID
  style?: React.CSSProperties
  onClick?: () => void
}

export const TeamIcon: React.FC<TeamIconProps> = ({
  hideTooltip,
  ...props
}) => {
  const teamSelector = createTeamSelector(props.teamId)
  const team = useSelector(teamSelector)

  if (!team) {
    return null
  }

  const innerProps = {
    ...props,
    team,
  }

  if (hideTooltip) {
    return <TeamIconInner {...innerProps} />
  }

  return (
    <Tooltip2
      popoverClassName="tooltip"
      content={team.name}
      hoverOpenDelay={300}
      targetTagName="div"
    >
      <TeamIconInner {...innerProps} />
    </Tooltip2>
  )
}

interface TeamIconInnerProps extends TeamIconProps {
  team: Team
}

const TeamIconInner: React.FC<TeamIconInnerProps> = ({
  className,
  onClick,
  style,
  team,
}) => {
  const name = team.name
  const color = team.color || 'gray'

  const isButton = !!onClick

  return (
    <div
      className={classNames(
        `bg-${color}-300`,
        `text-${color}-700`,
        'flex flex-shrink-0 items-center justify-center font-bold rounded-sm select-none overflow-hidden',
        className,
        { 'cursor-pointer': isButton }
      )}
      onClick={onClick}
      role={classNames('listitem', { button: isButton })}
      aria-label={name}
    >
      {team.pictureUrl ? <img src={team.pictureUrl} alt={name} /> : ''}
    </div>
  )
}

export const MIN_TEAM_ICON_DIMENSION = 16
