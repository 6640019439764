/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import type { BasicPointerEvent } from 'canvas-shared/lib/types/pointerEvents.types'
import type { RootState } from '../../../../types/redux'

/*---------------------------------------------------------------------------*/

export const pointerEventInDragHandle = (
  _state: RootState,
  pointer: BasicPointerEvent
) => {
  const target = pointer.target as HTMLElement

  const handleId = target.dataset['dragHandleId']

  if (handleId) {
    const top = handleId.split('-')[1] === 'top'
    const left = handleId.split('-')[2] === 'left'
    const start = handleId.split('-')[1] === 'start'
    const end = handleId.split('-')[1] === 'end'

    return { top, left, start, end }
  } else {
    return undefined
  }
}
