/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

export const Card: React.FC<React.HTMLProps<HTMLDivElement>> = ({
  children,
  ...divProps
}) => (
  <div
    className="bg-background-five-light dark:bg-background-five-dark border-border-weak-light dark:border-border-weak-dark flex flex-col flex-grow w-full h-full border rounded-xl overflow-hidden"
    {...divProps}
  >
    {children}
  </div>
)
