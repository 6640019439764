/*---- External -------------------------------------------------------------*/

import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Divider, Tooltip } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'

import { BaseEmoji, Emoji, EmojiData, Picker } from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css'

/*---- Qualdesk -------------------------------------------------------------*/

import CanvasVoteCount from './CanvasVoteCount'
import { useClassNamesWithDarkMode } from '../../hooks/darkMode/useClassNamesWithDarkMode'

import {
  itemsAddEmojiAction,
  itemsAddVoteAction,
  itemsRemoveEmojiAction,
  itemsRemoveVoteAction,
} from '../../redux/actionCreators/itemData'

import { selectMyUid } from '../../redux/selectors/auth/auth'
import { selectInteractionMode } from '../../redux/selectors/board/interactionModes'

import UserName from '../user/UserName'

import { ReactComponent as Smile } from '../../assets/icons/smile.svg'

import { InteractionMode } from '../../types'
import { Item } from 'canvas-shared/lib/types/Item.types'

/*---------------------------------------------------------------------------*/

interface Props {
  className?: string
  itemClassName?: string
  showButtons?: boolean
  item: Item.VoteableItem
}

const CanvasItemReactions: React.FC<Props> = React.memo(
  ({ className, itemClassName, showButtons, item }) => {
    const dispatch = useDispatch()
    const uid = useSelector(selectMyUid)
    const interactionMode = useSelector(selectInteractionMode)
    const { classNamesWithDarkMode } = useClassNamesWithDarkMode()

    const [emojiPickerOpen, setEmojiPickerOpen] = useState(false)

    const emojisToDisplay = useMemo(() => {
      if (item.data.emoji) {
        return Object.keys(item.data.emoji)
          .sort((a, b) => b.localeCompare(a))
          .map((id: string) => {
            let uids = !!item.data.emoji
              ? (item.data.emoji[id] as string[])
              : []
            if (interactionMode === InteractionMode.VOTING) {
              uids = uids.filter((u) => u === uid)
            }

            return {
              id,
              count: uids.length,
              uids,
            }
          })
          .filter(({ count }) => count > 0)
      } else {
        return []
      }
    }, [interactionMode, item, uid])

    const votes = useMemo(() => {
      if (item.data.votes) {
        if (interactionMode === InteractionMode.VOTING) {
          return item.data.votes?.filter((u: string) => u === uid)
        } else {
          return item.data.votes
        }
      } else {
        return []
      }
    }, [interactionMode, item, uid])

    const toggleEmoji = useCallback(
      (id: string) => {
        if (item.data.emoji && item.data.emoji[id]?.includes(uid)) {
          dispatch(itemsRemoveEmojiAction({ ids: [item.id], emojiId: id }))
        } else {
          dispatch(itemsAddEmojiAction({ ids: [item.id], emojiId: id }))
        }
      },
      [dispatch, item.data.emoji, item.id, uid]
    )

    const handleEmojiSelect = useCallback(
      (emoji: EmojiData) => {
        setEmojiPickerOpen(false)
        if (emoji.id) {
          toggleEmoji(emoji.id)
        }
      },
      [toggleEmoji]
    )

    const toggleVote = useCallback(() => {
      if (item.data.votes?.includes(uid as string)) {
        dispatch(itemsRemoveVoteAction({ ids: [item.id] }))
      } else {
        dispatch(itemsAddVoteAction({ ids: [item.id] }))
      }
    }, [dispatch, item.data.votes, item.id, uid])

    const openEmojiPicker = () => {
      setEmojiPickerOpen(true)
    }

    const renderButton = useCallback(
      (
        buttonProps: { id: string; count: number; uids: string[] },
        key: number
      ) => {
        const { id, count, uids } = buttonProps

        const handleClick = () => {
          toggleEmoji(id)
        }

        return (
          <Tooltip
            content={
              <div className="flex flex-col space-y-1">
                {uids.map((uid, i) => (
                  <UserName uid={uid} key={i} />
                ))}
              </div>
            }
            key={key}
            hoverOpenDelay={500}
            openOnTargetFocus={false}
          >
            <Button
              className={classNamesWithDarkMode(
                'px-2 py-1 rounded-full',
                itemClassName
              )}
              icon={
                <>
                  <Emoji emoji={{ id } as BaseEmoji} size={20} />
                  {count > 1 && (
                    <span className="ml-1/2 text-text-secondary-light dark:text-text-secondary-dark mr-1 font-semibold">
                      {count}
                    </span>
                  )}
                </>
              }
              onClick={handleClick}
              onPointerDown={(e) => {
                // Catch bubbling events to stop them reaching the canvas
                e.stopPropagation()
              }}
              role="button"
            />
          </Tooltip>
        )
      },
      [classNamesWithDarkMode, itemClassName, toggleEmoji]
    )

    const tooltipContent = useCallback(() => {
      if (!votes || votes.length === 0) {
        return null
      }

      return (
        <div className="flex flex-col space-y-1">
          {Object.values(votes as string[]).map((uid, i) => (
            <UserName uid={uid} key={i} />
          ))}
        </div>
      )
    }, [votes])

    const hasReactionsToDisplay = useMemo(
      () => emojisToDisplay.length > 0 || votes.length > 0,
      [emojisToDisplay.length, votes.length]
    )

    if (
      !showButtons &&
      !hasReactionsToDisplay &&
      interactionMode !== InteractionMode.VOTING
    ) {
      return null
    }

    return (
      <div
        className={classNamesWithDarkMode(
          'flex space-x-1',
          'select-none',
          className
        )}
      >
        {showButtons && (
          <Popover2
            isOpen={emojiPickerOpen}
            onInteraction={(nextState: boolean) => nextState}
            content={
              <Picker
                color="#3B82F6"
                onSelect={handleEmojiSelect}
                showPreview={false}
                showSkinTones={false}
              />
            }
          >
            <Button
              icon={<Smile className="bp3-icon" />}
              minimal
              onClick={openEmojiPicker}
            />
          </Popover2>
        )}
        <div className="flex space-x-1">
          {showButtons && hasReactionsToDisplay && <Divider />}
          {emojisToDisplay.map(renderButton)}
          {(votes.length > 0 ||
            interactionMode === InteractionMode.VOTING ||
            showButtons) && (
            <CanvasVoteCount
              tooltipContent={tooltipContent()}
              itemClassName={itemClassName}
              voteCount={votes.length}
              toggleVote={toggleVote}
            />
          )}
        </div>
      </div>
    )
  }
)

export default CanvasItemReactions
