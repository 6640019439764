"use strict";
/*---- External -------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShareSetting = void 0;
var ShareSetting;
(function (ShareSetting) {
    ShareSetting["ANY"] = "any";
    ShareSetting["DOMAIN"] = "domain";
    ShareSetting["INVITATION"] = "invitation";
})(ShareSetting = exports.ShareSetting || (exports.ShareSetting = {}));
