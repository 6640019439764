/*---- External -------------------------------------------------------------*/

import { Button, Classes, NonIdealState } from '@blueprintjs/core'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { ReactComponent as BrowserFace } from '../../assets/icons/browser-face.svg'

import { useSystemDarkMode } from '../../hooks/darkMode/useSystemDarkMode'
import { useBrowserCheckDismissed } from '../../hooks/system/useBrowserCheck'

/*---------------------------------------------------------------------------*/

export const BrowserWarning: React.FC = () => {
  const dark = useSystemDarkMode()

  return (
    <div className={classNames({ [Classes.DARK]: dark, dark }, 'h-full')}>
      <NonIdealState
        className="bg-background-app-light dark:bg-background-app-dark"
        icon={<BrowserFace className="w-24 h-24" />}
        title="Your browser isn’t compatible with Qualdesk"
        description={
          <div className="leading-relaxed">
            For best results, please use a recent version of
            <br />
            <a href="https://www.google.com/chrome/">Chrome</a>,{' '}
            <a href="https://www.microsoft.com/edge">Edge</a>,{' '}
            <a href="https://www.mozilla.org/firefox">Firefox</a>,{' '}
            <a href="https://www.apple.com/safari/">Safari</a> or{' '}
            <a href="https://www.opera.com">Opera</a>
          </div>
        }
        action={<DismissButton />}
      />
    </div>
  )
}

const DismissButton: React.FC = () => {
  const [, setDismissed] = useBrowserCheckDismissed()

  const handleClick = () => {
    setDismissed(true)
  }

  return <Button onClick={handleClick} text="Proceed anyway" />
}
