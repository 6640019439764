/*---- External -------------------------------------------------------------*/

import { createAction } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import { Params } from '../../router/routes'

/*---------------------------------------------------------------------------*/

export const setParamsAction = createAction<Params | undefined>('params/set')
