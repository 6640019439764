/*---- External -------------------------------------------------------------*/

import React, { useState, useCallback, useMemo } from 'react'

import times from 'lodash.times'
import { Classes } from '@blueprintjs/core'
import classNames from 'classnames'
import { useAsync, useAsyncEffect } from '@react-hook/async'

/*---- Qualdesk -------------------------------------------------------------*/

import { functions } from '../../../config/firebase'
import { useContainerId } from '../../../contexts/Container'
import { useStatusReporter } from '../../../hooks/useStatusReporter'
import { usePartialMatchFilter } from '../../../hooks/usePartialMatchFilter'

import { SearchInputGroup } from '../../utilities/SearchInputGroup'
import { IssueBrowserImportOptions } from '../IssueBrowserImportOptions'
import { SidebarPanel } from '../SidebarPanel'
import { ControlledPanelProps } from '../SidebarPanelStack'
import { JiraFiltersPopover } from './JiraFiltersPopover'
import { JiraPreviewCard } from './JiraPreviewCard'
import { Footer } from '../Footer'

import { JiraContentBrowserResultSummary } from './jiraContentBrowser/JiraContentBrowserResultSummary'
import { JiraContentBrowserResultSorter } from './jiraContentBrowser/JiraContentBrowserResultSorter'
import jiraIcon from '../../../assets/integrations/jira/jira-icon.svg'
import * as JiraAPI from 'canvas-shared/lib/types/connections/JiraAPI.types'

import {
  Filter,
  FilterType,
} from 'canvas-shared/lib/types/connections/Jira.types'

import { capitalizeFirstLetter } from 'canvas-shared/lib/helpers/capitalizeFirstLetter'
import { PreviewItemWithBoardPresence } from 'canvas-shared/lib/types/PreviewItem.types'

/*---------------------------------------------------------------------------*/

interface JiraContentBrowserProps extends ControlledPanelProps {
  board: JiraAPI.Board
  domain: string
}

const getPreviewContent = functions.httpsCallable('https-jiraGetPreviewContent')
const fetchContent = functions.httpsCallable('https-jiraFetchContent')
const defaultFilter: Filter = {
  type: FilterType.IncludeDone,
  id: 'includeDone',
  label: 'Include issues marked as done',
  jqlFragment: `statusCategory != 'Done'`,
  jqlDefaultInclude: true,
}

export const JiraContentBrowser: React.FC<JiraContentBrowserProps> = ({
  board,
  domain,
  ...panelProps
}) => {
  const containerId = useContainerId()
  const [backgroundDataRefreshing, setBackgroundDataRefreshing] = useState(true)
  const [dataNeedsRefresh, setDataNeedsRefresh] = useState(true)

  setTimeout(() => {
    if (backgroundDataRefreshing) {
      setDataNeedsRefresh(true)
    }
  }, 2000)

  const [previewCards, setPreviewCards] = useState<
    PreviewItemWithBoardPresence.JiraCard[]
  >([])
  const { reportProgress, reportSuccess, reportError } = useStatusReporter()

  const [appliedFilters, setAppliedFilters] = useState<Filter[]>([
    defaultFilter,
  ])
  const [freeTextQuery, setFreeTextQuery] = useState('')
  const [sortedCards, setSortedCards] = useState<
    PreviewItemWithBoardPresence.JiraCard[]
  >(previewCards)

  const [selectedIssueIds, setSelectedIssueIds] = useState<Set<string>>(
    new Set()
  )

  const freeTextFilteredPreviewCards = usePartialMatchFilter<
    PreviewItemWithBoardPresence.JiraCard
  >(sortedCards || [], freeTextQuery, ({ data }) =>
    [
      data.assigneeName,
      data.key,
      data.labels.join(' '),
      data.sprintName,
      data.title,
    ].join(' ')
  )

  const onFreeTextQueryChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFreeTextQuery(event.target.value)
    },
    []
  )

  const onFreeTextQueryClear = useCallback(() => {
    setFreeTextQuery('')
  }, [])

  const fetchData = async (filters: Filter[]) => {
    try {
      const {
        data: { previewItems: filteredCards, refreshingData },
      } = await getPreviewContent({
        jiraBoardId: board.id,
        filters: appliedFilters,
        domain: domain,
        containerId,
      })
      setPreviewCards(filteredCards)
      setBackgroundDataRefreshing(refreshingData)
      setDataNeedsRefresh(false)
    } catch (error) {
      reportError(error, 'Failed to get Jira issues')
    }
  }

  const [
    { status: fetchStatus, cancel: cancelFetchIssues },
    fetchIssues,
  ] = useAsync(fetchData)

  const [{ cancel: cancelRefreshIssues }, refreshIssues] = useAsync(fetchData)

  useAsyncEffect(() => fetchIssues(appliedFilters), [appliedFilters])
  useAsyncEffect(async () => {
    if (dataNeedsRefresh) {
      refreshIssues(appliedFilters)
    }
  }, [dataNeedsRefresh])

  const [{ status: importStatus }, importIssues] = useAsync(async () => {
    try {
      reportProgress('Importing Jira content…')

      await fetchContent({
        boardId: containerId,
        jiraBoardId: board.id,
        issueIds: Array.from(selectedIssueIds),
      })

      reportSuccess('Jira content imported successfully', { timeout: 2000 })
      const cardsWithUpdatedBoardPresence = previewCards.map((i) => ({
        ...i,
        presentOnBoard: selectedIssueIds.has(i.data.id) || i.presentOnBoard,
      }))
      setPreviewCards(cardsWithUpdatedBoardPresence)
      setSelectedIssueIds(new Set())
    } catch (error) {
      reportError(error, 'Failed to get Jira issues')
    }
  })

  const onChangeIssue = useCallback(
    (issue) => {
      const newSelectedIssueIds = new Set(selectedIssueIds)
      if (newSelectedIssueIds.has(issue.data.id)) {
        newSelectedIssueIds.delete(issue.data.id)
      } else {
        newSelectedIssueIds.add(issue.data.id)
      }
      setSelectedIssueIds(newSelectedIssueIds)
    },
    [selectedIssueIds]
  )

  const {
    location: { displayName },
  } = board

  const visibleFilters = useMemo(
    () =>
      appliedFilters.filter(
        (f) => f.type !== FilterType.Custom && !f.jqlDefaultInclude
      ),
    [appliedFilters]
  )

  const customJqlPresent = useMemo(
    () => !!appliedFilters.find((f) => f.type === FilterType.Custom),
    [appliedFilters]
  )

  const onSelectAll = useCallback(() => {
    const newSelectedIssueIds = new Set(
      freeTextFilteredPreviewCards.map((issue) => issue.data.id)
    )
    setSelectedIssueIds(newSelectedIssueIds)
  }, [freeTextFilteredPreviewCards])

  const onDeselectAll = useCallback(() => {
    setSelectedIssueIds(new Set())
  }, [])

  return (
    <SidebarPanel
      {...panelProps}
      text="Jira"
      iconUrl={jiraIcon}
      iconAltText="Jira icon"
      back={panelProps.closePanel}
      footer={
        freeTextFilteredPreviewCards.length > 0 && (
          <Footer>
            <IssueBrowserImportOptions
              importIssues={importIssues}
              loading={importStatus === 'loading'}
              onDeselectAll={onDeselectAll}
              onSelectAll={onSelectAll}
              selectedIssuesCount={selectedIssueIds.size}
              availableIssuesCount={freeTextFilteredPreviewCards.length}
            />
          </Footer>
        )
      }
    >
      <div className="flex-grow overflow-y-auto">
        <div className="pt-5 px-6 leading-normal">
          <h2 className="text-text-primary-light dark:text-text-primary-dark text-lg font-semibold">
            {displayName}
          </h2>
          <p className="text-text-secondary-light dark:text-text-secondary-dark text-sm">
            {capitalizeFirstLetter(domain)}
          </p>
        </div>
        <div className="flex items-center pl-5 pr-6 pt-3 space-x-1">
          <SearchInputGroup
            value={freeTextQuery}
            onChange={onFreeTextQueryChange}
            onClear={onFreeTextQueryClear}
            placeholder={`Find in ‘${displayName}’…`}
            changeBackgroundOnFocus={false}
            small
            fill
          />
          <JiraFiltersPopover
            board={board}
            setAppliedFilters={(filters) => {
              cancelFetchIssues()
              cancelRefreshIssues()
              setAppliedFilters(filters)
            }}
            appliedFilters={appliedFilters}
          />
        </div>
        <div className="flex items-center justify-between p-6 pb-3 pr-3">
          <JiraContentBrowserResultSummary
            customJqlPresent={customJqlPresent}
            visibleFilterCount={visibleFilters.length}
            backgroundDataRefreshing={backgroundDataRefreshing}
            loading={fetchStatus === 'loading'}
            resultCount={freeTextFilteredPreviewCards.length}
          />
          <div className="flex-shrink-0">
            <JiraContentBrowserResultSorter
              setSortedCards={setSortedCards}
              cards={previewCards}
            />
          </div>
        </div>
        {/* TODO: add blank state */}
        <ul className="p-3 pb-6 pt-0 space-y-3">
          {fetchStatus === 'loading' &&
            times(3).map((i) => (
              <li key={i} className={classNames('h-20', Classes.SKELETON)} />
            ))}
          {fetchStatus !== 'loading' &&
            freeTextFilteredPreviewCards.map((issue) => (
              <li key={issue.id}>
                <JiraPreviewCard
                  issue={issue}
                  checked={selectedIssueIds.has(issue.data.id)}
                  onChange={onChangeIssue}
                />
              </li>
            ))}
        </ul>
      </div>
    </SidebarPanel>
  )
}
