/*---- External -------------------------------------------------------------*/

import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import xor from 'lodash.xor'

/*---- Qualdesk -------------------------------------------------------------*/

import { UUID } from 'canvas-shared/lib/types'
import { positionInRect } from '../../hooks/board/pointerEvents/helpers/positionInRect'
import { ThunkAPI } from '../../types/redux'
import { positionsDataSelector } from '../selectors/board/positions'
import { selectSelectedItemIdsInActiveGesture } from '../selectors/board/selections'
import { convertItemIdsToSelectionRecord } from './helpers/convertItemIdsToSelectionRecord'

/*---------------------------------------------------------------------------*/

export const selectionAddAction = createAction<UUID>('selection/add')
export const selectionRemoveAction = createAction<UUID>('selection/remove')
export const selectionClearAction = createAction('selection/clear')

export const selectionToggleAction = createAsyncThunk<
  Record<UUID, true>,
  UUID[],
  ThunkAPI
>('selection/toggle', (toggleItemIds, { getState }) => {
  const state = getState()
  const initialItemIds = Object.keys(state.board.session.data.selection || {})

  const newItemIds = initialItemIds
    .filter((id) => !toggleItemIds.includes(id))
    .concat(toggleItemIds.filter((id) => !initialItemIds.includes(id)))

  return convertItemIdsToSelectionRecord(newItemIds)
})

export const selectionSetAction = createAction<UUID[]>('selection/set')

export const selectionSelectAllAction = createAsyncThunk<
  Record<UUID, true>,
  undefined,
  ThunkAPI
>('selection/selectAll', (_, { getState }) => {
  const state = getState()

  return convertItemIdsToSelectionRecord(
    Object.keys(positionsDataSelector(state))
  )
})

export const selectionExtendWithRectAction = createAsyncThunk<
  Record<UUID, true>,
  Record<'startX' | 'startY' | 'endX' | 'endY', number>,
  ThunkAPI
>('selection/extendWithRect', (rect, { getState }) => {
  const state = getState()
  const positions = positionsDataSelector(state)
  const selectedItemIdsInActiveGesture = selectSelectedItemIdsInActiveGesture(
    state
  )

  const itemIdsInSelectionRect = Object.values(positions)
    .filter((p) => positionInRect(p, rect))
    .map((p) => p.id)

  return convertItemIdsToSelectionRecord(
    xor(itemIdsInSelectionRect, selectedItemIdsInActiveGesture)
  )
})

export const selectionSetWithRectAction = createAsyncThunk<
  Record<UUID, true>,
  Record<'startX' | 'startY' | 'endX' | 'endY', number>,
  ThunkAPI
>('selection/setWithRect', (rect, { getState }) => {
  const positions = positionsDataSelector(getState())

  return convertItemIdsToSelectionRecord(
    Object.values(positions)
      .filter((p) => positionInRect(p, rect))
      .map((p) => p.id)
  )
})
