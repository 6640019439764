/*---- External -------------------------------------------------------------*/

import React from 'react'

import { Divider } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { SelectButton } from './canvasTools/SelectButton'
import { AddItemButton } from './canvasTools/AddItemButton'
import { AddTextItemButton } from './canvasTools/AddTextItemButton'
import { AddShapeItemButton } from './canvasTools/AddShapeItemButton'
import { TimerTools } from './canvasTools/TimerTools'
import { ReactionTools } from './canvasTools/ReactionTools'
import { SearchButton } from './canvasTools/SearchButton'

/*---------------------------------------------------------------------------*/

const CanvasTools: React.FC = () => (
  <div className="flex flex-row items-center space-x-4">
    <SelectButton />
    <Divider className="h-6" />
    <div className="flex flex-row space-x-0.5">
      <AddTextItemButton />
      <AddShapeItemButton />
      <AddItemButton />
    </div>
    <Divider className="h-6" />
    <div className="flex flex-row space-x-0.5">
      <TimerTools />
      <ReactionTools />
    </div>
    <Divider className="h-6" />
    <SearchButton />
  </div>
)

export default CanvasTools
