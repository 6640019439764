/*---- External -------------------------------------------------------------*/

import { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import mousetrap from 'mousetrap'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  HOTKEY_SELECT,
  HOTKEY_TEXT,
  HOTKEY_RECTANGLE,
  HOTKEY_LINE,
  HOTKEY_STICKY,
  HOTKEY_CARD,
  HOTKEY_SEARCH,
} from '../../config/hotkeys'

import { useSearchContext } from '../../contexts/Search'

import {
  copyAction,
  cutAction,
  pasteAction,
} from '../../redux/actionCreators/clipboard'

import { useSelectionActions } from '../../redux/actions/board/useSelectionActions'

import { useBoardToolbarHandlers } from './useBoardToolbarHandlers'

import {
  duplicateItemsAction,
  removeItemsAction,
} from '../../redux/actionCreators/items'
import { clearCursorModeAction } from '../../redux/actionCreators/cursorMode'
import { pendingCancelAllAction } from '../../redux/actionCreators/pending'
import {
  zoomInAction,
  zoomOutAction,
  zoomToDefaultAction,
} from '../../redux/actionCreators/zoom'

/*---------------------------------------------------------------------------*/

type Callback = () => void

const useKey = (
  combo: string | string[],
  callback: Callback,
  upCallback?: Callback
) => {
  let callbackRef = useRef<Callback>()
  callbackRef.current = callback

  let upCallbackRef = useRef<Callback>()
  upCallbackRef.current = upCallback

  useEffect(() => {
    mousetrap.bind(combo, (e) => {
      e.preventDefault()
      if (!!callbackRef.current) {
        callbackRef.current()
      }
    })

    if (!!upCallbackRef.current) {
      mousetrap.bind(
        combo,
        (e) => {
          e.preventDefault()
          if (!!upCallbackRef.current) {
            upCallbackRef.current()
          }
        },
        'keyup'
      )
    }

    return () => {
      mousetrap.unbind(combo)
      if (!!upCallbackRef.current) {
        mousetrap.unbind(combo, 'keyup')
      }
    }
  }, [combo])
}

export const useKeyboardEvents = () => {
  const { selectAll } = useSelectionActions()
  const { setIsOpen } = useSearchContext()

  const {
    handleAddTextItem,
    handleAddRectangleItem,
    handleAddCardItem,
    handleAddLineItem,
    handleAddStickyItem,
  } = useBoardToolbarHandlers()

  const dispatch = useDispatch()

  const reset = () => {
    dispatch(clearCursorModeAction())
    dispatch(pendingCancelAllAction())
  }

  // Set keycode for minus key in Firefox

  useEffect(() => {
    mousetrap.addKeycodes({
      173: 'firefox_minus',
    })
  }, [])

  // Delete

  useKey('del', () => dispatch(removeItemsAction()))
  useKey('backspace', () => dispatch(removeItemsAction()))

  // Select

  useKey('meta+a', selectAll)

  // Duplicate

  useKey('meta+d', () => dispatch(duplicateItemsAction()))

  // Select

  useKey(HOTKEY_SELECT, reset)

  // Create items

  useKey(HOTKEY_TEXT, handleAddTextItem)
  useKey(HOTKEY_RECTANGLE, handleAddRectangleItem)
  useKey(HOTKEY_LINE, handleAddLineItem)
  useKey(HOTKEY_STICKY, handleAddStickyItem)
  useKey(HOTKEY_CARD, handleAddCardItem)

  // Search

  useKey(HOTKEY_SEARCH, () => setIsOpen(true))

  // Cancel

  useKey('esc', reset)

  // Zoom

  const zoomIn = () => {
    dispatch(zoomInAction())
  }

  const zoomOut = () => {
    dispatch(zoomOutAction())
  }

  const zoomToDefault = () => {
    dispatch(zoomToDefaultAction())
  }

  useKey('meta+=', zoomIn)
  useKey('meta+firefox_minus', zoomOut)
  useKey('meta+-', zoomOut)
  useKey('meta+0', zoomToDefault)

  const cut = useCallback((e: ClipboardEvent) => dispatch(cutAction(e)), [
    dispatch,
  ])
  const copy = useCallback((e: ClipboardEvent) => dispatch(copyAction(e)), [
    dispatch,
  ])
  const paste = useCallback((e: ClipboardEvent) => dispatch(pasteAction(e)), [
    dispatch,
  ])

  const handleCutCopyPasteEvents = () => {
    document.addEventListener('cut', cut)
    document.addEventListener('copy', copy)
    document.addEventListener('paste', paste)

    return () => {
      document.removeEventListener('cut', cut)
      document.removeEventListener('copy', copy)
      document.removeEventListener('paste', paste)
    }
  }

  useEffect(handleCutCopyPasteEvents, [cut, copy, paste])
}
