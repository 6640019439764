/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { TextAlignment } from 'canvas-shared/lib/types/text.types'

/*---------------------------------------------------------------------------*/

export const textAlignments: TextAlignment[] = [
  {
    id: 'left',
    displayName: 'Left',
    icon: 'align-left',
  },
  {
    id: 'center',
    displayName: 'Center',
    icon: 'align-center',
  },
  {
    id: 'right',
    displayName: 'Right',
    icon: 'align-right',
  },
]

export const defaultTextAlignment = textAlignments[0]
