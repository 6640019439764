"use strict";
/*---- External -------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.BACKLOG_SPRINT_NAME = exports.StatusCategoryKey = exports.FilterType = exports.FetchContentOptionType = void 0;
var FetchContentOptionType;
(function (FetchContentOptionType) {
    FetchContentOptionType["Filters"] = "Filters";
    FetchContentOptionType["JQL"] = "JQL";
    FetchContentOptionType["Nothing"] = "Nothing";
    FetchContentOptionType["Everything"] = "Everything";
})(FetchContentOptionType = exports.FetchContentOptionType || (exports.FetchContentOptionType = {}));
var FilterType;
(function (FilterType) {
    FilterType["Custom"] = "Custom";
    FilterType["IncludeDone"] = "includedone";
    FilterType["ExcludeChild"] = "excludechild";
    FilterType["IssueType"] = "issuetype";
    FilterType["Column"] = "column";
    FilterType["Sprint"] = "sprint";
    FilterType["Label"] = "label";
    FilterType["AssignableUser"] = "assignableuser";
    FilterType["Epic"] = "epic";
})(FilterType = exports.FilterType || (exports.FilterType = {}));
var StatusCategoryKey;
(function (StatusCategoryKey) {
    StatusCategoryKey["New"] = "new";
    StatusCategoryKey["Indeterminate"] = "indeterminate";
    StatusCategoryKey["Done"] = "done";
})(StatusCategoryKey = exports.StatusCategoryKey || (exports.StatusCategoryKey = {}));
exports.BACKLOG_SPRINT_NAME = 'Backlog';
