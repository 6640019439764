/*---- External -------------------------------------------------------------*/

import { useCallback, useMemo } from 'react'

import { useFirestore } from 'react-redux-firebase'

/*---- Qualdesk -------------------------------------------------------------*/

import { functions } from '../../../../../config/firebase'

import { generateItemPath } from '../../../../paths/itemsPaths'

import { useContainerId } from '../../../../../contexts/Container'

import { useItemUpdateActions } from '../../helpers/useItemUpdateActions'

import { UUID } from 'canvas-shared/lib/types'
import { JiraBoardConnection } from 'canvas-shared/lib/types/BoardConnection.types'

import { Item, ItemType } from 'canvas-shared/lib/types/Item.types'
import * as Jira from 'canvas-shared/lib/types/connections/Jira.types'
import * as JiraAPI from 'canvas-shared/lib/types/connections/JiraAPI.types'

/*---------------------------------------------------------------------------*/

const transitionIssues = functions.httpsCallable('https-jiraTransitionIssues')
const assignParent = functions.httpsCallable('https-jiraAssignParent')

interface ItemJiraActions {
  jiraAssignUser: (itemIds: UUID[], account: Jira.Account) => Promise<void>
  jiraTransition: (
    itemIds: UUID[],
    transition: Jira.IssueTransition
  ) => Promise<void>
  jiraSetSprint: (itemIds: UUID[], sprint: JiraAPI.Sprint) => Promise<void>
  jiraUnsetSprint: (itemIds: UUID[]) => Promise<void>
  jiraPublishEstimates: (items: Item.JiraCard[]) => Promise<void>
  jiraAssignParent: (itemIds: UUID[], parentId: string) => Promise<void>
  convertItemsToJira: (
    itemIds: UUID[],
    connection: JiraBoardConnection,
    issueType: JiraAPI.IssueType,
    project: Jira.Project
  ) => Promise<void>
}

export const useItemJiraActions = (): ItemJiraActions => {
  const firestore = useFirestore()
  const containerId = useContainerId()

  const { generateItemUpdate } = useItemUpdateActions()

  const jiraTransition = useCallback(
    async (itemIds: UUID[], transition: Jira.IssueTransition) => {
      await transitionIssues({
        containerId,
        itemIds,
        transition,
      })
    },
    [containerId]
  )

  const jiraSetSprint = useCallback(
    async (itemIds: UUID[], sprint: JiraAPI.Sprint) => {
      const { id, name, self } = sprint
      const batch = firestore.batch()
      itemIds.forEach((itemId) => {
        const ref = firestore.doc(generateItemPath(containerId, itemId))
        batch.update(
          ref,
          generateItemUpdate({
            loading: true,
            'data.jira.sprint': { id, name, self },
          })
        )
      })
      await batch.commit()
    },
    [containerId, firestore, generateItemUpdate]
  )

  const jiraUnsetSprint = useCallback(
    async (itemIds: UUID[]) => {
      const batch = firestore.batch()
      itemIds.forEach((itemId) => {
        const ref = firestore.doc(generateItemPath(containerId, itemId))
        batch.update(
          ref,
          generateItemUpdate({
            'data.jira.sprint': null,
          })
        )
      })
      await batch.commit()
    },
    [containerId, firestore, generateItemUpdate]
  )

  const jiraAssignUser = useCallback(
    async (itemIds: UUID[], account: Jira.Account) => {
      const { accountId, displayName, avatarUrls } = account
      const batch = firestore.batch()
      itemIds.forEach((itemId) => {
        const ref = firestore.doc(generateItemPath(containerId, itemId))
        batch.update(
          ref,
          generateItemUpdate({
            'data.jira.assignee': {
              id: accountId,
              name: displayName,
              avatar: avatarUrls,
            },
          })
        )
      })
      await batch.commit()
    },
    [containerId, firestore, generateItemUpdate]
  )

  const jiraAssignParent = useCallback(
    async (itemIds: UUID[], parentId: string) => {
      const batch = firestore.batch()

      itemIds.forEach((itemId) => {
        const ref = firestore.doc(generateItemPath(containerId, itemId))
        batch.update(ref, generateItemUpdate({ loading: true }))
      })

      await batch.commit()
      await assignParent({
        containerId,
        itemIds,
        parentId,
      })
    },
    [containerId, firestore, generateItemUpdate]
  )

  const jiraPublishEstimates = useCallback(
    async (items: Item.JiraCard[]) => {
      const batch = firestore.batch()
      items.forEach((item) => {
        const ref = firestore.doc(generateItemPath(containerId, item.id))
        const estimate = item?.data.estimate?.value
        batch.update(
          ref,
          generateItemUpdate({
            loading: true,
            'data.jira.estimate': estimate === undefined ? null : estimate,
          })
        )
      })
      await batch.commit()
    },
    [containerId, firestore, generateItemUpdate]
  )

  const convertItemsToJira = useCallback(
    async (
      itemIds: UUID[],
      connection: JiraBoardConnection,
      issueType: JiraAPI.IssueType,
      project: Jira.Project
    ) => {
      const batch = firestore.batch()
      itemIds.forEach((itemId) => {
        const ref = firestore.doc(generateItemPath(containerId, itemId))
        batch.update(
          ref,
          generateItemUpdate({
            type: ItemType.JiraCard,
            loading: true,
            'data.jira': {
              importedBy: null,
              connection: {
                jiraBoardId: connection.jira.boardId,
                domain: connection.domain,
              },
              type: {
                id: issueType.id,
                self: issueType.self,
                name: issueType.name,
                icon: issueType.iconUrl,
              },
              project: {
                id: project.id,
                self: project.self,
                name: project.name,
                avatar: project.avatarUrls,
              },
            },
          })
        )
      })
      await batch.commit()
    },
    [containerId, firestore, generateItemUpdate]
  )

  return useMemo(
    () => ({
      jiraTransition,
      jiraAssignUser,
      jiraPublishEstimates,
      jiraSetSprint,
      jiraUnsetSprint,
      jiraAssignParent,
      convertItemsToJira,
    }),
    [
      convertItemsToJira,
      jiraAssignParent,
      jiraAssignUser,
      jiraPublishEstimates,
      jiraSetSprint,
      jiraTransition,
      jiraUnsetSprint,
    ]
  )
}
