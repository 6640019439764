/*---- External -------------------------------------------------------------*/

import { combineEpics, ofType } from 'redux-observable'
import { mapTo } from 'rxjs/operators'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  scrollAndZoomAction,
  scrollAndZoomByAction,
  scrollByAction,
  scrollDimensionsSetAllAction,
  scrollOrZoomChangedAction,
  scrollToAction,
  scrollToCenterOnAction,
  scrollToCenterOnItemIdAction,
} from '../actionCreators/scroll'

import {
  generateScrollDimensionsPath,
  generateScrollOffsetsPath,
} from '../paths/scrollPaths'

import { createRTDBEpic } from './helpers/createRTDBEpic'

import type {
  ScrollDimensions,
  ScrollOffsets,
} from 'canvas-shared/lib/types/scrollAndPosition.types'
import { generateZoomLevelPath } from '../paths/zoomLevelPath'
import { EpicWithDifferentActions, ThunkAPI } from '../../types/redux'
import { AsyncThunkAction } from '@reduxjs/toolkit'

/*---------------------------------------------------------------------------*/

const dimensionsSetAllEpic = createRTDBEpic<ScrollDimensions>(
  scrollDimensionsSetAllAction.fulfilled,
  ({ content, containerId, uid, database }) => {
    database().ref(generateScrollDimensionsPath(containerId, uid)).set(content)
  }
)

const offsetsOnlyEpic = createRTDBEpic<ScrollOffsets>(
  [scrollToAction, scrollByAction.fulfilled, scrollToCenterOnAction.fulfilled],
  ({ content, containerId, uid, database }) => {
    database().ref(generateScrollOffsetsPath(containerId, uid)).set(content)
  }
)

const offsetsAndZoomEpic = createRTDBEpic<{
  scrollOffsets: ScrollOffsets
  zoomLevel: number
}>(
  [scrollAndZoomAction.fulfilled, scrollAndZoomByAction.fulfilled],
  ({ content, containerId, uid, database }) => {
    database()
      .ref(generateScrollOffsetsPath(containerId, uid))
      .set(content.scrollOffsets)
    database()
      .ref(generateZoomLevelPath(containerId, uid))
      .set(content.zoomLevel)
  }
)

const scrollOrZoomChangedEpic: EpicWithDifferentActions<
  any,
  AsyncThunkAction<any, void, ThunkAPI>
> = (action$) =>
  action$.pipe(
    ofType<any>(
      scrollAndZoomAction.fulfilled,
      scrollAndZoomByAction.fulfilled,
      scrollToAction,
      scrollToCenterOnAction.fulfilled,
      scrollToCenterOnItemIdAction.fulfilled,
      scrollByAction.fulfilled
    ),
    mapTo(scrollOrZoomChangedAction())
  )

export const scrollEpic = combineEpics(
  dimensionsSetAllEpic,
  offsetsOnlyEpic,
  offsetsAndZoomEpic,
  scrollOrZoomChangedEpic
)
