"use strict";
/*---- External -------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGrid = void 0;
/*---- Qualdesk -------------------------------------------------------------*/
const spreadsheetColumnLetter_1 = require("../spreadsheetColumnLetter");
/*---------------------------------------------------------------------------*/
const getGrid = (sheets, sheetId, headerRow) => {
    var _a, _b;
    if (sheets === undefined || sheetId === undefined || sheetId === null) {
        return undefined;
    }
    const sheet = sheets.find((s) => { var _a; return ((_a = s.properties) === null || _a === void 0 ? void 0 : _a.sheetId) === sheetId; });
    if (!sheet || !sheet.data || !sheet.properties) {
        return undefined;
    }
    const gridData = sheet.data[0];
    const rows = Object.values((gridData === null || gridData === void 0 ? void 0 : gridData.rowData) || {});
    const columnCount = Math.max(...rows.map((r) => { var _a; return ((_a = r.values) === null || _a === void 0 ? void 0 : _a.length) || 0; }));
    const sheetName = sheet.properties.title || '';
    let columnHeaders = [];
    for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {
        const defaultValue = `Column ${spreadsheetColumnLetter_1.spreadsheetColumnLetter(columnIndex)}`;
        if (headerRow && ((_a = rows[0]) === null || _a === void 0 ? void 0 : _a.values)) {
            columnHeaders.push(((_b = rows[0].values[columnIndex]) === null || _b === void 0 ? void 0 : _b.formattedValue) || defaultValue);
        }
        else {
            columnHeaders.push(defaultValue);
        }
    }
    return {
        gridData,
        rows,
        columnCount,
        columnHeaders,
        sheetName,
    };
};
exports.getGrid = getGrid;
