/*---- External -------------------------------------------------------------*/

import { createReducer } from '@reduxjs/toolkit'

/*---- Qualdesk -------------------------------------------------------------*/

import {
  dismissToastAction,
  showToastAction,
  updateToastAction,
} from '../actionCreators/toast'

import { ToastState } from '../../types/redux'

/*---------------------------------------------------------------------------*/

const initialState: ToastState = {}

export const toastReducer = createReducer(initialState, (builder) => {
  builder.addCase(showToastAction.fulfilled, (state, action) => {
    state[action.payload.id] = action.payload
  })
  builder.addCase(dismissToastAction, (state, action) => {
    delete state[action.payload]
  })
  builder.addCase(updateToastAction, (state, action) => {
    state[action.payload.id] = action.payload
  })
})
