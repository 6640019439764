/*---- External -------------------------------------------------------------*/

import { createLocalStorageStateHook } from 'use-local-storage-state'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

export const useRedirectPath = createLocalStorageStateHook<string | undefined>(
  'redirectPath'
)
