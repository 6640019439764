/*---- External -------------------------------------------------------------*/

import React from 'react'

import { Divider } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { BoardInviteDialogPeople } from './BoardInviteDialogPeople'
import { BoardInviteDialogLink } from './BoardInviteDialogLink'

/*---------------------------------------------------------------------------*/

export const BoardInviteDialog = () => (
  <div className="flex flex-col">
    <div className="flex flex-col p-6 space-y-2">
      <BoardInviteDialogPeople />
    </div>
    <Divider className="mx-0" />
    <div className="flex flex-col p-6 space-y-2">
      <BoardInviteDialogLink />
    </div>
  </div>
)
