/*---- External -------------------------------------------------------------*/

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { otherUserSelectionsSelector } from '../../selectors/board/otherUserSelections'

/*---------------------------------------------------------------------------*/

export const useSelectionsByOtherUsers = () =>
  useSelector(otherUserSelectionsSelector)
