/*---- External -------------------------------------------------------------*/

import React, { useState } from 'react'

import classNames from 'classnames'

import {
  Button,
  Classes,
  IInputGroupProps,
  InputGroup,
} from '@blueprintjs/core'

import { MagnifyingGlass, XCircle } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

interface SearchInputGroupProps extends IInputGroupProps {
  onClear: () => void
  autoFocus?: boolean
  minimal?: boolean
  changeBackgroundOnFocus?: boolean
}

export const SearchInputGroup: React.FC<SearchInputGroupProps> = ({
  value,
  onClear,
  small = false,
  autoFocus = false,
  minimal = false,
  changeBackgroundOnFocus = true,
  className,
  ...inputGroupProps
}) => {
  const [hasFocus, setHasFocus] = useState(false)
  const focus = () => setHasFocus(true)
  const blur = () => setHasFocus(false)

  const hasMinimalStyle = minimal && !hasFocus && !value

  return (
    <InputGroup
      className={classNames(
        'search-input-group flex flex-row items-center border border-transparent rounded-full',
        {
          minimal: hasMinimalStyle,
          'border-border-weak-light dark:border-border-weak-dark bg-background-four-light dark:bg-background-four-dark': !hasMinimalStyle,
          'bg-background-five-light dark:bg-background-five-dark':
            changeBackgroundOnFocus && (hasFocus || !!value),
          'h-8': small,
        },
        className
      )}
      inputMode="search"
      leftIcon={
        <MagnifyingGlass
          size={16}
          weight="bold"
          className={classNames(
            Classes.ICON,
            'text-icon-secondary-light dark:text-icon-secondary-dark m-2'
          )}
        />
      }
      rightElement={
        !!value ? (
          <Button
            minimal
            icon={<XCircle size={16} weight="bold" className={Classes.ICON} />}
            onClick={onClear}
          />
        ) : undefined
      }
      round
      small={small}
      value={value}
      autoFocus={autoFocus}
      onFocus={focus}
      onBlur={blur}
      {...inputGroupProps}
    />
  )
}
