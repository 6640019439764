/*---- External -------------------------------------------------------------*/

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { generatePath, useHistory } from 'react-router-dom'

import { Button, Classes, Intent } from '@blueprintjs/core'
import { WarningCircle } from 'phosphor-react'

/*---- Qualdesk -------------------------------------------------------------*/

import { selectUserConnections } from '../../redux/selectors/auth/userConnections'

import {
  dismissToastAction,
  updateToastAction,
} from '../../redux/actionCreators/toast'
import { useUserConnectionsActions } from '../../redux/actions/auth/useUserConnectionsActions'

import { IntegrationIcon } from '../../components/integrations/IntegrationIcon'

import { Routes } from '../../router/routes'
import { useTypedParams } from '../helpers/useTypedParams'
import { useBoards } from '../../redux/selectorHooks/workspace/boards'

import {
  UserConnection,
  UserConnections,
  UserConnectionType,
} from 'canvas-shared/lib/types/UserConnection.types'

import {
  createSelectConnection,
  selectBoardConnectionIdsWithSyncErrors,
} from '../../redux/selectors/board/connections'

import {
  connectionDescriptor,
  connectionTitle,
} from 'canvas-shared/lib/config/connections/labels'

import { redirectToGoogleAuth } from '../../components/connections/google/helpers/redirectToGoogleAuth'

import { showToastAction } from '../../redux/actionCreators/toast'

/*---------------------------------------------------------------------------*/

export const useConnectionMonitor = () => {
  const connections = useSelector(selectUserConnections)
  const dispatch = useDispatch()
  const { disconnectConnection } = useUserConnectionsActions()
  const reconnecting = useRef<string>()
  const { replace } = useHistory()
  const { containerId } = useTypedParams()
  const boards = useBoards()

  const onDismiss = useCallback(
    (connectionId: keyof UserConnections) => {
      dispatch(dismissToastAction(`reconnection-${connectionId}`))
    },
    [dispatch]
  )

  const connectionIdsWithSyncErrors = useSelector(
    selectBoardConnectionIdsWithSyncErrors
  )
  const idsWithErrors = useRef(connectionIdsWithSyncErrors)

  const reconnect = useCallback(
    async (connectionId: keyof UserConnections) => {
      reconnecting.current = connectionId
      const connection = connections[connectionId]

      if (!connection) {
        return
      }

      switch (connection.type) {
        case UserConnectionType.Google:
          redirectToGoogleAuth()
          break
        case UserConnectionType.Jira:
          await disconnectConnection(connection.type)
          onDismiss(connectionId)

          // HACK: If the user isn’t currently on a board, we need to
          // send them to one so they can go through the Jira connection process
          const resolvedContainerId = !!containerId
            ? containerId
            : boards?.[0].id

          if (!resolvedContainerId) {
            throw new Error(
              'Can’t reauthenticate to Jira if there are no boards'
            )
          }

          replace(
            generatePath(Routes.CANVAS_CONNECTIONS.path + '/jira', {
              containerId: resolvedContainerId,
            })
          )
          break
        case UserConnectionType.Linear:
          break
        case UserConnectionType.Trello:
          break
      }
    },
    [boards, connections, containerId, disconnectConnection, onDismiss, replace]
  )

  useEffect(() => {
    const connectionIds = Object.keys(connections) as Array<
      keyof UserConnections
    >
    connectionIds.forEach((connectionId) => {
      const connection = connections[connectionId]

      if (
        connection?.reauthenticationRequired &&
        reconnecting.current !== connectionId
      ) {
        dispatch(
          updateToastAction({
            id: `reconnection-${connectionId}`,
            toast: {
              className: 'items-center',
              icon: (
                <IntegrationIcon
                  className={Classes.ICON}
                  connection={connection}
                />
              ),
              message: (
                <ReauthenticationMessage
                  connectionId={connectionId}
                  connection={connection}
                  reconnect={reconnect}
                />
              ),
              onDismiss: () => onDismiss(connectionId),
              timeout: 0,
            },
          })
        )
      } else {
        onDismiss(connectionId)
      }
    })
  }, [connections, dispatch, onDismiss, reconnect])

  useEffect(() => {
    connectionIdsWithSyncErrors.forEach((id) => {
      if (!idsWithErrors.current.includes(id)) {
        dispatch(
          showToastAction({
            className: 'items-center',
            icon: <WarningCircle className={Classes.ICON} size={16} />,
            intent: Intent.DANGER,
            message: <ConnectionSyncErrorMessage connectionId={id} />,
          })
        )
      }
    })
    idsWithErrors.current = connectionIdsWithSyncErrors
  }, [connectionIdsWithSyncErrors, dispatch])
}

interface MessageProps {
  connectionId: keyof UserConnections
  connection: UserConnection
  reconnect: (connectionId: keyof UserConnections) => void
}

const ReauthenticationMessage: React.FC<MessageProps> = ({
  connectionId,
  connection,
  reconnect,
}) => {
  const [loading, setLoading] = useState(false)
  const handleClick = () => {
    setLoading(true)
    reconnect(connectionId)
  }

  return (
    <div className="flex items-center justify-between space-x-4">
      <span className="font-semibold">Action required</span>
      <Button loading={loading} onClick={handleClick}>
        Reconnect to {connection.type}
      </Button>
    </div>
  )
}

interface ConnectionSyncErrorMessageProps {
  connectionId: string
}

const ConnectionSyncErrorMessage: React.FC<ConnectionSyncErrorMessageProps> = ({
  connectionId,
}) => {
  const selectConnection = createSelectConnection(connectionId)
  const connection = useSelector(selectConnection)

  if (!connection) {
    throw new Error(
      `Tried to show an error message for a nonexistent connection id ${connectionId}`
    )
  }

  return (
    <>
      Couldn’t sync data for your {connectionDescriptor(connection)}{' '}
      <b>{connectionTitle(connection)}</b>
    </>
  )
}
