/*---- External -------------------------------------------------------------*/

import React from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { GoogleSelectFileForm } from './GoogleSelectFileForm'

import googleSheetsIcon from '../../../assets/integrations/google/google-sheets-icon.svg'

import { ControlledPanelProps } from '../SidebarPanelStack'

/*---------------------------------------------------------------------------*/

export const GoogleSelectFileFirst: React.FC<ControlledPanelProps> = (
  panelProps
) => (
  <div className="flex flex-col flex-grow justify-center p-6">
    <div className="flex flex-col space-y-4">
      <div className="flex justify-center">
        <img
          alt="Google Sheets icon"
          className="w-12 h-12"
          src={googleSheetsIcon}
        />
      </div>
      <h2 className="text-text-primary-light dark:text-text-primary-dark text-center text-xl font-semibold">
        Connect a sheet
      </h2>
      <h3 className="text-text-secondary-light dark:text-text-secondary-dark text-center text-sm leading-normal">
        Copy the link to your Google Sheets document and paste it below
      </h3>
      <GoogleSelectFileForm
        className="flex flex-col space-y-4"
        fill
        openPanel={panelProps.openPanel}
      />
    </div>
  </div>
)
