/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'

import intersection from 'lodash.intersection'

/*---- Qualdesk -------------------------------------------------------------*/

import getBoundingRectFromPositions from '../../../helpers/getBoundingRectFromPositions'
import { createDeepEqualSelector } from '../helpers/createDeepEqualSelector'
import { isGroup } from 'canvas-shared/lib/helpers/itemTypes'

import { getItemsData } from './items'
import { positionsDataSelector } from './positions'

import { UUID } from 'canvas-shared/lib/types'
import { Item, ItemType } from 'canvas-shared/lib/types/Item.types'
import { ItemPosition } from 'canvas-shared/lib/types/Position.types'
import { RootState } from '../../../types/redux'

/*---------------------------------------------------------------------------*/

const getDynamicItemsData = (state: RootState) =>
  state.board.items.dynamicItems.data

export const selectGroups = createDeepEqualSelector(
  createSelector(
    getDynamicItemsData,
    getItemsData,
    positionsDataSelector,
    (dynamicItemsData, itemsData, positionsData) =>
      Object.values(dynamicItemsData)
        .filter((g) => isGroup(g))
        .map((g) =>
          enhanceCanvasGroup(g as Item.CanvasGroup, itemsData, positionsData)
        )
  ),
  (groups) => groups
)

export const selectGroupIds = createDeepEqualSelector(
  createSelector(selectGroups, (groups) => groups.map((i) => i.id)),
  (ids) => ids
)

export const selectDynamicItemsLoaded = (state: RootState) =>
  state.board.items.dynamicItems.loaded

export const createGroupSelector = (groupId: UUID) =>
  createSelector(selectGroups, (groups) => groups.find((g) => g.id === groupId))

export const createSelectUnfilteredGroupsWithMemberIds = (memberIds: UUID[]) =>
  createSelector(
    getDynamicItemsData,
    (dynamicItemsData) =>
      Object.values(dynamicItemsData).filter(
        (g) => isGroup(g) && intersection(memberIds, g.memberIds).length > 0
      ) as Item.CanvasGroup[]
  )

const enhanceCanvasGroup = (
  d: Item.CanvasGroup,
  itemsData: Record<string, Item.AnyItem>,
  positionsData: Record<string, ItemPosition>
): Item.CanvasGroupWithMemberData => {
  const memberIds = d.memberIds.filter(
    (itemId) => !!itemsData[itemId] && !!positionsData[itemId]
  )

  const members = memberIds.map((id) => itemsData[id])
  const memberPositions = memberIds.map((id) => positionsData[id])
  const groupRect = getBoundingRectFromPositions(memberPositions)

  return {
    ...d,
    type: ItemType.Group,
    memberIds,
    memberPositions,
    members,
    groupRect,
  }
}
