/*---- External -------------------------------------------------------------*/

import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { deployCommitHashSelector } from '../../selectors/system/deployCommitHash'

/*---------------------------------------------------------------------------*/

export const useLatestVersion = () => useSelector(deployCommitHashSelector)
